// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import ContaminationDeclarationFinalizedQuery from "../../graphql/queries/contaminationDeclarationFinalized.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Alert} from "antd";
import moment from "moment";
import FileDownloadBox from "../FileDownloadBox";

type Props = {
    contaminationDeclarationId: string,
};

type State = {}


class ServiceTicketContaminationDeclarationFinalized extends Component<Props, State> {

    render() {
        const {contaminationDeclarationFinalizedQuery, t} = this.props;

        const contaminationDeclaration = _.get(contaminationDeclarationFinalizedQuery, 'contaminationDeclaration');
        const file = _.get(contaminationDeclaration, 'file') || _.get(contaminationDeclaration, 'print.sheet');

        return <div className={"p-10"}>
            <Alert
                message={t('Contamination Declaration was finalized')}
                description={t('Contamination Declaration was finalized by USER at DATE', {
                    USER: _.get(contaminationDeclaration, 'finalized.finalizedBy.name'),
                    DATE: moment(_.get(contaminationDeclaration, 'finalized.insert')).format("DD.MM.YYYY hh:mm"),
                })}
                type="success"
                showIcon
            />

            {file ?
                <div className={'mt-10'}>

                    <FileDownloadBox
                        title={t('Print Contamination Declaration')}
                        thumbnailLink={_.get(file, 'thumbnailLink')}
                        link={_.get(file, 'link')}
                        size={_.get(file, 'size')}
                        update={_.get(file, 'update')}
                    />

                </div>

                : undefined}

        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(ContaminationDeclarationFinalizedQuery, {
        name: 'contaminationDeclarationFinalizedQuery',
        options: (props) => ({
            variables: {
                id: props.contaminationDeclarationId
            }
        })
    }),
    waitWhileLoading('contaminationDeclarationFinalizedQuery', ['contaminationDeclaration']),
)(ServiceTicketContaminationDeclarationFinalized);
