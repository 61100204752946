// @flow
import React, {Component} from 'react';
import {Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ComponentStarredQuery from "./../../graphql/queries/ComponentStarredQuery.graphql";
import UpdateStarredComponentMutation from "../../graphql/mutations/UpdateStarredComponentMutation.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {toastError} from "../../utils/toast";
import StarredComponentsQuery from "../../graphql/queries/starredComponents.graphql";
import ComponentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";


type Props = {
    componentId: string,
    starred: boolean,
    t: Function,
};

type State = {
    loading: boolean
}

class ComponentStarredButton extends Component<Props, State> {

    state = {
        loading: false
    };

    _handleToggleStarred = () => {
        const {starred, componentId, updateStarredComponentMutation} = this.props;

        this.setLoading(true)
            .then(() => {
                return updateStarredComponentMutation({
                    variables: {
                        id: componentId,
                        starred: !starred,
                    }
                })
            })
            .catch((err) => {
                toastError(err);
            })
            .finally(() => {
                this.setLoading(false);
            })
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {starred, t} = this.props;

        return <Tooltip title={starred ? t('click to unmark') : t('click to mark')}>
            <Button loading={this.state.loading} type={starred ? 'primary' : null} size={'large'} shape="circle"
                    onClick={this._handleToggleStarred}>
                {!this.state.loading ? <FontAwesomeIcon outline={!starred} name={'star'}/> : null}
            </Button>
        </Tooltip>;
    }
}

export default compose(
    withTranslation(),
    graphql(ComponentStarredQuery, {
        name: 'componentStarredQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentStarredQuery', 'component'),
    graphql(UpdateStarredComponentMutation, {
        name: 'updateStarredComponentMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ComponentStarredQuery,
                variables: {
                    id: props.componentId
                }
            }, {
                query: ComponentQuery,
                variables: {
                    id: props.componentId
                }
            }, {
                query: StarredComponentsQuery,
                variables: {
                    thumbnailWidth: 50,
                    thumbnailHeight: 50
                }
            }]
        }),
    }),
    withProps((props) => ({
        starred: _.get(props, 'componentStarredQuery.component.starred', false)
    }))
)(ComponentStarredButton);
