// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import queryWrapper from "../QueryWrapper";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import Empty from "./../Empty";

import ServiceTicketContaminationDeclarationCreateQuery
    from "./../../graphql/queries/serviceTicketContaminationDeclarationCreateQuery.graphql";
import CreateContaminationDeclarationMutation from "./../../graphql/mutations/createContaminationDeclaration.graphql";
import {checkIfServiceTicketCanCreateContaminationDeclaration} from "../../utils/ContaminationDeclarationHelper";
import Loader from "../Loader";
import Error from "../Error";

type Props = {
    serviceTicketId: string,
    serviceTicketContaminationDeclarationCreateQuery: any,
    createContaminationDeclarationMutation: Function,
    onCreated: (createdContaminationDeclaration: any)=> void
};

type State = {
    loading: boolean,
    canCreate: boolean,
    contaminationDeclaration?: any,
    error?: string
}

class ContaminationDeclarationAutoCreate extends Component<Props, State> {

    state = {
        loading: true,
        canCreate: false,
        contaminationDeclaration: null,
        error: null
    };

    componentDidMount() {

        const {serviceTicketContaminationDeclarationCreateQuery, createContaminationDeclarationMutation, serviceTicketId, t} = this.props;
        const {serviceTicket} = serviceTicketContaminationDeclarationCreateQuery;

        const canCreate = checkIfServiceTicketCanCreateContaminationDeclaration(serviceTicket);
        this.setState({
            contaminationDeclaration: serviceTicket.contaminationDeclaration,
            canCreate,
            loading: canCreate && !serviceTicket.contaminationDeclaration,
        }, () => {
            if (serviceTicket.contaminationDeclaration) {
                this.props.onCreated(serviceTicket.contaminationDeclaration)
            }
        });
        if (canCreate && !serviceTicket.contaminationDeclaration) {
            createContaminationDeclarationMutation({
                variables: {
                    serviceTicketId
                }
            }).then(({data}) => {
                this.setState({
                    loading: false,
                    contaminationDeclaration: data.createContaminationDeclaration
                }, () => {
                    if (this.props.onCreated) {
                        this.props.onCreated(data.createContaminationDeclaration)
                    }
                })

            })
                .catch(() => {
                    this.setState({
                        error: t('Could not create contamination declaration'),
                        loading: false
                    })
                })

        }
    }

    render() {
        const {t, serviceTicketId} = this.props;

        const {canCreate, loading, error, contaminationDeclaration} = this.state;

        if (!canCreate) {
            return <Empty text={'You are not able to create a contamination declaration for this service ticket'}/>;
        }

        if (loading) {
            return <Loader title={t('Creating contamination declaration')}/>
        }

        if (error) {
            return <Error description={error}/>
        }

        if (contaminationDeclaration) {
            return <Link
                to={`/service/${serviceTicketId}/contaminationDeclaration`}>{t('Open_2')} {t('contamination declaration')}</Link>
        }

        return <Loader hideTitle/>

    }
}


export default compose(
    graphql(ServiceTicketContaminationDeclarationCreateQuery, {
        name: 'serviceTicketContaminationDeclarationCreateQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    graphql(CreateContaminationDeclarationMutation, {
        name: 'createContaminationDeclarationMutation',
    }),
)(withTranslation()(
    queryWrapper(withRouter(withTranslation()(ContaminationDeclarationAutoCreate)), (props: Props) => ({
        queryConfigs: [{
            query: props.serviceTicketContaminationDeclarationCreateQuery,
            checkProperties: ['serviceTicket.component.componentType.contaminationDeclarationTypeDefinitionAttributeList'],
        }]
    }))));
