// @flow
import React, {Component} from 'react';
import  {Modal,Avatar} from "antd";
import _ from 'lodash';
import {compose, withProps} from "recompose";
import styled from "styled-components";
import {graphql} from '@apollo/client/react/hoc';
import ProfileQuery from "./../../graphql/queries/profile.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Sizes from "../../themes/default/sizes";
import {UserOutlined} from "@ant-design/icons";
import AttributeInlineForm from "../Form/attributes-inline-form";
import Panel from "../Panel";
import {Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import UpdateProfileMutation from "../../graphql/mutations/updateProfileImage.graphql";

const QUERY_KEY = 'profileQuery';

const NameLabel = styled.span`
  padding: ${Sizes.grid / 2}px 0;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
`;

const EmailLabel = styled.span`
  padding: ${Sizes.grid / 2}px 0;
  margin-bottom: ${Sizes.grid}px;
  display: inline-block;
`;

type Props = {
    profile?: any,
};


class ProfileIntro extends Component<Props, State> {

    state = {
        modal: false
    }

    render() {
        const {profile, t, updateProfileMutation, profileQuery} = this.props;

        let avatarAttribute = _.get(profile, 'typeDefinitionAttributeList.typeDefinitionAttributes', []).map((attribute) => {
            if (attribute.index === 'avatar') {
                return {
                    ...attribute,
                    inputProps: {
                        customContent: <div>
                            <p className="ant-upload-drag-icon">
                                <Icon type="user"/>
                            </p>
                            <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
                            <p className="ant-upload-hint">
                                {t('a square picture is recommended')}
                            </p>
                        </div>
                    },
                    name: '',
                    formItemLayout: {
                        colon: false,
                        labelCol: {
                            md: {
                                span: 0
                            }
                        },
                        wrapperCol: {
                            md: {
                                span: 24
                            }
                        }
                    },
                }
            }
        }).filter(function (el) {
            return el != null;
        });

        return <div className={"text-center white-bg"}>
            <div onClick={() => this.setState({modal: true})} className={"mb-10 pointer"}>
                <Avatar  size={64} src={_.get(profile, 'avatar.thumbnailLink')} icon={<UserOutlined/>}/>
            </div>
            <Modal
                onCancel={() => this.setState({modal: false})}
                onOk={() => this.setState({modal: false})}
                destroyOnClose
                footer={null}
                visible={this.state.modal}
            >
                <AttributeInlineForm
                    attributes={avatarAttribute}
                    useSubmit={false}
                    onChange={(values) => {

                        return new Promise((resolve) => {
                            if (values.avatar === null){
                                return updateProfileMutation({
                                    variables: {
                                        input: {
                                            avatar: null
                                        }
                                    }
                                })
                            }
                            return resolve();
                        }).then(() => {
                            return profileQuery.refetch().then(() => this.setState({modal: false}))
                        })


                    }}
                />
            </Modal>
            <div>
                <NameLabel>{profile.name}</NameLabel>
            </div>
            <div>
                <EmailLabel>{profile.email}</EmailLabel>
            </div>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ProfileQuery, {
        name: QUERY_KEY,
    }),
    graphql(UpdateProfileMutation, {
        name: 'updateProfileMutation',
    }),
    waitWhileLoading(QUERY_KEY, 'profile', {
        loaderSize: 'small'
    }),
    withProps((props) => ({
        profile: _.get(props, QUERY_KEY + '.profile')
    }))
)(ProfileIntro);
