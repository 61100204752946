// @flow
import React, {Component} from 'react';
import FillQueryTable from "../QueryTable/fill";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ServiceProviderRoleListQuery from "../../graphql/queries/serviceProviderRoleList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";

type Props = {
    serviceProviderId?: any,
    roleListQuery: query,
    t: Function,
    onClick: Function,
}

class ServiceProviderRoleList extends Component<Props> {
    render() {
        const {roleListQuery, t, onClick} = this.props;

        return <div className={'ServiceProviderRoleList'}>
            <FillQueryTable
                style={{borderRadius: 0}}
                className={"link-table"}
                columns={[
                    {
                        key: 'name',
                        dataIndex: 'name',
                        title: t('Name')
                    },
                    {
                        key: 'description',
                        dataIndex: 'description',
                        title: t('description')
                    }
                ]}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (onClick) return onClick(record);
                        },
                    };
                }}
                size={'small'}
                rowKey={"id"}
                query={roleListQuery}
                itemsKeyPath={'serviceProviderRoleList.roles'}
                totalKeyPath={'serviceProviderRoleList.total'}
                sortBy={'name'}/>
        </div>
    }
}

export default compose(
    graphql(ServiceProviderRoleListQuery, {
        name: 'roleListQuery',
        options: (props) => ({
            variables: {
                serviceProviderId: props.serviceProviderId
            }
        }),
    }),
    waitWhileLoading('roleListQuery', ['serviceProviderRoleList.roles']),
    withTranslation(),
)(ServiceProviderRoleList);

