// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import * as _ from "lodash";
import componentAttributes from "../../graphql/queries/component-Attributes.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributeInlineForm from "./../Form/attributes-inline-form";
import UpdateComponentAttributes from "./../../graphql/mutations/updateComponent.graphql";
import {getAttributeGroups} from "../../utils/attributeGroups";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import ComponentLogTimelineQuery from "../../graphql/queries/componentLogTimeline.graphql";
import statics from "../../statics";
import componentListQuery from "../../graphql/queries/componentList-ComponentBrowser.graphql";
import {connect} from "react-redux";
import ProfileQuery from "../../graphql/queries/profileComponent.graphql";
import {Link} from "react-router-dom";
import {Tooltip} from "antd";

type Props = {
    componentId: string,
    filter?: Function,
    getComponentAttributes: query,
}

class ComponentAttributeTabs extends Component<Props, State> {

    render() {

        const props = this.props;
        const {componentId, t} = this.props;

        let attributes = _.get(props, 'getComponentAttributes.component.typeDefinitionAttributeList.typeDefinitionAttributes').map((item) => {
            let res = {...item};
            if (res.index === "cuContaminated"){
                res = {
                    ...res,
                    inputType: 'switch',
                    confirm: true,
                    confirmTitle: t('cuContaminated_confirm_title')
                }
            }

            if (_.get(res,'options.versionable', false)){
                res.name = <Tooltip title={t('View history')}>
                    <Link to={`/infrastructure/${componentId}/versionalattributes/${item.index}`}>{item.name}</Link>
                </Tooltip>
            }

            return res
        });

        if (props.filter) {
            attributes = attributes.filter(props.filter);
        }

        const groups = getAttributeGroups(attributes, props.t);

        const fromProps = {
            attributes,
            inline: true,
            groups,
            useTabs: true,
            mutation: _.get(props, 'updateComponentAttributes'),
            translateMutationOptions: (input) => {
                const {values} = input;
                return {
                    variables: {
                        id: props.componentId,
                        input: values,
                    }
                }
            }
        };

        return <AttributeInlineForm {...fromProps}/>;
    }
}

export default compose(
    // connect(mapStateToProps),
    withTranslation(),
    graphql(componentAttributes, {
        name: 'getComponentAttributes',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('getComponentAttributes', ['component.typeDefinitionAttributeList'], {
        loaderSize: 'small'
    }),
    graphql(ProfileQuery, {
        name: 'profileQuery'
    }),
    waitWhileLoading('profileQuery', 'profile.rootComponent.id', {
        optional: true
    }),
    connect((state) => {
        return {
            finderId: _.get(state,'settings.componentBrowser.componentId'),
            stockSearchEnabled: _.get(state,'settings.componentBrowser.stockSearchEnabled'),
        }
    }, null),
    graphql(UpdateComponentAttributes, {
        name: 'updateComponentAttributes',
        options: (props) => {
            const rootComponentId= _.get(props, 'profileQuery.profile.rootComponent.id');
            return {
                refetchQueries: [{
                    query: componentQuery,
                    variables: {
                        id: props.componentId
                    }
                },{
                    query: ComponentLogTimelineQuery,
                    variables: {
                        id: props.componentId,
                        params: {
                            start: 0,
                            limit: statics.defaultPagingSize,
                            filter: {level: {operator: "in", value: ["information", "warning", "error"]}}
                        }
                    }
                },{
                    query: componentListQuery,
                    variables: {
                        params: {
                            filter: {
                                parentId: props.componentId ? {
                                    operator: "e",
                                    direct: !(props.stockSearchEnabled && props.finderId === rootComponentId),
                                    value: props.componentId
                                } : undefined,
                                componentTypeIndex: props.stockSearchEnabled && props.finderId === rootComponentId ? {
                                    value: ['stock']
                                } : undefined,
                            },
                            search: {
                                query: null,
                                fields: ["name", "serialNo", "catalogNo"]
                            }
                        }
                    }
                }]
            }
        }
    }),
)(ComponentAttributeTabs);
