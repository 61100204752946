import React, {Component} from "react";

export default class BodyRow extends Component {
    render() {
        const { isOver, connectDragSource, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;

        return connectDragSource(
            <tr {...restProps} className={className} style={style} />,
        );
    }
}
