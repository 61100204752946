// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import AccessToken from "../../graphql/queries/accessToken.graphql";

const ConnectorsBreadcrumb = ({accessTokenQuery, t}) =>
    <span>{_.get(accessTokenQuery, 'accessToken.name', t('Unknown'))}</span>;

export default compose(
    graphql(AccessToken, {
        name: 'accessTokenQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.id')
            }
        }),
    }),
)(withTranslation()(ConnectorsBreadcrumb));
