// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import TCOAdminSettingsQuery from "./../../graphql/queries/tcoAdminSettings.graphql"
import _ from 'lodash'
import waitWhileLoading from "../../hoc/waitWhileLoading";
import TDAAttributesForm from "../TDAAttributesForm";

type Props = {};

type State = {

}

class TCOAdminSettings extends Component<Props, State> {

  render() {
    const { data } = this.props;

    return <div>
        <TDAAttributesForm
           formStyles={{
               formItemLayout: {
                   labelCol: {
                       xs: { span: 24 },
                       sm: { span: 12 },
                   },
                   wrapperCol: {
                       xs: { span: 24 },
                       sm: { span: 12 },
                   },
               }
           }}
            attributes={_.get(data,'tcoConfig.typeDefinitionAttributeList.typeDefinitionAttributes')}
        />
    </div>;
  }
}

export default compose(
    graphql(TCOAdminSettingsQuery),
    waitWhileLoading()
)(TCOAdminSettings);
