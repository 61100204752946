// @flow
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ContentHeader from "../../components/Typo/ContentHeader";
import {compose} from "redux";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";
import ServiceActionTypeTable from "../../components/ServiceActionTypeTable";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};

class ServiceActionTypeListRoute extends Component<Props, State> {


    render() {
        const {t, history} = this.props;

        return <NavigationContainer navigationBar={<ContentHeader
            title={t('Service Actions')}
        />}>
            <ServiceActionTypeTable onClick={(record) => history.push(`/admin/serviceactionstypes/${record.index}`)}/>
        </NavigationContainer>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withResources(["administrateServiceActionTypePrivileges"])
)(ServiceActionTypeListRoute);
