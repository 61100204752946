import React, {useState} from 'react';
import {Alert, Button, message, Popconfirm} from "antd";
import ClientAccessRequestListID from "../../graphql/queries/clientAccessRequestListID.graphql";
import {useMutation, useQuery} from "@apollo/client";
import ClientActiveQuery from "../../graphql/queries/ClientActive.graphql";
import ClientActiveMutation from "../../graphql/mutations/activateClient.graphql";
import CreateClientAccessRequestMutation from "../../graphql/mutations/createClientAccessRequest.graphql";
import ClientDetailsQuery from "../../graphql/queries/clientDetails.graphql";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import Strings from "../../i18n/strings";
import ClientAccessRequestListQuery from "../../graphql/queries/clientAccessRequestList.graphql";

interface Props {
    clientId: string,
    currentUserClientId: string
}

const ClientActivationBanner = ({clientId, currentUserClientId}: Props) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const clientActiveQuery = useQuery(ClientActiveQuery, {
        variables: {
            id: clientId,
        },
        fetchPolicy: "network-only"
    })
    const clientAccessRequestListIDQuery = useQuery(ClientAccessRequestListID, {
        variables: {
            params: {
                filter: {
                    requestedByClientId: {
                        value: currentUserClientId
                    },
                    clientId: {
                        value: clientId
                    }
                }
            }
        },
        fetchPolicy: "network-only"
    });

    const [activateClientMutation] = useMutation(ClientActiveMutation, {
        variables: {
            id: clientId,
        },
        refetchQueries: [
            {
                query: ClientDetailsQuery,
                variables: {
                    id: clientId
                }
            },
            {
                query: ClientActiveQuery,
                variables: {
                    id: clientId
                }
            },
        ]
    })
    const [createClientAccessRequestMutation] = useMutation(CreateClientAccessRequestMutation, {
        variables: {
            id: clientId,
        },
        refetchQueries: [
            {
                query: ClientAccessRequestListQuery
            }
        ]
    })

    const handleActivate = () => {
        setLoading(true)
        activateClientMutation()
            .then((res) => {
                message.success(_.get(res, 'data.activateClient.message'));
            })
            .catch(() => {
                message.error(t(Strings.errorOccurred))
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const handleRequestAccess = () => {
        setLoading(true)
        createClientAccessRequestMutation()
            .then((res) => {
                if (_.get(res, 'data.createClientAccessRequest.id')) {
                    message.success(t("Access requested."));
                }
            })
            .catch(() => {
                message.error(t("Failed to request access."))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const clientName = _.get(clientActiveQuery, 'data.client.name')
    const isClientActive = _.get(clientActiveQuery, 'data.client.active')
    const hasAccessToClientComponent = _.get(clientActiveQuery, 'data.client.component.id')
    const openClientAccessRequests = _.get(clientAccessRequestListIDQuery, 'data.clientAccessRequestList.clientAccessRequests', []).filter(request => request.status === "pending")

    let bannerSettings;

    if (isClientActive && hasAccessToClientComponent) {
        bannerSettings = null
    } else if (isClientActive && !hasAccessToClientComponent && openClientAccessRequests.length > 0) {
        bannerSettings = {
            text: t('You have already sent an access request'),
            button: <Button disabled type={'primary'}>
                {t('Request access')}
            </Button>,
            bannerType: "warning"
        }
    } else if (isClientActive && !hasAccessToClientComponent) {
        const activatedByName = _.get(clientActiveQuery, 'data.client.activatedBy.name')

        bannerSettings = {
            text: t("CLIENT is activated by SERVICEPROVIDER but no access",{CLIENT: clientName, SERVICEPROVIDER: activatedByName}),
            button: <Popconfirm onConfirm={handleRequestAccess} title={t('Are you sure')}>
                <Button loading={loading} type={'primary'}>
                    {t('Request access')}
                </Button>
            </Popconfirm>,
            bannerType: "warning"
        }
    } else {
        bannerSettings = {
            text: t("CLIENT is not activated",{CLIENT: clientName}),
            button: <Popconfirm onConfirm={handleActivate} title={t('Are you sure')}>
                <Button loading={loading} type={'primary'}>
                    {t('Activate for VSM')}
                </Button>
            </Popconfirm>,
            bannerType: "warning"
        }
    }

    if (!bannerSettings || clientActiveQuery.loading || clientAccessRequestListIDQuery.loading) {
        return null;
    }

    return (
        <div className={"mh-15"}>
            <Alert
                message={t("Client activation status")}
                description={bannerSettings.text}
                type={bannerSettings.bannerType}
                showIcon
                action={bannerSettings.button}
                style={{alignItems: "center"}}
            />
        </div>
    );
}

export default ClientActivationBanner;
