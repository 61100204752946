// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import DefaultServiceTaskQuery from "../../graphql/queries/defaultServiceTask.graphql";
import Panel from "../Panel";

type Props = {
    history: {
        push: Function
    },
    componentId: string,
    createServicePlanMutation: any,
    t: Function,
    onDone: () => void,
};

type State = {
    values: any,
}


class CreateServicePlan extends Component<Props, State> {

    state = {
        values: {}
    }

    render() {
        const {componentId, mutation, t, client, onDone} = this.props;
        const {values} = this.state;

        return <div className={'ph-10'}>
            <Panel title={t('New service recommendation')}>
                <TypeDefinitionAttributesForm
                    index={"servicePlan"}
                    options={{
                        componentId
                    }}
                    formProps={{
                        useSubmit: true,
                        submitTitle: t('Create'),
                        mutation: mutation,
                        submitChangesOnly: false,
                        onChange: (vals) => {
                            const tmp = {
                                ...this.state.values,
                                ...vals
                            };
                            if (vals.defaultServiceTaskId !== undefined && !_.isEqual(this.state.values.defaultServiceTaskId, vals.defaultServiceTaskId)) {
                                const query = client.query({
                                    query: DefaultServiceTaskQuery,
                                    variables: {
                                        id: vals.defaultServiceTaskId
                                    }
                                });
                                query.then(({data}) => {
                                    const {defaultServiceTask} = data;
                                    this.setState({
                                        values: {
                                            ...tmp,
                                            name: defaultServiceTask.name,
                                            description: defaultServiceTask.description,
                                        }
                                    })
                                })

                            }
                            this.setState({
                                values: {
                                    ...this.state.values,
                                    ...vals
                                }
                            })
                        },
                        values,
                        translateMutationOptions: ({values}) => {
                            return {
                                variables: {
                                    id: componentId,
                                    input: values
                                }
                            }
                        },
                        onSubmit: onDone
                    }}
                />
            </Panel>
        </div>;
    }
}

export default compose(
        withTranslation(),
    withApollo
)(CreateServicePlan)
