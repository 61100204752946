// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";

import ServiceTicketWorkflowBoard from "../../components/ServiceTicketWorkflowBoard";
import queryString from "query-string";
import {compose, withProps} from "recompose";
import connect from "react-redux/es/connect/connect";
import {setServiceWorkflowQuery} from "../../actions/settings";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import parseSearchQueryParams from "../../utils/searchParams";

type Props = {
    match: {
        params: any
    },
    history: {
        push: Function
    },
    t: Function,
}

class WorkflowRoute extends Component<Props> {

    render() {

        const {filter} = this.props;

        return <ServiceTicketWorkflowBoard onChange={(params) => {
            const query = params ? queryString.stringify(params) : null;

            this.props.setSearchQuery(query);
            if (!params) {
                this.props.history.push(`/workflow`)
            } else {
                this.props.history.push(`?${queryString.stringify(params)}`)
            }

        }} filter={filter}/>
    }
}


export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        let query = _.get(props, 'history.location.search');
        return {
            query
        }
    }),
    connect((state, props) => {

        let query = props.query;
        if (!query || !query.length) {
            query = _.get(state, 'settings.serviceWorkflowQuery');
        }

        return {
            query,
        }
    }, dispatch => ({
        setSearchQuery: (query) => {
            return dispatch(setServiceWorkflowQuery(query))
        }
    })),
    withProps((props) => {
        return {
            filter: parseSearchQueryParams(props.query),
            setSearchQuery: props.setSearchQuery,
            push: props.history.push
        }
    })
)(WorkflowRoute);
