// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {withTranslation} from "react-i18next";
import ProductComponentType from "../../graphql/queries/productComponentType.graphql"
import Panel from "../Panel";
import ProductDefaultServiceTasksTable from "../ProductDefaultServiceTasksTable";
import ComponentTypeDefaultServiceTasksTable from "../ComponentTypeDefaultServiceTasksTable";
import {Button} from "@dreebit/react-components";
import {Link} from "react-router-dom";

type Props = {
    t: Function,
    productId: string,
};


class ProductDefaultServiceTasks extends Component<Props, State> {


    render() {
        const {productId,t, data} = this.props;

        return <div className={"p-10"}>
            <Panel title={t("Product Default Service Tasks")}
                topRight={<Link to={`/catalog/products/${productId}/defaultServiceTasks/create`}>
                    <Button icon={"plus"}>{t("Add")}</Button>
                </Link>}
            >
                <ProductDefaultServiceTasksTable productId={productId}/>
            </Panel>

            <Panel title={t("Component Type Default Service Tasks")}>
                <ComponentTypeDefaultServiceTasksTable componentTypeIndex={_.get(data,'product.componentType.index')}/>
            </Panel>
        </div>
    }
}

export default compose(
    graphql(ProductComponentType,{
        options: (props) => ({
            variables: {
                id: props.productId
            }
        })
    }),
    withTranslation(),
)(ProductDefaultServiceTasks);
