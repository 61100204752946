// @flow
import React, {Component} from 'react';

type Props = {};

type State = {}

class ServiceCalendarItemIcon extends Component<Props, State> {

    render() {
        const {} = this.props;

        return <div></div>;
    }
}

export default ServiceCalendarItemIcon;
