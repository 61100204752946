// @flow
import React, {Component} from 'react';
import {Popconfirm} from "antd";
import {toastError} from "../../utils/toast";
import {Button} from "@dreebit/react-components";
import Strings from "../../i18n/strings";
import {withTranslation} from "react-i18next";

type Props = {
    mutation: any,
    confirm?: boolean,
    onDone?: (res: any) => void,
    onError?: (err: any) => void,
    buttonProps?: any,
    variables?: Object,
    onClick?: Function,
    block?: boolean
};

type State = {
    loading: boolean
}

class MutationButton extends Component<Props, State> {

    state = {
        loading: false
    };

    onClick = () => {
        const {mutation, onDone, onError, variables, onClick} = this.props;

        this.setLoading(true)
            .then(() => mutation(variables ? variables : undefined))
            .then((res) => {
                if (onDone) {
                    onDone(res);
                }
            })
            .catch((err) => {
                if (onError) {
                    onError(err)
                } else {
                    toastError(err);
                }
            })
            .finally(() => this.setLoading(false));

        if (onClick) onClick();

    };


    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, () => {
                resolve();
            })
        })
    };

    render() {
        const {buttonProps, t, confirm, block} = this.props;

        const button = <Button {...buttonProps} loading={this.state.loading}
                               onClick={confirm ? undefined : this.onClick} block={block}>
            {this.props.children}
        </Button>;

        if (confirm){
            return <Popconfirm onConfirm={this.onClick} title={t(Strings.areYouSure)}>
                {button}
            </Popconfirm>
        }

        return button;
    }
}

export default withTranslation()(MutationButton) ;
