// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ContentHeader from "../Typo/ContentHeader";
import Panel from "../Panel";
import ComponentTypeServiceReportConfigList from "../ComponentTypeServiceReportConfigList";
import CategoryList from "../CategoryList";
import {createDndContext} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Col, Modal, Row} from "antd";
import CatalogCategoriesDetailRoute from "../../routes/catalog/categories/detail";


type Props = {
  t: Function,
  onServiceReportConfigRowClick: (row: any) => void,
};

type State = {
  visible: boolean,
  categoryId: string,
  categoryType: string,
}

class ComponentTypeServiceReportConfigDashboard extends Component<Props, State> {
  componentDidMount() {
  }

  state = {
    visible: false,
    categoryId: null,
    categoryType: null
  };

  showModal = (row) => {
    console.log(row)
    this.setState({
      visible: true,
      categoryId: row.id,
      categoryType: row.categoryType,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      // categoryId: null
    });
  };


  render() {
    const {t, match, onServiceReportConfigRowClick} = this.props;

    const RNDContext = createDndContext(HTML5Backend);

    return <div>
      <ContentHeader
          title={t('Service report templates')}
      />
      <Row>
        <Col md={14}>
          <Panel
              key={'serviceReportConfig'}
              title={'Service Report Config'}
              containerStyle={{marginLeft: '10px', marginRight: '10px'}}
          >
            <ComponentTypeServiceReportConfigList
                index={_.get(match, 'params.index')}
                onServiceReportConfigRowClick={row => onServiceReportConfigRowClick(row)}
                context={RNDContext}
            />
          </Panel>
        </Col>
        <Col md={10}>
          <Panel
              key={'attributeCategoryList'}
              title={t('Categories')}
              containerStyle={{marginLeft: '10px', marginRight: '10px'}}
          >
            <CategoryList
                categoryType={'serviceReport'}
                onCategoryListRowClick={row => this.showModal(row)}
                context={RNDContext}
            />
          </Panel>
        </Col>
      </Row>
      <Modal
          visible={this.state.visible}
          footer={null}
          onCancel={this.handleCancel}
      >
        <CatalogCategoriesDetailRoute
            onUpdateAttributeCategory={() => this.handleCancel()}
            handleDeleteRedirect={() => this.handleCancel()}
            id={this.state.categoryId}
            categoryType={this.state.categoryType}
        />
      </Modal>


    </div>;
  }
}

export default compose(
  withRouter,
  withTranslation()
)(ComponentTypeServiceReportConfigDashboard);
