// @flow
import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import DashboardRoute from "../dashboard";
import InfrastructureCreateComponent from "../InfrastructureCreateComponent";
import Infrastructure from "../infrastructure";
import Catalog from "../catalog";
import ServiceContractPartnerRoute from "../service/contractPartners";
import ServiceBudgetRoute from "../service/budget";
import Service from "../service";
import ServiceCalendar from "../serviceCalendar";
import Admin from "../admin";
import Profile from "../profile";
import Dev from "../dev";
import MessagesRoute from "../messages";
import Workflow from "../workflow";
import News from "../news"
import TicketRedirect from "../service/ticketRedirect";
import InfrastructureCreateTokenRoute from "../InfrastructureCreateComponent/InfrastructureCreateToken";
import Error404 from "../errorPages/error404";

import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";
import BookmarkCountQuery from "../../graphql/queries/bookmarkCount.graphql";
import PortletCountQuery from "../../graphql/queries/portletCount.graphql";

class AppRoutes extends Component<Props, State> {

    render() {
        const {portletCountQuery, bookmarkCountQuery} = this.props;
        const portletCount = _.get(portletCountQuery, 'portletList.total')
        const infrastructureBookmarkCount = _.get(bookmarkCountQuery, 'bookmarkList.total')

        return <Switch>
            <Route path="/dashboard" component={DashboardRoute}/>
            <Route from="/infrastructure/cmt/:token" component={InfrastructureCreateTokenRoute}/>
            <Route from="/infrastructure/cmt" component={InfrastructureCreateTokenRoute}/>
            <Route path="/infrastructure/create/:id" component={InfrastructureCreateComponent}/>
            <Redirect exact from="/infrastructure/create" to="/infrastructure/dashboard"/>
            <Route path="/infrastructure/:index" component={Infrastructure}/>
            <Route
                path="/infrastructure"
                component={() => <Infrastructure redirectTab={infrastructureBookmarkCount === 0 ? 'search' : null}/>}
            />
            <Route path="/catalog" component={Catalog}/>
            {/* deprecated: Funktionen für Service Vertrags Partner & Service Budget werden testweise nicht mehr angezeigt */}
            {/* TODO: ggf. später wieder einfügen oder komplett entfernen */}
            {/*<Route path="/contractpartners" component={ServiceContractPartnerRoute}/>*/}
            {/*<Route path="/service/budget" component={ServiceBudgetRoute}/>*/}
            <Route path="/service/ticket/:mongoId" component={TicketRedirect}/>
            <Route path="/service/:index" component={Service}/>
            <Route path="/service" component={Service}/>
            <Route path="/workflow" component={Workflow}/>
            <Route path="/calendar/:selectedId?/:selectedIndex?"
                   component={ServiceCalendar}/>
            <Route path="/admin" component={Admin}/>
            <Route path="/news" component={News}/>
            <Route path="/messages/:id" component={MessagesRoute}/>
            <Route path="/messages" component={MessagesRoute}/>
            <Route path="/profile/:index" component={Profile}/>
            <Redirect from="/profile" to={"/profile/details"}/>
            {
                portletCount > 0 ?
                    <Redirect exact from="/" to="/dashboard"/> :
                    <Redirect exact from="/" to="/infrastructure"/>
            }
            <Redirect from="/" to="/dashboard"/>
        </Switch>;
    }
}

export default compose(
    graphql(PortletCountQuery, {
        name: 'portletCountQuery',
    }),
    waitWhileLoading('portletCountQuery'),
    graphql(BookmarkCountQuery, {
        name: 'bookmarkCountQuery',
        options: (props: Props) => ({
            variables: {
                params: {filter: {type: {value: 'infrastructureSearch'}}}
            }
        }),
    }),
    waitWhileLoading('bookmarkCountQuery')
)(AppRoutes)
