// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import ContentHeader from "../../../components/Typo/ContentHeader";
import ContentWrapper from "../../../components/ContentWrapper";
import CatalogAttributeCreateForm from "../../../components/CatalogAttributeCreateForm";
import Panel from "../../../components/Panel";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import _ from "lodash";


class CatalogAttributeCreateRoute extends Component<Props, State> {

    render() {
        const {t, history} = this.props;

        return <div>
            <ContentHeader title={t('New Catalog Attribute')}/>
            <ContentWrapper>
                <Panel>
                    <CatalogAttributeCreateForm onCreated={(res) => {
                        const index = _.get(res, 'data.createCatalogAttribute.index')
                        if (index) history.push(`/catalog/attributes/${index}`)
                    }}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withTranslation(),
    withRouter
)(CatalogAttributeCreateRoute);
