import React from "react";

const SvgNewServiceAction = props => (
    <svg
        id="newServiceAction_svg__Ebene_1"
        x={0}
        y={0}
        viewBox="0 0 150 100"
        xmlSpace="preserve"
        width={props.width}
        {...props}
    >
        <style>
            {
                ".newServiceAction_svg__st0{fill:#8394ae}.newServiceAction_svg__st1{fill:#fff}.newServiceAction_svg__st2{font-family:'Evogria'}.newServiceAction_svg__st3{font-size:34.6969px}"
            }
        </style>
        <path className="newServiceAction_svg__st0" d="M0 0h150v100H0z"/>
        <text
            transform="translate(82.885 79.65)"
            className="newServiceAction_svg__st1 newServiceAction_svg__st2 newServiceAction_svg__st3"
        >
            {"!"}
        </text>
        <circle
            className="newServiceAction_svg__st1"
            cx={91.7}
            cy={68.6}
            r={22.4}
        />
        <text
            transform="translate(84.505 83.096)"
            className="newServiceAction_svg__st0 newServiceAction_svg__st2 newServiceAction_svg__st3"
        >
            {"!"}
        </text>
        <path
            className="newServiceAction_svg__st1"
            d="M76.2 46.9c2.2-1.6 4.7-2.9 7.4-3.7 6.5-2.1 13.3-1.5 19.1 1.2 2.2-2.2 3.9-4.8 4.9-7.8.7-2.1 1.1-3.7 1.1-4.9 0-.5-.1-.9-.4-1.2-.3-.3-.6-.5-1.1-.5-.3 0-1.4.5-3.3 1.6s-4 2.4-6.3 3.8-3.5 2.2-3.7 2.3l-9-5V22.4l13.6-7.8c.5-.3.7-.8.7-1.3s-.2-1-.7-1.3c-1.4-.9-3-1.6-5-2.2-1.9-.5-3.7-.8-5.5-.8-5.7 0-10.6 2-14.7 6.1s-6.1 9-6.1 14.7 2 10.6 6.1 14.7c.9.9 1.9 1.7 2.9 2.4zM73.9 48.8c-.9-.7-1.8-1.4-2.7-2.3-2.3-2.3-4.1-5-5.3-8L34.3 70.1c-1.2 1.2-1.8 2.6-1.8 4.2 0 1.7.6 3.1 1.8 4.2l4.9 5c1.2 1.1 2.6 1.7 4.2 1.7s3-.6 4.2-1.7l17.8-17.8c.6-6.4 3.7-12.5 8.5-16.9zM46.4 74.3c-.8 0-1.5-.3-2.1-.9s-.9-1.3-.9-2.1.3-1.5.9-2.1c.6-.6 1.3-.9 2.1-.9s1.5.3 2.1.9c.6.6.9 1.3.9 2.1s-.3 1.5-.9 2.1c-.6.6-1.3.9-2.1.9z"
        />
    </svg>
);

export default SvgNewServiceAction;