// @flow
import React, {Component} from 'react';
import Color from "color";
import styled from "styled-components";
import Dot from "../Dot";
import Colors from "../../themes/default/colors";
import OneLineLabel from "../OneLineLabel";

type Props = {
    text: string,
    color: string,
    bordered: boolean,
    fullWidth: boolean
};

const Text = styled.span`
  margin-left: 5px;
`;

const Wrapper = styled.div`
  ${(props) => {
    if (props.bordered) {
        return `
        border: 1px solid ${props.color};
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        border-radius: 4px;
        background-color: ${Color(props.color).alpha(0.1)};
      `
    }
}} 
  color: ${(props) => Color(props.color).darken(0.5).string()} !important;
  ${(props) => {
    if (props.fullWidth) {
        return `
        width: 100%;
      `
    }
}} 
`;

const DotWrapper = styled.div`
    padding-right: 3px;
`


class DotLabel extends Component<Props, State> {

    static defaultProps = {
        color: Colors.greyDark
    };

    render() {
        const {text, bordered, color, fullWidth} = this.props;

        return <Wrapper fullWidth={fullWidth} color={color} bordered={bordered}>
            <DotWrapper><Dot color={color}/></DotWrapper> <OneLineLabel>{text}</OneLineLabel>
        </Wrapper>;
    }
}

export default DotLabel;
