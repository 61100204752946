// @flow
import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import ProfileConnectors from "../../../components/ProfileConnectors";
import ProfileConnectorCreate from "../../../components/ProfileConnectorCreate";
import ProfileConnectorDetail from "../../../components/ProfileConnectorDetail";
import withResources from "../../../hoc/withResources";


class ConnectorsRoute extends Component<Props, State> {

    render() {


        return <Switch>
            <Route exact path="/profile/connectors" component={ProfileConnectors}/>
            <Route exact path="/profile/connectors/create" component={ProfileConnectorCreate}/>
            <Route exact path="/profile/connectors/:id" component={ProfileConnectorDetail}/>
        </Switch>;
    }
}

export default withResources('administrateConnectors')(ConnectorsRoute);
