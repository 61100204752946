import React, {Component} from 'react'
import _ from 'lodash'
import AttributesInlineForm from "../Form/attributes-inline-form";
import ServiceControlFormQuery from "./../../graphql/queries/ServiceControlForm.graphql"
import UpdateServiceControlMutation from "./../../graphql/mutations/updateServiceControl.graphql"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    serviceControlId: string,
    serviceControlFormQuery: any,
    updateServiceControlMutation: Function,
}

class ServiceControlForm extends Component<Props> {

    render() {

        const {serviceControlFormQuery, updateServiceControlMutation, serviceControlId} = this.props;

        return <div>
            <AttributesInlineForm
                className={'m-10'}
                attributes={_.get(serviceControlFormQuery, 'serviceControl.typeDefinitionAttributeList.typeDefinitionAttributes')}
                translateMutationOptions={({values}) => ({
                    variables: {
                        attributes: values,
                        serviceControlId
                    }
                })}
                mutation={updateServiceControlMutation}
            />
        </div>
    }

}

export default compose(
    graphql(UpdateServiceControlMutation, {
        name: 'updateServiceControlMutation'
    }),
    graphql(ServiceControlFormQuery, {
        name: 'serviceControlFormQuery',
        options: props => ({
            variables: {
                serviceControlId: props.serviceControlId
            }
        }),
    }),
    waitWhileLoading('serviceControlFormQuery', ['serviceControl.typeDefinitionAttributeList.typeDefinitionAttributes'])
)(ServiceControlForm)
