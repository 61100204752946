// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import AttributesInlineForm from "../Form/attributes-inline-form";


type Props = {
    value: any,
    t: Function,
    onChange: Function,
};

class BookmarkNameInput extends Component<Props, State> {
    render() {
        const {t, value, onChange} = this.props;

        const attributes = [{
            name: t('Key'),
            index: 'bookmarkName',
            type: 'string',
            inputType: 'text',
            inputTypeOptions: {
                placeholder: 'bookmarkId',
            },
        }];

        return <AttributesInlineForm
            layout={'inline'}
            onValuesChange={(changes) => {
                onChange(changes)
            }}
            values={value}
            attributes={attributes}
            useSubmit={false}
        />
    }
}

export default compose(
    withTranslation(),
)(BookmarkNameInput);
