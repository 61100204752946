import * as actionTypes from '../actions/actionTypes';

export const initialState = {
    forms: {},
    componentBrowser: {
        componentId: "initialSetting",
        stockSearchEnabled: false,
        clientListView: false
    },
    serviceWorkflowQuery: '',
    infrastructureSearchQuery: "",
    productSearchQuery: "fields=name&fields=catalogNo&status=inReview",
    manufacturerSearchQuery: "",
    serviceBudgetQuery: "",
    componentBrowserWidth: 400,
    activeServiceTicketTab: null,
    activeInfrastructureTab: null,
    resources: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_FORM: {
            const tmp = state;
            tmp.forms[action.form] = action.data;
            return {
                ...tmp
            };
        }

        case actionTypes.SET_COMPONENT_BROWSER_COMPONENT: {
            const tmp = {...state};
            if (!tmp.componentBrowser) {
                tmp.componentBrowser = {}
            }
            tmp.componentBrowser.componentId = action.componentId;
            return tmp;
        }

        case actionTypes.SET_COMPONENT_BROWSER_CLIENT_LIST: {
            const tmp = {...state};
            if (!tmp.componentBrowser) {
                tmp.componentBrowser = {}
            }
            tmp.componentBrowser.clientListView = action.enabled;
            return tmp;
        }

        case actionTypes.SET_LAST_SERVICE_SEARCH: {
            const tmp = {...state};
            if (!tmp.serviceSearchQuery) {
                tmp.serviceSearchQuery = initialState.serviceSearchQuery
            }
            tmp.serviceSearchQuery = action.query;
            return tmp;
        }

        case actionTypes.SET_LAST_PRODUCT_SEARCH: {
            const tmp = {...state};
            if (!tmp.productSearchQuery) {
                tmp.productSearchQuery = initialState.productSearchQuery
            }
            tmp.productSearchQuery = action.query;
            return tmp;
        }

        case actionTypes.SET_LAST_MANUFACTURER_SEARCH: {
            const tmp = {...state};
            if (!tmp.manufacturerSearchQuery) {
                tmp.manufacturerSearchQuery = initialState.manufacturerSearchQuery
            }
            tmp.manufacturerSearchQuery = action.query;
            return tmp;
        }


        case actionTypes.SET_LAST_WORKFLOW_SEARCH: {
            const tmp = {...state};
            if (!tmp.serviceWorkflowQuery) {
                tmp.serviceWorkflowQuery = initialState.serviceWorkflowQuery
            }
            if (action.query) {
                tmp.serviceWorkflowQuery = action.query;
            } else {
                tmp.serviceWorkflowQuery = initialState.serviceWorkflowQuery
            }

            return tmp;
        }

        case actionTypes.SET_LAST_INFRASTRUCTURE_SEARCH: {
            const tmp = {...state};
            if (!tmp.infrastructureSearchQuery) {
                tmp.infrastructureSearchQuery = initialState.infrastructureSearchQuery
            }
            tmp.infrastructureSearchQuery = action.query;
            return tmp;
        }

        case actionTypes.SET_LAST_BUDGET_SEARCH: {
            const tmp = {...state};
            if (!tmp.serviceBudgetQuery) {
                tmp.serviceBudgetQuery = initialState.serviceBudgetQuery
            }
            tmp.serviceBudgetQuery = action.query;
            return tmp;
        }

        case actionTypes.SET_COMPONENT_BROWSER_WIDTH: {
            const tmp = {...state};
            tmp.componentBrowserWidth = action.width;
            return tmp;
        }

        case actionTypes.SET_COMPONENT_BROWSER_STOCK_SEARCH: {
            const tmp = {...state};
            const componentBrowser = {...tmp.componentBrowser};
            componentBrowser.stockSearchEnabled = action.enabled;
            tmp.componentBrowser = componentBrowser;
            return tmp;
        }

        case actionTypes.SET_ACTIVE_SERVICE_TAB: {
            const tmp = {...state};
            tmp.activeServiceTicketTab = action.tab;
            return tmp;
        }

        case actionTypes.SET_ACTIVE_INFRASTRUCTURE_TAB: {
            const tmp = {...state};
            tmp.activeInfrastructureTab = action.tab;
            return tmp;
        }

        case actionTypes.SET_PROFILE_RESOURCES: {
            const tmp = {...state};
            tmp.resources = action.resources;
            return tmp;
        }

        case actionTypes.SET_PROFILE_LANGUAGE: {
            const tmp = {...state};
            tmp.language = action.language;
            return tmp;
        }

        case actionTypes.SET_FILE_TABLE_VIEW: {
            const tmp = {...state};
            tmp.fileTableView = action.fileTableView;
            return tmp;
        }

        case actionTypes.USER_LOGGED_IN:
            return initialState;

        case actionTypes.USER_LOGGED_OUT:
            return initialState;

        default:
            return state;
    }
};

export default reducer;
