// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {Grow, Row} from "../Flex";
import type {UserInfo} from "../../types";
import BorderWrapper from "../Border";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {Button, Icon} from "@dreebit/react-components";
import MutationButton from "../MutationButton";
import {Link} from "react-router-dom";
import FileUploadDropZone from "../FileUploadDropZone";
import classNames from "classnames";

import "./index.less";

type Props = {
    formId?: string,
    name: string,
    loading?: boolean,
    update: string,
    updatedBy: UserInfo,
    finalized?: any,
    editLink?: string,
    downloadLink?: string,
    onFinalize: () => ?Promise<any>,
    onDownload: () => ?Promise<any>,
    onUploaded: (fileResponse: any) => ?Promise<any>,
    disableEdit: boolean
};

type State = {

}

class FinalizeListItem extends Component<Props, State> {


    state = {
        loadingEdit: false,
        loadingDownload: false,
        loadingFinalize: false,
        loading: false,
    };

    handleUpload = (fileResponses) => {
        const {onUploaded} = this.props;
        if (onUploaded) {
            fileResponses.forEach((fileResponse) => {
                this.handleLoadingPromise(onUploaded(fileResponse), 'loading');
            })
        }

    };

    handleEdit = () => {
        const {onEdit} = this.props;
        if (onEdit) this.handleLoadingPromise(onEdit(), 'loadingEdit');

    };

    handleDownload = () => {
        const {onDownload} = this.props;
        if (onDownload) this.handleLoadingPromise(onDownload(), 'loadingDownload');
    };

    handleFinalize = () => {
        const {onFinalize} = this.props;
        if (onFinalize) this.handleLoadingPromise(onFinalize(), 'loadingFinalize');
    };

    handleLoadingPromise(promise: ?Promise<any>, loadingKey: string) {
        if (promise && promise.finally) {
            const newState = {};
            newState[loadingKey] = true;
            this.setState(newState, () => {
                promise.finally(() => {
                    newState[loadingKey] = false;
                    this.setState(newState);
                })
            })
        }
    }

  render() {

    const { rightTools, onUploaded, formId, name, update,t, updatedBy, finalized ,downloadLink, editLink, onDownload, onFinalize, loading} = this.props;


    let dateText = <span className={"text-dark"}>{name} {t('not created yet')}</span>;

      const isCreated = !!formId;
      const downloadButton = onDownload ? <MutationButton buttonProps={{icon: 'download'}}
                                                          mutation={onDownload}>{t('Download')}</MutationButton> : downloadLink ?
          <Button icon={'download'} target={"_BLANK"} download href={downloadLink}>{t('Download')}</Button> : null;
      const linkButton = <Button><Link to={editLink}>{isCreated ? t('Edit') : t('Create')}</Link></Button>;
      const finalizeButton = <MutationButton confirm buttonProps={{
          type: 'primary',
          icon: 'lock'
      }} mutation={onFinalize}>{t('Finalize')}</MutationButton>;

      let iconType = null;

      const actions = [];
      let className;

    if (!isCreated){
        className = "warning";
        actions.push(linkButton);
        iconType = 'warning';
    }else if (finalized) {
        className = "success";
        iconType = 'check-circle';
        dateText = `${t('Finalized at DATE by NAME', {
            DATE: moment(_.get(finalized, 'insert', update)).format('LL HH:mm'),
            NAME: _.get(finalized, 'finalizedBy.name')
        })} `;
        actions.push(downloadButton);
    }else {
        iconType = 'warning';
        className = "warning";
        dateText = `${name} ${t('is not finalized yet')}`;
        actions.push(linkButton);
        if (onFinalize) {
            actions.push(finalizeButton);
        }
    }

    const content = <Row className={"p-10"}>
        <Grow>
            <div style={{lineHeight: "2em"}}>
                  <span>
                    <Icon className={`${className}-color`} type={iconType} style={{marginRight: 6}}/>
                  </span>
                <Link className={"text-dark"} to={editLink}>
                    {dateText}
                </Link>
            </div>

        </Grow>
        <div>
            {actions.map((item, index) => {
                let margin = 10;
                if (index === actions.length-1){
                    margin = 0;
                }
                return <span style={{marginRight: margin}} key={`${index}`}>
                        {item}
                    </span>
            })}
            { rightTools }
        </div>
    </Row>;

      return <BorderWrapper radius={5} className={`${className}-border`}>
          {onUploaded ?
              <FileUploadDropZone
                  loaderSize={'small'}
                  onUploaded={this.handleUpload}
                  dropzoneProps={{
                      accept: ['image/*', 'application/pdf'],
                      multiple: false,
                      style: {}
                  }}
              >
            {({isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles}) => (
                <div className={classNames('FinalizeListItem', {isDragActive, isDragReject, isDragAccept})}>
                    {content}
                </div>
            )}

            </FileUploadDropZone> : content}
    </BorderWrapper>;
  }
}

export default withTranslation()(FinalizeListItem);
