// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {Badge} from "antd";
import {Button} from "@dreebit/react-components";
import ComponentStatusQuery from "./../../graphql/queries/ComponentStatusQuery.graphql";
import UpdateComponentMutation from "./../../graphql/mutations/updateComponent.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {toastError} from "../../utils/toast";
import componentAttributes from "../../graphql/queries/component-Attributes.graphql";

type Props = {
    componentId: string,
    active: boolean,
    componentStatusQuery: query,
};


class ComponentStatusButton extends Component<Props, State> {

    state = {
        loading: false
    };

    _handleToggle = () => {
        const {componentStatusQuery, componentId, updateComponentMutation} = this.props;
        this.setLoading(true)
            .then(() => {
                return updateComponentMutation({
                    variables: {
                        id: componentId,
                        input: {
                            status: !componentStatusQuery.component.status
                        }
                    }
                })
            })
            .catch((err) => {
                toastError(err);
            })
            .finally(() => {
                this.setLoading(false);
            })
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {componentStatusQuery, t} = this.props;

        let content = componentStatusQuery.component.status ?
            <Badge status="processing" text={t('Status')}/> :
            <Badge status="default" text={t('Status')}/>;

        return <Button loading={this.state.loading} onClick={this._handleToggle}>{content}</Button>;
    }
}

export default compose(
    withTranslation(),
    graphql(ComponentStatusQuery, {
        name: 'componentStatusQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    graphql(UpdateComponentMutation, {
        name: 'updateComponentMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: componentAttributes,
                variables: {
                    id: props.componentId
                }
            }]
        }),
    }),
    waitWhileLoading('componentStatusQuery'),
    withProps((props) => ({
        active: _.get(props, 'componentStatusQuery.component.status', false)
    }))
)(ComponentStatusButton);

