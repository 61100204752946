// @flow
import React, {Component} from 'react';
import ContentHeader from "../Typo/ContentHeader";
import {Link} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import ContentWrapper from "../ContentWrapper";
import NotificationSettingsListTable from "../NotificationSettingsListTable";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import NavigationContainer from "../NavigationContainer";

type Props = {
    t: Function
};


class ProfileNotificationSettings extends Component<Props, State> {


    render() {
        const {t} = this.props;

        return <NavigationContainer
            navigationBar={
            <ContentHeader title={t('Notificationsettings')}
                           tools={<div>
                               <Link to={'/profile/notificationsettings/create'}>
                                   <Button icon={"plus"}>
                                       {t('Add')}
                                   </Button>
                               </Link>
                           </div>}/>
        }>
            <ContentWrapper>
                <NotificationSettingsListTable
                    onClick={(notificationsetting) => this.props.history.push(`/profile/notificationsettings/${notificationsetting.id}`)}
                />
            </ContentWrapper>
        </NavigationContainer>;
    }
}

export default compose(
    withTranslation()
)(ProfileNotificationSettings);
