export default class LogLevelIconFactory {

    static getIcon(logActionKey) {

        let result;

        switch (logActionKey) {
            case 'componentAttributeUpdate':
            case 'componentRuntimeUpdate':
            case 'componentUpdate':
            case 'productAttributeUpdate':
            case 'serviceActionUpdate':
            case 'serviceTicketAttributeUpdate':
            case 'serviceActionSet':
            case 'serviceTicketAttributeSet':
            case 'serviceTicketSet':
                result = 'pen';
                break;

            case 'componentCreate':
            case 'serviceTicketCreate':
            case 'serviceActionCreate':
                result = 'plus';
                break;

            case 'componentMoveOld':
                result = 'exchange-alt';
                break;

            case 'componentNoteCreate':
            case 'productNote':
            case 'componentNote':
                result = 'sticky-note';
                break;

            case 'contaminationDeclarationCreate':
            case 'contaminationDeclarationFileCreate':
            case 'contaminationDeclarationUpdate':
                result = 'radiation';
                break;

            case 'finalTestCreate':
            case 'finalTestSet':
            case 'finalTestUpdate':
                result = 'file-invoice';
                break;

            case 'servicePlanCreate':
            case 'servicePlanUpdate':
                result = 'calendar-alt';
                break;

            case 'serviceTicketDelete':
                result = 'minus';
                break;

            case 'serviceTicketFileCreate':
            case 'serviceTicketFileDelete':
            case 'serviceActionFileCreate':
                result = 'file';
                break;

            default:
                result = 'info';
                break;
        }

        return result;
    }

    static getColor(logActionLevel) {

        let result;

        switch (logActionLevel) {
            case 'information':
                result = 'blue-grey-color';
                break;

            case 'warning':
                result = 'warning-color';
                break;

            case 'error':
                result = 'error-color';
                break;

            default:
                result = 'blue-grey-color';
                break;
        }

        return result;
    }

    static getBackgroundColor(logActionLevel) {

        let result;

        switch (logActionLevel) {
            case 'information':
                result = 'blue-grey-bg';
                break;

            case 'warning':
                result = 'warning-bg';
                break;

            case 'error':
                result = 'error-bg';
                break;

            default:
                result = 'blue-grey-bg';
                break;
        }

        return result;
    }

}
