// @flow
import React, {Component} from 'react';
import styled from "styled-components";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import DeleteComponentMutation from "./../../graphql/mutations/DeleteComponent.graphql";
import Bold from "../../components/Utilities/Bold";
import Strings from "../../i18n/strings";
import {Button} from "@dreebit/react-components";
import {toastError, toastMessage} from "../../utils/toast";
import openedComponentList from "../../graphql/queries/openedComponentList.graphql";
import ComponentDeleteFlagQuery from "../../graphql/queries/componentDeleteFlag.graphql";
import {connect} from "react-redux";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    match: {
        params: {
            id: string
        }
    }
};

type State = {
    loading: boolean
}

const Wrapper = styled.div`
  padding: 0 ${2 * Sizes.grid}px
`;

class ComponentDelete extends Component<Props, State> {

    state = {
        loading: false,
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    _handleDelete = () => {

        const {deleteComponentMutation, history, client} = this.props;

        this.setLoading(true)

            .then(() => {
                return deleteComponentMutation();
            })
            .then( () => {
                return client.clearStore();
            })
            .then(() => {
                return client.query({
                    query: openedComponentList
                });
            })
            .then(() => {
                return this.setLoading(false);
            })
            .then(() => {
                if (this.props.onDelete) {
                    return this.props.onDelete(this.props.componentId)
                } else {
                    history.push('/infrastructure/search');
                }
                return null;
            })
            .then(toastMessage)
            .catch(toastError)

    };

    render() {
        const {t, componentDeleteFlagQuery} = this.props;

        const isTrash = _.get(componentDeleteFlagQuery,'component.trash');

        return <Wrapper>
            <Panel title={t('Delete')}>
                <div className={"text-center"}>
                    <Bold>{t(Strings.areYouSure)}</Bold>
                    <p>{isTrash ? t('The component will be removed') : t('Delete components will be moved to trash location')}</p>
                    <Button
                        loading={this.state.loading}
                        type={'danger'}
                        onClick={this._handleDelete}
                    >
                        {t('Delete')}
                    </Button>
                </div>

            </Panel>
        </Wrapper>;
    }
}

export default compose(
    withTranslation(),
    connect((state) => {
        return {
            finderId: _.get(state,'settings.componentBrowser.componentId'),
        }
    }, null),
    withProps(({match}) => ({
        componentId: _.get(match, 'params.id')
    })),
    graphql(ComponentDeleteFlagQuery,{
        name: 'componentDeleteFlagQuery',
        options: (props) => {
            return {
                variables: {
                    id: props.componentId,
                }
            }
        }
    }),
    waitWhileLoading('componentDeleteFlagQuery'),
    graphql(DeleteComponentMutation, {
        name: 'deleteComponentMutation',
        options: (props: Props) => ({
            variables: {
                id: props.componentId,
            },
        }),
    }),
    withApollo,
)(ComponentDelete);

