// @flow
import React, {Component} from "react"
import {Route, Switch, withRouter} from "react-router-dom";
import ServiceContractPartnersList from "../../components/ServiceContractPartnerList";
import ServiceContractProductList from "../../components/ServiceContractProductList";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ContentHeader from "../../components/Typo/ContentHeader";
import withResources from "../../hoc/withResources";
import Error404 from "../errorPages/error404";


type Props = {
  t: Function,
}
// deprecated: Funktionen für Service Vertrags Partner werden testweise nicht mehr angezeigt
// ggf. später wieder einfügen oder komplett entfernen
// auch ServiceContractProductList / ServiceContractPartnersList beachten

class ServiceContractPartnerRoute extends Component<Props> {

  render() {

    const { t } = this.props;

    return <div className="full-size">
      <ContentHeader title={t('Contract Partners')}/>
      <Switch>
        <Route path="/contractpartners/:id" component={ServiceContractProductList}/>
        <Route path="/contractpartners" component={ServiceContractPartnersList}/>
        <Route component={Error404}/>
      </Switch>
    </div>

  }
}

export default compose(
  withResources('administrateServiceContracts'),
  withTranslation(),
  withRouter,
)(ServiceContractPartnerRoute);
