// @flow
import React, {Component} from 'react';

import {Button} from "@dreebit/react-components";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ComponentListSearchQuery from "../../graphql/queries/componentList-Search.graphql"
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ComponentTypeIcon from "../ComponentTypeIcon";
import groupByShelfBox from "./groupByShelfBox";
import SubselectionListLoading from "./subselectionListLoading";

import './subselectionList.css';


type Props = {
    component: {
        id: number,
        name: string,
        childrenCount: number,
        componentType: {
            name: string,
        }
    },
    t: Function,
};


class SubselectionList extends Component<Props> {

    render() {

        const {component, componentListSearchQuery, t} = this.props;

        const subComponents = _.get(componentListSearchQuery, 'componentList.components', []);
        const subComponentsSorted = groupByShelfBox(subComponents);

        if (componentListSearchQuery.loading) {
            return <SubselectionListLoading/>
        }


        return <div className="list-wrapper">
            <div className="list-header">
                <div className="flex flex-space-between">
                    <div className="flex flex-align-items-center ellipse-label">
                        <ComponentTypeIcon componentTypeIndex={component.componentType.index} size={12}
                                           color={'black'}/>
                        <span className="larger-text ml-5 ellipse-label">{component.name}</span>
                    </div>
                    {component.childrenCount ? <Button className={"sub-button ml-5"} size={"small"}>
                        <FontAwesomeIcon style={{fontSize: "12px", marginRight: "3px"}} name={"sitemap"}/> {component.childrenCount}
                    </Button> : null }

                </div>
                <span className="large-text">{component.componentType.name}</span>
                <div className="flex flex-align-items-center ellipse-label">
                    <FontAwesomeIcon name={"user"} circled={false} color={"black"} size={"1x"}/>
                    <span className="ml-5 ellipse-label">{t("Person Responsible")}: {component.owner ? component.owner : '-'}</span>
                </div>
            </div>

            {(component.componentType.index === 'shelf' && subComponentsSorted.shelvBox) ?
                <div className="list-body">
                    <Link to={`/infrastructure/stock/${component.id}`}>
                        <div className="list-item p-5 flex flex-space-between flex-align-items-center">
                            <span
                                className="text-dark-gray">{subComponentsSorted.shelvBox.length} {t('Compartments')}</span>
                            <FontAwesomeIcon name={"angle-right"} circled={false} color={"#bdc6cf"} size={"2x"}/>
                        </div>
                    </Link>
                    {subComponentsSorted.noShelvBox ? subComponentsSorted.noShelvBox.map((subComp, index) => {
                        return <Link key={index}
                                     to={subComp.componentType.product ? `/infrastructure/${subComp.id}` : `/infrastructure/stock/${subComp.id}`}>
                            <div className="list-item p-5 flex flex-space-between flex-align-items-center">
                                <div className="flex-col ellipse-label">
                                    <span className="text-dark-gray ellipse-label">{subComp.name}</span>
                                    {subComp.componentType.product ?
                                      <span className="subtitle ellipse-label">S/N: {_.get(subComp, 'serialNo', '-')}</span> :
                                      <span className="subtitle ellipse-label">{subComp.componentType.name}</span>
                                    }
                                </div>
                                <div className="flex flex-align-items-center">
                                    <Button className={"sub-button mh-5"} size={"small"}>
                                        <FontAwesomeIcon style={{fontSize: "12px", marginRight: "3px"}}
                                                         name={"sitemap"}/> {subComp.childrenCount}
                                    </Button>
                                    <FontAwesomeIcon name={"angle-right"} circled={false} color={"#bdc6cf"}
                                                     size={"2x"}/>
                                </div>
                            </div>
                        </Link>
                    }) : null
                    }
                </div>
                :
                <div className="list-body">
                    {subComponents.map((subComp, index) => {
                        return <Link key={index}
                                     to={subComp.product ? `/infrastructure/${subComp.id}` : `/infrastructure/stock/${subComp.id}`}>
                            <div className="list-item p-5 flex flex-space-between">
                                <div className="flex-col ellipse-label">
                                    <span className="text-dark-gray ellipse-label">{subComp.name}</span>
                                    {subComp.product ?
                                      <span className="subtitle ellipse-label">S/N: {_.get(subComp, 'serialNo', '-')}</span> :
                                      <span className="subtitle ellipse-label">{subComp.componentType.name}</span>
                                    }
                                </div>
                                <div className="flex flex-align-items-center">
                                    <Button className={"sub-button mh-5"} size={"small"}>
                                        <FontAwesomeIcon style={{fontSize: "12px", marginRight: "3px"}}
                                                         name={"sitemap"}/> {subComp.childrenCount}
                                    </Button>
                                    <FontAwesomeIcon name={"angle-right"} circled={false} color={"#bdc6cf"}
                                                     size={"2x"}/>
                                </div>
                            </div>
                        </Link>
                    })}
                </div>
            }
        </div>;
    }
}


export default compose(
    graphql(ComponentListSearchQuery, {
        name: 'componentListSearchQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: {
                        parentId: {
                            value: props.component.id,
                            direct: true
                        }
                    }
                }
            }
        })
    }),
    withTranslation()
)(SubselectionList);
