// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {Icon} from "@dreebit/react-components";
import ProductInfoBox from "../ProductInfobox";
import {withTranslation} from "react-i18next";
import MutationButton from "../MutationButton";
import {graphql} from '@apollo/client/react/hoc'
import MatchProductsMutation from "../../graphql/mutations/matchProducts.graphql";

type Props = {
    sourceId: string,
    targetId: string,
    onMatched: () => void
};

type State = {

}

class ProductMatchPerformer extends Component<Props, State> {

  render() {
    const { sourceId, targetId, t, matchProductsMutation, onMatched } = this.props;

    return <div>

        <div className={"border-dashed"}>
            <ProductInfoBox productId={sourceId} />
        </div>
        <div className={"text-center pv-10"}>
            <Icon style={{fontSize: 18}} type="down" />
        </div>
        <div className={"border-success"}>
            <ProductInfoBox productId={targetId} />
        </div>

        <div className={"text-center mt-10"}>
            <MutationButton
                buttonProps={{
                    type: 'primary'
                }}
                mutation={matchProductsMutation}
                onDone={onMatched}>{t("Perform match")}</MutationButton>
        </div>
    </div>;
  }
}

export default compose(
    withTranslation(),
    graphql(MatchProductsMutation, {
        name: 'matchProductsMutation',
        options: props => ({
            variables: {
                productId: props.targetId,
                matchProductId: props.sourceId
            }
        })
    }),
)(ProductMatchPerformer);
