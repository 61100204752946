// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {List, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import componentTypeList from './../../graphql/queries/componentTypeList.graphql';
import ComponentTypeIcon from "../ComponentTypeIcon";
import Colors from "../../themes/default/colors";

type Props = {
    title: string,
    getComponentTypeList: {
        loading: boolean,
        componentTypeList: ?any
    },
    filter: ?Function,
    onPress: ?Function,
    style: ?any,
}

class ComponentTypeList extends Component<Props> {

    render() {

        const {title, getComponentTypeList, filter, onPress, style} = this.props;

        let data = [];
        if (getComponentTypeList.componentTypeList && getComponentTypeList.componentTypeList.componentTypes) {
            data = getComponentTypeList.componentTypeList.componentTypes;
            if (filter) {
                data = data.filter(filter);
            }
        }

        return <List
            className="clickable-list"
            style={{
                background: Colors.white,
                ...style
            }}
            header={<div> {title}</div>}
            bordered
            itemLayout={'horizontal'}
            loading={getComponentTypeList.loading}
            dataSource={data}
            renderItem={item => (<List.Item
                onClick={() => {
                    if (onPress) {
                        onPress(item)
                    } else {
                    }
                }}
            >
                {
                    item.description ?
                        <Tooltip title={item.description} placement="bottomLeft">
                            <div className="flex-row" style={{width: '100%'}}>
                                <div className="flex-grow-1">
                                    <ComponentTypeIcon size={16} componentTypeIndex={item.index}/> <span
                                    style={{fontSite: 14, marginLeft: 8, marginTop: 2}}>{item.name}</span>
                                </div>
                                <div>
                                    {onPress ? <Icon type={"right"}/> : null}
                                </div>
                            </div>
                        </Tooltip>
                        :
                        <div className="flex-row" style={{width: '100%'}}>
                            <div className="flex-grow-1">
                                <ComponentTypeIcon size={16} componentTypeIndex={item.index}/> <span
                                style={{fontSite: 14, marginLeft: 8, marginTop: 2}}>{item.name}</span>
                            </div>
                            <div>
                                {onPress ? <Icon type={"right"}/> : null}
                            </div>
                        </div>
                }
            </List.Item>)}
        />;
    }

}


export default compose(
    graphql(componentTypeList, {
        name: 'getComponentTypeList',
    }),
)(ComponentTypeList);
