// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {Button} from "@dreebit/react-components";
import {Link, withRouter} from "react-router-dom";

import ContentHeader from "../../components/Typo/ContentHeader";
import RoleList from "../../components/RoleList";
import {compose} from "redux";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};

class RoleListRoute extends Component<Props> {
    render() {
        const {t, history} = this.props;

        return <NavigationContainer navigationBar={<ContentHeader title={t('Roles')} tools={
            <Link to={'/admin/roles/add'}>
                <Button icon={"plus"}>
                    {t('Add')}
                </Button>
            </Link>
        }/>} >
            <RoleList onClick={(record) => history.push(`/admin/roles/${record.id}`)}/>
        </NavigationContainer>
    }
}

export default compose(
    withResources(["administrateRoles"]),
    withRouter,
    withTranslation(),
)(RoleListRoute);
