// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import HostModulesQuery from './../../graphql/queries/HostModules.graphql';
import ModulesQuery from '../../graphql/queries/ModuleList.graphql';
import HostModuleListItem from "../ClientModuleListItem";
import type {Query} from "../../types";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Sizes from "../../themes/default/sizes";

type Props = {
    hostId: string,
    hostModulesQuery: Query,
    modulesQuery: Query,
};


class HostModules extends Component<Props> {


    render() {

        const {hostId, hostModulesQuery, modulesQuery} = this.props;
        const modules = _.get(modulesQuery, 'moduleList.modules');
        const activeModuleKeys = _.chain(hostModulesQuery)
            .get('host.modules')
            .map(item => (item.key))
            .value()
        ;

        return <div>
            {modules && modules.map(module => (
                <HostModuleListItem
                    clientId={hostId}
                    active={activeModuleKeys.indexOf(module.key) > -1}
                    module={module} style={{
                    margin: Sizes.grid
                }}/>
            ))
            }
        </div>;
    }
}

export default compose(
    graphql(ModulesQuery, {
        name: 'modulesQuery',
    }),
    waitWhileLoading('modulesQuery', 'moduleList.modules'),
    graphql(HostModulesQuery, {
        name: 'hostModulesQuery',
        options: (props: Props) => ({
            variables: {
                id: props.hostId
            }
        }),
    }),
    waitWhileLoading('hostModulesQuery', 'host.modules')
)(HostModules);
