// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import {Modal, Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ServiceReportQuery from "../../graphql/queries/serviceReport.graphql";
import ServiceReportListQuery from "../../graphql/queries/serviceReportList.graphql";
import TypeDefinitionAttributesQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql"
import CreateServiceReportPdfMutation from "../../graphql/mutations/createServiceReportPdf.graphql";
import UpdateServiceReportMutation from "../../graphql/mutations/updateServiceReport.graphql";
import DeleteServiceReportMutation from "../../graphql/mutations/deleteServiceReport.graphql"
import styled from "styled-components";
import {getServiceReportAttributeGroups} from "../../utils/attributeGroups";
import withResources from "../../hoc/withResources";
import TDAAttributesForm from "../TDAAttributesForm";


type Props = {
  serviceTicketId: string,
  serviceReportId: string,
  serviceReportQuery: query,
  createServiceReportPdfMutation: Function,
  updateServiceReportMutation: Function,
  deleteServiceReportMutation: Function,
  handleDeleteRedirect: Function,
  t: Function
};

type State = {
  visible: boolean
}

const Wrapper = styled.div`
  background: white;
  margin: 16px;
  padding: 10px;
`;

class ServiceReportDetail extends Component<Props, State> {

  state = {
    visible: false
  };

  handleDelete = () => {
    const {deleteServiceReportMutation, serviceTicketId, serviceReportId, handleDeleteRedirect} = this.props;

    deleteServiceReportMutation({
      variables: {serviceReportId},
      options: () => ({
        refetchQueries: [{
          query: ServiceReportListQuery,
          variables: {
            serviceTicketId
          }
        }]
      }),
    }).finally(
        handleDeleteRedirect()
    )
  };

  render() {
    const {
      serviceReportQuery,
      pdfAttributesQuery,
      createServiceReportPdfMutation,
      updateServiceReportMutation,
      t,
      serviceReportId
    } = this.props;

    let tdaAttributes = _.get(serviceReportQuery, 'serviceReport.typeDefinitionAttributeList.typeDefinitionAttributes', []);
    const categories = _.get(serviceReportQuery, 'serviceReport.attributeCategoryList.attributeCategories');
    const pdfAttributes = _.get(pdfAttributesQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes');

    return <>
      <Wrapper>
        <div className="flex-row justify-flex-end mb-20">
          <Button onClick={() => {
            this.setState({visible: true})
          }}>
            <Icon type={'printer'}/> {t('Print')}
          </Button>
          <Popconfirm
              title={t('Please confirm to delete this item')}
              icon={<Icon type="delete" style={{color: 'red'}}/>}
              onConfirm={this.handleDelete}
              okText={t('Confirm')}
              cancelText={t('Cancel')}
              placement="bottomRight"
          >
            <Button className="ml-10" type={'danger'}>
              <Icon type={'remove'}/> {t('Delete')}
            </Button>
          </Popconfirm>
        </div>
        <TDAAttributesForm
            blockNavigationOnUnsavedChanges={true}
            className={"submit-right"}
            attributes={tdaAttributes}
            groups={getServiceReportAttributeGroups(tdaAttributes, categories)}
            t={t}

            useSubmit={true}
            mutation={updateServiceReportMutation}
            translateMutationOptions={({values}) => ({
              variables: {
                serviceReportId,
                input: values
              }
          })}
        />
      </Wrapper>
      <Modal
        visible={this.state.visible}
        footer={null}
        onCancel={() => this.setState({visible: false})}
      >
        <div className={'mt-20'}>
          <TDAAttributesForm
            mutation={(args) => {
              const values = args.variables;
              createServiceReportPdfMutation({
                variables: {
                  serviceReportId,
                  input: values
                }
              }).then((res) => {
                window.open(_.get(res, 'data.createServiceReportPdf.link'), _.get(res, 'data.createServiceReportPdf.basename'))

              })
            }}
            attributes={pdfAttributes}
          />
        </div>
      </Modal>
    </>
  }
}

export default compose(
  withTranslation(),
  withResources(["serviceReports"]),
  graphql(ServiceReportQuery, {
    name: 'serviceReportQuery',
    options: (props: Props) => ({
      variables: {
        serviceReportId: _.get(props, 'serviceReportId')
      }
    })
  }),
  waitWhileLoading('serviceReportQuery', ["serviceReport"]),
  graphql(TypeDefinitionAttributesQuery, {
    name: "pdfAttributesQuery",
    options: (props) => ({
      variables: {
        index: 'serviceReportPdf',
        options: {serviceReportId: _.get(props, 'serviceReportId')}
      }
    })
  }),
  waitWhileLoading('pdfAttributesQuery', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
  graphql(CreateServiceReportPdfMutation, {
    name: "createServiceReportPdfMutation",
  }),
  graphql(UpdateServiceReportMutation, {
    name: "updateServiceReportMutation",
    options: (props: Props) => ({
      refetchQueries: [{
        query: ServiceReportListQuery,
        variables: {
          serviceTicketId: _.get(props, 'serviceTicketId')
        }
      }, {
        query: ServiceReportQuery,
        variables: {
          serviceReportId: _.get(props, 'serviceReportId')
        }
      }]
    }),
  }),
  graphql(DeleteServiceReportMutation, {
    name: "deleteServiceReportMutation",
    options: (props: Props) => ({
      refetchQueries: [{
        query: ServiceReportListQuery,
        variables: {
          serviceTicketId: _.get(props, 'serviceTicketId')
        }
      }]
    }),
  }),
)(ServiceReportDetail);
