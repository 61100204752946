// @flow
import React, {Component} from "react"
import _ from 'lodash';
import ClientDashboard from "./ClientDashboard";
import Error from "../../components/Error";
import withResources from "../../hoc/withResources";

type Props = {
    match: any
}

class ClientDashboardRoute extends Component<Props> {

    render() {

        const {match} = this.props;
        const clientId = _.get(match, 'params.id');
        const tab = _.get(match, 'params.tab', 'form');

        if (!clientId) {
            return <Error type="404"/>
        }

        return <ClientDashboard tab={tab} clientId={clientId}/>;
    }

}

export default ClientDashboardRoute;
