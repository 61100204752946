// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import AttributeCategoryQuery from "../../graphql/queries/attributeCategory.graphql";

const CategoryBreadcrumb = ({attributeCategoryQuery, t}) =>
    <span>{_.get(attributeCategoryQuery, 'attributeCategory.name', t('Unknown'))}</span>;

export default compose(
    graphql(AttributeCategoryQuery, {
        name: 'attributeCategoryQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.id'),
            }
        })
    }),
)(withTranslation()(CategoryBreadcrumb));
