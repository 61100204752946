// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import CompanyFormQuery from "./../../graphql/queries/CompanyForm.graphql"
import UpdateCompanyMutation from "./../../graphql/mutations/UpdateCompany.graphql"
import _ from 'lodash'
import waitWhileLoading from "../../hoc/waitWhileLoading";
import TDAAttributesForm from "../TDAAttributesForm";
import {getAttributeGroups} from "../../utils/attributeGroups";
import {withTranslation} from "react-i18next";

type Props = {

};

type State = {

}

class CompanyForm extends Component<Props, State> {

  render() {
    const { attributes, updateCompanyMutation, t} = this.props;

    const groupWhitelist = ["default", "address", "company", "contact", "rmaConfig"]
    let groups = getAttributeGroups(attributes, t);
    groups = groups.filter(group => groupWhitelist.includes(group.index))

    return <TDAAttributesForm
        className={"submit-right"}
        useTabs
        attributes={attributes}
        groups={groups}
        translateMutationOptions={({changes}) => {
            return {
                variables: {
                    input: changes
                }
            }
        }}
        mutation={updateCompanyMutation}
    />
  }
}

export default compose(
    withTranslation(),
    graphql(CompanyFormQuery, {
        name: 'companyFormQuery',
        options: (props) => ({

        })
    }),
    waitWhileLoading('companyFormQuery'),
    withProps((props) => ({
        attributes: _.get(props,'companyFormQuery.company.typeDefinitionAttributeList.typeDefinitionAttributes',[])
    })),
    graphql(UpdateCompanyMutation, {
        name: 'updateCompanyMutation'
    })
)(CompanyForm);
