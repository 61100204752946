// @flow
import React, {Component} from 'react';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import queryString from "querystring";
import {withTranslation} from "react-i18next";
import {Button} from "@dreebit/react-components";
import ContentHeader from "../../components/Typo/ContentHeader";
import ManufacturerListComponent from "../../components/ManufacturerListComponent/list";
import ManufacturerDashboard from "./manufacturerDashboard";
import ManufacturerCreate from "../../components/ManufacturerCreate";
import {Col, Grow, Row} from "../../components/Flex";
import NavigationContainer from "../../components/NavigationContainer";
import {compose} from "recompose";
import ManufacturerMatchRoute from './../../components/ManufacturerMatchRoute';

type Props = {
    t: Function,
    history: Function,
};


class ManufacturersMasterDetail extends Component<Props, State> {
    render() {
        const {t, history, filter, setQuery} = this.props;

        console.log(filter);

        return (
            <Row className={'full-height full-width'}>
                <Col className={'full-height'} style={{borderRight: "1px solid #cfdbe2", minWidth: 'fit-content'}}>
                    <NavigationContainer navigationBar={<ContentHeader title={t('Manufacturer')}
                                                                       hideBreadcrumb
                                                                       tools={<div>
                                                                           <Link to={'/catalog/manufacturer/create'}>
                                                                               <Button icon={"plus"}>
                                                                                   {t('Add')}
                                                                               </Button>
                                                                           </Link>
                                                                       </div>}/>}>
                        <ManufacturerListComponent
                            filter={filter}
                            onChange={(values, {allValues}) => {

                                return;
                                const query = queryString.stringify(allValues);
                                setQuery(query);
                                history.push({
                                    search: query
                                })
                            }}
                        />
                    </NavigationContainer>

                </Col>
                <Grow style={{height: '100%'}}>
                    <Switch>
                        <Route
                            path='/catalog/manufacturer/create'
                            component={ManufacturerCreate}
                        />

                        <Route
                            exact path='/catalog/manufacturer/:id'
                            component={ManufacturerDashboard}
                        />
                        <Route
                            exact path='/catalog/manufacturer/:id/match'
                            component={ManufacturerMatchRoute}
                        />
                        <Redirect exact from="/catalog/manufacturer" to="/catalog/manufacturer/create"/>
                    </Switch>
                </Grow>
            </Row>
        )
    }
}

export default compose(
    withTranslation(),
    /*
    withRouter,
    withProps((props) => {
        let query = _.get(props, 'history.location.search');
        return {
            query
        }
    }),
    connect((state, props) => {

        let query = props.query;
        console.log(query,query.length);
        if (!query || !query.length) {
            query = _.get(state, 'settings.manufacturerSearchQuery');
        }

        return {
            query,
        }
    }, dispatch => ({
        setQuery: (query) => {
            return dispatch(setManufacturerSearchQuery(query))
        }
    })),
    withProps((props) => ({
        filter: queryString.parse(props.query)
    }))

     */
)(ManufacturersMasterDetail);

