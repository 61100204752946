// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

import AttributesInlineForm from "../Form/attributes-inline-form";

type Props = {
  onChange: Function,
  values: any,
  t: Function,
  disableStatus?: boolean,
};


class ServiceBudgetToolbar extends Component<Props, State> {
  render() {
    const {onChange, values, t} = this.props;

    const attributes = [
      {
        index: 'insert',
        type: "object",
        inputType: "daterangepicker",
        name: t('date range'),
        format: 'LL'
      },
    ];

    return <AttributesInlineForm
      attributes={attributes}
      layout={'inline'}
      useSubmit={false}
      onChange={(attributeValues, allValues) => {
        if (onChange)
          onChange(attributeValues, allValues)
      }}
      values={values}
    />
  }
}

export default withTranslation()(ServiceBudgetToolbar)
