// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PortletQuery from "../../graphql/queries/portlet.graphql"

const DashboardBreadcrumb = ({query, t}) =>
    <span>{_.get(query, 'userPortlet.title', t('Unknown'))}</span>;

export default compose(
    graphql(PortletQuery, {
        name: 'query',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.dashboardId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(DashboardBreadcrumb));
