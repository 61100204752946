// @flow
import React, {Component} from 'react';
import Editable from "../Editable";

type Props = {
    value: any,
    mutation: Function,
    renderDisplay?: Function,
    onDone: (val: any) => void,
    translateMutationOptions?: (value: string) => any
};

type State = {
    loading: false
}

class EditableMutation extends Component<Props, State> {

    state = {
        loading: false
    };

    render() {

        const {renderDisplay} = this.props;
        const {loading} = this.state;

        return <Editable
            value={ this.props.value }
            renderDisplay={renderDisplay}
            loading={loading || this.state.loading}
            onChange={(val) => {
                let options = null;
                if (this.props.translateMutationOptions){
                    options =  this.props.translateMutationOptions(val)
                }
                this.setState({
                    loading: true
                })
                this.props.mutation(options).then((res) => {
                    this.setState({
                        loading: false
                    })
                    if (this.props.onDone){
                        this.props.onDone(res, val)
                    }
                })
            }}
        />
    }
}


export default EditableMutation;
