import React from 'react';
import {Alert, Button} from "antd";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import _ from "lodash";
import CreateDocuSignEnvelopeMutation from "../../graphql/mutations/createDocuSignEnvelope.graphql";
import FinalizeContaminationDeclarationMutation from "../../graphql/mutations/finalizeContaminationDeclaration.graphql";


interface Props {
    state: "signing_complete" | "cancel" | "decline" | "notFinalized",
    serviceTicket: any,
    onCloseModal: () => void
}

const ServiceTicketDocuSignModal = ({state, serviceTicket, onCloseModal}: Props) => {

    const {t} = useTranslation();

    const [finalizeContaminationDeclarationMutation] = useMutation(FinalizeContaminationDeclarationMutation)
    const [createDocuSignEnvelopeMutation] = useMutation(CreateDocuSignEnvelopeMutation)

    const finalizeContaminationDeclaration = () => {
        finalizeContaminationDeclarationMutation({
            variables: {
                contaminationDeclarationId: _.get(serviceTicket, 'contaminationDeclaration.id')
            }
        }).then((res) => {
            if (_.get(res, 'data.finalizeContaminationDeclaration.finalized')) {
                window.open(_.get(res, 'data.finalizeContaminationDeclaration.print.sheet.link'), '_blank');
                onCloseModal();
            }
        })
    }

    const openDocuSign = () => {
        createDocuSignEnvelopeMutation({
            variables: {
                serviceTicketId: _.get(serviceTicket, 'id')
            }
        }).then((res) => {
            if (_.get(res, 'data.createDocuSignEnvelope.link')) {
                window.open(_.get(res, 'data.createDocuSignEnvelope.link'), '_blank');
                onCloseModal();
            }
        })
    }

    let textBlock = null;

    switch (state){
        case 'notFinalized' :
            textBlock = <>
                <div>
                    {/*{t('You can now sign your declaration of contamination. You can print out the document, sign it and enclose it with the product. If you want to sign the document digitally, use our DocuSign® solution.')}*/}
                    {t('You have two options to sign your contamination declaration. The first option is to print the document, sign it, and include it with the product. Alternatively, you can digitally sign the document using our DocuSign solution.')}
                    <div className={"flex flex-end mt-15"}>
                        <Button className={"mr-5"} onClick={finalizeContaminationDeclaration}>
                            {t('Print')}
                        </Button>
                        <Button type={"primary"} onClick={openDocuSign}>
                            {t('Sign via DocuSign®')}
                        </Button>
                    </div>
                </div>
            </>
            break;
        case 'signing_complete':
            textBlock = <Alert
                type={"success"}
                message={<div style={{textAlign: 'center'}}>
                    {t("You successfully signed your declaration of contamination!")}
                </div>}
            />
            break;
        case 'decline':
            textBlock = <Alert
                type={"warning"}
                message={<>
                    <div style={{textAlign: 'center'}}>
                        {t("You can finish signing your declaration of decontamination later")}
                    </div>
                    <div style={{textAlign: 'center'}}>
                        {t("You will receive a mail from DocuSign for signing your declaration of decontamination later")}
                    </div>
                </>}
            />
            break;
        case 'cancel':
            textBlock = <Alert
                type={"warning"}
                message={<>
                    <div style={{textAlign: 'center'}}>
                        {t("You canceled signing your declaration of decontamination")}
                    </div>
                    <div style={{textAlign: 'center'}}>
                        {t("You have received your unsigned decontamination declaration by mail Please sign it by hand and send it to us with your package")}
                    </div>
                </>}
            />
            break;
        default:
            textBlock = <>
            </>
            break;
    }

    return (
        <div>
            {textBlock}
        </div>
    );
}

export default ServiceTicketDocuSignModal;
