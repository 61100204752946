// @flow
import React, { Component } from 'react';
import _ from "lodash";
import TDAAttributesForm from "../TDAAttributesForm";
import {compose} from "recompose";
import {connect} from "react-redux";
import {saveForm} from "../../actions/settings";

type Props = {
    index: string,
};

type State = {

}

class ReduxAttributeForm extends Component<Props, State> {

  render() {
    const { save, values, index } = this.props;

    return <TDAAttributesForm
        {...this.props}
        cacheChanges
        values={values}
        translateMutationOptions={({values}) => {
            return values;
        }}
        mutation={(values) => {

            return new Promise((resolve, reject) => {
                const allValues = {
                    ...this.props.values,
                    ...values,
                }
                save(allValues)
                resolve(allValues);
            })
        }}
    />
  }
}

export default compose(
    connect(({settings}, props) => {

        return {
            values: {
                ..._.get(settings,`forms.${props.index}`,{})
            }
        }
    }, (dispatch, props) => {
        return {
            save: (values) => dispatch(saveForm(props.index, values))
        }
    }),
)(ReduxAttributeForm);
