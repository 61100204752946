// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import _ from 'lodash';
import Error from "../../components/Error";
import InvoiceDetail from "../../components/InvoiceDetail";
import {withRouter} from "react-router-dom";
import withResources from "../../hoc/withResources";

type Props = {
  match: {
    params: any
  },
  history: {
    push: Function
  },
  t: Function,
}

class InvoiceDetailRoute extends Component<Props> {


  render() {

    const {match} = this.props;
    const invoiceId = _.get(match, 'params.id');
    if (!invoiceId) {
      return <Error status={'404'}/>
    }
    return <InvoiceDetail invoiceId={invoiceId}/>
  }

}

export default compose(
  withResources('accounting'),
  withRouter,
  withRouter,
  withTranslation(),
)(InvoiceDetailRoute);
