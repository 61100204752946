// @flow
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {message} from "antd";
import {Button} from "@dreebit/react-components";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";

import waitWhileLoading from "../../hoc/waitWhileLoading";
import ContentHeader from "../../components/Typo/ContentHeader";
import ConnectorDashboard from "../../components/ConnectorDashboard";
import AccessToken from "../../graphql/queries/accessToken.graphql";
import DeleteAccessToken from "../../graphql/mutations/deleteAccessToken.graphql";
import AccessTokenList from "../../graphql/queries/accessTokenList.graphql";
import {withRouter} from "react-router-dom";

type Props = {
    match: {
        params: {
            id: string,
        }
    },
    history: {
        push: Function,
    },
    t: Function,
    accessTokenQuery: query,
    deleteAccessTokenMutation: Function,
}

class ProfileConnectorDetail extends Component<Props, State> {

    handleDelete = () => {
        const {deleteAccessTokenMutation, history, match} = this.props;
        deleteAccessTokenMutation({variables: {id: match.params.id}}).then((res) => {
            message.success(res.data.deleteAccessToken.message);
            history.push('/profile/connectors');
        })
    };

    render() {
        const {t, accessTokenQuery, match} = this.props;

        const title = _.get(accessTokenQuery, 'accessToken.name', t('undefined'));

        return <div>
            <ContentHeader title={title} tools={
                <Button
                    type={'danger'}
                    onClick={this.handleDelete}>{t('Delete')}
                </Button>
            }/>
            <ConnectorDashboard id={match.params.id}/>
        </div>;
    }
}

export default compose(
    withRouter,
    graphql(AccessToken, {
        name: 'accessTokenQuery',
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        }),
    }),
    waitWhileLoading('accessTokenQuery', 'accessToken'),
    graphql(DeleteAccessToken, {
        name: 'deleteAccessTokenMutation',
        options: () => ({
            refetchQueries: [{
                query: AccessTokenList,
                variables: {
                    params: {
                        filter: {
                            type: {
                                operator: 'in',
                                value: 'apiToken'
                            }
                        }
                    }
                }
            }]
        })
    }),
    withTranslation(),
)(ProfileConnectorDetail);
