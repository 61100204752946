// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {compose, withProps} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import ContentHeader from "../Typo/ContentHeader";
import ServiceProviderDashboardQuery from "./../../graphql/queries/ServiceProviderDashboard.graphql";
import DeleteServiceProviderMutation from "./../../graphql/queries/deleteServiceProvider.graphql";
import ServiceProviderListQuery from "./../../graphql/queries/serviceProviderList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {message, Popconfirm, Tabs} from "antd";
import {Button} from "@dreebit/react-components";
import Strings from "../../i18n/strings";
import Error from "../Error";
import ServiceProviderUpdateForm from "../ServiceProviderUpdateForm";
import {Route, Switch, withRouter} from "react-router-dom";
import statics from "../../statics";
import withProfile from "../../hoc/withProfile";
import ServiceProviderModules from "../ServiceProviderModules";
import ServiceProviderRoleList from "../ServiceProviderRoleList";
import ServiceProviderUsersDashboard from "../ServiceProviderUsersDashboard";
import NavigationContainer from "../NavigationContainer";


type Props = {
    serviceProviderId: string,
    t: Function,
    serviceProvider: any,
    serviceProviderPublishMutation: Function,
    deleteServiceProviderMutation: Function,
    onDelete: ?Function,
    derviceProviderDashboardQuery: query,
    history: {
        push: Function,
    },
    resources?: any
};

type State = {
    removing: boolean,
    publishing: boolean,
}

const TabPane = Tabs.TabPane;

class ServiceProviderDashboard extends Component<Props, State> {

    state = {
        removing: false,
        publishing: false
    };

    handleDelete = () => {
        const {t, deleteServiceProviderMutation, onDelete} = this.props;

        this.setState({
            removing: true,
        }, () => {
            deleteServiceProviderMutation().then((res) => {
                message.success(_.get(res, 'data.message') || t('ServiceProvider removed'));
                setTimeout(() => {
                    if (onDelete) {
                        onDelete(res);
                    }
                }, 0)
            }).catch(() => {
                message.error(Strings.errorOccurred);
            }).finally(() => {
                this.setState({
                    removing: false,
                })
            })
        });
    };

    render() {
        const {t, serviceProvider, resources, serviceProviderId} = this.props;

        if (!serviceProvider) {
            return <Error/>
        }

        const header =  <ContentHeader title={serviceProvider.name}
                                       tools={<Popconfirm placement="bottomRight" key={"remove"} onConfirm={this.handleDelete}
                                                          title={t(Strings.areYouSure)}>
                                           <Button type={'danger'} loading={this.state.removing}>{t('Delete')}</Button>
                                       </Popconfirm>}/>;

        let tabs = [<TabPane tab={t('Core Data')} key="form">

        </TabPane>];

        if (resources.indexOf("administrateServiceProviderModules") !== -1) {
            tabs.push(<TabPane tab={t('Modules')} key="modules">

            </TabPane>)
        }

        if (resources.indexOf("administrateServiceProviderRoles") !== -1) {
            tabs.push(<TabPane tab={t('Roles')} key="roles">
                <ServiceProviderRoleList serviceProviderId={serviceProviderId}/>
            </TabPane>);
        }

        if (resources.indexOf("administrateServiceProviderUsers") !== -1) {
            tabs.push(<TabPane tab={t('User')} key="user">
                <ServiceProviderUsersDashboard serviceProviderId={serviceProviderId}/>
            </TabPane>)
        }



        return <div>
            <NavigationContainer navigationBar={
                <div className={'full-height-from-content-header bg-white ph-10'}>
                    {header}
                    <Tabs
                        onChange={this.handleTabChange}>
                        {tabs}
                    </Tabs>

                </div>
            }>
                <Switch>
                    <Route render={() => <ServiceProviderUpdateForm serviceProviderId={serviceProviderId}/>}/>
                    <Route render={() => <ServiceProviderModules serviceProviderId={serviceProviderId}/>} />
                    <Route render={() => <ServiceProviderRoleList serviceProviderId={serviceProviderId}/>} />
                    <Route render={() => <ServiceProviderUsersDashboard serviceProviderId={serviceProviderId}/>} />
                </Switch>
            </NavigationContainer>

        </div>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(DeleteServiceProviderMutation, {
        name: 'deleteServiceProviderMutation',
        options: (props) => ({
            variables: {
                id: props.serviceProviderId
            },
            refetchQueries: [{
                query: ServiceProviderListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                    }
                }
            }]
        })
    }),
    graphql(ServiceProviderDashboardQuery, {
        name: 'serviceProviderDashboardQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceProviderId
            }
        }),
    }),
    waitWhileLoading('serviceProviderDashboardQuery', 'serviceProvider'),
    withProps((props) => ({
        serviceProvider: _.get(props, 'serviceProviderDashboardQuery.serviceProvider')
    })),
    withProfile()
)(ServiceProviderDashboard);
