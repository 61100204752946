import _ from "lodash";
import ProfileQuery from "./../graphql/queries/profile.graphql";
import {useQuery} from "@apollo/client";

export default function useProfile() {

    const {data} = useQuery(ProfileQuery);

    return _.get(data,'profile');
};
