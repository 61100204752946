// @flow
import React, { Component } from 'react';
import MultiActionWrapper from "../MultiActionWrapper";
import {compose} from "recompose";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import {withTranslation} from "react-i18next";
import FinalizeFinalTestMutation from "../../graphql/mutations/finalizeFinalTest.graphql"
import {Alert} from "antd";
import {Button} from "@dreebit/react-components";

type Props = {
  serviceTicketIds: string[],
  onFinished: () => void,
};

class ServiceTicketsFinalizeFinalTest extends Component<Props> {
  componentDidMount() {}

  render() {
    const { onFinished, serviceTicketIds, t, finalizeFinalTestMutation} = this.props;

    return <MultiActionWrapper
      onFinished={onFinished}
      total={serviceTicketIds.length}
      mutation={finalizeFinalTestMutation}>
      {({queue}) => {
        return <div>
          <Alert message={t('The final test will be finalized for all selected service tickets')} type="info" showIcon />
          <div className={'flex-row flex-center'}>
            <Button
              type={'primary'}
              className={"mt-10"}
              onClick={() => {
                serviceTicketIds.forEach((serviceTicketId) => {
                  queue.push({
                    variables: {serviceTicketId: serviceTicketId}
                  })
                }
              )}}
            >
              {t('finalizeServiceTickets', {count: serviceTicketIds.length})}
            </Button>
          </div>
        </div>
      }}
    </MultiActionWrapper>
  }
}

export default compose(
  graphql(FinalizeFinalTestMutation, {
    name: 'finalizeFinalTestMutation'
  }),
  withApollo,
  withTranslation(),
)(ServiceTicketsFinalizeFinalTest)
