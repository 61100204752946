// @flow
import React, {Component} from "react"
import updateProduct from "./../../graphql/mutations/updateProduct.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {getAttributeGroups} from "../../utils/attributeGroups";
import ProductFormQuery from "../../graphql/queries/productForm.graphql";

type Props = {
    id: ?string,
    updateProductMutation: Function,
    productDashboardQuery: query,
    t: Function,
}


class ProductCreate extends Component<Props, State> {

    render() {
        const {updateProductMutation, id, productFormQuery, t} = this.props;

        const attributeFilter = _.get(productFormQuery, 'product.typeDefinitionAttributeList.typeDefinitionAttributes',[]).filter((item) => item.index !== 'imageId');

        const groups = getAttributeGroups(attributeFilter, t);

        return <AttributesInlineForm
            useTabs
            inline
            groups={groups}
            attributes={attributeFilter}
            layout={'horizontal'}
            t={t}
            mutation={updateProductMutation}
            translateMutationOptions={({values, changes}) => {

                return {
                    variables: {
                        id: id,
                        attributes: values
                    }
                }}
            }
        />;
    }

}

export default compose(
    graphql(ProductFormQuery, {
        name: 'productFormQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    graphql(updateProduct, {
        name: 'updateProductMutation',
    }),
    withTranslation(),
)(ProductCreate);
