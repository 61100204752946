// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import CreateBookmarkMutation from "./../../graphql/mutations/createBookmark.graphql";
import ComponentName from "./../../graphql/queries/component-Name.graphql";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {
    BOOKMARK_TYPE_INFRASTRUCTURE_DETAIL,
    BOOKMARK_TYPE_INFRASTRUCTURE_NAVIGATION,
    getBookmarkInput
} from "../../utils/bookmark";
import BookmarkListQuery from "../../graphql/queries/bookmarkList.graphql";
import {bookmarkListVariables} from "../BookmarkList";
import _ from "lodash";

type Props = {
    createBookmarkMutation: Function,
    onCreated: ?Function,
    client?: any,
};


class InfastructureDashboardBookmarkCreatePanel extends Component<Props, State> {


    render() {

        const {t, createBookmarkMutation, client} = this.props;

        return <AttributesInlineForm
            layout={"vertical"}
            submitTitle={t('Add')}
            attributes={[{
                name: t('Type'),
                index: 'type',
                type: 'string',
                inputType: 'radio',
                rules: [{name: "required"}],
                optionValues: [
                    {
                        value: BOOKMARK_TYPE_INFRASTRUCTURE_DETAIL,
                        name: t("Details Bookmark")
                    },
                    {
                        value: BOOKMARK_TYPE_INFRASTRUCTURE_NAVIGATION,
                        name: t("Navigation Bookmark")
                    }
                ]
            }, {
                name: t('Component'),
                rules: [{name: "required"}],
                index: 'componentId',
                type: 'string',
                inputType: 'COMPONENT',
                entityType: 'component',
                inputTypeOptions: {
                    useNavigator: false
                }

            }]}
            useSubmit={true}
            onSubmit={(res) => {
                if (this.props.onCreated) {
                    this.props.onCreated(res);
                }
            }}
            mutation={createBookmarkMutation}
            translateMutationOptions={({values}) => {

                const {component} = client.cache.readQuery({
                    query: ComponentName,
                    variables: {
                        id: values.componentId
                    }
                });

                return {
                    variables: {
                        input: getBookmarkInput(values.type, {
                            title: `${component.name}`,
                            componentId: values.componentId
                        })
                    }

                }
            }}
        />;
    }
}

export default compose(
    withTranslation(),
    withApollo,
    graphql(CreateBookmarkMutation, {
        name: 'createBookmarkMutation',
        options: () => ({
            refetchQueries: [
                {
                    query: BookmarkListQuery,
                    variables: bookmarkListVariables(["infrastructureDetail", "infrastructureSearch", "componentSelection"])
                }
            ]
        })
    })
)(InfastructureDashboardBookmarkCreatePanel);
