// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import HostDetailQuery from "../../graphql/queries/HostDetailQuery.graphql"

const HostBreadcrumb = ({hostDetailsQuery}) => <span>{_.get(hostDetailsQuery, 'host.name', '...')}</span>;

export default compose(
    graphql(HostDetailQuery, {
        name: 'hostDetailsQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.hostId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(HostBreadcrumb));
