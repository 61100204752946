import React from "react";
import Gif from "./VSM_loading.gif";
import pvGif from "./VSM_loading_PV.gif";
import useTheme from "../../hooks/useTheme";

function VSMLoadingAnimation() {

    const pvThemes = ["pv", "pvuk"]
    const {theme} = useTheme();

    return <div
        className={"white-bg"}
        style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            textAlign: 'center',
            display: "flex",
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
        <div>
            <img
                src={pvThemes.indexOf(theme) > -1 ? pvGif : Gif}
                alt="Loading animation"
                width={300}
            />
        </div>
    </div>
}

export default VSMLoadingAnimation;
