// @flow
import React, { Component } from 'react';
import {Carousel} from 'antd';
import A_DoneServiceTickets from "./kpi_components/a_doneServiceTickets";
import ServiceTicketsPerMonth from "./serviceTicketsPerMonth";
import ServiceTicketsPerWeek from "./serviceTicketsPerWeek";
import ServiceTicketsPerYearProportional from "./serviceTicketsPerYearProportional"
import './kpiStyles.less';
import KpiPanel from "../KpiPanel";
import FunctionalBlockRecons from "./functionalBlockRecons";
import ServiceTicketWarrantyStatus from "./serviceTicketWarrantyStatus";
import ServiceTicketLeadTime from "./serviceTicketLeadTime";

type Props = {

};

type State = {

}

class KpiDashboard extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const {  } = this.props;

    let userParams = {
      dateRange: {
        from: "2019-02",
        to: "2020-02"
      }
    }

    return <Carousel>
      <div className="full-height">
        <div className="flex flex-row">
          <KpiPanel title={'A - Anzahl der erledigten Servicetickets'} width={3} height={1.6}>
            <A_DoneServiceTickets width={3} height={1.6} userParams={userParams}/>
          </KpiPanel>
          <KpiPanel title={'Externe Servicetickets der letzten 12 Monate'} width={1.8} height={1.6}>
            <ServiceTicketsPerYearProportional width={1.8} height={1.6} />
          </KpiPanel>
        </div>

        <div className="flex flex-row">
          <KpiPanel title={'Servicetickets August 2019'} width={2.4} height={1.1}>
            <ServiceTicketsPerMonth width={2.4} height={1.1} />
          </KpiPanel>
          <KpiPanel title={'Servicetickets KW 19/20 2019'} width={2.4} height={1.1}>
            <ServiceTicketsPerWeek width={2.4} height={1.1} />
          </KpiPanel>
        </div>
      </div>

      <div className="full-height">

        <div className="flex flex-row">
          <KpiPanel title={'Gewährleistungsfälle 2019'} width={2.1} height={1.5}>
            <ServiceTicketWarrantyStatus/>
          </KpiPanel>
        </div>

        <div className="flex flex-row">
          <KpiPanel title={'Blockaustausche 2019'} width={1} height={0.7}>
            <FunctionalBlockRecons
              daterange={{from: "2019-01-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
              width={0.7}
              height={0.7}
            />
          </KpiPanel>
          <KpiPanel title={'Blockaustausche Dez. 2019'} width={1} height={0.7}>
            <FunctionalBlockRecons
              daterange={{from: "2019-12-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
              width={0.7}
              height={0.7}
            />
          </KpiPanel>
        </div>

        <div className={'flex flex-row'}>
          <KpiPanel title={'Avg Leadtime 2019'} width={1} height={0.7}>
            <ServiceTicketLeadTime
              userParams={{dateRange: {from: "2019-01-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}, componentTypes: ['rotaryVanePumpTwoStage']}}
              width={0.7}
              height={0.7}
            />
          </KpiPanel>
        </div>

      </div>
    </Carousel>
  }
}

export default KpiDashboard;
