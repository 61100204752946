// @flow
import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import DashboardPortletSettingsRoute from "./settings";
import Home from "./home";
import DashboardManagePortlets from "./dashboard-manage-portlets";
import Error404 from "../errorPages/error404";

type Props = {};

type State = {}

class DashboardRoute extends Component<Props, State> {

    render() {
        const {} = this.props;

        return <Switch>
            <Route exact path="/dashboard" component={Home}/>
            <Route path="/dashboard/manage" component={DashboardManagePortlets}/>
            <Route path="/dashboard/:id" component={DashboardPortletSettingsRoute}/>
            <Route component={Error404}/>
        </Switch>;
    }
}

export default DashboardRoute;
