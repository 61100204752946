// @flow
import React from 'react';
import classNames from 'classnames';
import loaderStyles from './Loader.css';
import {Spin} from "antd";
import {Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";

type Props = {
    t: Function,
    spinning?: boolean,
    mask?: boolean,
    fullScreen?: boolean,
    title?: string,
    size?: string,
    absolute?: boolean,
    useIcon?: boolean,
    hideTitle?: boolean,
    styles?: any,
}

class Index extends React.Component<Props> {
    static defaultProps = {
        spinning: true,
        fullScreen: false,
        useIcon: false,
    };

    render() {

        const {spinning, fullScreen, size, absolute, mask, t, useIcon, styles, hideTitle} = this.props;

        if (useIcon) {
            const antIcon = <Icon type="loading" style={styles} spin/>;
            return <Spin indicator={antIcon}/>
        }

        if (size === 'small') {
            const spin = <Spin size={'small'} className={classNames('loader small', {
                mask,
                [loaderStyles.absolute]: absolute,
            })}/>;
            if (mask) {
                return <div
                    className={classNames('loader small', {
                        mask,
                        absolute: absolute || size === 'fullScreen',
                        hidden: !spinning,
                        fullScreen: fullScreen || size === 'fullScreen',
                    })}
                >
                    {spin}
                </div>
            }
            return spin
        }

        return (
            <div
                className={classNames('loader large white-bg', {
                    mask,
                    absolute: absolute || size === 'fullScreen',
                    hidden: !spinning,
                    fullScreen: fullScreen || size === 'fullScreen',
                })}
            >
                <div className={'wrapper'}>
                    <div className={'inner'}/>
                    {!hideTitle ? <div className={'text'}>{this.props.title || t('Loading')}</div> : null}

                </div>
            </div>
        );
    }
}

export default withTranslation()(Index);

