import React from "react"
import NotificationPopover from "../../components/NotificationPopover";
import {Icon} from "@dreebit/react-components";
import MaterialCommunityIcon from "../../components/Icons/MaterialCommunityIcon";
import MessagesIcon from "../../components/MessagesIcon";
import _ from "lodash";
import externalUrls from "../../statics/externalUrls";

const getRightMenu = (theme: string) => {
    let items = [
        {
            id: 'notifications',
            name: 'Notifications',
            custom: () => <NotificationPopover theme={theme}/>
        },
        {
            id: 'messages',
            name: 'Messages',
            route: '/messages',
            icon: <MessagesIcon theme={theme} />,
            resources: ["conversation"]
        },
        {
            id: 'profile',
            name: 'Profile',
            route: '/profile',
            icon: theme === 'pv'
                ? <MaterialCommunityIcon size={20} name={"account"}/>
                : <Icon type={"user"}/>
        }];



    if (theme === 'pv') {
        const environment =  _.get(window["__BE__"], 'env', "production");
        const shopLink = _.get(externalUrls[environment], 'pvWebsite') || externalUrls["production"].pvWebsite;

        items.push({
            id: 'shop',
            name: 'Shop',
            link: shopLink,
            icon: <MaterialCommunityIcon size={20} name={"store"}/>
        });
    }

    return [
        ...items,
        {
            id: 'admin',
            name: 'Settings',
            route: '/admin',
            icon: theme === 'pv'
                ? <MaterialCommunityIcon size={20} name={"cog"}/>
                : <Icon type={"SettingOutlined"}/>,
            resources: [
                'accounting',
                'administrateClients',
                'administrateCompany',
                'administrateCustomAttributes',
                'administrateRoles',
                'administrateServiceActionTypePrivileges',
                'administrateServiceCenters',
                'administrateServiceProviders',
                'administrateTCO',
                'administrateUsers',
                'clientAccessRequest',
                'developer',
                'logistician',
                'newsEditor',
                'statistics',
            ]
        },
    ];
};

export default getRightMenu;
