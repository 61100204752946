// @flow
import React from "react"
import {useTranslation} from "react-i18next";
import _ from "lodash";
import CustomAttributeFormAttributesQuery from "../../graphql/queries/customAttributeForm.graphql";
import CustomAttributeUpdateMutation from "../../graphql/mutations/updateCustomAttribute.graphql";
import AttributeInlineForm from "./../Form/attributes-inline-form";
import Sizes from "../../themes/default/sizes";
import customAttributeDetails from "../../graphql/queries/customAttributeDetails.graphql";
import {useMutation, useQuery} from "@apollo/client";
import {Loader} from "@dreebit/react-components";

const blacklist = [];

const attributeConfigs = {
    "searchable": {
        "categories": ["custom-attribute-options"],
    },
    "versionable": {
        "categories": ["custom-attribute-options"],
    },
    "exportable": {
        "categories": ["custom-attribute-options"],
    },
    "componentCreate": {
        "categories": ["custom-attribute-options"],
    },
    "optionValues": {
        "categories": ["custom-attribute-options"],
    },
    "sharedClients": {
        "inputType": "select",
        "categories": ["client"],
        "condition": (form) => {
            return !form.getFieldValue("sharedForAllClients")
        }
    },
    "sharedForAllClients": {
        "categories": ["client"],
    },
    "sharedServiceProviders": {
        "inputType": "select",
        "categories": ["serviceProvider"],
        "condition": (form) => {
            return !form.getFieldValue("sharedForAllServiceProviders")
        }
    },
    "sharedForAllServiceProviders": {
        "categories": ["serviceProvider"],
    },
    "componentTypes": {
        "inputType": "tree",
        "categories": ["componentTypes"],
    },
};

type Props = {
    index: any
};

export const CustomAttributeForm = ({index}: Props) => {
    const {t} = useTranslation();
    const customAttributeForm = useQuery(CustomAttributeFormAttributesQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            index: index
        }
    });
    const [customAttributeUpdate] = useMutation(CustomAttributeUpdateMutation, {
        refetchQueries: [{
            query: customAttributeDetails,
            variables: {
                index: index
            }
        }]
    });

    if (customAttributeForm.loading) return <Loader/>;

    return <AttributeInlineForm
        mutation={customAttributeUpdate}
        translateMutationOptions={(formValues: any) => {
            const {changes, attributes}: any = formValues;

            if (changes.optionValues) {
                const optionValuesAttribute = _.find(attributes, {index: 'optionValues'});
                const nameAttribute = _.find(optionValuesAttribute.attributes, {index: 'name'});
                if (nameAttribute) {
                    changes.optionValues = changes.optionValues.map((optionValue) => {

                        const defaultTranslationLang = _.first(Object.keys(optionValue.name));
                        return {
                            ...optionValue,
                            name: nameAttribute.languages.map((language) => language.value).reduce((acc, languageKey) => {
                                if (!acc[languageKey]) {
                                    acc[languageKey] = optionValue["name"][languageKey] || optionValue["name"][defaultTranslationLang]
                                }
                                return acc;
                            }, optionValue.name)
                        }
                    })
                }
            }

            return {
                variables: {
                    input: changes,
                    index: index,
                }
            }
        }}
        groups={[
            {
                categoryIndex: "default",
                name: t("General"),
                colProps: {
                    lg: 12
                }
            }, {
                categoryIndex: "custom-attribute-options",
                name: t("Options"),
                colProps: {
                    lg: 12
                }
            }, {
                categoryIndex: "componentTypes",
                name: t("Component types"),
                colProps: {
                    lg: 12
                }
            }, {
                categoryIndex: "client",
                name: t("Client"),
                colProps: {
                    lg: 12
                }
            }, {
                categoryIndex: "serviceProvider",
                name: t("Service Provider"),
                colProps: {
                    lg: 12
                }
            }]}
        submitButtonWrapperStyle={{textAlign: "right", marginRight: Sizes.grid * 2}}
        attributes={
            _.get(customAttributeForm, 'data.customAttribute.typeDefinitionAttributeList.typeDefinitionAttributes', [])
                .filter(item => blacklist.indexOf(item.index) === -1)
                .map(item => {
                    const attribute = {
                        ...item,
                        categories: ["default"],
                        ..._.get(attributeConfigs, item.index),
                    };

                    if (window.__DEV__ && attribute.index === 'options') {
                        if (!_.find(attribute.optionValues, {value: 'tco'})) {
                            attribute.optionValues.push({
                                name: 'Total Cost of Ownership (TCO)',
                                value: 'tco'
                            })
                            if (attribute.value && attribute.value.length) {
                                attribute.value.push("tco");
                            }
                        }
                    }

                    return attribute;
                })
        }
    />
};
