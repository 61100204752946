// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash';

import UserListQuery from "./../../graphql/queries/userList.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import {Tag} from "antd";
import FillQueryTable from "../QueryTable/fill";
import Statics from "../../themes/default/values";
import withProfile from "../../hoc/withProfile";

type Props = {
    userListQuery: query,
    onClick: Function,
    t: Function,
    filter: Object
}

class UserList extends Component<Props> {
    render() {
        const {userListQuery, onClick, t} = this.props;

        return <FillQueryTable
            style={{borderRadius: 0}}
            className={"link-table"}
            columns={[
                {
                    key: 'name',
                    dataIndex: 'name',
                    width: 250,
                    title: t('Name')
                },
                {
                    key: 'email',
                    dataIndex: 'email',
                    width: 300,
                    title: t('email')
                }, {
                    key: 'roles',
                    dataIndex: 'roles',
                    title: t('Roles'),
                    render: tags => (
                        <span>
                          {tags.map(tag => {
                              return <Tag key={tag.id}>{tag.name}</Tag>;
                          })}
                        </span>
                    ),
                }
            ]}
            onRow={(record) => {
                return {
                    onClick: () => {
                        onClick(record);
                    },
                };
            }}
            size={'middle'}
            rowKey={"id"}
            query={userListQuery}
            itemsKeyPath={'userList.users'}
            totalKeyPath={'userList.total'}/>
    }

}

export default compose(
    withTranslation(),
    graphql(UserListQuery, {
        name: 'userListQuery',
        options: (props: Props) => {
            return {
                variables: {
                    params: {
                        start: 0,
                        limit: Statics.pagingSize*2,
                        search: {
                            query: _.get(props, 'filter.query', '') == null ? '' : _.get(props, 'filter.query', ''),
                            fields: ['name', 'firstName', 'lastName', 'email']
                        },
                        sort: {
                            name: 'ASC'
                        }
                    }
                }
            }
        },
    }),
    waitWhileLoading('userListQuery', ['userList.users']),
    withProfile({fetchPolicy: 'network-only'}),
)(UserList);
