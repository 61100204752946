// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {Col, Row} from "antd";
import {Link, withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "./../Form/attributes-inline-form";
import {compose} from "redux";
import Error from "./../Error";

import serviceTicketAttributes from "./../../graphql/queries/serviceTicketAttributes.graphql";
import updateServiceTicket from "./../../graphql/mutations/updateServiceTicket.graphql";

import type {ServiceTicketAttributes_serviceTicket} from "../../graphql/operation-result-types.flow";
import Sizes from "../../themes/default/sizes";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";

type Props = {
    serviceTicketId: string,
    updateServiceTicketMutation: Function,
    serviceTicketAttributesQuery: {
        loading: boolean,
        error?: any,
        serviceTicket: ServiceTicketAttributes_serviceTicket
    },
};


class ServiceTicketDetailsInlineForm extends Component<Props, State> {


    translateMutationOptions = (input) => {

        const {values} = input;

        return {
            variables: {
                id: this.props.serviceTicketId,
                attributes: values
            }
        }
    };

    render() {
        const {serviceTicketAttributesQuery, updateServiceTicketMutation, t} = this.props;

        const attributes = _.get(serviceTicketAttributesQuery, 'serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes')

        if (!attributes) {
            return <Error title={t('No attributes found')}/>
        }

        const serviceTicketId = _.get(serviceTicketAttributesQuery, 'serviceTicket.id')

        const leftAttributes = attributes.filter(item => item.categories.indexOf('dashboardLeft') !== -1)
        const rightAttributes = attributes.filter(item => item.categories.indexOf('dashboardRight') !== -1)
        const bottomAttributes = attributes.filter(item => item.categories.indexOf('dashboardBottom') !== -1)

        return <React.Fragment>
            <Panel title={t('Details')}
                   topRight={<Link to={`/service/${serviceTicketId}/attributes`}>{t('More')}</Link>}>
                <Row gutter={2 * Sizes.grid}>
                    <Col md={12}>
                        <AttributesInlineForm
                            editable
                            layout={'vertical'}
                            submitOnChange
                            mutation={updateServiceTicketMutation}
                            translateMutationOptions={this.translateMutationOptions}
                            attributes={leftAttributes}/>
                    </Col>
                    <Col md={12}>
                        <AttributesInlineForm
                            editable
                            submitOnChange
                            mutation={updateServiceTicketMutation}
                            layout={'vertical'}
                            translateMutationOptions={this.translateMutationOptions}
                            attributes={rightAttributes}/>
                    </Col>
                </Row>
            </Panel>
            {bottomAttributes.length ? <Panel>
                <AttributesInlineForm
                    editable
                    submitOnChange
                    mutation={updateServiceTicketMutation}
                    layout={'vertical'}
                    translateMutationOptions={this.translateMutationOptions}
                    attributes={bottomAttributes}/>
            </Panel>: null}
        </React.Fragment>

    }
}


export default compose(
    graphql(serviceTicketAttributes, {
        name: 'serviceTicketAttributesQuery',
        options: (props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    graphql(updateServiceTicket, {
        name: 'updateServiceTicketMutation'
    }),
    waitWhileLoading('serviceTicketAttributesQuery','serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes'),
    withRouter,
    withTranslation()
)(ServiceTicketDetailsInlineForm);

