// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";

import UserDetailsQuery from "./../../graphql/queries/userDetails.graphql";
import {Col, Row} from "../../components/Flex";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributesInlineForm from "../Form/attributes-inline-form";
import RoleList from "../../graphql/queries/roleList.graphql";
import UserRoles from "../ListAdapter";
import UserRoleLoaderConfig from "../DataLoader/UserRoleLoaderConfig"
import RemoveUserFromRole from "../../graphql/mutations/removeUserFromRole.graphql";
import AddRoleToUser from "../../graphql/mutations/addRoleToUser.graphql";
import DeleteUser from "../../graphql/mutations/deleteUser.graphql";
import UpdateUser from "../../graphql/mutations/updateUser.graphql";
import PanelComponent from "../Panel";
import UserListQuery from "../../graphql/queries/userList.graphql";
import ContentHeaderWithBreadcrumb from "../ContentHeaderWithBreadcrumb";
import PasswordResetButton from "../PasswordResetButton";
import {toastSuccess} from "../../utils/toast";

type Props = {
    userId: string,
    t: Function,
    userDetailsQuery: query,
    userTypeDefinitionAttributeList: query,
    removeUserFromRoleMutation: Function,
    addRoleToUserMutation: Function,
    deleteUserMutation: Function,
    history: {
        push: Function
    },
    updateUserMutation: Function,
}

class UserDashboard extends Component<Props> {

    handleDeleteRoleFromUser = (index) => {
        const {removeUserFromRoleMutation, userId} = this.props;

        removeUserFromRoleMutation({variables: {roleId: index.id, userId: userId}}).then((res) => {
            toastSuccess(this.props.t('Role removed'))
        })
    };

    handleAddRoleToUser = (value) => {
        const {addRoleToUserMutation, userId} = this.props;

        addRoleToUserMutation({variables: {roleId: value, userId: userId}}).then((res) => {
            toastSuccess(this.props.t('Added role'))
        })
    };

    handleDeleteUser = () => {
        const {deleteUserMutation, userId, history} = this.props;

        deleteUserMutation({variables: {userId: userId}}).then((res) => {
            toastSuccess(this.props.t('User deleted'));
            history.push(`/admin/users`)
        });
    };

    render() {
        const {userId, userDetailsQuery, t, updateUserMutation} = this.props;

        return <Col style={{width: '100%'}}>
            <ContentHeaderWithBreadcrumb
                title={userDetailsQuery.user.name}
                tools={
                    <Popconfirm placement="bottomRight" title={t("Are you sure delete this user?")}
                                onConfirm={this.handleDeleteUser} okText={t("Yes")} cancelText={t("No")}
                                icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                        <Button type="danger" icon="delete">{t('Delete')}</Button>
                    </Popconfirm>
                }
                path={[{
                    name: t("Users"),
                    id: -1,
                    link: '/admin/users',
                }, {
                    name: userDetailsQuery.user.name,
                    id: userId,
                    link: '/admin/users',
                }]}
            />
            <Row className={"flex-center"}
                 style={{backgroundColor: '#F9F9F9', height: '100%', width: '100%', marginTop: '20px'}}>
                <Col style={{width: '90%'}} className={"flex-align-items-center"}>
                    <div style={{width: '100%'}}>
                        <PanelComponent
                            title={t('Edit')}
                            topRight={<PasswordResetButton email={_.get(userDetailsQuery, 'user.email')}/>}
                            children={<AttributesInlineForm
                                attributes={_.get(userDetailsQuery, 'user.typeDefinitionAttributeList.typeDefinitionAttributes')}
                                layout={'horizontal'}
                                useSubmit={true}
                                t={t}
                                mutation={updateUserMutation}
                                translateMutationOptions={({values}) => {
                                    console.log(values);
                                    return {
                                        variables: {
                                            userId: userId,
                                            user: {
                                                firstName: values.firstName,
                                                lastName: values.lastName,
                                                email: values.email,
                                                phone: values.phone,
                                                language: values.language,
                                                betaTest: values.betaTest,
                                            }
                                        }
                                    }
                                }}
                                values={userDetailsQuery.user}
                            />}
                        />
                    </div>
                    <div style={{width: '100%', padding: '20px 0 40px 0'}}>
                        <PanelComponent
                            title={t('Roles')}
                            children={<UserRoles
                                items={userDetailsQuery.user.roles}
                                addRoleToUserMutation={(value) => {
                                    this.handleAddRoleToUser(value)
                                }}
                                deleteRoleFromUserMutation={(index) => {
                                    this.handleDeleteRoleFromUser(index)
                                }}
                                loaderConfig={UserRoleLoaderConfig}
                                queryPath={'roleList.roles'}
                                placeholder={t('select roles')}
                            />}
                        />
                    </div>
                </Col>
            </Row>
        </Col>;
    }
}

export default compose(
    graphql(UserDetailsQuery, {
        name: 'userDetailsQuery',
        options: props => ({
            fetchPolicy: 'network-only',
            variables: {
                id: props.userId
            }
        }),
    }),
    waitWhileLoading('userDetailsQuery', ['user']),
    graphql(RoleList, {
        name: 'roleList'
    }),
    waitWhileLoading('roleList', ['roleList.roles']),
    graphql(RemoveUserFromRole, {
        name: 'removeUserFromRoleMutation',
        options: props => ({
            refetchQueries: [{
                query: UserListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 50,
                    }
                }
            },{
                query: UserDetailsQuery,
                variables: {
                    id: props.userId
                }
            }]
        })
    }),
    graphql(AddRoleToUser, {
        name: 'addRoleToUserMutation',
        options: props => ({
            refetchQueries: [{
                query: UserListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 50,
                    }
                }
            },{
                query: UserDetailsQuery,
                variables: {
                    id: props.userId
                }
            }]
        })
    }),
    graphql(DeleteUser, {
        name: 'deleteUserMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: UserListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 50,
                    }
                }
            }]
        })
    }),
    graphql(UpdateUser, {
        name: 'updateUserMutation',
        options: props => ({
            refetchQueries: [{
                query: UserListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 50,
                    }
                }
            },{
                query: UserDetailsQuery,
                variables: {
                    id: props.userId
                }
            }]
        })
    }),
    withTranslation(),
)(UserDashboard);
