// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import AttributesInlineForm from "../Form/attributes-inline-form";
import CreateComponentMutation from "./../../graphql/mutations/createComponent.graphql";
import AddOpenedComponentMutation from "./../../graphql/mutations/addOpenedComponent.graphql";
import AddFilesToComponentMutation from "./../../graphql/mutations/addComponentFiles.graphql";
import ComponentDuplicateInfoQuery from "./../../graphql/queries/componentDuplicateInfo.graphql"

import waitWhileLoading from "../../hoc/waitWhileLoading";
import runSerial from "../../utils/serialpromises";
import openedComponentList from "../../graphql/queries/openedComponentList.graphql";
import {withRouter} from "react-router-dom";

const blackList = ["serialNo", "runtime", "runtimeSinceLastService", "itemNumber", "imageId"];


class ComponentDuplicationPanel extends Component<Props, State> {

    render() {
        const {
            componentId,
            t,
            createComponentMutation,
            componentDuplicateInfoQuery,
            addFilesToComponentMutation,
            values,
            addOpenedComponentMutation
        } = this.props;

        const parentId = _.chain(componentDuplicateInfoQuery).get('component.path').filter(item => item.id !== componentId).last().get('id').value();

        return <div>
            <AttributesInlineForm
                submitTitle={t('Duplicate')}
                attributes={[
                    {
                        index: 'parentId',
                        name: t('Target'),
                        type: 'string',
                        inputType: 'component',
                        rules: [{name: 'required'}]
                    }, {
                        index: 'count',
                        name: t('Count'),
                        inputType: 'int',
                        type: 'int',
                        rules: [{name: 'required'}]
                    }, {
                        index: 'copyFiles',
                        name: t('Copy Files'),
                        disabled: _.get(componentDuplicateInfoQuery, 'component.componentFileList.total', 0) === 0,
                        inputType: 'switch',
                        type: 'bool',
                    },
                    /*
                    {
                        index: 'includeChildren',
                        name: t('Include Sub-Components'),
                        inputType: 'switch',
                        type: 'bool',
                    },
                     */
                    {
                        index: 'openTabs',
                        name: t('Open tabs'),
                        inputType: 'switch',
                        type: 'bool',
                    }
                ]}
                values={{
                    count: 1,
                    openTabs: true,
                    copyFiles: false,
                    parentId,
                }}
                useSubmit
                translateMutationOptions={({values}) => {
                    return {
                        variables: values
                    }
                }}
                mutation={({variables}) => {

                    const existingAttributes = _.get(componentDuplicateInfoQuery, 'component.typeDefinitionAttributeList.typeDefinitionAttributes', []).filter(item => !item.disabled)
                    const attributes = filterDuplicationAttributes(existingAttributes).map((attr) => {
                        const newAttribute = _.cloneDeep(attr);
                        if(attr.value && typeof attr.value === 'string' && attr.type === 'int'){
                            newAttribute.value = Number(newAttribute.value)
                        }
                        return newAttribute;
                    });

                    const count = _.get(variables, 'count', 1);
                    const openTabs = _.get(variables, 'openTabs', false);
                    const copyFiles = _.get(variables, 'copyFiles', false);
                    const includeChildren = _.get(variables, 'includeChildren', false);

                    const attributeValues = attributes.reduce((acc, cur) => {
                        acc[cur.index] = _.get(values, cur.index, cur.value);
                        return acc;
                    }, {});

                    if (_.get(componentDuplicateInfoQuery, 'component.componentType.index') === 'machine') {
                        attributeValues["manufacturerName"] = _.get(componentDuplicateInfoQuery, 'component.manufacturerName') || 'Unknown';
                    }

                    const componentCreateInput = {
                        componentTypeIndex: _.get(componentDuplicateInfoQuery, 'component.componentType.index'),
                        productId: _.get(componentDuplicateInfoQuery, 'component.product.id'),
                        attributes: attributeValues
                    };

                    const tasks = [];
                    for (let i = 0; i < count; i++) {
                        tasks.push(() => {
                            return createComponentMutation({
                                variables: {
                                    parentId: _.get(variables, 'parentId', parentId),
                                    componentCreateInput
                                }
                            }).then((res) => {
                                if (copyFiles && _.get(componentDuplicateInfoQuery, 'component.componentFileList.total')) {
                                    return addFilesToComponentMutation({
                                        variables: {
                                            id: _.get(res, 'data.createComponent.id'),
                                            files: _.get(componentDuplicateInfoQuery, 'component.componentFileList.componentFiles', []).map((item) => ({
                                                fileId: item.id,
                                                category: item.category
                                            }))
                                        }
                                    }).then(() => {

                                        /*
                                        if (includeChildren){
                                            return addFilesToComponentMutation({
                                                variables: {
                                                    id: _.get(res,'data.createComponent.id'),
                                                    files: _.get(componentDuplicateInfoQuery, 'component.componentFileList.componentFiles',[]).map((item) => ({
                                                        fileId: item.id,
                                                        category: item.category
                                                    }))
                                                }
                                            }).then( () => res )
                                        }

                                         */
                                        return res
                                    })
                                }
                                return res
                            })
                        })
                    }

                    return runSerial(tasks)
                        .then((results) => {
                            const componentIds = results.map(item => _.get(item, 'data.createComponent.id'));

                            if (openTabs) {
                                if (componentIds && componentIds.length) {
                                    addOpenedComponentMutation({
                                        variables: {
                                            componentIds
                                        },
                                        refetchQueries: [
                                            {
                                                query: openedComponentList
                                            }
                                        ]
                                    })
                                }
                            }

                            return componentIds;
                        })
                        .then((componentIds) => {
                            if (_.first(componentIds)) {
                                this.props.history.push(`/infrastructure/${_.first(componentIds)}`)
                            }
                        })
                }}
            />
        </div>;
    }
}

export const filterDuplicationAttributes = (existingAttributes) => {


    const attributes = existingAttributes
        .filter(item => blackList.indexOf(item.index) === -1)
        .filter(item => item.value !== undefined && item.value !== null && item.value !== "");

    return attributes || [];
};

export default compose(
    withTranslation(),
    withRouter,
    graphql(ComponentDuplicateInfoQuery, {
        name: 'componentDuplicateInfoQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        })
    }),
    waitWhileLoading('componentDuplicateInfoQuery', 'component.path'),
    graphql(AddOpenedComponentMutation, {
        name: 'addOpenedComponentMutation',
    }),
    graphql(AddFilesToComponentMutation, {
        name: 'addFilesToComponentMutation',
    }),
    graphql(CreateComponentMutation, {
        name: 'createComponentMutation',
    }),
)(ComponentDuplicationPanel);
