import React from 'react';
import _ from 'lodash'
import {branch, compose, renderComponent} from 'recompose';
import Forbidden from "../components/Forbidden";
import {connect} from "react-redux";

/**
 * Returns true if all required resources are in profileResources
 * @param resources
 * @param profileResources
 */
export const checkResource = (resources: string[] | string, profileResources = []): boolean => {

    let tmp = resources;
    if (!Array.isArray(tmp)) {
        tmp = [resources];
    }
    return tmp.reduce((acc, cur) => {
        if (!acc) return acc;
        return profileResources.indexOf(cur) > -1;
    }, true)

};


/**
 0: "catalogEditor"
 1: "administrateStocks"
 2: "administrateConnectors"
 3: "copsManagement"
 4: "administrateClients"
 5: "administrateServiceContracts"
 6: "administrateServiceCenters"
 7: "showWorkflow"
 8: "serviceContractsRead"
 9: "showServiceBudget"
 10: "newsEditor"
 11: "accounting"
 12: "logistician"
 13: "administrateServiceRequests"
 * @param acc
 * @param role
 * @returns {string[]}
 */

export const reduceRoleResources = (acc: string[], role): string[] => {

    return acc.concat(_.get(role, 'resources', [])
        .map(resource => _.get(resource, 'key'))
    )

};

function mapStateToProps(state) {
    return {
        resources: _.get(state, 'settings.resources')
    }
}

const withResources = (resources: string[] | string) =>
    compose(
        connect(mapStateToProps),
        branch(
            (props) => resources && !checkResource(
                resources,
                props.resources
            ),
            renderComponent(() => (<Forbidden/>)),
        )
    );

export default withResources;
