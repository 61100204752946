// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {message} from "antd";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import _ from "lodash";

import query from "./../../graphql/queries/serviceTicketContaminationDeclaration.graphql"
import createMutation from "./../../graphql/mutations/createContaminationDeclaration.graphql"
import setContaminationDeclarationFile from "./../../graphql/mutations/setContaminationDeclarationFile.graphql"
import {getPrintLink} from "../../utils/ContaminationDeclarationHelper";

import FinalizeContaminationDeclarationMutation from "../../graphql/mutations/finalizeContaminationDeclaration.graphql";
import FinalizeListItem from "../FinalizeListItem";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    t: Function,
    serviceTicketId: number | string,
    contaminationDeclarationQuery: any,
    createMutation: Function,
    setContaminationDeclarationFileMutation: Function
};

type State = {
    loading: boolean
}

class ContaminationDeclarationFinalizeWidget extends Component<Props, State> {

    state = {
        loading: false
    };

    handleUpload = (file: FileUploadResponse) => {

        const {t, contaminationDeclarationQuery, setContaminationDeclarationFileMutation, createMutation} = this.props;
        const {serviceTicket} = contaminationDeclarationQuery;

        this.setState({
            loading: true,
        }, () => {
            const updatePromise = (id) => {
                return setContaminationDeclarationFileMutation({
                    variables: {
                        contaminationDeclarationId: id,
                        fileId: file.fileId
                    }
                })
                    .then(() => {
                        message.success(t('File was uploaded'));
                    })
                    .catch((err) => {
                        console.error(err);
                        message.error(t('Could not upload file'));
                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        })
                    })
            };
            if (!serviceTicket.contaminationDeclaration) {
                createMutation({
                    variables: {
                        serviceTicketId: serviceTicket.id
                    }
                }).then((result) => {
                    const id = _.get(result, 'data.createContaminationDeclaration.id');
                    if (id) {
                        updatePromise(id)
                    } else {
                        message.error(t('Could not create contamination declaration'));
                    }
                }).catch((err: Error) => {
                    message.error(t('Could not create contamination declaration'));
                    console.error(err);
                    this.setState({
                        loading: false
                    })
                })
            } else {
                updatePromise(serviceTicket.contaminationDeclaration.id)
            }

        })

    };

    handleFinalize = () => {

        const {t, finalizeContaminationDeclarationMutation, contaminationDeclarationQuery} = this.props;
        const contaminationDeclarationId = _.get(contaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.id');

        this.setState({
            loading: true,
        });
        finalizeContaminationDeclarationMutation({
            variables: {
                contaminationDeclarationId: contaminationDeclarationId,
            }
        })
            .then(() => {
                message.success(t('deco finalized'));
            })
            .catch((err) => {
                console.error(err);
                message.error(t('Error'));
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    };


    render() {
        const {contaminationDeclarationQuery, t} = this.props;
        const contaminationDeclarationId = _.get(contaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.id');

        const ticketId = _.get(contaminationDeclarationQuery, 'serviceTicket.id');
        const editLink = `/service/${ticketId}/contaminationDeclaration`;


        return <FinalizeListItem
            formId={_.get(contaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.id')}
            name={t('Contamination Declaration')}
            update={_.get(contaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.update')}
            updatedBy={_.get(contaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.updatedBy')}
            editLink={editLink}
            downloadLink={contaminationDeclarationId ? getPrintLink(_.get(contaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration')) : undefined}
            loading={this.state.loading}
            onUploaded={this.handleUpload}
            onFinalize={contaminationDeclarationId ? this.handleFinalize : undefined}
            finalized={contaminationDeclarationId && _.get(contaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.finalized')}
        />;
    }
}


export default compose(
    graphql(query, {
        name: 'contaminationDeclarationQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('contaminationDeclarationQuery'),
    graphql(createMutation, {
        name: 'createMutation'
    }),
    graphql(setContaminationDeclarationFile, {
        name: 'setContaminationDeclarationFileMutation',
        options: props => ({
            refetchQueries: [{
                query: query,
                variables: {
                    id: props.serviceTicketId
                }
            }]
        })
    }),
    graphql(FinalizeContaminationDeclarationMutation, {
        name: 'finalizeContaminationDeclarationMutation',
        options: props => ({
            refetchQueries: [{
                query: query,
                variables: {
                    id: props.serviceTicketId
                }
            }]
        })
    }),
    withRouter,
    withTranslation()
)(ContaminationDeclarationFinalizeWidget);
