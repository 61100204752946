// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {Button} from "@dreebit/react-components";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import bookmarkList from "./../../graphql/queries/bookmarkList.graphql"
import createBookmark from "./../../graphql/mutations/createBookmark.graphql";
import BookmarkList, {bookmarkListVariables} from "./../BookmarkList";
import queryString from "query-string";

type Props = {
    t: Function,
    createBookmarkMutation: Function,
    filter: any,
}

type State = {
    loadingCreate: boolean,
}

class ServiceCalendarBookmarks extends Component<Props, State> {

    static defaultProps = {
        filter: {}
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingCreate: false
        }
    }


    createBookmark = () => {

        const {t, createBookmarkMutation, filter} = this.props;

        console.log(queryString.stringify(filter), filter);

        this.setState({
            loadingCreate: true
        }, () => {
            createBookmarkMutation({
                variables: {
                    input: {
                        title: t('New filter'),
                        type: 'serviceCalendar',
                        data: JSON.stringify({
                            link: `/calendar?${queryString.stringify(filter)}`
                        })
                    }
                }
            }).then(() => {

            }).finally(() => {
                this.setState({
                    loadingCreate: false
                })
            })
        })

    };

    render() {
        const {t} = this.props;
        const {loadingCreate} = this.state;

        return <div className={'full-height'}>
            <div className={'white-bg h-50 bb p-10 flex-row flex-space-between'}>
                <h3 className={'m-0 text-dark flex-grow-1 ellipse-label'}>{t('Service Calendar')}</h3>
            </div>

            <div className={"text-center pv-10 ph-10"}>
                <Button loading={loadingCreate} onClick={this.createBookmark} type={'default'}
                        className={'full-width'}>{t('Save current search')}</Button>
            </div>
            <div className={"ph-10"}>
                <div className={"bb"}/>
            </div>
            <h4 className={'ph-10 pv-5 text-dark'}>{t('Saved filter')}</h4>
            <div style={{height: 'calc(100% - 141px)'}}>
                <BookmarkList type={['serviceCalendar']}/>
            </div>

        </div>
    }

}

export default compose(
    graphql(createBookmark, {
        name: 'createBookmarkMutation',
        options: () => ({
            refetchQueries: [{
                query: bookmarkList,
                variables: bookmarkListVariables('serviceCalendar')
            }]
        })
    }),
)(withTranslation()(ServiceCalendarBookmarks));
