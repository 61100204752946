// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import AccessTokenListQuery from "../../graphql/queries/accessTokenList.graphql";
import RemoveAccessTokenMutation from "../../graphql/mutations/removeAccessToken.graphql";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import moment from "moment";
import {Tag} from "antd";
import {Button} from "@dreebit/react-components";
import Toolbar from "../Toolbar";
import {toastError} from "../../utils/toast";
import PaginationQueryTable from "../QueryTable/pagination";

type State = {
    selectedRowIds: any[],
    loading: boolean,
}

class AccessTokenListTable extends Component<Props, State> {

    state = {
        selectedRowIds: [],
        loading: false,
    };

    _handleDelete = () => {

        const {removeAccessTokenMutation} = this.props;

        this.setLoading(true)
            .then(() => {
                const promises = this.state.selectedRowIds.map(id => removeAccessTokenMutation({variables: {id}}));
                return Promise.all(promises);
            })
            .catch(toastError)
            .finally(() => {
                this.setState({
                    selectedRowIds: [],
                });
                this.setLoading(false);
            })

    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {accessTokenListQuery, t} = this.props;

        const {selectedRowIds} = this.state;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowIds: selectedRows.map(item => item.id)
                })
            },
            getCheckboxProps: record => ({
                disabled: record.current, // Column configuration not to be checked
                name: record.name,
            }),
        };

        return <div>
            {selectedRowIds.length ? <Toolbar
                renderLeft={() => <Button onClick={this._handleDelete}>{t('Delete')}</Button>}
            /> : null}
            <PaginationQueryTable
                rowSelection={rowSelection}
                size={'small'}
                rowKey={"id"}
                query={accessTokenListQuery}
                itemsKeyPath={'accessTokenList.accessTokens'}
                totalKeyPath={'accessTokenList.total'}
                limitKeyPath={'params.limit'}
                startKeyPath={'params.start'}
                pagingSize={25}
                columns={[
                    {
                        dataIndex: 'type',
                        key: 'type',
                        width: 10,
                        render: (text) => {
                            switch (text) {
                                case 'web':
                                    return <FontAwesomeIcon name={'globe'}/>;
                                case 'mobile':
                                    return <FontAwesomeIcon name={'mobile'}/>;
                                case 'android':
                                    return <FontAwesomeIcon name={'android'}/>;
                                case 'ios':
                                    return <FontAwesomeIcon name={'apple'}/>;
                                default:
                                    return <FontAwesomeIcon name={'globe'}/>
                            }
                        }
                    },
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: t('Name'),
                        render: (text, record) => {
                            return <>
                                {text} {record.current ? <Tag>{t('Current')}</Tag> : null}
                            </>
                        }
                    },
                    {
                        dataIndex: 'insert',
                        key: 'insert',
                        width: 200,
                        title: t('Login'),
                        render: (text) => moment(text).format('L LT')
                    },
                    {
                        dataIndex: 'expires',
                        key: 'expires',
                        width: 200,
                        title: t('Expires'),
                        render: (text) => moment(text).format('L LT')
                    }
                ]}
            />
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(AccessTokenListQuery, {
        name: 'accessTokenListQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    start: 0,
                    limit: 25,
                }
            }
        }),
    }),
    graphql(RemoveAccessTokenMutation, {
        name: 'removeAccessTokenMutation',
        options: () => ({
            refetchQueries: [{
                query: AccessTokenListQuery
            }]
        })
    }),
)(AccessTokenListTable);

