// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {compose} from "redux";
import ServiceTicketFinalTestQuery from "./../../graphql/queries/serviceTicketFinalTest.graphql"
import FinalTestForm from "./../FinalTestForm";
import FinalTestAutoCreate from "../FinalTestAutoCreate";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceTicketFinalTestFinalized from "../ServiceTicketFinalTestFinalized";

type Props = {
    serviceTicketId: string,
    serviceTicketFinalTestQuery: any,
};

type State = {
    createdFinalTest?: any
}

class ServiceTicketFinalTest extends Component<Props, State> {

    state = {
        createdFinalTest: null
    };

    render() {
        const {serviceTicketFinalTestQuery, serviceTicketId} = this.props;

        let finalTest = _.get(serviceTicketFinalTestQuery, 'serviceTicket.finalTest');

        if (!finalTest) {
            return <FinalTestAutoCreate
                onCreated={(createdFinalTest) => {
                    serviceTicketFinalTestQuery.refetch();
                }}
                serviceTicketId={serviceTicketId}/>
        }

        return <div className={'ph-20'}>
            <ServiceTicketFinalTestFinalized finalTestId={finalTest.id}/>
            <FinalTestForm serviceTicketId={serviceTicketId} finalTestId={finalTest.id}/>

        </div>;
    }
}

export default compose(
    graphql(ServiceTicketFinalTestQuery, {
        name: 'serviceTicketFinalTestQuery',
        options: (props) => ({
            variables: {
                id: props.serviceTicketId
            },
            fetchPolicy: 'no-cache'
        })
    }),
    waitWhileLoading('serviceTicketFinalTestQuery', ["serviceTicket.finalTest"]),
    withTranslation()
)(
    ServiceTicketFinalTest
);

