// @flow
import React, {Component} from "react"
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import ProductLogs from "../../../components/ProductLogs";
import queryString from "query-string";
import _ from "lodash";
import {withRouter} from "react-router-dom";

type Props = {
    match: {
        params: {
            id: string,
        }
    },
    productId?: string,
    t: Function,
}

class ProductLogsRoute extends Component<Props> {

    _handleFilterChange = (params) => {

        const {filter, productId} = this.props;

        const values = {
            ...filter,
            ...params
        };
        const stringified = queryString.stringify(values);

        this.props.history.push(`/catalog/products/${productId}/logs?${stringified}`)

    };

    render() {

        const {productId, params} = this.props;

        return <ProductLogs values={params} onChange={this._handleFilterChange} productId={productId}/>
    }

}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        let query = _.get(props, 'history.location.search');
        const productId = _.get(props, 'match.params.id');
        return {
            productId,
            params: queryString.parse(query)
        }
    }),
)(ProductLogsRoute);
