// @flow
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import ContentHeader from "../../components/Typo/ContentHeader";
import LogisticiansList from "../../components/LogisticiansList";
import {compose} from "redux";
import {withProps} from "recompose";
import _ from "lodash";
import queryString from "query-string";
import AttributesInlineForm from "../../components/Form/attributes-inline-form";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
}

class LogisticianListRoute extends Component<Props, State> {


    render() {
        const {t, history, filter} = this.props;

        const attributes = [{
            index: "query",
            type: "string",
            inputType: "text",
            inputTypeOptions: {
                placeholder: t("Search")
            }
        }];

        return <NavigationContainer navigationBar={ <ContentHeader
            title={t('Logistician')}
            tools={<Link to={"/admin/logisticians/new"}>
                <Button>
                    {t('Add')}
                </Button>
            </Link>}
        />} >

            <NavigationContainer navigationBar={
                <AttributesInlineForm
                    className={"ph-5 pt-5"}
                    attributes={attributes}
                    layout={'vertical'}
                    useSubmit={false}
                    onChange={(attributeValues) => {
                        history.push(`/admin/logisticians?${queryString.stringify(attributeValues)}`)
                    }}
                    values={filter}
                />
            } >

                <LogisticiansList onClick={(record) => history.push(`/admin/logisticians/detail/${record.id}`)}
                                  filter={filter}
                />
            </NavigationContainer>
        </NavigationContainer>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        const query = _.get(props, 'history.location.search');
        return {
            filter: queryString.parse(query),
        }
    }),
    withResources(["logistician"])
)(LogisticianListRoute);
