import React from "react";
import {Route, Switch} from "react-router-dom";
import ServiceReportCreateRoute from "./create";
import ServiceReportDetailRoute from "./detail";
import ServiceReportListRoute from "./list";


interface Props {

}

const ServiceTicketServiceReportRoute = (props: Props) => {


    return <Switch>

        <Route path="/service/:id/serviceReports/create"
               component={ServiceReportCreateRoute}
        />
        <Route path="/service/:id/serviceReports/:serviceReportId"
               component={ServiceReportDetailRoute}
        />

        <Route path="/service/:id/serviceReports"
               component={ServiceReportListRoute}
        />
    </Switch>;
}

export default ServiceTicketServiceReportRoute;
