// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import _ from "lodash";

import query from "./../../graphql/queries/serviceTicketFinalTest.graphql"
import createMutation from "./../../graphql/mutations/createFinalTest.graphql"
import {message} from "antd";
import FinalizeFinalTestMutation from "../../graphql/mutations/finalizeFinalTest.graphql";
import FinalizeListItem from "../FinalizeListItem";
import waitWhileLoading from "../../hoc/waitWhileLoading";


type Props = {
    t: Function,
    serviceTicketId: number | string,
    finalTestQuery: any,
    createMutation: Function,
    disableEdit: boolean
};

type State = {
    loading: boolean,
}

class FinalTestFinalizeWidget extends Component<Props, State> {

    state = {
        loading: false
    };

    handleFinalize = () => {

        const {t, finalizeFinalTestMutation, finalTestQuery} = this.props;
        const finalTestId = _.get(finalTestQuery, 'serviceTicket.finalTest.id');

        this.setState({
            loading: true,
        });
        finalizeFinalTestMutation({
            variables: {
                finalTestId: finalTestId,
            }
        })
            .then(() => {
                message.success(t('final test finalized'));
            })
            .catch((err) => {
                console.error(err);
                message.error(t('Error'));
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    };


    render() {
        const {finalTestQuery, disableEdit, t} = this.props;

        const ticketId = _.get(finalTestQuery, 'serviceTicket.id');
        const editLink = `/service/${ticketId}/finalTest`;
        const finalTestId = _.get(finalTestQuery, 'serviceTicket.finalTest.id');

        return <FinalizeListItem
            formId={_.get(finalTestQuery, 'serviceTicket.finalTest.id')}
            name={t('Final Test')}
            disableEdit={disableEdit}
            editLink={editLink}
            update={_.get(finalTestQuery, 'serviceTicket.finalTest.finalize.insert', _.get(finalTestQuery, 'serviceTicket.finalTest.update'))}
            updatedBy={_.get(finalTestQuery, 'serviceTicket.finalTest.updatedBy')}
            downloadLink={finalTestId ? _.get(finalTestQuery, 'serviceTicket.finalTest.print.sheet.link') : undefined}
            loading={this.state.loading}
            onFinalize={finalTestId ? this.handleFinalize : undefined}
            finalized={finalTestId && _.get(finalTestQuery, 'serviceTicket.finalTest.finalized')}
        />;
    }
}


export default compose(
    graphql(query, {
        name: 'finalTestQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('finalTestQuery'),
    graphql(createMutation, {
        name: 'createMutation'
    }),
    graphql(FinalizeFinalTestMutation, {
        name: 'finalizeFinalTestMutation'
    }),
    withRouter,
    withTranslation()
)(FinalTestFinalizeWidget)
