// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Exception from "../Exception";

class Forbidden extends Component<Props, State> {
    render() {
        const {t} = this.props;

        return <div className={"p-10 bg-white"}>
            <Exception
                backText={t('Back home')}
                type={403}/>
        </div>
    }
}

export default withTranslation()(Forbidden);
