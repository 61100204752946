// @flow
import {withRouter} from "react-router-dom";
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import FilePreviewListItem from "../FilePreviewListItem";
import {graphql} from '@apollo/client/react/hoc';
import * as _ from "lodash";
import ContaminationDeclarationFileQuery from "./../../graphql/queries/contaminationDeclarationFile.graphql"
import SetContaminationDeclarationFileMutation from "./../../graphql/mutations/setContaminationDeclarationFile.graphql";
import RemoveContaminationDeclarationFileMutation
    from "./../../graphql/mutations/removeContaminationDeclarationFile.graphql";
import Empty from "../Empty";
import FileUploader from "../FileUploader";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import ServiceTicketContaminationDeclarationQuery
    from "../../graphql/queries/serviceTicketContaminationDeclaration.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    contaminationDeclarationId: string,
    contaminationDeclarationFileQuery: any,
    setContaminationDeclarationFileMutation: Function,
    serviceTicketId: any
};

type State = {
    uploadedFile?: any
}

class ContaminationDeclarationUpload extends Component<Props, State> {

    state = {
        uploadedFile: null
    };

    handleUpdateFile(fileId: string) {

        const {contaminationDeclarationId, setContaminationDeclarationFileMutation} = this.props;
        setContaminationDeclarationFileMutation({
            variables: {
                contaminationDeclarationId,
                fileId
            }
        }).then(({data}) => {
            this.setState({
                uploadedFile: _.get(data, 'setContaminationDeclarationFile')
            })
        })

    }

    render() {
        const {contaminationDeclarationFileQuery, removeContaminationDeclarationFileMutation} = this.props;
        const {uploadedFile} = this.state;

        let file = uploadedFile;
        if (!file) {
            file = _.get(contaminationDeclarationFileQuery, 'contaminationDeclaration.file');
        }

        return <div>
            {uploadedFile || file ? <FilePreviewListItem
                onRemove={(file) => {
                return removeContaminationDeclarationFileMutation({
                    variables: {
                        fileId: file.id
                    }
                })
                }}
                file={uploadedFile || file}
            /> : <Empty text={'There are no uploaded contamination declaration files'}/>}
            <FileUploader
                multiple={false}
                onChange={(fileList) => {
                    const uploadInfo = _.first(fileList);
                    const fileId = _.get(uploadInfo, 'response.fileId');
                    if (fileId && (!file || String(fileId) !== file.id)) {
                        this.handleUpdateFile(String(fileId));
                    }
                }}
            />
        </div>;
    }
}


export default compose(
    graphql(ContaminationDeclarationFileQuery, {
        name: 'contaminationDeclarationFileQuery',
        options: (props) => ({
            variables: {
                id: props.contaminationDeclarationId
            }
        })
    }),
    graphql(RemoveContaminationDeclarationFileMutation, {
        name: 'removeContaminationDeclarationFileMutation'
    }),
    waitWhileLoading('contaminationDeclarationFileQuery'),
    graphql(SetContaminationDeclarationFileMutation, {
        name: 'setContaminationDeclarationFileMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ContaminationDeclarationFileQuery,
                variables: {
                    id: props.contaminationDeclarationId
                }
            },{
                query: serviceTicketDashboard,
                variables: {
                    id: props.serviceTicketId
                }
            },{
                query: ServiceTicketContaminationDeclarationQuery,
                fetchPolicy: 'network-only',
                variables: {
                    id: props.serviceTicketId
                }
            }]
        }),
    }),
    withTranslation(),
    withRouter,
    withTranslation(),

)(ContaminationDeclarationUpload);

