import React from 'react';
import {useHistory} from "react-router-dom";
import NavigationContainer from "../../components/NavigationContainer";
import ContentHeader from "../../components/Typo/ContentHeader";
import {useTranslation} from "react-i18next";
import ClientAccessRequestList from "../../components/ClientAccessRequestList";


interface Props {

}

const ClientAccessRequestListRoute = ({}: Props) => {

    const {t} = useTranslation();
    const history = useHistory();

    return <NavigationContainer navigationBar={
        <div>
            <ContentHeader title={t('Access requests')} />
        </div>
    }
    >
        <ClientAccessRequestList onClick={(id) => history.push('/admin/accessrequests/' + id)}/>
    </NavigationContainer>
}

export default ClientAccessRequestListRoute;
