import moment from "moment";

export function groupAndCombineByWeekday(lastWeek, currentWeek) {
  const previousKW = lastWeek.reduce((groups, ticket) => {
    const date = moment(ticket.update).format('dd');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(ticket);
    return groups;
  }, {});

  const previousKWfiltered = Object.keys(previousKW).map((date) => {
    return {
      date,
      previousWeek: previousKW[date].length
    };
  });

  const currentKW = currentWeek.reduce((groups, ticket) => {
    const date = moment(ticket.update).format('dd');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(ticket);
    return groups;
  }, {});

  const currentKWfiltered = Object.keys(currentKW).map((date) => {
    return {
      date,
      currentWeek: currentKW[date].length
    };
  });

  return previousKWfiltered.map((item, i) => Object.assign({}, item, currentKWfiltered[i]));
}

export function groupAndCombineByMonth(internalTickets, externalTickets) {
  const internalTicketsPerYear = internalTickets.reduce((groups, ticket) => {
    const date = moment(ticket.update).format('MMM');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(ticket);
    return groups;
  }, {});

  const internalTicketsPerYearFiltered = Object.keys(internalTicketsPerYear).map((date) => {
    return {
      date,
      intern: internalTicketsPerYear[date].length
    };
  });

  const externalTicketsPerYear = externalTickets.reduce((groups, ticket) => {
    const date = moment(ticket.update).format('MMM');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(ticket);
    return groups;
  }, {});

  const externalTicketsPerYearFiltered = Object.keys(externalTicketsPerYear).map((date) => {
    return {
      date,
      extern: externalTicketsPerYear[date].length
    };
  });

  return internalTicketsPerYearFiltered.map((item, i) => Object.assign({}, item, externalTicketsPerYearFiltered[i]));
}

export function groupByMomentFormat(input, format) {

  const groups = input.reduce((groups, ticket) => {
    const date = moment(ticket.update).format(format);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(ticket);
    return groups;
  }, {});

  return Object.keys(groups).map((date) => {
    return {
      date,
      tickets: groups[date].length
    };
  });
}

export function getAvgLeadTimeInDays(input) {
  let sum = 0
  input.forEach(item => {
    sum += (new Date(item.update) - new Date(item.insert)) / (1000*60*60*24);
  })

  return (sum / input.length).toFixed(2);
}
