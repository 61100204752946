// @flow
import React, {Component} from 'react';
import {Col, Grow, Row} from "../Flex";

type Props = {
    borderBottom: boolean,
    renderLeft?: ?Function,
    renderRight?: ?Function,
    renderMiddle?: ?Function,
    style: ?any,
};

export default class Toolbar extends Component<Props> {
    static defaultProps = {
        borderBottom: false,
        renderLeft: null,
        renderRight: null,
        renderMiddle: null,
        style: {}
    };
    props: Props;

    render() {

        const {style, renderLeft, renderRight, renderMiddle, borderBottom, className} = this.props;

        return (
            <Row
                className={`full-width toolbarRow ${className || ''}`}
                style={{
                    padding: '8px 16px',
                    borderBottom: borderBottom ? '1px solid #ccc' : null,
                    ...style
                }}
            >
                {
                    renderLeft
                        ? <Grow style={{
                            order: '0 !important'
                        }}
                        >
                            {renderLeft()}
                        </Grow>
                        : null
                }

                {
                    renderMiddle
                        ? <Grow style={{
                            order: '1 !important',
                            flex: 1
                        }}
                        >
                            {renderMiddle()}
                        </Grow>
                        : null
                }

                {
                    renderRight
                        ? <Col style={{
                            order: '2 !important'
                        }}
                        >
                            {renderRight()}
                        </Col>
                        : null
                }

            </Row>
        );
    }
}
