// @flow

import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";

import ServiceControlQuery from "../../graphql/queries/ServiceControlSidebar.graphql";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import IconSidebarPanel from "../IconSidebarPanel";
import ComponentInfoBox from "../ComponentInfoBox";
import ClientInfoBox from "../ClientInfoBox";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceControlInfoBox from "./../ServiceControlInfoBox";
import ServiceControlPerformAction from "../ServiceControlPerformAction";
import {Icon} from "@dreebit/react-components";
import {SidebarWrapper} from "../ServicePlanSidebar";

type Props = {
    serviceControlId: string,
    onClose?: any
};


class ServiceControlSidebar extends Component<Props, State> {


    render() {

        const {t, serviceControlQuery, serviceControlId, onClose} = this.props;


        const items = [{
            index: 'component',
            title: t('Component'),
            icon: <FontAwesomeIcon name={'box'}/>,
            body: <ComponentInfoBox wrapperStyle={{}}
                                    componentId={_.get(serviceControlQuery, 'serviceControl.component.id')}/>
        }, {
            index: 'serviceControl',
            title: t('Service control'),
            icon: <FontAwesomeIcon name={'clock'}/>,
            body: <ServiceControlInfoBox serviceControlId={serviceControlId}/>
        }, {
            index: 'serviceControlAction',
            title: t('Perform service control'),
            icon: <FontAwesomeIcon name={'flash'}/>,
            body: <ServiceControlPerformAction serviceControlId={serviceControlId}/>
        }, {
            index: 'client',
            title: t('Client info'),
            icon: <FontAwesomeIcon name={'building'}/>,
            body: <ClientInfoBox clientId={_.get(serviceControlQuery, 'serviceControl.component.client.id')}/>
        }];

        return <SidebarWrapper>
            <div className={'flex justify-flex-end'}>
                <Icon
                    type={'close'}
                    style={{fontSize: 16}}
                    onClick={onClose}
                />
            </div>
            <IconSidebarPanel hideIcons items={items}/>
        </SidebarWrapper>
    }

}

export default compose(
    graphql(ServiceControlQuery, {
        name: 'serviceControlQuery',
        options: (props) => {
            return {
                variables: {
                    serviceControlId: props.serviceControlId,
                }
            }
        }
    }),
    waitWhileLoading('serviceControlQuery'),
    withTranslation()
)(ServiceControlSidebar)
