// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";

import Toolbar from "../Toolbar";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {Button} from "@dreebit/react-components";
import {Link} from "react-router-dom";

type Props = {
  t: Function,
  logLevelEnums: any,
  logTypeEnums: any,
  onChange: Function,
  values: any,
  productId: any,
};

class ProductLogToolbar extends Component<Props, State> {
  render() {
    const {t, logLevelEnums, logTypeEnums, onChange, values, productId} = this.props;

    const attributes = [{
      index: 'logLevel',
      type: 'string',
      entityType: 'logLevel',
      enumType: 'LogLevel',
      inputType: 'select',
      multiple: true,
      placeholder: t('Level'),
      config: {
        mode: 'default',
      },
      optionValues: logLevelEnums.map(item => ({
        name: item.description,
        value: item.name
      })),
    }, {
      index: 'logType',
      type: 'string',
      multiple: true,
      entityType: 'logType',
      enumType: 'LogTypeKey',
      inputType: 'select',
      placeholder: t('Action'),
      config: {
        mode: 'default',
      },
      optionValues: logTypeEnums.map(item => ({
        name: item.description,
        value: item.name
      })),
    }];

    return <Toolbar
      style={{paddingBottom: 10}}
      renderLeft={() => <AttributesInlineForm
        layout={'inline'}
        onValuesChange={(changes) => {
          onChange(changes)
        }}
        values={values}
        attributes={attributes}
        useSubmit={false}
      />}
    />
  }
}

export default compose(
  withTranslation(),
)(ProductLogToolbar);
