// @flow
import React from 'react';

export const DEFAULT_THEME = "vsm";
export const LOGO_THEMES = ["vsm", "xenon", "pv"];

export const ThemeContext = React.createContext({theme: DEFAULT_THEME});

export default (props) => {

    const selector = document.querySelector("meta[name='theme']");
    let theme = DEFAULT_THEME;
    if (selector){
        const content = selector.getAttribute("content");
        if (content && content !== '{{THEME}}'){
            theme = content;
        }else {
            theme = DEFAULT_THEME
        }
    }


    return <ThemeContext.Provider value={{theme}}>
        {props.children}
    </ThemeContext.Provider>
};
