// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ServiceDepartmentCreateMutation from "./../../graphql/mutations/createServiceDepartment.graphql";
import ServiceCenterDepartmentListQuery from "./../../graphql/queries/serviceCenterDepartmentList.graphql";
import AttributeInlineForm from "../Form/attributes-inline-form";

type Props = {
    serviceCenterId: string
};


class ServiceDepartmentCreateForm extends Component<Props, State> {

    render() {
        const {serviceDepartmentCreateMutation, serviceCenterId, t} = this.props;

        return <div>
            <AttributeInlineForm
                useSubmit
                submitTitle={t('Add')}
                attributes={[
                    {
                        index: 'name',
                        type: 'string',
                        inputType: 'text',
                        rules: [{name: 'required'}],
                        name: t('Name')
                    }]}
                mutation={serviceDepartmentCreateMutation}
                translateMutationOptions={({values}) => ({
                    variables: {
                        serviceCenterId,
                        input: values
                    }
                })}
            />
            {/* <TypeDefinitionAttributesForm
        index={'serviceDepartment'}
        formProps={{
          mutation: serviceDepartmentCreateMutation,
          translateMutationOptions: (input) => ({
            serviceCenterId,
            input
          })
        }}/>*/}
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceDepartmentCreateMutation, {
        name: 'serviceDepartmentCreateMutation',
        options: (props) => ({
            refetchQueries: [
                {
                    query: ServiceCenterDepartmentListQuery,
                    variables: {
                        serviceCenterId: props.serviceCenterId
                    }
                }
            ]
        })
    }),
)(ServiceDepartmentCreateForm);
