// @flow
import React, { Component } from 'react';
import NavigationContainer from "../../components/NavigationContainer";

type Props = {

};

type State = {

}

class NavigationContainerRoute extends Component<Props, State> {

  render() {
    const { } = this.props;

    return <div className={"full-size position-absolute"}>
        <NavigationContainer navigationBar={
            <div style={{height: 40, background: 'pink'}}>

            </div>
        }>
            <div className={"full-size"} style={{background: 'red'}}>

            </div>
        </NavigationContainer>
    </div>;
  }
}

export default NavigationContainerRoute;
