import React from "react";
import "./index.less";

interface Props {

}

const VSMColorBar = ({}: Props) => {

    return <table className={"vsm-color-bar"}>
        <tr>
            <td className="color1"></td>
            <td className="color2"></td>
            <td className="color3"></td>
        </tr>
    </table>;
}

export default VSMColorBar;
