import React from 'react';
import ContentHeader from "../../components/Typo/ContentHeader";
import {useTranslation} from "react-i18next";
import Panel from "../../components/Panel";
import CreateClientSteps from "../../components/CreateClientSteps";

const ClientCreateRoute = ({}: Props) => {

    const {t} = useTranslation();

    return (
        <div>
            <ContentHeader
                title={t('New client')}
            />

            <Panel  title={t("Please provide some details")}>
                <CreateClientSteps />
            </Panel>
        </div>
    );
}

export default ClientCreateRoute;
