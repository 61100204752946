// @flow
import React from 'react';
import _ from 'lodash';
import {Checkbox, Dropdown, Menu, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import "../GroupedTable/index.css";
import Thumbnail from "../Thumbnail";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import FileIconFactory from "../../utils/FileIconFactory";
import FileSizeLabel from "../Utilities/FileSizeLabel";
import {useTranslation} from "react-i18next";
import {LightgalleryItem, useLightgallery} from "react-lightgallery";

const SubMenu = Menu.SubMenu;

interface Props {
    dataSource: Array,
    groupKey: String,
    groups: Array,
    onCategoryClick: Function,
    onPrivateChange: Function,
    onMenuClick: Function,
    rowSelection: any,
    selectedRowIds: any[],
    customActions: any,
}

const TileFilelist = ({dataSource, groupKey, groups, onCategoryClick, onPrivateChange, onMenuClick, rowSelection, selectedRowIds, customActions}: Props) => {

    const {t} = useTranslation();
    const {openGallery} = useLightgallery();

    const onChange = (checkbox: any, item?: any) => {
        if (rowSelection && rowSelection.onSelect) {
            rowSelection.onSelect(item, checkbox.target.checked, dataSource);
        }
    };

    const renderItems = (children) => {
        return  <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        }}
        >
            {
                _.map(children, (item, index) => {
                    const thumb = _.get(item, 'thumbnailLink');

                    let categoriesSubmenu = null;
                    if (groups) {
                        categoriesSubmenu =
                            <SubMenu key="category"
                                     title={<span><MaterialCommunityIcon name="tag"/> {t('Category')}</span>}>
                                {groups.map(category => (
                                    <Menu.Item key={category.index}>{item.category === category.index ?
                                        <MaterialCommunityIcon name="checkbox-marked"/> :
                                        <MaterialCommunityIcon name="checkbox-blank-outline"/>} {category.title}</Menu.Item>
                                ))}
                            </SubMenu>
                    }

                    const menu = (
                        <Menu onClick={(data) => {
                            onMenuClick(item, data)
                        }}>
                            <Menu.Item key="download"><a href={item.link} download={item.filename} target={"_BLANK"}>
                                <MaterialCommunityIcon name="download"/> {t('Download')}
                            </a></Menu.Item>
                            {/*<Menu.Item key="edit"><MaterialCommunityIcon name="pencil"/> {t('Edit')}</Menu.Item>*/}
                            {onCategoryClick ? categoriesSubmenu : undefined}
                            {onPrivateChange ? item.private
                                ? <Menu.Item key="public"><MaterialCommunityIcon name="share-variant"/> {t('Share')}
                                </Menu.Item>
                                : <Menu.Item key="private"><MaterialCommunityIcon name="lock"/> {t('Private')}</Menu.Item> : null}

                            {
                                !item.access || _.get(item, 'access.remove', false)
                                    ? <Menu.Item key="delete"><MaterialCommunityIcon name="delete"/> {t('Delete')}</Menu.Item>
                                    : null
                            }
                            {
                                customActions
                                    ? customActions.map((action) => {
                                        return action.check
                                            ? <Menu.Item key={_.get(action, 'key')}><Icon type={_.get(action, 'icon')}/> {_.get(action, 'text')}</Menu.Item>
                                            : null
                                    })
                                    : null
                            }
                        </Menu>
                    );

                    return <div key={index}
                                className={"grey-lighter-bg"}
                                style={{
                                    width: 250,
                                    height: 250,
                                    padding: 5,
                                    margin: 10,
                                    border: '1px solid #e8e8e8',
                                    borderRadius: 5,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                    >
                        <div style={{position: "relative"}}>
                            <Checkbox
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px'
                                }}
                                checked={selectedRowIds.indexOf(item.id) > -1}
                                onChange={(e) => onChange(e, item)}
                                id={item.id}
                            />
                            <Dropdown overlay={menu}>
                                <div style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                }}>
                                    <Icon type="down" />
                                </div>
                            </Dropdown>
                        </div>
                        {
                            thumb
                                ?
                                    <div style={{display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                                        <LightgalleryItem src={_.get(item, 'link')} group={"file-tile-gallery"} thumb={thumb}>
                                            <Thumbnail size={150} link={thumb}/>
                                        </LightgalleryItem>
                                    </div>

                                : <a style={{display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}
                                     onClick={() => {
                                         if (item && item.link) {
                                             window.open(item.link)
                                         }}
                                     }
                                >
                                    <MaterialCommunityIcon size={75} {...FileIconFactory.getIcon(item.contentType)}/>
                                </a>
                        }
                        <div style={{padding: '5px 5px 0px 5px', borderTop: '1px solid #e8e8e8'}}>
                            <div style={{fontWeight: 'bold', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                <Tooltip
                                    title={item.private ? t('This file is private') : t('Share file between client and service provider')}
                                >
                                    <MaterialCommunityIcon name={item.private ? 'lock' : 'share-variant'}/>
                                </Tooltip>
                                {' ' + item.filename}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <span>{item.contentType}</span>
                                <FileSizeLabel bytes={item.size}/>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    }

    const orderedGroup = _.orderBy(groups, ['title'], ['asc']);

    return (
        <div style={{width: '100%'}}>
            {
                groups ? _.map(orderedGroup, (groupItem, groupIndex) => {
                    const children = _.filter(dataSource, data => data[groupKey] === groupItem.index);

                    if (!children.length) return null;

                    const activeChildren = children.filter((child) => {
                        return selectedRowIds ? selectedRowIds.indexOf(child.id) >= 0 : false
                    });

                    return <div key={groupIndex}>
                        <div className={'category-row'}
                             style={{
                                 fontWeight: 'bold',
                                 padding: '6px 8px',
                                 borderBottom: '1px solid #e8e8e8'
                             }}
                        >
                            <Checkbox
                                indeterminate={activeChildren.length > 0 && activeChildren.length !== children.length}
                                onChange={(e) => onChange(e, groupItem)}
                                checked={activeChildren.length === children.length}
                                style={{
                                    paddingRight: 10
                                }}
                            />
                            {groupItem.title}
                        </div>
                        {renderItems(children)}
                    </div>

                }) : renderItems(dataSource)
            }
        </div>
    );
}

export default TileFilelist;
