// @flow
import React, {Component} from 'react';
import {Avatar} from "antd";
import Bold from "../Utilities/Bold";
import {bytesToSize} from "../../utils/fileSize";
import {withTranslation} from "react-i18next";
import {DownloadOutlined} from "@ant-design/icons";

type Props = {
    title: string,
    link: string,
    thumbnailLink: string,
    size: string,
    icon?: string,
};

type State = {}

class FileDownloadBox extends Component<Props, State> {

    render() {
        const {thumbnailLink, link, title, size, t} = this.props;

        return <a target={"_BLANK"} download href={link} className={"link border border-radius-5 p-10 flex flex-row"}>
            <div className={"flex-self-center"}>
                <Avatar icon={<DownloadOutlined/>} shape={'square'} size={'large'} src={thumbnailLink}/>
            </div>
            <div className={"ph-10"}>
                <div><Bold>{title}</Bold></div>
                <div><span>{size ? bytesToSize(size) : t('Click to download')}</span></div>
            </div>
        </a>;
    }
}

export default withTranslation()(FileDownloadBox);
