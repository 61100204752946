// @flow
import React, {Component} from 'react';
import {Link, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";

import ContentHeader from "../../components/Typo/ContentHeader";
import {Col, Grow, Row} from "../../components/Flex";
import CatalogAttributesList from "../../components/CatalogAttributesList";

import {Button} from "@dreebit/react-components";
import CatalogAttributeCreateRoute from "./attributes/create";
import CatalogAttributeDetailRoute from "./attributes/detail";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};


class AttributesRoute extends Component<Props> {



    render() {
        const {t, history} = this.props;

        return (
            <Row className={'full-size'}>
                <Col className={"br"} style={{width: "20%", minWidth: 300}}>
                    <ContentHeader
                        hideBreadcrumb
                        title={t('Attributes')}
                        description={t('Catalog Attributes')}
                        tools={<Link to={'/catalog/attributes/create'}><Button
                            icon={"plus"}>{t('New')}</Button></Link>}/>
                    <div style={{height: 'calc(100vh - 120px)', overflow: 'scroll'}}>
                        <CatalogAttributesList/>
                    </div>
                </Col>
                <Grow className={"overflow-scroll"}>
                    <Switch>
                        <Route
                            path='/catalog/attributes/create'
                            component={() => (<CatalogAttributeCreateRoute/>)}
                        />
                        <Route
                            path={'/catalog/attributes/:index'}
                            component={CatalogAttributeDetailRoute}
                        />
                        <Redirect exact from={'/catalog/attributes'} to={'/catalog/attributes/create'}/>
                    </Switch>
                </Grow>
            </Row>
        );
    }
}

export default withRouter(withTranslation()(AttributesRoute));
