// @flow
import React from "react"
import _ from 'lodash';
import {Route, Switch} from "react-router-dom";
import ServiceTicketDashboardHeader from "../../components/ServiceTicketDashboard/header";
import Error from "../../components/Error";
import ServiceTicketContaminationDeclarationRoute from "./contaminationDeclaration";
import ServiceTicketDashboardRoute from "./dashboard";
import ServiceTicketFinalTestRoute from "./finalTest";
import ServiceActionRoute from "./action";
import Redirect from "./../Redirect";
import ServiceTicketLogTimelineRoute from './ticketLogs';
import ServiceTicketActionsRoute from "./ticketActions";
import ServiceActionCreateRoute from "./actionCreate";
import ServiceTicketAttributesRoute from "./ticketAttributes";
import ServiceTicketWarrantyReportRoute from "./warrantyReport";
import {useQuery} from "@apollo/client";
import ServiceTicketQuery from "../../graphql/queries/serviceTicketDashboard.graphql";
import ReconDetailView from "../../components/ReconDetailView";
import ServiceTicketRemove from "../../components/ServiceTicketRemove";
import ServiceTicketQualityIssueRoute from "./qualityIssue";
import ServiceTicketServiceReportRoute from "./serviceReports";
import Loader from "../../components/Loader";

type Props = {
    match: {
        params: {
            id: string
        }
    },
    history: {
        push: Function
    },
    t: Function,
}

const ServiceTicketRoute = (props: Props) => {

    const ticketId = _.get(props, 'match.params.id');

    const { loading, error, data } = useQuery(ServiceTicketQuery, {
        fetchPolicy: "network-only",
        variables: {
            id: ticketId
        }
    })

    if (loading) {
        return <Loader/>
    }

    const serviceTicket = _.get(data, 'serviceTicket');

    if (!serviceTicket) {
        const description = error && _.get(error, 'message');
        return <Error status={'404'} description={description}/>
    }

    const readOnly = serviceTicket.access.write === false && serviceTicket.access.read === true

    return <div className={"full-size overflow-scroll-y"}>
        <ServiceTicketDashboardHeader
            serviceTicketId={ticketId}
        />

        <Switch>
            <Route exact path="/service/:id/dashboard" component={ServiceTicketDashboardRoute}/>
            {!readOnly ? <Route exact path="/service/:id/remove" component={ServiceTicketRemove}/> : null}
            {!readOnly ? <Route exact path="/service/:id/recon" component={() => <ReconDetailView serviceTicketId={ticketId}/>}/>: null}
            <Route exact path="/service/:id/contaminationDeclaration" component={ServiceTicketContaminationDeclarationRoute}/>
            <Route exact path="/service/:id/finalTest" component={ServiceTicketFinalTestRoute}/>
            <Route path="/service/:id/serviceReports" component={ServiceTicketServiceReportRoute}/>
            <Route exact path="/service/:id/warrantyReport" component={ServiceTicketWarrantyReportRoute}/>
            <Route exact path="/service/:id/qualityIssue" component={ServiceTicketQualityIssueRoute}/>
            <Route exact path="/service/:id/actions" component={ServiceTicketActionsRoute}/>
            {!readOnly ? <Route exact path="/service/:id/actions/add" component={ServiceActionCreateRoute}/>: null}
            <Route path="/service/:id/actions/:serviceActionId" component={ServiceActionRoute}/>
            <Route path="/service/:id/attributes/:index" component={ServiceTicketAttributesRoute}/>
            <Redirect path="/service/:id/attributes" to="/service/:id/attributes/default"/>
            <Route path="/service/:id/logs" component={() => <ServiceTicketLogTimelineRoute ticketId={ticketId}/>}/>
            <Redirect exact from="/service/:id" to="/service/:id/dashboard"/>
        </Switch>
    </div>
}

export default ServiceTicketRoute;