// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

import NotificationSettingList from "../../graphql/queries/notificationSettingList.graphql";
import QueryTable from "../QueryTable";
import Toolbar from "../Toolbar";
import {Button} from "@dreebit/react-components";
import {toastError} from "../../utils/toast";
import DeleteNotificationSettingMutation from "../../graphql/mutations/deleteNotificationSetting.graphql";
import NotificationSettingsListTableIcons from "../NotificationSettingsListTableIcons";

type Props = {
    t: Function,
    onClick: Function,
    notificationSettingListQuery: query,
    deleteNotificationSetting: Function,
};

type State = {
    selectedRowIds: any[],
    loading: boolean,
}

class NotificationSettingsListTable extends Component<Props, State> {

    state = {
        selectedRowIds: [],
        loading: false,
    };
    _handleDelete = () => {

        const {deleteNotificationSetting} = this.props;

        this.setLoading(true)
            .then(() => {
                const promises = this.state.selectedRowIds.map(id => deleteNotificationSetting({variables: {id}}));
                return Promise.all(promises);
            })
            .catch(toastError)
            .finally(() => {
                this.setState({
                    selectedRowIds: [],
                });
                this.setLoading(false);
            })

    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {notificationSettingListQuery, t, onClick} = this.props;
        const {selectedRowIds} = this.state;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {

                this.setState({
                    selectedRowIds: selectedRows.map(item => item.id)
                })
            },
            getCheckboxProps: record => ({
                disabled: !record.access.remove, // Column configuration not to be checked
                name: record.name,
            }),
        };

        return <div>
            {selectedRowIds.length ? <Toolbar
                renderLeft={() => <Button onClick={this._handleDelete}>{t('Delete')}</Button>}
            /> : null}
            <QueryTable
                loading={this.state.loading}
                rowSelection={rowSelection}
                className={"link-table"}
                size={'middle'}
                query={notificationSettingListQuery}
                itemsKeyPath={'notificationSettingList.notificationSettings'}
                totalKeyPath={'notificationSettingList.total'}
                onRow={(record) => {
                    return {
                        onClick: () => onClick ? onClick(record) : null
                    };
                }}
                rowKey={"id"}
                columns={[
                    {
                        dataIndex: 'index',
                        key: 'index',
                        width: 75,
                        render: (text) => {
                            return <NotificationSettingsListTableIcons type={text} width={'40px'}/>
                        }
                    },
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: t('Name')
                    },
                    {
                        dataIndex: 'notificationType.description',
                        key: 'notificationType.description',
                        title: t('Description'),
                    }
                ]}
            />
        </div>
    }
}


export default compose(
    withTranslation(),
    graphql(NotificationSettingList, {
        name: 'notificationSettingListQuery',
    }),
    graphql(DeleteNotificationSettingMutation, {
        name: 'deleteNotificationSetting',
        options: () => ({
            refetchQueries: [{
                query: NotificationSettingList
            }]
        })
    }),
)(NotificationSettingsListTable);

