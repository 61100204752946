// @flow
import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";

import {Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ContentWrapper from "../../../components/ContentWrapper";
import _ from "lodash";
import ContentHeader from "../../../components/Typo/ContentHeader";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import CatalogAttributeQuery from "../../../graphql/queries/CatalogAttribute.graphql";
import waitWhileLoading from "../../../hoc/waitWhileLoading";
import CatalogAttributeDashboard from "../../../components/CatalogAttributeDashboard";
import CatalogAttributeProducts from "../../../components/CatalogAttributeProducts";
import {toastError, toastMessage} from "../../../utils/toast";
import DeleteCatalogAttributeMutation from "../../../graphql/mutations/DeleteCatalogAttribute.graphql";
import CatalogAttributesListQuery from "../../../graphql/queries/CatalogAttributesList.graphql";
import Panel from "../../../components/Panel";
import statics from "../../../statics";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};

class CatalogAttributeDetailRoute extends Component<Props> {

    state = {
        deleting: false
    };

    _handleDelete = () => {
        const {deleteCatalogAttributeMutation, catalogAttributeQuery, onDeleted, history} = this.props;
        const index = _.get(catalogAttributeQuery, 'catalogAttribute.index');

        this.setLoading(true)
            .then(() => deleteCatalogAttributeMutation({
                variables: {
                    index: index
                }
            }))
            .then((res) => {
                toastMessage(_.get(res, 'data.deleteCatalogAttribute.message'));
                if (onDeleted) onDeleted(res);
            })
            .then(() => {
                history.push('/catalog/attributes/create')
            })
            .catch((err) => toastError(err))
            .finally(() => {
                this.setLoading(false);
            })
    };

    setLoading = (deleting) => {
        return new Promise((resolve) => {
            this.setState({
                deleting
            }, resolve)
        })
    };

    render() {
        const {t, history, catalogAttributeQuery} = this.props;

        const catalogAttribute = catalogAttributeQuery.catalogAttribute;

        return (
            <div>
                <ContentHeader title={catalogAttribute.name}
                               tools={<Popconfirm placement="bottomRight" title={t("Are you sure delete this task?")}
                                                  onConfirm={this._handleDelete} okText={t("Yes")} cancelText={t("No")}
                                                  icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                                   <Button loading={this.state.deleting} type="danger" icon="delete">{t('Delete')}</Button>
                               </Popconfirm>}/>
                <ContentWrapper>
                    <Switch>
                        <Route
                            exact path='/catalog/attributes/:index'
                            component={(props) => (<CatalogAttributeDashboard index={props.match.params.index}/>)}
                        />
                        <Route
                            path='/catalog/attributes/:index/products'
                            component={(props) => (<Panel title={t("Products")}>
                                <CatalogAttributeProducts index={props.match.params.index}/>
                            </Panel>)}
                        />
                    </Switch>
                </ContentWrapper>
            </div>
        );
    }
}


export default compose(
    withRouter,
    withTranslation(),
    graphql(CatalogAttributeQuery, {
        name: 'catalogAttributeQuery',
        options: (props: Props) => ({
            variables: {
                index: _.get(props, 'match.params.index', '')
            }
        }),
    }),
    graphql(DeleteCatalogAttributeMutation, {
        name: 'deleteCatalogAttributeMutation',
        options: () => ({
            refetchQueries: [{
                query: CatalogAttributesListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                        sort: {
                            name: 'ASC'
                        }
                    }
                }
            }]
        }),
    }),
    waitWhileLoading('catalogAttributeQuery', ['catalogAttribute']),
)(CatalogAttributeDetailRoute);

