import _ from "lodash";
import { useSelector } from 'react-redux'


export default function useResources() {

    return useSelector((state) => {
        return _.get(state, 'settings.resources')
    })
};
