import React from 'react';
import ComponentStatisticListQuery from "../../graphql/queries/componentStatisticList.graphql";
import {useQuery} from "@apollo/client";
import {Spin, Row, Col, Card, Statistic} from "antd";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {

}

const ComponentStatistics = ({}: Props) => {

    const componentStatisticListQuery = useQuery(ComponentStatisticListQuery)
    const history = useHistory();
    const {t, i18n} = useTranslation();

    if (componentStatisticListQuery.loading) {
        return <Spin />
    }

    const componentStatistics = _.get(componentStatisticListQuery, 'data.componentStatisticList.componentStatistics')

    return (
        <Row gutter={[16, 16]}>
            {
                componentStatistics.map((item) => {
                    const url = new URL(item.componentList.link);
                    const link = url.searchParams.get('redirect');

                    return <Col span={6} onClick={() => history.push(link)}>
                        <Card bordered={false}>
                            <Statistic
                                title={t(item.name)}
                                value={item.componentList.total}
                                valueStyle={{ color: '#656565' }}
                                groupSeparator={i18n.language === 'de' ? '.' : ','}
                            />
                        </Card>
                    </Col>
                })
            }
        </Row>
    );
}

export default ComponentStatistics;
