// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
// import ClientInfoQuery from "../../graphql/queries/ClientInfoBox.graphql";
import ClientInfoQuery from "../../graphql/queries/ComponentClientInfo.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import SplitPanel from "../SplitPanel";
import withProfile from "../../hoc/withProfile";

type Props = {
    componentType: string,
    componentId?: any
};

type State = {

}

class IconSplitPanelSharedClient extends Component<Props, State> {

  render() {
    const { clientInfoQuery, t, profile, componentType } = this.props;

    const totalShared = _.get(clientInfoQuery,'client.sharedHostList.total', 0);

    if (totalShared > 0) {
        const hosts = _.get(clientInfoQuery,'client.sharedHostList.clients',[]).map(item => item.name ).join(", ");

        return <SplitPanel
            link={`/admin/clients/${_.get(clientInfoQuery,'client.id')}/share`}
            className={"mb-15"}
            title={componentType === "client" ? t('This client is shared with NAME', {NAME: hosts}) : t('This component is shared with NAME', {NAME: hosts})}
            description={t('Click here for more information')}
            icon={'share-alt'}
        />;
    }

    // deprecated: Komponenten werden nicht mehr in dem Sinn geteilt, sondern es können einfach mehrere clients Zugriff darauf haben
    // const profileHost = _.get(profile, 'client.host.id');
    // const clientHostId = _.get(clientInfoQuery,'component.client.serviceProvider.id');
    //
    // if (profileHost !== clientHostId && _.get(clientInfoQuery,'component.client.serviceProvider.name')){
    //     return <SplitPanel
    //         className={"mb-15"}
    //         title={t('This component is shared by NAME', {NAME: _.get(clientInfoQuery,'component.client.serviceProvider.name')})}
    //         description={t('You have limit access to this component')}
    //         icon={'share-alt'}
    //     />;
    // }

    return null;

  }
}


export default compose(
    graphql(ClientInfoQuery, {
        name: 'clientInfoQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId,
            }
        }),
    }),
    waitWhileLoading('clientInfoQuery', ['component.client']),
    withTranslation(),
)(IconSplitPanelSharedClient);

