import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import _ from "lodash";
import NewsList from "../../components/NewsList";
import Loader from "./../../components/Loader"
import NewsListQuery from "./../../graphql/queries/NewsList.graphql"
import {useQuery} from "@apollo/client";
import statics from "../../statics";
import NewsDetailRoute from "./detail";
import {Col, Row} from "antd";
import ContentHeader from "../../components/Typo/ContentHeader";
import {useTranslation} from "react-i18next";

interface Props {

}

const NewsRoute = (props: Props) => {

    const {t} = useTranslation();
    const query = useQuery(NewsListQuery, {
        variables: {
            params: {
                limit: statics.defaultPagingSize,
                start: 0
            }
        }
    });

    if (query.loading) return <Loader/>;


    return <Row className={"calculatedBody"}>
        <Col className="full-height overflow-scroll bg-white br" sm={10} md={8} lg={6} xl={5} xxl={4}>
            <ContentHeader hideBreadcrumb title={t('News')}/>
            <NewsList/>
        </Col>
        <Col className="full-height overflow-scroll" sm={14} md={16} lg={18} xl={19} xxl={20}>
            <Switch>
                <Route path="/news/:id" component={NewsDetailRoute}/>
                <Redirect exact from="/news"
                          to={`/news/${_.get(query,'data.newsList.news[0].id')}`}/>
            </Switch>
        </Col>
    </Row>

};

export default NewsRoute;
