// @flow
import React, {Component} from 'react';
import Dropzone from 'react-dropzone'
import * as axios from "axios";
import {message} from "antd";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import _ from "lodash";
import Loader from "../Loader";
import type {FileUploadResponse} from "../../types";
import {connect} from "react-redux";

type Props = {
    t: Function,
    children: any,
    multiple: boolean,
    loaderSize?: string,
    dropzoneProps: any,
    onUploaded?: (response: FileUploadResponse) => void
};

type State = {
    loading: boolean
}

class FileUploadDropZone extends Component<Props, State> {

    state = {
        loading: false,
    };

    getHeader() {
        if (!this.props.token) return;
        const token = this.props.token;
        return {
            authorization: token ? `Bearer ${token}` : null,
        }
    }

    uploadFile(files): Promise<{
        response: any,
        blob: any,
    }> {
        return Promise.all(files.map((file) => {
            const formData = new FormData();
            formData.append('file', file);

            return axios.post('/file', formData, {
                headers: this.getHeader()
            }).then(response => {
                return response.data
            })
        }))

    }

    handleDrop = (file: File) => {

        const {t} = this.props;

        this.setState({
            loading: true,
        }, () => {
            this.uploadFile(file)
                .then((result) => {
                    const {onUploaded} = this.props;
                    if (onUploaded) onUploaded(result);
                })
                .catch((err) => {
                    console.error(err);
                    message.error(_.get(err, 'message', t('Could not upload file')));
                })
                .finally(() => {
                    this.setState({
                        loading: false
                    })
                })
        })
    };

    render() {
        const {dropzoneProps, loaderSize, multiple} = this.props;
        const {loading} = this.state;

        return <div style={{position: 'relative'}}>
            <Dropzone
                disableClick
                multiple={multiple}
                onClick={() => {
                }}
                {...dropzoneProps}
                onDrop={this.handleDrop}
            >
                {this.renderChildren}
            </Dropzone>
            {loading ? <Loader size={loaderSize} absolute mask hideTitle/> : null}
        </div>;
    }

    renderChildren = (dropzoneState) => {
        const {children} = this.props;
        if (typeof children === 'function') {
            return children(dropzoneState)
        }
        return children
    }
}

export default compose(
    connect((state) => ({
        token: _.get(state, 'auth.token')
    })),
    withTranslation()
)(FileUploadDropZone);
