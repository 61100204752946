// @flow
import React, {Component} from 'react';
import {DatePicker, Form, Input, Select} from 'antd';
import {Button} from "@dreebit/react-components";
import kpiListConfig from './kpi-list-config'
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ComponentTypeListQuery from "../../graphql/queries/componentTypeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash"

type Props = {};

type State = {
  userParams: any[];
}

class KpiSettings extends Component<Props, State> {
  state = {
    userParams: []
  };
  componentDidMount() {}

  render() {
    const { componentTypeListQuery } = this.props;

    const componentTypeList = _.get(componentTypeListQuery, 'componentTypeList.componentTypes');
    const {Option} = Select;
    const { RangePicker, MonthPicker } = DatePicker;

    const onKpiChange = value => {
      switch (value) {
        case "a_doneServiceTickets":
          this.setState({userParams: ["dateRangeYear"]});
          return;
        case "a1_doneExternalServiceTickets":
          this.setState({userParams: ["dateRangeYear"]});
          return;
        case "a2_doneInternalServiceTickets":
          this.setState({userParams: ["dateRangeYear"]});
          return;
        case "b_doneExternalServiceTicketsMonth":
          this.setState({userParams: ["dateRangeMonth"]});
          return;
        case "c_doneExternalServiceTicketsMonthComponentType":
          this.setState({userParams: ["dateRangeMonth", "componentType"]});
          return;
        case "d_functionalBlockRecons":
          this.setState({userParams: ["dateRangeYear"]});
          return;
        case "d1_functionalBlockReconsMonth":
          this.setState({userParams: ["dateRangeMonth"]});
          return;
        default:
          this.setState({userParams: []});
          return;
      }
    };

    return <div style={{width: '50%', marginTop: '30px', margin:'auto'}}>
      <Form>
        <Form.Item name="panelName" label="Panelname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="kpiType" label="KPI" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder="Wählen Sie einen KPI"
            onChange={onKpiChange}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            { kpiListConfig.map((item) => {
              return <Option value={item.name}>{item.key} - {item.description}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.kpiType !== currentValues.kpiType}
        >
          {this.state.userParams.length > 0 && this.state.userParams.includes('dateRangeYear') ? (
              <Form.Item
                name="dateRange"
                label="Datum"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <RangePicker picker="year" />
              </Form.Item>
            ) : null}

          {this.state.userParams.length > 0 && this.state.userParams.includes('dateRangeMonth') ? (
            <Form.Item
              name="dateRange"
              label="Datum"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker picker="month" />
            </Form.Item>
          ) : null}

          {this.state.userParams.length > 0 && this.state.userParams.includes('componentType') ? (
            <Form.Item
              name="componentType"
              label="Komponententyp"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select mode="multiple">
                { componentTypeList.map((item) => {
                  return <Option value={item.index}>{item.name}</Option>
                })}
              </Select>
            </Form.Item>
          ) : null}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Hinzufügen
          </Button>
        </Form.Item>
      </Form>
    </div>;
  }
}

export default compose(
  graphql(ComponentTypeListQuery, {
    name: 'componentTypeListQuery',
  }),
  waitWhileLoading('componentTypeListQuery', 'componentTypeList.componentTypes'),
)(KpiSettings);
