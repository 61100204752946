// @flow
import React, {Component} from 'react';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {Col, Grow, Row} from "../Flex/index";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import {Popconfirm} from 'antd';
import {Button, Icon} from "@dreebit/react-components";
import _ from 'lodash';
import ServicePlanFormHeaderQuery from "../../graphql/queries/servicePlanFormHeader.graphql";
import DeleteServicePlanMutation from "../../graphql/mutations/deleteServicePlan.graphql";
import {plannedToColor} from "../ServicePlanListItem/index";
import styled from "styled-components";
import moment from "moment/moment";
import {withTranslation} from "react-i18next";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import ServicePlanAndControlQuery from "../../graphql/queries/servicePlanAndControlList.graphql";


type Props = {
    servicePlanId: string,
    servicePlanFormHeaderQuery: any,
    deleteServicePlanMutation: Function,
    t: Function,
    onDelete: Function,
};


const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  background-color: white;
  color: grey;
`;

const LeftBorder = styled.div`
  width: 2px;
  background-color: ${(props) => (plannedToColor(props.isPlanned))}
  height: 100%;
  position: absolute;
`;
const Title = styled.span`
  font-size: 1.3em;
  margin-left: 7px;
  color: grey;
`;

const DueDate = styled.span`
  color: grey;
`;

const Updated = styled.span`
  color: grey;
  margin-top: 5px;
`;

const Insert = styled.span`
  color: grey;
  margin-top: 5px;
`;


class ServicePlanFormHeader extends Component<Props> {

    handleDelete = () => {
        const {deleteServicePlanMutation, onDelete} = this.props;

        deleteServicePlanMutation().then((res) => {
            if (onDelete) {
                onDelete(res);
            }
        })
    };

    render() {

        const {servicePlanFormHeaderQuery, t} = this.props;

        const servicePlan = _.get(servicePlanFormHeaderQuery, 'servicePlan');


        return <Wrapper>
            <Row>
                <LeftBorder isPlanned={servicePlan.isPlanned}/>
                <Grow className={'mh-10 mv-5'}>
                    <Col className={'p-5'}>
                        <Row className={'flex-align-items-center'}>
                            <FontAwesomeIcon name={servicePlan.isPlanned ? 'calendar-check' : 'calendar-times'}
                                             circled={false} size={'1.5x'}/>
                            <Title className={'flex-grow-1'}>{servicePlan.name}</Title>
                            <Popconfirm
                                title={t('Please confirm to delete this item')}
                                icon={<Icon type="delete" style={{color: 'red'}}/>}
                                onConfirm={this.handleDelete}
                                okText={t('Confirm')}
                                cancelText={t('Cancel')}
                            >
                                <Button icon="delete" type="danger">{t('Remove')}</Button>
                            </Popconfirm>
                        </Row>
                        <DueDate>{t('Due to')}: {moment(servicePlan.dueDate).format('LL')}</DueDate>
                        <Row>
                            <Updated
                                className={'flex-grow-1'}>{t('Last update')} {moment(servicePlan.update).fromNow()}</Updated>
                            <Insert>{t('Insert by')} {servicePlan.createdBy.name} {moment(servicePlan.insert).fromNow()}</Insert>
                        </Row>
                    </Col>
                </Grow>
            </Row>
        </Wrapper>;
    }
}


export default compose(
    graphql(DeleteServicePlanMutation, {
        name: 'deleteServicePlanMutation',
        options: props => ({
            refetchQueries: [{
                query: componentQuery,
                variables: {
                    id: props.componentId
                }
            }, {
                query: ServicePlanAndControlQuery,
                variables: {
                    params: {
                        filter: {
                            componentId: {
                                value: props.componentId,
                            }
                        }
                    }
                }
            }]
        })
    }),
    graphql(ServicePlanFormHeaderQuery, {
        name: 'servicePlanFormHeaderQuery',
        options: props => ({
            variables: {
                servicePlanId: props.servicePlanId
            }
        }),
    }),
    waitWhileLoading('servicePlanFormHeaderQuery', ['servicePlan'])
)(withTranslation()(ServicePlanFormHeader))

