// @flow
import React, {Component} from "react"
import {Col, Row} from "antd";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import ComponentTypeList from "./../ComponentTypeList";
import ComponentCreateProduct from "./ComponentCreateProduct";
import Panel from "../Panel";
import {compose} from "recompose";

type Props = {
    t: Function,
    onSelectComponentType: ?Function,
    onSelectProduct: ?Function,
    onCreateProduct: ?Function,
}

class ComponentCreateComponentTypes extends Component<Props> {

    render() {

        const {t, onSelectComponentType, onCreateProduct, onSelectProduct, history, parentId} = this.props;
        const stockTypes = ['stock', 'shelf', 'box'];
        const blacklist = ['componentProduct', 'trashPool', 'globalTrashPool'];

        /*
        <Panel title={t('Use one time token')}>
                        <Toolbar className={"mb-10"} style={{padding: 0}}
                            renderLeft={() => <div>{t('Please enter your component access token')}:</div>}
                                 renderRight={() => <Tooltip title={t('Component access token info')}><Icon type={'info-circle'}/></Tooltip>}
                        >

                        </Toolbar>
                        <TokenInput onEnter={(token) => history.push(`/infrastructure/create/token/${token}?parentId=${parentId}`)}/>
                    </Panel>
         */

        return <div>
            <Row gutter={16}>
                <Col md={12}>
                    <div tour-id="createComponent__newDevice">
                        <Panel title={t('New device')}>
                            <ComponentCreateProduct onProductExposed={onCreateProduct}
                                                    onProductSelect={onSelectProduct}/>
                        </Panel>
                    </div>
                </Col>
                <Col md={12}>
                    <div tour-id="createComponent__newUnit">
                        <Panel title={t('Infrastructure unit')} containerStyle={{padding: 0, border: "none"}}>
                            <ComponentTypeList style={{marginBottom: 8}} title={t('General')} filter={(item) => {
                                return stockTypes.indexOf(item.index) === -1 && blacklist.indexOf(item.index) === -1 && (!item.product || item.index === "machine")
                            }} onPress={(item) => onSelectComponentType(item.index)}/>
                        </Panel>

                        <ComponentTypeList title={t('Stock')} filter={(item) => {
                            return stockTypes.indexOf(item.index) !== -1 && blacklist.indexOf(item.index) === -1 && (!item.product || item.index === "machine")
                        }} onPress={(item) => onSelectComponentType(item.index)}/>
                    </div>
                </Col>
            </Row>
        </div>;
    }

}

export default compose(
    withTranslation(),
    withRouter
)(ComponentCreateComponentTypes);
