// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ComponentProductIdQuery from "../../graphql/queries/ComponentProductId.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ProductInfoBox from "../ProductInfobox";

type Props = {

};

type State = {

}

class ComponentProductInfo extends Component<Props, State> {

  render() {
    const { componentProductQuery } = this.props;

    return <ProductInfoBox productId={_.get(componentProductQuery, 'component.product.id')} />;
  }
}

export default compose(
    graphql(ComponentProductIdQuery, {
      name: 'componentProductQuery',
      options: props => ({
        variables: {
          componentId: props.componentId
        }
      })
    }),
  waitWhileLoading('componentProductQuery')
)(ComponentProductInfo);
