// @flow
import React, { Component } from 'react';
import Panel from "../Panel";
import {withTranslation} from "react-i18next";
import ProductMatch from "../ProductMatch";

type Props = {

};

type State = {

}

class ProductMatchWrapper extends Component<Props, State> {

  render() {
    const { productId, t } = this.props;

    return <div className={"p-10"}>
        <Panel title={t("Match a product")}>
            <ProductMatch productId={productId}/>
        </Panel>
    </div>;
  }
}

export default withTranslation()(ProductMatchWrapper);
