// @flow
import React, {Component} from "react"
import _ from "lodash";
import ComponentCreateComponentTypes from "../../components/ComponentCreate/ComponentCreateComponentTypes";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import queryString from "query-string";

type Props = {
    match: {
        params: {
            id: string
        }
    },
    history: {
        push: Function
    }
}

class InfrastructureCreateComponentComponentType extends Component<Props> {

    redirectUrl = null;

    constructor(props) {
        super(props);

        this.redirectUrl = `/infrastructure/create/${this.props.match.params.id}/details`;
    }

    onSelectComponentType = (componentTypeIndex) => {
        this.redirect({componentTypeIndex});
    };

    onCreateProduct = (product) => {
        console.log(product)
        this.redirect({
            componentTypeIndex: 'componentProduct',
            product
        });
    };

    onSelectProduct = (productId) => {
        this.redirect({
            productId
        });
    };

    redirect(params) {
        const {history} = this.props;
        params.product = queryString.stringify(params.product);

        const stringified = queryString.stringify(params);
        history.push(`${this.redirectUrl}?${stringified}`)
    }

    render() {

        return <ComponentCreateComponentTypes
            parentId={_.get(this.props, 'match.params.id')}
            onSelectComponentType={this.onSelectComponentType}
            onCreateProduct={this.onCreateProduct}
            onSelectProduct={this.onSelectProduct}/>;
    }

}

export default withRouter(withTranslation()(InfrastructureCreateComponentComponentType));
