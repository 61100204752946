// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Modal} from 'antd';
import {Button} from "@dreebit/react-components";
import _ from "lodash";
import UpdateAccessToken from "../../graphql/mutations/updateAccessToken.graphql";
import AccessToken from "../../graphql/queries/accessToken.graphql";

type Props = {
    selectData: any,
    t: Function,
    showModal: any,
    onCancel: Function,
    id: any,
    updateAccessTokenMutation: Function,
    data: any,
}

class JsonDataMenu extends Component<Props, State> {

    handleDelete = () => {
        const {updateAccessTokenMutation, data, selectData, onCancel, id} = this.props;

        const selectedSplit = _.get(selectData, 'node.props.title', '').split(': ');
        const key = selectedSplit[0];
        delete data[key];

        updateAccessTokenMutation({variables: {id: id, attributes: {data: JSON.stringify(data)}}}).then(() => {
            onCancel();
        })
    };

    render() {
        const {selectData, t, showModal, onCancel} = this.props;

        const title = _.get(selectData, 'node.props.title', '');

        return <Modal
            title={title}
            footer={null}
            visible={showModal}
            onOk={onCancel}
            onCancel={onCancel}
        >
            <Button
                type={'danger'}
                onClick={this.handleDelete}>{t('Delete Bookmark')}
            </Button>
        </Modal>
    }

}

export default compose(
    graphql(UpdateAccessToken, {
        name: 'updateAccessTokenMutation',
        options: props => ({
            refetchQueries: [{
                query: AccessToken,
                variables: {
                    id: props.id
                }
            }]
        })
    }),
    withTranslation(),
)(JsonDataMenu);
