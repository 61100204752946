// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import ServiceProviderModulesQuery from './../../graphql/queries/ServiceProviderModules.graphql';
import ModulesQuery from '../../graphql/queries/ModuleList.graphql';
import ServiceProviderModuleListItem from "../ServiceProviderModuleListItem";
import type {Query} from "../../types";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Sizes from "../../themes/default/sizes";

type Props = {
    serviceProviderId: string,
    serviceProviderModulesQuery: Query,
    modulesQuery: Query,
};


class ServiceProviderModules extends Component<Props> {


    render() {

        const {serviceProviderId, serviceProviderModulesQuery, modulesQuery} = this.props;
        const modules = _.get(modulesQuery, 'moduleList.modules');
        const activeModuleKeys = _.chain(serviceProviderModulesQuery)
            .get('serviceProvider.modules')
            .map(item => (item.key))
            .value()
        ;

        return <div>
            {modules && modules.map(module => (
                <ServiceProviderModuleListItem
                    clientId={serviceProviderId}
                    serviceProviderId={serviceProviderId}
                    active={activeModuleKeys.indexOf(module.key) > -1}
                    module={module}
                    style={{
                        margin: Sizes.grid
                    }}
                    refetchQueries={[{
                        query: ServiceProviderModulesQuery,
                        variables: {
                            id: serviceProviderId
                        }
                    }]}
                />
            ))
            }
        </div>;
    }
}

export default compose(
    graphql(ModulesQuery, {
        name: 'modulesQuery',
    }),
    waitWhileLoading('modulesQuery', 'moduleList.modules'),
    graphql(ServiceProviderModulesQuery, {
        name: 'serviceProviderModulesQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceProviderId
            }
        }),
    }),
    waitWhileLoading('serviceProviderModulesQuery', 'serviceProvider.modules')
)(ServiceProviderModules);
