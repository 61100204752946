import React from "react";
import classNames from "classnames";
import "./index.css"

interface Props {

}

const ShadowPanel = ({children, className, style, topBar}: Props) => {

    return <div style={style} className={classNames("shadow-panel",className)}>
        {topBar ? <div className={"shadow-panel-header"}>{topBar}</div> : undefined }
        <div className={"shadow-panel-body"}>
            {children}
        </div>
    </div>;
}

export default ShadowPanel;
