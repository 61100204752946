import _ from "lodash";

export default (tdas: any[], configs?: {customQueries: {[key: string]: string}}) => {

    const overrideKeys = Object.keys(_.get(configs,'customQueries',{})).filter((key)=> _.find(tdas, {
        index: key
    }));

    const result = tdas.filter((tda) => overrideKeys.indexOf(tda.index) === -1).reduce((acc, tda) => {

        const index = tda.index;
        if (!index) return acc;
        const idKeyPath = `${index}`.split('.');
        idKeyPath.forEach((key, i) => {
            if (i > 0){
                const tmp = [...idKeyPath].slice(0,i+1);
                tmp[tmp.length-1] = 'id';
                _.set(acc,tmp.join('.'), null);
            }
        })

        _.set(acc,index, null);
        return acc;
    }, {});

    let resultString = JSON.stringify(result).substring(1);
    resultString = resultString.substring(0, resultString.length - 1);
    resultString = resultString.replace(/"/g,"")
    resultString = resultString.replace(/:/g," ")
    resultString = resultString.replace(/{/g,"{ ")
    resultString = resultString.replace(/}/g,"} ")
    resultString = resultString.replace(/null/g, "");
    resultString = resultString.replace(/,/g, "");

    overrideKeys.forEach((key) => {
        resultString += configs.customQueries[key] || '';
    })

    return resultString;

}
