// @flow
import React, {Component} from 'react';
import {userIsAuthenticatedRedir} from '../../utils/auth';
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import menu from './menu';
import getRightMenu from './rightMenu';
import {Header} from '../../components/Layout';
import "./styles.css"
import AppRoutes from "./routes";
import {withTranslation} from "react-i18next";
import ErrorBoundary from "../../components/ErrorBoundary";
import NavigationContainer from "../../components/NavigationContainer";
import withTheme from "../../hoc/withTheme";
import TourGuide from "../../components/TourGuide"
import {LightgalleryProvider} from "react-lightgallery";

type Props = {
    history: boolean,
    cookies: any,
    profileQuery?: any,
    t: any
}

class AppWrapper extends Component<Props, State> {
/* // OLD SSO REDIRECT
    componentDidMount(): * {
        const url = new URL(window.location);
        let urlChanged = false;

        if (url.searchParams.get('sso')) {
            urlChanged = true;
            url.searchParams.delete('sso');
        }

        let redirect = url.searchParams.get('redirect');

        const redirectLink = _.get(this.props, 'profileQuery.profile.redirectLink');
        if (!redirect && redirectLink) {
            urlChanged = true;
            redirect = redirectLink;

            this.props.updateProfileMutation({
                variables: {
                    input: {
                        redirectLink: null
                    }
                }
            })
        }

        if (redirect) {
            urlChanged = true;
            url.searchParams.delete('redirect');
        }

        if (urlChanged) {
            setTimeout(() => {
                window.history.replaceState({}, '', url);
                if (redirect) {
                    this.props.history.replace(redirect)
                }
            }, 0)
        }
    }
*/
    render() {

        const theme = this.props.theme;
        const rightMenu = getRightMenu(theme);

        return (
            <LightgalleryProvider>
                <div className={`theme-${theme}`}>
                    <NavigationContainer
                        navigationBar={<Header mainMenu={menu} theme={theme} rightMenu={rightMenu}/>}
                    >
                        <div className={"calculatedBody layout"}>
                            <ErrorBoundary>
                                <AppRoutes/>
                            </ErrorBoundary>
                        </div>
                    </NavigationContainer>
                    <TourGuide/>
                </div>
            </LightgalleryProvider>
        );
    }
}

export default compose(
    withRouter,
    userIsAuthenticatedRedir,
    withTranslation(),
    withTheme()
)(AppWrapper);
