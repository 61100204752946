// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ServiceControlPerform from "../../graphql/queries/ServiceControlPerform.graphql";

const servicecontrolsBreadcrumb = ({serviceControlPerformQuery}) =>
    <span>{_.get(serviceControlPerformQuery, 'serviceControl.name', '...')}</span>;

export default compose(
    graphql(ServiceControlPerform, {
        name: 'serviceControlPerformQuery',
        options: ({match}) => ({
            variables: {
                serviceControlId: _.get(match, 'params.serviceControlId'),
            }
        })
    }),
)(withTranslation()(servicecontrolsBreadcrumb));
