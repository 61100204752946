//@flow
import React, {Component} from "react"
import moment from "moment";
import _ from 'lodash'
import './index.css';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ServiceTicketLogHistoryHeaderQuery from "./../../graphql/queries/serviceTicketLogHistoryHeader.graphql"
import queryWrapper from "../QueryWrapper";
import {withTranslation} from "react-i18next";


type Props = {
    serviceTicketId: string,
    serviceTicketLogHistoryHeaderQuery: any,
};


class ServiceTicketLogHistoryHeader extends Component<Props> {


    render() {

        const {serviceTicketLogHistoryHeaderQuery, t} = this.props;

        const serviceTicket = _.get(serviceTicketLogHistoryHeaderQuery, 'serviceTicket');


        return <div className="log-header">

            <div className="log-last-user">
                <span><b>{t('Created at')}:</b> {moment(serviceTicket.insert).format('LLL')} ({serviceTicket.createdBy.name})</span><br/>
                <span><b>{t('Updated at')}:</b> {moment(serviceTicket.update).format('LLL')} ({serviceTicket.updatedBy.name})</span>
            </div>
        </div>

    }
}


export default compose(
    withTranslation(),
    graphql(ServiceTicketLogHistoryHeaderQuery, {
        name: 'serviceTicketLogHistoryHeaderQuery',
        options: (props: Props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.serviceTicketId
            }
        })
    })
)(queryWrapper(ServiceTicketLogHistoryHeader, (props: Props) => ({
    queryConfigs: [{
        query: props.serviceTicketLogHistoryHeaderQuery,
        checkProperties: ['serviceTicket'],
    }]
})));
