// @flow
import React, {Component} from 'react';
import {message, Radio} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from "lodash";
import FileUploader from "./../FileUploader";
import FilesTable from "./../FilesTable";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {setFileTableView} from "../../actions/settings";
import serviceTicketFileZipQuery from "../../graphql/queries/serviceTicketFileZip.graphql";
import {withRouter} from "react-router-dom";
import {withApollo} from "@apollo/client/react/hoc";

type Props = {
    readOnly?: boolean,
    addFileMutation?: Function,
    addFileMutationOptions?: Function,
    editFileMutation?: Function,
    editFileMutationOptions?: Function,
    removeFileMutation?: Function,
    removeFileMutationOptions?: Function,
    fileListQuery: any,
    fileListKey: string | string[],
    multiple: boolean,
    enablePrivate: boolean,
    customActions: any,
    categories?: any,
    checkPrivateCondition?: (file) => void,
};

type State = {
    selectedRowIds: any[]
}

class FileUploadList extends Component<Props, State> {
    state = {
        selectedRowIds: []
    }

    static defaultProps = {
        multiple: true,
        tableView: true
    };

    onDownloadClick = (selectedRowIds: any[], onSuccess: Function, onError: Function) => {
        this.setState({
            loading: true
        }, () => {
            this.props.client.query({
                query: serviceTicketFileZipQuery,
                variables:{
                    id: _.get(this.props, 'match.params.id', ''),
                    params: {
                        filter: {
                            id: {
                                value: selectedRowIds
                            }
                        }
                    }
                }
            }).then(({data}) => {
                const link = _.get(data, 'serviceTicket.serviceTicketFileList.zipFile.link');
                window.open(link, '_blank');
                onSuccess(link);
            })
                .catch((e) => {
                    onError(e);
                })
        });
    };

    onUploadDone = (fileList) => {

        const {addFileMutation, addFileMutationOptions} = this.props;
        if (addFileMutation && fileList && fileList.length) {
            const fileCreateInputs = fileList.map((uploadResult) => {
                const fileId = _.get(uploadResult, 'response.fileId');
                return {
                    fileId,
                    filename: _.get(uploadResult, 'name')
                }
            });
            const options = addFileMutationOptions ? addFileMutationOptions(fileCreateInputs) : {
                variables: {
                    files: fileCreateInputs
                }
            };
            this.setState({
                loading: true,
            }, () => {
                addFileMutation(options)
                    .then(() => {
                        message.success(this.props.t(`File was added successfully`));
                    })
                    .catch((err) => {
                        console.error(err);
                        message.error(this.props.t(`Could not link file`));
                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        });
                    })
            })

        }
    };

    handleUpload = (uploadResult) => {


    };

    handleRemove = (file) => {
        const {removeFileMutation, removeFileMutationOptions} = this.props;
        if (removeFileMutation) {
            const options = removeFileMutationOptions ? removeFileMutationOptions(file) : {
                variables: {
                    fileId: file.id
                }
            };
            return removeFileMutation(options);
        }

    };

    handleChange = (file, input?) => {
        const {editFileMutation, editFileMutationOptions} = this.props;
        if (editFileMutation) {
            const options = editFileMutationOptions ? editFileMutationOptions(file, input) : {
                variables: {
                    fileId: file.id
                }
            };
            return editFileMutation(options);
        }
    };

    render() {
        const {
            fileListQuery,
            readOnly,
            multiple,
            fileListKey,
            customActions,
            enablePrivate,
            fileTableView,
            setFileTableView,
            checkPrivateCondition,
            t
        } = this.props;

        let keys = Array.isArray(fileListKey) ? fileListKey : [fileListKey];
        const files = _.flatten(keys.map((item) => {
            return _.get(fileListQuery, item)
        }));

        const categories = _.uniqBy(
            _.compact(
                files.map((file: any) => {
                    const category = _.get(file, 'category');
                    if (category) return {
                        index: category,
                        title: t('fileCategory-' + category)
                    }
                    return null;
                })
            ),
            'index'
        );

        const table = <FilesTable
            groupCategories={categories && categories.length > 0}
            categories={categories}
            changeSelectedRowIds={(changes) => {
                this.setState({
                    selectedRowIds: changes
                })
            }}
            selectedRowIds={this.state.selectedRowIds}
            files={files}
            onDeleteClick={!readOnly ? this.handleRemove : undefined}
            onFileNameChange={(file, filename: string) => {
                if (!readOnly) {
                    return this.handleChange(file, {filename});
                }
            }}
            onPrivateChange={enablePrivate ? this.handleChange : undefined}
            customActions={customActions}
            fileTableView={fileTableView}
            onDownloadClick={this.onDownloadClick}
            checkPrivateCondition={checkPrivateCondition}
        />;

        if (readOnly) return table;

        return <div>
            <FileUploader
                multiple={multiple}
                hideSuccessMessages
                autoRemove
                onUploadDone={this.onUploadDone}
                onUpload={this.handleUpload}/>
            <div style={{marginTop: 8}}>
                <div style={{textAlign: 'right', paddingBottom: 5}}>
                    <Radio.Group onChange={(view) => setFileTableView ? setFileTableView(_.get(view, 'target.value', 'table')) : null}
                                 defaultValue={fileTableView}
                    >
                        <Radio.Button value="table">
                            <span style={{display: 'flex'}}>
                                <Icon type="unordered-list"
                                      style={{display: 'flex', alignItems: 'center', marginRight: 10}}
                                />
                                {t('List view')}
                            </span>
                        </Radio.Button>
                        <Radio.Button value="tile">
                            <span style={{display: 'flex'}}>
                                <Icon type="appstore"
                                      style={{display: 'flex', alignItems: 'center', marginRight: 10}}
                                />
                                {t('Tile view')}
                            </span>
                        </Radio.Button>
                    </Radio.Group>
                </div>
                {table}
            </div>

        </div>;
    }
}

export default compose(
    withTranslation(),
    withRouter,
    connect((state) => {
        return {
            fileTableView: _.get(state, 'settings.fileTableView')
        }
    }, (dispatch) => {
        return {
            setFileTableView: (fileTableView) => dispatch(setFileTableView(fileTableView))
        }
    }),
    withApollo,
)(FileUploadList);
