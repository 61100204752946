// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import CreateErrorPatternMutation from "./../../graphql/mutations/createErrorPattern.graphql";
import ErrorPatternListQuery from "./../../graphql/queries/errorPatternList.graphql";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import statics from "../../statics";

type Props = {};

type State = {

}

class ErrorPatternCreateForm extends Component<Props, State> {

    render() {
        const {mutation} = this.props;

        return <TypeDefinitionAttributesForm
            index="errorPattern"
            formProps={{
                mutation: mutation,
                onSubmit: (res) => {
                    if (this.props.onCreate) {
                        this.props.onCreate(res);
                    }
                },
                translateMutationOptions: ({values}) => {

                    if(!values.name.en_EN){
                        values.name.en_EN = values.name.de_DE
                    }
                    if(!values.name.de_DE){
                        values.name.de_DE = values.name.en_EN
                    }

                   return {
                        variables: {
                            input: values
                        }
                    }
                }
            }}
        />
    }
}

export default compose(
    graphql(CreateErrorPatternMutation, {
        name: 'mutation',
        options: () => ({
            refetchQueries: [{
                query: ErrorPatternListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                    }
                }
            }]
        })
    }),
)(ErrorPatternCreateForm);
