// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import TypeDefinitionAttributeListQuery from "./../../graphql/queries/typeDefinitionAttributeList.graphql";
import CreateServiceProviderUserMutation from "./../../graphql/mutations/createServiceProviderUser.graphql";
import type {Query} from "../../types";
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceProviderUsersQuery from "../../graphql/queries/serviceProviderUserList.graphql";
import {withTranslation} from "react-i18next";
import Warning from "../Warning";

type Props = {
    t: Function,
    serviceProviderId: string,
    typeDefinitionAttributeListQuery: Query,
    createServiceProviderUserMutation: Function,
    history: {
        push: Function,
    }
};

class ServiceProviderAdminForm extends Component<Props, State> {
    render() {
        const {typeDefinitionAttributeListQuery, createServiceProviderUserMutation, serviceProviderId, t} = this.props;

        return <div>
            <Warning className={"mb-20"}
                     message={t("You are able to create a new serviceProvider user Created uses will own the admin role")}
                     type={"info"}/>
            <AttributesInlineForm
                clearOnSubmit={true}
                attributes={_.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes')}
                mutation={createServiceProviderUserMutation}
                useSubmit
                submitTitle={t('Add')}
                translateMutationOptions={({values}) => {

                    return {
                        variables: {
                            serviceProviderId: serviceProviderId,
                            user: values
                        }
                    }
                }}/>
        </div>;
    }
}

export default compose(
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props: Props) => ({
            variables: {
                index: 'user',
            }
        }),
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery', 'typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(CreateServiceProviderUserMutation, {
        name: 'createServiceProviderUserMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ServiceProviderUsersQuery,
                variables: {
                    serviceProviderId: props.serviceProviderId
                }
            }]
        })
    }),
    withTranslation()
)(ServiceProviderAdminForm);
