// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Col, Row} from "antd";
import _ from "lodash";
import JSONPretty from "react-json-pretty";
import {withProps} from "recompose";
import queryString from "query-string";

import Panel from "../Panel";
import ContentWrapper from "../ContentWrapper";
import ProfileForm from "../ProfileForm";

type Props = {
    t: Function,
    componentName: any,
    params: any
};

type State = {
    response: Object
}

class DynamicForms extends Component<Props, State> {
  state = {
      response: {}
  };

  render() {
    const { t, componentName, params } = this.props;
    const { response } = this.state;

    let props = {};
    if(Object.keys(params).length > 0){
        props = JSON.parse(JSON.stringify(params.props));
    }

    const component = (name) => {
        switch (name) {
            case 'profileForm': {
                return <ProfileForm onSubmit={(response) => console.log(response)}/>;
            }
            default: return <div/>
        }
    };

    return <ContentWrapper>
        <Row gutter={16}>
            <Col md={14}>
                <Panel bordered={false} title={t('form')}>
                    <ContentWrapper>
                        {component(componentName)}
                    </ContentWrapper>
                </Panel>
            </Col>
            <Col md={10}>
                <Panel bordered={false} title={t('response')}>
                    <ContentWrapper>
                        <JSONPretty data={response}/>
                    </ContentWrapper>
                </Panel>
            </Col>
        </Row>
    </ContentWrapper>;
  }
}

export default compose(
    withTranslation(),
    withProps((props) => {
        const params = queryString.parse(_.get(props, 'history.location.search'));
        const componentName = _.get(props, 'match.params.componentName');
        return {
            params,
            componentName
        };
    }),
)(DynamicForms);