// @flow
import React, { Component } from 'react';
import Panel from "../Panel";
import CompanyForm from "../CompanyForm";

type Props = {

};

type State = {

}

class CompanyDashboard extends Component<Props, State> {

  render() {
    const { } = this.props;

    return <div className={"p-10"}>
        <Panel>
            <CompanyForm />
        </Panel>
    </div>;
  }
}

export default CompanyDashboard;
