// @flow
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import _ from 'lodash';
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import {Link} from "react-router-dom";

import ComponentImage from "../ComponentImage";
import OneLineLabel from "../OneLineLabel";
import {mapServiceCalendarBadgeItemProps} from "../../utils/calendarItemHelper";
import FillQueryTable from "../QueryTable/fill";
import {getCalendarListFilter} from "../ServiceCalendarList";
import CalendarItemListQuery from "../../graphql/queries/calendarItemList.graphql";
import queryString from "query-string";
import Toolbar from "../Toolbar";
import {DatePicker} from 'antd';
import moment from 'moment';
import {graphql} from "@apollo/client/react/hoc";
import {compose} from "recompose";

const {RangePicker} = DatePicker;

type Props = {
    filter: any,
    onChange?: any
};

const ServiceCalendarTable = ({filter, onChange, serviceCalenderListQuery}: Props) => {

    const {t} = useTranslation();
    const [dateRange, setDateRange] = useState([moment(_.get(filter, 'date[0]', null)), moment(_.get(filter, 'date[1]', null))] || []);

    const handleChangeDateRange = (value: [string, string]) => {
        if (onChange) {
            onChange(value);
        }
        setDateRange(value);
    };

    const columns = [{
        dataIndex: 'image',
        width: 40,
        render: (text, item) => {
            const calendarItem = mapServiceCalendarBadgeItemProps(item);

            return <div
                className="item-icon"
                style={{
                    backgroundColor: _.get(calendarItem, 'color')
                }}
            />
        }
    }, {
        dataIndex: 'calendarItemIndex',
        title: t('Type'),
        width: 300,
        render: (text, item) => {
            const calendarItem = mapServiceCalendarBadgeItemProps(item);

            const date = moment(_.get(item, 'date')).format('LL');

            return <div className={'flex-col'}>
                <Link
                    to={`/calendar/${calendarItem.dataId}/${calendarItem.calendarItemIndex}?${queryString.stringify(filter)}`}
                    className="item-title">
                    {
                        _.get(calendarItem, 'text')
                    }
                </Link>
                <span style={{
                    fontSize: 11,
                    fontStyle: 'italic'
                }}>
                            {date}
                        </span>
            </div>;
        }
    }, {
        key: 'component.image.thumbnailLink',
        dataIndex: 'component.image.thumbnailLink',
        width: 50,
        render: (_text, item) => {
            const calendarItem = mapServiceCalendarBadgeItemProps(item);
            const component = _.get(calendarItem, 'data.component');

            return <Link to={`/infrastructure/${component.id}`}>
                <ComponentImage
                    size={30}
                    link={_.get(component, 'image.thumbnailLink')}
                />
            </Link>
        }
    }, {
        key: 'component.name',
        dataIndex: 'component.name',
        title: t('Component'),
        sorter: true,
        render: (_text, item) => {
            const calendarItem = mapServiceCalendarBadgeItemProps(item);
            const component = _.get(calendarItem, 'data.component');

            return <div style={{wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: 500}}>
                <Link to={`/infrastructure/${component.id}`}>
                    {component.name}
                </Link>
                <div style={{maxWidth: 500}}>
                    <OneLineLabel>
                        <ComponentPathBreadcrumb hideLastItem path={component.path}/>
                    </OneLineLabel>
                </div>
            </div>
        }
    }, {
        key: 'component.serialNo',
        width: 150,
        dataIndex: 'serialNo',
        title: t('Serial No'),
        render: (_text, item) => {
            const calendarItem = mapServiceCalendarBadgeItemProps(item);
            const component = _.get(calendarItem, 'data.component');

            return _.get(component, 'serialNo')
        }
    }, {
        key: 'component.product.catalogNo',
        dataIndex: 'component.product.catalogNo',
        width: 150,
        title: t('Catalog No'),
        render: (_text, item) => {
            const calendarItem = mapServiceCalendarBadgeItemProps(item);
            const component = _.get(calendarItem, 'data.component');

            return _.get(component, 'product.catalogNo')
        }
    }, {
        key: 'client.id',
        dataIndex: 'client.name',
        width: 250,
        title: t('Client'),
        render: (_text, item) => {
            const calendarItem = mapServiceCalendarBadgeItemProps(item);
            const component = _.get(calendarItem, 'data.component');

            return _.get(component, 'client.name')
        }
    }]

    console.log(dateRange, 'dateRange');

    return <div
        className={'flex flex-column full-height'}
    >
        <Toolbar
            renderRight={() => <RangePicker
                format={'LL'}
                value={dateRange}
                onChange={handleChangeDateRange}
            />}
        />
        <FillQueryTable
            size={"middle"}
            query={serviceCalenderListQuery}
            loading={serviceCalenderListQuery.loading}
            itemsKeyPath={'calendarItemList.calendarItems'}
            totalKeyPath={'calendarItemList.total'}
            limitKeyPath={'params.limit'}
            startKeyPath={'params.start'}
            rowKey={"id"}
            columns={columns}
        />
    </div>
};


export default compose(
    graphql(CalendarItemListQuery, {
        name: 'serviceCalenderListQuery',
        options: (props) => {
            return {
                variables: {
                    params: {
                        start: 0,
                        limit: 30,
                        filter: getCalendarListFilter(props.filter)
                    }
                },
            }
        },
    }),
)(ServiceCalendarTable);
