// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {message, Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {filterFromProps, searchParamsFromProps} from "../InfrastructureSearch/results";
import {withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import _ from "lodash"
import ComponentListExportQuery from "../../graphql/queries/componentListExport.graphql";

type Props = {
    t: Function,
    params: {
        parentId?: number,
    },
};

type State = {
    loading: boolean
}

class InfrastructureSearchExport extends Component<Props> {

    state = {
        loading: false
    };

    handleDownload = () => {
        const {client, t} = this.props;

        this.setState({
            loading: true
        }, () => {
            client.query({
                query: ComponentListExportQuery,
                variables: {
                    params: {
                        filter: filterFromProps(this.props),
                        search: searchParamsFromProps(this.props)
                    }
                }
            }).then(({data}) => {
                const link = _.get(data,'componentList.export.link');
                if (!link){
                    return message.error(t("No export link found"));
                }
                window.open(link);
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })


    };

    render() {
        const {t} = this.props;
        const {loading} = this.state;

        return <Tooltip title={t('Export')}>
            <Button icon={"download"} loading={loading} onClick={this.handleDownload} className="mr-10">

            </Button>
        </Tooltip>
    }
}

export default compose(
    withTranslation(),
    withRouter,
    withApollo,
    /*
    graphql(ComponentListExportQuery, {
        name: 'componentListExportQuery',
        options: props => ({
            variables: {
                params: {
                    filter: filterFromProps(props),
                    search: searchParamsFromProps(props),
                    start: 0,
                    limit: 30
                }
            }
        }),
    }),

     */
    withTranslation()
)(InfrastructureSearchExport);
