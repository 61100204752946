import queryString from "query-string";
import {cleanKeys} from "./cleanObject";

const parseSearchQueryParams = (query) => {
    const params = queryString.parse(query);

    return cleanKeys(Object.keys(params).reduce((acc, key) => {
        let val = params[key];
        if (val === "true") { val = true; }
        if (val === "false") { val = false; }
        acc[key] =  val;
        return acc;
    },{}))
}

export default parseSearchQueryParams;