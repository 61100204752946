// @flow
import React, { Component } from 'react';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {Tabs} from "antd";
import ComponentDefaultImage from '../ComponentDefaultImage'
import ProductAccessoryListQuery from "../../graphql/queries/productAccessoryList.graphql";
import _ from "lodash";
import Image from "../Utilities/Image";
import Empty from "../Empty";
import QueryTable from "../QueryTable";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
  productId: number
};


class ComponentProductSpareParts extends Component<Props> {

  getActiveTabKey () {
    const { productAccessoryListQuery } = this.props;

    const keyCounts = [
      {
        key: 'accessories',
        count: _.get(productAccessoryListQuery, 'product.accessoryList.total')
      },
      {
        key: 'consumables',
        count: _.get(productAccessoryListQuery, 'product.consumableList.total')
      },
      {
        key: 'spareParts',
        count: _.get(productAccessoryListQuery, 'product.sparePartList.total')
      }
    ]

    for(let i = 0; i < keyCounts.length; i++) {
      if(keyCounts[i].count > 0) {
        return keyCounts[i].key
      }
    }

  }

  render() {
    const { t, productAccessoryListQuery } = this.props;

    const totalCount = _.get(productAccessoryListQuery, 'product.accessoryList.total') +
      _.get(productAccessoryListQuery, 'product.consumableList.total') +
      _.get(productAccessoryListQuery, 'product.sparePartList.total')

    const columns = [
      {
        dataIndex: '',
        title: t('Name'),
        render: (item) => <div className={'flex flex-align-items-center'}>
          {
            item.imageUrl ?
              <Image
                src={_.get(item, 'imageUrl')}
                height={40}
                width={40}
              /> :
              <ComponentDefaultImage
                height={40}
                width={40}
              />
          }
          <div className={'pl-10'}>
            <a href={_.get(item,'manufacturerLink')} target={'_blank'}>
              <span dangerouslySetInnerHTML={{ __html: _.get(item,'name')}}/>
            </a>
          </div>
        </div>
      },
      {
        dataIndex: 'category',
        title: t('Category')
      },
      {
        dataIndex: 'catalogNo',
        title: t('Catalog No')
      },
    ]

    const { TabPane } = Tabs;

    return <div className={'p-10'}>
      {totalCount > 0 ?
        <Tabs defaultActiveKey={this.getActiveTabKey()}>
          <TabPane
            tab={t("Accessories")}
            key="accessories"
            disabled={_.get(productAccessoryListQuery, 'product.accessoryList.total') === 0}
          >
            <QueryTable
              query={productAccessoryListQuery}
              columns={columns}
              totalKeyPath={'product.accessoryList.total'}
              itemsKeyPath={'product.accessoryList.productReferences'}
            />
          </TabPane>
          <TabPane
            tab={t("Consumables")}
            key="consumables"
            disabled={_.get(productAccessoryListQuery, 'product.consumableList.total') === 0}
          >
            <QueryTable
              query={productAccessoryListQuery}
              columns={columns}
              totalKeyPath={'product.consumableList.total'}
              itemsKeyPath={'product.consumableList.productReferences'}
            />
          </TabPane>
          <TabPane
            tab={t("Spare parts")}
            key="spareParts"
            disabled={_.get(productAccessoryListQuery, 'product.sparePartList.total') === 0}
          >
            <QueryTable
              query={productAccessoryListQuery}
              columns={columns}
              totalKeyPath={'product.sparePartList.total'}
              itemsKeyPath={'product.sparePartList.productReferences'}
            />
          </TabPane>
        </Tabs>
        : <Empty/>
      }
    </div>;
  }
}

export default compose(
  withTranslation(),
  graphql(ProductAccessoryListQuery, {
    name: 'productAccessoryListQuery',
    options: (props: Props) =>({
      variables: {
        productId: props.productId
      }
    }),
  }),
  waitWhileLoading('productAccessoryListQuery', 'product')
)(ComponentProductSpareParts);
