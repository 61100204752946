// @flow
import React, {Component} from 'react';
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import {withTranslation} from "react-i18next";
import AddParticipantsToConversationMutation from "../../graphql/mutations/addParticipantsToConversation.graphql";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';

type Props = {
  conversationId: string,
  participants: [],
  addParticipantsToConversationMutation: Function,
  t: Function
};

type State = {

}

class ConversationParticipantList extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { addParticipantsToConversationMutation, conversationId, participants, t } = this.props;

    const attributes = [{
      entityType: "user",
      index: "messageParticipant",
      inputType: "select",
      type: "string"
    }];

    return <div className={'flex-col mt-10'}>
      <span className={'bold'}>{t('Participants')}</span>
      {participants.map((user) => {
        return <div key={user.id}>
          <MaterialCommunityIcon size={14} name={'bell'}/>
          <span className={'ml-5'}>{user.name}</span>
        </div>
      })}
      {/*
      <TDAAttributesForm
        mutation={addParticipantsToConversationMutation}
        attributes={attributes}
        submitTitle={t('Add')}
        translateMutationOptions={(attributes) => ({
          variables: {
            input: {
              conversationId: conversationId,
              participants: [attributes.values.messageParticipant]
            },
          }
        })}
      />
      */}
    </div>;
  }
}

export default compose(
  graphql(AddParticipantsToConversationMutation, {
    name: 'addParticipantsToConversationMutation',
  }),
  withTranslation()
)(ConversationParticipantList);

