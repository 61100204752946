// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import CreateAttributeCategoryMutation from '../../graphql/mutations/createAttributeCategoryMutation.graphql'
import TypeDefinitionAttributeListQuery from '../../graphql/queries/typeDefinitionAttributeList.graphql'
import _ from "lodash";
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withRouter} from "react-router-dom";
import AttributeCategoryListQuery from "../../graphql/queries/attributeCategoryList.graphql";

type Props = {
  createAttributeCategoryMutation: Function,
  typeDefinitionAttributeListQuery: query,
  t: Function
};
class NewCategoryModal extends Component<Props> {
  componentDidMount() {}

  render() {
    const { createAttributeCategoryMutation, typeDefinitionAttributeListQuery, t } = this.props;

    const replaceAttributeValue = function(arr, key, newValue) {
      let match = _.find(arr, key);
      if(match)
        _.merge(match, newValue);
      else
        arr.push(newValue);
    }

    _.mixin({ '$replace': replaceAttributeValue })

    const attributes = _.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes')

    _.$replace(attributes, {index: "categoryType"}, {value: "serviceReport"})

    return <AttributesInlineForm
      attributes={attributes}
      t={t}
      useSubmit={true}
      onSubmit={() => {}}
      mutation={createAttributeCategoryMutation}
      translateMutationOptions={({values}) => ({
        variables: {
          input: values
        }
      })}
    />;
  }
}

export default compose(
  withRouter,
  graphql(CreateAttributeCategoryMutation, {
    name: 'createAttributeCategoryMutation',
    options: (props) => ({
      refetchQueries: [
        {
          query: AttributeCategoryListQuery,
          variables: {
            params: {
              filter: {
                categoryType: {
                  value: 'serviceReport'
                }
              }
            }
          }
        }]
    }),
  }),
  graphql(TypeDefinitionAttributeListQuery, {
    name: 'typeDefinitionAttributeListQuery',
    options: () => ({
      variables: {
        index: "attributeCategory"
      }
    }),
  }),
  waitWhileLoading('typeDefinitionAttributeListQuery', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
  withTranslation()
)(NewCategoryModal);
