// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {Mutation} from "@apollo/client/react/components";
import {graphql} from "@apollo/client/react/hoc";
import ServiceTicketListQuery from "./../../graphql/queries/ServiceTicketListPortlet.graphql";
import UpdatePortletMutation from "./../../graphql/mutations/updateUserPortlet.graphql"
import ServiceTicketsTable from "../ServiceTicketsTable";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Values from "../../themes/default/values";
import {compose, withProps} from "recompose";
import type {PortletInterface} from "./PortletInterface";
import {Translation} from "react-i18next";
import {filterFromProps, orderFromProps, searchParamsFromProps} from "../ServiceSearch/results";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";


const ServiceTicketsList = compose(
    withProps((props) => {
        const configString = _.get(props, 'portlet.config');
        const config = configString ? JSON.parse(configString) : {};
        const params = _.get(config, 'filter', {});
        const filter = filterFromProps({
            params
        });

        const sort = orderFromProps({
            params
        });

        const search = searchParamsFromProps({
            params
        })

        return {
            filter,
            sort,
            search
        }
    }),
    graphql(ServiceTicketListQuery, {
        name: 'query',
        options: (props: Props) => ({
            variables: {
                params: {
                    start: 0,
                    limit: Values.pagingSize,
                    filter: props.filter,
                    sort: props.sort,
                    search: props.search,
                }
            }
        }),
    }),
    waitWhileLoading('query')
)(ServiceTicketsTable);


type Props = {
    portlet: any,
};


export default class ServiceTicketsListPortlet extends Component<Props, State> implements PortletInterface {

    static RENDER_SETTINGS_COMPONENT = (portlet: any) => {

        const config = portlet.config ? JSON.parse(portlet.config) : {};

        return <div>
            <Mutation mutation={UpdatePortletMutation}>
                {(updatePortletMutation, {data}) => (
                    <Translation>
                        {
                            (t) => <TypeDefinitionAttributesForm
                                index={'serviceTicketFilter'}
                                formProps={{
                                    values: _.get(config, 'filter', {}),
                                    submitChangesOnly: false,
                                    mutation: updatePortletMutation,
                                    translateMutationOptions: ({values}) => {

                                        const newConfig = {
                                            ...config,
                                            filter: values
                                        }

                                        return {
                                            variables: {
                                                id: portlet.id,
                                                input: {
                                                    config: JSON.stringify(newConfig)
                                                }
                                            }
                                        }
                                    }

                                }}


                            />
                        }
                    </Translation>
                )}
            </Mutation>

        </div>
    }

    render() {
        const {portlet} = this.props;

        return <ServiceTicketsList portlet={portlet}/>;
    }
}



