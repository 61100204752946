// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import _ from 'lodash';
import ServiceTicketFinalTest from "../../components/ServiceTicketFinalTest";
import Error from "../../components/Error";
import FinalTestSubHeader from "../../components/SubHeaderWithTools/finalTestSubHeader";

type Props = {
    match: {
        params: any
    },
    history: {
        push: Function
    },
    t: Function,
}

const Wrapper = styled.div`
  background: white;
  margin: 16px;
`;

class ServiceTicketContaminationDeclarationRoute extends Component<Props> {


    render() {

        const {match} = this.props;
        const ticketId = _.get(match, 'params.id');
        if (!ticketId) {
            return <Error status={'404'}/>
        }
        return <Wrapper>
            <FinalTestSubHeader serviceTicketId={ticketId}/>
            <ServiceTicketFinalTest serviceTicketId={ticketId}/>

        </Wrapper>

    }

}

export default withTranslation()(ServiceTicketContaminationDeclarationRoute);

