// @flow
import React, {Component} from "react"
import {FaClipboard, FaExchange, FaDollar, FaHistory, FaLifeBouy, FaShield, FaTicket, FaWrench, FaBomb, FaExclamationCircle, FaPhone, FaPuzzlePiece} from 'react-icons/lib/fa';

import {Tooltip} from "antd";
import {withTranslation} from "react-i18next";

type Props = {
    index: string,
    size?: number,
    color?: string,
    style?: any,
}

class ServiceTicketTypeIcon extends Component<Props> {

    render() {

        const {index, t} = this.props;

        const settings = {
            size: this.props.size,
            color: this.props.color,
            style: this.props.style,
        };

        let icon = <FaTicket {...settings}/>;

        switch (index) {

            case 'sale':
                icon = <FaDollar {...settings}/>;
                break;

            case 'recon':
                icon = <FaExchange {...settings}/>;
                break;

            case 'repair':
                icon = <FaWrench {...settings}/>;
                break;

            case 'maintenance':
                icon = <FaShield {...settings}/>;
                break;

            case 'control':
                icon = <FaHistory {...settings}/>;
                break;

            case 'customerComplaint':
                icon = <FaBomb {...settings}/>;
                break;

            case 'loan':
                icon = <FaLifeBouy {...settings}/>;
                break;

            case 'request':
                icon = <FaClipboard {...settings}/>;
                break;

            case 'xenonFaultReport':
                icon = <FaExclamationCircle {...settings}/>;
                break;

            case 'xenonGeneralRequest':
                icon = <FaPhone {...settings}/>;
                break;

            case 'xenonPartRequest':
                icon = <FaPuzzlePiece {...settings}/>;
                break;

            default:
                break;
        }

        return <Tooltip placement="topLeft" title={t(index)}>{icon}</Tooltip>;
    }
}

export default withTranslation()(ServiceTicketTypeIcon);
