// @flow
import React, {Component} from 'react';
import LogisticiansListQuery from "./../../graphql/queries/LogisticiansListQuery.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";
import FillQueryTable from "../QueryTable/fill";

type Props = {
    logisticiansListQuery: query,
    t: Function,
    onClick: Function,
    filter: Object
}

class LogisticiansList extends Component<Props, State> {

    render() {

        const {logisticiansListQuery, t, onClick} = this.props;

        return <FillQueryTable
            style={{borderRadius: 0}}
            className={"link-table"}
            columns={[
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: t('Name')
                }
            ]}
            onRow={(record) => {
                return {
                    onClick: () => {
                        onClick(record);
                    },
                };
            }}
            size={'small'}
            rowKey={"id"}
            query={logisticiansListQuery}
            itemsKeyPath={'logisticianList.logisticians'}
            totalKeyPath={'logisticianList.total'}
            sortBy={'name'}/>
    }
}

export default compose(
    withTranslation(),
    graphql(LogisticiansListQuery, {
        name: 'logisticiansListQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    limit: 40,
                    start: 0,
                    search: {
                        query: _.get(props, 'filter.query', ''),
                        fields: ['name']
                    }
                }
            }
        }),
    }),
    waitWhileLoading('logisticiansListQuery', 'logisticianList.logisticians')
)(LogisticiansList);
