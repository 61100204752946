import React from "react";
import ServiceReportCreate from "../../../components/ServiceReportCreate";
import {useHistory, useParams} from "react-router-dom";

interface Props {

}

const ServiceReportCreateRoute = (props: Props) => {

    const history = useHistory();
    const {id} = useParams();

    return <ServiceReportCreate serviceTicketId={id} onCreateServiceReport={(serviceTicketId, serviceReportId) => {
        return history.push(`/service/${serviceTicketId}/serviceReports/${serviceReportId}`)
    }}/>;
}

export default ServiceReportCreateRoute;
