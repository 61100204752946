// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import VersionableAttributeList from "../../graphql/queries/VersionableAttributeList.graphql";
import {Collapse} from 'antd';
import statics from "../../statics";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import FillQueryTable from "../QueryTable/fill";

const Panel = Collapse.Panel;

type Props = {
  t: Function,
    title: string,
    index: string,
    query: query,
    createVersionableAttributeMutation: Function,
    componentId: string,
    endDate: any,
    startDate: any,
    attributes: any
};
class VersionableTable extends Component<Props, State> {

  render() {
    const { t, title, index, query, createVersionableAttributeMutation, componentId } = this.props;

      let attributes = [
          {
              index: 'update',
              type: "string",
              inputType: "datetime",
              name: t("date")
          }
      ];


    return <FillQueryTable
        columns={[
            {
                dataIndex: 'update',
                key: 'update',
                title: t('Date'),
                render: (text) => moment(text).format('L LT')
            },
            {
                key: 'value',
                dataIndex: 'value',
                title: title,
                render: (text, record) => record.stringValue || record.floatValue || record.intValue
            }
        ]}
        size={'small'}
        rowKey={"id"}
        query={query}
        limitKeyPath={'params.limit'}
        startKeyPath={'params.start'}
        itemsKeyPath={'versionableAttributeList.versionableAttributes'}
        totalKeyPath={'versionableAttributeList.total'}/>
  }
}

export default compose(
    withTranslation(),
    graphql(VersionableAttributeList, {
        name: 'query',
        options: (props: Props) => {
            return {
                variables: {
                    id: _.get(props, 'componentId'),
                    index: _.get(props, 'index'),
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                        sort: {
                            update: 'DESC'
                        }
                    }
                }
            }
        },
    }),
    waitWhileLoading('query', ['versionableAttributeList.versionableAttributes']),
)(VersionableTable);
