// @flow
import React, {Component} from "react"
import {userIsAuthenticatedRedir} from "../../../utils/auth";
import {withTranslation} from "react-i18next/src/index";
import AddDashboardPortlets from "../../../components/DashboardPortlets/add-dashboard-portlets";
import ContentHeader from "../../../components/Typo/ContentHeader";
import {compose} from "recompose";
import ContentWrapper from "../../../components/ContentWrapper";
import {withRouter} from "react-router-dom";


type Props = {
    t: Function,
}

class DashboardManagePortlets extends Component<Props> {

    _handleCreate = () => {
        this.props.history.goBack();
    };

    render() {

        const {t} = this.props;

        return <div>
            <ContentHeader title={t('Portlets')}/>
            <ContentWrapper className={"white-bg"}>
                <AddDashboardPortlets onCreate={this._handleCreate}/>
            </ContentWrapper>
        </div>;
    }

}

export default compose(
    withRouter,
    userIsAuthenticatedRedir,
    withTranslation()
)(DashboardManagePortlets)
