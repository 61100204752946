//@flow
import React, {Component} from 'react'
import type {Filter} from "../ServiceCalendar";
import ServiceCalendar, {getQueryFilter} from "../ServiceCalendar";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "./../Form/attributes-inline-form";
import ServiceCalendarTable from '../ServiceCalendarTable';


import "./index.css";
import isServiceProvider from "../../utils/isServiceProvider";
import _ from "lodash";
import withProfile from "../../hoc/withProfile";
import ServiceCalendarList, {getCalendarListFilter} from '../ServiceCalendarList';
import {Dropdown, Menu, Popover} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import Export from "../Export";
import CalendarItemListQuery from "../../graphql/queries/calendarItemList.graphql";
import ServiceCalendarBookmarks from "../ServiceCalendarBookmarks";

type Props = {
    filter: ?Filter,
    onChange: (Filter) => void,
    onViewChange?: any
}

export const getServiceCalendarFilterAttributes = (t) => [
    {
        name: t('Type of service action'),
        index: 'calendarItemIndex',
        type: 'string',
        entityType: 'enum',
        enumType: 'CalendarItemIndex',
        inputType: 'select',
    },{
        name: t('Client'),
        index: 'clientId',
        searchable: true,
        type: 'string',
        entityType: 'client',
        inputType: 'select',
    }, {
        index: 'componentTypeIndex',
        name: t('Component Type'),
        type: 'string',
        searchable: true,
        entityType: 'enum',
        enumType: 'ProductComponentTypeIndex',
        inputType: 'select',
    }, {
        index: 'isPlanned',
        name: t('Planned'),
        type: 'string',
        inputType: 'select',
        optionValues: [{
            value: 'true',
            name: t('Planned')
        },{
            value: 'false',
            name: t('Unplanned')
        }]
    }
];

class ServiceCalendarFilterPanel extends Component<Props> {

    onFilterChange = (values) => {
        const {filter} = this.props;

        if (this.props.onChange) {
            this.props.onChange({
                ...filter,
                ...values,
            })
        }
    };

    onDateChange = (values) => {
        if (this.props.onChange) {
            this.props.onChange({
                ...this.props.filter,
                date: values.date || values,
            })
        }
    };

    render() {
        const {filter, t, onViewChange} = this.props;

        const config = {
            calendar: {
                name: t('Calendar View'),
                icon: "calendar",
                view: <ServiceCalendar onChange={this.onDateChange} filter={filter}/>
            },
            list: {
                name: t('List View'),
                icon: "unordered-list",
                view: <ServiceCalendarList onChange={this.onDateChange} filter={filter}/>
            },
            table: {
                name: t('Table View'),
                icon: "table",
                view: <ServiceCalendarTable onChange={this.onDateChange} filter={filter}/>
            }
        };

        const attributes = [
            ...getServiceCalendarFilterAttributes(t).map(item => ({
                ...item,
                name: null,
                inputTypeOptions: {
                    ...item.inputTypeOptions,
                },
                placeholder: item.name
            }))
        ];

        if (!isServiceProvider(this.props.resources)) {
            attributes.removeAt(_.findIndex(attributes, {index: 'clientId'}))
        }

        const viewType = _.get(filter, 'view', 'calendar');

        const viewMenu = <Menu onClick={onViewChange}>
            {
                Object.keys(config).map((el) => {
                    const conf = _.get(config, el, {});

                    return <Menu.Item key={el}>
                        <Icon type={conf.icon}/>
                        {
                            conf.name
                        }
                    </Menu.Item>
                })
            }
        </Menu>;

        return (
            <div className={'ServiceCalendarFilterPanel bg-white full-height flex flex-column'}>
                <div style={{"padding": "8px 8px 0 8px"}} className={'full-width flex-row flex-space-between'}>
                    <div className={'flex-grow-1'} tour-id="calendar__filter">
                        <AttributesInlineForm
                            layout={'inline'}
                            values={filter}
                            inline={false}
                            useSubmit={false}
                            attributes={attributes}
                            onValuesChange={this.onFilterChange}
                        />
                    </div>
                    <div className={'full-height flex-row flex-align-items-center'}>
                        <Export
                            variables={{
                                params: {
                                    filter: viewType === "calendar" ? getQueryFilter(filter) : getCalendarListFilter(filter)
                                }
                            }}
                            downloadLinkPath={'calendarItemList.export.link'}
                            query={CalendarItemListQuery}
                        />
                        <Dropdown overlay={viewMenu}>
                            <Button
                                title={_.get(config, `${viewType}.name`, _.get(config, 'calendar.name', null))}
                                type={'primary'}
                                icon={_.get(config, `${viewType}.icon`, _.get(config, 'calendar.icon', null))}
                                tour-id="calendar__view"
                            />
                        </Dropdown>
                        <Popover placement="bottom" content={<ServiceCalendarBookmarks filter={filter}/>}
                                 trigger="click">
                            <Button className={"ml-10"} icon={<Icon type={"BookOutlined"}/>}/>
                        </Popover>
                    </div>
                </div>
                <div className={'flex-grow-1'} style={{overflow: 'auto'}}>
                    {
                        _.get(config, `${viewType}.view`, _.get(config, 'calendar.view', null))
                    }
                </div>
            </div>
        )

    }
}

export default compose(
    withTranslation(),
    withProfile()
)(ServiceCalendarFilterPanel);
