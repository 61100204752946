// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {message} from "antd";
import {Button} from "@dreebit/react-components";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import serviceActionsTimeline from "./../../graphql/queries/serviceActionsTimeline.graphql";
import query from "./../../graphql/queries/ServiceTicketWarrantyReport.graphql"
import createMutation from "./../../graphql/mutations/createWarrantyReport.graphql"
import addWarrantyReportFiles from "./../../graphql/mutations/addWarrantyReportFiles.graphql"
import {getPrintLink} from "../../utils/ContaminationDeclarationHelper";

import FinalizeWarrantyReportMutation from "../../graphql/mutations/finalizeWarrantyReport.graphql";
import FinalizeListItem from "../FinalizeListItem";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import withProfile from "../../hoc/withProfile";
import {checkResource} from "../../hoc/withResources";

type Props = {
    t: Function,
    serviceTicketId: number | string,
    warrantyReportQuery: any,
    createMutation: Function,
    addWarrantyReportFilesMutation: Function,
};

type State = {
    loading: boolean
}

class WarrantyReportFinalizeWidget extends Component<Props, State> {

    state = {
        loading: false
    };

    handleUpload = (file: FileUploadResponse) => {

        const {t, warrantyReportQuery, addWarrantyReportFilesMutation, createMutation} = this.props;
        const {serviceTicket} = warrantyReportQuery;

        this.setState({
            loading: true,
        }, () => {
            const updatePromise = (id) => {
                return addWarrantyReportFilesMutation({
                    variables: {
                        warrantyReportId: id,
                        files: [{
                            fileId: file.fileId
                        }]
                    }
                })
                    .then(() => {
                        message.success(t('File was uploaded'));
                    })
                    .catch((err) => {
                        console.error(err);
                        message.error(t('Could not upload file'));
                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        })
                    })
            };
            if (!serviceTicket.warrantyReport) {
                createMutation({
                    variables: {
                        serviceTicketId: serviceTicket.id
                    }
                }).then((result) => {
                    const id = _.get(result, 'data.createWarrantyReport.id');
                    if (id) {
                        updatePromise(id)
                    } else {
                        message.error(t('Could not create contamination declaration'));
                    }
                }).catch((err: Error) => {
                    message.error(t('Could not create contamination declaration'));
                    console.error(err);
                    this.setState({
                        loading: false
                    })
                })
            } else {
                updatePromise(serviceTicket.warrantyReport.id)
            }

        })

    };

    handleFinalize = () => {

        const {t, finalizeWarrantyReportMutation, warrantyReportQuery} = this.props;
        const warrantyReportId = _.get(warrantyReportQuery, 'serviceTicket.warrantyReport.id');

        this.setState({
            loading: true,
        });
        finalizeWarrantyReportMutation({
            variables: {
                warrantyReportId: warrantyReportId,
            }
        })
            .then(() => {
                message.success(t('warranty report finalized'));
            })
            .catch((err) => {
                console.error(err);
                message.error(t('Error'));
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    };


    render() {
        const {warrantyReportQuery, t, resources} = this.props;
        const warrantyReport = _.get(warrantyReportQuery, 'serviceTicket.warrantyReport');
        const warrantyReportId = _.get(warrantyReport, 'id');

        const ticketId = _.get(warrantyReportQuery, 'serviceTicket.id');
        const editLink = `/service/${ticketId}/warrantyReport`;

        const finalized = warrantyReportId && _.get(warrantyReport, 'finalized');

        const files = [];
        if (_.get(warrantyReport,'print.customer')){
            files.push({
                ..._.get(warrantyReport,'print.customer'),
                filename: t('Customer Report')
            })
        }
        if (_.get(warrantyReport,'print.serviceProvider')){
            files.push({
                ..._.get(warrantyReport,'print.serviceProvider'),
                filename: t('Service Provider Report')
            })
        }

        const canFinalize = checkResource(["grantWarrantyStatus"],resources);

        return <FinalizeListItem
            formId={_.get(warrantyReportQuery, 'serviceTicket.warrantyReport.id')}
            name={t('Warranty Report')}
            update={_.get(warrantyReportQuery, 'serviceTicket.warrantyReport.update')}
            updatedBy={_.get(warrantyReportQuery, 'serviceTicket.warrantyReport.updatedBy')}
            editLink={editLink}
            downloadLink={warrantyReportId ? getPrintLink(_.get(warrantyReportQuery, 'serviceTicket.warrantyReport')) : undefined}
            loading={this.state.loading}
            onUploaded={this.handleUpload}
            onFinalize={warrantyReportId && canFinalize ? this.handleFinalize : undefined}
            finalized={finalized}
            rightTools={finalized ? files.map((file, index) => <Button target={"_BLANK"} download icon={'download'} style={{marginLeft: index > 0 ? 5 : 0}} href={_.get(file, 'link')}>
                {_.get(file, 'filename')}
            </Button>) : undefined}
        />;
    }
}


export default compose(
    graphql(query, {
        name: 'warrantyReportQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                serviceTicketId: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('warrantyReportQuery'),
    graphql(createMutation, {
        name: 'createMutation'
    }),
    graphql(addWarrantyReportFiles, {
        name: 'addWarrantyReportFilesMutation',
        options: props => ({
            refetchQueries: [{
                query: query,
                variables: {
                    serviceTicketId: props.serviceTicketId
                }
            }]
        })
    }),
    graphql(FinalizeWarrantyReportMutation, {
        name: 'finalizeWarrantyReportMutation',
        options: props => ({
            refetchQueries: [
                {
                    query: serviceActionsTimeline,
                    variables: {
                        id: props.serviceTicketId
                    }
                }
            ]
        })
    }),
    withTranslation(),
    withRouter,
    withProfile(),
)(WarrantyReportFinalizeWidget);
