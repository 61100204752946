import React from 'react';
import _ from "lodash";
import {compose} from "redux";
import { graphql } from '@apollo/client/react/hoc';
import DefaultServiceTaskNameQuery from "../../graphql/queries/errorPatternName.graphql";
import {withTranslation} from "react-i18next";

const ErrorPatternsBreadcrumb = ({query, t}) =>
    <span>{_.get(query, 'errorPattern.name', '...')}</span>;

export default compose(
    graphql(DefaultServiceTaskNameQuery, {
        name: 'query',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.errorPatternId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(ErrorPatternsBreadcrumb));
