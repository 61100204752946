// @flow
import React, {Component} from 'react';
import Panel from "../../components/Panel";
import {compose} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {Col, Row} from "antd";
import TCOCalculationSettings from "../../components/TCOCalculationSettings";
import {connect} from "react-redux";
import {formTCOComponentSettings} from "../../utils/formName";
import ComponentTCOTabs from "../../components/ComponentTCOTabs";
import ComponentTCOValuesTable from "../../components/ComponentTCOValuesTable";

type Props = {

};

type State = {

}

class ComponentTCORoute extends Component<Props, State> {

  render() {
    const { tcoSettings, t } = this.props;

    const componentId = _.get(this.props, 'match.params.id');

    return <div className={"p-10"}>
        <Panel title={t('Calculation settings')}>
            <TCOCalculationSettings
                componentId={componentId}/>
        </Panel>
        <Row gutter={16}>

           <Col md={12}>

               <Panel title={t('List')}>
                   <ComponentTCOValuesTable componentId={componentId} tcoSettings={tcoSettings} />
               </Panel>
           </Col>
            <Col md={12}>
                <Panel title={t('Charts')}>
                    <ComponentTCOTabs tcoSettings={tcoSettings} componentId={componentId} />

                </Panel>
            </Col>
        </Row>

    </div>;

      /*
      <ComponentTCOHistoryChart
                        tcoSettings={tcoSettings}
                        componentId={componentId}/>
       */
  }
}

export default compose(
    connect(({settings}, props)=> {
        const componentId = _.get(props, 'match.params.id');
        return {
            tcoSettings: {
                ..._.get(settings,`forms.${formTCOComponentSettings(componentId)}`,{})
            }
        }
    }),
    withTranslation()
)(ComponentTCORoute);
