// @flow
import React, {Component} from 'react';
import {Progress} from 'antd';
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ServiceTicketListTotalQuery from "../../graphql/queries/serviceTicketListTotal.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";

type Props = {
  dateRange: { from: string, to: string }
};

type State = {

}

class ServiceTicketWarrantyStatus extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { totalTicketsQuery, totalCheckedAndAcceptedQuery, totalCheckedAndNotAcceptedQuery, totalNotCheckedQuery } = this.props;

    const totalTickets = _.get(totalTicketsQuery, 'serviceTicketList.total');
    const warrantyTickets = [
      {
        key: "warrantyCheckedAndAccepted",
        title: "GW anerkannt",
        total: _.get(totalCheckedAndAcceptedQuery, 'serviceTicketList.total')
      },
      {
        key: "warrantyCheckedAndNotAccepted",
        title: "GW abgelehnt",
        total: _.get(totalCheckedAndNotAcceptedQuery, 'serviceTicketList.total')
      },
      {
        key: "warrantyNotChecked",
        title: "GW ungeprüft",
        total: _.get(totalNotCheckedQuery, 'serviceTicketList.total')
      }
    ]

    return <div className={'flex flex-col flex-align-items-center mt-20 full-width'}>
      <div className={'flex flex-row flex-space-around full-width'}>
          {warrantyTickets.map((item) => {
            return (
              <div className="flex flex-col flex-align-items-center">
                <Progress
                  type="circle"
                  status="normal"
                  strokeColor={{
                    '0%': '#d26f26',
                    '100%': '#ea974a',
                  }}
                  percent={100}
                  format={() => `${item.total}`}
                />
                <span>{item.title}</span>
              </div>
            )
          })}
      </div>

      <span style={{fontSize: 40, fontWeight: 'bold'}}>{totalTickets}</span>
      <span>gesamt</span>
    </div>;
  }
}

export default compose(
  graphql(ServiceTicketListTotalQuery, {
    name: 'totalTicketsQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            clientId: {operator: 'nin', value: '602'},
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: {from: "2019-01", to: "2020-0"}}
          }
        }
      }
    }),
  }),
  waitWhileLoading('totalTicketsQuery', 'serviceTicketList.total'),
  graphql(ServiceTicketListTotalQuery, {
    name: 'totalCheckedAndAcceptedQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            warrantyStatus: {operator: 'in', value: 'checkedAndAccepted'},
            clientId: {operator: 'nin', value: '602'},
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: {from: "2019-01", to: "2020-0"}}
          }
        }
      }
    }),
  }),
  waitWhileLoading('totalCheckedAndAcceptedQuery', 'serviceTicketList.total'),
  graphql(ServiceTicketListTotalQuery, {
    name: 'totalCheckedAndNotAcceptedQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            warrantyStatus: {operator: 'in', value: 'checkedAndNotAccepted'},
            clientId: {operator: 'nin', value: '602'},
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: {from: "2019-01", to: "2020-0"}}
          }
        }
      }
    }),
  }),
  waitWhileLoading('totalCheckedAndNotAcceptedQuery', 'serviceTicketList.total'),
  graphql(ServiceTicketListTotalQuery, {
    name: 'totalNotCheckedQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            warrantyStatus: {operator: 'in', value: 'notChecked'},
            clientId: {operator: 'nin', value: '602'},
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: {from: "2019-01", to: "2020-0"}}
          }
        }
      }
    }),
  }),
  waitWhileLoading('totalNotCheckedQuery', 'serviceTicketList.total'),
)(ServiceTicketWarrantyStatus);
