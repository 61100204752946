// @flow
import _ from "lodash";
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import UpdateComponentMutation from "../../graphql/mutations/updateComponent.graphql";
import {withTranslation} from "react-i18next";
import FileUploader from "../FileUploader";

import ComponentImageQuery from "./../../graphql/queries/componentImage.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import MutationButton from "../MutationButton";

type Props = {
    componentId: string,
    onFinished: () => void
};

type State = {}

class ComponentImageUploader extends Component<Props, State> {


    _handleUpload = (upload) => {
        const {updateComponentMutation, componentId, onFinished} = this.props;

        const promise = updateComponentMutation({
            variables: {
                id: componentId,
                input: {
                    imageId: _.get(upload, 'response.fileId')
                }
            }
        })

        if (onFinished) {
            promise.then(onFinished);
        }

    }

    render() {

        const {link, t, updateComponentMutation, componentId, onFinished} = this.props;

        return <div>
            <FileUploader
                customContent={link ? <div>
                    <span className={"text-center"}>{t("Click here to upload a new image")}</span>
                    <div className={"p-10"}>
                        <img className={"full-width"} src={link}/>
                    </div>
                </div>: undefined}
                accept={"image/*"}
                multiple={false}
                onUpload={this._handleUpload}
            />
            <div className={"mt-10"}>
                <MutationButton
                    confirm
                    buttonProps={{type: 'danger'}}
                    onDone={onFinished}
                    mutation={updateComponentMutation} variables={{
                        variables: {
                            id: componentId,
                            input: {
                                imageId: null
                            }
                        }
                }} >
                    {t("Remove image")}
                </MutationButton>
            </div>
        </div>;
    }
}

const imageConfig = {
    width: 470,
    height: 470,
    crop: 'fit'
}

export default compose(
    graphql(ComponentImageQuery, {
        fetchPolicy: 'cache-first',
        name: 'componentImageQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId,
                ...imageConfig
            }
        })
    }),
    waitWhileLoading("componentImageQuery"),
    withProps((props) => {

        let link = _.get(props, 'componentImageQuery.component.image.thumbnailLink');
        if (link && link.endsWith("default-image-component.png")) {
            link = undefined;
        }
        return {
            link
        }
    }),
    graphql(UpdateComponentMutation, {
        name: 'updateComponentMutation',
        options: (props) => ({
            refetchQueries: [{
                query: ComponentImageQuery,
                variables: {
                    id: props.componentId,
                    ...imageConfig
                }
            }]
        })
    }),
    withTranslation(),
)(ComponentImageUploader);

