import _ from "lodash";

export const sortDirtyList = (items, dirties) => {

    if (!Array.isArray(items)) return items;
    const tmp = [...items];

    const ranks = tmp.reduce((acc, cur, index) => {
        acc[cur.id] = index;
        const dirty = _.find(dirties,{id: cur.id});
        if (dirty){
            if (index > dirty.index){
                acc[cur.id] = dirty.index-0.1
            }else {
                acc[cur.id] = dirty.index+0.1
            }
        }else {

        }
        return acc;
    },{})

    return tmp.sort((a, b) => {
        return ranks[a.id] - ranks[b.id];
    });
}
