// @flow
import React, {Component} from 'react';
import styled from "styled-components";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import ComponentMove from "../../components/ComponentMove";

type Props = {
    match: {
        params: {
            id: string
        }
    }
};


const Wrapper = styled.div`
  padding: 0 ${2 * Sizes.grid}px
`;

class ComponentMoveRoute extends Component<Props, State> {


    render() {
        const {t, match} = this.props;

        const componentId = _.get(match, 'params.id');

        return <Wrapper>
            <Panel title={t('Move')} bordered={false} containerStyle={{padding: 0, border: "none"}}>

                <ComponentMove componentId={componentId}/>

            </Panel>
        </Wrapper>;
    }
}

export default withTranslation()(ComponentMoveRoute);

