// @flow
import React, {Component} from 'react';
import {message, Tooltip} from 'antd';
import {Button} from "@dreebit/react-components";
import axios from "axios";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import {toastError} from "../../utils/toast";
import {checkForValidMail} from "../../utils/email";
import withProfile from "../../hoc/withProfile";

type Props = {
    email: string
};

class PasswordResetButton extends Component<Props, State> {




    resetPWRequest = () => {

        const {t} = this.props;

        const data = new FormData();
        data.append("email", this.props.email);
        axios({
            method: 'post',
            url: '/auth/reset-password',
            data: data,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then((response) => {
                message.success(t('We sent a link to the provided e-mail'));
            })
            .catch((err) => {
                toastError(err)
            });
    };


    render() {
        const {t, email, profile} = this.props;
        const resetPasswordLink = _.get(profile, 'resetPasswordLink', '');

        if (resetPasswordLink && resetPasswordLink.length) {
            return <div className={'passwordResetWrapper'}>
                <Button
                    type="link"
                    target="_blank"
                    className="display-block"
                    href={resetPasswordLink}
                >
                    {t('Change password')}
                </Button>
            </div>
        } else if (!checkForValidMail(email)) {
            return <div className={'passwordResetWrapper'}>
                <Tooltip
                    title={t('Please insert a valid email address')}
                    placement="top"
                >
                    <Button
                        disabled
                        type="link"
                    >
                        {t('Reset password')}
                    </Button>

                </Tooltip>
            </div>
        } else {
            return <div className={'passwordResetWrapper'}>
                <Button
                    type="link"
                    onClick={this.resetPWRequest}
                >
                    {t('Reset password')}
                </Button>
            </div>
        }
    }
}

export default compose(
    withTranslation(),
    withProfile()
)(PasswordResetButton);
