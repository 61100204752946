// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import queryString from "query-string";
import {compose, withProps} from "recompose";
import ServiceSearch from "./../../components/ServiceSearch"
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import {setServiceSearchQuery} from "../../actions/settings";
import parseSearchQueryParams from "../../utils/searchParams";
import {graphql} from '@apollo/client/react/hoc';
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import withProfile from "../../hoc/withProfile";

type Props = {
    match: {
        params: any
    },
    history: {
        push: Function
    },
    t: Function,
}

class ServiceTicketSearchRoute extends Component<Props> {

    render() {

        const {filter, defaultValues, setSearchQuery, isDefaultFilter} = this.props;

        return <ServiceSearch
            isDefaultFilter={isDefaultFilter}
            params={filter}
            onChange={(changes) => {
                if (!changes) {

                    const query = queryString.stringify(defaultValues);

                    setSearchQuery(query);
                    this.props.history.push({
                        search: query
                    })
                } else {

                    const params = {...filter, ...changes};
                    let values = Object.keys(params).reduce((acc, cur) => {
                        if (Array.isArray(params[cur]) && !params[cur].length) {
                            acc[cur] = null;
                        } else {
                            acc[cur] = params[cur];
                        }
                        return acc;
                    }, {})
                    const query = queryString.stringify(values, {skipNull: false});
                    setSearchQuery(query)

                    this.props.history.push({
                        search: `?${query}`
                    })
                }
            }}
        />
    }

}

export default compose(
    withRouter,
    withTranslation(),
    withProfile({
        fetchPolicy: 'cache-first'
    }),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props) => {
            return {
                variables: {
                    index: 'serviceTicketFilter'
                }
            }
        }
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery'),
    withProps((props) => {
        let query = _.get(props, 'history.location.search');
        return {
            query
        }
    }),
    connect((state, props) => {

        let query = props.query;
        if (!query || !query.length) {
            query = _.get(state, 'settings.serviceSearchQuery');
        }

        return {
            query,
        }
    }, dispatch => ({
        setSearchQuery: (query) => {
            return dispatch(setServiceSearchQuery(query))
        }
    })),
    withProps((props) => {

        let defaultValues = {
            ..._.get(props, 'typeDefinitionAttributeListQuery.typeDefinitionAttributeList.typeDefinitionAttributes', []).reduce((acc, cur) => {
                if (cur.value) {
                    acc[_.get(cur, 'index')] = _.get(cur, 'value');
                }
                if (cur.addOnAttribute) {
                    acc[_.get(cur, 'addOnAttribute.index')] = _.get(cur, 'addOnAttribute.value');
                }
                return acc;
            }, {}),
        }

        let profileDefaultFilter = null;
        const defaultServiceFilterString = _.get(props, 'profile.serviceTicketDefaultFilter');
        if (defaultServiceFilterString) {
            try {
                profileDefaultFilter = JSON.parse(defaultServiceFilterString);
                if (Object.keys(profileDefaultFilter).length) {
                    defaultValues = profileDefaultFilter;
                }
            } catch (e) {
                console.log(e)
            }
        }

        const result = {
            profileDefaultFilter,
            defaultValues,
            queryParams: parseSearchQueryParams(props.query)
        }
        return result;
    }),
    withProps((props) => {

        const filter = props.query && props.query.length ? props.queryParams : props.defaultValues;
        return {
            isDefaultFilter: JSON.stringify(props.profileDefaultFilter) === JSON.stringify(filter),
            filter
        }
    })
)(ServiceTicketSearchRoute);

