// @flow
import userListQuery from "./../../graphql/queries/userList.graphql"
import userQuery from "./../../graphql/queries/userInfo.graphql"

const UserLoaderConfig = {
    dataKey: 'userList',
    totalKey: 'total',
    itemsKey: 'users',
    valueKey: 'id',
    textKey: 'name',
    query: userListQuery,
    getQueryVariables: (query) => {
        return {
            params: {
                start: 0,
                limit: 10,
                search: {
                    query: query,
                    fields: ["name", "email"]
                }
            }
        }
    },
    valueQuery: userQuery,
    getValueQueryVariables: (value) => {
        return {
            id: value
        }
    },
    valueQueryDataKey: 'user'
};

export default UserLoaderConfig;
