// @flow
import type {SplitPanelModel} from "../SplitPanel";
import SplitPanel from "../SplitPanel";

import React, {Component} from 'react';

type Props = {
    items: SplitPanelModel[],
    dataCy?: string,
    disableMargin?: boolean,
};


class SplitPanelList extends Component<Props, State> {


    render() {
        const {dataCy, items, disableMargin} = this.props;

        return <div
            data-cy={dataCy ? dataCy : null}
            style={{border: '1px solid transparent', borderRadius: '8px', overflow: 'hidden', width: '100%'}}>
            {items.map((item: SplitPanelModel, index) => {

                const props = {...item};

                return <SplitPanel className={disableMargin ? '' : 'mb-15'} key={index}{...props} />

            })}
        </div>;
    }
}

export default SplitPanelList;
