// @flow
import React, {Component} from 'react';
import AccessControlPicker from "../AccessControlPicker";
import RoleListQuery from "../../graphql/queries/roleList.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ComponentRoleListQuery from "../../graphql/queries/componentRoleList.graphql";
import UpdateComponentAclMutation from "../../graphql/mutations/UpdateComponentAcl.graphql";
import RemoveComponentAclMutation from "../../graphql/mutations/RemoveComponentAcl.graphql";
import AddRoleToComponentMutation from "../../graphql/mutations/AddRoleToComponentMutation.graphql";
import {withTranslation} from "react-i18next";

class ComponentRoleAccess extends Component<Props, State> {

    render() {
        const {componentId, addRoleToComponentMutation, updateComponentAclMutation, removeComponentAclMutation, componentPath} = this.props;

        return <AccessControlPicker
            showSearch
            addMutation={addRoleToComponentMutation}
            updateMutation={updateComponentAclMutation}
            removeMutation={removeComponentAclMutation}
            translateMutationOptions={(method, id, input) => {

                const variables = {
                    input
                };

                if (method === 'create') {
                    variables.componentId = componentId;
                    variables.roleId = id;
                }

                if (method === 'update') {
                    variables.id = id;
                }

                if (method === 'delete') {
                    variables.id = id;
                }

                return {
                    variables,
                    refetchQueries: [
                        {
                            query: ComponentRoleListQuery,
                            variables: {
                                id: this.props.componentId,

                            }
                        }
                    ]
                }
            }}
            selectDataLoader={{
                query: RoleListQuery,
                dataKey: 'roleList',
                getQueryVariables: () => {
                    return {
                        start: 0
                    };
                },
                local: true,
                itemsKey: 'roles',
                totalKey: 'total',
                textKey: 'name',
                valueKey: 'id',
            }}
            valuesDataLoader={{
                query: ComponentRoleListQuery,
                getQueryVariables: () => ({
                    id: this.props.componentId
                }),
                dataKey: 'componentRoleList',
                itemsKey: 'componentRoles',
                totalKey: 'total',
                textKey: 'role.name',
                valueKey: 'id',
                readKey: 'read',
                writeKey: 'write',
            }}
            componentPath={componentPath}
        />;
    }
}

export default compose(
    graphql(UpdateComponentAclMutation, {
        name: 'updateComponentAclMutation'
    }),
    graphql(RemoveComponentAclMutation, {
        name: 'removeComponentAclMutation'
    }),
    graphql(AddRoleToComponentMutation, {
        name: 'addRoleToComponentMutation',
    }),
    withTranslation(),
)(ComponentRoleAccess);
