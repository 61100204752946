// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {Button} from "@dreebit/react-components";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import createBookmark from "./../../graphql/mutations/createBookmark.graphql";
import BookmarkList, {bookmarkListVariables} from "./../BookmarkList";
import queryString from "query-string";
import BookmarkDetailModal from "../BookmarkDetailModal";
import BookmarkListQuery from "../../graphql/queries/bookmarkList.graphql";

type Props = {
    t: Function,
    createBookmarkMutation: Function,
    params: any
}

type State = {
    loadingCreate: boolean,
    visible: boolean,
    bookmarkId: string
}

class ServiceTicketsSearchBookmarks extends Component<Props, State> {

    state = {
        visible: false,
        loadingCreate: false,
        bookmarkId: null
    };

    static defaultProps = {
        params: {}
    };

    createBookmark = () => {

        const {t, createBookmarkMutation, params} = this.props;

        this.setState({
            loadingCreate: true
        }, () => {
            createBookmarkMutation({
                variables: {
                    input: {
                        title: t('New servicet ticket search'),
                        type: 'serviceTicketSearch',
                        data: JSON.stringify({
                            link: `/service/search?${(queryString.stringify(params))}`
                        })
                    }
                }
            }).then(() => {

            }).finally(() => {
                this.setState({
                    loadingCreate: false
                })
            })
        })

    };

    render() {

        const {t} = this.props;
        const {loadingCreate, visible, bookmarkId} = this.state;

        return <div className={'full-height flex-column'}>

            <div className={"p-10"}>
                <Button loading={loadingCreate} onClick={this.createBookmark} type={'default'}
                        className={'full-width'} >{t('Save current search')}</Button>
            </div>

            <BookmarkList
                type={['serviceTicketSearch','serviceTicketSelection']}
                onSelect={(id: string) => this.setState({bookmarkId: id, visible: true})}
            />

            {
                visible && bookmarkId
                    ? <BookmarkDetailModal
                        visible={visible}
                        onClose={() => this.setState({visible: false})}
                        id={bookmarkId}
                    />
                    : null
            }
        </div>
    }

}

export default compose(
    graphql(createBookmark, {
        name: 'createBookmarkMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: BookmarkListQuery,
                    variables: bookmarkListVariables(['serviceTicketSearch', 'serviceTicketSelection'])
                }]
        })
    }),
    withTranslation()
)(ServiceTicketsSearchBookmarks);
