import React from 'react';
import FillQueryTable from "../QueryTable/fill";
import {useQuery} from "@apollo/client";
import ClientAccessListQuery from "../../graphql/queries/clientAccessList.graphql";
import {useTranslation} from "react-i18next";
import {Spin, Tag} from "antd";
import _ from "lodash";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";

interface Props {
    clientId: number,
    onClick?: Function,
    defaultServiceProviderId?: string,
}

const ClientServiceProvidersList = (props: Props) => {
    const { t } = useTranslation()
    const clientAccessListQuery = useQuery(ClientAccessListQuery, {
        variables: {
            clientId: props.clientId
        }
    });

    if (clientAccessListQuery.loading) {
        return <Spin/>
    }

    return <FillQueryTable
        style={{borderRadius: 0}}
        columns={[
            {
                key: 'name',
                dataIndex: ['serviceProvider', 'name'],
                title: t('Service Provider'),
                render: (text, item) => {
                    const isDefaultServiceProvider = props.defaultServiceProviderId && props.defaultServiceProviderId === _.get(item, 'serviceProvider.id');
                    return <>{text} {isDefaultServiceProvider ? <Tag className={"ml-5"} color={'blue'}>{t("Default Service Provider")}</Tag> : null}</>
                }
            }, {
                key: 'component',
                dataIndex: ['component', 'name'],
                title: t('Component'),
                width: 400,
                render: (text, item) => {
                    return <ComponentPathBreadcrumb path={item.component.path} componentId={item.component.id} />
                }
            }, {
                key: 'read',
                dataIndex: 'read',
                title: t('Read'),
                width: 150,
                render: (text, item) => {
                    return _.get(item, 'read', false) ? <CheckOutlined /> : <CloseOutlined />;
                }
            }, {
                key: 'write',
                dataIndex: 'write',
                title: t('Write'),
                width: 150,
                render: (text, item) => {
                    return _.get(item, 'write', false) ? <CheckOutlined /> : <CloseOutlined />;
                }
            }, {
                key: 'serviceTickets',
                dataIndex: 'serviceTickets',
                title: t('ServiceTickets'),
                width: 150,
                render: (text, item) => {
                    return _.get(item, 'serviceTickets', false) ? <CheckOutlined /> : <CloseOutlined />;
                }
            }
        ]}

        // className={"link-table"}
        onRow={(record) => {
            return {
                onClick: () => {
                    props.onClick ? props.onClick(record) : () => {};
                },
            };
        }}
        size={'middle'}
        rowKey={"id"}
        query={clientAccessListQuery}
        itemsKeyPath={'clientAccessList.clientAccess'}
        totalKeyPath={'clientAccessList.total'}
        sortBy={'name'}/>
}

export default ClientServiceProvidersList;
