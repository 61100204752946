// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import CreateServiceProviderMutation from "./../../graphql/mutations/CreateServiceProviderMutation.graphql";
import ServiceProviderListQuery from "../../graphql/queries/serviceProviderList.graphql";
import statics from "../../statics";
import {getAttributeGroups} from "../../utils/attributeGroups";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";
import PanelComponent from "../Panel";
import AttributesInlineForm from "../Form/attributes-inline-form";

type Props = {
    onCreate: Function,
    createServiceProviderMutation: () => Promise,
};


class ServiceProviderCreate extends Component<Props, State> {


    render() {
        const {createServiceProviderMutation, attributes, onCreate, t} = this.props;

        const groups = getAttributeGroups(attributes, t)

        return <PanelComponent
            bordered={false}
            children={<AttributesInlineForm
                attributes={attributes}
                groups={groups}
                mutation={createServiceProviderMutation}
                translateMutationOptions={({values}) => ({
                    variables: {
                        input: values
                    }
                })}
                useSubmit
                submitTitle={t('Create')}
            />}
        />
    }
}

export default compose(
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props: Props) => ({
            variables: {
                index: 'serviceProvider',
            }
        }),
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery', 'typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(CreateServiceProviderMutation, {
        name: 'createServiceProviderMutation',
        options: () => ({
            refetchQueries: [{
                query: ServiceProviderListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                    }
                }
            }]
        })
    }),
    withTranslation(),
    withProps((props) => ({
        attributes: _.get(props, 'typeDefinitionAttributeListQuery.typeDefinitionAttributeList.typeDefinitionAttributes'),
    }))
)(ServiceProviderCreate);
