import React, {Component} from 'react'
import _ from 'lodash'
import AttributesInlineForm from "../Form/attributes-inline-form";
import ServicePlanFormQuery from "./../../graphql/queries/ServicePlanForm.graphql"
import UpdateServicePlanMutation from "./../../graphql/mutations/updateServicePlan.graphql"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";


type Props = {
    servicePlanId: string,
    servicePlanFormQuery: any,
    updateServicePlanMutation: Function,
}

class ServicePlanForm extends Component<Props> {

    render() {

        const {servicePlanFormQuery, updateServicePlanMutation, servicePlanId} = this.props;

        return <div>
            <AttributesInlineForm
                className={'m-10'}
                attributes={_.get(servicePlanFormQuery, 'servicePlan.typeDefinitionAttributeList.typeDefinitionAttributes').map((item) => {
                    if (item.entityType === 'defaultServiceTask') {
                        return {
                            ...item,
                            data: {
                                ...item.data,
                                componentId: _.get(servicePlanFormQuery, 'servicePlan.component.id')
                            }
                        }
                    }
                    return item;
                })}
                translateMutationOptions={({values}) => ({
                    variables: {
                        attributes: values,
                        servicePlanId
                    }
                })}
                mutation={updateServicePlanMutation}
            />
        </div>
    }

}

export default compose(
    graphql(UpdateServicePlanMutation, {
        name: 'updateServicePlanMutation'
    }),
    graphql(ServicePlanFormQuery, {
        name: 'servicePlanFormQuery',
        options: props => ({
            variables: {
                servicePlanId: props.servicePlanId
            }
        }),
    }),
    waitWhileLoading('servicePlanFormQuery', ['servicePlan.typeDefinitionAttributeList.typeDefinitionAttributes'])
)(ServicePlanForm)
