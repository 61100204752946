const kpiListConfig = [
  {
    key: "A",
    name: "a_doneServiceTickets",
    description: "Anzahl der erledigten Servicetickets",
    period: "year",
    params: {
      filter: {
        serviceTicketStateIndex: {value: "done"},
        serviceTicketTypeIndex: {operator: "in", value: ["maintenance", "repair", "loan", "recon"]},
        update: {operator: "range", range: {from: "2019-01-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
      }
    },
    userParams: ["dateRangeYear"]
  },
  {
    key: "A1",
    name: "a1_doneExternalServiceTickets",
    description: "Anzahl der erledigten, externen Servicetickets",
    period: "year",
    params: {
      filter: {
        serviceTicketStateIndex: {value: "done"},
        serviceTicketTypeIndex: {operator: "in", value: ["maintenance", "repair", "loan", "recon"]},
        clientId: {operator: "nin", value: 602},
        update: {operator: "range", range: {from: "2019-01-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
      }
    },
    userParams: ["dateRangeYear"]
  },
  {
    key: "A2",
    name: "a2_doneInternalServiceTickets",
    description: "Anzahl der erledigten, internen Servicetickets",
    period: "year",
    params: {
      filter: {
        serviceTicketStateIndex: {value: "done"},
        serviceTicketTypeIndex: {operator: "in", value: ["maintenance", "repair", "loan", "recon"]},
        clientId: {operator: "in", value: 602},
        update: {operator: "range", range: {from: "2019-01-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
      }
    },
    userParams: ["dateRangeYear"]
  },
  {
    key: "B",
    name: "b_doneExternalServiceTicketsMonth",
    description: "Anzahl der erledigten, externen Servicetickets pro Monat",
    period: "month",
    params: {
      filter: {
        serviceTicketStateIndex: {value: "done"},
        serviceTicketTypeIndex: {operator: "in", value: ["maintenance", "repair", "loan", "recon"]},
        clientId: {operator: "nin", value: 602},
        update: {operator: "range", range: {from: "2019-12-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
      }
    },
    userParams: ["dateRangeMonth"]
  },
  {
    key: "C",
    name: "c_doneExternalServiceTicketsMonthComponentType",
    description: "Anzahl der erledigten, externen Servicetickets pro Monat, pro Komponententyp",
    period: "month",
    params: {
      filter: {
        serviceTicketStateIndex: {value: 'done'},
        serviceTicketTypeIndex: {operator: "in", value: ["maintenance", "repair", "loan", "recon"]},
        clientId: {operator: "nin", value: 602},
        update: {operator: "range", range: {from: "2019-12-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
      },
    },
    userParams: ["dateRangeMonth", "componentType"]
  },
  {
    key: "D",
    name: "d_functionalBlockRecons",
    description: "Anzahl der erledigten Blockaustausche pro Jahr",
    period: "year",
    params: {
      filter: {
        serviceTicketStateIndex: {value: 'done'},
        serviceTicketTypeIndex: {operator: 'in', value: ['recon']},
        componentTypeIndex: {operator: 'in', value: ['functionalBlock']},
        update: {operator: "range", range: {from: "2019-01-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
      },
    },
    userParams: ["dateRangeYear"]
  },
  {
    key: "D1",
    name: "d1_functionalBlockReconsMonth",
    description: "Anzahl der erledigten Blockaustausche pro Monat",
    period: "month",
    params: {
      filter: {
        serviceTicketStateIndex: {value: 'done'},
        serviceTicketTypeIndex: {operator: 'in', value: ['recon']},
        componentTypeIndex: {operator: 'in', value: ['functionalBlock']},
        update: {operator: "range", range: {from: "2019-12-01T00:00:00.000Z", to: "2019-12-31T23:59:59.999Z"}}
      },
    },
    userParams: ["dateRangeMonth"]
  }
]

export default kpiListConfig;
