// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import UpdateProfileMutation from "../../graphql/mutations/updateProfile.graphql"
import _ from "lodash";
import ServiceSearchToolbar from "./toolbar"
import ServiceSearchResults from "./results"
import {Dropdown, Layout, Menu, Tabs, Tooltip} from "antd";
import {Button, Icon, toastSuccess} from "@dreebit/react-components";
import ServiceTicketsSearchBookmarks from "../ServiceTicketsSearchBookmarks";
import ServiceSearchExport from "../ServiceSearchExport";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import NavigationContainer from "../NavigationContainer";
import {connectForm} from "../../resolver/settings";
import ServiceSearchForm from "../ServiceSearchForm";
import Bold from "../Utilities/Bold";
import {Col, Grow, Row} from "../Flex";
import {graphql} from "@apollo/client/react/hoc";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import withTheme from "../../hoc/withTheme";

const {TabPane} = Tabs;


type Props = {
    isDefaultFilter?: boolean,
    params?: any,
    onChange?: Function,
}

type State = {
    showBookmarks: boolean
}

const { Sider } = Layout;

class ServiceSearch extends Component<Props, State> {
    state = {};

    onClick = () => {
        const {onChange} = this.props;

        if (onChange) {
            onChange();
        }
    };

    saveDefaultFilter = (params) => {
        const {updateProfileMutation} = this.props;

        return updateProfileMutation({
            variables: {
                input: {
                    serviceTicketDefaultFilter: params ? JSON.stringify(params) : null
                }
            }
        }).then((res) => {
            toastSuccess(params ? this.props.t("Default filter was set") : this.props.t("Default filter was reset"))
            return res
        })

    }

    onUseOr = (useOr) => {
        const {onChange, params} = this.props;
        let tmp = {
            ...params,
            conditionOperator: useOr ? "or" : null
        };
        if (onChange) {
            onChange(tmp);
        }
    }

    render() {

        const {
            params,
            onChange,
            t,
            serviceSearchSideBarForm,
            setServiceSearchSideBarForm,
            isDefaultFilter,
            theme
        } = this.props;

        const siderCollapsed = !_.get(serviceSearchSideBarForm, "open");

        const useOr = _.get(params, "conditionOperator") === "or"

        let xenonOptions = null;

        if (theme === "xenon") {
            xenonOptions = <Row>
                <Grow>
                    <Bold>{t("Search Settings")}</Bold>
                </Grow>

                <div className={"no-padding-buttons"}>
                    {useOr ? <Tooltip placement={"top"}
                                      title={t("Combine objects that meet at least one criteria within a set of filter")}>
                        <Button onClick={() => this.onUseOr(false)} type={"link"}><MaterialCommunityIcon
                            name={"unlink"}/></Button>
                    </Tooltip> : <Tooltip placement={"top"}
                                          title={t("Combine objects that meet all criteria within a set of filter")}>
                        <Button onClick={() => this.onUseOr(true)} type={"link"}><MaterialCommunityIcon name={"link"}/></Button>
                    </Tooltip>}


                    <Dropdown overlay={<Menu
                        onClick={(info) => {
                            switch (info.key) {
                                case 'useDefaultFilter':
                                    this.saveDefaultFilter(this.props.params);
                                    break;
                                case 'removeDefaultFilter':
                                    this.saveDefaultFilter().then(() => {
                                        this.onClick();
                                    })
                                    break;
                                case 'reset':
                                    this.onClick();
                                    break;
                                default:
                            }
                        }}
                    >
                        {isDefaultFilter ?
                            <Menu.Item key={'removeDefaultFilter'}>{t("Remove default filter")}</Menu.Item> :
                            <Menu.Item key={'useDefaultFilter'}>{t("Use as default filter")}</Menu.Item>}
                        <Menu.Divider key={'divider'}/>
                        <Menu.Item key={'reset'} danger>{t("Reset")}</Menu.Item>
                    </Menu>}>
                        <Button type={"link"}><MaterialCommunityIcon name={'menu'}/></Button>
                    </Dropdown>
                </div>


            </Row>
        }


        return <Row className={"full-size"}>

            <Col>
                {siderCollapsed ? <div style={{width: 52}} onClick={() => setServiceSearchSideBarForm({open: true})}
                                       className={"full-size flex flex-center text-center pointer white-bg"}>
                    <span className={"vertical-text"}><Bold>{t("Search options")}</Bold></span>
                </div> : <div style={{overflowX: 'hidden', width: 300}} className={"full-height overflow-auto"}>

                    <Tabs defaultActiveKey="filter"
                          destroyInactiveTabPane
                          tabBarExtraContent={<Icon className={"link-dark mt-10 mr-10"}
                                                    onClick={(collapsed) => setServiceSearchSideBarForm({open: false})}
                                                    type={"close"}/>}>
                        <TabPane tab={<span className={"ph-5"}>{t("Filter")}</span>} key="filter">
                            <div className={"ph-10"}>
                                {xenonOptions}
                                <ServiceSearchForm disableBookmark cleanDefaultValues params={params}
                                                   onChange={onChange}/>
                            </div>
                        </TabPane>

                        <TabPane tab={t("Bookmarks")} key="bookmarks">
                            <ServiceTicketsSearchBookmarks params={params}/>
                        </TabPane>
                    </Tabs>
                </div>}
            </Col>

            <Grow>
                <NavigationContainer navigationBar={<ServiceSearchToolbar params={params} onChange={onChange}
                                                                          renderLeft={() => {
                                                                              return <Tooltip title={t("Filter")}>
                                                                                  <Button
                                                                                      tour-id="serviceSearch__filter"
                                                                                      className={"mr-10 vertical-middle"}
                                                                                      type={'default'} icon={'filter'}
                                                                                      onClick={() => setServiceSearchSideBarForm({open: !_.get(serviceSearchSideBarForm, "open")})}/>
                                                                              </Tooltip>
                                                                          }}
                                                                          renderRight={() => <div
                                                                              style={{
                                                                                  width: 140,
                                                                                  marginTop: 4,
                                                                                  textAlign: 'right'
                                                                              }}>
                                                                              <Tooltip title={t("Reset")}>
                                                                                  <Button
                                                                                      data-cy={'service-search-filter-reset-button'}
                                                                                      style={{marginRight: 8}}
                                                                                      type={"danger"}
                                                                                      icon={"rollback"}
                                                                                      onClick={() => this.onClick()}
                                                                                  />
                                                                              </Tooltip>
                                                                              <ServiceSearchExport params={params}/>
                                                                          </div>}
                />}>
                    <ServiceSearchResults params={params} onChange={onChange} onRef={ref => (this.serviceSearchResults = ref)} bookmarkCollapsed={this.state.collapsed}/>
                </NavigationContainer>
            </Grow>
        </Row>


    }

}


export default compose(
    withTranslation(),
    withRouter,
    graphql(UpdateProfileMutation, {
        name: 'updateProfileMutation'
    }),
    connectForm("serviceSearchSideBarForm"),
    connectForm("serviceSearchBookmarkSideBarForm"),
    withTheme()
)(ServiceSearch);

