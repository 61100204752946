//@flow
import _ from "lodash"

export default function groupByShelfBox(components: any[]): any {

    return _.groupBy(components, (component) => {
        if (_.get(component, 'componentType.index') === 'box') {
            return 'shelvBox';
        } else {
            return 'noShelvBox';
        }
    });
}