// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import type {Attribute} from "../../types";
import _ from "lodash";
import BookmarkQuery from "../../graphql/queries/bookmarkData.graphql"
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql"
import Toolbar from "../Toolbar";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import TDAAttributesForm from "../TDAAttributesForm";

type Props = {
    params?: {
        [string]: any
    },
    renderRight?: Function,
    onChange?: Function,
    history: {
        push: Function
    }
}

type State = {
    filterAttributes: Array<Attribute>
}

export const getAttributes = (t, props?) => ([
    {
        name: t('Search'),
        index: 'query',
        type: 'string',
        inputType: 'text',
        debounceTime: 500,
        placeholder: t('Search'),
        addOnAttribute: {
            index: 'fields',
            type: 'string',
            inputType: 'select',
            placeholder: t('Fields'),
            multiple: true,
            maxTagCount: 0,
            optionValues: [
                {
                    value: 'key',
                    name: t('Ticket Key')
                }, {
                    value: 'serialNo',
                    name: t('Serial')
                }, {
                    value: 'catalogNo',
                    name: t('Catalog No')
                }, {
                    value: 'poNumber',
                    name: t('PO Number')
                }, {
                    value: 'componentName',
                    name: t('Component name')
                }
            ]
        },
    },

    {
        name: t('Type'),
        index: 'serviceTicketTypeIndex',
        type: 'string',
        entityType: 'enum',
        enumType: 'ServiceTicketTypeIndex',
        inputType: 'select',
        multiple: true,
        maxTagCount: 0,
        placeholder: t('Ticket Type'),

    },
    {
        name: t('State'),
        index: 'serviceTicketStateIndex',
        type: 'string',
        entityType: 'enum',
        enumType: 'ServiceTicketStateIndex',
        maxTagCount: 0,
        multiple: true,
        inputType: 'select',
        placeholder: t('State'),

    },
    {
        name: t('Department'),
        index: 'serviceDepartmentId',
        type: 'string',
        entityType: 'serviceDepartment',
        maxTagCount: 0,
        multiple: true,
        inputType: 'select',
        placeholder: t('Department'),

    },
    {
        name: t('Client'),
        index: 'clientId',
        type: 'string',
        maxTagCount: 0,
        multiple: true,
        entityType: 'client',
        inputType: 'select',
        placeholder: t('Client'),
        resources: ["serviceProvider"]
    },
    {
        name: t('Assignee'),
        index: 'assigneeId',
        type: 'string',
        entityType: 'user',
        maxTagCount: 0,
        multiple: true,
        inputType: 'select',
        placeholder: t('Assignee'),
    },
    {
        name: t('Service Provider'),
        index: 'serviceProviderId',
        type: 'string',
        entityType: 'serviceProvider',
        maxTagCount: 0,
        multiple: true,
        inputType: 'select',
        placeholder: t('Service Provider'),
    },
    {
        name: t('Component Type'),
        index: 'componentTypeIndex',
        type: 'string',
        entityType: 'enum',
        maxTagCount: 0,
        multiple: true,
        enumType: 'ComponentTypeIndex',
        inputType: 'select',
        placeholder: t('ComponentType'),
    },
    // {
    //   index: 'manufacturerId',
    //   type: 'string',
    //   entityType: 'manufacturer',
    //   inputType: 'select',
    //   placeholder: 'Manufacturer',
    //   config: {
    //     mode: 'default',
    //     showSearch: true
    //   }
    // },
    // {
    //   index: 'componentId',
    //   type: 'int',
    //   inputType: 'component',
    // },
    {
        name: t('Warranty Status'),
        index: 'warrantyStatus',
        type: 'string',
        entityType: 'enum',
        maxTagCount: 0,
        multiple: true,
        enumType: 'WarrantyStatus',
        inputType: 'select',
        placeholder: t('Warranty Status'),
    },{
        index: 'insert',
        type: "object",
        inputType: "daterangepicker",
        name: t('date range'),
        format: 'LL'
    }
]);

class ServiceSearchToolbar extends Component<Props, State> {


    constructor(props) {
        super(props);

        const {t} = this.props;

        this.state = {
            filterAttributes: getAttributes(t, this.props)
        }
    }

    onReset() {
        this._handleChanges();
    }

    _handleChanges = (changes) => {

        console.log("_handleChanges", changes);

        const {onChange, params} = this.props;

        if (onChange) {
            if (!changes) {
                onChange(null)
            } else {
                const values = {
                    ...params,
                    ...changes
                };
                onChange(values)
            }

        }
    }

    render() {

        const {onChange, bookmarkQuery, params, t, renderRight, typeDefinitionAttributeListQuery} = this.props;


        const attributes = _.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', [])

        const attr = [...attributes];
        /*
        const attr = attributes.map(item => {

            const replaceName = item.inputType !== 'checkbox' && item.inputType !== 'daterange';
            return {...item, name: replaceName ? null : item.name, placeholder: item.name}
        });
        */
        if (bookmarkQuery && _.get(bookmarkQuery, 'bookmark.data')) {
            const bookmarkData = JSON.parse(_.get(bookmarkQuery, 'bookmark.data'));
            const count = _.get(bookmarkData, 'serviceTicketIds', []).length;

            attr.unshift({
                index: 'selectionBookmark',
                inputType: 'button',
                name: t("Selection") + `: ${_.get(bookmarkQuery, 'bookmark.title')} (${count})`,
                icon: 'delete',
                onClick: () => {
                    this._handleChanges({
                        selectionBookmark: null
                    })
                }
            })
        }


        return <Toolbar
            className={"white-bg"}
            renderLeft={() => {

                return <div data-cy='service-search-toolbar' className={"inline-child"}>
                    {this.props.renderLeft ? this.props.renderLeft() : null }
                    <TDAAttributesForm
                        containerClassName={"inline"}
                        formType={'modal'}
                        valueToolbarOnly={true}
                        cacheChanges={false}
                        layout={'inline'}
                        onChange={this._handleChanges}
                        useSubmit={false}
                        values={params}
                        attributes={attr.map((item) => ({...item, value: undefined}))}
                    />
                </div>
            }
            }
            renderRight={renderRight}
        />;
    }

}

export default compose(
    withTranslation(),
    withRouter,
    graphql(TypeDefinitionAttributeListQuery,{
        name: 'typeDefinitionAttributeListQuery',
        options: (props) => {
            return {
                variables: {
                    index: 'serviceTicketFilter'
                }
            }
        }
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery'),
    graphql(BookmarkQuery,{
        name: 'bookmarkQuery',
        skip: (props) => !_.get(props,'params.selectionBookmark'),
        options: (props) => {
            return {
                variables: {
                    bookmarkId: _.get(props,'params.selectionBookmark')
                }
            }
        }
    }),
    waitWhileLoading('bookmarkQuery',null, {
        optional: true,
        allowErrors: true
    })
)(ServiceSearchToolbar);
