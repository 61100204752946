import React, {Component} from 'react';
import ProductLogTimelineQuery from "./../../graphql/queries/productLog.graphql";
import _ from 'lodash';
import QueryLogList from "../QueryLogList";
import LogDetailView from "../LogDetailView";

type Props = {
  productId: string,
  values: any,
};

class ProductLogTimeline extends Component<Props> {
  render() {

    const logLevel = _.get(this.props, 'values.logLevel', []);
    const logActionKeys = _.get(this.props, 'values.logActionKey', [])

    return <QueryLogList
      query={ProductLogTimelineQuery}
      variables={{
        id: this.props.productId,
        params: {
          filter: {
            level: logLevel && logLevel.length ? {
              operator: 'in',
              value: logLevel
            } : undefined,
            actionKey: logActionKeys && logActionKeys.length ? {
              operator: 'in',
              value: logActionKeys
            } : undefined
          },
          sort: {
            insert: 'DESC'
          },
          start: 0,
          limit: 15
        }
      }}
      itemsKeyPath={'product.logList.logs'}
      totalKeyPath={'product.logList.total'}
      renderItems={(items) => <LogDetailView logs={items}/>}
    />
  }
}


export default ProductLogTimeline
