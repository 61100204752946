import React, {useState} from 'react';
import _ from "lodash";
import {Button, Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import useProfile from "../../hooks/useProfile";

interface Props {
    onAccept: (comment: string) => void,
    onReject: (comment: string) => void,
    clientAccessRequest: any,
}

const ClientAccessRequestActions = ({onAccept, onReject, clientAccessRequest}: Props) => {

    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [respondText, setRespondText] = useState("");
    const profile = useProfile();
    console.log(_.get(profile, 'id'), 'PROF')
    console.log(_.get(clientAccessRequest, 'requestedBy.id'), 'requested by')

    const requestedByCurrentUser = _.get(profile, 'id') === _.get(clientAccessRequest, 'requestedBy.id')
    const status = _.get(clientAccessRequest, 'status')
    const requestedByName = _.get(clientAccessRequest, 'requestedBy.name')
    const clientName = _.get(clientAccessRequest, 'client.name')

    const { TextArea } = Input;

    if (status === "pending" && !requestedByCurrentUser) {
        return (
            <Row gutter={[0, 40]}>
                <Col span={24}>
                    <div>Sie können die Anfrage akzeptieren, wodurch {requestedByName} Zugriff auf die Infrastruktur von {clientName} erhält. Alternativ können Sie die Anfrage ablehnen.</div>
                </Col>
                <Col span={24}>
                    <Form form={form} layout={"vertical"}>
                        <Form.Item name={"Kommentar"} label={t("Comment")}>
                            <TextArea value={respondText} onChange={(e) => setRespondText(_.get(e, 'target.value', ""))}/>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={5} offset={6}>
                    <Button
                        className={"full-width"}
                        onClick={() => onReject(respondText)}
                    >
                        {t("Reject")}
                    </Button>
                </Col>
                <Col span={5} offset={2}>
                    <Button
                        type={"primary"}
                        className={"full-width"}
                        onClick={() => onAccept(respondText)}
                    >
                        {t("Accept")}
                    </Button>
                </Col>
            </Row>

        );
    } else if (requestedByCurrentUser) {
        return (
            <div>
                <div>Sie haben keine Berechtigungen, um diese Anfrage zu bearbeiten.</div>
            </div>
        );
    } else {
        return (
            <div>
                <div>Die Anfrage wurde bereits bearbeitet.</div>
            </div>
        );
    }

}

export default ClientAccessRequestActions;
