// @flow
import React, {Component} from 'react';
import type {ServiceControlListItem} from "../../types";
import {Col, Grow, Row} from "../Flex";
import styled from "styled-components";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import {Tag} from 'antd';
import {withTranslation} from "react-i18next";
import moment from "moment";
import UserLabel from "../UserLabel";

type Props = {
    item: ServiceControlListItem,
    t: Function
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;
  background-color: white;
  color: grey;
`;

const LeftBorder = styled.div`
  width: 2px;
  background-color: #3a3f51
  height: 100%;
  position: absolute;
`;
const Title = styled.span`
  font-size: 1.3em;
  margin-left: 7px;
  color: grey;
`;

const UpcomingControl = styled.span`
  color: grey;
`;

const Updated = styled.span`
  color: grey;
  margin-top: 5px;
`;

const Insert = styled.span`
  color: grey;
  margin-top: 5px;
`;


class ServiceControlListItemComponent extends Component<Props> {

    render() {
        const {item, t} = this.props;

        return <Wrapper>
            <Row>
                <LeftBorder/>
                <Grow className={'mh-10 mv-5'}>
                    <Col className={'p-5'}>
                        <Row className={'flex-align-items-center'}>
                            <FontAwesomeIcon name={'clock'} circled={false} size={'1.5x'}/>
                            <Title className={'flex-grow-1'}>{item.name}</Title>
                            <Tag style={{marginRight: '0'}}
                                 color={'#3a3f51'}>{t('Service control')}</Tag>
                        </Row>
                        <UpcomingControl>{t('Upcoming control')}: {moment(item.upcomingControl).format('LL')} ({t('Cycle in days')}: {item.dailyCycle})</UpcomingControl>
                        <Row>
                            <Updated
                                className={'flex-grow-1'}>{t('Last update')} {moment(item.update).fromNow()}</Updated>
                            <Insert>{t('Insert by')} <UserLabel showIcon={false}
                                                                userInfo={item.createdBy}/> {moment(item.insert).fromNow()}
                            </Insert>
                        </Row>
                    </Col>
                </Grow>
            </Row>
        </Wrapper>;
    }
}

export default withTranslation()(ServiceControlListItemComponent);