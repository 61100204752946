// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {compose} from "recompose";
import {AutoComplete} from "antd"
import {withTranslation} from "react-i18next";
import GlobalSearchQuery from "./../../graphql/queries/GlobalSearchQuery.graphql";
import QueryAutocomplete from "../QueryAutocomplete";
import ComponentListItem from "../ComponentList/ComponentListItem";
import ServiceTicketSearchListItem from "../ServiceTicketList/ServiceTicketSearchItem";
import {withRouter} from "react-router-dom";

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;

class GlobalSearchInput extends Component<Props, State> {


    renderItem = (searchItem) => {

        const {history} = this.props;

        switch (searchItem.searchItemIndex) {
            case 'component': {

                const component = searchItem;
                if (!component) return <span>{searchItem.name}</span>;
                return <ComponentListItem
                    showPath
                    onClick={() => history.push(`/infrastructure/${_.get(component, 'id')}`)}
                    item={component}
                />
            }

            case 'serviceTicket': {

                const ticket = searchItem;
                if (!ticket) return <span>{searchItem.name}</span>;
                return <ServiceTicketSearchListItem
                    onClick={() => history.push(`/service/${_.get(ticket, 'id')}`)}
                    item={{
                        ...ticket,
                        component: ticket.componentPath && ticket.componentPath.length ? {
                            ..._.last(ticket.componentPath),
                            path: ticket.componentPath,
                        } : undefined
                    }}/>
            }

            default:
                return <span>{searchItem.name}</span>
        }

    };

    _handleSelect = (searchItem, searchQuery) => {

        // if (!searchQuery || !searchQuery.length) return;

        const {history} = this.props;

        const searchItemIndex = _.get(searchItem, 'searchItemIndex', searchItem);

        switch (searchItemIndex) {
            case 'component': {
                history.push(`/infrastructure/${_.get(searchItem, 'id')}`);
                break;
            }

            case 'serviceTicket': {
                history.push(`/service/${_.get(searchItem, 'id')}`);
                break;
            }

            case 'serviceTicketSearch': {
                history.push(`/service/search?query=${searchQuery}&fields=componentName&fields=catalogNo&fields=serialNo&fields=key`);
                break;
            }

            case 'infrastructureSearch': {
                history.push(`/infrastructure/search?query=${searchQuery}&fields=name&fields=catalogNo&fields=serialNo`);
                break;
            }

            default: {
            }
        }
    };

    render() {
        const {t} = this.props;

        return <div
            tour-id="global__search"
            data-cy={"global-search"}
            className="global-search-wrapper"
            style={{width: 250}}>

            <QueryAutocomplete
                query={GlobalSearchQuery}
                defaultActiveFirstOption
                variables={{
                    params: {
                        start: 0,
                        limit: 5,
                        search: {
                            query: null,
                            fields: ["name", "key", "serialNo", "catalogNo", "requestId"]
                        }
                    }
                }}
                size={'large'}
                onSelect={this._handleSelect}
                itemsKeyPath={"searchItemList"}
                dropdownClassName={"global-search-dropdown"}
                searchQueryKeyPath={"params.search.query"}
                className={"global-search-input"}
                translateDataSource={(results) => {
                    const groups = {
                        [t("Infrastructure")]: [
                            {
                                id: 'infrastructureSearch',
                                searchItemIndex: 'infrastructureSearch',
                                name: t('Search for components'),
                            },
                            ..._.get(results,'componentSearchItemList.componentSearchItems',[]).map(item => ({
                                searchItemIndex: 'component',
                                ...item
                            }))
                            ],
                        [t("Service")]: [ {
                            id: 'serviceTicketSearch',
                            searchItemIndex: 'serviceTicketSearch',
                            name: t('Search for service tickets'),
                        }, ..._.get(results,'serviceTicketSearchItemList.serviceTicketSearchItems',[]).map(item => ({
                            searchItemIndex: 'serviceTicket',
                            ...item
                        }))],
                    };

                    return Object.keys(groups).map((groupKey) => {

                        return {
                            label: groupKey,
                            options: _.uniqBy(_.get(groups, groupKey, []).map(opt => (
                                {
                                    value: opt.id,
                                    label: this.renderItem(opt)
                                }
                            )),'value')
                        }
                    });
                }
                }
            />

        </div>
    }
}

export default compose(
    withRouter,
    withTranslation()
)(GlobalSearchInput);
