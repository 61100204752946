// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {compose} from "recompose";
import ServiceActionQuery from "../../graphql/queries/serviceActionShipping.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Avatar, Col, Row} from "antd";
import Bold from "../Utilities/Bold";
import {MailOutlined} from "@ant-design/icons";

type Props = {
    serviceActionId: string
};

type State = {

}

class ServiceActionShippingInfoBox extends Component<Props, State> {

  render() {
    const {
        serviceActionQuery
    } = this.props;

    const {serviceAction} = serviceActionQuery;

    return <Row>
        <Col sm={4}>
            <Avatar icon={<MailOutlined/>} src={_.get(serviceAction, 'logistician.image.thumbnailLink')}
                    size={"large"}/>
        </Col>
        <Col sm={20}>
            <div><Bold>{_.get(serviceAction, 'logistician.name')}</Bold></div>
            <div>
                <a target={"_BLANK"} href={_.get(serviceAction, 'link')}>{_.get(serviceAction, 'link')}</a>
            </div>
        </Col>
    </Row>
  }
}

export default compose(
    graphql(ServiceActionQuery, {
        name: 'serviceActionQuery',
        options: (props) => ({
            variables: {
                id: props.serviceActionId
            }
        })
    }),
    waitWhileLoading('serviceActionQuery')
)(ServiceActionShippingInfoBox);
