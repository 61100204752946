// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {FaExchange} from "react-icons/lib/fa";
import {graphql} from '@apollo/client/react/hoc';
import {Col, message, Row} from "antd";
import {Button} from "@dreebit/react-components";
import _ from 'lodash';
import ServiceTicketReconConfigQuery from "../../graphql/queries/serviceTicketReconConfig.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import updateServiceTicketReconConfig from "../../graphql/mutations/updateServiceTicketReconConfig.graphql";
import CreateServiceActionMutation from "../../graphql/mutations/createServiceAction.graphql";
import ServiceActionsTimelineQuery from "../../graphql/queries/serviceActionsTimeline.graphql";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ReconComponentContainer from "../ReconComponentContainer";
import Colors from "../../themes/default/colors";
import Bold from "../Utilities/Bold";

type Props = {
    t: Function,
    serviceTicketId: any,
    serviceTicketDashboardQuery: query,
};

type State = {
    loading: boolean
}

class ReconDetailView extends Component<Props, State> {

    state = {
        loading: false,
    };

    handleSave = (reconConfig: {reconComponentId?: string, reconComponentDestinationId?: string, serviceTicketComponentDestinationId?: string}) => {
        const {updateServiceTicketReconConfigMutation, serviceTicketId, serviceTicketReconConfigQuery, t} = this.props;

        updateServiceTicketReconConfigMutation({
            variables: {
                id: serviceTicketId,
                reconConfig
            }
        })
            .then(() => {
                serviceTicketReconConfigQuery.refetch();
            })
            .catch(() => message.error(t('An error occurred')))
    };

    handleComplete = () => {

        const {createServiceActionMutation, serviceTicketReconConfigQuery, serviceTicketId, t } = this.props;

        this.setState({
            loading: true
        },() => {
            createServiceActionMutation({
                variables: {
                    input: {},
                    serviceActionTypeIndex: 'reconCompleted',
                    serviceTicketId
                }
            })
                .then((res) => {
                    return serviceTicketReconConfigQuery.refetch();
                })
                .catch((e) => {
                    console.error(e);
                    message.error(t('An error occurred while creating this service action'))
                })
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                })
        });


    };



    render() {
        const {t, serviceTicketReconConfigQuery, serviceTicketId} = this.props;
        const { loading } = this.state;

        const {serviceTicket} = serviceTicketReconConfigQuery;

        const {reconConfig} = serviceTicket;

        const reconId = _.get(reconConfig, 'reconComponent.id');
        const reconCompleted = _.get(serviceTicket, 'reconCompleted', false);

        const settings = {
            size: '70px'
        };



        const component = _.get(serviceTicketReconConfigQuery,'serviceTicket.component');

        return <div className={"p-10"}>
            <Row gutter={12}>
                <Col md={10}>
                    <ReconComponentContainer
                        title={t('Recon-Config')}
                        componentData={_.get(component,'access.read') === false ? _.get(serviceTicketReconConfigQuery,'serviceTicket.component'): undefined}
                        componentId={_.get(serviceTicketReconConfigQuery,'serviceTicket.component.id')}
                        path={_.get(serviceTicketReconConfigQuery,'serviceTicket.reconConfig.serviceTicketComponentDestination.path')}
                        onPathComponentChange={!reconCompleted ? (id) => this.handleSave({
                            serviceTicketComponentDestinationId: id
                        }): null}
                    />
                </Col>
                <Col md={4}>
                    <div style={{marginTop: 100, marginBottom: 40, textAlign: 'center'}}>
                        <FaExchange {...settings}/>
                    </div>

                    {reconCompleted
                        ? <div className={"text-center"} style={{marginTop: -100}}>
                            <FontAwesomeIcon style={{marginRight: 0, marginTop: 13}} color={Colors.success} name={'check-circle'} size={"2x"}/>
                            <div className={"mt-30"}>
                            <Bold>
                                {t('Recon was completed')}
                            </Bold>
                            </div>
                        </div>

                        : reconId ? <div style={{textAlign: 'center'}}>
                            <Button loading={loading} type={'primary'} onClick={this.handleComplete}>
                                {t('Complete recon')}
                            </Button>
                        </div> : null
                    }

                </Col>

                <Col md={10}>
                    <ReconComponentContainer
                        title={t('Target')}
                        componentData={_.get(serviceTicketReconConfigQuery,'serviceTicket.reconConfig.reconComponent')}
                        componentId={_.get(serviceTicketReconConfigQuery,'serviceTicket.reconConfig.reconComponent.id')}
                        onComponentChange={!reconCompleted ? (id) => this.handleSave({
                            reconComponentId: id
                        }): null}
                        path={_.get(serviceTicketReconConfigQuery,'serviceTicket.reconConfig.reconComponentDestination.path')}
                        onPathComponentChange={!reconCompleted ? (id) => this.handleSave({
                            reconComponentDestinationId: id
                        }):null}
                    />
                </Col>
            </Row>
        </div>;
    }
}


export default compose(
    withTranslation(),
    graphql(ServiceTicketReconConfigQuery, {
        name: 'serviceTicketReconConfigQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketReconConfigQuery', 'serviceTicket'),
    graphql(updateServiceTicketReconConfig, {
        name: 'updateServiceTicketReconConfigMutation'
    }),
    graphql(CreateServiceActionMutation, {
        name: 'createServiceActionMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceActionsTimelineQuery,
                    variables: {
                        id: props.serviceTicketId,
                    }
                }
            ]
        })
    }),
)(ReconDetailView);
