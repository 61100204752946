// @flow
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import ContentHeader from "../../components/Typo/ContentHeader";
import ServiceCenterList from "../../components/ServiceCenterList";
import {compose} from "redux";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};

class ServiceCentersListRoute extends Component<Props, State> {


    render() {
        const {t, history} = this.props;

        return <NavigationContainer navigationBar={
            <ContentHeader
                title={t('ServiceCenters')}
                tools={<Link to={"/admin/servicecenters/new"}>
                    <Button>
                        {t('Add')}
                    </Button>
                </Link>}
            />
        } >
            <ServiceCenterList onClick={(record) => history.push(`/admin/servicecenters/${record.id}`)}/>
        </NavigationContainer>
    }
}

export default compose(
    withResources(["administrateServiceCenters"]),
    withRouter,
    withTranslation(),
)(ServiceCentersListRoute);
