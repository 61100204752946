// @flow
import React, {Component} from "react"
import _ from 'lodash'
import CustomAttributeDashboard from "../../components/CustomAttributeDashboard";
import Error from "../../components/Error";
import withResources from "../../hoc/withResources";

type Props = {
    match: any,
}

class CustomAttributeDashboardRoute extends Component<Props> {


    render() {

        const {match} = this.props;
        const index = _.get(match, 'params.index');

        if (!index) {
            return <Error type="404"/>
        }

        return <CustomAttributeDashboard index={index}/>;
    }

}

export default withResources(["administrateCustomAttributes"])(CustomAttributeDashboardRoute)
