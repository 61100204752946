// @flow
import React, {Component} from "react"
import {Avatar, List} from "antd";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import "./styles.css";
import {Link} from "react-router-dom";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import EllipseLabel from "../EllipseLabel";
import Bold from "../Utilities/Bold";

type Props = {
    onClick?: Function,
    showPath: ?boolean,
    useLink: ?boolean,
    item: any,
}

class ServiceTicketSearchListItem extends Component<Props> {


    render() {

        const {item, onClick, showPath, useLink, t} = this.props;

        const component = _.get(item, 'component');


        const listItem = <List.Item className={'serviceticket-search-list-item'} onClick={onClick}>
            <List.Item.Meta
                avatar={<Avatar src={_.get(item, 'componentImage.thumbnailLink')}/>}
                title={<div style={{width: "100%"}}>
                    <Bold>{_.get(item, 'key')}</Bold>: <span>{_.get(item, 'name')}</span></div>}
                description={<div>
                    <div>{_.get(item, 'client.name')}</div>
                    {component ?
                        <span>{component.name} {component.serial ? `(SN: ${component.serial})` : null}</span> : t('No component linked')}
                    {showPath && component ? <EllipseLabel maxWidth={200}><ComponentPathBreadcrumb
                        path={component.path}/></EllipseLabel> : null}
                </div>}
            />
        </List.Item>;

        if (useLink) {
            return <Link to={`/service/tickets/${item.id}`}>{listItem}</Link>;
        }

        return listItem;

    }

}

export default withTranslation()(ServiceTicketSearchListItem);
