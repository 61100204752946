// @flow
import React, {Component} from 'react';
import {Modal, Tooltip} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import ComponentSubscribeForm from "../ComponentSubscribeForm";
import ComponentSubscriptionQuery from "./../../graphql/queries/componentSubscription.graphql"

type Props = {
    componentId: string,
    t: Function,
    componentSubscriptionQuery: query,
};

type State = {
    showModal: boolean,
}

class ComponentSubscriptionButton extends Component<Props, State> {

    state = {
        showModal: false,
    };

    _hideModal = () => {
        this.setState({
            showModal: false
        })
    };

    _showModal = () => {
        this.setState({
            showModal: true
        })
    };

    render() {
        const {t, query, componentId} = this.props;

        const componentSubscription = _.get(query, 'component.componentSubscription', {});

        return <Tooltip title={t('click to subscribe')}>
            <Button shape="circle" type={componentSubscription.active ? "primary" : "default"}
                    onClick={this._showModal} size={'large'}>
                <Icon type="notification"/>
            </Button>
            <Modal
                width={660}
                destroyOnClose
                bodyStyle={{padding: 0, paddingBottom: 8}}
                footer={null}
                title={t('Subscribe component')}
                visible={this.state.showModal}
                onOk={this._hideModal}
                onCancel={this._hideModal}
            >
                <ComponentSubscribeForm componentId={componentId}
                                        onSubmit={this._hideModal}/>
            </Modal>

        </Tooltip>;
    }
}

export default compose(
    graphql(ComponentSubscriptionQuery, {
        name: 'query',
        options: (props) => ({
            variables: {
                id: props.componentId
            }
        })
    }),
    withTranslation(),
)(ComponentSubscriptionButton);
