// @flow
import React, {Component} from 'react';
import _ from "lodash";
import Error from "../../components/Error";
import ServiceCenterDashboard from "../../components/ServiceCenterDashboard";
import withResources from "../../hoc/withResources";

type Props = {
    match: {
        id: string,
    },
    history: {
        goBack: Function,
        push: Function,
    }
};

class ServiceCenterDashboardRoute extends Component<Props, State> {

    render() {

        const {match} = this.props;
        const id = _.get(match, 'params.id');

        if (!id) {
            return <Error type="404"/>
        }

        return <ServiceCenterDashboard serviceCenterId={id} onDelete={() => {
            this.props.history.push(`/admin/servicecenters`)
        }}/>;
    }
}

export default withResources(["administrateServiceCenters"])(ServiceCenterDashboardRoute);
