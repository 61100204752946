// @flow
import React, {Component} from "react"
import withResources from "../../hoc/withResources";
import ContentHeader from "../../components/Typo/ContentHeader";
import CompanyDashboard from "../../components/CompanyDashboard";
import NavigationContainer from "../../components/NavigationContainer";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";


type Props = {
    match: any
}

class CompanyDashboardRoute extends Component<Props> {

    render() {
        return <NavigationContainer scroll navigationBar={<ContentHeader title={this.props.t('Company')}/>}>
            <div className={'full-height-from-content-header bg-light-grey'}>

                <CompanyDashboard />

            </div>
        </NavigationContainer>
    }

}

export default compose(
    withResources(["administrateCompany"]),
    withTranslation(),
)(CompanyDashboardRoute);
