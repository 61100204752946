// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ServiceCenterListQuery from "./../../graphql/queries/serviceCenterList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import type {Query} from "../../types";
import FillQueryTable from "../QueryTable/fill";
import './index.css';

type Props = {
    serviceCenterListQuery: Query,
    onClick: Function,
};


class ServiceCenterList extends Component<Props, State> {


    render() {
        const {serviceCenterListQuery, t, onClick} = this.props;

        return <div className={'ServiceCenterList'}>
            <FillQueryTable
                style={{borderRadius: 0}}
                className={"link-table"}
                columns={[
                    {
                        key: 'name',
                        dataIndex: 'name',
                        title: t('Name')
                    }
                ]}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onClick(record);
                        },
                    };
                }}
                size={'small'}
                rowKey={"id"}
                query={serviceCenterListQuery}
                itemsKeyPath={'serviceCenterList.serviceCenters'}
                totalKeyPath={'serviceCenterList.total'}/>
        </div>
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceCenterListQuery, {
        name: 'serviceCenterListQuery',
        options: () => ({
            variables: {
                start: 0,
                limit: 40,
            }
        }),
    }),
    waitWhileLoading('serviceCenterListQuery', 'serviceCenterList.serviceCenters'),
)(ServiceCenterList);
