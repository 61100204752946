// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import {Tabs} from "antd";
import {graphql} from '@apollo/client/react/hoc';
import AttributesInlineForm from "./../../components/Form/attributes-inline-form";
import ServiceTicketAttributesQuery from "../../graphql/queries/serviceTicketAttributes.graphql";
import UpdateServiceTicketMutation from "../../graphql/mutations/updateServiceTicket.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import type {Attribute} from "../../types";
import Panel from "../../components/Panel";

type Props = {
    ticketId: string,
    index: string,
    attributes: Attribute[],
    updateServiceTicket: Function,
};


class ServiceTicketAttributesRoute extends Component<Props, State> {

    renderForm = (attributes) => {
        const {ticketId, updateServiceTicket} = this.props;

        return <AttributesInlineForm
            useSubmit
            attributes={attributes}
            mutation={updateServiceTicket}
            translateMutationOptions={(res) => {

                const values = res.values;
                const changes = Object.keys(values).reduce((acc, key) => {
                    const attribute = _.find(attributes,{index: key});
                    if (attribute.value !== values[key]){
                        acc[key] = values[key];
                    }
                    return acc;
                },{});
                return {
                    variables: {
                        id: ticketId,
                        attributes: changes
                    }
                }
            }}/>
    };

    render() {

        const {attributes, index} = this.props;

        const categories = attributes.reduce((acc, cur) => {
            cur.categories.forEach((category) => {
                if (!acc[category.index]) {
                    acc[category.index] = category;
                }
            });
            return acc;
        }, {});

        if (categories.length) {
            const filteredAttributes = attributes.filter(item => {
                return item.categories.filter(category => category.index === index).length > 0
            });

            return <div>
                <div className="white-bg">
                    <Tabs tabBarStyle={{marginBottom: 0, padding: '0 20px'}} defaultActiveKey={index}
                          onChange={this.onChange}>
                        {Object.keys(categories).map(key => {
                            return <Tabs.TabPane tab={categories[key].name} key={key}/>
                        })}
                    </Tabs>
                </div>
                <div className="white-bg p-20 mt-10">
                    {this.renderForm(filteredAttributes)}
                </div>

            </div>;
        } else {
            return <Panel title={'Attributes'}>
                {this.renderForm(attributes)}
            </Panel>
        }


    }

}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => ({
        ticketId: _.get(props, 'match.params.id'),
        index: _.get(props, 'index'),
    })),
    graphql(ServiceTicketAttributesQuery, {
        name: 'serviceTicketAttributesQuery',
        options: props => ({
            variables: {
                id: props.ticketId
            }
        }),
    }),
    waitWhileLoading('serviceTicketAttributesQuery', 'serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes'),
    withProps((props) => ({
        attributes: _.get(props, 'serviceTicketAttributesQuery.serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes')
    })),
    graphql(UpdateServiceTicketMutation, {
        name: 'updateServiceTicket',
    }),
)(ServiceTicketAttributesRoute);

