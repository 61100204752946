import React from "react";
import TDAAttributesForm from "../TDAAttributesForm";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import ServiceActionQuery from "../../graphql/queries/serviceActionTypeDetails.graphql";
import Loader from "../Loader";
import _ from "lodash";

interface Props {
    index: string,
}

const ServiceActionTypeInfo = (props: Props) => {

    const {t} = useTranslation();

    const query = useQuery(ServiceActionQuery, {
        variables: {
            index: props.index
        }
    })

    if (query.loading) return <Loader/>

    return <TDAAttributesForm
        readOnly
        values={_.get(query,'data.serviceActionType')}
        attributes={[{
            index: 'code',
            name: t('Code'),
            inputType: 'LABEL'
        },{
            index: 'name',
            name: t('Name'),
            inputType: 'LABEL'
        },{
            index: 'index',
            name: t('Index'),
            inputType: 'LABEL'
        }]}
    />;
}

export default ServiceActionTypeInfo;
