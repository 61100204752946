// @flow
import React, {Component} from 'react';

type Props = {
    name: string,
    circled?: boolean,
    color?: string,
    className?: string,
    size?: string,
    outline?: boolean,
    style?: any,
};


class FontAwesomeIcon extends Component<Props, State> {


    render() {
        const {name, circled, color, size, outline, style, className} = this.props;

        if (outline) {
            if (circled) {
                return <span className={`fa-stack fa-${size} ${className}`} style={{color: color, ...style}}>
            <i className="fa fa-circle fa-stack-2x"/>
            <i className={`far fa-${name} fa-stack-1x fa-inverse`} data-fa-transform="shrink-5"/>
        </span>
            }
            return <i style={style} className={`far fa-${name} fa-${size}`}/>
        }

        if (circled) {
            return <span className={`fa-stack fa-${size} ${className}`} style={{color: color, ...style}}>
            <i className="fa fa-circle fa-stack-2x"/>
            <i className={`fas fa-${name} fa-stack-1x fa-inverse`} data-fa-transform="shrink-6"/>
        </span>
        }
        return <i className={`fas fa-${name} fa-${size} ${className}`} style={{color: color, ...style}}/>

    }
}

export default FontAwesomeIcon;
