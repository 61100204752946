// @flow
import React, {Component, useCallback, useRef, useState} from 'react';
import {message, Modal, Popconfirm, Table} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import update from 'immutability-helper';
import {compose} from "recompose";
import AttributeCategoryListQuery from '../../graphql/queries/attributeCategoryList.graphql';
import UpdateAttributeCategoryRankMutation from '../../graphql/mutations/updateAttributeCategoryRank.graphql';
import DeleteAttributeCategoryMutation from '../../graphql/mutations/deleteAttributeCategory.graphql';
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import '../ComponentTypeServiceReportConfigList/styles.less';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import NewCategoryModal from "../ComponentTypeServiceReportConfig/newCategoryModal";

type Props = {
  serviceReportAttributeConfigListQuery: query,
  updateAttributeCategoryRankMutation: Function,
  categoryType: string,
  onCategoryListRowClick: (row: any) => void,
  t: Function
};

type State = {
  visible: boolean
};

class CategoryList extends Component<Props, State> {

  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleDelete = (id) => {

    const { deleteAttributeCategoryMutation, t } = this.props;

    deleteAttributeCategoryMutation({
      variables: {
        id: id
      }
    }).then((res) => {
      message.success(t('Removed'));
    })
  };

  render() {
    const {attributeCategoryListQuery, updateAttributeCategoryRankMutation, t, onCategoryListRowClick} = this.props;

    const attributeCategories = _.get(attributeCategoryListQuery, 'attributeCategoryList.attributeCategories');

    const type = 'DragableBodyRow';

    const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
      const ref = React.useRef();
      const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
          const { index: dragIndex } = monitor.getItem() || {};
          if (dragIndex === index) {
            return {};
          }
          return {
            isOver: monitor.isOver(),
            dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
          };
        },
        drop: item => {
          moveRow(item.index, index);
        },
      });
      const [, drag] = useDrag({
        item: { type, index },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
      });
      drop(drag(ref));
      return (
        <tr
          ref={ref}
          className={`${className}${isOver ? dropClassName : ''}`}
          style={{ cursor: 'move', ...style }}
          {...restProps}
        />
      );
    };

    const columns = [
      {
        title: t('Name'),
        dataIndex: 'name',
        key: 'id',
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        width: 10,
        render: (item) => _.get(item, 'access.remove') ? <Popconfirm
            title={t('Please confirm to delete this item')}
            icon={<Icon type="delete" style={{color: 'red'}}/>}
            onConfirm={() => this.handleDelete(item.id)}
            okText={t('Confirm')}
            cancelText={t('Cancel')}
            placement="left"
        >
          <Icon type="delete" style={{color: 'red'}}/>
        </Popconfirm> : null
      }
    ];

    const DragSortingTable: React.FC = () => {
      const [data, setData] = useState(attributeCategories);

      const components = {
        body: {
          row: DragableBodyRow,
        },
      };

      const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
          const dragRow = data[dragIndex];
          setData(
            update(data, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
              ],
            }),
          );
          if(hoverIndex < dragIndex){
            updateAttributeCategoryRankMutation({
              variables: {
                categoryIds: data[dragIndex].id,
                afterId: data[hoverIndex].id
              }
            })
          }else{
            updateAttributeCategoryRankMutation({
              variables: {
                categoryIds: data[dragIndex].id,
                beforeId: data[hoverIndex].id
              }
            })
          }
        },
        [data],
      );

      const manager = useRef(this.props.context);

      return (
        <>
          <div className={'mb-10 flex-row'}>
            <div className={'flex-grow-1'}/>
            <Button
              style={{marginTop: '4px'}}
              onClick={this.showModal}
            >
              {t('Add')}
            </Button>
          </div>
          <DndProvider manager={manager.current.dragDropManager}>
            <Table
                columns={columns}
                dataSource={data}
                components={components}
                onRow={(record, index) => ({
                  index,
                  moveRow,
                  onClick: () => onCategoryListRowClick(record)
                })}
                pagination={false}
            />
          </DndProvider>
        </>
      )
    };

    return <>
      <div>
        <DragSortingTable />
      </div>
      <Modal
        title={t('New Category')}
        visible={this.state.visible}
        footer={null}
        onCancel={this.handleCancel}
      >
        <NewCategoryModal/>
      </Modal>
    </>
  }
}
export default compose(
  withRouter,
  withTranslation(),
  graphql(AttributeCategoryListQuery, {
    name: 'attributeCategoryListQuery',
    options: (props: Props) => ({
      variables: {
        params: {
          filter: {
            categoryType: {
              value: _.get(props, 'categoryType')
            }
          }
        }
      }
    })
  }),
  waitWhileLoading('attributeCategoryListQuery', 'attributeCategoryList.attributeCategories'),
  graphql(UpdateAttributeCategoryRankMutation, {
    name: "updateAttributeCategoryRankMutation",
  }),
  graphql(DeleteAttributeCategoryMutation, {
    name: "deleteAttributeCategoryMutation",
    options: (props: Props) => ({
      refetchQueries: [{
        query: AttributeCategoryListQuery,
        variables: {
          params: {
            filter: {
              categoryType: {
                value: _.get(props, 'categoryType')
              }
            }
          }
        }
      }]
    }),
  }),
)(CategoryList);
