// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import customAttributeList from "./../../graphql/queries/customAttributeList.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import FillQueryTable from "../QueryTable/fill";
import withProfile from "../../hoc/withProfile";

type Props = {
    customAttributeListQuery: query
}

class CustomAttributeList extends Component<Props> {

    render() {

        const {customAttributeListQuery, history, t, profile} = this.props;

        const clientId = _.get(profile, 'client.id');

        return <FillQueryTable
            style={{borderRadius: 0}}
            className={"link-table"}
            columns={[
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: t('Name')
                },
                {
                    key: 'type',
                    dataIndex: 'type',
                    title: t('Type'),
                    width: 100,
                },
                {
                    key: 'sharedForAllClients',
                    title: t('Share'),
                    dataIndex: 'sharedForAllClients',
                    render: (text, record) => {

                        const isOwner = _.get(record,"owner.id") === clientId;
                        if (_.get(record,"sharedForAllClients") && isOwner) {
                            return <span>{t("Shared with all clients")}</span>
                        }

                        if (_.get(record,"sharedClients",[]).length && isOwner) {
                            const CLIENTS = _.get(record, "sharedClients", []).length > 1 ? t("some clients") : _.get(record, "sharedClients[0].name", t("some clients"));
                            return <span>{t("Shared with CLIENTS",{CLIENTS})}</span>
                        }

                        if ((_.get(record,"sharedClients",[]).length || _.get(record,"sharedForAllClients")) && !isOwner) {
                            return t("Shared by OWNER", {OWNER: _.get(record,"owner.name")})
                        }

                        return null;
                    }
                },
                {
                    key: 'options',
                    dataIndex: 'options',
                    title: t('Options'),
                    render: (text, record) => {
                        return Object.keys(record.options).filter((key) => record.options[key] === true).map((item) => t(item)).join(", ")
                    }
                }
            ]}
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push(`/admin/attributes/${record.index}`)
                    },
                };
            }}
            size={'small'}
            rowKey={"index"}
            uniqueIdentifier={"index"}
            query={customAttributeListQuery}
            limitKeyPath={'params.limit'}
            startKeyPath={'params.start'}
            itemsKeyPath={'customAttributeList.customAttributes'}
            totalKeyPath={'customAttributeList.total'}/>
    }
}

export default compose(
    withProfile(),
    withRouter,
    withTranslation(),
    graphql(customAttributeList, {
        name: 'customAttributeListQuery',
        options: (props) => ({
            variables: {
                params: {
                    start: 0,
                    limit: 40,
                    filter: props.filter
                }
            }
        }),
    }),
    waitWhileLoading('customAttributeListQuery', 'customAttributeList.customAttributes')
)(CustomAttributeList);
