import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Col} from "../Flex";
import StatisticsTabFilter from "../StatisticsTabFilter";
import StatisticsHistoryTable from "../StatisticsHistoryTable";

type Props = {
}

const StatisticsHistory = ({}: Props) => {
    const {t} = useTranslation();

    const [filter, setFilter]: any = useState();

    return <Col className={'full-height'}>
        <StatisticsTabFilter
            filter={filter}
            onChange={(changes: any) => {
                setFilter({
                    ...filter,
                    ...changes
                })
            }}
        />

        <StatisticsHistoryTable
            filter={filter}
        />
    </Col>
};

export default StatisticsHistory
