// @flow
import React, {Component} from "react"
import _ from 'lodash'
import {List} from 'antd';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import portletList from './../../graphql/queries/portletList.graphql'
import createUserPortlet from './../../graphql/mutations/createUserPortlet.graphql'
import PortletListItem from "./portlet-list-item";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import DashboardPortletListQuery from "../../graphql/queries/DashboardPortletListQuery.graphql";

type Props = {
    getPortletList: any,
    createUserPortlet: Function,
    onCreate?: Function,
}

class AddDashboardPortlets extends Component<Props> {

    constructor(props) {
        super(props);

        this.state = {
            loadingPortletIndex: null
        }
    }


    onAddPortlet(portlet) {

        const index = portlet.index;

        this.setState({
            loadingPortletIndex: index
        }, () => {
            this.props.createUserPortlet({
                variables: {
                    index
                }
            })
                .then((res) => {
                    this.setState({
                        loadingPortletIndex: null
                    });
                    return res;
                })
                .then((res) => {
                    if (this.props.onCreate) {
                        this.props.onCreate(res)
                    }
                })
                .catch(() => {
                    this.setState({
                        loadingPortletIndex: null
                    });
                })

        })

    }

    render() {

        const {portletListQuery} = this.props;
        const {loadingPortletIndex} = this.state;

        return <div>
            <List
                loadMore={null}
                dataSource={_.get(portletListQuery, 'portletList.portlets', [])}
                renderItem={item => (
                    <PortletListItem portlet={item} loading={item.index === loadingPortletIndex}
                                     onAdd={() => this.onAddPortlet(item)}/>
                )}
            />
        </div>;
    }
}

export default compose(
    graphql(createUserPortlet, {
        name: 'createUserPortlet',
        options: () => ({
            refetchQueries: [
                {
                    query: DashboardPortletListQuery
                }
            ]
        })
    }),
    graphql(portletList, {
        name: 'portletListQuery',
    }),
    waitWhileLoading('portletListQuery', 'portletList.portlets')
)(AddDashboardPortlets);
