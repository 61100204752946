// @flow
import React, {Component} from "react"

import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import ProductSearch from "./ProductSerach";

type Props = {
    onSelect: ?Function,
}

class ProductSearchBar extends Component<Props> {

    state = {
        query: null
    };

    handleSearch = (query) => {
        this.setState({
            query
        });
    };

    render() {

        const {onSelect} = this.props;
        const {query} = this.state;

        return <ProductSearch onSelect={onSelect} query={query} debounce={500} handleSearch={this.handleSearch}/>
    }

}

export default compose(
    withTranslation()
)(ProductSearchBar);
