// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import UpdateServicePlanMutation from "./../../graphql/mutations/updateServicePlan.graphql"
import ServicePlanQuery from "./../../graphql/queries/ServicePlanDateInfo.graphql";
import AttributesInlineForm from "./../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    servicePlanId: string,
    servicePlanQuery?: any,
    updateServicePlanMutation: Function,
};


const attributeWhiteList = ['dueDate', 'description', 'name', 'defaultServiceTaskId', 'isPlanned'];

class ServicePlanDateInfoBox extends Component<Props, State> {


    render() {
        const {servicePlanQuery, servicePlanId, updateServicePlanMutation} = this.props;

        const attributes = _.chain(servicePlanQuery)
            .get('servicePlan.typeDefinitionAttributeList.typeDefinitionAttributes', [])
            .filter(item => {
                if (_.get(item, 'index') === 'defaultServiceTaskId' && !_.get(item, 'defaultServiceTask')) {
                    return false;
                }
                return attributeWhiteList.indexOf(_.get(item, 'index')) !== -1
            })
            .value();

        return <div>
            <AttributesInlineForm
                inline
                attributes={attributes}
                layout={"vertical"}
                translateMutationOptions={({values}) => ({
                    variables: {
                        attributes: values,
                        servicePlanId
                    }
                })}
                mutation={updateServicePlanMutation}
            />
        </div>;
    }
}


export default compose(
    graphql(ServicePlanQuery, {
        name: 'servicePlanQuery',
        options: (props: Props) => ({
            variables: {
                servicePlanId: props.servicePlanId
            }
        }),
    }),
    graphql(UpdateServicePlanMutation, {
        name: 'updateServicePlanMutation'
    }),
    waitWhileLoading('servicePlanQuery', 'servicePlan.typeDefinitionAttributeList.typeDefinitionAttributes')
)(ServicePlanDateInfoBox);
