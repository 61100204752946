// @flow
import React, {Component} from 'react';
import _ from "lodash";
import ServiceActionWizardForm from "../../components/ServiceActionWizardForm";
import Panel from "../../components/Panel";
import ContentWrapper from "../../components/ContentWrapper";
import {withRouter} from "react-router-dom";


class ServiceActionCreateRoute extends Component<Props, State> {

    _handleSubmit = (res) => {
        const {match, history} = this.props;
        const ticketId = _.get(match, 'params.id');
        const id = _.get(res, 'data.createServiceAction.id')
        if (id) history.push(`/service/${ticketId}/actions/${id}`);

    };

    render() {
        const {match} = this.props;
        const ticketId = _.get(match, 'params.id');

        return <ContentWrapper>
            <Panel title={'New service action'}>
                <ServiceActionWizardForm

                    useSubmit={true}
                    onSaved={this._handleSubmit}
                    serviceTicketId={ticketId}/>
            </Panel>
        </ContentWrapper>;
    }
}

export default withRouter(ServiceActionCreateRoute);
