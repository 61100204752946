// @flow
import React, {Component} from "react"
import InfrastructureSearchToolbar from "./toolbar"
import InfrastructureSearchResults from "./results"
import NavigationContainer from "../NavigationContainer";
import {Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import InfrastructureSearchExport from "../InfrastructureSearchExport";

type Props = {
    params?: any,
    onChange?: Function,
}

type State = {
    showBookmarks: boolean,
    selectedItems: string[]
}

class InfrastructureSearch extends Component<Props, State> {
    state = {
        selectedItems: []
    };

    onClick = () => {
        const {onChange} = this.props;

        if (onChange) {
            onChange();
        }
    };

    render() {
        const {params, onChange, t} = this.props;

        return <div className={"full-size"}>
            <NavigationContainer navigationBar={
                <InfrastructureSearchToolbar
                    params={params}
                    onChange={onChange}
                    renderRight={() => <div style={{width: 140, marginTop: 4, textAlign: 'right'}}>
                        <Tooltip title={t("Reset")}>
                            <Button
                                data-cy={'service-search-filter-reset-button'}
                                style={{marginRight: 8}}
                                type={"danger"}
                                icon={"rollback"}
                                onClick={() => this.onClick()}
                            />
                        </Tooltip>
                        <InfrastructureSearchExport params={params}/>
                    </div>}
                />
            }>
                <InfrastructureSearchResults
                    params={params}
                    onChange={onChange}
                    onRef={ref => (this.searchResults = ref)}
                    bookmarkCollapsed={this.state.collapsed}
                    onChangeSelectedItems={(items) => this.setState({selectedItems: items})}
                    selectedItems={this.state.selectedItems}
                />
            </NavigationContainer>
        </div>
    }
}


export default compose(
    withTranslation(),
    withRouter
)(InfrastructureSearch);

