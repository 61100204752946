import React from "react"
import {InputTypeFactory} from "@dreebit-registry/form-attributes-vsm";
import AttributeComponent from "./AttributeComponents/Component";
import AttributeFiles from "./AttributeFiles";
import AttributeHidden from "./AttributeHidden";

const createInputTypeFactory = () => {
    const inputTypeMap = InputTypeFactory.build();

    inputTypeMap["HIDDEN"] = (attribute:any, form: any, style: any, translations: any) => {
        return <AttributeHidden
            key={attribute.index}
            attribute={attribute}
            form={form}
            style={style}
            translations={translations}
        />
    }

    inputTypeMap["FILES"] = (attribute:any, form: any, style: any, translations: any) => {
        return <AttributeFiles
            key={attribute.index}
            attribute={attribute}
            form={form}
            style={style}
            translations={translations}
        />
    }

    inputTypeMap["COMPONENT"] = (attribute: any, form: any, style: any, translations: any) => {
        return <AttributeComponent
            key={attribute.index}
            attribute={attribute}
            form={form}
            style={style}
            translations={translations}
        />
    }

    inputTypeMap["COMPONENTPICKER"] = inputTypeMap["COMPONENT"]

    return inputTypeMap;
}

export default createInputTypeFactory;
