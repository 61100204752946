// @flow
import React from 'react';
import MessageDashboardDetail from "./../../../components/MessageDashboard/detail"
import {useParams} from "react-router-dom";

const ConversationRoute = ({}: Props) => {
    const {id} = useParams();

    return  <MessageDashboardDetail
        conversationId={id}
    />
}

export default ConversationRoute;
