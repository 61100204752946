import React from 'react';
import Loader from "../Loader";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import CreateClientMutation from "../../graphql/mutations/createClient.graphql";
import _ from "lodash";
import {useHistory} from "react-router-dom";

interface Props {

}

const CreateClientClientInformationStep = ({addressData, client}: Props) => {

    const {t} = useTranslation();
    const history = useHistory();

    const attributesFromProps = _.get(client, 'typeDefinitionAttributeList.typeDefinitionAttributes')
    const typeDefinitionAttributesQuery = useQuery(TypeDefinitionAttributeListQuery, {
        variables: {
            index: "client"
        },
        skip: attributesFromProps
    })

    const [createClient] = useMutation(CreateClientMutation);

    if (typeDefinitionAttributesQuery?.loading) {
        return <Loader />
    }

    let attributes = attributesFromProps ?
        attributesFromProps
        : _.get(typeDefinitionAttributesQuery, 'data.typeDefinitionAttributeList.typeDefinitionAttributes')
    ;

    attributes = attributes.filter(x => x.categories.includes("serviceProviderClient"))

    return <div className={"w-75"} style={{margin: "0 auto"}}>
        <div className={'mb-20'}>
            {t('You can now enter a customer number and a contact person for this client.')}
        </div>
        <AttributesInlineForm
            attributes={attributes}
            mutation={createClient}
            useSubmit
            submitTitle={t('Submit')}
            translateMutationOptions={({values}) => {
                if (_.get(client, 'id')) {
                    return {
                        variables: {
                            clientId: _.get(client, 'id'),
                            input: {
                                ...values
                            }
                        },
                        refetchQueries: ["clientList"]
                    }
                } else {
                    return {
                        variables: {
                            input: {
                                ...addressData,
                                ...values
                            }
                        },
                        refetchQueries: ["clientList"]
                    }
                }
            }}
            onSubmit={(res) => {
                const clientId = _.get(res, 'data.createClient.id')

                if (clientId) {
                    history.push(`/admin/clients/${clientId}/form`)
                }
            }}
        />
    </div>

}

export default CreateClientClientInformationStep;
