// @flow
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import moment from "moment";
import './index.css';

type Props = {
    items: any[],
    renderItem?: (item: any, index: number) => any
}

const TimeQueryListItem = ({items, renderItem}: Props) => {
    const {t} = useTranslation();

    const logsByDate = _.groupBy(items, (item: any) => {
        return moment(item.date).format('LL');
    });

    let groupedItems = Object.keys(logsByDate)
        .reduce((acc, key) => {
            acc = [
                ...acc,
                {
                    type: "section",
                    day: key
                },
                ...logsByDate[key]
                    .map((item) => ({
                        ...item,
                        type: "item"
                    }))
            ];
        return acc;
    }, []);

    return <div
        className="timeline-wrapper"
    >
        {
            groupedItems.map((item: any, index: number) => {
                if (item.type === "section") {
                    return <div
                        className={"date-section"}
                        key={index}
                    >
                        {item.day}
                    </div>
                } else if (item.type === "item") {
                    if(renderItem) return renderItem(item);
                    return null;
                }

                return null;
            })
        }
    </div>
};

export default TimeQueryListItem
