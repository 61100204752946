// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ServiceProviderQuery from "../../graphql/queries/serviceProvider.graphql"

const ServiceProviderBreadcrumb = ({serviceProviderQuery, t}) =>
    <span>{_.get(serviceProviderQuery, 'serviceProvider.name', t('Unknown'))}</span>;

export default compose(
    graphql(ServiceProviderQuery, {
        name: 'serviceProviderQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.serviceProviderId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(ServiceProviderBreadcrumb));
