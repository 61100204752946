// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentListSearchQuery from "../../graphql/queries/componentList-Search.graphql"
import SubselectionSiteList from "./subselectionSiteList";
import StockSearchInput from "../StockSearchInput";
import _ from "lodash";
import groupSites from "./groupSites";
import ContentHeader from "../Typo/ContentHeader";
import {withTranslation} from "react-i18next";
import SubselectionList from "../StockList/subselectionList";
import './index.css';

type Props = {
    filter: {},
    t: Function,
};

class StockSiteList extends Component<Props> {

    render() {
        const {componentListSearchQuery, t} = this.props;

        const components = _.get(componentListSearchQuery, 'componentList.components');
        const componentsBySite = groupSites(components);

        let content = null;

        const keys = Object.keys(componentsBySite);

        if (keys.length === 1){
            const first = _.first(keys)
            if (componentsBySite[first] && componentsBySite[first].length){
                content = componentsBySite[first].map(item => <SubselectionList
                    key={item.id}
                    component={item}
                />)
            }
        }

        if (!content){
            content = keys.map((key, index) => {
                return <SubselectionSiteList
                    key={index}
                    site={key}
                    subComponents={componentsBySite[key]}
                />
            });
        }


        return <div className={'StockWrapper flex-col'}>
            <ContentHeader title={t('Location')} toolsClassName={"flex-grow-1"} tools={<StockSearchInput/>}/>
            <div className="overflow-scroll" style={{}}>
                <div className="flex flex-flow-row-wrap flex-space-around" style={{minHeight: 'min-content'}}>
                    {content}
                </div>
            </div>
        </div>;
    }
}

export default compose(
    graphql(ComponentListSearchQuery, {
        name: 'componentListSearchQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: props.filter
                }
            }
        })
    }),
    waitWhileLoading('componentListSearchQuery', 'componentList'),
    withTranslation(),
)(StockSiteList);

