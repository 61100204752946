// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import CatalogAttributeQuery from "../../graphql/queries/CatalogAttribute.graphql";

const AttributesBreadcrumb = ({CatalogAttributeQuery, t}) =>
    <span>{_.get(CatalogAttributeQuery, 'catalogAttribute.name', t('Unknown'))}</span>;

export default compose(
    graphql(CatalogAttributeQuery, {
        name: 'CatalogAttributeQuery',
        options: ({match}) => ({
            variables: {
                index: _.get(match, 'params.index')
            }
        }),
    }),
)(withTranslation()(AttributesBreadcrumb));
