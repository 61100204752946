import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    number: number
}

export function formatCurrency(number, t) {
    const value = new Intl.NumberFormat(t('locale'), { style: 'currency', currency: t('EUR') }).format(number)
    return value
}

const CurrencyLabel = ({number}: Props) => {

    const { t } = useTranslation()


    return <span>{formatCurrency(number, t)}</span>;
}

export default CurrencyLabel;
