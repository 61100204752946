import React from "react";
import {useQuery} from "@apollo/client";
import statisticList from "../../graphql/queries/statisticList.graphql";
import _ from "lodash";
import {Row} from "../Flex";
import StatisticsPanel from "../StatisticsPanel";
import {useTranslation} from "react-i18next";
import './index.css';
import {AppstoreOutlined, FileTextOutlined, ShopOutlined, UserOutlined} from "@ant-design/icons";
import {getFilterVariables} from "../../routes/admin/StatisticsRoute";

type Props = {
    filter?: any
}

const StatisticsDashboardSummaryPanels = ({filter}: Props) => {
    const {t} = useTranslation();

    const {data, loading} = useQuery(statisticList, {
        variables: {
            params: {
                filter: getFilterVariables(filter),
                type: 'total',
            }
        }
    });

    const statistics: any[] = _.get(data, 'statisticList.statistics', []);
    const statistic: ?{
        userCount: number | string,
        clientCount: number | string,
        componentCount: number | string,
        serviceTicketCount: number | string
    } = _.first(statistics);

    return <Row className={`StatisticsDashboardPanels`}>
        <StatisticsPanel
            loading={loading}
            title={t('Components')}
            count={_.get(statistic, 'componentCount')}
            icon={<AppstoreOutlined />}
        />

        <StatisticsPanel
            loading={loading}
            title={t('Clients')}
            count={_.get(statistic, 'clientCount')}
            icon={<ShopOutlined />}
        />

        <StatisticsPanel
            loading={loading}
            title={t('User')}
            count={_.get(statistic, 'userCount')}
            icon={<UserOutlined />}
        />

        <StatisticsPanel
            loading={loading}
            title={t('Service Tickets')}
            count={_.get(statistic, 'serviceTicketCount')}
            icon={<FileTextOutlined />}
        />
    </Row>
};

export default StatisticsDashboardSummaryPanels;
