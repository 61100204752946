// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ClientDetailsQuery from "../../graphql/queries/clientDetails.graphql"

const ClientBreadcrumb = ({clientDetailsQuery}) => <span>{_.get(clientDetailsQuery, 'client.name', '...')}</span>;

export default compose(
    graphql(ClientDetailsQuery, {
        name: 'clientDetailsQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.clientId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(ClientBreadcrumb));
