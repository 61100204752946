// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import ProductAttributesDetail from "../ProductAttributesDetail";

type Props = {
    t: Function,
    getProductName: query,
    productId: string
};


class ProductAttributes extends Component<Props, State> {

    render() {
        const { productId } = this.props;

        return <ProductAttributesDetail id={productId} />
    }
}

export default compose(

)(ProductAttributes);
