// @flow
import React, {Component, ReactNode} from 'react';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import type {QueryConfig} from "../../types";
import QueryTable from "../QueryTable";
import {Query} from '@apollo/client/react/components';
import {Row} from "../Flex";
import QuerySelect from "../QuerySelect";
import MutationButton from "../MutationButton";

type Props = {
    renderAddSelectOption?: (item: any) => ReactNode,
    addQueryConfig: QueryConfig,
    dataSourceQueryConfig: QueryConfig,
    onAdd: (value: any) => any,
    onDelete: (ids: string[]) => Promise<any>,
    tableProps: any,
};

type State = {

}

class MutationTable extends Component<Props, State> {

    state = {
        selectedRowKeys: [], // Check here to configure the default column
        addModalVisible: true,
    };

    onSelectChange = selectedRowKeys => {

        this.setState({ selectedRowKeys });
    };


    render() {
    const { tableProps, t, dataSourceQueryConfig, addQueryConfig, onDelete, onAdd, renderAddSelectOption } = this.props;

        const { selectedRowKeys } = this.state;

      const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
      };
        const hasSelected = selectedRowKeys.length > 0;


        return <Query query={dataSourceQueryConfig.query} variables={dataSourceQueryConfig.variables}>
            {(query) => {

                return <div>
                    <Row className={"white-bg mb-10"}>
                        { hasSelected ?
                            <div>
                                {
                                    onDelete ?  <MutationButton mutation={() => onDelete(this.state.selectedRowKeys).then( () => query.refetch()) } >{t("Delete")} ({selectedRowKeys.length})</MutationButton> : null
                                }
                            </div>
                            :
                            onAdd && addQueryConfig ? <QuerySelect
                                renderOption={renderAddSelectOption}
                                onSelect={(value) => {

                                    return onAdd(value).then( () => query.refetch() )
                                }}
                                queryConfig={addQueryConfig}/> : null
                        }

                    </Row>
                    <QueryTable
                        itemsKeyPath={dataSourceQueryConfig.dataKeyPath}
                        totalKeyPath={dataSourceQueryConfig.totalKeyPath}
                        query={query}
                        rowSelection={rowSelection}
                        {...tableProps} />
                </div>

            }}
        </Query>
  }
}

export default compose(
    withTranslation()
)(MutationTable);
