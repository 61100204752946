// @flow
import React, {Component} from "react"
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import type {Query} from "../../types";
import ProductStatusLabel from "./../ProductStatusLabel";
import FillQueryTable from "../QueryTable/fill";
import ManufacturerListQuery from "../../graphql/queries/manufacturerList.graphql";

type Props = {
    manufacturerListQuery: Query,
    t: Function,
    filterValues: any,
    onClick: Function,
    tableProps?: any,
    removeKeyById?: string
}

class ManufacturerMatchList extends Component<Props> {
    render() {
        const {manufacturerListQuery, t, onClick, tableProps, removeKeyById} = this.props;

        return <div className={"full-size"}>
            <FillQueryTable
                className={"link-table"}
                size={'middle'}
                onRow={(record) => {
                    return {
                        onClick: () => onClick ? onClick(record) : null
                    };
                }}
                query={manufacturerListQuery}
                itemsKeyPath={'manufacturerList.manufacturers'}
                totalKeyPath={'manufacturerList.total'}
                rowKey={"id"}
                removeKeyById={removeKeyById}
                columns={[
                    {
                        dataIndex: 'name',
                        title: t('Name'),
                        key: 'name',
                        sorter: true,

                    }, {
                        dataIndex: 'status',
                        title: t('Status'),
                        key: 'status',
                        render: (text) => <ProductStatusLabel status={text}/>
                    }
                ]}
                {...tableProps}
            />
        </div>


    }
}

const getParams = (filter) => {
    const params = {};
    if (filter.status) {
        _.set(params, 'filter.status', {
            operator: "in",
            value: _.get(filter, 'status', "published")
        })
    }

    if (filter.query) {
        _.set(params, 'search', {
            query: _.get(filter, 'query', "")
        })
    }

    return params;
};

export default compose(
    withTranslation(),
    graphql(ManufacturerListQuery, {
        name: 'manufacturerListQuery',
        options: props => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                params: {
                    ...getParams(props.filterValues),
                    limit: 30,
                    start: 0,
                }
            }
        }),
    }),
)(ManufacturerMatchList);
