// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {Table} from "antd";

import "./index.css";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import TileFilelist from "../TileFilelist";

type Props = {
    groups?: {
        title: string,
        index: string,
    }[],
    groupKey?: string,
    dataSource: any[],
    columns: any[],
    fileTableView: boolean,
    onCategoryClick?: Function,
    onPrivateChange?: Function,
    onMenuClick?: Function,
    rowKey?: any,
    selectedRowIds?: any,
    changeSelectedRowIds?: any,
    categories?: any,
    customActions: any,
};

type State = {
    selectedRowIds: any[]
}

class GroupedTable extends Component<Props, State> {

    static getDerivedStateFromProps(nextProps) {
        return {
            selectedRowIds: _.get(nextProps, 'selectedRowIds', []),
        }
    }

    render() {
        const {
            dataSource,
            categories,
            changeSelectedRowIds,
            rowKey,
            groupKey,
            groups,
            columns,
            rowClassName,
            fileTableView,
            t,
            onCategoryClick,
            onPrivateChange,
            onMenuClick,
            customActions
        } = this.props;
        const {selectedRowIds} = this.state;

        let data = dataSource;
        let cols = columns;

        if (groupKey && groups) {
            const grouped = _.groupBy(dataSource, groupKey);
            data = Object.keys(grouped).reduce((acc, groupIndex) => {

                acc.push({
                    __type: 'category',
                    title: _.get(_.find(groups, {index: groupIndex}), 'title', groupIndex),
                    index: groupIndex,
                    key: groupIndex,
                    id: groupIndex,
                });

                grouped[groupIndex].forEach((item) => {
                    acc.push(item)
                });

                return acc;
            }, []);

            cols = columns.map((item, index) => {
                const col = {...item};
                if (index === 0) {
                    col.render = (text, row) => {
                        if (row.__type === 'category') {
                            return {
                                children: <span>{row.title}</span>,
                                props: {
                                    colSpan: columns.length + 1,
                                },
                            };
                        }
                        return columns[index].render ? columns[index].render(text, row) : text;
                    }
                } else {
                    col.render = (text, row) => {
                        if (row.__type === 'category') {
                            return {
                                props: {
                                    colSpan: 0,
                                },
                            };
                        }
                        return columns[index].render ? columns[index].render(text, row) : text;
                    }
                }
                return col;
            });
        }

        const rowSelection = {
            selectedRowKeys: selectedRowIds,
            onSelect: (record: any, selected: boolean, selectedRows: any) => {
                let checkedList = _.cloneDeep(selectedRowIds);

                const recordIndex = _.get(record, 'id') || _.get(record, 'index');

                if (_.findIndex(categories, (category) => category.index === recordIndex) > -1) {
                    if (selected) {
                        checkedList = data
                            .filter((item) => {
                                return item.category === recordIndex
                            })
                            .map(item => item.id);

                        checkedList.push(recordIndex);
                    } else {
                        checkedList = [];
                    }
                } else {
                    if (selected) {
                        checkedList.push(recordIndex);
                    } else {
                        checkedList = checkedList.filter((id: string) => {
                            return id !== recordIndex
                        });
                    }
                }

                if (changeSelectedRowIds) changeSelectedRowIds(checkedList);
                else this.setState({
                    selectedRowIds: checkedList
                })
            }
        };

        switch (fileTableView) {
            case 'tile' : {
                return <TileFilelist
                    dataSource={data}
                    groupKey={groupKey}
                    groups={groups}
                    onCategoryClick={onCategoryClick}
                    onMenuClick={onMenuClick}
                    onPrivateChange={onPrivateChange}
                    rowSelection={rowSelection}
                    selectedRowIds={selectedRowIds}
                    customActions={customActions}
                />
            }
            default: {
                return <Table
                    {...this.props}
                    rowSelection={rowSelection}
                    rowKey={rowKey ? rowKey : 'id'}
                    rowClassName={(record, index) => {
                        let tmp = null;
                        if (_.isFunction(rowClassName)) {
                            tmp = rowClassName(record, index);
                        } else if (_.isString(rowClassName)) {
                            tmp = rowClassName;
                        }
                        if (record.__type === 'category') {
                            if (tmp) {
                                tmp += " ";
                            }
                            tmp += "category-row";
                        }
                        return tmp;
                    }}
                    columns={cols}
                    dataSource={data}
                />
            }
        }
    }
}

export default compose(
    withRouter,
    withTranslation(),
)(GroupedTable);
