// @flow
import React, {Component} from "react"
import _ from 'lodash';
import {graphql} from '@apollo/client/react/hoc';
import {Dropdown, Menu, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import {compose, withProps} from "recompose";
import ComponentBrowserHeader from "./ComponentBrowserHeader";
import ProfileQuery from "./../../graphql/queries/profileComponent.graphql";
import componentListQuery from './../../graphql/queries/componentList-ComponentBrowser.graphql';
import componentListPathQuery from './../../graphql/queries/componentList-ComponentBrowserPath.graphql';
import ComponentBrowserListItem from "../ComponentList/ComponentBrowserListItem";
import ComponentBrowserListItemSmall from "../ComponentList/ComponentBrowserListItemSmall";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import styled from "styled-components";
import QueryListAdvanced from "../QueryListAdvanced";
import {withTranslation} from "react-i18next";
import ComponentClientListFilter from "../ComponentClientListFilter";
import withProfile from "../../hoc/withProfile";
import ProfileDefaultServiceProviderQuery from "../../graphql/queries/profileDefaultServiceProvider.graphql";


const ComponentBrowserHeaderWrapper = styled.div`
  height: 40px;
  border: 1px solid #e8e8e8;
  padding: 4px 5px;
`;

type Props = {
    onSelectChange?: Function,
    showOnlyComponent?: boolean,
    selectedComponentId?: string,
    hideBackButton?: boolean,
    hidePathButton?: boolean,
    onStockSearch: Function,
    onSetClientListViewActive?: Function,
    stockSearchEnabled?: boolean,
    hideTools: boolean,
    size?: string,
    finderId: string,
    componentId: string,
    clientListView: boolean,
    getComponent: {
        component: {
            id: number,
            name: string,
            path: Array<any>,
        },
        loading: boolean
    },
    getComponentList: {
        componentList: Array<any>,
        loading: boolean
    },
    onPathItemClick: Function,
    onPathIconClick: Function,
    onAccessoryClick: Function,
    onClick: Function,
    highlightComponentId?: String
}


class ComponentBrowser extends Component<Props, State> {

    static defaultProps = {
        size: 'large',
        hideBackButton: false,
        hideTools: false,
        hidePathButton: false,
        highlightComponentId: null,
        defaultSearchOption: "current"
    };

    state = {
        selectedKey: null,
        serviceProviderFilterId: this.props.defaultServiceProviderId,
        searchText: null
    };

    /*
    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        const props = ["getComponent"];
        return props.reduce((acc, cur) => {
            if (acc) return acc;
            return this.props[cur] !== nextProps[cur];
        }, false);
    }
*/


    componentDidUpdate(prevProps) {
        if (prevProps.componentId !== this.props.componentId){
            this.setState({
                searchText: null
            })
        }

        if ((prevProps.finderId !== this.props.finderId) || (prevProps.clientListView !== this.props.clientListView)){
            this.setState({
                searchText: null
            })
        }

        /*
        // Typical usage (don't forget to compare props):
        if (this.props.componentId !== prevProps.componentId && this.containerRef.current) {
            this.containerRef.current.scrollTop = 0;
        }

         */
    }

    renderLargeItem = (item) => {

        return <ComponentBrowserListItem
            key={item.id}
            highlightComponentId={this.props.highlightComponentId && this.props.highlightComponentId === item.id}
            item={item}
            onClick={() => {
                this.handleClick(item)
            }}
            onPathIconClick={() => {
                if (this.props.onPathIconClick) {
                    this.props.onPathIconClick(item)
                }
            }}
            onAccessoryClick={() => {
                if (this.props.onAccessoryClick) {
                    this.props.onAccessoryClick(item)
                }
            }}/>;

    };

    renderSmallItem = (item) => {

        const comp = <ComponentBrowserListItemSmall
            key={item.id}
            highlightComponentId={this.props.highlightComponentId && this.props.highlightComponentId === item.id}
            selected={item.id === this.props.selectedComponentId}
            onSelectChange={this.props.onSelectChange ? (selected) => {
                this.props.onSelectChange(item, selected.target.checked);
            } : null}
            item={item}
            showOnlyComponent={this.props.showOnlyComponent}
            onClick={() => {
                this.handleClick(item)
            }} onAccessoryClick={() => {
            if (this.props.onAccessoryClick) {
                this.props.onAccessoryClick(item)
            }
        }}/>;

        return comp;
    };

    handleClick(item) {
        if (this.props.onClick) {
            this.props.onClick(item);
        }
    }

    _handleMenuSelect = ({key}) => {
        this.setState({
            selectedKey: key
        })
    };

    render() {

        const {t, componentId, stockSearchEnabled, onPathItemClick, size, hideBackButton, hideTools, selectedComponentId, hidePathButton, onStockSearch, finderId, clientListView, onSetClientListViewActive} = this.props;

        const { selectedKey } = this.state;
        const searchOption = selectedKey || this.props.defaultSearchOption;
        const searchOptions = [searchOption];

        const menu = (
            <Menu onClick={this._handleMenuSelect} selectedKeys={searchOptions}>
                <Menu.Item key={"current"}>
                    {searchOption === "current" ? <Icon type={"check"}/> : null}
                    {t("Search only on this level")}
                </Menu.Item>
                <Menu.Item key={"global"}>
                    {searchOption === "global" ? <Icon type={"check"}/> : null}
                    {t("Deep search from this level")}
                </Menu.Item>
            </Menu>
        );


        return <div
            className={'full-height component-browser'}
            data-cy={"componentBrowser"}
            tour-id="infrastructure__componentBrowser"
        >
            {
                clientListView ?
                    <ComponentBrowserHeaderWrapper className={"component-browser-header-wrapper"}>
                        <ComponentClientListFilter
                            serviceProviderId={this.state.serviceProviderFilterId}
                            onChangeServiceProviderId={(id) => this.setState({serviceProviderFilterId: id})}
                        />
                    </ComponentBrowserHeaderWrapper>
                :
                    <ComponentBrowserHeaderWrapper className={"component-browser-header-wrapper"}>
                        <ComponentBrowserHeader
                            hideTools={hideTools}
                            hidePathButton={hidePathButton}
                            selectedComponent={selectedComponentId}
                            onSelectChange={this.props.onSelectChange ? (item, selected) => {
                                this.props.onSelectChange(item, selected.target.checked);
                            } : null}
                            onBackPress={!hideBackButton ? (path) => {
                                if (path.length > 1) {
                                    onPathItemClick(_.chain(path).clone().get("[1]").value());
                                } else {
                                    onSetClientListViewActive()
                                }
                            } : null}
                            stockSearchEnabled={stockSearchEnabled}
                            onStockSearch={onStockSearch ? () => {
                                onStockSearch();
                                onPathItemClick(_.get(this.props, 'profileQuery.profile.client.component.id'))
                            } : undefined}
                            onPathItemClick={onPathItemClick}
                            componentId={componentId}
                        />
                    </ComponentBrowserHeaderWrapper>
            }
            <div
                id='container'
                className="full-size"
                data-cy={'TEST_componentBrowserList'}
            >
                <QueryListAdvanced
                    showSearchBar
                    searchAddonAfter={<Dropdown overlay={menu}>
                        <div>
                            <Icon type={"search"}/>
                            <Icon type="down" />
                        </div>
                    </Dropdown>}
                    itemsKeyPath={'componentList.components'}
                    totalKeyPath={'componentList.total'}
                    startKeyPath={'params.start'}
                    limitKeyPath={'params.limit'}
                    onSearchTextChanged={(searchText) => {
                        this.setState({
                            searchText
                        })
                    }}
                    style={{height: "95%", width: "100%"}}
                    query={searchOption === "global" ? componentListPathQuery : componentListQuery}
                    searchText={this.state.searchText}
                    pagingSize={Math.round(window.innerHeight / 66) + 5}
                    queryProps={{
                        variables: {
                            params: {
                                filter: {
                                    parentId: componentId && !clientListView ? {
                                        operator: "e",
                                        direct: (searchOption === "current" || !this.state.searchText || !this.state.searchText.length) && !(stockSearchEnabled && this.props.finderId === this.props.rootComponentId),
                                        value: componentId
                                    } : undefined,
                                    componentTypeIndex: (clientListView && !(searchOption === "global")) || (clientListView && searchOption === "global" && !this.state.searchText) ? {
                                        value: ['client', 'globalTrashPool']
                                    } : undefined,
                                    filterOptions: {
                                        value: ['searchForDeletedComponents']
                                    },
                                    serviceProviderId: clientListView && this.state.serviceProviderFilterId ? {
                                        value: [this.state.serviceProviderFilterId]
                                    } : undefined
                                },
                                search: {
                                    query: this.state.searchText,
                                    fields: ["name", "serialNo", "catalogNo"],
                                }
                            }
                        }
                    }}
                    renderItem={(item) => {

                        let comp = null;
                        if (size === 'small'){
                            comp = this.renderSmallItem(item);
                        }else {
                            comp = this.renderLargeItem(item);
                        }

                        if (searchOption === "global"){
                            return <Tooltip
                                key={item.id}
                                title={_.get(item,'path',[]).map((item) => item.name).join(" > ")}
                                mouseEnterDelay={0.7}
                            >
                                <div>
                                {comp}
                                </div>
                            </Tooltip>
                        }

                        return comp;
                    }}
                />

            </div>
        </div>;
    }

}


export default compose(
    graphql(ProfileQuery, {
        name: 'profileQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first'
        })
    }),
    waitWhileLoading('profileQuery', 'profile.rootComponent.id', {
        optional: true
    }),
    graphql(ProfileDefaultServiceProviderQuery, {
        name: 'profileDefaultServiceProviderQuery'
    }),
    waitWhileLoading('profileDefaultServiceProviderQuery', 'profile.client.defaultServiceProvider'),
    withProps((props) => {
        const finderId = props.finderId || _.get(props, 'profileQuery.profile.rootComponent.id');
        return {
            finderId,
            rootComponentId: _.get(props, 'profileQuery.profile.rootComponent.id'),
            componentId: finderId === 'stock' ? _.get(props, 'profileQuery.profile.rootComponent.id') : finderId,
            defaultServiceProviderId: _.get(props, 'profileDefaultServiceProviderQuery.profile.client.defaultServiceProvider.id')
        }
    }),
    withTranslation()
)(ComponentBrowser);
