// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {message, Modal} from "antd";
import {Button} from "@dreebit/react-components";
import _ from 'lodash'
import {withTranslation} from "react-i18next";

import CreateServiceTicketMutation from "../../graphql/mutations/createControlServiceTicket.graphql";
import ServiceControlPerformQuery from "../../graphql/queries/ServiceControlPerform.graphql";
import CreateServiceActionMutation from "../../graphql/mutations/createServiceAction.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Sizes from "../../themes/default/sizes";
import {toastError} from "../../utils/toast";
import ServiceTicketInfo from "../ServiceTicketInfo";

type Props = {
    serviceControlId: string,
    serviceControlPerformQuery: any,
    createServiceTicketMutation: Function,
};

type State = {
    loadingIndex: ?string,
    createdServiceTicketId: ?string,
    visible?: boolean,
}

class ServiceControlPerformAction extends Component<Props, State> {

    state = {
        loadingIndex: null,
        createdServiceTicketId: null,
        visible: false
    };

    handlePerformAction = (faults: boolean) => {

        const {createServiceTicketMutation, t, serviceControlPerformQuery, createServiceActionMutation, serviceControlId} = this.props;

        this.setState({
            loadingIndex: faults ? 'notOk' : 'ok'
        }, () => {
            createServiceTicketMutation({
                variables: {
                    componentId: _.get(serviceControlPerformQuery, 'serviceControl.component.id'),
                    serviceControlId,
                    input: {
                        name: _.get(serviceControlPerformQuery, 'serviceControl.name'),
                        faults,
                        clientId: _.get(serviceControlPerformQuery, 'serviceControl.component.client.id'),
                    }
                }
            })
                .then((res) => {

                    const createdServiceTicketId = _.get(res, 'data.createServiceTicketControl.id');

                    this.setState({
                        visible: true,
                        createdServiceTicketId
                    });
                    if (!faults) {
                        return createServiceActionMutation({
                            variables: {
                                serviceActionTypeIndex: 'serviceTicketDone',
                                serviceTicketId: createdServiceTicketId
                            }
                        }).then(() => res);
                    }

                    return res;
                })
                .then((res) => {
                    message.success(t('Service ticket created'), () => {

                    })
                }).catch((err) => {
                    toastError(t('Could not create service ticket'), err)
            }).finally(() => {
                this.setState({
                    loadingIndex: null
                })
            })
        })

    };


    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {

        const {t} = this.props;
        const {loadingIndex} = this.state;

        return <div>
            <Button onClick={() => this.handlePerformAction(false)} loading={loadingIndex === 'ok'}
                    style={{marginBottom: Sizes.grid}} type="default"
                    block>{t('Yes, the inspection was okay.')}</Button>
            <Button onClick={() => this.handlePerformAction(true)} loading={loadingIndex === 'notOk'} type="danger"
                    block>{t('No, the inspection was not okay.')}</Button>

            <Modal
                title={t("Service ticket created")}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <ServiceTicketInfo serviceTicketId={this.state.createdServiceTicketId}/>
            </Modal>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceControlPerformQuery, {
        name: 'serviceControlPerformQuery',
        options: (props: Props) => ({
            variables: {
                serviceControlId: props.serviceControlId,
            }
        }),
    }),
    waitWhileLoading('serviceControlPerformQuery', ['serviceControl.component.id', 'serviceControl.component.client.id']),
    graphql(CreateServiceTicketMutation, {
        name: 'createServiceTicketMutation'
    }),
    graphql(CreateServiceActionMutation, {
        name: 'createServiceActionMutation'
    })
)(ServiceControlPerformAction);
