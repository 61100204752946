// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

type Props = {
    text: string,
};


class TranslatedLabel extends Component<Props, State> {


    render() {
        const {text, t} = this.props;

        return <span>{t(text)}</span>;
    }
}

export default withTranslation()(TranslatedLabel);
