// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {Col, Row} from "antd";
import CatalogAttributeForm from "./../../components/CatalogAttributeForm";
import CatalogAttributeQuery from "./../../graphql/queries/CatalogAttribute.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";
import Sizes from "../../themes/default/sizes";
import SplitPanel from "../SplitPanel";

type Props = {
    deleteCatalogAttributeMutation?: Function,
    onDeleted?: Function,
    CatalogAttributeQuery: query,
    index: string
};

type State = {
    deleting: boolean
}

class CatalogAttributeDashboard extends Component<Props, State> {




    render() {
        const {t, index, CatalogAttributeQuery} = this.props;
        const catalogAttribute = _.get(CatalogAttributeQuery, 'catalogAttribute');

        return <Row gutter={2 * Sizes.grid}>
            <Col lg={12}>
                <Panel title={t("Details")}>
                    <CatalogAttributeForm index={index}/>
                </Panel>
            </Col>
            <Col lg={12} className={"pt-50"}>
                <SplitPanel
                    icon={"box"}
                    title={t('Products')}
                    link={`/catalog/attributes/${catalogAttribute.index}/products`}
                    largeTitle={_.get(catalogAttribute, 'productList.total', 0)}
                />
            </Col>
        </Row>
    }
}

export default compose(
    withTranslation(),
    graphql(CatalogAttributeQuery, {
        name: 'CatalogAttributeQuery',
        options: (props: Props) => ({
            variables: {
                index: _.get(props, 'index', '')
            }
        }),
    }),
    waitWhileLoading('CatalogAttributeQuery', ['catalogAttribute']),
)(CatalogAttributeDashboard);
