// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ProductStatusLabel from "../ProductStatusLabel";
import ManufacturerQuery from "../../graphql/queries/manufacturer.graphql";

type Props = {
    manufacturerId: string,
    manufacturer: any,
    wrapperStyle: ?any,
};


class ManufacturerInfoBox extends Component<Props, State> {
    static defaultProps = {
        wrapperStyle: {padding: '8px 16px'}
    };

    render() {
        const {manufacturer, t, wrapperStyle} = this.props;

        const { status, name} = manufacturer;

        return <div style={wrapperStyle}>
            <div style={{fontSize: 18}}>
                <span>{name}</span>
            </div>
            <div>
                <ProductStatusLabel status={status}/>
            </div>
        </div>;
    }
}

export default compose(
    graphql(ManufacturerQuery, {
        name: 'manufacturerQuery',
        options: (props: Props) => ({
            variables: {
                id: props.manufacturerId
            }
        }),
    }),
    waitWhileLoading('manufacturerQuery'),
    withTranslation(),
    withProps(props => ({
        manufacturer: _.get(props, 'manufacturerQuery.manufacturer')
    }))
)(ManufacturerInfoBox);
