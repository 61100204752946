// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {List, Tag} from 'antd';

import InfiniteList from "../InifityList";
import {Grow, Row} from "../Flex";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ClientUsersQuery from "../../graphql/queries/ClientUsers.graphql";

type Props = {
    listQuery: query,
}

class ClientUserList extends Component<Props> {
    render() {
        const {listQuery} = this.props;

        return (
            <InfiniteList itemHeight={65} renderItem={(item) => {
                return <List.Item style={{width: '100%'}}>
                    <Grow style={{marginLeft: '10px'}}>
                        <List.Item.Meta
                            title={item.name ? item.name : ""}
                            description={item.email ? item.email : ""}
                        />
                    </Grow>
                    {
                        item.roles
                            ? <Row style={{marginRight: '10px'}} className={"flex-center"}>
                                {item.roles.map((el) => {
                                    return <Tag key={el.id}>{el.name}</Tag>
                                })}
                            </Row>
                            : null
                    }
                </List.Item>
            }}
                          query={listQuery} dataKey={'clientUserList'} itemsKey={'users'}/>
        )
    }

}


export default compose(
    graphql(ClientUsersQuery, {
        name: 'listQuery',
        options: (props: Props) => ({
            variables: {
                id: props.clientId
            }
        }),
    }),
    waitWhileLoading('listQuery', ['clientUserList.users']),
)(ClientUserList);
