// @flow
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

import ContentHeader from "../Typo/ContentHeader";
import AttributesInlineForm from "../Form/attributes-inline-form";
import DefaultServiceTaskTypeDefinitionAttributeListQuery
    from "../../graphql/queries/defaultServiceTaskTypeDefinitionAttributeList.graphql";
import CreateDefaultServiceTaskMutation from "../../graphql/mutations/createDefaultServiceTask.graphql";
import ComponentTypeDefaultServiceTaskListQuery
    from "../../graphql/queries/componentTypeDefaultServiceTaskList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";


type Props = {
    index: string,
    t: Function,
    defaultServiceTaskTypeDefinitionAttributeListQuery: query,
    createDefaultServiceTaskMutation: Function,
    history: {
        push: Function,
    },

};


class ComponentTypeCreateDefaultServiceTask extends Component<Props> {

    render() {
        const {index, t, defaultServiceTaskTypeDefinitionAttributeListQuery, createDefaultServiceTaskMutation, history} = this.props;

        const attribtues = _.get(defaultServiceTaskTypeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);

        return <div>
            <ContentHeader title={t('Create a new default service task')}/>
            <div className={'m-20'}>
                <Panel>
                    <AttributesInlineForm
                        attributes={attribtues}
                        t={t}
                        useSubmit={true}
                        onSubmit={() => {
                            history.push(`/catalog/componentTypes/detail/${index}`);
                        }}
                        mutation={createDefaultServiceTaskMutation}
                        translateMutationOptions={({values}) => ({
                            variables: {
                                componentTypeIndex: index,
                                input: values
                            }
                        })}
                    />
                </Panel>
            </div>
        </div>;
    }
}

export default compose(
    withRouter,
    graphql(DefaultServiceTaskTypeDefinitionAttributeListQuery, {
        name: 'defaultServiceTaskTypeDefinitionAttributeListQuery'
    }),
    waitWhileLoading('defaultServiceTaskTypeDefinitionAttributeListQuery', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
    graphql(CreateDefaultServiceTaskMutation, {
        name: "createDefaultServiceTaskMutation",
        options: (props: Props) => ({
            refetchQueries: [{
                query: ComponentTypeDefaultServiceTaskListQuery,
                variables: {
                    "params": {
                        "filter": {
                            "componentTypeIndex": {
                                "value": _.get(props, 'index')
                            }
                        }
                    }
                }
            }]
        }),
    }),
    withTranslation(),
)(ComponentTypeCreateDefaultServiceTask);
