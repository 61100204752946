// @flow
import React, {Component} from 'react';
import RMADecontaminationDeclaration from "../../components/RMADecontaminationDeclaration";
import {Col, Row} from "antd";
import {compose} from "recompose";
import Panel from "../../components/Panel";
import {withTranslation} from "react-i18next";

type Props = {

};

type State = {

}

class DecoRoute extends Component<Props, State> {

  render() {
    const { t } = this.props;

    return <div className={"full-height overflow-scroll"}>
        <div className={"container"}>

            <Row>
                <Col md={{span: 20, offset: 2}}>
                    <Panel containerStyle={{padding: 0}}>
                        <RMADecontaminationDeclaration/>
                    </Panel>

                </Col>

            </Row>
        </div>
    </div>
  }
}

export default compose(
    withTranslation()
)(DecoRoute);
