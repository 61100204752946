// @flow
import React from 'react';
import queryString from "query-string"
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import Login from "../../components/Login";
import VersionLabel from "../../components/VersionLabel";
import './index.less';
import KeycloakProvider from "../../provider/keycloak";
import ShadowPanel from "../../components/ShadowPanel";
import VSMColorBar from "../../components/VSMColorBar";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import NoAccessPanel, {nonAccessRoles} from "../../components/Login/noAccess";

type Props = {
    match:{
        params: {
            email: String
        }
    },
    history: {
        location: any,
    }
}

type State = {
    userRole: any
}

class LoginRoute extends React.Component<Props> {

    state = {
        userRole: null
    }

    render() {
        const search = queryString.parse(_.get(this.props, 'history.location.search'));
        const email = _.get(this.props, 'match.params.email', '');

        const beVariable = window["__BE__"];

        const {i18n} = this.props;
        // Users from the PV website are either directly logged in or redirected to the SSO
        const directKeycloakLogin =  _.get(beVariable, 'ref') === "website";

        return (
            <div className="full-size login-background" style={{display: "flex", flexDirection: "column",}}>
                {/*<NotificationBanner showDetailsButton={false} />*/}

                <div className="full-size"
                     style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <ShadowPanel topBar={<VSMColorBar/>} style={{width: 360}}>
                        <KeycloakProvider
                            directKeycloakLogin={directKeycloakLogin}
                            language={i18n.language}
                            onSetUserRole={(role) => this.setState({userRole: role})}
                        >
                            {
                                nonAccessRoles.includes(this.state.userRole) ?
                                    <NoAccessPanel
                                        role={this.state.userRole}
                                        onLogout={() => this.setState({userRole: null})}
                                    />
                                    :
                                    <Login
                                        redirect={_.get(search, 'redirect')}
                                        email={email}
                                    />
                            }

                        </KeycloakProvider>
                    </ShadowPanel>
                </div>

                <div
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '8px'}}
                    className={"footer text-center"}
                >
                    <VersionLabel/>
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withTranslation()
)(LoginRoute);
