// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import AttributesInlineForm from "../Form/attributes-inline-form";
import ComponentDuplicateInfoQuery from "./../../graphql/queries/componentDuplicateInfo.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {filterDuplicationAttributes} from "../ComponentDuplicationPanel";
import Bold from "../Utilities/Bold";

class ComponentDuplicationInfoPanel extends Component<Props, State> {
    render() {
        const {t, componentDuplicateInfoQuery, onValuesChange} = this.props;

        const attributes = filterDuplicationAttributes(_.get(componentDuplicateInfoQuery, 'component.typeDefinitionAttributeList.typeDefinitionAttributes', []));

        return <div>
            <Bold>{t('Component Duplication Text')}</Bold>
            <AttributesInlineForm
                inline
                mutation={({variables})=>{
                    if (onValuesChange){
                        onValuesChange(variables);
                    }
                }}
                attributes={attributes}
            />
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ComponentDuplicateInfoQuery, {
        name: 'componentDuplicateInfoQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        })
    }),
    waitWhileLoading('componentDuplicateInfoQuery', 'component.path'),
)(ComponentDuplicationInfoPanel);
