// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import UserCreateComponent from "../../components/UserCreateComponent";
import PanelComponent from "../../components/Panel";
import {Col, Row} from "../../components/Flex";
import ContentHeaderWithBreadcrumb from "../../components/ContentHeaderWithBreadcrumb";
import withResources from "../../hoc/withResources";
import _ from "lodash";

type Props = {
    history: {
        push: Function
    },
    t: Function,
}

class UserCreate extends Component<Props> {
    render() {
        const {t, history} = this.props;
        return <Col style={{width: '100%'}}>
            <ContentHeaderWithBreadcrumb
                title={t('New user')}
                path={[{
                    name: t("Users"),
                    id: -1,
                    link: '/admin/users',
                }, {
                    name: t('New user'),
                    id: -1,
                    link: '/admin/users/add',
                }]}
            />
            <Row className={"flex-center"}
                 style={{backgroundColor: '#F9F9F9', height: '100%', width: '100%', marginTop: '20px'}}>
                <PanelComponent
                    children={<UserCreateComponent onSaved={(res) => {
                        const id = _.get(res, 'data.createUser.id')
                        if (id) history.push(`/admin/users/detail/${id}`)
                    }}/>}
                    style={{width: '90%'}}
                />
            </Row>
        </Col>;
    }
}

export default withTranslation()(withResources(["administrateUsers"])(withRouter(UserCreate)));