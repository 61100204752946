// @flow
import React, {Component} from 'react';
import {Badge, Card, Collapse, Switch} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from "lodash"
import './index.css';

import {Grow, Row} from "../Flex"
import ReactMarkdown from "react-markdown";

const Panel = Collapse.Panel;

type Props = {
    moduleList: any,
    onChange: Function,
    activatedList: [{
        key: string,
    }],
};

class ModulePanel extends Component<Props, State> {
    render() {
        const {moduleList, onChange, activatedList} = this.props;

        const moduleFilteredList = moduleList.map((el) => {
            return el.resources.length > 0 ? el : null
        }).filter(n => n);

        return <Collapse accordion className={'full-width'}>
            {
                moduleFilteredList.map((el) => {
                    const activeNumber = el.resources.map((i) => {
                        return _.filter(activatedList, _.matches(i)).length > 0 ? _.filter(activatedList, _.matches(i)).length : null;
                    }).filter(n => n).length;

                    const text = el.resources.map((e) => {
                        return <div key={e.key} style={{paddingBottom: '10px'}}>
                            <Card
                                title={e.name}
                                className={'full-width'}
                                style={{background: '#F9F9F9'}}
                                extra={
                                    _.filter(activatedList, _.matches(e)).length > 0
                                        ? <Switch defaultChecked checkedChildren={<Icon type="check"/>}
                                                  onChange={(value) => {
                                                      onChange(value, e.key)
                                                  }}/>
                                        : <Switch checkedChildren={<Icon type="check"/>} onChange={(value) => {
                                            onChange(value, e.key)
                                        }}/>
                                }
                            >
                                <ReactMarkdown source={e.description}/>
                            </Card>
                        </div>
                    });

                    return <Panel className={'full-width CollapseContent'} header={
                        <Row className={'full-width'}>
                            <Grow>
                                {el.name}
                            </Grow>

                            {
                                activeNumber > 0
                                    ? <Badge count={activeNumber} style={{backgroundColor: '#52c41a'}}/>
                                    : null
                            }
                        </Row>
                    } key={el.key}>
                        {text}
                    </Panel>
                })
            }
        </Collapse>;
    }
}

export default ModulePanel;
