import React, {useState} from "react";
import {Alert, Card, Col, Row, Steps} from 'antd';
import {Button, Icon} from "@dreebit/react-components";
import {useHistory, useLocation, useParams} from "react-router-dom";
import _ from "lodash";
import Panel from "../../components/Panel";
import TokenInput from "../../components/TokenInput";
import {useTranslation} from "react-i18next";
import ComponentTokenPreview from "../../components/ComponentTokenPreview";
import ComponentMoveTokenMutation from "../../graphql/mutations/ComponentMoveToken.graphql"
import {useMutation, useQuery} from "@apollo/client";
import ComponentTokenPreviewQuery from "../../graphql/queries/ComponentTokenPreview.graphql";
import {toastError} from "../../utils/toast";
import parseSearchQueryParams from "../../utils/searchParams";
import ComponentInfoBox from "../../components/ComponentInfoBox";
import ComponentPicker from "../../components/Form/fields/ComponentPicker";
import MutationButton from "../../components/MutationButton";
import ContentHeader from "../../components/Typo/ContentHeader";

const { Step } = Steps;


interface Props {

}


const InfrastructureCreateTokenRoute = (props: Props) => {

    const params = useParams();
    const location = useLocation();
    const searchParams = parseSearchQueryParams(location.search);

    const history = useHistory();
    const {t} = useTranslation();
    const [step, setStep] = useState(0);
    const [typedToken, setTypedToken] = useState(null);
    const [parentId, setParentId] = useState(null);

    const currentToken = typedToken || params.token;
    const currentParentId = parentId || searchParams.parentId;

    const tokenComponentQuery = useQuery(ComponentTokenPreviewQuery, {
        skip: !currentToken || currentToken.length < 25,
        variables: {
            token: currentToken
        }
    });

    const tokenComponent = _.get(tokenComponentQuery,'data.componentMoveToken.component');

    const [tokenMutation] = useMutation(ComponentMoveTokenMutation, {
        variables: {
            token: currentToken,
            parentId: currentParentId
        }
    });

    const prevButton = <Button key={'prev'} onClick={() => setStep(step-1)} type={'link'}>{t("Back")}</Button>;
    const nextButton = <Button key={'next'} onClick={() => setStep(step+1)} type={'link'}>{t("Next")}</Button>;

    let content = null;
    switch (step){
        case 0: {
            content = <Card
                title={t('Enter your token')}
                actions={tokenComponent ? [
                    nextButton,
                ] : null}
            >
                <div className={"mb-20"}>
                    {(_.get(tokenComponentQuery,'error')) ?
                        <Alert showIcon type={'error'} message={t('Token not valid')} description={_.get(tokenComponentQuery,'error.message')}/> :
                        tokenComponent ?
                            <Alert showIcon type={'success'} message={t('Token valid')} description={t('Please proceed and check the preview of your component')} />
                            : <Alert showIcon message={t('Please enter the token the token is a 25 digit code')} />}
                </div>
                <div className={"text-center"}>
                    <TokenInput onChange={(token, valid) => {
                        history.push(`/infrastructure/cmt/${token}`)
                    }} showSuccess={!_.get(tokenComponentQuery,'error') && tokenComponent} showError={!!_.get(tokenComponentQuery,'error')} token={currentToken} />
                </div>
            </Card>;
            break;
        }
        case 1: {
            content = <Card
                actions={[
                    prevButton,
                    nextButton
                ]}
                title={t('Preview of your component')}>
                <ComponentTokenPreview  token={currentToken} />
            </Card>;
            break;
        }

        case 2: {
            content = <Card
                actions={currentParentId ? [
                    prevButton,
                    nextButton
                ] : [prevButton]}
                extra={<div>
                    <ComponentPicker value={currentParentId} titleOnly hideDeleteButton title={t('Change target')} onChange={setParentId} />
                </div>}
                title={t('Target')}>
                { currentParentId ? <ComponentInfoBox componentId={currentParentId} /> : <Alert message={t('Please select a target')}/>}
            </Card>;
            break;
        }

        case 3: {
            content = <Card
                actions={currentParentId ? [
                    prevButton,
                    <MutationButton onError={ (e) => toastError(e)} onDone={(res) => {
                        const id = _.get(res, 'data.moveComponentWithToken.component.id')
                        if (id) history.push(`/infrastructure/${id}`)
                    }} confirm mutation={tokenMutation} buttonProps={{type: 'link'}}>{t("Create")}</MutationButton>
                ] : [prevButton]}
                title={t('Verify')}>

                <Alert
                    type={'info'}
                    showIcon
                    message={t('The following component will be moved to your infrastructure')}
                    description={t('Please make sure all entries are correct the token can only be used once')}
                />

                <Row className={"mt-20"}>
                    <Col xs={11}>
                        <div className={"br-5 border"}>
                            <ComponentInfoBox componentData={tokenComponent}/>
                        </div>
                    </Col>
                    <Col xs={2} className={"text-center pt-30"}>
                        <Icon type="arrow-right" style={{fontSize: 30}} />
                    </Col>
                    <Col xs={11}>
                        <div className={"br-5 border"}>
                        <ComponentInfoBox componentId={currentParentId}/>
                        </div>
                    </Col>
                </Row>

            </Card>;
            break;
        }

    }

    return <div>

        <ContentHeader title={t("Use a component token")} />

        <Panel title={'One time access token'}>
            <div className={"p-10"}>

                <Steps  current={step} onChange={tokenComponent ? setStep : undefined}>
                    <Step title={t("Enter Token")} />
                    <Step title={t("Preview")}/>
                    <Step title={t("Target")}/>
                    <Step title={t("Verify")} />
                </Steps>

                <div className={"mt-10 pv-10"}>
                    {content}
                </div>

            </div>

        </Panel>

    </div>
};

export default InfrastructureCreateTokenRoute;
