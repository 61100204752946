// @flow
import React, {Component} from "react"
import {Divider} from "antd";
import {withTranslation} from "react-i18next";
import ProductSearchBar from "../ProductSerachBar";
import AttributesInlineForm from "../Form/attributes-inline-form";

type Props = {
    onProductSelect?: Function,
    onProductExposed?: Function,
}

type State = {
    createProductForm: boolean,
}

class ComponentCreateProduct extends Component<Props, State> {


    state = {
        createProductForm: false
    };


    render() {

        const {t, onProductSelect, onProductExposed} = this.props;

        const attributes = [
            {
                index: 'name',
                name: t('Product name'),
                type: 'string',
                inputType: 'text',
                inputOptions: {
                    placeholder: t('Name of Product'),
                },
                rules: [{name: "required"}]
            },
            {
                index: 'catalogNo',
                name: t('Catalog No'),
                type: 'string',
                inputType: 'text',
                inputOptions: {
                    placeholder: t('Catalog No'),
                },
                rules: [{name: "required"}]
            },
            {
                index: 'manufacturerId',
                name: t('Manufacturer'),
                type: 'string',
                inputType: 'autocomplete',
                entityType: 'manufacturer',
                inputOptions: {
                    placeholder: t('Name of manufacturer'),
                },
                rules: [{name: "required"}],
                allowNoOption: true
            }
        ];

        return <div>
            <ProductSearchBar onSelect={onProductSelect}/>
            <p className="mv-5 ph-5">{t('Find products by typing name, catalog number or manufacturer')}</p>
            <div className="mt-15">
                {this.state.createProductForm
                    ? <div className="ph-10 pv-15 bg-light-grey">
                        <span>{t('Please insert your product details')}</span>
                        <Divider/>
                        <AttributesInlineForm
                            clearApolloCache
                            attributes={attributes}
                                              useSubmit
                                              translateMutationOptions={({values}) => (values)}
                                              mutation={onProductExposed}/>
                    </div>
                    : <div className="p-10 bg-light-grey">
                        <h4>{t('Product not found')}?</h4>
                        <a onClick={() => this.setState({createProductForm: true})}>{t('Please enter some details')}</a>
                    </div>
                }
            </div>
        </div>
    }

}

export default withTranslation()(ComponentCreateProduct);
