// @flow
import React, {Component} from "react"
import {Checkbox, Tag} from 'antd';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import Colors from "../../themes/default/colors";

type Props = {
    style?: any,
    selected: boolean,
    item: {
        id: number,
        name: string,
        componentType: {
            index: string
        },
        componentList: {
            total: number
        },
        cuContaminated: boolean,
    },
    onAccessoryClick: Function,
    onClick: Function,
    onSelectChange: Function,
    showOnlyComponent?: boolean,
    t: Function,
    highlightComponentId: boolean
}

class ComponentBrowserListItemSmall extends Component<Props> {


    render() {

        const {item, onClick, onSelectChange, selected, showOnlyComponent, t, highlightComponentId} = this.props;

        return <div style={{padding: 4, backgroundColor: highlightComponentId ? Colors.greyLighter : null}} className="bg-white hover-bbg-light-grey" data-cy={'TEST_componentBrowserListItemSmall'}>
            <div className="flex-row cursor-pointer" style={{padding: '8px 0 5px 5px'}}>
                <div className="mr-5" style={{alignSelf: 'center', paddingRight: '5px'}}>
                    <Checkbox data-cy={'TEST_componentBrowserListItemSmallCheckbox'} checked={selected} onChange={onSelectChange}
                              disabled={showOnlyComponent && !_.get(item, 'componentType.product')}/>
                </div>
                <div data-cy={'TEST_componentBrowserListItemSmallDiv'} className="flex-grow-1 cursor-pointer" onClick={onClick}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{fontSize: 15}}>{item.name} </span>
                        {item.serialNo ? <span style={{fontSize: 12}}>{t('Serial')}: {item.serialNo ? item.serialNo : '-'}</span> : <span>{_.get(item, 'componentType.name')}</span> }
                    </div>
                </div>
                {_.get(item, 'childrenCount', 0) > 0 ? <div data-cy={'TEST_componentBrowserListItemSmallChildren'} className="cursor-pointer">
                    <Tag><i className={'mdi mdi-sitemap'}/> {_.get(item, 'childrenCount', 0)}</Tag>
                </div> : null}
            </div>
        </div>
    }

}

export default withTranslation()(ComponentBrowserListItemSmall);
