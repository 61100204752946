// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import _ from 'lodash'
import {Alert, Col, message, Popconfirm, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {withProps} from "recompose";
import ContentHeader from "../Typo/ContentHeader";
import customAttributeDetails from "./../../graphql/queries/customAttributeDetails.graphql";
import deleteCustomAttribute from "./../../graphql/mutations/deleteCustomAttribute.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {CustomAttributeForm} from "../CustomAttributeForm";
import Panel from "../Panel";
import ContentWrapper from "../ContentWrapper";
import Sizes from "../../themes/default/sizes";
import customAttributeList from "../../graphql/queries/customAttributeList.graphql";
import {withRouter} from "react-router-dom";
import Bold from "../Utilities/Bold";
import CopyCode from "../CopyCode";
import withProfile from "../../hoc/withProfile";

type Props = {
    index: string,
    customAttributeDetailsQuery: any,
    deleteCustomAttributeMutation: Function,
    history: {
        push: Function
    }
}

class CustomAttributeDashboard extends Component<Props> {

    handleDelete = () => {
        const {deleteCustomAttributeMutation, index, history} = this.props;

        deleteCustomAttributeMutation({variables: {index: index}}).then((res) => {
            message.success(res.data.deleteCustomAttribute.message);
            history.push(`/admin/attributes`)
        });
    };


    render() {

        const {customAttribute, index, t, profile} = this.props;
        const isOwner = _.get(customAttribute,"owner.id") === _.get(profile,"client.id");
        const accessRemove = _.get(customAttribute,"access.remove", false);
        const deleteButton = accessRemove ? <Popconfirm
            placement="bottomRight" title={t("Are you sure?")}
            onConfirm={this.handleDelete}
            okText={t("Yes")}
            cancelText={t("No")}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
        >
            <Button type="danger" icon="delete">{t('Delete')}</Button>
        </Popconfirm> : null;

        return <div>
            <ContentHeader title={_.get(customAttribute, 'name')} tools={deleteButton} />
            <ContentWrapper>
                <Row gutter={Sizes.grid * 2}>
                    <Col xl={24}>
                        {!isOwner ?  <Alert message={t("This attribute is shared with you by {{OWNER}}", {OWNER:  _.get(customAttribute,"owner.name") })}
                                           description={t("Shared attributes can only be changed by the owner.")}
                                           type="warning" showIcon />
                            : null}
                        <Panel

                        >
                            <Row className={'mb-10'} gutter={16}>
                                <Col className="gutter-row text-right pv-5" span={7}>
                                    <Bold>{t('Key')}:</Bold>
                                </Col>
                                <Col className="gutter-row" span={17}>
                                    <CopyCode code={index}/>
                                </Col>
                            </Row>
                        </Panel>
                    </Col>
                    <Col xl={24}>
                        <Panel
                            title={t('Details')}
                        >
                            <CustomAttributeForm index={index}/>
                        </Panel>

                    </Col>
                </Row>
            </ContentWrapper>
        </div>;
    }

}

export default compose(
    withTranslation(),
    withProfile(),
    withRouter,
    graphql(customAttributeDetails, {
        name: 'customAttributeDetailsQuery',
        options: props => ({
            variables: {
                index: props.index
            }
        }),
    }),
    waitWhileLoading('customAttributeDetailsQuery', 'customAttribute'),
    graphql(deleteCustomAttribute, {
        name: 'deleteCustomAttributeMutation',
        options: props => ({
            refetchQueries: [{
                query: customAttributeList,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    }),
    withProps((props) => ({
        customAttribute: _.get(props, 'customAttributeDetailsQuery.customAttribute'),
    }))
)(CustomAttributeDashboard);
