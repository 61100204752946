// @flow
import React, {Component} from 'react';
import FillQueryTable from "../QueryTable/fill";
import {withTranslation} from "react-i18next";
import ServiceTicketActionTypesListQuery from "../../graphql/queries/serviceActionTypeList.graphql";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';

type Props = {

};

type State = {

}

class ServiceActionTypeTable extends Component<Props, State> {

    render() {
        const { t, query } = this.props;

        const columns = [
            {
                title: t('Code'),
                dataIndex: 'code',
                key: 'code',
                width: 100
            },
            {
                title: t('Name'),
                dataIndex: 'name',
                key: 'name',
            }
        ]

        return <FillQueryTable

            rowKey={"id"}
            columns={columns}
            onRow={(record) => {
                return {
                    onClick: () => {
                        if (this.props.onClick){
                            this.props.onClick(record);
                        }
                    },
                };
            }}
            itemsKeyPath={'serviceActionTypeList.serviceActionTypes'}
            totalKeyPath={'serviceActionTypeList.total'}
            query={query}

        />

    }
}
export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceTicketActionTypesListQuery, {
        name: 'query',
    }),
)(ServiceActionTypeTable);
