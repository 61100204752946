// @flow
import React, {Component} from 'react';
import _ from "lodash";
import ErrorComponent from "../Error";
import {Popover} from "antd";
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";

type Props = {
    placement?: string,
    query: any,
};


class QueryErrorIndicator extends Component<Props, State> {

    static defaultProps = {
        placement: "bottom"
    };

    render() {
        const {query, t, placement} = this.props;

        if (!query || !query.error) {
            return null;
        }

        const error = _.get(query, `error`);
        let content = null;
        if (_.has(error, 'graphQLErrors')) {
            content = <ErrorComponent title={'GraphQL API Error'}
                                      description={_.first(error.graphQLErrors.map(({message, debugMessage}, i) => (
                                          <div><span key={i}>{message}{debugMessage ? `: ${debugMessage}` : ''}</span>
                                          </div>
                                      )))}/>;
        } else if (_.has(error, 'networkError')) {
            content = <ErrorComponent title={'Network Error'} description={error.networkError}/>;
        }

        return <div style={{position: 'relative'}}>
            <Popover
                arrowPointAtCenter
                content={content}
                placement={placement}
                overlayStyle={{width: 300}}
                title={t('Error')}>
                <Button shape={"circle"} type="danger" icon={"alert"}/>
            </Popover>
        </div>;
    }
}

export default withTranslation()(QueryErrorIndicator);
