// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import ContentHeader from "../../components/Typo/ContentHeader";
import NewNews from "../../components/NewsCreate";
import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import withResources from "../../hoc/withResources";
import {compose} from "recompose";

const ContentWrapper = styled.div`
   padding: ${Sizes.grid}px;
`;

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};


class NewNewsRoute extends Component<Props, State> {


    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader title={t('New News')}/>
            <ContentWrapper>
                <Panel>
                    <NewNews onCreate={(res) => {
                        const newsId = _.get(res, 'data.createNews.id');
                        if (newsId) {
                            this.props.history.push(`/admin/news/${newsId}`);
                        }
                    }}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withTranslation(),
    withResources(["newsEditor"])
)(NewNewsRoute)
