// @flow
import React, {Component} from 'react';
import {Col, Descriptions, Menu, Row} from "antd";
import {compose} from "recompose";
import ContentHeader from "../../../components/Typo/ContentHeader";
import ContentWrapper from "../../../components/ContentWrapper";
import {withTranslation} from "react-i18next";
import Panel from "../../../components/Panel";
import NewsSlider from "../../../components/NewsSlider";
import withTheme from "../../../hoc/withTheme";

type Props = {
    theme?: any,
    t?: any
};

type State = {

}

class SystemRoute extends Component<Props, State> {

  render() {
      const {t, theme} = this.props;

      const host = window["__HOST__"];

      const getPrivacyPolicy = () => {
          switch (theme) {
              case 'pv':
                  return t('data_privacy_link_pv');
              case 'vsm':
                  return t('data_privacy_link');
              default:
                  return t('data_privacy_link');
          }
      };

      return <div>
          <ContentHeader title={t('Profile')}/>
          <ContentWrapper>
              <Row gutter={16}>
                  <Col md={12}>

                      <Panel title={t('Latest news')}>
                          <NewsSlider/>
                      </Panel>

                </Col>
                <Col md={12}>
                    <Panel title={'System Info'}>

                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={t("Host")}>{window.location.host}</Descriptions.Item>
                            <Descriptions.Item label={t("API")}>{window.__BE_VERSION__}</Descriptions.Item>
                            <Descriptions.Item label={t("Web")}>{process.env.REACT_APP_VERSION}</Descriptions.Item>
                        </Descriptions>
                    </Panel>

                    <Panel title={'Links'}>

                        <Menu>
                            <Menu.Item>
                                <a target={"_BLANK"} href={"/business-terms"}>
                                    {t("Business Terms")}
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a target={"_BLANK"} href={getPrivacyPolicy()}>
                                    {t("Privacy Policy")}
                                </a>
                            </Menu.Item>
                            {
                                host === "pv" ?
                                    <Menu.Item>
                                        <a target={"_BLANK"} href={t("legal_notice_link_pv")}>
                                            {t("Legal Notice")}
                                        </a>
                                    </Menu.Item>
                                : null
                            }
                        </Menu>
                    </Panel>
                </Col>
            </Row>
        </ContentWrapper>
    </div>;
  }
}

export default compose(
    withTranslation(),
    withTheme()
)(SystemRoute);
