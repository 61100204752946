// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";

import Toolbar from "../Toolbar";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {Button} from "@dreebit/react-components";
import {Link} from "react-router-dom";
import ConfirmActiveAlertButton from "../ConfirmActiveAlertButton";

type Props = {
    t: Function,
    onChange: Function,
    values: any,
    componentId: any,
    activeAlert?: any
};

class ComponentLogToolbar extends Component<Props, State> {
    render() {
        const {t, onChange, values, componentId, activeAlert} = this.props;

        const attributes = [{
            index: 'logLevel',
            type: 'string',
            entityType: 'enum',
            enumType: 'LogLevel',
            inputType: 'select',
            multiple: true,
            hideLabel: true,
            placeholder: t('Level'),
            config: {
                mode: 'default',
            },
        }, {
            index: 'logType',
            type: 'string',
            multiple: true,
            hideLabel: true,
            entityType: 'enum',
            enumType: 'ComponentLogType',
            inputType: 'select',
            placeholder: t('Action'),
            config: {
                mode: 'default',
            },
        }];

        return <Toolbar
            style={{paddingBottom: 10}}
            renderLeft={() => <AttributesInlineForm
                layout={'inline'}
                onValuesChange={(changes) => {
                    onChange(changes)
                }}
                values={values}
                attributes={attributes}
                useSubmit={false}
            />}
            renderRight={() => <div>
                {
                    activeAlert
                        ? <ConfirmActiveAlertButton componentId={componentId}/>
                        : null
                }
            </div>}
        />
    }
}

export default compose(
    withTranslation(),
)(ComponentLogToolbar);
