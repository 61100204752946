// @flow
import React, {Component} from 'react';
import ServiceTicketLabel from './serviceTicketLabel';
import ConversationParticipantList from './participantList';
import {Modal, Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";
import ConversationQuery from '../../graphql/queries/conversation.graphql';
import UpdateConversationMutation from "../../graphql/mutations/updateConversation.graphql";
import DeleteConversationMutation from "../../graphql/mutations/deleteConversation.graphql";
import ConversationListQuery from "../../graphql/queries/conversationList.graphql";
import {compose} from "redux";
import {graphql, withApollo} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {toastError, toastMessage} from "../../utils/toast";
import statics from "../../statics";
import MessageDashboardSettingsAddParticipant from "../MessageDashboardSettingsAddParticipant";

type Props = {
  conversationId: string,
  conversationQuery: any,
  updateConversationMutation: Function,
  deleteConversationMutation: Function,
  t: Function
};

type State = {
  addParticipantModalVisibility: boolean
}

class InfoSettings extends Component<Props, State> {
  state = {
    addParticipantModalVisibility: false
  }

  componentDidMount() {
  }

  archiveConversation = () => {
    const {updateConversationMutation, conversationId, t, client} = this.props;

    client.clearStore().then(() => {
      updateConversationMutation({
        variables: {
          conversationId: conversationId,
          input: {
            archive: true
          }
        }
      })
    }).then((response) => {
      toastMessage(t("Conversation archived"))
    }).catch((err) => {
      toastError(err)
    })
  };

  deleteConversation = () => {
    const { deleteConversationMutation, conversationId, history, client } = this.props;

    client.clearStore()
        .then(() => deleteConversationMutation({
          variables: {
            conversationId: conversationId,
          },
        }))
        .then(toastMessage).then(() => {
      history.push(`/messages`);
    }).catch(toastError);
  };

  changeAddParticipantModalVisibility = (visible?: boolean) => {
    this.setState({
      addParticipantModalVisibility: visible ? visible : !this.state.addParticipantModalVisibility
    })
  };

  addParticipant = () => {

  };

  render() {
    const {conversationId, conversationQuery, t} = this.props;

    const serviceTicket = _.get(conversationQuery, 'conversation.serviceTicket', null);
    const conversationParticipantList = _.get(conversationQuery, 'conversation.conversationParticipantList.conversationParticipants', null);
    const conversationAccess = _.get(conversationQuery, 'conversation.access');
    const archived = _.get(conversationQuery, 'conversation.archive');

    return <div className={'p-5'}>

      {serviceTicket ? <ServiceTicketLabel serviceTicket={serviceTicket}/> : null}
      <ConversationParticipantList conversationId={conversationId} participants={conversationParticipantList}/>
      <div className={'mt-10 flex-col'}>

        <Button
            className={'mt-10'}
            type="default"
            onClick={this.changeAddParticipantModalVisibility}
            icon="plus"
        >
          {t('Add Participants')}
        </Button>

        {
          !archived ? <Button
              className={'mt-10'}
              type="primary"
              onClick={this.archiveConversation}
              icon="book"
          >
            {t('Archive')}
          </Button> : null
        }

        {_.get(conversationAccess, 'remove') ? <Popconfirm title={t('Are you sure to delete this conversation?')} onConfirm={this.deleteConversation}>
          <Button
              className={'mt-10'}
              type="danger"
              disabled={!(_.get(conversationAccess, 'remove'))}
              icon="delete"
          >
            {t('Delete')}
          </Button>
        </Popconfirm> : null}

      </div>

      {
        this.state.addParticipantModalVisibility
            ? <Modal
                title={t("Add Participants")}
                visible={this.state.addParticipantModalVisibility}
                footer={null}
                onCancel={() => this.changeAddParticipantModalVisibility(false)}
            >
              <MessageDashboardSettingsAddParticipant
                  onClose={() => this.changeAddParticipantModalVisibility(false)}
                  conversationId={conversationId}
              />
            </Modal>
            : null
      }
    </div>;
  }
}

export default compose(
  graphql(UpdateConversationMutation, {
    name: 'updateConversationMutation',
    options: (props) => {
      return {
        refetchQueries: ["conversationList", "conversation"]
      }
    }
  }),
  graphql(DeleteConversationMutation, {
    name: 'deleteConversationMutation',
    options: (props) => {
      return {
        refetchQueries: ["conversationList"]
      }
    }
  }),
  graphql(ConversationQuery, {
    name: 'conversationQuery',
    options: (props: Props) => ({
      variables: {
        conversationId: props.conversationId
      }
    }),
  }),
  withRouter,
  waitWhileLoading('conversationQuery', 'conversation'),
  withTranslation(),
    withApollo
)(InfoSettings);
