import React, {Component} from 'react';
import _ from "lodash";
import {compose} from "recompose";
import {Dropdown, Menu, Tag} from "antd";
import SubComponentsNameQuery from "./../../graphql/queries/SubComponentsName.graphql";
import {graphql} from '@apollo/client/react/hoc';
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

type Props = {
    componentId: string
}

class SubComponentsDropdown extends Component<Props, State> {

    render() {
        const {query, t, componentId} = this.props;

        const components = _.get(query, 'componentList.components', []);
        const total = _.get(query, 'componentList.total', "");

        const menu =
            <Menu>
                {
                    components.length ? components.map((component) => (<Menu.Item key={component.id}>
                        <Link to={`/infrastructure/${component.id}`}>{component.name}</Link>

                    </Menu.Item>)) : <Menu.Item key={componentId}>
                        <Link to={`/infrastructure/create/${componentId}/componentType`}>{t("New sub component")}</Link>
                    </Menu.Item>
                }
            </Menu>


        return <Dropdown overlay={menu}>
            <Tag>
                <FontAwesomeIcon style={{fontSize: "0.8em"}} name="sitemap"/> {total}
            </Tag>
        </Dropdown>
    }
}



export default compose(
    graphql(SubComponentsNameQuery, {
        name: 'query',
        options: (props) => {
            return {
                variables: {
                    id: props.componentId
                }
            }
        }
    }),
    withTranslation()
)(SubComponentsDropdown)
;
