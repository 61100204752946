// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {AutoComplete, Input, Spin} from "antd";
import {withTranslation} from "react-i18next";
import {withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {Icon} from "@dreebit/react-components";

type Props = {
    query: any,
    variables: any,
    debounce?: number,
    searchQueryKeyPath: string,
    itemsKeyPath: string,
    onSelect?: (dataItem: any, searchQuery: string) => void,
    mapDataItem?: (dataItem: any) => any,
    translateDataSource?: (dataSource: any) => any
};

type State = {
    lastSelected: string,
    searchQuery: string,
    loading: boolean,
    dataSource: any[],
}

class QueryAutocomplete extends Component<Props, State> {

    static defaultProps = {
        debounce: 1000,
    }

    state = {
        dataSource: null,
        searchQuery: null,
        loading: false
    };

    _lastSelected: string = null;

    componentDidMount(): void {

        this.debounceSearch = _.debounce((searchQuery) => this._search(searchQuery), this.props.debounce);
    }

    _search = async (searchQuery) => {


        this.setLoading(true);

        const queryVariables = {
            ...this.props.variables,
        };

        _.set(queryVariables, this.props.searchQueryKeyPath, this.state.searchQuery);

        this.props.client.query({
            query: this.props.query,
            variables: queryVariables,
            fetchPolicy: 'no-cache',
        }).then(({data}) => {
            this.setState({
                loading: false,
                dataSource: _.get(data, this.props.itemsKeyPath, [])
            });
        }).catch(() => {
            this.setState({
                loading: false
            });
        })

    };

    _handleSearch = async (searchQuery) => {

        if (searchQuery === this._lastSelected) {
            this.setState({
                searchQuery: null,
            });
            this._lastSelected = null;
        } else {
            this.setState({
                searchQuery,
            });

            if (searchQuery.length > 1) {

                this.debounceSearch(searchQuery);

            }
        }

    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    _handleSelect = (value, item) => {

        this._lastSelected = value;
        if (this.props.onSelect) {
            this.props.onSelect(_.get(item,'props.children.props.item', value), this.state.searchQuery);
        }

        this.setState({
            lastSelected: value,
            searchQuery: null
        })


    };

    render() {
        const {className, t, mapDataItem, translateDataSource, defaultDataSource} = this.props;

        const {loading, dataSource, searchQuery} = this.state;


        let options = dataSource || defaultDataSource;

        if (mapDataItem) {
            options = options.map(mapDataItem);
        }

        if (translateDataSource) {
            options = translateDataSource(options);
        }

        return <AutoComplete
            disabled={this.props.disabled}
            autoClearSearchValue
            defaultActiveFirstOption={this.props.defaultActiveFirstOption}
            className={className}
            dropdownClassName={this.props.dropdownClassName}
            dropdownMatchSelectWidth={500}
            dropdownStyle={{width: 380}}
            size="large"
            style={{width: '100%'}}
            options={options}
            placeholder={t('Search')}
            onChange={value => {
                this._handleSearch(value);
            }}
            value={searchQuery}
            onSelect={this._handleSelect}
            optionLabelProp="value"
        >
            <Input size={this.props.size} placeholder={t('Search')} value={null}
                   suffix={loading ? <Spin size={'small'}/> :
                       <Icon type="search" className="certain-category-icon"/>}/>
        </AutoComplete>

    }
}

export default compose(
    withTranslation(),
    withApollo
)(QueryAutocomplete);
