// @flow

import React, {Component} from 'react';
import {Input} from 'antd';
import {Icon} from "@dreebit/react-components";
import "./styles.css"

type Props = {
    onChange?: Function,
    value: ?string,
    inputContainerStyles?: any,
    displayContainerStyles?: any,
    inputStyles?: any,
    enableClick: boolean,
    loading: boolean,
    hideIcon: boolean,
    renderDisplay: ?Function,
    style?: any
}

type State = {
    value: ?string,
    editable: boolean,
}

class Editable extends Component<Props, State> {
    state = {
        value: this.props.value,
        editable: false,
    };
    handleChange = (e) => {
        const value = e.target.value;
        this.setState({value});
    };
    check = () => {
        this.setState({editable: false});
        if (this.props.onChange) {
            this.props.onChange(this.state.value);
        }
    };
    edit = () => {
        this.setState({editable: true});
    };

    render() {
        const {inputContainerStyles, displayContainerStyles, inputStyles, renderDisplay, enableClick, hideIcon, style} = this.props;
        const {value, editable} = this.state;
        return (
            <span style={style} className="editable-cell">
        {
            editable ?
                <span style={inputContainerStyles} className="editable-cell-input-wrapper">
              <Input
                  style={inputStyles}
                  value={value}
                  onChange={this.handleChange}
                  onPressEnter={this.check}
              />
              <Icon
                  type="check"
                  className="editable-cell-icon-check"
                  onClick={this.check}
              />
            </span>
                :
                <span onClick={enableClick ? this.edit : null} style={displayContainerStyles}
                      className={`editable-cell-text-wrapper${enableClick ? ' clickable' : ''}`}>
              {renderDisplay ? renderDisplay(value) : value}
                    {!hideIcon ? <Icon
                        type="edit"
                        className="editable-cell-icon"
                        onClick={this.edit}
                    /> : null}
            </span>
        }
      </span>
        );
    }
}

export default Editable;
