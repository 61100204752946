// @flow
import React, { Component } from 'react';

type Props = {
  title?: String,
  width: number,      /* 1 - 5 */
  height: number      /* 1 - 3 */
};

type State = {

}

class KpiPanel extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { title, width, height } = this.props;

    return <div className={'mt-20 ml-20 p-10 bg-panel'} style={{width: `${width * 20}%`, height: `${height * 33}%`}}>
      <span className={'text-bold'} style={{marginLeft: 'auto', marginRight: 'auto', color: '#808080'}}>{title}</span>
      {this.props.children}
    </div>;
  }
}

export default KpiPanel;
