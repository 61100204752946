// @flow
import React, {Component} from "react"
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {compose, withProps} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import componentFormsQuery from "../../graphql/queries/component-FormAttributes.graphql"
import updateComponentMutation from "../../graphql/mutations/updateComponent.graphql"
import type {Query} from "../../types";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentAttributeTabs from "../../components/ComponentAttributeTabs";

type Props = {
    t: Function,
    getComponentForms: Query,
    updateComponent: Function,
    match: {
        params: {
            id: string,
            index: string
        }
    }
}

class ComponentForms extends Component<Props> {

    onChange = (key) => {

        const {history, match} = this.props;
        const componentId = match.params.id;
        history.push(`/infrastructure/${componentId}/forms/${key}`);

    };

    render() {

        const {attributes, componentId, updateComponent, index, t} = this.props;

        const categories = attributes.reduce((acc, cur) => {
            cur.categories.forEach((category) => {
                if (acc.indexOf(category) === -1) {
                    acc.push(category);
                }
            });
            return acc;
        }, []);


        const filteredAttributes = attributes.filter(item => {
            return item.categories.indexOf(index) > -1
        });

        const blackList = ["imageId"];

        return <div className="white-bg">
            <ComponentAttributeTabs
                filter={(attribute) => {
                    return blackList.indexOf(attribute.index) === -1
                }}
                componentId={componentId}/>
        </div>;
    }

}

export default compose(
    withTranslation(),
    withRouter,
    withProps((props) => ({
        componentId: _.get(props, 'match.params.id'),
        index: _.get(props, 'index')
    })),
    graphql(componentFormsQuery, {
        name: 'getComponentForms',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('getComponentForms', 'component.typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(updateComponentMutation, {
        name: 'updateComponent',
    }),
    withProps((props) => ({
        attributes: _.get(props, 'getComponentForms.component.typeDefinitionAttributeList.typeDefinitionAttributes')
    })),
)(ComponentForms);

