// @flow
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";
import {message, Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";

import DefaultServiceTask from "../../graphql/queries/defaultServiceTask.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributesInlineForm from "../Form/attributes-inline-form";
import DeleteDefaultServiceTask from "../../graphql/mutations/deleteDefaultServiceTask.graphql";
import ComponentTypeProduct from "../../graphql/queries/componentTypeProduct.graphql";
import UpdateDefaultServiceTask from "../../graphql/mutations/updateDefaultServiceTask.graphql";
import Panel from "../Panel";

type Props = {
    defaultServiceTaskId: string,
    productId: string,
    history: {
        push: Function,
    },
    defaultServiceTask: query,
    deleteDefaultServiceTask: Function,
    t: Function,
    updateDefaultServiceTask: Function
};

class ProductDefaultServiceTasksDashboard extends Component<Props, State> {

    handleDelete = () => {
        const {deleteDefaultServiceTask, history, productId, defaultServiceTaskId} = this.props;

        deleteDefaultServiceTask({variables: {defaultServiceTaskId}}).then((res) => {
            message.success(res.data.deleteDefaultServiceTask.message);
            history.push(`/catalog/products/${productId}/defaultServiceTasks`);
        })
    };

    render() {
        const {defaultServiceTask, t, updateDefaultServiceTask, defaultServiceTaskId} = this.props;

        return <Panel
            title={<span >
                {t("Edit")}
            </span>}
            topRight={
            <div className={"mb-10"}>
                <Popconfirm placement="bottomRight" title={t("Are you sure delete this task?")}
                            onConfirm={this.handleDelete} okText={t("Yes")} cancelText={t("No")}
                            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                    <Button type="danger" icon="delete">{t('Delete')}</Button>
                </Popconfirm>
            </div>
        }>
            <div style={{paddingTop: '30px'}}>
                <AttributesInlineForm
                    attributes={_.get(defaultServiceTask, 'defaultServiceTask.typeDefinitionAttributeList.typeDefinitionAttributes', {})}
                    layout={'horizontal'}
                    useSubmit={true}
                    t={t}
                    mutation={updateDefaultServiceTask}
                    translateMutationOptions={({values}) => {
                        return {
                            variables: {
                                defaultServiceTaskId: defaultServiceTaskId,
                                attributes: values
                            }
                        }
                    }}
                />
            </div>
        </Panel>
    }
}

export default compose(
    withRouter,
    graphql(DefaultServiceTask, {
        name: 'defaultServiceTask',
        options: props => ({
            variables: {
                id: _.get(props, 'defaultServiceTaskId')
            }
        }),
    }),
    waitWhileLoading('defaultServiceTask', ["defaultServiceTask.id"]),
    graphql(DeleteDefaultServiceTask, {
        name: 'deleteDefaultServiceTask',
        options: props => ({
            refetchQueries: [{
                query: ComponentTypeProduct,
                variables: {
                    id: _.get(props.match, 'params.id')
                }
            }]
        })
    }),
    graphql(UpdateDefaultServiceTask, {
        name: 'updateDefaultServiceTask',
        options: props => ({
            refetchQueries: [{
                query: ComponentTypeProduct,
                variables: {
                    id: _.get(props.match, 'params.id')
                }
            }]
        })
    }),
    withTranslation(),
)(ProductDefaultServiceTasksDashboard);
