// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash';
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import NewsUpdateMutation from "./../../graphql/mutations/updateNews.graphql";
import NewsFormQuery from "../../graphql/queries/NewsForm.graphql";
import NewsListQuery from "../../graphql/queries/NewsList.graphql";
import NewsDashboardQuery from "../../graphql/queries/NewsDashboard.graphql";

type Props = {
    newsId: string,

};


class NewsUpdateForm extends Component<Props, State> {
    componentDidMount() {
    }

    render() {
        const {newsUpdateMutation, attributes, newsId} = this.props;

        return <AttributesInlineForm
            attributes={attributes}
            mutation={newsUpdateMutation}
            translateMutationOptions={({values}) => ({
                variables: {
                    id: newsId,
                    input: values
                }
            })}
            useSubmit
        />;
    }
}


export default compose(
    graphql(NewsUpdateMutation, {
        name: 'newsUpdateMutation',
        options: props => ({
            refetchQueries: [{
                query: NewsListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }, {
                query: NewsDashboardQuery,
                variables: {
                    id: props.newsId
                }
            }]
        })
    }),
    graphql(NewsFormQuery, {
        name: 'newsFormQuery',
        options: (props: Props) => ({
            variables: {
                id: props.newsId
            }
        }),
    }),
    waitWhileLoading('newsFormQuery', 'news'),
    withProps((props) => ({
        attributes: _.get(props, 'newsFormQuery.news.typeDefinitionAttributeList.typeDefinitionAttributes')
    }))
)(NewsUpdateForm);
