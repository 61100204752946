// @flow
import React, {Component} from "react"

import FontAwesomeIcon from "../Icons/FontAwesomeIcon";

type Props = {
    componentTypeIndex: string,
    size: number,
    color: string
}

class ComponentTypeIcon extends Component<Props> {

    render() {

        let icon = {
            name: 'cube',
            type: 'fontawesome'
        };

        switch (this.props.componentTypeIndex) {
            case 'client':
                icon.name = 'building';
                break;

            case 'serviceProvider':
                icon.name = 'wrench';
                break;

            case 'site':
                icon.name = 'industry';
                break;

            case 'department':
                icon.name = 'cubes';
                break;

            case 'machine':
                icon.name = 'cogs';
                break;

            case 'stock':
                return <i className="mdi mdi-forklift"/>;


            case 'shelf':
                return <i className="mdi mdi-locker"/>;


            case 'box':
                return <i className="mdi mdi-cube"/>;

            case 'pool':
                return <i className="mdi mdi-google-circles-group"/>;


            default:

                break;
        }

        if (icon.type === 'fontawesome') {
            return <FontAwesomeIcon name={icon.name}/>
        }
        return null;

    }

}

export default ComponentTypeIcon;
