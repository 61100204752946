// @flow
import React, {Component} from 'react';
import {Col, Grow, Row} from "../Flex";
import Colors from "../../themes/default/colors";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import "./styles.css";
import {Link} from "react-router-dom";


type Props = {
    link?: string,
    showLinkIndicator?: boolean,
    icon?: string,
    leftComponent?: any,
    leftClass?: string,
    largeTitle?: string,
    title?: string,
    description?: any,
    textOnly?: string,
    leftColor?: string
};

export type SplitPanelModel = Props;

class SplitPanel extends Component<Props, State> {

    static defaultProps = {
        leftColor: Colors.greyDark,
        showLinkIndicator: false
    };

    renderLeftContent() {
        return this.props.leftComponent || <FontAwesomeIcon name={this.props.icon} circled={false} size={'2x'}/>;
    }

    renderRightContent() {

        const {largeTitle, title, description, textOnly, className} = this.props;


        return (textOnly ? <span className={[className, 'text-only']}>{textOnly}</span> :
                largeTitle ? <div>
                        <Row className={'flex-align-items-center'}>
              <span className={'large-title'}>
                {largeTitle}
              </span>
                            <span className={'title'}>
                {title}
              </span>
                        </Row>
                        <Row className={'description'}>{description}</Row>
                    </div>
                    :
                    <div>
                        <Row className={'flex-align-items-end'}>
              <span className={'title'}>
                {title}
              </span>
                        </Row>
                        <Row className={'description'}>{description}</Row>
                    </div>

        );
    }

    render() {
        const {link, leftColor, showLinkIndicator, className, leftClass} = this.props;

        const panel = <Row className={'split-panel ' + className}>
            <Col
                className={'left-content-wrapper flex-align-items-center flex-center ' + leftClass}
                style={{backgroundColor: leftClass && leftClass.includes('-bg') ? null : leftColor}}
            >
                <div>{this.renderLeftContent()}</div>
            </Col>
            <Grow className={'right-content-wrapper flex flex-align-items-center'}>{showLinkIndicator ?
                <Row className={"flex-grow-1"}>
                    <Grow className={'mr-10'}>{this.renderRightContent()}</Grow>
                    <Col className={'mr-10'} style={{justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon name={"angle-right"} circled={false} size={'2x'}/>
                    </Col>
                </Row>
                :
                this.renderRightContent()}
            </Grow>
        </Row>;

        return (link ?
                <Link to={link}>
                    {panel}
                </Link>
                : panel
        );
    }
}

export default SplitPanel;
