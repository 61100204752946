// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import _ from "lodash";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';


import {Dropdown, Menu, Tag} from "antd";
import {Button} from "@dreebit/react-components";
import RouterBreadcrumbs from "../../routes/RouterBreadcrumbs";
import ServiceTicketDashboardHeaderQuery from "./../../graphql/queries/serviceTicketHeader.graphql";
import updateServiceTicket from "./../../graphql/mutations/updateServiceTicket.graphql";
import ServiceTicketStarredButton from "../ServiceTicketStarredButton";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import ServiceTicketStateBadge from "../ServiceTicketStateBadge";


import "./header.css";
import {Link, withRouter} from "react-router-dom";
import queryWrapper from "../QueryWrapper";
import ServiceTicketSubscriptionButton from "../ServiceTicketSubscriptionButton";
import ServiceTicketComponentInQuarantineStoreBadge from "../ServiceTicketComponentInQuarantineStoreBadge";
import ServiceTicketShareButton from "../ServiceTicketShareButton";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import CULabel from "../CULabel";

type Props = {
	match: any,
	breadcrumbs?: any,
	serviceTicketQuery: any,
	updateServiceTicketMutation: Function,
};


class ServiceTicketDashboardHeader extends Component<Props, State> {


	render() {
		const {serviceTicketQuery, t, breadcrumbs} = this.props;

		const serviceTicket = serviceTicketQuery.serviceTicket;
		const read = _.get(serviceTicket, 'access.read', false);
		const write = _.get(serviceTicket, 'access.write', false);
		const remove = _.get(serviceTicket, 'access.remove', false);
		const component = _.get(serviceTicket, 'component');
		const externalServiceProvider = _.get(serviceTicket, 'externalServiceProvider');
		const componentReadAccess = _.get(serviceTicket, 'component.access.read', false)

		const menuItems = [];

		if (read) {
			menuItems.push(<Menu.Item key={'actions'}>
				<Link to={`/service/${serviceTicket.id}/actions`}>{t('Actions')}</Link>
			</Menu.Item>);
			menuItems.push(<Menu.Item key={'history'}>
				<Link to={`/service/${serviceTicket.id}/logs`}>{t('History')}</Link>
			</Menu.Item>);
		}

		if (write) {
			menuItems.push(<Menu.Item key={'edit'}>
				<Link to={`/service/${serviceTicket.id}/attributes`}>{t('Edit')}</Link>
			</Menu.Item>);
		}

		if (remove){
			menuItems.push(<Menu.Item key={'remove'}>
				<Link
					to={`/service/${serviceTicket.id}/remove${component && component.id ? `?componentId=${component.id}` : ''}`}>{t('Remove')}</Link>
			</Menu.Item>);
		}

		const menu = (
			<Menu>
				{menuItems}
			</Menu>
		);

		const pathButtons = [];
		const lastPathItem = _.last(breadcrumbs);
		if (lastPathItem) {

			if (serviceTicket.print && serviceTicket.print.sheet && serviceTicket.print.sheet.link) {
				pathButtons.push(<a key={'ticket-print'} target={"_BLANK"}
									href={serviceTicket.print.sheet.link}><Button size={'large'} shape="circle"
																				  icon="printer"/></a>)
			}
		}

		return <div className={'ServiceTicketDashboardHeader'}>
			<div className={'top-row'}>
				<div className={'title'}>
					<ServiceTicketTypeIcon style={{marginRight: 8}} index={serviceTicket.serviceTicketType.index}/>
					<Link className={"text-dark"}
						  to={`/service/${serviceTicket.id}`}>{serviceTicket.key}: {serviceTicket.name}</Link>
					{/*<Editable
            style={{flexGrow: 1}}
            enableClick
            hideIcon
            value={serviceTicket.name} />*/}
				</div>
				<div className={'tools'} style={{display: 'flex', flexDirection: 'row'}}>
					{pathButtons.map(item => (item))}
					<ServiceTicketShareButton serviceTicketId={serviceTicket.id} buttonProps={{
						size: 'large',
						shape: 'circle',
					}}/>
					<ServiceTicketStarredButton serviceTicketId={serviceTicket.id} buttonProps={{
						size: 'large',
						shape: 'circle',
					}}/>
					<ServiceTicketSubscriptionButton serviceTicketId={serviceTicket.id}/>
					{menuItems.length ? <Dropdown overlay={menu}>
						<Button data-cy={'TEST_10002'} size={'large'} shape="circle" icon="bars"/>
					</Dropdown> : null}
				</div>
			</div>


			<div className={'bottom-row'}>
				<RouterBreadcrumbs style={{display: 'inline-block'}}/>

				<ServiceTicketComponentInQuarantineStoreBadge serviceTicketId={serviceTicket.id} style={{float: 'right'}} />
				{
					componentReadAccess ?
						<CULabel componentId={_.get(serviceTicket, 'component.id')} style={{float: 'right'}} />
					: null
				}
				<ServiceTicketStateBadge style={{float: 'right'}} state={serviceTicket.serviceTicketState}/>
				{
					externalServiceProvider ?
						<Tag color={'geekblue'} style={{float: 'right'}} >
							<span>{t('External Service')}</span>: {_.get(externalServiceProvider,'name')}
						</Tag>
					: null
				}
				{/*<span>{t('Last updated at {{date}} by {{user}}',{date: moment(serviceTicket.update).format('LLL'), user: serviceTicket.updatedBy.name})}</span>*/}
			</div>

		</div>;
	}
}

const routes = [
	{path: '/service/:id', breadcrumb: 'ticket'},
	{path: '/service/:id/contaminationDeclaration', breadcrumb: 'contaminationDeclaration'},
];

export default compose(
	graphql(ServiceTicketDashboardHeaderQuery, {
		name: 'serviceTicketQuery',
		options: (props) => ({
			variables: {
				id: props.serviceTicketId
			}
		})
	}),
	waitWhileLoading('serviceTicketQuery', ['serviceTicket']),
	graphql(updateServiceTicket, {
		name: 'updateServiceTicketMutation',
	}),
)(withTranslation()(
	queryWrapper(
		withBreadcrumbs(routes)(withRouter(withTranslation()(ServiceTicketDashboardHeader))),
		(props: Props) => ({
			queryConfigs: [{
				query: props.serviceTicketQuery,
				checkProperties: ['serviceTicket'],
			}]
		}))
));

