// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import CatalogAttributeQuery from "./../../graphql/queries/CatalogAttribute.graphql";
import UpdateCatalogAttributeMutation from "./../../graphql/queries/UpdateCatalogAttributeMutation.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributesInlineForm from "../Form/attributes-inline-form";

type Props = {
    index: string,
    catalogAttributeQuery: query,
    updateCatalogAttributeMutation: Function,
    t: Function
};



class CatalogAttributeForm extends Component<Props, State> {

    render() {
        const {index, catalogAttributeQuery, updateCatalogAttributeMutation, t} = this.props;

        const blackList = ["products"]

        return <AttributesInlineForm
            attributes={_.get(catalogAttributeQuery, 'catalogAttribute.typeDefinitionAttributeList.typeDefinitionAttributes', []).filter((item) => blackList.indexOf(item.index) === -1)}
            t={t}
            useSubmit={true}
            mutation={updateCatalogAttributeMutation}
            translateMutationOptions={({values, changes}) => ({
                variables: {
                    catalogAttributeIndex: index,
                    input: changes
                }
            })}
        />/*<TDAAttributesForm
            mutation={updateCatalogAttributeMutation}
            attributes={_.get(catalogAttributeQuery, 'catalogAttribute.typeDefinitionAttributeList.typeDefinitionAttributes', [])}
            submitTitle={t('Save')}
            translateMutationOptions={(values) => ({
                index: index,
                input: values
            })}
        />*/
    }
}

export default compose(
    withTranslation(),
    graphql(CatalogAttributeQuery, {
        name: 'catalogAttributeQuery',
        options: (props: Props) => ({
            variables: {
                index: props.index
            }
        }),
    }),
    waitWhileLoading('catalogAttributeQuery', ['catalogAttribute']),
    graphql(UpdateCatalogAttributeMutation, {
        name: 'updateCatalogAttributeMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: CatalogAttributeQuery,
                variables: {
                    index: props.index
                }
            }]
        }),
    })
)(CatalogAttributeForm);
