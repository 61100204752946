// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import FileUploadList from "../FileUploadList";
import ProductFilesQuery from "./../../graphql/queries/productFiles.graphql";
import AddProductFilesMutation from "./../../graphql/mutations/addProductFiles.graphql";
import DeleteProductFileMutation from "./../../graphql/mutations/deleteProductFile.graphql";
import UpdateProductFileMutation from "./../../graphql/mutations/updateProductFile.graphql";

import type {FileCreateInput} from "../../types";
import ProductDashboardQuery from "../../graphql/queries/productDashboard.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    addProductFilesMutation: Function,
    deleteProductFileMutation: Function,
    updateProductFileMutation: Function,
    productFilesQuery: any,
    productId: string,
};


class ProductDashboardFilesWidget extends Component<Props, State> {


    render() {
        const {
            addProductFilesMutation,
            t,
            deleteProductFileMutation,
            updateProductFileMutation,
            productFilesQuery,
            productId
        } = this.props;

        return <div>
            <FileUploadList
                categories={[{
                    index: 'default',
                    title: t('Uploads')
                }, {
                    index: 'empolis',
                    title: t('Manufacturer Files')
                }]}
                addFileMutation={addProductFilesMutation}
                addFileMutationOptions={(fileInputs: FileCreateInput[]) => ({
                    variables: {
                        productId,
                        input: fileInputs
                    }
                })}
                editFileMutation={updateProductFileMutation}
                editFileMutationOptions={(file, input) => ({
                    variables: {
                        productFileId: file.id,
                        input
                    }
                })}
                removeFileMutation={deleteProductFileMutation}
                removeFileMutationOptions={(file) => ({
                    variables: {
                        productFileId: file.id
                    }
                })}
                fileListQuery={productFilesQuery}
                fileListKey={['product.productFileList.productFiles', 'product.empolisFileList.productFiles']}
            />
        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(ProductFilesQuery, {
        name: 'productFilesQuery',
        options: (props: Props) => ({
            variables: {
                id: props.productId
            }
        })
    }),
    waitWhileLoading('productFilesQuery'),
    graphql(AddProductFilesMutation, {
        name: 'addProductFilesMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ProductDashboardQuery,
                    variables: {
                        id: props.productId
                    }
                }, {
                    query: ProductFilesQuery,
                    variables: {
                        id: props.productId
                    }
                }
            ]
        })
    }),
    graphql(UpdateProductFileMutation, {
        name: 'updateProductFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ProductDashboardQuery,
                    variables: {
                        id: props.productId
                    }
                },{
                    query: ProductFilesQuery,
                    variables: {
                        id: props.productId
                    }
                }
            ]
        })
    }),
    graphql(DeleteProductFileMutation, {
        name: 'deleteProductFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ProductDashboardQuery,
                    variables: {
                        id: props.productId
                    }
                }, {
                    query: ProductFilesQuery,
                    variables: {
                        id: props.productId
                    }
                }
            ]
        })
    })
)(ProductDashboardFilesWidget);
