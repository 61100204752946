// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketInfo.graphql";
import ServiceTicketWorkFlowListItem from "../ServiceTicketWorkflowBoard/listItem";
import {Link} from "react-router-dom";


type Props = {
    serviceTicketId: string,
};

class ServiceTicketInfo extends Component<Props, State> {

  render() {
      const {item} = this.props;

      if (!item) return null;

      return <Link className={'text-dark dark-link'} to={`/service/${item.id}`}>
          <ServiceTicketWorkFlowListItem item={item}/>
      </Link>;
  }
}


export default compose(
    withTranslation(),
    graphql(serviceTicketDashboard, {
        name: 'serviceTicketDashboardQuery',
        options: (props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    withProps((props) => ({
        item: _.get(props,'serviceTicketDashboardQuery.serviceTicket')
    }))
)(ServiceTicketInfo);
