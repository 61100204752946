//@flow
const  axios = require("axios")
const  _ = require('lodash')

const login = (email, password, options) => {

    return new Promise((resolve, reject) => {

        const url = _.get(options, 'host', '') + '/auth/token';
        axios.post(url, {
            grant_type: 'password',
            client_id: 'web',
            username: email,
            password: password
        }).then((res) => {
            if (res.status === 200) {
                const data = res.data;
                if (data.error) {
                    return reject(new Error(data['error_description'] || data.error));
                }
                if (data.access_token) {
                    /*
                    Beispiel Response
                    {
                      access_token:"126cb07ca83713a90984ec57d691be3b7dfc65dd",
                      expires_in:28800,
                      scope:null,
                      token_type:"Bearer",
                    }
                     */
                    resolve({
                        message: 'Login erfolgreich',
                        token: data.access_token
                    })
                } else {
                    reject(Error('Kein Token erhalten.'));
                    console.log(data);
                }
            } else {
                reject(new Error('Server Error'));
            }
        }).catch((err) => {
            reject(err);
        })

    })

};

module.exports = login;
