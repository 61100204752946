// @flow
import React, {Component} from 'react';
import {Menu} from "antd";
import {Waypoint} from "react-waypoint";
import styled from 'styled-components';

import {Col, Grow, Row} from "../Flex";
import Colors from "../../themes/default/colors";
import Sizes from "../../themes/default/sizes";

type Props = {
    hideIcons: boolean,
    items: {
        index: string,
        icon: any,
        title: string,
        body: any,
    }[]
};

type State = {
    activeWayPoints: {
        [string]: boolean,
    }
}

const Title = styled.div`
  font-size: 1.3em;
  border-bottom: 1px solid ${Colors.greyDark};
  margin-bottom: ${2 * Sizes.grid}px;
`;

const Wrapper = styled.div`
  height: 100%;
`;

/**
 overflow: scroll;
 height: 100%;
 position: absolute;
 */
const ScrollContainer = styled.div`
  
`;

const BodyWrapper = styled.div`
  padding: ${Sizes.grid}px;
`;

const Item = styled.div`
  padding-bottom: ${2 * Sizes.grid}px;
`;

class IconSidebarPanel extends Component<Props, State> {

    state = {
        activeWayPoints: {}
    };

    handleWaypoint = (index, active) => {

        const activeWayPoints = {
            ...this.state.activeWayPoints,
        };
        activeWayPoints[index] = active;

        this.setState({
            activeWayPoints
        })
    };

    renderContent() {

        const {items} = this.props;

        return <BodyWrapper>
            {items.map(item => <Item key={item.index}>
                <Waypoint
                    onEnter={() => this.handleWaypoint(item.index, true)}
                    onLeave={() => this.handleWaypoint(item.index, false)}
                />
                {item.title ? <Title>{item.title}</Title> : null}
                {item.body}
            </Item>)}
        </BodyWrapper>
    }

    render() {
        const {items, hideIcons} = this.props;

        if (hideIcons) {
            return this.renderContent();
        }

        const selectedKeys = Object.keys(this.state.activeWayPoints).filter(key => !!this.state.activeWayPoints[key]);

        return <Wrapper>
            <Row>
                <Col>
                    <Menu selectedKeys={selectedKeys}>
                        {items.map(item => <Menu.Item key={item.index}>{item.icon}</Menu.Item>)}
                    </Menu>
                </Col>
                <Grow>
                    <ScrollContainer>
                        {this.renderContent()}
                    </ScrollContainer>
                </Grow>
            </Row>
        </Wrapper>;
    }
}

export default IconSidebarPanel;
