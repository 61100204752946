// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash';

import NotificationTypeList from "../../graphql/queries/notificationTypeList.graphql";
import CreateNotificationSetting from "../../graphql/mutations/createNotificationSetting.graphql";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import NotificationSettingList from "../../graphql/queries/notificationSettingList.graphql";

type Props = {
    t: Function;
    createNotificationSettingMutation: Function,
    notificationTypeListQuery: query,
    onSaved: Function,
}

class NotificationSettingsCreateForm extends Component<Props> {
    render() {
        const {t, notificationTypeListQuery, createNotificationSettingMutation, onSaved} = this.props;

        const optionValues = _.get(notificationTypeListQuery, 'notificationTypeList.notificationTypes', []).map(item => ({
            value: item.index,
            name: item.name
        }));

        const values = {type: _.chain(optionValues).first().get("value").value()};

        return <AttributesInlineForm
            attributes={[
                {
                    index: 'type',
                    name: t('Typ'),
                    type: 'string',
                    inputType: 'select',
                    optionValues: optionValues,
                }
            ]}
            layout={'horizontal'}
            useSubmit
            mutation={createNotificationSettingMutation}
            translateMutationOptions={({values}) => {
                return {
                    variables: values
                }
            }}
            values={values}
            submitChangesOnly={false}
            onSubmit={(res) => {
                onSaved(res)
            }}

        />
    }
}

export default compose(
    withRouter,
    graphql(NotificationTypeList, {
        name: 'notificationTypeListQuery',
    }),
    waitWhileLoading('notificationTypeListQuery', 'notificationTypeList.notificationTypes'),
    graphql(CreateNotificationSetting, {
        name: 'createNotificationSettingMutation',
        options: () => ({
            refetchQueries: [{
                query: NotificationSettingList
            }]
        })
    }),
    withTranslation()
)(withRouter(NotificationSettingsCreateForm));
