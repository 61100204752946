// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";

import BookmarkList from "../../graphql/queries/bookmarkList.graphql";
import QueryTable from "../QueryTable";

type Props = {
    values: any,
    t: Function,
    bookmarkListQuery: query,
    onChange: Function,
}

class Index extends Component<Props, State> {
    render() {
        const {t, bookmarkListQuery, onChange} = this.props;

        return <div>
            <QueryTable
                rowSelection={{onChange}}
                size={'small'}
                query={bookmarkListQuery}
                itemsKeyPath={'bookmarkList.bookmarks'}
                totalKeyPath={'bookmarkList.total'}
                columns={[
                    {
                        dataIndex: 'title',
                        key: 'title',
                        title: t('Title')
                    }
                ]}
            />
        </div>
    }
}

export default compose(
    graphql(BookmarkList, {
        name: 'bookmarkListQuery',
        options: (props) => ({
            variables: {
                params: {
                    filter: {
                        type: {
                            operator: 'in',
                            value: _.get(props.values, 'type', {})
                        }
                    },
                    search: {
                        query: _.get(props.values, 'query', ''),
                        fields: ["title"]
                    }
                }
            }
        }),
    }),
    waitWhileLoading('bookmarkListQuery'),
    withTranslation(),
)(Index);
