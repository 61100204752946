// @flow
import React, {Component} from "react"
import {Dropdown, Menu, Tabs} from "antd";
import {Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import openedComponentList from "./../../graphql/queries/openedComponentList.graphql";
import removeOpenedComponent from "./../../graphql/mutations/removeOpenedComponent.graphql";
import {withRouter} from 'react-router-dom'
import * as _ from "lodash";
import AddOpenedComponentTab from "./AddOpenedComponentTab";
import ComponentTab from "./ComponentTab";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import Colors from "../../themes/default/colors";
import {Col, Grow, Row} from "../Flex";
import ComponentImage from "../ComponentImage";
import ComponentTypeIcon from "../ComponentTypeIcon";
import OneLineLabel from "../OneLineLabel";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import './index.css';

const TabPane = Tabs.TabPane;
const { SubMenu } = Menu;

type Props = {
    activeKey?: string,
    getOpenedComponentList: {
        openedComponentList: any,
        loading: boolean,
        error: any,
    },
    removeOpenedComponent: Function,
    addOpenedComponent: Function,
    history: {
        push: Function
    },
    closedTab: Function,
    onTabChange: (tab: string) => void,
    disabledTabs?: Array
}

const defaultTabKeys = [];

class OpenedComponentsTabs extends Component<Props> {


    constructor(props) {
        super(props);

        this.state = {
            removedTabs: [],
            removedComponents: []
        }

    }

    _handleCloseAll = (blackListIds: string[] = []) => {

        const {removeOpenedComponent, getOpenedComponentList} = this.props;

        const componentIds = _.chain(getOpenedComponentList)
            .get('openedComponentList.components', [])
            .map(item => item.id)
            .filter(id => blackListIds.indexOf(id) === -1)
            .value();

        return removeOpenedComponent({
            variables: {
                componentIds
            }
        }).then(() => {
            getOpenedComponentList.refetch();
        })




    };

    onTabClick(tab) {
        if (this.props.onTabChange) this.props.onTabChange(tab)
    }


    getTabIcon = (key) => {
        switch(key) {
            case 'dashboard':
                return 'speedometer';
            case 'stock':
                return 'forklift';
            case 'search':
                return 'magnify';
        }
    };

    render() {

        const {getOpenedComponentList, activeKey, t, disabledTabs} = this.props;
        const {removedComponents} = this.state;

        let tabs = defaultTabKeys.map((key) => (
            <TabPane key={key} tab={<span style={{height: '40px'}}><MaterialCommunityIcon size={14} name={this.getTabIcon(key)}/></span>}
                     closable={false}/>));

        const components = _.chain(getOpenedComponentList).get('openedComponentList.components', []).clone().reverse().value();

        tabs = [...tabs, ...components
            .filter(item => !!item && this.state.removedTabs.indexOf(item.id) === -1)
            .map(item => {
                return <TabPane tab={<ComponentTab name={item.name} serialNo={item.serialNo}/>}
                                key={String(item.id)}></TabPane>;
            })
        ];

        if (activeKey && tabs.map(item => item.key).indexOf(activeKey) === -1 && disabledTabs.indexOf(activeKey) === -1 && removedComponents.indexOf(activeKey) === -1) {
            tabs = [
                ...tabs,
                <TabPane tab={<AddOpenedComponentTab componentId={activeKey}/>} key={activeKey}/>,
            ]
        }

        const menu = (
            <Menu
                mode="inline"
            >
                <Menu.Item
                    onClick={() => this._handleCloseAll().then(() => this.props.history.push(`/infrastructure/dashboard`))}
                    key="1"
                >
                    {t('Close all')}
                </Menu.Item>
                <Menu.Item onClick={() => this._handleCloseAll([activeKey])}
                           key="2"
                           style={{borderBottomWidth: 1}}
                >
                    {t('Close all expect current')}
                </Menu.Item>
                <SubMenu
                    key="componentMenu"
                    title={t('opened Components')}
                    popupClassName={'componentSubMenu'}
                >
                {
                    _.map(components, (component, index) =>
                        <Menu.Item onClick={() => this.onTabClick(component.id)}
                                   key={index + 3}
                                   style={{backgroundColor: activeKey === component.id ? Colors.greyLighter : null}}
                                   disabled={activeKey === component.id}
                        >
                            <Row style={{width: '100%'}}>
                                <Col style={{alignItems: 'center', justifyContent: 'center'}}>
                                    <ComponentImage componentId={component.id} size={50}/>
                                </Col>
                                <Grow className={"ph-10 pv-5"}>
                                    <div style={{fontSize: 18}}>
                                        <ComponentTypeIcon componentTypeIndex={component.componentType.index}/>
                                        <span style={{paddingLeft: '5px'}}>
                                            {component.name}
                                        </span>
                                    </div>
                                    {
                                        component.product
                                            ? <div>
                                                <OneLineLabel>
                                                    <span>{t('Serial')}: </span>
                                                    <span>{_.get(component, 'serialNo')}</span>
                                                </OneLineLabel>
                                                <OneLineLabel>
                                                    <span>{t('Catalog No')}: </span>
                                                    <span>{_.get(component, 'product.catalogNo')} ({_.get(component, 'product.manufacturer.name')})</span>
                                                </OneLineLabel>
                                            </div>
                                            : <div>
                                                <FontAwesomeIcon name={'sitemap'}/> {_.get(component, 'childrenCount')} {t('Sub Components')}
                                            </div>
                                    }
                                </Grow>
                            </Row>
                        </Menu.Item>
                    )
                }
                </SubMenu>
            </Menu>
        );


        return <div style={{flex: 1, overflowX: 'hidden'}}>
            <Tabs
                tabBarExtraContent={<Dropdown overlay={menu}>
                    <Icon style={{height: '42px', display: 'flex'}} className={"flex-align-items-center ph-5"}
                          type={"menu"}/>
                </Dropdown>}
                className="tabs-no-bottom-margin tabs-top-padding tabs-hide-more-button full-height componentSearchTab"
                hideAdd
                onTabClick={(tab) => {
                    this.onTabClick(tab)
                }}
                onEdit={(key) => {
                    const componentIds = components.map(item => item.id);
                    const index = componentIds.indexOf(key);
                    this._handleCloseAll(componentIds.filter(id => id !== key)).then(() => {
                        if (index > 0){
                            this.props.history.push(`/infrastructure/${componentIds[index-1]}`)
                        }else {
                            this.props.history.push(`/infrastructure/dashboard`)
                        }
                    })
                }}
                activeKey={this.props.activeKey}
                type="editable-card"
            >
                {tabs}
            </Tabs>
        </div>;
    }

}


export default compose(
    withTranslation(),
    graphql(openedComponentList, {
        name: 'getOpenedComponentList',

    }),
    graphql(removeOpenedComponent, {
        name: 'removeOpenedComponent',
    }),
    withRouter
)(OpenedComponentsTabs);
