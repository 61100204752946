// @flow
import React, {Component} from 'react';
import _ from "lodash";
import ContentHeader from "../../../components/Typo/ContentHeader";
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import DashboardPortletSettings from "../../../components/DashboardPortletSettings";
import RemovePortletMutation from "./../../../graphql/mutations/deleteUserPortlet.graphql"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import Strings from "../../../i18n/strings";
import {message, Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";
import DashboardPortletListQuery from "../../../graphql/queries/DashboardPortletListQuery.graphql";
import NavigationContainer from "../../../components/NavigationContainer";

type Props = {
    t: Function,
    removePortletMutation: Function
}

type State = {
    settingsOpen: boolean
}

class DashboardPortletSettingsRoute extends Component<Props, State> {

    state = {
        settingsOpen: false
    };

    handleDelete = (portletId) => {
        const {removePortletMutation, history, t} = this.props;

        removePortletMutation({variables: {id: portletId}}).then((res) => {
            message.success(t('Removed'));
            history.push(`/dashboard`);
        })
    };

    render() {

        const {t, match, history} = this.props;

        const portletId = _.get(match, 'params.id');
        return <NavigationContainer
            scroll
            navigationBar={<ContentHeader title={t('Settings')} tools={
                <Popconfirm placement="bottomRight" onConfirm={() => this.handleDelete(portletId)}
                            title={t(Strings.areYouSure)}>
                    <Button type={"danger"} loading={this.state.removing}>{t('Delete')}</Button>
                </Popconfirm>
            }/>}
        >
            <DashboardPortletSettings portletId={portletId}/>
        </NavigationContainer>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(RemovePortletMutation, {
        name: 'removePortletMutation',
        options: () => ({
            refetchQueries: [{
                query: DashboardPortletListQuery
            }],
        })
    })
)(DashboardPortletSettingsRoute);
