// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {withRouter} from "react-router-dom";
import ServicePlanDashboard from "./../../components/ServicePlanDashboard";

type Props = {
    match: {
        params: {
            servicePlanId: string,
            id: any
        }
    },
    history: {
        goBack: Function,
    }
};


class ComponentServicePlan extends Component<Props, State> {

    render() {
        const servicePlanId = _.get(this.props, 'match.params.servicePlanId', null);
        const componentId = _.get(this.props, 'match.params.id', null);
        return <ServicePlanDashboard onDelete={this.props.history.goBack} componentId={componentId}
                                     servicePlanId={servicePlanId}/>
    }
}

export default withRouter(ComponentServicePlan);
