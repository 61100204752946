// @flow
import React, {Component} from "react"
import {Avatar} from "antd";
import _ from "lodash";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import moment from "moment";
import ProductListQuery from "./../../graphql/queries/productList.graphql"
import ProductStatusLabel from "./../ProductStatusLabel";
import {Link} from "react-router-dom";
import Statics from "../../themes/default/values";
import {objectToFilter} from "../../utils/queryFilter";
import {QueryTable} from "@dreebit/react-components";
import {SizeMe} from "react-sizeme";

type Props = {
    t: Function,
    filterValues: any,
    useLink: boolean,
    hidePublished: boolean,
    tableProps?: any
}

class ProductList extends Component<Props> {
    static defaultProps = {
        useLink: true
}

    render() {
        const {filterValues, t, tableProps, useLink} = this.props;

        return <SizeMe
            refreshMode={"throttle"}
            monitorHeight
        >
            {({size}) => {
                return <div className={"full-height overflow-hidden"}>
                    <QueryTable
                        className={"link-table"}
                        size={'middle'}
                        scroll={size ? {
                            y: size.height ? size.height - 70 : 500,
                            x: size.width - 5
                        } : undefined}
                        queryData={{
                            query: ProductListQuery,
                            itemsKeyPath: 'productList.products',
                            totalKeyPath: 'productList.total',
                            limitKeyPath: 'params.limit',
                            startKeyPath: 'params.start',
                            pagingSize: Statics.pagingSize,
                            queryVariables: {
                                params: {
                                    ...getParams(filterValues),
                                    limit: Statics.pagingSize * 2,
                                    start: 0,
                                }
                            }
                        }}
                        rowKey={"id"}
                        columns={[
                            {
                                dataIndex: 'image.thumbnailLink',
                                key: 'image.thumbnailLink',
                                width: 50,
                                render: (text, record) => <Avatar shape={"square"} src={_.get(record,'image.thumbnailLink',"./images/default_image_component.png")}/>
                            },
                            {
                                dataIndex: 'name',
                                title: t('Name'),
                                key: 'name',
                                sorter: true,
                                render: (text, item) => {
                                    if (!useLink) {
                                        return text;
                                    }
                                    return <Link to={`/catalog/products/${_.get(item, 'id')}`}><span
                                        className='link-dark'>{text}</span></Link>
                                }
                            }, {
                                dataIndex: 'manufacturer',
                                title: t('Manufacturer'),
                                key: 'manufacturer',
                                render: (text) => _.get(text, 'name')
                            }, {
                                dataIndex: 'catalogNo',
                                title: t('Catalog No'),
                                key: 'catalogNo',
                            }, {
                                dataIndex: 'componentType',
                                title: t('Component Type'),
                                key: 'componentType',
                                render: (text) => _.get(text, 'name')
                            }, {
                                dataIndex: 'insert',
                                title: t('Insert'),
                                key: 'insert',
                                render: (text) => moment(text).format('l LT')
                            }, {
                                dataIndex: 'status',
                                title: t('Status'),
                                key: 'status',
                                render: (text) => <ProductStatusLabel status={text}/>
                            }
                        ]}
                        {...tableProps}
                    />
                </div>
            }}
        </SizeMe>
    }
}

const getParams = (filter) => {
    const params = {
        filter: {
            ...objectToFilter(filter),
            fields: undefined,
            query: undefined
        }
    };
    if (filter.status) {
        _.set(params, 'filter.status', {
            operator: "in",
            value: _.get(filter, 'status', "inReview")
        })
    }

    if (filter.manufacturerId) {
        _.set(params, 'filter.manufacturerId', {
            value: _.get(filter, 'manufacturerId')
        })
    }

    if (filter.query) {
        _.set(params, 'search', {
            query: _.get(filter, 'query', ""),
            fields: _.get(filter, 'fields', ["name", "catalogNo"]),
        })
    }

    return params;
};

export default compose(
    withTranslation()
)(ProductList);
