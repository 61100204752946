// @flow
import React, {Component} from 'react';
import TDAAttributesForm from "../TDAAttributesForm";
import CreateVersionableAttributeMutation from "../../graphql/mutations/CreateVersionableAttribute.graphql";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc'
import {compose} from "recompose";
import moment from "moment";
import VersionableAttributeList from "../../graphql/queries/VersionableAttributeList.graphql";
import _ from "lodash";
import statics from "../../statics";

type Props = {
    componentId: string,
    tda: any,
};

type State = {

}

class VersionalAttributeCreateEntryForm extends Component<Props, State> {

  render() {
    const { t, tda, createVersionableAttributeMutation, componentId} = this.props;

    return <TDAAttributesForm
        mutation={createVersionableAttributeMutation}
        translateMutationOptions={({values}) => ({
            variables: {
                id: componentId,
                input: {
                    ...values,
                    update: moment(values.update).toISOString()
                }
            }
        })}
        attributes={[{
            index: "update",
            inputType: 'datetime',
            type: 'string',
            name: t("Date"),
            value: moment()
        },tda]}
    />;
  }
}

export default compose(
    withTranslation(),
    graphql(CreateVersionableAttributeMutation,{
        name: 'createVersionableAttributeMutation',
        options: (props) => ({
            refetchQueries: [{
                query: VersionableAttributeList,
                variables: {
                    id: _.get(props, 'componentId'),
                    index: _.get(props, 'tda.index'),
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                        sort: {
                            update: 'DESC'
                        }
                    }
                }
            }]
        })
    })
)(VersionalAttributeCreateEntryForm);
