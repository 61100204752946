// @flow
import React, {Component} from 'react';
import HostListQuery from "./../../graphql/queries/HostListQuery.graphql"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import QueryTable from "../QueryTable";
import {withTranslation} from "react-i18next";
import {Badge} from "antd";
import {Link} from "react-router-dom";

type Props = {};

type State = {

}

class HostList extends Component<Props, State> {

  render() {
    const { hostListQuery, t } = this.props;

    return <div>
        <QueryTable
            query={hostListQuery}
            itemsKeyPath={'hostList.hosts'}
            totalKeyPath={'hostList.total'}
            columns={[{
                key: 'name',
                dataIndex: 'name',
                title: t('Name'),
                render: (text, record) => {
                    return <Link className={"text-link"} to={`/admin/hosts/${record.id}`}>{text}</Link>
                }
            },{
                key: 'active',
                dataIndex: 'active',
                title: t('Active'),
                render: (text, record) => {
                    return text ? <Badge status="processing" text={t("Active")} /> :   <Badge status="default" text={t("Inactive")} />
                }
            }]}
        />
    </div>;
  }
}

export default compose(
    withTranslation(),
    graphql(HostListQuery, {
        name: 'hostListQuery'
    })
)(HostList);
