import React from 'react';
import {branch, compose, renderComponent, withProps} from 'recompose';
import _ from 'lodash';
import Loader from '../components/Loader';
import ErrorComponent from '../components/Error';
import {withTranslation} from "react-i18next";
import {toastError} from "../utils/toast";

const waitWhileLoading = (propName = 'data', keys: string[], opts) => {
    const options =  {
        optional: false,
        allowErrors: false,
        loaderSize: null,
        showLoader: true,
        renderLoader: null,
        renderError: null,
        allowToasts: false,
        ...opts
    };
    return compose(
        withTranslation(),
        withProps((props) => {
            if(props[propName] && !_.get(options, 'allowToasts', false) && (props[propName].error || props[propName].rejected)){
                const error = _.get(props, `${[propName]}.error`);

                toastError(error);

                if(props.onError){
                    props.onError(error);
                }
            }
        }),
        branch(
            (props) => !_.get(options, 'optional', false) && (!props || !props[propName]),
            renderComponent(() => (<ErrorComponent enableFeedback title={'No query'}/>)),
        ),
        branch(
            (props) => _.has(props, propName) && (props[propName].loading || props[propName].pending),
            renderComponent(() => options.showLoader !== false ? options.renderLoader ? options.renderLoader() : <Loader size={_.get(options, 'loaderSize')} loading/> : null),
        ),
        branch(
            (props) => props[propName] && !_.get(options, 'allowErrors') && (props[propName].error || props[propName].rejected),
            renderComponent((props) => {

                const t = props.t;
                const error = _.get(props, `${[propName]}.error`);
                const query = props[propName];
                const queryString = _.chain(query).get("error.graphQLErrors[0].operation.query.loc.source.body").value();
                const operationName = _.chain(query).get("error.graphQLErrors[0].operation.operationName").value();
                const feedbackData = {
                    error: _.get(error, 'message'),
                    variables: query.variables,
                    operationName,
                    query: queryString || propName
                };

                if (options.renderError) return options.renderError(error, feedbackData)


                if (_.has(error, 'graphQLErrors')) {

                    console.log(error, _.get(props, `${[propName]}`));
                    return <ErrorComponent title={t('Error occurred')}
                                           error={error}
                                           status={_.get(error, 'status')}
                                           enableFeedback
                                           feedbackData={feedbackData}
                                           description={window.__DEV__ ? _.first(error.graphQLErrors.map(({message, debugMessage}, i) => (
                                               message || debugMessage
                                           ))) : t('Please try to reload this page') }/>;
                } else if (_.has(error, 'networkError')) {
                    return <ErrorComponent enableFeedback feedbackData={feedbackData} title={'Network Error'} description={error.networkError }/>;
                }

                return <ErrorComponent enableFeedback feedbackData={feedbackData} title={'Query error'}/>;
            }),
        ),
        branch(
            (props) => {
                if (_.isString(keys)) {
                    keys = [keys];
                }
                return props[propName] && keys && keys.reduce((acc, cur) => (acc || !_.has(props[propName], cur)), false)
            },
            renderComponent((props) => {
                const query = props[propName];
                console.log(query);
                console.error('Keys not found', keys, props[propName]);
                return <ErrorComponent title={`Keys not found`} enableFeedback description={`${keys} could not be found [${propName}]`}/>;
            }),
        ),
    );
}


export default waitWhileLoading;
