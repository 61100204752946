// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import DeleteComponentMutation from "./../../graphql/mutations/DeleteComponent.graphql";
import Error from "../Error";
import MultiActionWrapper from "../MultiActionWrapper";
import {Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";
import Strings from "../../i18n/strings";

type Props = {
    componentIds: string,
    onFinished?: () => void
};

type State = {
    finishedCount: number,
    loading: boolean
}


class ComponentMultiDelete extends Component<Props, State> {

    state = {
        loading: false,
        finishedCount: -1
    };

    _handleChange = (selectedId) => {
        this.setState({
            selectedId
        })
    };


    render() {
        const {componentIds, deleteComponentMutation, t, onFinished} = this.props;

        if (!componentIds || !componentIds.length){
            return <Error title={t('No components to move')} />
        }

        return <MultiActionWrapper
            onFinished={onFinished}
            total={componentIds.length}
            mutation={deleteComponentMutation}>
            {({queue}) => {
                return <Popconfirm title={t(Strings.areYouSure)} onConfirm={() => {
                    componentIds.forEach((componentId) => {
                        queue.push({
                            variables: {id: componentId}
                        })
                    })
                }}>
                    <Button type={'danger'}>{t('Delete TOTAL components',{ total: componentIds.length })}</Button>
                </Popconfirm>
            }}
        </MultiActionWrapper>
    }
}

export default compose(
    graphql(DeleteComponentMutation, {
        name: 'deleteComponentMutation',
    }),
    withTranslation()
)(ComponentMultiDelete);
