// @flow
import React, {Component} from 'react';
import {Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import ServiceActionWizardForm from "./../ServiceActionWizardForm";
import Bold from "../Utilities/Bold";
import {compose} from "recompose";
import withProfile from "../../hoc/withProfile";
import {graphql} from "@apollo/client/react/hoc";
import serviceActionTypeForm from "../../graphql/queries/serviceActionTypeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    serviceTicketStateIndex: string,
    serviceTicketTypeIndex: string,
    serviceTicketId: string,
    onSubmit?: Function,
    onModalChange?: (visible: boolean) => void,
    onClick?: any,
    onCancel?: any
};

type State = {
    visible: boolean,
}

class AddTimelineItem extends Component<Props, State> {
    state = {visible: false};

    onClick = () => {
        const {onClick} = this.props;

        if(onClick) onClick();
        this.showModal();
    }

    showModal = () => {
        this.setState({
            visible: true,
        });

        if (this.props.onModalChange){
            this.props.onModalChange(true)
        }
    };

    handleOk = (res) => {
        setTimeout(() => {
            this.setState({
                visible: false,
            });

            if (this.props.onSubmit) {
                return this.props.onSubmit(res)
            }
        },100)
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });

        if (this.props.onModalChange) this.props.onModalChange(false);
        if (this.props.onCancel) this.props.onCancel();
    };

    render() {
        const {serviceTicketId, serviceTicketStateIndex,serviceActionTypeFormQuery, t, profile} = this.props;
        const {visible} = this.state;

        if (!_.get(serviceActionTypeFormQuery,'serviceActionTypeList.serviceActionTypes',[]).length){
            return null;
        }

        return (
            <div style={{padding: '27px 32px 0'}}>
                {serviceTicketStateIndex === _.get(JSON.parse(_.get(profile, 'hostConfig', '{}')), 'serviceTicketConfirmStatus', 'done') ?
                    <Popconfirm
                        title={<div>
                            <div>
                                <Bold>{t("This ticket is already done")}</Bold>
                            </div>
                            <span>{t("Are you sure to add a new service action")}</span>
                        </div>}
                        onConfirm={this.showModal}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button data-cy={'TEST_20001'} shape={'circle'} size={'large'} icon={'plus'}/>
                    </Popconfirm>
                : <Button data-cy={'TEST_20001'} shape={'circle'} size={'large'} icon={'plus'} type="primary" onClick={this.onClick}/>}
                {
                    visible && <ServiceActionWizardForm modal
                                                        serviceActionTypesFetchPolicy={'cache-first'}
                                                        onSaved={this.handleOk}
                                                        visible={visible}
                                                        onCancel={this.handleCancel}
                                                        serviceTicketId={serviceTicketId}
                                                        serviceTicketTypeIndex={this.props.serviceTicketTypeIndex}/>
                }
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    withProfile(),
    graphql(serviceActionTypeForm, {
        name: 'serviceActionTypeFormQuery',
        options: (props) => ({
            variables: {
                serviceTicketId: props.serviceTicketId,
                serviceTicketTypeIndex: props.serviceTicketTypeIndex
            }
        }),
    }),
    waitWhileLoading('serviceActionTypeFormQuery', 'serviceActionTypeList', { showLoader: false}),
)(AddTimelineItem);

