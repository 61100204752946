// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import Empty from "./../Empty";

import ServiceTicketFinalTestCreateQuery from "./../../graphql/queries/serviceTicketFinalTestCreateQuery.graphql";
import CreateFinalTestMutation from "./../../graphql/mutations/createFinalTest.graphql";
import Loader from "../Loader";
import Error from "../Error";
import {checkIfServiceTicketCanCreateFinalTest} from "../../utils/FinaltestHelper";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    serviceTicketId: string,
    serviceTicketFinalTestCreateQuery: any,
    createFinalTestMutation: Function,
    onCreated: (createdFinalTest: any)=> void
};

type State = {
    loading: boolean,
    canCreate: boolean,
    finalTest?: any,
    error?: string
}

class FinalTestAutoCreate extends Component<Props, State> {

    state = {
        loading: true,
        canCreate: null,
        finalTest: null,
        error: null
    };

    componentDidMount() {

        const {serviceTicketFinalTestCreateQuery, createFinalTestMutation, serviceTicketId, t} = this.props;
        const {serviceTicket} = serviceTicketFinalTestCreateQuery;


        const canCreate = checkIfServiceTicketCanCreateFinalTest(serviceTicket);

        this.setState({
            finalTest: serviceTicket.finalTest,
            canCreate,
            loading: canCreate && !serviceTicket.finalTest,
        });
        if (canCreate) {
            createFinalTestMutation({
                variables: {
                    serviceTicketId
                }
            }).then(({data}) => {
                this.setState({
                    loading: false,
                    finalTest: data.createFinalTest
                }, () => {
                    if (this.props.onCreated) {
                        this.props.onCreated(data.createFinalTest)
                    }
                })

            })
                .catch(() => {
                    this.setState({
                        error: t('Could not create final test'),
                        loading: false
                    })
                })

        }
    }

    render() {
        const {t, serviceTicketId} = this.props;

        const {canCreate, loading, error, finalTest} = this.state;

        if (canCreate === false) {
            return <Empty text={'You are not able to create a final test for this service ticket'}/>;
        }

        if (loading === true) {
            return <Loader title={t('Creating final test')}/>
        }

        if (error) {
            return <Error description={error}></Error>
        }

        if (finalTest) {
            return <Link
                to={`/service/${serviceTicketId}/finalTest`}>{t('Open_2')} {t('Finaltest')}</Link>
        }

        return <Loader hideTitle/>

    }
}


export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceTicketFinalTestCreateQuery, {
        name: 'serviceTicketFinalTestCreateQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketFinalTestCreateQuery', 'serviceTicket.component.componentType.finalTestTypeDefinitionAttributeList'),
    graphql(CreateFinalTestMutation, {
        name: 'createFinalTestMutation',
    }),
)(FinalTestAutoCreate);
