// @flow
import React, { Component } from 'react';
import _ from "lodash";
import {compose} from "recompose";
import Panel from "../../components/Panel";
import {withTranslation} from "react-i18next";
import ComponentProductChanger from "../../components/ComponentProductChanger";
import {withRouter} from "react-router-dom";
import {Col, Row} from "antd";
import ComponentProductInfo from "../../components/ComponentProductInfo";
import withResources from "../../hoc/withResources";

type Props = {

};

type State = {

}

class ComponentProductChange extends Component<Props, State> {

  render() {
    const { t, match } = this.props;

    const componentId = _.get(match,'params.id');

    return <div className={"p-10"}>
      <Row gutter={16}>
        <Col md={8}>
          <Panel title={t('Product Info')}>
            <ComponentProductInfo componentId={componentId} />
          </Panel>
        </Col>
        <Col md={16}>
          <Panel title={t('Change Product')}>
            <ComponentProductChanger componentId={componentId}/>
          </Panel>
        </Col>
      </Row>
    </div>;
  }
}

export default compose(
    withResources('updateComponentProduct'),
    withTranslation(),
    withRouter
)(ComponentProductChange);
