// @flow
import React, {Component} from 'react';
import {Button, Icon} from "@dreebit/react-components";
import _ from 'lodash'
import updateStarredServiceTicket from './../../graphql/mutations/updateStarredServiceTicket.graphql';
import ServiceTicketStarredInfoQuery from './../../graphql/queries/serviceTicketStarredInfo.graphql';
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {toastError} from "../../utils/toast";
import StarredServiceTicketsQuery from "../../graphql/queries/serviceTicketStarred.graphql";

type Props = {
    buttonProps?: any,
    serviceTicketId: string | number,
    serviceTicketStarredQuery: any,
    updateStarredServiceTicketMutation: Function,
};

type State = {
    loading: boolean,
}

class ServiceTicketStarredButton extends Component<Props, State> {

    state = {
        loading: false,
        starred: undefined,
    };


    onClick = () => {

        const {updateStarredServiceTicketMutation, serviceTicketId, serviceTicketStarredInfoQuery} = this.props;

        const starred = _.get(serviceTicketStarredInfoQuery, 'serviceTicket.starred', null);

        this.setLoading(true)
            .then(() => updateStarredServiceTicketMutation({
                variables: {
                    id: serviceTicketId,
                    starred: !starred
                }
            }))
            .catch(toastError)
            .finally(() => this.setLoading(false))

    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {buttonProps, serviceTicketStarredInfoQuery} = this.props;

        const loading = _.get(serviceTicketStarredInfoQuery, 'loading') || this.state.loading;

        if (serviceTicketStarredInfoQuery.error) {
            return <Button disabled {...buttonProps} icon={'alert'}/>;
        }

        const starred = _.get(serviceTicketStarredInfoQuery, 'serviceTicket.starred', null);
        return <Button type={starred ? 'primary' : null} onClick={this.onClick} {...buttonProps}
                       loading={loading}>{!loading ?
            <Icon type={'star'} theme={starred ? 'filled' : null}/> : null}</Button>;
    }
}


export default compose(
    graphql(ServiceTicketStarredInfoQuery, {
        name: 'serviceTicketStarredInfoQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceTicketId
            }
        }),
    }),
    graphql(updateStarredServiceTicket, {
        name: 'updateStarredServiceTicketMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: StarredServiceTicketsQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 50,
                    }
                }
            }, {
                query: ServiceTicketStarredInfoQuery,
                variables: {
                    id: props.serviceTicketId
                }
            }],
        })
    }),
)(ServiceTicketStarredButton);

