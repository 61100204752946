import React from "react";
import {Col, Row} from "antd";
import {Button} from "@dreebit/react-components";
import {useTranslation} from "react-i18next";
import Panel from "../Panel";
import {useMutation, useQuery} from "@apollo/client";
import ComponentTokenQuery from "../../graphql/queries/componentToken.graphql";
import Loader from "../Loader";
import _ from "lodash";
import CopyCode from "../CopyCode";
import Bold from "../Utilities/Bold";
import ComponentMoveTokenInfoText from "../ComponentMoveTokenInfoText";
import Toolbar from "../Toolbar";
import MutationButton from "../MutationButton";
import DeleteComponentMoveTokenMutation from "../../graphql/mutations/deleteComponentMoveToken.graphql";
import moment from "moment";

interface Props {

}

const ComponentTokenInfoPanel = ({componentId}: Props) => {

    const {t} = useTranslation();

    const query = useQuery(ComponentTokenQuery,{
        variables: {
            componentId
        }
    });

    const token = _.get(query,'data.component.componentMoveToken');
    const tokenId = _.get(query,'data.component.componentMoveToken.id');

    const [mutation] = useMutation(DeleteComponentMoveTokenMutation, {
        variables: {
            id: tokenId
        }
    });

    if (query.loading) return <Loader/>;


    return <div>

        <Row className={'ph-15'} gutter={16}>
            <Col sm={8} lg={6} xl={4}>
                <Panel>
                    <div className={"mb-5"}><Bold>{t('Print it as A4 Sheet')}</Bold></div>
                    <a href={_.get(token,'print.sheet.link')} target={"_BLANK"}><img className={"border p-2 mb-10"} src={_.get(token,'print.qr.link')} style={{width: "100%"}}/></a>
                    <a href={_.get(token,'print.sheet.link')} target={"_BLANK"}><Button style={{width: '100%'}}>{t("Print")}</Button></a>
                </Panel>
            </Col>
            <Col sm={16} lg={18} xl={20}>
                <Panel bannerText={'Component move token can be used by third party users'} bannerType={'warning'}>
                    <div className={"mb-10"}>
                        <Bold>{t("Click here to copy the Token")}:</Bold>
                    </div>
                    <CopyCode code={_.get(token, 'token')}/>
                    <div className={'text-grey italic'}>
                        {t('This token was created at DATE',{DATE: moment(_.get(token,'insert')).format('DD.MM.YYYY HH:mm')})}
                    </div>
                    <div className={"mt-10"}>
                        <ComponentMoveTokenInfoText/>
                    </div>
                    {tokenId ? <div>
                        <Toolbar style={{paddingRight: 5}} renderRight={() => <MutationButton mutation={mutation} onDone={() => query.refetch()} confirm buttonProps={{type: 'danger'}}>{t('Delete')}</MutationButton>} />
                    </div> : null}

                </Panel>
            </Col>
        </Row>
    </div>
};

export default ComponentTokenInfoPanel;
