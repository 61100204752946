// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import BulkServiceActionWizardForm from "../ServiceActionWizardForm/bulk";
import ServiceTicketsBulkFileUpload from "../ServiceTicketsBulkFileUpload";
import {withApollo} from "@apollo/client/react/hoc";
import ServiceTicketsMultiForm from "../ServiceTicketsMultiForm";
import ServiceTicketsPDFExport from "../ServiceTicketsPDFExport";
import ServiceTicketsFinalizeFinalTest from "../ServiceTicketsFinalizeFinalTest";
import ServiceTicketsAddFinalTest from "../ServiceTicketsAddFinalTest";
import ServiceTicketsDelete from "../ServiceTicketsDelete";
import withProfile from "../../hoc/withProfile";

type Props = {
    preferredServiceTicketType?: string,
    serviceTicketIds: string,
    onDone: Function,
    onDelete?: Function,
    onSelectAction: Function
};


class ServiceTicketMultiAction extends Component<Props, State> {

    state = {
        selectedAction: "action"
    };

    render() {
        const {
            serviceTicketIds,
            t,
            onDone,
            onSelectAction,
            preferredServiceTicketType,
            client,
            onDelete,
            resources
        } = this.props;
        const {selectedAction} = this.state;

        const actions = [
            {
                index: 'action',
                name: t('New Service Action'),
                render: (serviceTicketIds) => {
                    return <BulkServiceActionWizardForm
                        preferredServiceTicketType={preferredServiceTicketType}
                        serviceTicketIds={serviceTicketIds}
                        onFinished={(res) => {
                            return client.clearStore().then(() => onDone ? onDone(res) : null);
                        }}
                    />
                }
            }, {
                index: 'fileUpload',
                name: t('File upload'),
                render: (serviceTicketIds) => {
                    return <ServiceTicketsBulkFileUpload serviceTicketIds={serviceTicketIds} onFinished={onDone}/>
                }
            }, {
                index: 'attributes',
                name: t('Attributes'),
                render: (serviceTicketIds) => {
                    return <ServiceTicketsMultiForm serviceTicketIds={serviceTicketIds} onFinished={onDone}/>
                }
            }, {
                index: 'pdfExport',
                name: t('PDF Export'),
                render: (serviceTicketIds) => {
                    return <ServiceTicketsPDFExport serviceTicketIds={serviceTicketIds} onFinished={onDone}/>
                }
            }, {
                index: 'addFinalTest',
                name: t('Add final test'),
                render: (serviceTicketIds) => {
                    return <ServiceTicketsAddFinalTest serviceTicketIds={serviceTicketIds} onFinished={onDone}/>
                }
            }, {
                index: 'finalizeFinalTest',
                name: t('Finalize final test'),
                render: (serviceTicketIds) => {
                    return <ServiceTicketsFinalizeFinalTest serviceTicketIds={serviceTicketIds} onFinished={onDone}/>
                }
            }, {
                index: 'deleteServiceTicket',
                name: t('Delete service ticket'),
                render: (serviceTicketIds) => {
                    return <ServiceTicketsDelete serviceTicketIds={serviceTicketIds} onFinished={() => {
                        if (onDone) onDone();
                        if (onDelete) onDelete();
                    }}/>
                },
                resources: ["administrateServiceTickets"]
            }
        ];


        const actionItem = _.find(actions, {index: selectedAction});

        return <div>
            <AttributesInlineForm
                attributes={[
                    {
                        index: 'actionIndex',
                        name: 'Action',
                        type: 'string',
                        inputType: 'select',
                        optionValues: actions
                            .filter((item) => {
                                const itemResources = _.get(item, 'resources', []);
                                if (!itemResources.length) return true;

                                return itemResources.reduce((acc, cur) => acc || resources.indexOf(cur) > -1, false);
                            })
                            .map(item => ({
                                value: item.index,
                                name: item.name
                            })),
                        value: selectedAction
                    }
                ]}
                onChange={(values) => {

                    this.setState({
                        selectedAction: values.actionIndex
                    })
                    onSelectAction(values.actionIndex)
                }}
                useSubmit={false}
            />

            {actionItem ? <div>
                <hr/>
                {actionItem.render(serviceTicketIds)}</div> : <div className={"ant-row"}>
                <div
                    className={"pv-10 ant-col-md-24 ant-col-lg-offset-7 ant-col-lg-17"}>{t('Please select an action')}</div>
            </div>}
        </div>;
    }
}

export default compose(
    withTranslation(),
    withProfile(),
    withApollo,
)(ServiceTicketMultiAction);
