import React from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";

type Props = {
    filter: any,
    onChange: (changes: any) => any
};

const StatisticsTabFilter = ({filter, onChange}: Props) => {
    const {t} = useTranslation();
    const {tab} = useParams();

    return <div className={'full-width ph-10 gap-10'}>
        <TypeDefinitionAttributesForm
            index={'statisticFilter'}
            mapAttributes={(attribute: any) => {
                if (attribute.inputType.toUpperCase() === 'SELECT') return {
                    ...attribute,
                    addOnAttribute: {
                        index: 'operator',
                        inactiveIcon: 'menu-unfold',
                        activeIcon: 'menu-fold',
                        inputType: 'button',
                        targetType: 'boolean',
                        type: 'boolean',
                        inactiveTooltip: t('inSelectOperation'),
                        activeTooltip: t('ninSelectOperation'),
                    }
                }

                return attribute
            }}
            filter={(attribute: any) => {
                if (attribute.categories) return attribute.categories.indexOf(tab) > -1;
                return true;
            }}
            formProps={{
                cacheChanges: false,
                useSubmit: false,
                layout: 'inline',
                values: filter,
                onChange: (changes, allvalues) => {
                    onChange(changes)
                }
            }}
        />
    </div>
}

export default StatisticsTabFilter;
