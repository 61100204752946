// @flow
import React, {Component} from 'react';
import SubHeaderWithTools from "./index";
import _ from "lodash";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import ServiceTicketContaminationDeclarationQuery
    from "../../graphql/queries/serviceTicketContaminationDeclaration.graphql";
import FinalizeContaminationDeclarationMutation from "../../graphql/mutations/finalizeContaminationDeclaration.graphql";
import {withTranslation} from "react-i18next";
import {message} from "antd";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import query from "../../graphql/queries/contaminationDeclarationForm.graphql";
import {withProps} from "recompose";

type Props = {
    serviceTicketId: string,
    t: Function,
    contaminationDeclarationId?: string
}

class ContaminationDeclarationSubHeader extends Component<Props> {


    _handleFinalize = () => {

        const {
            t,
            finalizeContaminationDeclarationMutation,
            serviceTicketContaminationDeclarationQuery,
            contaminationDeclarationId
        } = this.props;

        this.setState({
            loading: true,
        });
        finalizeContaminationDeclarationMutation({
            variables: {
                contaminationDeclarationId: contaminationDeclarationId,
            }
        })
            .then(({data}) => {
                message.success(t('deco finalized'));

                const printURL = _.get(data, 'finalizeContaminationDeclaration.print.sheet.link');
                const file = _.get(data, 'finalizeContaminationDeclaration.file');
                if (printURL && !file) {
                    window.open(printURL, "_blank");
                }
            })
            .catch((err) => {
                console.error(err);
                message.error(t('Error'));
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            })

    };


    render() {
        const {t, serviceTicketContaminationDeclarationQuery, contaminationDeclarationId} = this.props;
        const finalized = _.get(serviceTicketContaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.finalized', false);
        const printLink = _.get(serviceTicketContaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.file.link') || _.get(serviceTicketContaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.print.sheet.link');

        return <SubHeaderWithTools title={t('Decontamination Declaration')}
                                   finalized={finalized}
                                   onFinalize={contaminationDeclarationId && !finalized ? this._handleFinalize : undefined}
                                   printLink={printLink}/>;
    }
}

export default compose(
    graphql(ServiceTicketContaminationDeclarationQuery, {
        name: 'serviceTicketContaminationDeclarationQuery',
        options: (props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketContaminationDeclarationQuery', 'serviceTicket.contaminationDeclaration'),
    withProps((props) => {
        const contaminationDeclarationId = _.get(props, 'serviceTicketContaminationDeclarationQuery.serviceTicket.contaminationDeclaration.id');
        return {
            contaminationDeclarationId
        }
    }),
    graphql(FinalizeContaminationDeclarationMutation, {
        name: 'finalizeContaminationDeclarationMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: serviceTicketDashboard,
                variables: {
                    id: props.serviceTicketId
                }
            }, {
                query: query,
                variables: {
                    id: props.contaminationDeclarationId
                }
            }, {
                query: ServiceTicketContaminationDeclarationQuery,
                variables: {
                    id: props.serviceTicketId
                }
            }]
        }),
    }),
)(withTranslation()(ContaminationDeclarationSubHeader));
