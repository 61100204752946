// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {compose, withProps} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import ContentHeader from "../../../../components/Typo/ContentHeader";
import ServiceProviderDashboardQuery from "./../../../../graphql/queries/ServiceProviderDashboard.graphql";
import DeleteServiceProviderMutation from "./../../../../graphql/queries/deleteServiceProvider.graphql";
import ServiceProviderListQuery from "./../../../../graphql/queries/serviceProviderList.graphql";
import waitWhileLoading from "../../../../hoc/waitWhileLoading";
import {message, Popconfirm, Tabs} from "antd";
import {Button} from "@dreebit/react-components";
import Strings from "../../../../i18n/strings";
import ServiceProviderUpdateForm from "../../../../components/ServiceProviderUpdateForm";
import Error from "../../../../components/Error";
import {Route, Switch, withRouter} from "react-router-dom";
import statics from "../../../../statics";
import withProfile from "../../../../hoc/withProfile";
import NavigationContainer from "../../../../components/NavigationContainer";
import './index.css';
import ContentWrapper from "../../../../components/ContentWrapper";


type Props = {
    serviceProviderId: string,
    t: Function,
    serviceProvider: any,
    serviceProviderPublishMutation: Function,
    deleteServiceProviderMutation: Function,
    onDelete: ?Function,
    derviceProviderDashboardQuery: query,
    history: {
        push: Function,
    }
};

type State = {
    removing: boolean,
    publishing: boolean,
}

const TabPane = Tabs.TabPane;

class ServiceProviderDetailRoute extends Component<Props, State> {

    state = {
        removing: false,
        publishing: false
    };

    handleDelete = () => {
        const {t, deleteServiceProviderMutation, onDelete} = this.props;

        this.setState({
            removing: true,
        }, () => {
            deleteServiceProviderMutation().then((res) => {
                message.success(_.get(res, 'data.message') || t('ServiceProvider removed'));
                setTimeout(() => {
                    if (onDelete) {
                        onDelete(res);
                    }
                }, 0)
            }).catch(() => {
                message.error(Strings.errorOccurred);
            }).finally(() => {
                this.setState({
                    removing: false,
                })
            })
        });
    };

    render() {
        const {t, serviceProvider, serviceProviderId} = this.props;

        if (!serviceProvider) {
            return <Error/>
        }

        const accessRemove = _.get(serviceProvider,"access.remove", false);

        const deleteButton = accessRemove ? <Popconfirm
            placement="bottomRight"
            key={"remove"}
            onConfirm={this.handleDelete}
            title={t(Strings.areYouSure)}
        >
            <Button type={'danger'} loading={this.state.removing}>{t('Delete')}</Button>
        </Popconfirm> : null;

        return <NavigationContainer scroll navigationBar={
            <div>
                <ContentHeader title={serviceProvider.name} tools={deleteButton}/>
                <ContentWrapper>
                    <ServiceProviderUpdateForm serviceProviderId={serviceProviderId}/>
                </ContentWrapper>
            </div>
        }/>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(DeleteServiceProviderMutation, {
        name: 'deleteServiceProviderMutation',
        options: (props) => ({
            variables: {
                id: props.serviceProviderId
            },
            refetchQueries: [{
                query: ServiceProviderListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                    }
                }
            }]
        })
    }),
    graphql(ServiceProviderDashboardQuery, {
        name: 'serviceProviderDashboardQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceProviderId
            }
        }),
    }),
    waitWhileLoading('serviceProviderDashboardQuery', 'serviceProvider'),
    withProps((props) => ({
        serviceProvider: _.get(props, 'serviceProviderDashboardQuery.serviceProvider')
    })),
    withProfile()
)(ServiceProviderDetailRoute);
