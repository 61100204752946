import React, {useState} from 'react';
import StatisticsTabFilter from "../StatisticsTabFilter";
import {Col} from "../Flex";
import {useTranslation} from "react-i18next";
import StatisticsDashboardSummaryPanels from "../StatisticsDashboardSummaryPanels";

type Props = {
};

const StatisticsDashboard = ({}: Props) => {
    const {t} = useTranslation();

    const [filter, setFilter]: any = useState();

    return <Col className={'full-height'}>
        <StatisticsTabFilter
            filter={filter}
            onChange={(changes: any) => {
                setFilter({
                    ...filter,
                    ...changes
                })
            }}
        />

        <StatisticsDashboardSummaryPanels
            filter={filter}
        />
    </Col>
}

export default StatisticsDashboard;
