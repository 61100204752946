// @flow
import React, {Component} from 'react';
import {Area, AreaChart, Legend, XAxis, YAxis} from "recharts";
import _ from 'lodash';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ServiceTicketListUpdateQuery from '../../graphql/queries/serviceTicketListUpdate.graphql'
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {groupAndCombineByWeekday} from "./helperFunctions";

type Props = {
  width: number,
  height: number
};

type State = {

}

class ServiceTicketsPerWeek extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { width, height, serviceTicketsThisWeekQuery, serviceTicketsLastWeekQuery } = this.props;

    const serviceTicketsThisWeek = _.get(serviceTicketsThisWeekQuery, 'serviceTicketList.serviceTickets');
    const serviceTicketsLastWeek = _.get(serviceTicketsLastWeekQuery, 'serviceTicketList.serviceTickets');

    const data = groupAndCombineByWeekday(serviceTicketsLastWeek, serviceTicketsThisWeek);

    let chartWidth = (width * window.innerWidth * 0.2) - 40;
    let chartHeight = (height * window.innerHeight * 0.33) - 40;

    return <div>
      <AreaChart width={chartWidth} height={chartHeight} data={data}
                 margin={{top: 20, right: 20, bottom: 20, left: 20}}>
        <defs>
          <linearGradient id="colorPrevious" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8394ae" stopOpacity={0.4}/>
            <stop offset="95%" stopColor="#8394ae" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ee8126" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#ee8126" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="date" />
        <YAxis />
        <Legend />
        <Area type="monotone" dataKey="previousWeek" name="Vorwoche" stroke="#8394ae" fillOpacity={0.4} fill="url(#colorPrevious)" />
        <Area type="monotone" dataKey="currentWeek" name="aktuelle Woche" stroke="#ee8126" fillOpacity={0.8} fill="url(#colorCurrent)" />
      </AreaChart>
    </div>;
  }
}

export default compose(
  graphql(ServiceTicketListUpdateQuery, {
    name: 'serviceTicketsThisWeekQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          start: 0,
          limit: 600,
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: {from: "2019-05-13T00:00:00.000Z", to: "2019-05-16T23:59:59.999Z"}}
          },
          sort: {
            update: 'ASC'
          }
        }
      }
    }),
  }),
  waitWhileLoading('serviceTicketsThisWeekQuery', 'serviceTicketList.serviceTickets'),
  graphql(ServiceTicketListUpdateQuery, {
    name: 'serviceTicketsLastWeekQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          start: 0,
          limit: 600,
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: {from: "2019-05-06T00:00:00.000Z", to: "2019-05-10T23:59:59.999Z"}}
          },
          sort: {
            update: 'ASC'
          }
        }
      }
    }),
  }),
  waitWhileLoading('serviceTicketsLastWeekQuery', 'serviceTicketList.serviceTickets'),
)(ServiceTicketsPerWeek);
