// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import TDAAttributesForm from "../TDAAttributesForm";
import {withTranslation} from "react-i18next";
import ServiceTicketWarrantyReportFormQuery from "../../graphql/queries/ServiceTicketWarrantyReportFormQuery.graphql";
import ServiceActionsTimelineQuery from "./../../graphql/queries/serviceActionsTimeline.graphql";
import UpdateWarrantyReportFormMutation from "../../graphql/mutations/updateWarrantyReportForm.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import MutationButton from "../MutationButton";
import FinalizeWarrantyReportMutation from "../../graphql/mutations/finalizeWarrantyReport.graphql";
import {checkResource} from "../../hoc/withResources";
import withProfile from "../../hoc/withProfile";
import {toastError} from "../../utils/toast";
import {withRouter} from "react-router-dom";

type Props = {
    serviceTicketId: string,
    history?: any
};

type State = {

}

class WarrantyReportForm extends Component<Props, State> {

    state = {
        changes: null
    }

  render() {
      const {
          attributes,
          t,
          resources,
          finalized,
          history,
          updateWarrantyReportFormMutation,
          warrantyReportId,
          finalizeWarrantyReportMutation
      } = this.props;


    const groupOrder = ["client","device","general","appraisal","accounting"]

      const canFinalize = checkResource(["grantWarrantyStatus"],resources);


    return <div className={"submit-right"}>
        <TDAAttributesForm
            groups={Object.keys(_.groupBy(attributes, (item) => {
                return _.first(item.categories);
            }))
                .sort((first, second) => groupOrder.indexOf(first) < groupOrder.indexOf(second) ? -1 : 1 )
                .map(item => ({
                index: item,
                name: t(item),
                colProps: {
                    xl: 12
                }
            }))}
            onChange={(changes) => this.setState({
                changes
            })}
            useSubmit={!finalized}
            onSubmit={() => {
                this.setState({
                    changes: null
                })
            }}
            afterSubmit={this.state.changes || !canFinalize ? null :
                <MutationButton buttonProps={{style: {float: "right", marginRight: 8}, icon: "lock"}}
                                onError={(e) => toastError(e)}
                                onDone={() => null}
                                mutation={finalizeWarrantyReportMutation}>{t("Finalize")}</MutationButton>}
            mutation={updateWarrantyReportFormMutation}
            translateMutationOptions={({changes}) => {
                return {
                    variables: {
                        warrantyReportId,
                        input: changes
                    }
                }
            }}
            formStyles={{
                formItemLayout: {
                    labelCol: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                    wrapperCol: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                }
            }}
            attributes={attributes}

        />
    </div>;
  }
}

export default compose(
    graphql(ServiceTicketWarrantyReportFormQuery, {
        name: 'serviceTicketWarrantyReportFormQuery',
        options: (props) => {
            return {
                variables: {
                    serviceTicketId: props.serviceTicketId
                }
            }
        }
    }),
    waitWhileLoading('serviceTicketWarrantyReportFormQuery', ['serviceTicket.warrantyReport']),
    withProps((props) => ({
        finalized: _.get(props, 'serviceTicketWarrantyReportFormQuery.serviceTicket.warrantyReport.finalized'),
        warrantyReportId: _.get(props, 'serviceTicketWarrantyReportFormQuery.serviceTicket.warrantyReport.id'),
        attributes: _.get(props, 'serviceTicketWarrantyReportFormQuery.serviceTicket.warrantyReport.typeDefinitionAttributeList.typeDefinitionAttributes', [])
    })),
    graphql(UpdateWarrantyReportFormMutation, {
        name: 'updateWarrantyReportFormMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceTicketWarrantyReportFormQuery,
                    variables: {
                        serviceTicketId: props.serviceTicketId
                    }
                }
            ]
        })
    }),
    graphql(FinalizeWarrantyReportMutation,{
        name: 'finalizeWarrantyReportMutation',
        options: (props) => {
            return {
                variables: {
                    warrantyReportId: _.get(props,'warrantyReportId'),
                },
                refetchQueries: [{
                    query: ServiceTicketWarrantyReportFormQuery,
                    variables: {
                        serviceTicketId: props.serviceTicketId
                    }
                },{
                    query: ServiceActionsTimelineQuery,
                    variables: {
                        id: props.serviceTicketId
                    }
                }]
            }
        }
    }),
    withTranslation(),
    withProfile(),
    withRouter
)(WarrantyReportForm);
