// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {Popover} from "antd";
import {Button} from "@dreebit/react-components";
import _ from 'lodash';

type Props = {
    bookmark: any
}

const BookmarkDetailModalFooter = (props: Props) => {

    const {bookmark} = props;
    const {t} = useTranslation();

    const qr = _.get(bookmark, 'print.qr');
    const sheet = _.get(bookmark, 'print.sheet');

    const _viewQRCode = () => {
        if (qr && qr.link) {
            window.open(qr.link)
        }
    };

    const _downloadSheet = () => {
        if (sheet && sheet.link) {
            window.open(sheet.link)
        }
    };

    return <div className={'full-width full-height flex-row flex-space-between'}>
        <div/>
        <div className={'flex-row'}>
            <Popover
                placement="top"
                content={<img alt={''} width="150" src={qr ? qr.link : t('No QR-Code exists')}/>}
                title={t('QR-Code')}
            >
                <Button
                    onClick={_viewQRCode}
                    icon="qrcode"
                >
                    {t('View QR-Code')}
                </Button>
            </Popover>
            <Button
                onClick={_downloadSheet}
                icon="download"
            >
                {t('Download Sheet')}
            </Button>
        </div>
    </div>
};

export default BookmarkDetailModalFooter
