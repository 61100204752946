// @flow
import React, {Component} from "react"

import {List} from 'antd';
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";

type Props = {
    portlet: {
        name: string,
        description: string,
        image: {
            link: string
        },
    },
    onAdd: ?Function,
    loading: boolean
}

class PortletListItem extends Component<Props> {


    static defaultProps = {
        loading: false
    };

    render() {

        const {portlet, t, loading, onAdd} = this.props;

        return <List.Item actions={onAdd ? [<Button onClick={onAdd} loading={loading}>{t('Add')}</Button>] : null}>
            <List.Item.Meta

                title={portlet.name}
                description={portlet.description}
            />
        </List.Item>
    }

}


export default withTranslation()(PortletListItem);
