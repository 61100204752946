// @flow
import React from "react";
import {List} from "antd";
import {Link} from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";
import EditableCell from "../../Utilities/EditableCell";
import ListTextItem from '../ListTextItem';

type Props = {
    history: any,
    onSelect: any,
    type: string,
    data: any,
    updateBookmarkMutation: any,
    deleteBookmarkMutation: any,
    onBookmarkClick?: (bookmark: any) => void
}

const BookmarkQueryList = (props: Props) => {

    const {history, onSelect, type, data, deleteBookmarkMutation, updateBookmarkMutation, onBookmarkClick} = props;

    const bookmarkId = queryString.parse(_.get(history, 'location.search', '')).selectionBookmark;

    return <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => {
            const data = JSON.parse(item.data);

            if(onSelect &&  ['serviceTicketSelection', 'componentSelection'].indexOf(item.type) >= 0){
                return <ListTextItem
                        bookmarkId={bookmarkId}
                        item={item}
                        data={data}
                        updateBookmarkMutation={updateBookmarkMutation}
                        onSelect={onSelect}
                        deleteBookmarkMutation={deleteBookmarkMutation}
                    />
            }

            return <List.Item className={'pv-5 ph-5 flex-row'}>
                <Link
                    key={item.id}
                    to={data && data.link ? data.link : ''}
                    className={'flex-grow-1'}
                    onClick={onBookmarkClick ? () => onBookmarkClick(item) : null}
                >
                    <EditableCell className={'full-width'} value={item.title}
                                  onRemove={() => {
                                      return deleteBookmarkMutation({
                                          variables: {
                                              id: item.id,
                                          }
                                      })
                                  }}
                                  onChange={(val) => {
                                      return updateBookmarkMutation({
                                          variables: {
                                              id: item.id,
                                              input: {
                                                  title: val
                                              }
                                          }
                                      })
                                  }}
                                  style={{
                                      color: '#494949'
                                  }}
                    />
                </Link>
            </List.Item>
        }}
    />
};

export default BookmarkQueryList
