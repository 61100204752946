// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {List, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from "lodash";
import {useDrop} from "react-dnd";
import {toastError, toastSuccess} from "../../../utils/toast";
import {Link} from "react-router-dom";
import './styles.less';

type Props = {
    item: any,
    bookmarkId: any,
    updateBookmarkMutation?: any,
    data: any,
    onSelect: any,
    deleteBookmarkMutation: any
}

const ListTextItem = (props: Props) => {

    const { item, bookmarkId, updateBookmarkMutation, data, onSelect, deleteBookmarkMutation } = props;
    const {t} = useTranslation();

    const [{DropClassName, IsOverName}, drop] = useDrop({
        accept: 'bookmark',
        collect: (monitor) => {

            return {
                IsOverName: monitor.isOver() ? 'is-over' : '',
                DropClassName: monitor.canDrop() ? 'can-drop' : '',
            }
        },
        drop: (droppedItem) => {
            if(droppedItem && droppedItem.id && item){
                let data = {};
                const bookmarkData = JSON.parse(item.data);
                let bookmarkIds = [];
                if(bookmarkData && bookmarkData.serviceTicketIds) bookmarkIds = bookmarkData.serviceTicketIds;
                if(bookmarkData && bookmarkData.componentIds) bookmarkIds = bookmarkData.componentIds;

                if(bookmarkData && bookmarkData.serviceTicketIds) {
                    data = {
                        serviceTicketIds: _.union(bookmarkIds, [droppedItem.id])
                    };
                }
                if(bookmarkData && bookmarkData.componentIds) {
                    data = {
                        componentIds: _.union(bookmarkIds, [droppedItem.id])
                    };
                }

                updateBookmarkMutation({
                    variables: {
                        id: item.id,
                        input: {
                            data: JSON.stringify({
                                link: bookmarkData.link,
                                ...data
                            })
                        }
                    }
                })
                    .then(() => {
                        toastSuccess(t('Successfully added'));
                    })
                    .catch(() => {
                        toastError(t('Fail to add'));
                    });
            }
        }
    });

    return <List.Item style={{padding: 0}}>
        <div ref={drop} style={{
            padding: '5px 5px'
        }}
             className={`flex-row full-width full-height ${IsOverName} ${DropClassName}`}
        >
            <Link key={item.id} to={item.access.read ? data && data.link ? data.link : '' : ''}
                  className={'flex-grow-1 editable-cell-text-wrapper'}>
                <span
                    style={{
                        color: !_.isNil(bookmarkId) && bookmarkId === item.id ? '#ee8126' : '#494949',
                        maxHeight: 60,
                        maxWidth: 150,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}
                >
                        {item.title}
                    </span>
            </Link>
            <div className="flex-row editable-cell-icon-wrapper">
                {
                    item.public
                        ? <Tooltip title={t('public')}>
                            <Icon
                                type="share-alt"
                            />
                        </Tooltip>
                        : null
                }
                {
                    item.access.read || item.access.write
                        ? <Icon
                            type="select"
                            onClick={() => onSelect(item.id)}
                        />
                        : null
                }
                {
                    item.access.remove
                        ? <Icon
                            type="delete"
                            onClick={() => {
                                return deleteBookmarkMutation({
                                    variables: {
                                        id: item.id,
                                    }
                                })
                            }}
                        />
                        : null
                }
            </div>
        </div>
    </List.Item>
};

export default ListTextItem
