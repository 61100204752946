// @flow
import React, {Component} from 'react';
import {Statistic} from 'antd';
import ServiceTicketListTotalQuery from "../../graphql/queries/serviceTicketListTotal.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";

type Props = {
  width?: number,
  height?: number,
  daterange?: {
    from: String,
    to: String
  }
};

type State = {

}

class FunctionalBlockRecons extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { previousTotalQuery, currentTotalQuery } = this.props;

    const currentTotal = _.get(currentTotalQuery, 'serviceTicketList.total');
    const previousTotal = _.get(previousTotalQuery, 'serviceTicketList.total');
    const changePercentage = (currentTotal / previousTotal - 1) * 100;


    return <div className={'flex flex-col flex-align-items-center mt-20'}>
      <span style={{fontSize: 40, fontWeight: 'bold'}}>{ currentTotal }</span>
      <div className={'flex-self-end'}>
        <Statistic
          value={changePercentage}
          decimalSeparator={','}
          precision={2}
          valueStyle={changePercentage > 0 ? { color: '#3f8600' } : { color: '#cf1322' }}
          suffix="%"
        />
      </div>
    </div>;
  }
}

export default compose(
  graphql(ServiceTicketListTotalQuery, {
    name: 'currentTotalQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['recon']},
            componentTypeIndex: {operator: 'in', value: ['functionalBlock']},
            update: {operator: 'range', range: props.daterange}
          },
        }
      }
    }),
  }),
  waitWhileLoading('currentTotalQuery', 'serviceTicketList.total'),
  graphql(ServiceTicketListTotalQuery, {
    name: 'previousTotalQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['recon']},
            componentTypeIndex: {operator: 'in', value: ['functionalBlock']},
            update: {operator: 'range', range: {
              from: new Date(new Date(props.daterange.from).getTime() - (new Date(props.daterange.to).getTime() - new Date(props.daterange.from).getTime())).toISOString(),
                to: new Date(new Date(props.daterange.from).getTime() - 60 * 60 * 24 * 1000).toISOString()
            }}
          },
        }
      }
    }),
  }),
  waitWhileLoading('previousTotalQuery', 'serviceTicketList.total'),
)(FunctionalBlockRecons);


