// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {compose, withProps} from "recompose";

import ContentHeader from "../../components/Typo/ContentHeader";
import ServiceBudgetList, {getParams} from "../../components/ServiceBudgetList";
import ServiceBudgetToolbar from "../../components/ServiceBudgetList/toolbar";
import queryString from "query-string";
import Toolbar from "../../components/Toolbar";
import NavigationContainer from "../../components/NavigationContainer";
import {connect} from "react-redux";
import {setServiceBudgetQuery} from "../../actions/settings";
import {Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import withResources from "../../hoc/withResources";
import ServiceBudgetExport from "../../components/ServiceBudgetExport";


type Props = {
  history: {
    push: Function,
  },
  match: any,
  t: Function,
};

class ServiceBudgetRoute extends Component<Props, State> {

  onClick = (params) => {
    if (!params) {
      setServiceBudgetQuery();
      this.props.history.push({
        search: ``
      })
    } else {
      const query = queryString.stringify(params);
      setServiceBudgetQuery(query);

      this.props.history.push({
        search: `?${query}`
      })
    }
  };


  render() {
    const {t, history, setQuery, filter} = this.props;


    return (

      <div className={"full-size"}>
        <NavigationContainer navigationBar={
          <div>
            <ContentHeader hideBreadcrumb title={t('Servicebudget')}/>
            <Toolbar
              borderBottom
              className={"white-bg"}
              renderLeft={() => <ServiceBudgetToolbar
                values={filter}
                onChange={(attributes, {allValues}) => {
                  const query = queryString.stringify(allValues);
                  setQuery(query);
                  history.push(`/service/budget?${query}`)
                }}
              />}
              renderRight={() => <div
                style={{width: 120, marginTop: 4, textAlign: 'right'}}>
                <Tooltip title={t("Reset")}>
                  <Button
                    style={{marginRight: 8}}
                    type={"danger"}
                    icon={"rollback"}
                    onClick={() => this.onClick()}
                  />
                </Tooltip>
                <ServiceBudgetExport params={getParams(filter)} />
              </div>}
            />
          </div>
        } >
          <ServiceBudgetList filterValues={filter}/>
        </NavigationContainer>
      </div>


    )
  }
}

export default compose(
  withResources('showServiceBudget'),
  withTranslation(),
  withRouter,
  withProps((props) => {
    let query = _.get(props, 'history.location.search');
    return {
      query
    }
  }),
  connect((state, props) => {

    let query = props.query;
    if (!query || !query.length) {
      query = _.get(state, 'settings.serviceBudgetQuery');
    }

    return {
      query,
    }
  }, dispatch => ({
    setQuery: (query) => {
      return dispatch(setServiceBudgetQuery(query))
    }
  })),
  withProps((props) => ({
    filter: queryString.parse(props.query)
  }))
)(ServiceBudgetRoute);
