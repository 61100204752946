// @flow
import React, {Component} from 'react';
import ComponentUserListQuery from "../../graphql/queries/componentUserList.graphql";
import UserListQuery from "../../graphql/queries/userList.graphql";
import AccessControlPicker from "../AccessControlPicker";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import UpdateComponentAclMutation from "../../graphql/mutations/UpdateComponentAcl.graphql";
import RemoveComponentAclMutation from "../../graphql/mutations/RemoveComponentAcl.graphql";
import AddUserToComponentMutation from "../../graphql/mutations/AddUserToComponentMutation.graphql";
import {withTranslation} from "react-i18next";

type Props = {
    componentId: string,
    addUserToComponentMutation: Function,
    updateComponentAclMutation: Function,
    removeComponentAclMutation: Function,
    componentPath: string
};

class ComponentUserAccess extends Component<Props, State> {

    render() {
        const {componentId, addUserToComponentMutation, updateComponentAclMutation, removeComponentAclMutation, componentPath} = this.props;

        return <AccessControlPicker
            showSearch
            addMutation={addUserToComponentMutation}
            updateMutation={updateComponentAclMutation}
            removeMutation={removeComponentAclMutation}
            translateMutationOptions={(method, id, input) => {

                const variables = {
                    input
                };

                if (method === 'create') {
                    variables.componentId = componentId;
                    variables.userId = id;
                }

                if (method === 'update') {
                    variables.id = id;
                }

                if (method === 'delete') {
                    variables.id = id;
                }

                return {
                    variables,
                    refetchQueries: [
                        {
                            query: ComponentUserListQuery,
                            variables: {
                                id: this.props.componentId
                            }
                        }
                    ]
                }
            }}
            selectDataLoader={{
                query: UserListQuery,
                dataKey: 'userList',
                getQueryVariables: (query) => {

                    return {
                        params: {
                            start: 0,
                            limit: 40,
                            search: {
                                query,
                                fields: ["name", "email"]
                            }
                        }
                    };
                },
                itemsKey: 'users',
                totalKey: 'total',
                textKey: 'name',
                valueKey: 'id',
            }}
            valuesDataLoader={{
                query: ComponentUserListQuery,
                getQueryVariables: () => ({
                    id: this.props.componentId
                }),
                dataKey: 'componentUserList',
                itemsKey: 'componentUsers',
                totalKey: 'total',
                textKey: 'user.name',
                valueKey: 'id',
                readKey: 'read',
                writeKey: 'write',
            }}
            componentPath={componentPath}
        />;
    }
}

export default compose(
    graphql(UpdateComponentAclMutation, {
        name: 'updateComponentAclMutation'
    }),
    graphql(RemoveComponentAclMutation, {
        name: 'removeComponentAclMutation'
    }),
    graphql(AddUserToComponentMutation, {
        name: 'addUserToComponentMutation',
    }),
    withTranslation(),
)(ComponentUserAccess);
