// @flow
import React, {Component} from 'react';
import StarredServiceTicketsList from "./../StarredServiceTicketsList";
import type {PortletInterface} from "./PortletInterface";


class StarredServiceTicketsPortlet extends Component<Props, State> implements PortletInterface {


    render() {


        return <StarredServiceTicketsList/>
    }
}

export default StarredServiceTicketsPortlet;
