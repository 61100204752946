// @flow
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import type {Query} from "../../types";
import _ from "lodash";
import QueryErrorIndicator from "../QueryErrorIndicator";
import {DatePicker, Input} from "antd";
import {Icon} from "@dreebit/react-components";
import Empty from "../Empty";
import {Waypoint} from "react-waypoint";
import Loader from "../Loader";
import Toolbar from "../Toolbar";
import TimeQueryListItem from "./item";
import moment from "moment";
import './index.css';

const { RangePicker } = DatePicker;

type Props = {
    query: Query,
    client: any,
    queryProps?: any,
    itemsKeyPath: string,
    totalKeyPath: string,
    limitKeyPath: ?string,
    startKeyPath: ?string,
    searchKeyPath: ?string,
    renderItem: (item: any, index: number) => any,
    style?: any,
    bordered?: boolean,
    pagingSize: ?number,
    listProps?: any,
    uniqueIdentifier?: any,
    onQueryMounted?: (queryLogList: any) => void,
    useSearch?: boolean,
    useDateRange?: boolean,
    onDateRangeChange?: any,
    dateRangeKeyPath?: string,
    empty?: any,
    dateRangeFromKeyPath?: string,
    dateRangeToKeyPath?: string,
    listStyle?: any,
    filter?: any,
    onSearchTextChanged?: any,
    scrollContainerRef?: any
};

type dateRangePaths = {
    dateRangeKeyPath?: string,
    dateRangeFromKeyPath?: string,
    dateRangeToKeyPath?: any
};

export const getDateRangeFromFilter = (filter?: any, paths?: dateRangePaths) => {
    if(!filter || !paths) return null;

    if(paths.dateRangeKeyPath) return _.get(filter, paths.dateRangeKeyPath, null);

    if(paths.dateRangeFromKeyPath && paths.dateRangeToKeyPath) {
        let from = _.get(filter, paths.dateRangeFromKeyPath, null);
        let to = _.get(filter, paths.dateRangeToKeyPath, null);

        if (from && to) {
            from = moment(from);
            to = moment(to);
        }

        return [from, to];
    }
};

const TimeQueryList = ({client, style, uniqueIdentifier, totalKeyPath, startKeyPath, searchKeyPath, query, pagingSize, listStyle, limitKeyPath, itemsKeyPath, bordered, onQueryMounted, renderItem, useDateRange, useSearch,
                       scrollContainerRef, dateRangeKeyPath, empty, dateRangeFromKeyPath, dateRangeToKeyPath, filter, queryProps, onSearchTextChanged, onDateRangeChange}: Props) => {
    const {t} = useTranslation();

    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [dateRange, setDateRange] = useState(getDateRangeFromFilter(filter, {dateRangeFromKeyPath, dateRangeToKeyPath}));
    const [searchText, setSearchText] = useState(null);

    useEffect(() => {
        setSearchText(searchText);
        refetch();
    }, [queryProps, searchText]);

    useEffect(() => {
        if(_.isEmpty(items)) fetchMore(0);
    }, [items]);

    const refetch = async () => {
        setItems([]);
    };

    let currentRequestId = null;
    const _debounceSearchTextChanges = _.debounce((text) => {
        if (onSearchTextChanged) onSearchTextChanged(text)
    }, 1000);
    const _debounceRefetch = _.debounce(refetch, 1100);

    const getVariablesForPage = (start, limit) => {
        const variables = Object.assign({},_.get(queryProps, 'variables', {}));
        _.set(variables, limitKeyPath, limit);
        _.set(variables, startKeyPath, start);
        return variables;
    };

    const handleSearch = (e: any, refetch: any) => {
        const text = _.get(e, 'currentTarget.value');
        setSearchText(text);

        _debounceSearchTextChanges(text);

        const variables = {
            ...queryProps.variables,
        };
        _.set(variables, searchKeyPath, text);

        _debounceRefetch(variables);
    };

    const handleWaypointEnter = () => {
        const length = items ? items.length : -1;

        if (length < total && total > 0) fetchMore(length)
    };

    const handleWaypointLeave = () => {};

    const fetchMore = (start) => {
        const variables = getVariablesForPage(start, pagingSize);

        if (start === 0){
            setLoading(true);
            setLoadingMore(false);
        }else {
            setLoading(false);
            setLoadingMore(true);
        }

        if (dateRange && dateRange.length){
            const range = _.compact(dateRange);
            if (range.length === 2){
                if(dateRangeKeyPath) _.set(variables, dateRangeKeyPath, range);
                if(dateRangeFromKeyPath && dateRangeToKeyPath) {
                    _.set(variables, dateRangeFromKeyPath, _.first(range));
                    _.set(variables, dateRangeToKeyPath, _.last(range));
                }
            }
        }

        currentRequestId = _.uniqueId('QueryListAdvancedRequest_');
        const newCurrentRequestId = `${currentRequestId}`;
        // @ts-ignore
        client.query({
            query,
            ...queryProps,
            variables
        }).then((q)=> {
            if (currentRequestId === newCurrentRequestId){
                setItems(_.uniqBy([...items, ..._.get(q, `data.${itemsKeyPath}`)], uniqueIdentifier));
                setTotal(_.get(q, `data.${totalKeyPath}`,-1));
                setLoading(false);
                setLoadingMore(false);
            }
        }).catch(() => {
            setLoading(false);
            setLoadingMore(false);
        })
    };

    const handleChangeDateRange = (value: [string, string]) => {
        if(onDateRangeChange) onDateRangeChange(value);
        setDateRange(value);
        refetch();
    };

    const getToolbarLeftContent = () => {
        if(useSearch) return <Input className={"no-border"}
                                    data-cy={'TEST_queryListAdvancedSearchInput'}
                                    allowClear
                                    value={searchText}
                                    suffix={<Icon type={"search"}/>}
                                    onChange={(e) => handleSearch(e, refetch)}
                                    placeholder={t("Search")}/>;

        return null;
    };
    const getToolbarRightContent = () => {
        if(useDateRange) {
            return <RangePicker
                format={'LL'}
                value={dateRange}
                onChange={handleChangeDateRange}
            />;
        }

        return null;
    };

    return <div
        className={'flex flex-column full-height'}
        style={{
            ...style
        }}
    >
        <div
            style={{float: "right", zIndex: 1, position: "relative", padding: "0 16px"}}
        >
            <QueryErrorIndicator
                placement={"left"}
                query={query}
            />
        </div>
        {
            useDateRange || useDateRange
                ? <Toolbar
                    renderLeft={getToolbarLeftContent}
                    renderRight={getToolbarRightContent}
                />
                : null
        }
        {
            loading && !loadingMore
                ? <Loader size={'small'}/>
                : items.length
                    ? <div className={'flex-grow-1 timeQueryListWrapper'}
                           ref={scrollContainerRef}
                           style={{
                               overflow: 'auto',
                               ...listStyle
                           }}
                    >
                        <TimeQueryListItem
                            items={items}
                            renderItem={renderItem}
                        />
                        {
                            loadingMore ? <Loader size={"small"}/> : null
                        }
                        {
                            !loading && items && items.length && total && items.length < total && items.length > 0
                                ? <div className={'wa'}>
                                    <Waypoint
                                        onEnter={handleWaypointEnter}
                                        onLeave={handleWaypointLeave}
                                    />
                                </div>
                                : null
                        }
                    </div>
                : <div
                    style={{padding: 8}}
                >
                    {empty || <Empty/>}
                </div>
        }
    </div>;
};

TimeQueryList.defaultProps = {
    pagingSize: 15,
    limitKeyPath: 'params.limit',
    startKeyPath: 'params.start',
    searchParamKeyPath: 'params.search',
    searchKeyPath: 'params.search.query',
    searchFieldsKeyPath: 'params.search.fields',
    queryProps: {},
    uniqueIdentifier: 'id'
};

export default TimeQueryList
