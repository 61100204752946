// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import ProfileQuery from "./../../graphql/queries/profileSessions.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ContentHeader from "../Typo/ContentHeader";
import ContentWrapper from "../ContentWrapper";
import AccessTokenListTable from "../AccessTokenListTable";
import Panel from "../Panel";

type Props = {
    query?: any[],
};


class ProfileSessions extends Component<Props, State> {

    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader title={t('Sessions')}/>
            <ContentWrapper>
                <Panel containerStyle={{padding: 0}}>

                    <AccessTokenListTable/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ProfileQuery, {
        name: 'ProfileQuery',
        options: () => ({
            variables: {}
        }),
    }),
    waitWhileLoading('ProfileQuery'),
    withProps((props) => ({
        query: _.get(props, 'ProfileQuery'),
    }))
)(ProfileSessions);
