// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import ReduxAttributeForm from "../ReduxAttributeForm";
import {formTCOComponentSettings} from "../../utils/formName";
import {withTranslation} from "react-i18next";

type Props = {};

type State = {

}

class TCOCalculationSettings extends Component<Props, State> {

  render() {
    const { componentId, t } = this.props;

    return <ReduxAttributeForm
        index={formTCOComponentSettings(componentId)}
        submitOnChange
        layout={'inline'}
        useSubmit={false}
        disableToasts
        attributes={[
          {
            index: 'considerServiceTickets',
            inputType: 'switch',
              type: 'bool',
            name: t('Consider Service Tickets')
          },
            {
                index: 'includeSubComponents',
                inputType: 'switch',
                type: 'bool',
                name: t('Include sub components')
            },
            {
                index: 'date',
                inputType: 'daterange',
                type: 'string',
                name: t('Date range')
            }
        ]}
    />
  }
}

export default compose(
  withTranslation()
)(TCOCalculationSettings);
