import React from "react";
import {Alert} from "antd";
import {useTranslation} from "react-i18next";
import MutationButton from "../MutationButton";
import CreateComponentMoveTokenMutation from "./../../graphql/mutations/createComponentMoveToken.graphql";
import {useMutation} from "@apollo/client";
import ComponentMoveTokenInfoText from "../ComponentMoveTokenInfoText";

interface Props {
    componentId: string,
    onDone?: (res: any) => void
}

const ComponentTokenCreateForm = ({componentId, onDone}: Props) => {

    const {t} = useTranslation();

    const [mutation] = useMutation(CreateComponentMoveTokenMutation, {
        variables: {
            componentId
        }
    });

    return  <Alert
        message={t("No access token available")}
        description={<div>

            <ComponentMoveTokenInfoText/>
            <div className={"text-center"} style={{marginLeft: -64, paddingTop: 12}}>
                <MutationButton mutation={mutation} onDone={onDone} confirm buttonProps={{type: 'primary'}}>
                    {t("Create")}
                </MutationButton>

            </div>

        </div>}
        type="warning"

        showIcon
    >

    </Alert>
};

export default ComponentTokenCreateForm;
