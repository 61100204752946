// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Alert, Card, Col, Row} from "antd";
import {Button} from "@dreebit/react-components";
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ComponentPicker from "../Form/fields/ComponentPicker";
import MoveComponentMutation from "./../../graphql/mutations/MoveComponent.graphql";
import {toastError, toastMessage} from "../../utils/toast";
import ComponentInfoBox from "../ComponentInfoBox";
import ComponentPathQuery from "../../graphql/queries/ComponentPath.graphql";
import {withRouter} from "react-router-dom";

type Props = {
    componentId: string
};

type State = {
    selectedId: string,
    loading: boolean,
}

class ComponentMove extends Component<Props, State> {

    state = {
        selectedId: null,
        loading: false,
    };


    _handleChange = (selectedId) => {
        this.setState({
            selectedId
        })
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    _handleClick = () => {
        const {moveComponentMutation, componentId, t, client, history} = this.props;

        if (moveComponentMutation) {
            this.setLoading(true)
                .then(() => moveComponentMutation({
                    variables: {
                        parentComponentId: this.state.selectedId,
                        componentId
                    }
                }))
                .then(() => toastMessage(t('Component was moved')))
                .then(() => this.setLoading(false))
                .then(() => history.push(`/infrastructure/${componentId}`))
                .catch(toastError)
        }
    };

    render() {
        const {componentId, t} = this.props;
        const {selectedId} = this.state;

        return <Card actions={[<Button disabled={!this.state.selectedId} onClick={this._handleClick}
                                       className={"mt-10 flex-self-center"} type={'primary'}
                                       loading={this.state.loading}>{t('Move')}</Button>]}>
            <Row>
                <Col sm={10}>
                    <div className={"border"}>
                        <ComponentInfoBox componentId={componentId}/>
                    </div>

                </Col>
                <Col className={'text-center'} sm={4}>
                    <div>
                        <FontAwesomeIcon size={'2x'} name={"long-arrow-alt-right"}/>
                    </div>
                </Col>
                <Col sm={10}>

                    {
                        selectedId ? <div className={"border"}><ComponentInfoBox componentId={selectedId}/></div> :
                            <Alert message={t('Please select a target')}/>
                    }

                    <div className={"text-left mt-20"}>
                        <ComponentPicker titleOnly title={t('Select target')} value={selectedId}
                                         onChange={this._handleChange}/>
                    </div>

                </Col>
            </Row>
        </Card>
    }
}

export default compose(
    withApollo,
    graphql(MoveComponentMutation, {
        name: 'moveComponentMutation',
        options: props => ({
            refetchQueries: [{
                query: ComponentPathQuery,
                variables: {
                    id: props.componentId
                }
            }]
        })
    }),
    withTranslation(),
    withRouter
)(ComponentMove);
