import React from "react";
import useDigitInput from 'react-digit-input';
import "./index.less"
import {Button} from "@dreebit/react-components";
import {useTranslation} from "react-i18next";
import {Row, Col} from "../Flex";

interface Props {
    token?: string,
    useSubmitButton?: boolean,
    showError?: boolean,
    showSuccess?: boolean,
    onChange: (token: string, valid: boolean) => void;
    onEnter: (token: string, valid: boolean) => void;
    submitTitle?: string,
    className?: string,
    successMessage?: string
}

const TokenInput = (props: Props) => {

    const count = 5;
    const [value, onChange] = React.useState(null);
    const {t} = useTranslation();
    const currentValue = value || props.token;
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9a-zA-Z]$/,
        length: count*count,
        value: currentValue || '',
        onChange: (val) => {
            const token = val.replace(/ /g,"");
            onChange(token);
            if (props.onChange){
                props.onChange(token, token && token.length === count*count)
            }
        },

    });

    const counter = Array(count).fill().map((_, index) => {
        return index
    });

    const disabled = props.showError || (!currentValue || currentValue.length !== count*count);

    const onSubmit = () => {
        if(props.onEnter){
            props.onEnter(value, !disabled)
        }
    };

    return <div className={`${props.className} token-input input-group ${props.showError ? 'error' : ''} ${props.showSuccess ? 'success' : ''}`} onKeyDown={(event) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }}>
        <Row className={'overflow-auto'}>
            { counter.map((i, index: any) => {
                return <Row className={'flex-align-items-center flex-center'}>
                    {counter.map((j, index: any) => {
                        return <input key={index} {...digits[i*count+j]} />
                    })}
                    {i < count-1 ? <span key={index} className="hyphen" /> : null}
                </Row>
            }) }
        </Row>

        {
            disabled
                ? <span className={'error-color'}>
                    {t('Please insert a valid token.')}
                </span>
                : null
        }

        {
            props.successMessage && !disabled
                ? <span className={'success-color'}>
                    {props.successMessage}
                </span>
                : null
        }
        { props.onEnter ? <Button disabled={disabled} onClick={onSubmit} className={"ml-10"} size={'small'} type={'primary'}>{props.submitTitle ? props.submitTitle : t("Submit")}</Button> : null}
    </div>;
};

export default TokenInput;
