// @flow
import React, {Component} from 'react';
import Panel from "../Panel";
import ComponentPicker from "../Form/fields/ComponentPicker";
import ComponentInfoBox from "../ComponentInfoBox";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ReconComponentPathBreadcrumb from "../ReconComponentPathBreadcrumb";
import {withTranslation} from "react-i18next";
import DashedContainer from "../DashedContainer";

type Props = {
    title?: string,
    componentData?: any,
    componentId: string,
    pathComponentId: string,
    onComponentChange?: (id: string) => void,
    onPathComponentChange?: (id: string) => void
};

type State = {
    id : String
}

class ReconComponentContainer extends Component<Props, State> {
    state= {
        id: this.props.componentId
    };

  render() {
    const { onComponentChange,title, onPathComponentChange, componentId, path, t, componentData } = this.props;
    const {id} = this.state;

    return <div>
        <Panel title={title}
               topRight={!onComponentChange ? <div style={{paddingBottom: '10px', height: 42}}/> : <div style={{paddingBottom: '10px'}}>
                   <ComponentPicker
                       showOnlyComponent={true}
                       hideDeleteButton={true}
                       titleOnly={true}
                       title={t('Edit')}
                       value={id ? id : 0}
                       onChange={(id) => {
                           this.setState({
                               id: id
                           });
                           onComponentChange(id)
                       }}
                   />
               </div>}>

            {componentId
                ? <div>
                    <ComponentInfoBox
                        componentData={componentData}
                        disableBreadcrumb
                        componentId={componentId}/>
                </div>
                : <DashedContainer>
                    {
                        id
                            ? <div className={"bg-white text-center"} style={{height: 180, padding: 10}}>
                                <div style={{textAlign: 'left'}}>
                                    <ComponentInfoBox
                                        disableBreadcrumb
                                        componentId={id}/>
                                </div>
                            </div>
                            : <div className={"bg-white text-center"} style={{height: 100, padding: 10}}>
                                <h4 className={"pv-5"}>
                                    {t('Select a component for replacement')}
                                </h4>
                                <hr/>
                                <div className={"pt-5"}>
                                    <ComponentPicker
                                        value={id ? id : 0}
                                        showOnlyComponent
                                        onChange={(id) => {
                                            this.setState({
                                                id: id
                                            });
                                            onComponentChange(id)
                                        }}/>
                                </div>
                            </div>
                    }
            </DashedContainer>
            }


        </Panel>
        <div style={{width: '100%', textAlign: 'center', marginTop: 10}}>
            <FontAwesomeIcon name={'arrow-down'} size={"3x"}/>
        </div>
        <Panel title={t('Path after recon')}
               topRight={!onPathComponentChange ? null : <div style={{paddingBottom: '10px'}}><ComponentPicker
                   title={t('Edit')}
                   onChange={(id) => {
                       onPathComponentChange(id)
                   }}/></div>}>
            {path ? <ReconComponentPathBreadcrumb path={path}/> : <span>{t('Please select a component')}</span>}

        </Panel>
    </div>;
  }
}

export default withTranslation()(ReconComponentContainer);
