// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import CreateServiceReportMutation from "../../graphql/mutations/createServiceReport.graphql";
import {withRouter} from "react-router-dom";
import AttributesInlineForm from "../Form/attributes-inline-form";
import styled from "styled-components";
import withResources from "../../hoc/withResources";
import ServiceReportListQuery from "../../graphql/queries/serviceReportList.graphql";


type Props = {
  serviceTicketId: string,
  componentType: string,
  onCreateServiceReport: (serviceTicketId: string, serviceReportId: string) => void,
  typeDefinitionAttributeListQuery: query,
  t: Function
};

const Wrapper = styled.div`
  background: white;
  margin: 16px;
  padding: 10px;
`;

class ServiceReportCreate extends Component<Props> {

  render() {
    const {
      onCreateServiceReport,
      serviceTicketId,
      typeDefinitionAttributeListQuery,
      createServiceReportMutation,
      t
    } = this.props

    const attributes = _.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes')

    return <Wrapper>
      <AttributesInlineForm
          attributes={attributes}
          t={t}
          useSubmit={true}
          onSubmit={(res) => {
            const serviceTicketId = _.get(res, 'data.createServiceReport.serviceTicket.id');
            const createServiceReportId = _.get(res, 'data.createServiceReport.id');

            onCreateServiceReport(serviceTicketId, createServiceReportId);
          }}
          mutation={createServiceReportMutation}
          translateMutationOptions={({values}) => ({
            variables: {
              serviceTicketId: _.get(this.props, 'match.params.id'),
              input: values
            }
          })}
      />
    </Wrapper>;
  }
}

export default compose(
  withRouter,
  withResources(["serviceReports"]),
  graphql(TypeDefinitionAttributeListQuery, {
    name: 'typeDefinitionAttributeListQuery',
    options: () => ({
      variables: {
        index: 'serviceReport'
      }
    })
  }),
  waitWhileLoading('typeDefinitionAttributeListQuery', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
  graphql(CreateServiceReportMutation, {
    name: "createServiceReportMutation",
    options: (props: Props) => ({
      refetchQueries: [{
        query: ServiceReportListQuery,
        variables: {
          serviceTicketId: _.get(props, 'serviceTicketId')
        }
      }]
    })
  }),
  withTranslation(),
)(ServiceReportCreate);
