// @flow
import React, {useState} from 'react';
import _ from "lodash";
import {useTranslation} from "react-i18next";
import ProductFilterList from "../ProductFilterList";
import {Modal} from "antd";
import ProductMatchPerformer from "../ProductMatchPerformer";
import ProductInfoQuery from "../../graphql/queries/ProductInfo.graphql";
import {useHistory} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {Loader} from "@dreebit/react-components";

interface Props {
    productId: string
}

const ProductMatch = ({productId}: Props) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [visible, setVisible] = useState(false);
    const [targetId, setTargetId] = useState(undefined);

    const productInfoQuery = useQuery(ProductInfoQuery, {
        variables: {
            id: productId
        }
    })

    const filterValues = {
        query: _.get(productInfoQuery,'data.product.catalogNo',_.get(productInfoQuery,'data.product.name')),
        fields: ["catalogNo","name"],
        manufacturerId: _.get(productInfoQuery,'data.product.manufacturer.id', undefined),
        status: "published"
    }

    if (productInfoQuery.loading) {
        return <Loader/>
    }

    return (
        <div>
            <ProductFilterList
                filterValues={filterValues}
                tableProps={{
                    scroll: { y: 500 }
                }}
                onProductSelect={(productId) => {
                    setTargetId(productId)
                    setVisible(true)
                }}
            />
            <Modal
                title={t("Match Product")}
                visible={visible && targetId}
                footer={null}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <ProductMatchPerformer
                    onMatched={() => {
                        const target = targetId;

                        setVisible(false)
                        setTargetId(null)
                        history.push(`/catalog/products/${target}`)
                    }}
                    sourceId={productId}
                    targetId={targetId}
                />
            </Modal>
        </div>
    );
}

export default ProductMatch;
