// @flow
import React, {Component} from 'react';
import {Avatar, List} from 'antd';
import {withTranslation} from "react-i18next";
import type {FilePreview} from "../../types";
import {bytesToSize} from "../../utils/fileSize";
import MutationButton from "../MutationButton";

type Props = {
    file: FilePreview,
    onRemove: (file: FilePreview) => Promise<any>
};

class FilePreviewListItem extends Component<Props, State> {


    render() {
        const {onRemove, file, t} = this.props;

        const actions = [<a target={"_BLANK"} download href={file.link}>{t('Download')}</a>];

        if (onRemove) {
            actions.push(<MutationButton mutation={() => onRemove(file)} buttonProps={{type: 'link'}} >{t('Remove')}</MutationButton>)
        }

        return <List.Item actions={actions}>
            <List.Item.Meta
                avatar={<Avatar src={file.thumbnailLink}/>}
                title={<a target={"_BLANK"} href={file.link}>{file.filename}</a>}
                description={`${bytesToSize(file.size)}`}
            />
        </List.Item>
    }
}

export default withTranslation()(FilePreviewListItem);

