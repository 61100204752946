const externalUrls = {
    testing: {
        pvWebsite: "https://www.dev.customerportal.pfeiffer-vacuum.com"
    },
    staging: {
        pvWebsite: "https://www.uat.customerportal.pfeiffer-vacuum.com"
    },
    production: {
        pvWebsite: "https://www.pfeiffer-vacuum.com"
    }
}

export default externalUrls
