// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import CatalogAttributesListQuery from "./../../graphql/queries/CatalogAttributesList.graphql";
import {List} from "antd";
import QueryList from "../QueryList";
import {Link} from "react-router-dom";
import statics from "../../statics";

type Props = {
    id: string,
};

class CatalogAttributesList extends Component<Props, State> {

    render() {
        const {catalogAttributesListQuery} = this.props;

        return <div>
            <QueryList
                query={catalogAttributesListQuery}
                uniqueIdentifier={'index'}
                itemsKeyPath={"catalogAttributeList.catalogAttributes"} totalKeyPath={'catalogAttributeList.total'}
                renderItem={(item) => {
                    return <Link key={item.index}
                                 to={`/catalog/attributes/${item.index}`}><List.Item>{item.name}</List.Item></Link>
                }}
                limitKeyPath={"params.limit"} startKeyPath={"params.start"}/>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(CatalogAttributesListQuery, {
        name: 'catalogAttributesListQuery',
        options: () => ({
            variables: {
                params: {
                    start: 0,
                    limit: statics.defaultPagingSize,
                    sort: {
                        name: 'ASC'
                    }
                }
            }
        }),
    }),
)(CatalogAttributesList);
