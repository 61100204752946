// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {withApollo} from "@apollo/client/react/hoc";
import ComponentMultiMove from "../ComponentsMultiMove";
import ComponentMultiDelete from "../ComponentsMultiDelete";
import {Col, Row} from "antd";
import ComponentsMultiForm from "../ComponentsMultiForm";
import ComponentsMultiServiceTicketCreate from "../ComponentsMultiServiceTicketCreate";

type Props = {
  preferredServiceTicketType?: string,
  componentIds: string,
  onDone: Function,
};


class ComponentsMultiAction extends Component<Props, State> {

  state = {
    selectedAction: "move"
  };

  render() {
    const {componentIds, t, onDone, client} = this.props;
    const {selectedAction} = this.state;

    const actions = [
      {
        index: 'edit',
        name: t('Edit'),
        render: (componentIds) => {

          return <ComponentsMultiForm
              componentIds={componentIds}
              onFinished={(res) => {
                return client.clearStore().then(() => onDone ? onDone(res) : null);

              }}/>
        },
      },
      {
        index: 'move',
        name: t('Move'),
        render: (componentIds) => {

          return <ComponentMultiMove
              componentIds={componentIds}
              onFinished={(res) => {
                return client.clearStore().then(() => onDone ? onDone(res) : null);

              }}/>
        }
      },
      {
        index: 'delete',
        name: t('Delete'),
        render: (componentIds) => {
          return <Row>
            <Col offset={8}>
              <ComponentMultiDelete onFinished={(res) => {
                return client.clearStore().then(() => onDone ? onDone(res) : null);
              }} componentIds={componentIds} />
            </Col>
          </Row>
        }
      }
    ];

    const host = window["__HOST__"];
    if (host !== "xenon") {
      actions.push(
          {
            index: 'createTicket',
            name: t('Create service ticket'),
            render: (componentIds) => {
              return <ComponentsMultiServiceTicketCreate
                  onFinished={(res) => {
                    return client.clearStore().then(() => onDone ? onDone(res) : null);
                  }}
                  componentIds={componentIds}
              />
            }
          }
      )
    }

    const actionItem = _.find(actions, {index: selectedAction});

    return <div>
      <p>
        {t('The following actions ')}
      </p>
      <AttributesInlineForm
          attributes={[
            {
              index: 'actionIndex',
              name: t('Action'),
              type: 'string',
              inputType: 'select',
              optionValues: actions.map(item => ({
                value: item.index,
                name: item.name
              })),
              value: selectedAction
            }
          ]}
          onChange={(values) => {

            this.setState({
              selectedAction: values.actionIndex
            })
          }}
          useSubmit={false}
      />

      {actionItem ? <div>
        <hr/>
        {actionItem.render(componentIds)}</div> : <div className={"ant-row"}>
        <div
            className={"pv-10 ant-col-md-24 ant-col-lg-offset-7 ant-col-lg-17"}>{t('Please select an action')}</div>
      </div>}
    </div>;
  }
}

export default compose(
    withTranslation(),
    withApollo,
)(ComponentsMultiAction);
