import moment from 'moment';
import _ from "lodash";

export function isDateInNearFuture(date, days: number, refDate = new Date()) {
    if (moment(date).diff(refDate, 'days') <= days) {
        return true;
    } else {
        return false;
    }
}


export function isDateInPast(date) {
    let d = date;
    if (_.isString(date)) {
        d = new Date(date);
    }
    if (d < new Date()) {
        return true;
    } else {
        return false;
    }
}