// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import Panel from "../Panel";
import _ from "lodash";
import {Col, Menu, Row, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";

import AccessToken from "../../graphql/queries/accessToken.graphql";
import UpdateAccessToken from "../../graphql/mutations/updateAccessToken.graphql";
import AttributesInlineForm from "../Form/attributes-inline-form";
import MenuButton from "../MenueButton";
import JsonTree from "../JsonTree";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import MatchBookmarksModal from "../MatchBookmarksModal";
import Bold from "../Utilities/Bold";
import CopyCode from "../CopyCode";


type Props = {
    id: any,
    updateAccessTokenMutation: Function,
    t: Function,
    accessTokenQuery: query,
}

type State = {
    showModal: boolean,
}

class ConnectorDashboard extends Component<Props, State> {
    state = {
        showModal: false,
    };

    _hideModal = () => {
        this.setState({
            showModal: false
        })
    };

    _showModal = () => {
        this.setState({
            showModal: true
        })
    };


    render() {
        const {t, updateAccessTokenMutation, accessTokenQuery, id} = this.props;

        const data = _.get(accessTokenQuery, 'accessToken', {});
        const attributes = _.get(data, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);
        const nameAttributes = [_.find(attributes, {'index': 'name'})];

        return <div>
            <Panel title={t('Edit')} style={{margin: 13}}>
                <Row className={'mb-10'} gutter={16}>
                    <Col className="gutter-row text-right pv-5" span={7}>
                        <Bold>Token:</Bold>
                    </Col>
                    <Col className="gutter-row" span={17}>
                        <CopyCode code={_.get(accessTokenQuery, 'accessToken.token')}/>
                    </Col>
                </Row>
                <AttributesInlineForm
                    attributes={nameAttributes}
                    layout={'horizontal'}
                    useSubmit={true}
                    t={t}
                    mutation={updateAccessTokenMutation}
                    translateMutationOptions={({values}) => ({
                        variables: {
                            id: data.id,
                            attributes: values
                        }
                    })}
                />
            </Panel>
            <div style={{display: 'flex'}}>
                <Panel title={<span>{t('Data')} <Tooltip placement="topLeft"
                                                         title={t('Select any data entry to show delete options. ') + t('Please restart the device after change any data')}>
                        <Icon style={{color: '#f58524'}} type="info-circle"/></Tooltip></span>}
                       style={{width: '100%', margin: 13}}
                       topRight={<MenuButton style={{paddingBottom: 13}} title={t('Options')} menu={
                           <Menu onClick={this._showModal}>
                               <Menu.Item key="1">
                                   <Icon type="plus"/>
                                   {t('add bookmark')}
                               </Menu.Item>
                           </Menu>
                       }/>}>

                    <div style={{marginLeft: '10px'}}>
                        <JsonTree data={data} id={id}/>
                    </div>
                </Panel>
            </div>
            <MatchBookmarksModal showModal={this.state.showModal} onClose={this._hideModal} data={data}/>
        </div>
    }
}

export default compose(
    graphql(UpdateAccessToken, {
        name: 'updateAccessTokenMutation',
        options: props => ({
            refetchQueries: [{
                query: AccessToken,
                variables: {
                    id: props.id
                }
            }]
        })
    }),
    graphql(AccessToken, {
        name: 'accessTokenQuery',
        options: (props: Props) => ({
            variables: {
                id: props.id
            }
        }),
    }),
    waitWhileLoading('accessTokenQuery', 'accessToken'),
    withTranslation(),
)(ConnectorDashboard);
