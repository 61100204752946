// @flow
import React, {Component} from "react";
import _ from "lodash";
import {connect} from 'react-redux';
import {Redirect, withRouter} from "react-router-dom";
import {compose} from "recompose";
import logo from '../../assets/logo/virtual-service-management-w240.png';
import AttributesInlineForm from "../Form/attributes-inline-form"
import PasswordResetButton from "../PasswordResetButton";
import login from "../../utils/login";
import {login as saveLogin} from "../../actions/auth";
import {withTranslation} from "react-i18next";
import {checkForValidMail} from "../../utils/email";
import checkHosts from "../../utils/hosts";
import withTheme from "../../hoc/withTheme";
import {Divider} from "antd";
import {Button} from "@dreebit/react-components";
import "./index.css";
import KeycloakService from "../../service/keycloak";


type Props = {
    cookies: any[],
    redirect: string,
    history: any,
    email?: String
}

type State = {
    changes: {
        email?: string,
        password?: string,
    },
    keycloak?: any,
    hosts?: any[],
    host: any,
    continueClicked?: boolean
}

let registration = false;
if (window.__REGISTRATION__ && window.__REGISTRATION__ !== '{{REGISTRATION}}') {
    registration = window.__REGISTRATION__ === 'true';
}

class Login extends Component<Props, State> {
    state = {
        changes: {
            email: _.get(this.props, 'email'),
            password: null
        },
        keycloak: null,
        continueClicked: false
    };

    componentDidMount() {
        if (this.state.changes.email && checkForValidMail(this.state.changes.email.toLowerCase())) {
            this.checkEmail(this.state.changes.email.toLowerCase())
        }
    }

    handleKeycloakLogin = () => {
        const {i18n} = this.props;

        KeycloakService.getInstance().login({
            locale: i18n.language,
            loginHint: this.state.changes.email
        })
    }

    checkEmail = (email?: string) => {

        checkHosts(email)
            .then((data) => {
                const hosts = _.get(data, 'hosts', []);
                const host = _.find(hosts, {url: window.location.origin});
                const keycloak = _.get(host, 'keycloak', process.env.NODE_ENV === 'development' && email === "uwe-unterdruck@vacuum.de" ? {} : null);

                return this.setState({
                    keycloak,
                    hosts,
                    host,
                    changes: {
                        email
                    },
                    continueClicked: false
                })
            })
    };

    onSubmit(values) {
        return login(values.email, values.password)
            .then(({token, message}) => {
                const {localLogin} = this.props;

                localLogin({
                    user: {},
                    token
                });
            })
    }

    render() {
        const {t, i18n, themeConfig, token, redirect} = this.props;
        const {changes, hosts, host, keycloak, continueClicked} = this.state;
        const {email, password} = changes;

        if (token) {
            return <Redirect to={redirect || '/'}/>
        }

        const keycloakInstance = KeycloakService.getInstance();
        const loginLogo = themeConfig["@login-logo"] || logo;

        let lang = i18n.language;
        try {
            lang = _.first(lang.split('-'));
        } catch (e) {
            lang = 'en';
        }


        let attributes = [
            {
                name: t('Email'),
                index: 'email',
                placeholder: "E-Mail",
                type: 'string',
                inputType: 'text',
                rules: [{name: 'required'}, {name: 'email'}]
            }
        ];

        if (!keycloakInstance || (continueClicked && hosts && !keycloak)) attributes.push({
            name: t('Password'),
            index: 'password',
            placeholder: t('Password'),
            type: 'string',
            inputType: 'password',
            rules: [{name: 'required'}]
        });

        return <div className="form login bg-white">
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center' ,marginBottom: 10}}>
                <img className="logo" alt={'logo'} src={loginLogo}/>
            </div>

            <div className={'title pb-20 LoginText'}>
                {
                    keycloak || !hosts
                        ? t('Please enter your email address')
                        : t('Please enter your password')
                }
            </div>

            <AttributesInlineForm
                values={changes}
                onChange={(values) => {
                    const lowerCaseMail = _.get(values, 'email', '').toLowerCase();

                    if (checkForValidMail(lowerCaseMail)) {
                        return this.checkEmail(lowerCaseMail);
                    }

                    const newChanges = {
                        ...changes,
                        ...values
                    };

                    this.setState({
                        changes: newChanges
                    })
                }}
                attributes={attributes}
                hideLabels
                disableToasts
                hideRequiredStar
                fullWidthSubmit
                layout={'vertical'}
                errorPresentationMode={'box'}
                defaultFormItemLayout={{
                    labelCol: {
                        md: {span: 0},
                        lg: {span: 0},
                    },
                    wrapperCol: {
                        md: {span: 24},
                        lg: {span: 24},
                    }
                }}
                submitTitle={!keycloakInstance || (continueClicked && hosts && !keycloak) ? t('Login') : t('Continue')}
                disableSubmitButton={!email || !checkForValidMail(email)}
                submitButtonWrapperStyle={{paddingLeft: 10}}
                submitButtonProps={{className: !keycloakInstance || (continueClicked && hosts && !keycloak) ? 'login' : 'continue'}}
                translateMutationOptions={({values}) => values}
                mutation={!keycloakInstance || (continueClicked && !keycloak && password) ? (values) => this.onSubmit(values) : keycloak ? () => this.handleKeycloakLogin() : () => this.setState({continueClicked: true})}
            />

            {
                !keycloakInstance || (continueClicked && hosts && !keycloak)
                    ? <PasswordResetButton email={this.state.changes.email}/>
                    : null
            }

            {
                registration
                    ? <>
                        <Divider/>
                        <div className={'registerButton'}>
                            <a href={`/${lang}/register`}>
                                <Button>
                                    {t('Create new Account')}
                                </Button>
                            </a>
                        </div>
                    </>
                    : null
            }
        </div>;
    }
}

export default compose(
    connect((state) => {
        return {
            token: _.get(state,'auth.token')
        }
    }, (dispatch) => ({
        localLogin: ({user, token}) => dispatch(saveLogin(user, token))
    })),
    withRouter,
    withTranslation(),
    withTheme()
)(Login);
