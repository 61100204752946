import React from 'react';
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ComponentNameQuery from "../../graphql/queries/component-Name.graphql";
import SubComponentsDropdown from "../../components/SubComponentsDropdown";
import {withProps} from "recompose";
import {Link} from "react-router-dom";


const ComponentWithSubcomponentsBreadcrumb = ({query, t, componentId}) => {

    return <Link to={`/infrastructure/${_.get(query, 'component.id')}`}>
        {_.get(query, 'component.name', '...')} <SubComponentsDropdown componentId={componentId}/>
    </Link>
}

export default compose(
    withProps((props) => {
        return {
            componentId: _.get(props, 'match.params.componentId', _.get(props, 'match.params.id'))
        }
    }),
    graphql(ComponentNameQuery, {
        name: 'query',
        options: (props) => {
            return {
                variables: {
                    id: _.get(props, 'componentId'),
                }
            }
        }
    }),
    withTranslation()
)(ComponentWithSubcomponentsBreadcrumb);
