// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import DeleteComponentTypeFromErrorPatternMutation
    from "./../../graphql/mutations/removeComponentTypesFromErrorPattern.graphql"
import ErrorPatternComponentTypesQuery from "./../../graphql/queries/errorPatternComponentTypes.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import QueryTable from "../QueryTable";
import {withTranslation} from "react-i18next";
import {Icon} from "@dreebit/react-components";

type Props = {
    errorPatternId: string
};

type State = {

}

class ErrorPatternComponentTypes extends Component<Props, State> {

  render() {
    const { query,t, deleteComponentTypeFromErrorPatternMutation, errorPatternId } = this.props;

    return <div>
        <QueryTable
            itemsKeyPath={'errorPattern.componentTypeList.componentTypes'}
            totalKeyPath={'errorPattern.componentTypeList.total'}
            query={query}
            rowKey={'id'}
            columns={[{
                dataIndex: 'name',
                title: t('Name')
            },{
                dataIndex: 'index',
                width: 40,
                render: (text) => <Icon type={'close'} className={"pointer"} onClick={() => deleteComponentTypeFromErrorPatternMutation({
                    variables: {
                        errorPatternId,
                        componentTypeIndices: [text]
                    }
                })}/>
            }]}
        />
    </div>;
  }
}

export default compose(
    graphql(ErrorPatternComponentTypesQuery,{
        name: 'query',
        options: props => ({
            variables: {
                id: props.errorPatternId
            }
        }),
    }),
    graphql(DeleteComponentTypeFromErrorPatternMutation,{
        name: 'deleteComponentTypeFromErrorPatternMutation'
    }),
    waitWhileLoading('query'),
    withTranslation()
)(ErrorPatternComponentTypes);
