// @flow
import React, {Component} from 'react';
import moment from "moment";
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import Sizes from "../../themes/default/sizes";


const Wrapper = styled.div`
    background-color: #f2f6f7;
    padding: ${Sizes.grid}px;
`;

type Props = {
    insert: any,
    update: any,
};


class InsertUpdateInfo extends Component<Props, State> {
    componentDidMount() {
    }

    render() {
        const {insert, update, t} = this.props;

        return <Wrapper className="log-header">

            <div>
                <span><b>{t('Insert at')}:</b> {moment(insert).format('LLL')}</span><br/>
                <span><b>{t('Last update')}:</b> {moment(update).format('LLL')}</span>
            </div>
        </Wrapper>;
    }
}

export default withTranslation()(InsertUpdateInfo);
