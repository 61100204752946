// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ServiceActionTypeDetailsQuery from "../../graphql/queries/serviceActionTypeDetails.graphql";

const ServiceActionTypeBreadcrumb = ({serviceActionTypeDetailsQuery, t}) =>
    <span>{_.get(serviceActionTypeDetailsQuery, 'serviceActionType.name', t('Unknown'))}</span>;

export default compose(
    graphql(ServiceActionTypeDetailsQuery, {
        name: 'serviceActionTypeDetailsQuery',
        options: ({match}) => ({
            variables: {
                index: _.get(match, 'params.index')
            }
        }),
    }),
)(withTranslation()(ServiceActionTypeBreadcrumb));
