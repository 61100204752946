const config = {
    403: {
        img: './images/403.svg',
        title: '403',
        desc: 'Sie haben auf diese Seite keinen Zugriff',
    },
    404: {
        img: './images/404.svg',
        title: '404',
        desc: 'Die angeforderte Seite wurde nicht gefunden',
    },
    500: {
        img: './images/500.svg',
        title: '500',
        desc: 'Es ist ein Fehler aufgetreten',
    },
};

export default config;
