// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import TDAAttributesForm from "../TDAAttributesForm";

type Props = {
    params?: {
        [string]: any
    },
    renderRight?: Function,
    onChange?: Function,
    history: {
        push: Function
    }
}

type State = {

}


class ComponentServiceTicketFilter extends Component<Props, State> {


    constructor(props) {
        super(props);


    }


    _handleChanges = (changes) => {

        const {onChange, params} = this.props;

        if (onChange) {
            if (!changes){
                onChange(null)
            }else {
                const values = {
                    ...params,
                    ...changes
                };
                onChange(values)
            }

        }
    }

    render() {

        const {params, t} = this.props;


        const attributes = [{
            index: 'children',
            type: "bool",
            inputType: "switch",
            name: t('Show tickets of sub components')
        }, {
            name: t('Type'),
            index: 'serviceTicketTypeIndex',
            type: 'string',
            entityType: 'enum',
            enumType: 'ServiceTicketTypeIndex',
            inputType: 'select',
            multiple: true,
            maxTagCount: 0,
            placeholder: t('Ticket Type'),

        },
            {
                name: t('State'),
                index: 'serviceTicketStateIndex',
                type: 'string',
                entityType: 'enum',
                enumType: 'ServiceTicketStateIndex',
                maxTagCount: 0,
                multiple: true,
                inputType: 'select',
                placeholder: t('State'),

            },]



        return <TDAAttributesForm
            cacheChanges={false}
            layout={'inline'}
            onChange={this._handleChanges}
            useSubmit={false}
            values={params}
            attributes={attributes}
        />
    }

}

export default compose(
    withTranslation(),
    withRouter,
)(ComponentServiceTicketFilter);
