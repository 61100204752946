// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {Col, Popconfirm, Popover, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ContentHeader from "../Typo/ContentHeader";
import ServiceCenterDashboardQuery from "./../../graphql/queries/ServiceCenterDashboard.graphql";
import ServiceCenterDeleteMutation from "./../../graphql/mutations/ServiceCenterDelete.graphql"
import ServiceCentersListQuery from "./../../graphql/queries/serviceCenterList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ContentWrapper from "../ContentWrapper";
import Panel from "../Panel";
import Sizes from "../../themes/default/sizes";
import ServiceCenterDepartmentCollapseList from "./../ServiceCenterDepartmentCollapseList";
import ServiceDepartmentCreateForm from "../ServiceDepartmentCreateForm";
import ServiceCenterForm from "../ServiceCenterForm";
import {withRouter} from "react-router-dom";
import {toastError, toastMessage} from "../../utils/toast";

type Props = {
    serviceCenterId: string,
    serviceCenterDeleteMutation: Function,
    history: {
        push: Function,
    }
};

type State = {
    deleting: boolean
}

class ServiceCenterDashboard extends Component<Props, State> {

    state = {
        deleting: false
    };

    _handleDelete = () => {
        const {serviceCenterDeleteMutation, onDelete} = this.props;

        this.setDeleting(true)
            .then(serviceCenterDeleteMutation)
            .then(() => {
                this.setDeleting(false)
            })
            .then((res) => {
                toastMessage(_.get(res, 'data.deleteServiceCenter.message'));
                if (onDelete) onDelete(res);
            })
            .catch(toastError)

    };

    setDeleting = (deleting) => {
        return new Promise((resolve) => {
            this.setState({
                deleting
            }, resolve)
        })
    };

    render() {
        const {t, serviceCenter, serviceCenterId} = this.props;

        return <div>
            <ContentHeader title={serviceCenter.name}
                           tools={<Popconfirm placement="bottomRight" title={t("Are you sure delete this user?")}
                                              onConfirm={this._handleDelete} okText={t("Yes")} cancelText={t("No")}
                                              icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                               <Button loading={this.state.deleting} type="danger" icon="delete">{t('Delete')}</Button>
                           </Popconfirm>}/>
            <ContentWrapper>
                <Row gutter={Sizes.grid}>
                    <Col lg={12}>
                        <Panel title={t('Details')}>
                            <ServiceCenterForm serviceCenterId={serviceCenterId}/>
                        </Panel>
                    </Col>
                    <Col lg={12}>
                        <Panel title={t('Departments')} topRight={<Popover
                            placement={'leftTop'}
                            overlayStyle={{minWidth: 500}}
                            content={<ServiceDepartmentCreateForm serviceCenterId={serviceCenterId}/>}
                            title={t('New department')}>
                            <Button size="small">{t('Add')}</Button>
                        </Popover>}>
                            <ServiceCenterDepartmentCollapseList serviceCenterId={serviceCenterId}/>
                        </Panel>
                    </Col>
                </Row>

            </ContentWrapper>

        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceCenterDashboardQuery, {
        name: 'serviceCenterDashboardQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceCenterId
            }
        }),
    }),
    graphql(ServiceCenterDeleteMutation, {
        name: 'serviceCenterDeleteMutation',
        options: (props) => ({
            variables: {
                id: props.serviceCenterId
            },
            refetchQueries: [{
                query: ServiceCentersListQuery,
                variables: {
                    start: 0,
                    limit: 40,
                }
            }]
        }),
    }),
    waitWhileLoading('serviceCenterDashboardQuery', 'serviceCenter.id'),
    withProps(props => ({
        serviceCenter: _.get(props, 'serviceCenterDashboardQuery.serviceCenter')
    }))
)(ServiceCenterDashboard);
