// @flow
import React, {Component} from "react"
import {Avatar, List} from "antd";
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import "./styles.css";
import {Link} from "react-router-dom";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import EllipseLabel from "../EllipseLabel";
import Bold from "../Utilities/Bold";

type Props = {
    onClick?: Function,
    showPath: ?boolean,
    useLink: ?boolean,
    item: {
        id: string,
        name: string,
        serial?: string,
        runTime?: number,
        path: Array<{
            id: string,
            name: string
        }>,
        image?: {
            thumbnailLink?: string
        },
        componentType: {
            index: string,
            name: string
        },
        product?: {
            catalogNo: string,
            manufacturer: {
                name: string
            }
        }
    }
}

class ComponentSearchListItem extends Component<Props> {


    render() {

        const {item, onClick, showPath, useLink, t} = this.props;


        const listItem = <List.Item className={'component-list-item position-relative'} onClick={onClick}>
            <List.Item.Meta
                avatar={<Avatar src={_.get(item, 'image.thumbnailLink')}/>}
                title={<span><Bold>{_.get(item, 'name')}</Bold></span>}
                description={<div style={{width: '100%'}}>
                    {item.serialNo ? <div>{t("Serial")}: {item.serialNo}</div> : null}
                    {item.product ? <div>{item.product.manufacturer.name}: {item.product.catalogNo}</div> : null}
                    {showPath ?
                        <EllipseLabel maxWidth={200}><ComponentPathBreadcrumb path={item.path}/></EllipseLabel> : null}
                </div>}
            />
        </List.Item>;

        if (useLink) {
            return <Link to={`/infrastructure/${item.id}`}>{listItem}</Link>;
        }

        return listItem;

    }

}

export default withTranslation()(ComponentSearchListItem);
