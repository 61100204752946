// @flow
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import ContentHeader from "../../components/Typo/ContentHeader";
import ServiceProviderList from "../../components/ServiceProviderList";
import {compose} from "redux";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";
import withProfile from "../../hoc/withProfile";
import _ from "lodash";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};

class ServiceProviderListRoute extends Component<Props, State> {


    render() {
        const {t, history, profile} = this.props;

        const userClientId = _.get(profile, 'client.id')
        const defaultServiceProviderId = _.get(profile, 'client.defaultServiceProvider.id')

        return <NavigationContainer navigationBar={<ContentHeader
            title={t('ServiceProvider')}
            tools={<Link to={"/admin/serviceProviders/new"}>
                <Button>
                    {t('Add')}
                </Button>
            </Link>}
        />}>
            <ServiceProviderList
                onClick={(record) => history.push(`/admin/serviceProviders/${record.id}`)}
                userClientId={userClientId}
                defaultServiceProviderId={defaultServiceProviderId}
            />
        </NavigationContainer>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withProfile(),
    withResources(["administrateServiceProviders"])
)(ServiceProviderListRoute);
