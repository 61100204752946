// @flow
import React, {Component} from "react"
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import _ from "lodash";
import AttributesInlineForm from "../Form/attributes-inline-form";
import UpdateHostMutation from "./../../graphql/mutations/updateHost.graphql";
import HostDetailsForm from "./../../graphql/queries/HostDetailsForm.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {categoryIndizesToGroups} from "../../utils/categoryHelper";

type Props = {
    hostId: string,
    updateHostMutation: Function,
    hostDetailsFormQuery: any,
}

class HostForm extends Component<Props> {

    render() {

        const {updateHostMutation, hostId, hostDetailsFormQuery, t} = this.props;

        const attributes = _.get(hostDetailsFormQuery, 'host.typeDefinitionAttributeList.typeDefinitionAttributes', []);

        /*
          attributes.push({
            type: 'float',
            name: 'Electricity Price',
            inputType: 'float',
            index: 'electricityPrice',
            categories: ['tariffs'],
            addOnAfter: '€',
        })
         */


        const groups = categoryIndizesToGroups(_.chain(attributes.map(item => item.categories))
                .flatten()
                .uniq()
                .value()
            , t);


        return <div className={'white-bg'}>
            <AttributesInlineForm
                inline={true}
                attributes={attributes}
                groups={groups}
                mutation={updateHostMutation}
                translateMutationOptions={({values}) => {

                    return {
                        variables: {
                            id: hostId,
                            input: values
                        }
                    }
                }}/>
        </div>;
    }

}

export default compose(
    graphql(HostDetailsForm, {
        name: 'hostDetailsFormQuery',
        options: (props) => ({
            variables: {
                id: props.hostId
            }
        }),
    }),
    waitWhileLoading('hostDetailsFormQuery', ['host.typeDefinitionAttributeList.typeDefinitionAttributes']),
    graphql(UpdateHostMutation, {
        name: 'updateHostMutation',
    }),
    withTranslation()
)(HostForm);
