// @flow
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import type {DataLoaderType} from "./../DataLoader/DataLoaderType.js";
import {Col, Grow} from "../Flex";
import _ from "lodash";
import {Button, Icon} from "@dreebit/react-components";
import {List, Popconfirm, Select} from "antd";

const Option = Select.Option;

type Props = {
    loaderConfig: DataLoaderType,
    items: any,
    addRoleToUserMutation: Function,
    deleteRoleFromUserMutation: Function,
    queryPath: string,
    placeholder: string,
};

const ListAdapter = ({
                         items, placeholder, queryPath, addRoleToUserMutation, deleteRoleFromUserMutation, loaderConfig
                     }: Props) => {
    const {t} = useTranslation();
    const [searchText, setSearchText] = useState(null);

    const {data, loading, refetch} = useQuery(loaderConfig.query, {
        variables: searchText ? loaderConfig.getQueryVariables(searchText) : null
    });

    useEffect(() => {
        if (searchText) {
            refetch();
        }
    }, [searchText]);

    const handleSearch = (text: string) => {
        setSearchText(text);
    };
    const list = _.get(data, queryPath, []);

    return <div style={{backgroundColor: '#fff', width: '100%', padding: '10px'}}>
        <div>
            <Select
                loading={loading}
                value={placeholder ? placeholder : ""}
                allowClear={true}
                style={{width: '100%'}}
                optionFilterProp="name"
                onSelect={addRoleToUserMutation}
                showSearch={true}
                onSearch={_.debounce(handleSearch, 500)}
                filterOption={loaderConfig.local ? (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false}
            >
                {list.map((el) => {
                    return <Option key={el.id}>{el.name}</Option>
                })}
            </Select>
        </div>
        <div style={{paddingTop: '10px'}}>
            <List
                size="small"
                bordered
                dataSource={items}
                renderItem={(index) => (
                    <List.Item>
                        <Grow className={"flex-self-center"}>
                            <Col>
                                <div style={{fontWeight: 'bold'}}>
                                    {index.name}
                                </div>
                                {
                                    index.description || index.email ? <div>
                                        {index.description ? index.description : index.email}
                                    </div> : null
                                }
                            </Col>
                        </Grow>
                        <Col>
                            <Popconfirm title={t("Are you sure delete this task?")} onConfirm={() => {
                                deleteRoleFromUserMutation(index)
                            }} okText={t("Yes")} cancelText={t("No")}
                                        icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                                <Button shape="circle" type="danger" icon="delete"></Button>
                            </Popconfirm>
                        </Col>
                    </List.Item>
                )}
            />
        </div>
    </div>
};

export default ListAdapter
