// @flow
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import BorderWrapper from "../Border";
import {withTranslation} from "react-i18next";
import {Grow, Row} from "../Flex";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ServiceReportCountQuery from "../../graphql/queries/serviceReportCount.graphql";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
  serviceTicketId: string,
  t: Function,
  serviceReportCountQuery: query
};

class ServiceReportWidget extends Component<Props> {
  componentDidMount() {}

  render() {
    const { serviceTicketId, t, serviceReportCountQuery } = this.props;

    const count = _.get(serviceReportCountQuery, 'serviceReportList.total');

    return <BorderWrapper className={'p-10'} radius={5} color={'#d9d9d9'}>
      <Row className={'flex-align-items-center'}>
        <Grow>
          {count > 0 ?
            <Link className={"text-dark"} to={`/service/${serviceTicketId}/serviceReports`}>
              <span>{t('Service report count',{count: count})}</span>
            </Link>
            :
            <span>{t('A service report has not yet been created')}</span>
          }
        </Grow>
        <Link className={"text-dark"} to={`/service/${serviceTicketId}/serviceReports/create`}>
          <Button>{t('Create')}</Button>
        </Link>
      </Row>
    </BorderWrapper>
  }
}

export default compose(
  withRouter,
  graphql(ServiceReportCountQuery, {
    name: 'serviceReportCountQuery',
    options: (props: Props) =>({
      fetchPolicy: 'cache-first',
      variables: {
        serviceTicketId: _.get(props, 'match.params.id')
      }
    }),
  }),
  waitWhileLoading('serviceReportCountQuery', 'serviceReportList.total'),
  withTranslation()
)(ServiceReportWidget);
