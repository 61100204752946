// @flow
import React, {Component} from 'react';
import GridLayout, {WidthProvider} from 'react-grid-layout';
import {withSize} from 'react-sizeme'

import DashboardPortletListQuery from "./../../graphql/queries/DashboardPortletListQuery.graphql";
import UpdateDashboardPortletMutation from "../../graphql/mutations/UpdateDashboardPortlet.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import DashboardPortletWrapper, {PORTLET_COMPONENT_MAP} from "../DashboardPortletWrapper";
import _ from 'lodash'

import {toastError} from "../../utils/toast";
import {compareObjectKeys} from "../../utils/compare";
import type {PortletInterface} from "../Portlets/PortletInterface";
import Empty from "../Empty";
import {withTranslation} from "react-i18next";


type Props = {
    userPortlets: any[],
    updateDashboardPortletMutation: Function,
    layout: any[],
};

type State = {
    locked: boolean
}

const defaultColConfig = {x: 0, y: 0, w: 1, h: 3};


const getUserPortletLayoutConfig = item => {

    const config = item.config ? JSON.parse(item.config) : {};
    return {
        i: item.id,
        x: _.get(config, 'layout.x', defaultColConfig.x),
        y: _.get(config, 'layout.y', defaultColConfig.y),
        w: _.get(config, 'layout.w', defaultColConfig.w),
        h: _.get(config, 'layout.h', defaultColConfig.h),
    }
};


class DashboardPortlets extends Component<Props, State> implements PortletInterface {

    state = {
        locked: false,
    };

    shouldComponentUpdate() {
        return !this.state.locked;
    }

    _handleLayoutChange = (currentLayout) => {

        const layout = this.getLayout();
        const {userPortlets, updateDashboardPortletMutation} = this.props;
        const promises = [];
        layout.forEach(existingLayoutItem => {

            const newLayoutItem = _.find(currentLayout, {i: existingLayoutItem.i});
            const userPortlet = _.find(userPortlets, item => item.id === existingLayoutItem.i);
            if (!userPortlet) {
                console.error('No tiles found', existingLayoutItem.i, userPortlets);
                return;
            }
            const compareKeys = ["x", "y", "h", "w"];

            if (!compareObjectKeys(newLayoutItem, existingLayoutItem, compareKeys)) {
                const config = userPortlet.config ? JSON.parse(userPortlet.config) : {};
                const newConfig = {
                    ...config,
                    layout: newLayoutItem
                };
                promises.push(updateDashboardPortletMutation({
                    variables: {
                        id: existingLayoutItem.i,
                        input: {
                            config: JSON.stringify(newConfig),
                        }
                    }
                }))
            }
        });

        if (promises.length) {
            this.setState({
                locked: true
            });
            Promise.all(promises)
                .then(() => this.setState({
                    locked: false
                }))
                .then(this.props.dashboardPortletListQuery.refetch())
                .catch((err) => {
                    console.error(err);
                    toastError(err)
                })
        }

    };

    getLayout = () => {
        return this.props.userPortlets.map(getUserPortletLayoutConfig);
    };

    render() {
        const {width, userPortlets, size, t} = this.props;

        if (!userPortlets.length) {
            return <Empty
                text={<h3 className={"ant-empty-normal"}>{t('Please add some dashboard tiles')}</h3>}
            >
                <div className={"text-center"}>
                    {t("Click on personalize dashboard to add tiles")}
                </div>
            </Empty>
        }

        const layout = this.getLayout();


        return <div>
            <GridLayout
                className="layout"
                layout={layout}
                isDraggable={true}
                cols={size.width > 1100 ? 2 : 1}
                rowHeight={120}
                width={width}
                onLayoutChange={this._handleLayoutChange}
            >
                {userPortlets.map(item => {

                    return <div key={item.id}>
                        <DashboardPortletWrapper

                            style={{height: "100%"}}
                            title={item.title}
                            portlet={item}
                        />
                    </div>
                })}
            </GridLayout>
        </div>;
    }
}

export default compose(
    graphql(DashboardPortletListQuery, {
        name: 'dashboardPortletListQuery'
    }),
    graphql(UpdateDashboardPortletMutation, {
        name: 'updateDashboardPortletMutation',
        options: () => ({
            refetchQueries: [{
                query: DashboardPortletListQuery
            }]
        })
    }),
    withTranslation(),
    waitWhileLoading('dashboardPortletListQuery', 'userPortletList.userPortlets'),
    WidthProvider,
    withProps((props) => ({
        userPortlets: _.get(props, 'dashboardPortletListQuery.userPortletList.userPortlets', [])
            .filter(item => Object.keys(PORTLET_COMPONENT_MAP).indexOf(_.get(item, 'portlet.index')) !== -1)
    })),
    withSize(),
)(DashboardPortlets);
