// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import BookmarkList from "../BookmarkList";
import {Modal} from "antd";
import {Button} from "@dreebit/react-components";
import InfastructureDashboardBookmarkCreatePanel from "./addBookmark";
import Panel from "../Panel";

type Props = {};

type State = {
    popoverVisible: boolean
}

class InfrastructureDashboardBookmarks extends Component<Props, State> {

    state = {
        popoverVisible: false
    };

    _handleVisibleChange = (popoverVisible) => {
        this.setState({popoverVisible});
    };

    render() {
        const {t} = this.props;

        return <Panel
            title={t('Bookmarks')}
            style={{
                height: '100%'
            }}
            containerStyle={{
                height: '70%',
                overflow: 'Scroll'
            }}
            topRight={<div>
                <Button onClick={() => {
                    this.setState({
                        popoverVisible: true
                    })
                }} icon={'plus'}>
                    {t('Add')}
                </Button>
                <Modal
                    trigger="click"
                    visible={this.state.popoverVisible}
                    onCancel={() => this._handleVisibleChange(false)}
                    footer={false}
                    title={t('New Bookmark')}
                >
                    <InfastructureDashboardBookmarkCreatePanel onCreated={() => {
                        this._handleVisibleChange(false);
                    }}/>
                </Modal>
            </div>}
        >
            <BookmarkList
                bordered
                type={["infrastructureSearch", "infrastructureDetail", "infrastructureNavigation"]}/>
        </Panel>
    }
}

export default withTranslation()(InfrastructureDashboardBookmarks);
