// @flow

import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import ServicePlanQuery from "../../graphql/queries/ServicePlanSidebar.graphql";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import IconSidebarPanel from "../IconSidebarPanel";
import ComponentInfoBox from "../ComponentInfoBox";
import ClientInfoBox from "../ClientInfoBox";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServicePlanDateInfoBox from "./../ServicePlanDateInfoBox";
import styled from "styled-components";
import {Icon} from "@dreebit/react-components";

type Props = {
    servicePlanId: string,
    onClose?: any
};

export const SidebarWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column
`;

class ServicePlanSidebar extends Component<Props, State> {


    render() {

        const {t, servicePlanQuery, onClose} = this.props;
        const items = [{
            index: 'component',
            title: t('Component'),
            icon: <FontAwesomeIcon name={'box'}/>,
            body: <ComponentInfoBox wrapperStyle={{}}
                                    componentId={_.get(servicePlanQuery, 'servicePlan.component.id')}/>
        }, {
            index: 'servicePlan',
            title: t('Service plan'),
            icon: <FontAwesomeIcon name={'clock'}/>,
            body: <ServicePlanDateInfoBox servicePlanId={_.get(servicePlanQuery, 'servicePlan.id')}/>
        }, {
            index: 'client',
            title: t('Client info'),
            icon: <FontAwesomeIcon name={'building'}/>,
            body: <ClientInfoBox clientId={_.get(servicePlanQuery, 'servicePlan.component.client.id')}/>
        }];

        return <SidebarWrapper>
            <div className={'flex justify-flex-end'}>
                <Icon
                    type={'close'}
                    style={{fontSize: 16}}
                    onClick={onClose}
                />
            </div>
            <IconSidebarPanel hideIcons items={items}/>
        </SidebarWrapper>
    }

}

export default compose(
    graphql(ServicePlanQuery, {
        name: 'servicePlanQuery',
        options: (props) => {
            return {
                variables: {
                    servicePlanId: props.servicePlanId,
                }
            }
        }
    }),
    waitWhileLoading('servicePlanQuery'),
    withTranslation()
)(ServicePlanSidebar)
