import React from "react";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import ComponentTcoValueCreateMutation from "./../../graphql/mutations/createComponentTcoValue.graphql"
import {useMutation} from "@apollo/client";

interface Props {
    onDone: (res: any) => void,
    componentId: string,
}

const ComponentTCOCreateForm = ({componentId, onDone}: Props) => {

    const [mutation] = useMutation(ComponentTcoValueCreateMutation)

    return <TypeDefinitionAttributesForm
        index={"componentTcoValue"}

        formProps={{
            onSubmit: onDone,
            values: {
              date: new Date().toISOString()
            },
            mutation,
            translateMutationOptions: ({values}) => {
                return {
                    variables: {
                        id: componentId,
                        input: values
                    }
                }
            }
        }}
    />
}

export default ComponentTCOCreateForm
