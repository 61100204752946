// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/logo/LoginBackground.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-route .registerButton {\n  display: flex;\n  justify-content: center;\n}\n.login-route .passwordResetWrapper {\n  display: flex;\n  justify-content: center;\n}\n.login-route .footer {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 40px;\n}\n.login-route .form {\n  padding: 36px;\n  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);\n}\n.login-route .form button[type=\"submit\"] {\n  width: 100%;\n}\n.login-route .form p {\n  color: #cccccc;\n  text-align: center;\n  margin-top: 16px;\n}\n.login-route .form p span:first-child {\n  margin-right: 16px;\n}\n.login-route .logo {\n  text-align: center;\n  cursor: pointer;\n  margin-bottom: 24px;\n}\n.login-route .logo img {\n  width: 40px;\n  margin-right: 8px;\n}\n.login-route .logo span {\n  vertical-align: text-bottom;\n  font-size: 16px;\n  text-transform: uppercase;\n  display: inline-block;\n}\n.login-route .ant-spin-container,\n.login-route .ant-spin-nested-loading {\n  height: 100%;\n}\n.login-background {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100%;\n  background-repeat: no-repeat;\n  background-position: bottom;\n}\n@media (max-width: 950px) {\n  .login-background {\n    background-size: cover;\n    background-position: left;\n  }\n}\n", ""]);
// Exports
module.exports = exports;
