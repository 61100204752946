// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash';
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceProviderUpdateMutation from "./../../graphql/mutations/updateServiceProvider.graphql";
import ServiceProviderFormQuery from "../../graphql/queries/ServiceProviderForm.graphql";
import {withTranslation} from "react-i18next";
import PanelComponent from "../Panel";
import {getAttributeGroups} from "../../utils/attributeGroups";

type Props = {
    serviceProviderId: string,
    t?: any
};


class ServiceProviderUpdateForm extends Component<Props, State> {
    componentDidMount() {
    }

    render() {
        const {serviceProviderUpdateMutation, attributes, access, serviceProviderId, t} = this.props;

        const groups = getAttributeGroups(attributes, t)

        return <PanelComponent
            bordered={false}
            children={<AttributesInlineForm
                attributes={attributes}
                groups={groups}
                mutation={serviceProviderUpdateMutation}
                translateMutationOptions={({values}) => ({
                    variables: {
                        id: serviceProviderId,
                        input: values
                    }
                })}
                useSubmit
                hideSubmit={!_.get(access, 'write', false)}
            />}
        />
    }
}


export default compose(
    withTranslation(),
    graphql(ServiceProviderUpdateMutation, {
        name: 'serviceProviderUpdateMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ServiceProviderFormQuery,
                variables: {
                    id: props.serviceProviderId
                }
            }]
        })
    }),
    graphql(ServiceProviderFormQuery, {
        name: 'serviceProviderFormQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceProviderId
            }
        }),
    }),
    waitWhileLoading('serviceProviderFormQuery', 'serviceProvider'),
    withProps((props) => ({
        attributes: _.get(props, 'serviceProviderFormQuery.serviceProvider.typeDefinitionAttributeList.typeDefinitionAttributes'),
        access: _.get(props, 'serviceProviderFormQuery.serviceProvider.access')
    }))
)(ServiceProviderUpdateForm);
