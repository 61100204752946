// @flow
import React, {Component} from 'react';
import _ from "lodash";
import serviceActionsTimeline from "./../../graphql/queries/serviceActionsTimeline.graphql";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {withSize} from "react-sizeme";
import Slider from "react-slick";
import type {
    serviceActionsTimeline_serviceActionList,
    serviceActionsTimeline_serviceActionList_serviceActions
} from "../../graphql/operation-result-types.flow";
import ServiceActionTimelineItem from "./timelimeItem";
import AddTimelineItem from "./addTimelineItem";

import "./index.css";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    hideAdd?: boolean,
    draggable?: boolean,
    serviceTicketId: string | number,
    serviceTicketTypeIndex: string | number,
    serviceTicketStateIndex: string,
    serviceActionsTimelineQuery: {
        loading: boolean,
        error?: any,
        serviceActionList: ?serviceActionsTimeline_serviceActionList,
    },
    onClick?: (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, event: any)=>void,
};

type State = {
    ignoreItemClick: boolean,
    draggable: boolean
}

class ServiceActionsTimeline extends Component<Props, State> {

    slider = new React.createRef();

    state = {
        draggable: this.props.draggable || true,
        currentIndex: null
    }

    componentDidUpdate(prevProps) {
        const oldItems = _.get(prevProps, 'serviceActionsTimelineQuery.serviceActionList.serviceActions');
        const newItems = _.get(this.props, 'serviceActionsTimelineQuery.serviceActionList.serviceActions');
        const oldSize = _.get(prevProps, 'size');
        const newSize = _.get(this.props, 'size');

        if (oldItems !== newItems || oldSize !== newSize) {
            const itemsPerPage = Math.floor((newSize.width - 200) / 360);

            if (this.slider.current && newItems.length >= itemsPerPage) {
                this.slider.current.slickGoTo(newItems.length - itemsPerPage);
            }
        }
    }

    render() {
        const {
            serviceActionsTimelineQuery,
            hideAdd,
            onClick,
            serviceTicketTypeIndex,
            serviceTicketStateIndex,
            serviceTicketId,
            size
        } = this.props;
        const {draggable} = this.state;

        const items = _.get(serviceActionsTimelineQuery, 'serviceActionList.serviceActions', []);
        const itemsPerPage = Math.floor((size.width - 200) / 360);
        const initialSlide = _.max([items.length - itemsPerPage, 0]);

        const settings = {
            className: "slider variable-width",
            arrows: true,
            dots: false,
            draggable,
            infinite: false,
            centerMode: false,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            centerPadding: '-100px',
            rtl: false,
            initialSlide,
            afterChange: (index) => {
                this.setState({
                    currentIndex: index
                })
            }
        };


        let hideRight = true;
        if (this.state.currentIndex !== null){
            hideRight = this.state.currentIndex >= items.length - itemsPerPage
        }

        return <div className={`ServiceActionsTimeline full-width flex-row mb-10 ${hideRight ? 'hide-right' : ''}`}>
            <Slider
                ref={this.slider}
                {...settings}
            >
                {items.map((item) => (
                    item ? <ServiceActionTimelineItem serviceTicketId={serviceTicketId}
                                                      onClick={onClick}
                                                      key={item.id}
                                                      serviceAction={item}/> : null
                ))}
            </Slider>

            {!hideAdd ? <AddTimelineItem
                onClick={() => {
                    if (draggable) this.setState({
                        draggable: !draggable
                    })
                }}
                onCancel={() => {
                    if (!draggable) this.setState({
                        draggable: !draggable
                    })
                }}
                onSubmit={() => {
                    serviceActionsTimelineQuery.refetch()
                        .then(() => {
                            if (!draggable) this.setState({
                                draggable: !draggable
                            })
                        })
                }}
                serviceTicketId={serviceTicketId}
                serviceTicketStateIndex={serviceTicketStateIndex}
                serviceTicketTypeIndex={serviceTicketTypeIndex}/> : null}
        </div>
    }
}


export default compose(
    graphql(serviceActionsTimeline, {
        name: 'serviceActionsTimelineQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceTicketId
            }
        }),
    }),
    waitWhileLoading('serviceActionsTimelineQuery', ['serviceActionList.serviceActions']),
    withSize()
)(ServiceActionsTimeline);
