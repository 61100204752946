// @flow
import React, {Component} from 'react';
import {Card, Empty, Spin} from "antd";
import {Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";

import RemoveUserPortletMutation from "./../../graphql/mutations/deleteUserPortlet.graphql"
import type {PortletInterface} from "../Portlets/PortletInterface";
import ServiceCalendarListPortlet from "../Portlets/ServiceCalendarListPortlet";
import ServiceTicketsListPortlet from "../Portlets/ServiceTicketsPortlet";
import StarredComponentsPortlet from "../Portlets/StarredComponentsPortlet";
import StarredServiceTicketsPortlet from "../Portlets/StarredServiceTicketsPortlet";
import {toastError, toastMessage} from "../../utils/toast";
import DashboardPortletListQuery from "../../graphql/queries/DashboardPortletListQuery.graphql";
import {Link} from "react-router-dom";


type Props = {
    bordered: boolean,
    title: string,
    portlet: any,
    removeUserPortletMutation: Function,
};

type State = {
    showModal: boolean,
    deleting: boolean
}

export const PORTLET_COMPONENT_MAP: { [string]: PortletInterface } = {
    "serviceCalendarListPortlet": ServiceCalendarListPortlet,
    "serviceTicketsPortlet": ServiceTicketsListPortlet,
    "starredComponentsPortlet": StarredComponentsPortlet,
    "starredServiceTicketsPortlet": StarredServiceTicketsPortlet,
};

class DashboardPortletWrapper extends Component<Props, State> {

    state = {
        showModal: false,
        deleting: false,
    };

    _hideModal = () => {
        this.setState({
            showModal: false
        })
    };

    _showModal = () => {
        this.setState({
            showModal: true
        })
    };

    _handleClose = () => {

        const {removeUserPortletMutation, t} = this.props;
        this.setDeleting(true)
            .then(() => removeUserPortletMutation())
            .then(() => toastMessage(t('Removed')))
            .catch(toastError)
            .finally(() => this.setDeleting(false))

    };

    setDeleting = (deleting) => {
        return new Promise((resolve) => {
            this.setState({
                deleting
            }, resolve)
        })
    };

    render() {
        const {portlet, t} = this.props;
        const {deleting} = this.state;

        const PortletComponent: PortletInterface = _.get(PORTLET_COMPONENT_MAP, _.get(portlet, 'portlet.index'));

        return <Card
            key={portlet.id}
            title={this.props.title}
            bordered={true}
            style={this.props.style}
            extra={[
                <Link key={'edit'} to={`dashboard/${portlet.id}`}><Icon className={"link-dark mr-5"} key={"setting"}
                                                                        onClick={this._showModal}
                                                                        type={"edit"}/></Link>,
                deleting ? <Spin key={'loading'} size={'small'}/> :
                    <Icon className={"link-dark"} key={"close"} onClick={this._handleClose} type={"close"}/>
            ]}
            bodyStyle={{padding: 0, overflowY: 'hidden', height: "calc(100% - 57px)"}}>

            {PortletComponent ? <PortletComponent portlet={portlet}/> : <Empty />}

        </Card>;
    }
}

export default compose(
    withTranslation(),
    graphql(RemoveUserPortletMutation, {
        name: 'removeUserPortletMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: DashboardPortletListQuery
            }],
            variables: {
                id: props.portlet.id
            }
        }),
    }),
)(DashboardPortletWrapper);
