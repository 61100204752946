// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {Col, message, Popconfirm, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import ServiceActionDashboardQuery from './../../graphql/queries/serviceActionDashboard.graphql';
import ServiceActionsTimelineQuery from './../../graphql/queries/serviceActionsTimeline.graphql';
import Error from "./../Error";
import ServiceActionDetailsInlineForm from "./detailsInlineForm";
import ServiceActionDashboardFilesWidget from "./filesWidget";
import Panel from "../Panel";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceActionMessageWidget from "./newMessageWidget";
import deleteServiceAction from "../../graphql/mutations/deleteServiceAction.graphql";
import ContentHeader from "../Typo/ContentHeader";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import {withRouter} from "react-router-dom";
import ServiceActionShippingInfoBox from "../ServiceActionShippingInfoBox";
import ServiceTicketInfo from "../ServiceTicketInfo";
import {checkResource} from "../../hoc/withResources";
import withProfile from "../../hoc/withProfile";
import Loader from "../Loader";

type Props = {
    t: Function,
    history: {
        push: Function,
    },
    serviceActionId: string | number,
    ticketId: any,
    serviceActionDashboardQuery: any,
    deleteServiceActionMutation: Function
};


class ServiceActionDashboard extends Component<Props, State> {

    handleDelete = () => {
        const {deleteServiceActionMutation, history, serviceActionId, ticketId} = this.props;

        deleteServiceActionMutation({variables: {id: serviceActionId}}).then((res) => {
            message.success(res.data.deleteServiceAction.message);
            history.push(`/service/${ticketId}`);
        })
    };

    render() {
        const {serviceActionDashboardQuery, serviceActionId, t, ticketId, resources} = this.props;
        const {serviceAction} = serviceActionDashboardQuery;
        if (!serviceAction) {
            return <Error status={'404'}/>
        }

        const customPanels = [];
        const serviceMessaging = _.get(serviceActionDashboardQuery, 'serviceAction.serviceTicket.serviceProvider.serviceProviderConfig.serviceMessaging', false);
        const showConversations = checkResource(["conversation"], resources) && serviceMessaging;

        if (serviceAction.serviceActionType.index === 'deliveryShipped'){
            customPanels.push(<Panel title={t('Shipping details')}>
                <ServiceActionShippingInfoBox serviceActionId={serviceAction.id} />
            </Panel>)
        }

        if (serviceAction.serviceActionType.index === 'subServiceTicket'){
            customPanels.push(<Panel title={t('Linked Service Ticket')}>
                <ServiceTicketInfo serviceTicketId={_.get(serviceAction,'subServiceTicket.id')} />
            </Panel>)
        }


        return <div className={'ServiceActionDashboard'}>
            <ContentHeader title={serviceAction.serviceActionType.code +': '+ serviceAction.serviceActionType.name} hideBreadcrumb
                           tools={_.get(serviceAction,'access.remove') ? <Popconfirm placement="bottomRight" title={t("Are you sure?")}
                                              onConfirm={this.handleDelete} okText={t("Yes")} cancelText={t("No")}
                                              icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                               <Button data-cy={'TEST_20005'} type="danger" icon="delete">{t('Delete')}</Button>
                           </Popconfirm> : null}/>
            <div style={{padding: 16}}>
                <Row gutter={16}>
                    <Col md={16}>
                        <Panel title={t('Details')}>
                            <ServiceActionDetailsInlineForm serviceActionId={serviceActionId}/>
                        </Panel>
                        <Panel title={t('Files')}>
                            <ServiceActionDashboardFilesWidget  serviceActionId={serviceActionId} isPrivate={_.get(serviceAction, 'private')}/>
                        </Panel>
                    </Col>
                    <Col md={8}>

                        {
                            customPanels
                        }

                        {
                            showConversations ?
                                <Panel title={t('New message')}>
                                    <ServiceActionMessageWidget serviceTicketId={ticketId} serviceActionId={serviceActionId}/>
                                </Panel>
                            : null
                        }
                    </Col>
                </Row>
            </div>
        </div>
    }
}

export default compose(
    withTranslation(),
    withRouter,
    withProfile(),
    graphql(ServiceActionDashboardQuery, {
        name: 'serviceActionDashboardQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.serviceActionId
            }
        })
    }),
    waitWhileLoading('serviceActionDashboardQuery', 'serviceAction'),
    graphql(deleteServiceAction, {
        name: 'deleteServiceActionMutation',
        options: (props) => ({
            refetchQueries: [{
                query: serviceTicketDashboard,
                variables: {
                    id: props.ticketId
                }
            },{
                query: ServiceActionsTimelineQuery,
                variables: {
                    id: props.ticketId
                }
            }]
        })
    }),
)(ServiceActionDashboard)
