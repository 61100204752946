import React, {useState} from 'react'
import {Arrow, Controls, Dot, Navigation} from 'reactour'
import './styles.less'
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import {getAllTourSteps} from "../../utils/getAllTourSteps";
import TourGuideSkipButton from "../TourGuideManagementButton/skip";
import {Button} from "@dreebit/react-components";
import {useTranslation} from "react-i18next";

export function TourStep({ current, content, totalSteps, gotoStep, close, title }) {

  const [closeContent, setCloseOpen] = useState(false);
  const { t, i18n } = useTranslation();

  if(closeContent) {
    return (
      <div className="tour-close-content">
        <span>{t('tour__close__content')}</span>
        <div className="tour-close-buttons">
          <div className="w-75 mt-10">
            <Button type="primary" onClick={close} block>
              {t('Continue later')}
            </Button>
          </div>
          <div className="w-75 mt-10">
            <TourGuideSkipButton
              style={{paddingTop: '10px'}}
              tourSkipInput={getAllTourSteps()}
              onDone={close}
              block
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="CustomHelper__content">
        <div className="tour-step-close-button" onClick={() => setCloseOpen(true)}>
          <FontAwesomeIcon style={{fontSize: 16}} name={"times"} />
        </div>
        {content}
        <Controls
          data-tour-elem="controls"
          className="CustomHelper__controls"
          style={{ position: 'absolute' }}
        >
          <Arrow
            onClick={() => gotoStep(current - 1)}
            disabled={current === 0}
            className="CustomHelper__navArrow"
          />
          <Navigation data-tour-elem="navigation">
            {Array.from(Array(totalSteps).keys()).map((li, i) => (
              <Dot
                key={li}
                onClick={() => current !== i && gotoStep(i)}
                current={current}
                index={i}
                disabled={current === i}
                showNumber={true}
                data-tour-elem="dot"
              />
            ))}
          </Navigation>
          {
            current === totalSteps - 1 ?
              <Button
                style={{marginLeft: 10}}
                type='primary'
                icon='check'
                data-tour-elem="last-step-button"
                onClick={close}
              >
                {t('All right')}
              </Button>
               :
              <Arrow
                onClick={() => gotoStep(current + 1)}
                disabled={current === totalSteps - 1}
                className="CustomHelper__navArrow"
                inverted
              />
          }
        </Controls>
      </div>
    )
  }
}
