// @flow
import React, {useEffect, useState} from "react"
import _ from 'lodash'
import {useTranslation} from "react-i18next";
import {Query} from "@apollo/client/react/components";
import AttributesInlineForm from "../Form/attributes-inline-form";
import CreateServiceTicketMutation from "../../graphql/mutations/createServiceTicket.graphql";
import EnumsQuery from "../../graphql/queries/enums.graphql";
import ComponentServiceTicketListQuery from "./../../graphql/queries/ComponentServiceTicketsList.graphql";
import ComponentServiceTicketsListQuery from "./../../graphql/queries/ComponentServiceTicketsList.graphql";
import ComponentLookupQuery from "../../graphql/queries/componentLookup.graphql";
import ComponentSerialNoQuery from "../../graphql/queries/component-serialNo.graphql";
import DefaultServiceTaskQuery from "./../../graphql/queries/defaultServiceTaskName.graphql";
import ServiceTicketTypeNameQuery from "./../../graphql/queries/serviceTicketTypeName.graphql";
import Sizes from "../../themes/default/sizes";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import {cleanKeys} from "../../utils/cleanObject";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import IconSplitPanelServiceTicketQuery from "../../graphql/queries/iconSplitPanelServiceTicket.graphql";
import activeServiceTicket from "../../graphql/queries/activeServiceTicket.graphql";
import {Form} from "antd";
import {useApolloClient, useMutation, useQuery} from "@apollo/client";

type Props = {
	componentId: string,
	onCreated: (data: any) => void,
	serialNo?: string
}

const ServiceTicketCreateForm = ({componentId, onCreated, serialNo}) =>  {

	const [changes, setChanges] = useState({});
	const [serviceProviderId, setServiceProviderId] = useState(null);
	const [form] = Form.useForm();
	const client = useApolloClient();
	const {t} = useTranslation();

	const serviceTicketTypesQuery = useQuery(EnumsQuery, {
		variables: {
			name: "ServiceTicketCreateTypeIndex"
		}
	})
	const componentServiceTicketListQuery = useQuery(ComponentServiceTicketListQuery, {
		skip: !componentId,
		variables: {
			componentId: componentId,
		}
	})
	const componentSerialNoQuery = useQuery(ComponentSerialNoQuery, {
		skip: !componentId,
		variables: {
			componentId: componentId,
		}
	})
	const componentSerialNo = _.get(componentSerialNoQuery, 'data.component.serialNo');

	const [createServiceTicketMutation] = useMutation(CreateServiceTicketMutation, {
		refetchQueries: [{
			query: componentQuery,
			variables: {
				id: componentId,
			}
		}]
	});

	useEffect(() => {
		if (_.isEmpty(changes.name) || changes.name === "" || !changes.name){
			if (form.getFieldValue('defaultServiceTaskId')){
				setDefaultServiceTaskName(form.getFieldValue('defaultServiceTaskId'))
			}
		}
	})

	const setDefaultServiceTaskName = (id) => {

		client.query({
			query: DefaultServiceTaskQuery,
			variables: {
				id
			}
		}).then((res) => {
			if (res && res.data){
				setChanges({
					...this.state.changes,
					name: _.get(res,'data.defaultServiceTask.name')
				})
			}
		})
	}

	const checkForExistingSerialNo = _.debounce(() => {
		const serialNo = form.getFieldValue('serialNo')
		const catalogNo = form.getFieldValue('catalogNo')

		if (serialNo && catalogNo) {
			client.query({
				query: ComponentLookupQuery,
				variables: {
					serialNo: serialNo,
					catalogNo: catalogNo
				},
				// skip: !(serialNo && catalogNo)
			}).then((res) => {
				if (!_.get(res, 'data.componentLookup.create') && serialNo !== componentSerialNo) {
					form.setFields([
						{
							name: 'serialNo',
							errors: [t('The serial number you entered is already assigned to another product in the system')],
						},
					]);
				} else {
					form.setFields([
						{
							name: 'serialNo',
							errors: [],
						},
					])
				}
			})
		}
	}, 600)

	const formGroups = [
		{
			name: t("Service Provider"),
			index: "serviceProvider",
			colProps: {
				lg: 24
			}
		},
		{
			name: t("Default"),
			index: "default",
			colProps: {
				lg: 24
			}
		},
		{
			name: t("Component data"),
			index: "componentData",
			colProps: {
				lg: 24
			}
		},
		{
			name: t("Contamination declaration"),
			index: "contaminationDeclaration",
			colProps: {
				lg: 24
			}
		},
		{
			name: t("Contact"),
			index: "contact",
			colProps: {
				lg: 24
			},
			footer: <div className={"mv-50"}>

			</div>
		},
		{
			name: t("Client"),
			index: "client",
			colProps: {
				lg: 24
			}
		},
		{
			name: t("Error"),
			index: "error",
			colProps: {
				lg: 24
			}
		},
		{
			name: t("Parts"),
			index: "parts",
			colProps: {
				lg: 24
			}
		}
	];

	let serviceTicketTypeIndex = _.get(changes, 'serviceTicketTypeIndex')

	const getTitle = (serviceTicketTypeIndex) => {
		return _.chain(serviceTicketTypesQuery).get('data.__type.enumValues').find({
			name: serviceTicketTypeIndex
		}).get('description').value()
	}

	return <Query
		skip={!componentId}
		query={TypeDefinitionAttributeListQuery}
		variables={{
			index: 'serviceTicket',
			options: {
				componentId: componentId,
				serviceProviderId: serviceProviderId,
				serviceTicketTypeIndex
			}
		}}
	>
		{({data}) => {

			if (!serviceTicketTypeIndex){
				serviceTicketTypeIndex = _.chain(data).get('typeDefinitionAttributeList.typeDefinitionAttributes', []).find({
					index: "serviceTicketTypeIndex"
				}).get('value').value()
			}

			const formProps = {
				attributes: [
					..._.chain(data)
						.get('typeDefinitionAttributeList.typeDefinitionAttributes', [])
						.value()
				].map((item) => {
					if (item.index === "defaultServiceTaskId") {
						return {
							...item,
							customData: {
								componentId
							}
						}
					}
					return item;
				}),
				className: "submit-right",
				groups: formGroups,
				submitTitle: t('Create'),
				onSubmit: onCreated,
				submitButtonWrapperStyle: {textAlign: "right", paddingRight: 2 * Sizes.grid},
				mutation: createServiceTicketMutation,
				translateMutationOptions: ({values}) => ({
					variables: {
						input: cleanKeys(values),
						componentId,
					},
					refetchQueries: [
						{
							query: ComponentServiceTicketsListQuery,
							variables: {componentId}
						},
						{
							query: IconSplitPanelServiceTicketQuery,
							variables: {componentId}
						},
						{
							query: activeServiceTicket,
							variables: {
								id: componentId
							}
						}
					]
				})
			}

			return <div className={"ph-20"}>
				<AttributesInlineForm
					form={form}
					onChange={(values) => {
						if (values.serviceProviderId) {
							setServiceProviderId(values.serviceProviderId)
						}
						if (values.serialNo || values.catalogNo) {
							checkForExistingSerialNo()
						}
						if (values.serviceTicketTypeIndex) {
							form.setFieldsValue({
								name: getTitle(values.serviceTicketTypeIndex)
							});
						}

						setChanges({
							...changes,
							...values
						})
					}}
					{...formProps}
					values={{
						...changes
					}}
				/>
			</div>;
		}}
	</Query>
}


export default ServiceTicketCreateForm;
