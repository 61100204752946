// @flow
import React, {Component} from 'react';
import classNames from 'classnames';


type Props = {
    children?: any,
    className?: any,
    tourId?: any
};


export class Row extends Component<Props> {

    render() {
        const {children, style} = this.props;

        return <div style={style} className={classNames('flex-row', this.props.className)}
                    tour-id={this.props.tourId}>{children}</div>;
    }
}

export class Col extends Component<Props> {

    render() {
        const {children, style} = this.props;

        return <div style={style} className={classNames('flex-col', this.props.className)}
                    tour-id={this.props.tourId}>{children}</div>;
    }

}


export class Grow extends Component<Props> {
    render() {
        const {children, style} = this.props;

        return <div style={style} className={classNames('flex-grow-1', this.props.className)}
                    tour-id={this.props.tourId}>{children}</div>;
    }
}
