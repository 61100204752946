// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import MutationButton from "../MutationButton";
import {graphql} from '@apollo/client/react/hoc'
import _ from "lodash";
import MatchManufacturersMutation from "../../graphql/mutations/matchManufacturer.graphql";
import ManufacturerListQuery from "../../graphql/queries/manufacturerList.graphql";
import ManufacturerInfoBox from "../ManufacturerInfoBox";

type Props = {
    sourceId: string,
    targetId: string,
    onMatched: () => void
};

type State = {

}

class ManufacturerMatchPerformer extends Component<Props, State> {

    render() {
        const { sourceId, targetId, t, matchManufacturerMutation, onMatched } = this.props;

        return <div>

            <div className={"border-dashed"}>
                <ManufacturerInfoBox manufacturerId={sourceId}/>
            </div>
            <div className={"text-center pv-10"}>
                <Icon style={{fontSize: 18}} type="down" />
            </div>
            <div className={"border-success"}>
                <ManufacturerInfoBox manufacturerId={targetId}/>
            </div>

            <div className={"text-center mt-10"}>
                <MutationButton
                    buttonProps={{
                        type: 'primary'
                    }}
                    mutation={matchManufacturerMutation}
                    onDone={onMatched}>{t("Perform match")}</MutationButton>
            </div>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(MatchManufacturersMutation, {
        name: 'matchManufacturerMutation',
        options: props => ({
            variables: {
                manufacturerId: props.targetId,
                matchManufacturerId: props.sourceId
            },
            refetchQueries: [{
                query: ManufacturerListQuery,
                variables: {
                    params: {
                        filter: {
                            status: {
                                operator: "in",
                                value: _.get(props, 'filter.status')
                            }
                        },
                        limit: 40,
                        search: {
                            query: _.get(props, 'filter.query')
                        }
                    }
                }
            }, {
                query: ManufacturerListQuery,
                variables: {
                    params: {
                        filter: {
                            status: {
                                value: 'published',
                            },
                        },
                    },
                }
            }]

        })
    }),
)(ManufacturerMatchPerformer);
