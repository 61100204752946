// @flow
import React, {Component} from 'react';
import {Link, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import CatalogCategoriesDetailRoute from "./detail";
import CatalogCategoriesListRoute from "./list";
import ComponentTypeServiceReportConfigDashboard from "../../../components/ComponentTypeServiceReportConfigDashboard";

type Props = {
    history: {
        push: Function
    }
};

class CatalogCategoriesRoute extends Component{
    render(){
        const {history} = this.props

        return <Switch>
            <Route path='/catalog/categories/:id' component={() => (<CatalogCategoriesDetailRoute
                handleDeleteRedirect={() => {
                    history.push(`/catalog/categories`)
                    }}
                onUpdateAttributeCategory={() => {
                    history.push(`/catalog/categories`)
                }}
                />
                )}
           />
            <Route path='/catalog/categories' component={() => (<CatalogCategoriesListRoute
                onCategoryListRowClick={(row) => {
                    return history.push(`/catalog/categories/${row.id}`)
                }}
            />)}/>

        </Switch>;
    }
}

export default compose(
    withTranslation()
)(CatalogCategoriesRoute);