// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import {mapServiceCalendarBadgeItemProps} from "../../utils/calendarItemHelper";
import {Grow} from "../Flex";
import CalendarItemListQuery from "../../graphql/queries/calendarItemList.graphql";
import TimeQueryList from "../TimeQueryList";
import './index.css';
import ComponentInfoBox from "../ComponentInfoBox";
import {Link} from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";
import {withApollo} from "@apollo/client/react/hoc";
import {getCurrentMonthRange} from "../../utils/dateHelper";
import {objectToFilter} from "../../utils/queryFilter";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

type Props = {
    filter: any,
    search: any,
    onChange?: any
};

const LargeLabel = styled.span`
  font-weight: 600;
`;

const IconRow = styled.div`
  margin-bottom: 4px;
`;

class ServiceCalendarList extends Component<Props, State> {


    render() {
        const {filter, onChange} = this.props;

        const queryProps = {
            variables: {
                params: {
                    filter: getCalendarListFilter(filter),
                    start: 0,
                    limit: 15
                }
            },
            fetchPolicy: 'no-cache'
        };

        return <TimeQueryList
            query={CalendarItemListQuery}
            client={this.props.client}
            itemsKeyPath={'calendarItemList.calendarItems'}
            totalKeyPath={'calendarItemList.total'}
            dateRangeFromKeyPath={'params.filter.date.range.from'}
            dateRangeToKeyPath={'params.filter.date.range.to'}
            filter={filter}
            useDateRange={true}
            queryProps={queryProps}
            onDateRangeChange={onChange}
            renderItem={(item, index) => {
                const calendarItem = mapServiceCalendarBadgeItemProps(item);
                const component = _.get(calendarItem, 'data.component');

                return <div className="item-card-wrapper" key={calendarItem.dataId}>
                    <div
                        className="item-icon"
                    >
                        <MaterialCommunityIcon
                            name={'calendarToday'}
                            circled={true}
                            color={_.get(calendarItem, 'color')}
                            size={'30px'}
                        />
                    </div>
                    <div className="item-card">
                        <div className={`item-head flex-row `}
                             style={{backgroundColor: _.get(calendarItem, 'color')}}
                        >
                            <Grow>
                                <Link
                                    to={`/calendar/${calendarItem.dataId}/${calendarItem.calendarItemIndex}?${queryString.stringify(filter)}`}
                                    className="item-title">
                                    <b>{_.get(calendarItem, 'text')}</b>
                                </Link>
                            </Grow>
                        </div>
                        <div className="item-body">
                            <div className="item-content">
                                <ComponentInfoBox wrapperStyle={{}}
                                                  componentData={component}/>
                            </div>
                        </div>
                        <div className="item-footer">
                            <IconRow>
                                <LargeLabel>
                                    <MaterialCommunityIcon name={'building'}/>
                                    <span style={{paddingLeft: 5}}>
                                            {_.get(component, 'client.name')}
                                        </span>
                                </LargeLabel>
                            </IconRow>
                        </div>
                    </div>
                </div>
            }}
        />
    }
}

export function getCalendarListFilter(queryFilter) {
    let filter = {
        ...queryFilter,
        view: undefined
    };

    let range = _.get(filter, 'date', []);
    if (range.length !== 2) range = getCurrentMonthRange(null, 7, true);

    return {
        ...objectToFilter(filter),
        date: {
            operator: "range",
            range: {
                from: range[0],
                to: range[1],
            }
        }
    };
}

export default compose(
    withTranslation(),
    withApollo
)(ServiceCalendarList);
