// @flow
import React, {Component} from 'react';
import _ from "lodash";
import CreateServicePlan from "./form"
import {compose} from "recompose";
import {withApollo} from "@apollo/client/react/hoc";
import ServiceTicketComponentQuery from "./../../graphql/queries/serviceTicketComponent.graphql";
import CreateServicePlanMutation from "./../../graphql/mutations/createServicePlan.graphql";

import Loader from "../Loader";
import Error from "../Error";

type Props = {
    serviceTicketIds: string[],
    onDone: () => void,
};

type State = {
    loading: boolean,
    showError: boolean,
}

class ServiceTicketCreateServicePlan extends Component<Props, State> {

    state = {
        loading: true,
        componentIds: []
    }

    componentDidMount() {
        const { client, serviceTicketIds } = this.props;

        const promises = serviceTicketIds.map((id) => {
            return client.query({
                query: ServiceTicketComponentQuery,
                variables: {
                    id
                }
            })
        });

        Promise.all(promises).then((results) => {

            this.setState({
                componentIds: results.map(res => _.get(res,'data.serviceTicket.component.id')),
                showError: false,
                loading: false
            })

        }).catch(() => {
            this.setState({
                showError: true,
                loading: false
            })
        })

    }

  render() {
    const { client, onDone } = this.props;

    if (this.state.loading){
        return <Loader/>
    }

      if (this.state.error){
          return <Error/>
      }

    return <div>
        <CreateServicePlan
            componentId={_.first(this.state.componentIds)}
            mutation={({variables}) => {
                return Promise.all(this.state.componentIds.map((componentId) => {
                    return client.mutate({
                        mutation: CreateServicePlanMutation,
                        variables: {
                            ...variables,
                            id: componentId
                        }
                    })
                })).then(()=>{
                    if (onDone){
                        onDone();
                    }
                })
            }}
        />
    </div>;
  }
}

export default compose(
    withApollo,
)(ServiceTicketCreateServicePlan);
