// @flow
import React, { Component } from 'react';
import NavigationContainer from "../../components/NavigationContainer";
import ContentHeader from "../../components/Typo/ContentHeader";
import {withTranslation} from "react-i18next";
import ContentWrapper from "../../components/ContentWrapper";
import {Col, Row} from "antd";
import Panel from "../../components/Panel";
import TCOAdminSettings from "../../components/TCOAdminSettingsForm";
import TCOAdminAttributes from "../../components/TCOAdminAttributes";

type Props = {

};

type State = {

}

class TCORoute extends Component<Props, State> {

  render() {
    const { t } = this.props;

    return <NavigationContainer
        scroll
    navigationBar={<ContentHeader title={t('Total Cost of Ownership')} />}
    >
        <ContentWrapper >
            <Row gutter={12}>
               <Col md={12}>
                   <Panel title={t('Settings')}>
                       <TCOAdminSettings/>
                   </Panel>
               </Col>
                <Col md={12}>
                    <Panel title={t('TCO Attributes')}>
                        <TCOAdminAttributes />
                    </Panel>
                </Col>
            </Row>
        </ContentWrapper >
    </NavigationContainer>
  }
}

export default withTranslation()(TCORoute);
