import React from "react";
import type {TDAAttributeFormOnChange} from "../TDAAttributesForm";
import TDAAttributesForm from "../TDAAttributesForm";
import Loader from "./../Loader";
import {useQuery} from "@apollo/client";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import BookmarkQuery from "../../graphql/queries/bookmarkData.graphql";
import _ from "lodash";
import {useTranslation} from "react-i18next";

interface Props {
    disableBookmark: boolean,
    params: any,
    onChange: TDAAttributeFormOnChange
}

const ComponentSearchForm = ({params, onChange, disableBookmark, cleanDefaultValues}: Props) => {

    const typeDefinitionAttributeListQuery = useQuery(TypeDefinitionAttributeListQuery, {
        variables: {
            index: 'componentFilter'
        }
    });

    const bookmarkQuery = useQuery(BookmarkQuery, {
        skip: !_.get(params, 'selectionBookmark') || disableBookmark,
        variables: {
            bookmarkId: _.get(params, 'selectionBookmark')
        }
    })

    const {t} = useTranslation();

    if (typeDefinitionAttributeListQuery.loading || bookmarkQuery.loading) return <Loader size={'small'}/>

    let attributes = [..._.get(typeDefinitionAttributeListQuery, 'data.typeDefinitionAttributeList.typeDefinitionAttributes', [])];

    if (cleanDefaultValues) {
        attributes = attributes.map((item) => ({
            ...item,
            value: undefined
        }))
    }

    if (!disableBookmark && bookmarkQuery && _.get(bookmarkQuery, 'data.bookmark.data')) {
        const bookmarkData = JSON.parse(_.get(bookmarkQuery, 'data.bookmark.data'));
        const count = _.get(bookmarkData, 'componentIds', []).length;

        if (!_.find(attributes, {index: 'selectionBookmark'})) {
            attributes.unshift({
                index: 'selectionBookmark',
                inputType: 'button',
                title: t("Selection") + `: ${_.get(bookmarkQuery, 'bookmark.title')} (${count})`,
                icon: 'delete',
                onClick: () => {
                    this._handleChanges({
                        selectionBookmark: null
                    })
                }
            })
        }
    }


    return <TDAAttributesForm
        cacheChanges={false}
        layout={'vertical'}
        onChange={onChange}
        useSubmit={false}
        values={params}
        attributes={_.map(attributes, (attribute: any) => {
            if (attribute.inputType.toLowerCase() === 'select' && attribute.multiple) {
                return {
                    ...attribute,
                    maxTagCount: 1
                }
            }

            return attribute;
        })}
    />;
}

export default ComponentSearchForm;
