// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import LogisticianCreate from "./../../components/LogisticianCreate";
import ContentHeader from "../../components/Typo/ContentHeader";
import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import Strings from "../../i18n/strings";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {withProps} from "recompose";
import _ from "lodash";
import queryString from "query-string";
import withResources from "../../hoc/withResources";

const ContentWrapper = styled.div`
   padding: ${2 * Sizes.grid}px
`;
type Props = {
    onSubmit: Function,
    filter: any
}

class NewLogistician extends Component<Props, State> {


    render() {
        const {t, onSubmit, filter} = this.props;

        return <div>
            <ContentHeader title={t('New Logistician')}/>
            <ContentWrapper>
                <Panel title={t(Strings.pleaseProvideSomeDetails)}>
                    <LogisticianCreate onSubmit={(res) => onSubmit(res)} filter={filter}/>
                </Panel>
            </ContentWrapper>

        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        const query = _.get(props, 'history.location.search');
        return {
            filter: queryString.parse(query),
        }
    }),
    withResources(["logistician"])
)(NewLogistician);
