import React from 'react';
import {Button} from "antd";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo from '../../assets/logo/virtual-service-management-w240.png';
import externalUrls from "../../statics/externalUrls";
import _ from "lodash";
import useTheme from "../../hooks/useTheme";

export const nonAccessRoles = ["OPEN", "LEAD", "REJECTED"];

interface Props {
    role: "OPEN" | "LEAD" | "REJECTED"
}

const NoAccessPanel = ({role}: Props) => {

    let { themeConfig } = useTheme();
    const {t, i18n} = useTranslation();
    const environment =  _.get(window["__BE__"], 'env', "production");
    const language = i18n.language === "de" ? "de" : "en";
    const myAccountLink = (_.get(externalUrls[environment], 'pvWebsite') || externalUrls["production"].pvWebsite) + `/global/${language}/shop/my-account/account-data`

    const loginLogo = themeConfig["@login-logo"] || logo;

    return (
        <div className={"flex-col flex-align-items-center"}>
            <img className="logo mb-20" alt={'logo'} src={loginLogo}/>
            <div className={"mb-20"}>{t(role + "_no_access_text")}</div>
            {
                role === "LEAD" ?
                    <div>
                        <Button
                            type={"primary"}
                            style={{width: 180}}
                            href={myAccountLink}
                        >
                            {t("Account settings")}
                        </Button>
                    </div>
                    : null
            }
            <div className={role === "LEAD" ? "mt-10" : ""}>
                <Link to={'/logout'}>
                    <Button type={"default"} style={{width: 180}}>{t("Logout")}</Button>
                </Link>
            </div>
        </div>
    );
}

export default NoAccessPanel;
