// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";

import Toolbar from "../Toolbar";
import AttributesInlineForm from "../Form/attributes-inline-form";


type Props = {
    values: any,
    t: Function,
    enums: any,
    onChange: Function,
};

class MatchBookmarksToolbar extends Component<Props, State> {
    render() {
        const {values, t, enums, onChange} = this.props;

        const attributes = [{
            index: 'query',
            type: 'string',
            inputType: 'text',
            inputTypeOptions: {
                placeholder: t('Search'),
            },
        },
            {
                index: 'type',
                type: 'string',
                entityType: 'type',
                inputType: 'select',
                inputTypeOptions: {
                    placeholder: t('Types'),
                },
                optionValues: enums.map(item => ({
                    name: item.name,
                    value: item.name
                })),
            }];

        return <Toolbar style={{paddingBottom: 10}} renderLeft={() => <AttributesInlineForm
            layout={'inline'}
            onValuesChange={(changes) => {
                onChange(changes)
            }}
            values={values}
            attributes={attributes}
            useSubmit={false}
        />}/>
    }
}

export default compose(
    withTranslation(),
)(MatchBookmarksToolbar);
