// @flow
import React, {Component} from 'react';
import Redirect from "./../Redirect";
import _ from "lodash";
import {compose} from "recompose";
import MongoIdTranslatorQuery from "../../graphql/queries/mongoIdTranslator.graphql"
import {graphql} from '@apollo/client/react/hoc';
import {withRouter} from "react-router-dom";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {

};

type State = {

}

class TicketRedirect extends Component<Props, State> {

  render() {
    const { mongoIdTranslatorQuery } = this.props;

    return <Redirect from={"/service/ticket/:mongoId"} to={`/service/${_.get(mongoIdTranslatorQuery,'mongoIdTranslator.id')}`}/>;
  }
}


export default compose(
    withRouter,
    graphql(MongoIdTranslatorQuery, {
      name: 'mongoIdTranslatorQuery',
      options: (props) => ({
       variables: {
         mongoId: _.get(props, 'match.params.mongoId'),
         type: 'serviceTicket'
       }
      })
    }),
    waitWhileLoading('mongoIdTranslatorQuery')
)(TicketRedirect);

