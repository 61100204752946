// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ProductInfoBox from "../ProductInfobox";
import MutationButton from "../MutationButton";
import UpdateComponentProductMutation from "../../graphql/mutations/updateComponentProduct.graphql"
import {withTranslation} from "react-i18next";

type Props = {
  productId: string,
  componentId: string,
    onDone: (res: any) => void
};

type State = {

}

class ComponentProductChangePerformer extends Component<Props, State> {

  render() {
    const { productId, updateComponentProductMutation, t, onDone } = this.props;

    return <div>
      <ProductInfoBox productId={productId} />
      <div className={"text-right"}>
          <MutationButton onDone={onDone} buttonProps={{type: "primary"}} mutation={updateComponentProductMutation} >
              {t("Change Product")}
          </MutationButton>
      </div>
    </div>;
  }
}

export default compose(
    withTranslation(),
    graphql(UpdateComponentProductMutation, {
      name: 'updateComponentProductMutation',
      options: (props) => ({
        variables: {
            componentId: props.componentId,
            productId: props.productId
        }
      })
    })
)(ComponentProductChangePerformer);
