// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {Button, Popconfirm} from "antd";
import {useMutation} from "@apollo/client";
import ConfirmComponentAlertLogsMutation from '../../graphql/mutations/confirmComponentAlertLogs.graphql';
import ComponentLogTimelineQuery from "../../graphql/queries/componentLogTimeline.graphql";
import './index.css';
import ComponentActiveAlertQuery from "../../graphql/queries/component-ActiveAlert.graphql";

type Props = {
    componentId: string,
    logIds?: any[],
    refetchQueries?: any[],
    title?: string
}

const ConfirmActiveAlertButton = ({componentId, logIds, refetchQueries, title}: Props) => {
    const {t} = useTranslation();

    const defaultRefetchQueries = [{
        query: ComponentLogTimelineQuery,
        variables: {
            id: componentId
        }
    }, {
        query: ComponentActiveAlertQuery,
        variables: {
            id: componentId
        }
    }];

    const [confirmComponentAlertLogs] = useMutation(ConfirmComponentAlertLogsMutation, {
        refetchQueries: defaultRefetchQueries.concat(refetchQueries)
    });

    return <Popconfirm title={t('Are you sure?')}
                       onConfirm={() => {
                           return confirmComponentAlertLogs({
                               variables: {
                                   componentId,
                                   logIds
                               }
                           });
                       }}
    >
        <Button
            type={'primary'}
            className={'ConfirmButton resetMargin autoHeight'}
        >
            {title ? title : logIds && logIds.length === 1 ? t('Confirm alert') : t('Confirm alerts')}
        </Button>
    </Popconfirm>
};

ConfirmActiveAlertButton.defaultProps = {
    refetchQueries: []
}

export default ConfirmActiveAlertButton
