// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {message, Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import _ from "lodash"
import ProductComponentListExportQuery from "../../graphql/queries/productComponentListExport.graphql";

type Props = {
    t: Function,
    productId: string
};

type State = {
    loading: boolean
}

class ProductComponentsExport extends Component<Props, State> {

    state = {
        loading: false
    };

    handleDownload = () => {
        const {client, t} = this.props;

        this.setState({
            loading: true
        }, () => {
            client.query({
                query: ProductComponentListExportQuery,
                variables: {
                    id: this.props.productId
                }
            }).then(({data}) => {
                const link = _.get(data,'productComponentList.export.link');
                if (!link){
                    return message.error(t("No export link found"));
                }
                window.open(link);
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })


    };

    render() {
        const {t} = this.props;
        const {loading} = this.state;

        return <Tooltip title={t('Export')}>
            <Button icon={"download"} loading={loading} onClick={this.handleDownload} >

            </Button>
        </Tooltip>
    }
}

export default compose(
    withTranslation(),
    withRouter,
    withApollo,
    withTranslation()
)(ProductComponentsExport);
