// @flow
import React, {Component} from "react"
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import product from "../../graphql/queries/productWithComponentTypeAttributes.graphql"
import createComponent from "../../graphql/mutations/createComponent.graphql"
import ComponentTypeInfoBox from "../ComponentTypeInfoBox";
import AttributesInlineForm from "../Form/attributes-inline-form";
import Panel from "../Panel";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Col, Row} from "antd";
import ComponentInfoBox from "../ComponentInfoBox";
import ProductInfoBox from "../ProductInfobox";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import Label from "../Utilities/Label";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import withProfile from "../../hoc/withProfile";
import isServiceProvider from "../../utils/isServiceProvider";

type Props = {
    parentComponentId: string,
    componentTypeIndex: string,
    productId: number,
    onCreated: Function,
    product: {
        name: string,
        catalogNo: string,
        manufacturer: string,
        manufacturerId: number,
    },
    getComponentType: {
        loading: boolean,
        componentType: any,
    },
    getProduct: {
        loading: boolean,
        product: any,
    },
    createComponent: Function,
    t: Function,
}

class ComponentCreateForm extends Component<Props> {


    values = {};

    shouldComponentUpdate() {
        return false;
    }

    render() {

        const {attributeQuery, getProduct, t, createComponent, parentComponentId, productId, onCreated, product, client} = this.props;


        let componentTypeIndex = this.props.componentTypeIndex;

        let productObject = null;

        if (product && Object.keys(product).length ){
            productObject = {...this.props.product};
            if(productId){
                productObject = {
                    name: getProduct.product.name,
                    catalogNo: getProduct.product.catalogNo
                }
            }
            if (isNaN(parseInt(productObject.manufacturerId))){
                _.set(productObject,'manufacturer',{
                    name: productObject.manufacturerId
                });
                delete productObject.manufacturerId;
            }
        }



        const attributes = _.get(attributeQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes',[]);
        const mainFields = ["name", "serialNo", ...attributes.filter(item => _.findIndex(item.rules, {name: 'required'}) !== -1).map(item => item.index)];

        if (isServiceProvider(this.props.resources)){
            mainFields.push("clientId");
        }

        return <Panel>
            <Row gutter={32}>
                <Col className={"br"} md={10}>

                    <Label>{t('New component')}</Label>
                    <div className={'white-bg border-radius-5 border-primary border-dashed border-width-2 p-10'}>
                        {productId ? <ProductInfoBox productId={productId}/> :
                            <ComponentTypeInfoBox componentTypeIndex={componentTypeIndex}/>}
                    </div>

                    <div className={"text-center mv-5"}>
                        <FontAwesomeIcon name={"long-arrow-alt-down"} size={"3x"}/>
                    </div>

                    <div className={'lighter-grey-bg border-radius-5 border'}>
                        <ComponentInfoBox
                            componentId={parentComponentId}/>
                    </div>
                </Col>
                <Col md={14}>
                    <Label>{t('Details')}</Label>
                    <AttributesInlineForm
                        useSubmit
                        layout={'vertical'}
                        collapse={mainFields}
                        mutation={(options) => {
                            return new Promise((resolve, reject)=> {

                                createComponent(options)
                                    .then((res) => {
                                        return client.clearStore().then(() => res);
                                    })
                                    .then(resolve)
                                    .catch(reject)
                            })
                        }}
                        onSubmit={onCreated}
                        translateMutationOptions={({values}) => {
                            Object.keys(values).map((key) => {
                                if(values[key] === "" || values[key] === null){
                                    delete values[key]
                                }
                            });
                            return {
                                variables: {
                                    parentId: parentComponentId,
                                    componentCreateInput: {
                                        componentTypeIndex: componentTypeIndex,
                                        productId,
                                        attributes: values,
                                        product:productObject ? productObject : undefined,
                                    }
                                }
                            }
                        }}
                        submitTitle={t('Add')}
                        attributes={attributes}
                        />
                </Col>
            </Row>

        </Panel>;
    }

}

export default compose(
    withTranslation(),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'attributeQuery',
        options: props => ({
            variables: {
                index: 'component',
                options: {
                    parentId: props.parentComponentId,
                    componentTypeIndex: props.componentTypeIndex,
                    productId: props.productId
                }
            }

        }),
    }),
    waitWhileLoading('attributeQuery'),

    graphql(product, {
        name: 'getProduct',
        skip: props => !props.productId,
        options: props => ({
            variables: {
                id: props.productId
            }
        }),
    }),
    waitWhileLoading('getProduct', null, {optional: true}),

    graphql(createComponent, {
        name: 'createComponent',
    }),
    withProfile(),
    withApollo,
)(ComponentCreateForm);
