// @flow
import React, {Component} from 'react';
import {EditableWrapper} from "@dreebit-registry/form-attributes-vsm";

type Props = {

};

type State = {

}

class AttributeHidden extends Component<Props, State> {


    render() {
        const { form, attribute, style } = this.props;

        return <EditableWrapper
            className={'form-attribute-hidden form-attribute-hidden-'+attribute.index}
            form={form}
            attribute={attribute}
            style={style}
        >
            { ({value, onChange}) => {
                return <input type={"hidden"} value={value}/>
            }}
        </EditableWrapper>;
    }
}

export default AttributeHidden;
