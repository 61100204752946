// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ComponentLogTimeline from "../../components/ComponentLogTimeline";
import ComponentLogToolbar from "../../components/ComponentLogToolbar";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentActiveAlertQuery from "../../graphql/queries/component-ActiveAlert.graphql";

type Props = {
    componentId: any,
    onChange?: (values: any) => void,
    componentActiveAlertQuery?: any,
    values?: any
};

type State = {
    values: any,
}

class ComponentLogs extends Component<Props, State> {
    static defaultProps = {
        values: {logLevel: [], logType: []},
    };

    onChangeToolbar = (changes) => {
        if (this.props.onChange) {
            this.props.onChange({
                ...this.props.values,
                ...changes
            })
        }
    };

    render() {
        const {componentId, componentActiveAlertQuery, values} = this.props;


        return <div>
            <ComponentLogToolbar values={this.props.values}
                                 componentId={componentId}
                                 onChange={this.onChangeToolbar}
                                 activeAlert={_.get(componentActiveAlertQuery, 'component.activeAlerts.total', 0) > 0}
            />
            <ComponentLogTimeline values={this.props.values} componentId={componentId}/>
        </div>
    }
}

export default compose(
    withRouter,
    graphql(ComponentActiveAlertQuery, {
        name: 'componentActiveAlertQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentActiveAlertQuery', 'component'),
    withTranslation()
)(ComponentLogs);
