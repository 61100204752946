// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import CreateServiceControl from "../../graphql/mutations/createServiceControl.graphql";
import ServiceControlCreateTypeDefinitionAttributeList
    from "../../graphql/queries/serviceControlCreateTypeDefinitionAttributeList.graphql";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import ServicePlanAndControlQuery from "../../graphql/queries/servicePlanAndControlList.graphql";

type Props = {
    componentId: string,
    serviceControlCreateTypeDefinitionAttributeList: any,
    createServiceControl: Function,
    onSaved: Function
};


class ServiceControlCreateForm extends Component<Props, State> {
    render() {
        const {createServiceControl, componentId, serviceControlCreateTypeDefinitionAttributeList, t} = this.props;

        return <AttributesInlineForm
            attributes={_.get(serviceControlCreateTypeDefinitionAttributeList, 'typeDefinitionAttributeList.typeDefinitionAttributes')}
            layout={'horizontal'}
            useSubmit={true}
            onSubmit={(res) => {
                this.props.onSaved(res);
            }}
            t={t}
            mutation={createServiceControl}
            translateMutationOptions={({values}) => ({
                variables: {
                    id: componentId,
                    input: values
                }
            })}
        />;
    }
}


export default compose(
    graphql(CreateServiceControl, {
        name: 'createServiceControl',
        options: props => ({
            refetchQueries: [{
                query: componentQuery,
                variables: {
                    id: props.componentId
                }
            }, {
                query: ServicePlanAndControlQuery,
                variables: {
                    params: {
                        filter: {
                            componentId: {
                                value: props.componentId,
                            }
                        }
                    }
                }
            }]
        })
    }),
    graphql(ServiceControlCreateTypeDefinitionAttributeList, {
        name: 'serviceControlCreateTypeDefinitionAttributeList'
    }),
    waitWhileLoading('serviceControlCreateTypeDefinitionAttributeList', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
    withTranslation(),
)(ServiceControlCreateForm);
