import React, {useState} from 'react';
import {Steps} from "antd";
import {serviceProviderSteps, defaultSteps} from "./stepConfig";
import CreateClientGeneralStep from "./step1";
import CreateClientAddressStep from "./step2";
import CreateClientClientInformationStep from "./step3";
import {useTranslation} from "react-i18next";
import useResourceCheck from "../../hooks/useResourceCheck";

const Components: {[key: string]: any} = {
    "CreateClientGeneralStep": CreateClientGeneralStep,
    "CreateClientAddressStep": CreateClientAddressStep,
    "CreateClientClientInformationStep": CreateClientClientInformationStep
}

const CreateClientSteps = ({}: Props) => {

    const {t} = useTranslation();
    const [currentStep, setCurrentStep] = useState(0)
    const [client, setClient] = useState(null)
    const [addressData, setAddressData] = useState({})
    const [searchClientQuery, setSearchClientQuery] = useState("")
    const isServiceProvider = useResourceCheck("serviceProvider")

    const steps = isServiceProvider ? serviceProviderSteps : defaultSteps;

    const nextStep = () => {
        if (currentStep + 1 < steps.length) {
            setCurrentStep(currentStep + 1)
        }
    }

    let content = null;

    if (typeof Components[steps[currentStep].component] !== "undefined") {
        content = React.createElement(Components[steps[currentStep].component], {
            onNextStep: () => nextStep(),
            setClient: (client) => setClient(client),
            client: client,
            setAddressData: (values) => setAddressData(values),
            addressData: addressData,
            searchClientQuery: searchClientQuery,
            setSearchClientQuery: (query) => setSearchClientQuery(query),
            isServiceProvider: isServiceProvider
        });
    }

    const stepsTranslated = steps.map((step) => {
        return {
            ...step,
            title: t(step.title)
        }
    })

    return (
        <div className={"m-25"}>
            <Steps
                className={'mb-40'}
                current={currentStep}
                items={stepsTranslated}
                onChange={(step) => {
                    if (step === 0) {
                        setClient(null)
                        setAddressData({})
                    }
                    if (step <= currentStep) {
                        setCurrentStep(step)
                    }
                }}
            />
            {content}
        </div>
    );
}

export default CreateClientSteps;
