// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {Col, Row, Spin} from "antd";
import ClientAdminForm from "./../ClientAdminForm";
import ClientUserList from "./../ClientUserList";
import Panel from "../Panel";
import Sizes from "../../themes/default/sizes";
import useProfile from "../../hooks/useProfile";
import _ from "lodash";
import {useQuery} from "@apollo/client";
import ClientActivatedByIdQuery from "../../graphql/queries/clientActivatedById.graphql"
import withResources from "../../hoc/withResources";

interface Props {
    clientId: string
}

const ClientUsersDashboard = ({clientId}: Props) => {

    const {t} = useTranslation();
    const profile = useProfile();
    const clientActivatedByIdQuery = useQuery(ClientActivatedByIdQuery, {
        variables: {
            id: clientId
        }
    })

    if (clientActivatedByIdQuery.loading) {
        return <Spin />
    }

    const clientActivatedById = _.get(clientActivatedByIdQuery, 'data.client.activatedBy.id')
    const profileClientId = _.get(profile, 'client.id')
    const showAddAdminPanel = (profileClientId === clientId) || (profileClientId === clientActivatedById)

    return <div className={'ph-10'}>
        <Row gutter={2 * Sizes.grid}>
            <Col md={12}>
                <Panel title={t('Admins')} containerStyle={{height: 400}}>
                    <ClientUserList clientId={clientId}/>
                </Panel>

            </Col>
            {
                showAddAdminPanel ?
                    <Col md={12}>
                        <Panel title={t('Add admin')}>
                            <ClientAdminForm clientId={clientId}/>
                        </Panel>
                    </Col>
                : null
            }
        </Row>
    </div>;

}

export default withResources(["administrateClients"])(ClientUsersDashboard);
