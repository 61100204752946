// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import ContentHeader from "../../components/Typo/ContentHeader";

import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import CustomAttributeCreate from "../../components/CustomAttributeCreate";
import withResources from "../../hoc/withResources";

const ContentWrapper = styled.div`
   padding: ${Sizes.grid}px;
`;

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};


class CustomAttributeCreateRoute extends Component<Props, State> {

    _handleOnCreate = (res) => {
        const {history} = this.props;
        const index = _.get(res, 'data.createCustomAttribute.index');
        if (index) {
            history.push(`/admin/attributes/${index}`);
        } else {
            history.push(`/admin/attributes`);
        }
    };

    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader title={t('Custom Attribute')}/>
            <ContentWrapper>
                <Panel>
                    <CustomAttributeCreate onCreate={this._handleOnCreate}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default withResources(["administrateCustomAttributes"])(withTranslation()(CustomAttributeCreateRoute)) ;
