// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ClientAccessRequestQuery from "../../graphql/queries/clientAccessRequest.graphql";

const ClientBreadcrumb = ({clientAccessRequestQuery}) =>
    <span>{_.get(clientAccessRequestQuery, 'clientAccessRequest.status', '...')}</span>;

export default compose(
    graphql(ClientAccessRequestQuery, {
        name: 'clientAccessRequestQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.id'),
            }
        })
    }),
)(withTranslation()(ClientBreadcrumb));
