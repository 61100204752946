// @flow
import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import _ from "lodash";

import ContentHeader from "../../components/Typo/ContentHeader";
import {Col, Grow, Row} from "../../components/Flex";
import ComponentTypesList from "./../../components/ComponentTypesList";
import ComponentTypeDefaultServiceTask from "../../components/ComponentTypeDefaultServiceTask";
import ComponentTypeCreateDefaultServiceTask from "../../components/ComponentTypeCreateDefaultServiceTask";
import ComponentTypeServiceReportConfig from "../../components/ComponentTypeServiceReportConfig";
import ComponentTypeCreateServiceReportConfig from "../../components/ComponentTypeCreateServiceReportConfig";
import ComponentTypeDashboard from "../../components/ComponentTypeDashboard";
import {compose} from "recompose";
import ComponentTypeServiceReportConfigDashboard from "../../components/ComponentTypeServiceReportConfigDashboard";
import Panel from "../../components/Panel";

type Props = {
    t: Function,
    match: {
        params: {
            index: string,
            defaultServiceTaskId: string,
            serviceReportConfigIndex: string,
        }
    },
    history: {
        push: Function
    }
};


class ComponentTypesMasterDetail extends Component<Props> {


    render() {
        const {t, match, history} = this.props

        return (
            <Row className={'full-size'}>
                <Col style={{borderRight: "1px solid #cfdbe2", width: "20%", minWidth: 300}}>
                    <ContentHeader hideBreadcrumb title={t('Component Types')}/>
                    <div style={{height: 'calc(100vh - 120px)', overflow: 'scroll'}}>
                        <ComponentTypesList value={true}/>
                    </div>
                </Col>
                <Grow className={"overflow-scroll"}>
                    <Switch>
                        <Route
                            exact
                            path='/catalog/componentTypes/detail/:index/defaultServiceTasks/:defaultServiceTaskId'
                            component={(props) => (
                                <ComponentTypeDefaultServiceTask index={_.get(match, 'params.index')}/>)}
                        />
                        <Route
                            path='/catalog/componentTypes/detail/:index/create'
                            component={() => (
                                <ComponentTypeCreateDefaultServiceTask index={_.get(match, 'params.index')}/>)}
                        />
                      <Route
                        exact path='/catalog/componentTypes/detail/:index/serviceReportConfig'
                        component={() => (<ComponentTypeServiceReportConfigDashboard
                            onServiceReportConfigRowClick={(row) => {
                                return history.push(`/catalog/componentTypes/detail/${row.componentType}/serviceReportConfig/${row.index}`)
                            }}
                            onCategoryListRowClick={(row) => {
                                return history.push(`/catalog/categories/${row.id}`)
                            }}
                        />)}
                      />
                        <Route
                          path='/catalog/componentTypes/detail/:index/serviceReportConfig/create'
                          component={() => (<div>
                              <ContentHeader title={t('New service report template')}/>
                              <div className={'m-10'}>
                                  <Panel>
                                      <ComponentTypeCreateServiceReportConfig
                                          index={_.get(match, 'params.index')}
                                          onCreateServiceReportConfig={(index) => {
                                              history.push(`/catalog/componentTypes/detail/${index}/serviceReportConfig`)
                                          }}
                                      />
                                  </Panel>
                              </div>
                          </div>)}
                        />
                        <Route
                          exact path='/catalog/componentTypes/detail/:index/serviceReportConfig/:serviceReportConfigIndex'
                          component={() => (<ComponentTypeServiceReportConfig
                            index={_.get(match, 'params.index')}
                            serviceReportConfigIndex={_.get(match, 'params.serviceReportConfigIndex')}
                            onUpdateServiceReportConfig={(index) => {
                                history.push(`/catalog/componentTypes/detail/${index}/serviceReportConfig`)
                            }}
                            handleDeleteRedirect={(index) => {
                                history.push(`/catalog/componentTypes/detail/${index}/serviceReportConfig`)
                            }}
                          />)}
                        />
                        <Route
                            path='/catalog/componentTypes/detail/:index'
                            component={() => (<ComponentTypeDashboard
                              index={_.get(match, 'params.index')}
                              onServiceReportConfigRowClick={(row) => {
                                  return history.push(`/catalog/componentTypes/detail/${row.componentType}/serviceReportConfig/${row.index}`)
                              }}
                            />)}
                        />
                    </Switch>
                </Grow>
            </Row>
        );
    }
}

export default compose(
    withRouter,
    withTranslation()
)(ComponentTypesMasterDetail);
