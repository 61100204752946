// @flow
import React, {Component, createElement} from "react"
import {Alert, message} from 'antd';
import {Button} from "@dreebit/react-components";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import SubmitDebugMessageMutation from "./../../graphql/mutations/submitDebugMessage.graphql";
import {graphql} from '@apollo/client/react/hoc';
import MutationButton from "../MutationButton";
import {compose, withProps} from "recompose";
import Exception from "../Exception";
import {Col, Grow, Row} from "../Flex";

type ErrorStatusEnum = 'default' | '404';

type Props = {
    t: Function,
    error?: any,
    title?: string,
    description?: string | any,
    type?: string,
    onRetry?: Function,
    closeText?: string,
    closeable?: boolean,
    enableFeedback?: boolean,
    feedbackData?: any,
    onClose?: Function,
    status?: ErrorStatusEnum,
    backToHome?: Boolean
};

class Error extends Component<Props> {

    static defaultProps = {
        type: "error",
        status: 'default'
    };

    onSendFeedback() {

    }

    renderDescription() {

        const { t, error, submitDebugMessageMutation, backToHome} = this.props;

        const text = this.props.description || _.get(error, 'message', error ? error.toString() : null);

        if (this.props.enableFeedback){
            return <div>
                <Row>
                    <Grow>
                        <div style={{
                            width: '100%',
                            maxHeight: 100,
                            overflow: 'scroll',
                            marginTop: 10,
                            marginBottom: 10
                        }}>
                            {text}
                        </div>
                    </Grow>
                    <Col>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                            {
                                backToHome
                                    ? createElement(
                                    'a',
                                    {
                                        to: '/',
                                        href: '/',
                                    },
                                    <Button type="primary" icon={'home'}>{t('Back to home')}</Button>
                                    )
                                    : null
                            }
                            <MutationButton
                                onDone={() => {
                                    message.success(t("Sent feedback"))
                                }}
                                onError={() => message.error(t("Could not send feedback"))}
                                mutation={submitDebugMessageMutation}
                                buttonProps={{
                                    type: "danger",
                                    icon: "alert"
                                }}>{t("Send Feedback")}</MutationButton>
                        </div>
                    </Col>
                </Row>

            </div>
        }

        return text
    }

    renderContent() {
        const {status, t, error} = this.props;

        switch (status) {
            case 404:
            case '404': {

                return <Exception
                    type={'404'}
                    title={this.props.title || _.get(error, 'name', t('404'))}
                    desc={this.props.description || _.get(error, 'message', t('Content not found'))}
                />
            }
            case 500:
            case '500': {
                return <Exception
                    type={'500'}
                    title={this.props.title || _.get(error, 'name', t('404'))}
                    desc={this.props.description || _.get(error, 'message', t('Content not found'))}
                />
            }
            default: {
            }
        }


        return <Alert message={this.props.title || _.get(error, 'name', t('An error occurred'))}
                      description={this.renderDescription()}
                      type={this.props.type}
                      closable={this.props.closeable}
                      closeText={this.props.closeText}
                      onClose={this.props.onClose}
        />
    }

    render() {


        return <div className={'errorComponent'}>
            {
                this.renderContent()
            }

        </div>;
    }

}

export default compose(
    withTranslation(),
    withProps((({error, title, description}) => ({
            title: title || _.get(error, 'name'),
            description:  description || _.get(error, 'message', error ? error.toString() : null)
        }))
    ),
    graphql(SubmitDebugMessageMutation, {
        name: 'submitDebugMessageMutation',
        options: (props) => {
            return {
                variables: {
                    input: {
                        type: "error",
                        text: _.isString(props.description) ? props.description : props.title,
                        data: props.feedbackData ? JSON.stringify(props.feedbackData) : undefined,
                    }
                }
            }
        }
    }),
)(Error);
