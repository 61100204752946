// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import CreateLogisticianMutation from "./../../graphql/mutations/CreateLogisticianMutation.graphql";
import LogisticiansListQuery from "./../../graphql/queries/LogisticiansListQuery.graphql";
import _ from "lodash";

type Props = {
    onSubmit: Function,
    createLogisticianMutation: Function,
};


class LogisticianCreate extends Component<Props, State> {


    render() {
        const {createLogisticianMutation, onSubmit} = this.props;

        return <TypeDefinitionAttributesForm
            index={'logistician'}
            formProps={{
                useSubmit: true,
                mutation: createLogisticianMutation,
                onSubmit: (res) => {
                    onSubmit(res);
                },
                translateMutationOptions: ({values}) => {
                    if(values && values.imageId !== ""){
                        return {
                            variables: {
                                input: {
                                    name: values.name,
                                    linkTemplate: values.linkTemplate,
                                    imageId: values.imageId
                                }
                            }
                        }
                    }
                    return {
                        variables: {
                            input: {
                                name: values.name,
                                linkTemplate: values.linkTemplate
                            }
                        }
                    }
                }
            }}/>;
    }
}

export default compose(
    graphql(CreateLogisticianMutation, {
        name: 'createLogisticianMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: LogisticiansListQuery,
                variables: {
                    params: {
                        limit: 40,
                        start: 0,
                        search: {
                            query: _.get(props, 'filter.query', ''),
                            fields: ['name']
                        }
                    }
                }
            }]
        })
    }),
    withTranslation(),
)(LogisticianCreate);
