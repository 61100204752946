// @flow
import React, {Component} from 'react';
import {Progress} from "antd";
import {withTranslation} from "react-i18next";

type Props = {
    total: number,
    finished: number,
};


class BulkProgress extends Component<Props, State> {

    render() {
        const {finished, total} = this.props;

        const percent = finished / total * 100;
        //const format = percent === 100 ? () => t('Done') : null;

        return <div className={"text-center pv-10"}>
            <div>
                <Progress
                    type="circle"
                    format={() => `${finished}/${total}`}
                    percent={Math.floor(percent)}
                />
            </div>
            <div className={"mt-10"}>
                {this.props.children}
            </div>
        </div>
    }
}

export default withTranslation()(BulkProgress);
