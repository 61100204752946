// @flow
import React, {Component} from 'react';
import QueryList from "../QueryList";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {Badge, Empty, List} from "antd";
import {Link} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import NotificationListQuery from "../../graphql/queries/notificationList.graphql";
import OneLineLabel from "../OneLineLabel";
import readNotifications from "../../graphql/mutations/readNotifications.graphql";
import Colors from "../../themes/default/colors";
import {Grow, Row} from "../Flex";
import UnreadNotificationsQuery from "../../graphql/queries/unreadNotifications.graphql";


type Props = {
    notificationListQuery: query,
    width: any,
    t: Function,
    dottedWidth: any,
    readNotificationsMutation: Function,
    onClick?: Function
};

class NotificationList extends Component<Props, State> {

    unread = (id) => {
        const {readNotificationsMutation} = this.props;
        readNotificationsMutation({variables: {id: [id]}});
    };

    onClick = (item) => {

        this.unread(item.id);
        if(this.props.onClick) this.props.onClick();
    };

    render() {
        const {notificationListQuery, t, dottedWidth, onClick} = this.props;

        return <QueryList
            query={notificationListQuery}
            empty={<Empty description={t('No unread notifications at the moment')}/>}
            itemsKeyPath={'notificationList.notifications'}
            totalKeyPath={'notificationList.total'}
            renderItem={(item) => {
                let link = null;
                if(item.link) link = item.link.match(/(?=#)(.*)/)[0].replace('#', '');

                let title = <Row>
                    <Grow>
                        <b>{item.subject}</b>
                    </Grow>
                    <span style={{
                        color: Colors.grey,
                        fontSize: '10px',
                        paddingLeft: '10px'
                    }}>{moment(item.insert).format('LLL')}</span>
                </Row>;

                const list = <List.Item key={item.id}>
                    <List.Item.Meta
                        avatar={item.read ? null : <Badge dot/>}
                        title={title}
                        description={<OneLineLabel style={{maxWidth: 400}}>
                            <span>{item.content}</span>
                        </OneLineLabel>}
                    />
                </List.Item>;

                return link ? <Link key={item.id}
                                         to={link}
                                         onClick={() => this.onClick(item)}
                                    >{list}</Link>
                    : <a key={item.id}
                            onClick={() => this.onClick(item)}
                    >{list}</a>
            }}
        />;
    }
}

export default compose(
    graphql(NotificationListQuery, {
        name: 'notificationListQuery',
        options: () => ({
            fetchPolicy: "cache-and-network",
            variables: {
                params: {
                    filter: {
                        read: {
                            value: false
                        }
                    },
                    start: 0,
                    limit: 6
                }
            }
        }),
    }),
    graphql(readNotifications, {
        name: 'readNotificationsMutation',
        options: () => ({
            refetchQueries: [{
                query: NotificationListQuery,
                variables: {
                    params: {
                        filter: {
                            read: {
                                value: false
                            }
                        },
                        start: 0,
                        limit: 6
                    }
                }
            },{
                query: UnreadNotificationsQuery
            },{
                query: NotificationListQuery,
                variables: {
                    params: {
                        filter: {
                            read: {
                                value: false
                            }
                        }
                    }
                }
            }]
        })
    }),
    withTranslation()
)(NotificationList);
