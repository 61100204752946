import React, {Component} from 'react';
import ComponentLogTimelineQuery from "./../../graphql/queries/componentLogTimeline.graphql";
import _ from 'lodash';
import QueryLogList from "../QueryLogList";
import LogDetailView from "../LogDetailView";

type Props = {
    componentId: string,
    values: any,
};

class ComponentLogTimeline extends Component<Props> {

    query = null;


    render() {
        const {componentId} = this.props;
        const logLevel = _.get(this.props, 'values.logLevel', []);
        const logType = _.get(this.props, 'values.logType', [])


        return <QueryLogList
            onQueryMounted={(query) => this.query = query}
            query={ComponentLogTimelineQuery}
            pagingSize={15}
            limitKeyPath={"params.limit"}
            variables={{
                id: componentId,
                params: {
                    filter: {
                        level: logLevel && logLevel.length ? {
                            operator: 'in',
                            value: logLevel
                        } : undefined,
                        logType: logType && logType.length ? {
                            operator: 'in',
                            value: logType
                        } : undefined
                        },
                    },
                    sort: {
                        insert: 'DESC'
                    },
                    limit: 15,
                    start: 0
                }}
            itemsKeyPath={'component.logList.logs'}
            totalKeyPath={'component.logList.total'}
            renderItems={(items) => <LogDetailView onRemoved={() => {
                this.query.loadPage(0);
            }}
                                                   logs={items}
                                                   componentId={componentId}
                                                   refetchQueries={[{
                                                       query: ComponentLogTimelineQuery,
                                                       variables: {
                                                           id: componentId,
                                                           params: {
                                                               filter: {
                                                                   level: logLevel && logLevel.length ? {
                                                                       operator: 'in',
                                                                       value: logLevel
                                                                   } : undefined,
                                                                   logType: logType && logType.length ? {
                                                                       operator: 'in',
                                                                       value: logType
                                                                   } : undefined
                                                               },
                                                               sort: {
                                                                   insert: 'DESC'
                                                               },
                                                               start: 0,
                                                               limit: 15
                                                           }
                                                       }
                                                   }]}
            />}
        />
    }
}


export default ComponentLogTimeline
