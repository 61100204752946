// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";

import ProfileQuery from "./../../graphql/queries/profileSessions.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ContentHeader from "../Typo/ContentHeader";
import ContentWrapper from "../ContentWrapper";
import ConnectorsListTable from "../ConnectorsListTable";
import {Link} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import Panel from "../Panel";


class ProfileConnectors extends Component<Props, State> {

    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader title={t('Connectors')}
                           tools={<div>
                               <Link to={'/profile/connectors/create'}>
                                   <Button icon={"plus"}>
                                       {t('Add')}
                                   </Button>
                               </Link>
                           </div>}/>
            <ContentWrapper>
                <Panel containerStyle={{padding: 0}}>
                    <ConnectorsListTable
                        onClick={(connector) => this.props.history.push(`/profile/connectors/${connector.id}`)}
                    />
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ProfileQuery, {
        name: 'ProfileQuery',
    }),
    waitWhileLoading('ProfileQuery'),
    withProps((props) => ({
        query: _.get(props, 'ProfileQuery'),
    }))
)(ProfileConnectors);
