import React from "react";
import Query from "./../../graphql/queries/serviceTicketSubTicketList.graphql"
import {useQuery} from "@apollo/client";
import ServiceTicketsTable from "../ServiceTicketsTable";

interface Props {
    serviceTicketId: string,
}

const ServiceTicketSubTicketList = ({serviceTicketId}: Props) => {

    const query = useQuery(Query, {
        fetchPolicy: 'cache-first',
        variables: {
            id: serviceTicketId
        }
    })

    return <ServiceTicketsTable
        showColumns={['name','component','client']}
        totalKeyPath={'serviceTicket.subServiceTicketList.total'}
        itemsKeyPath={'serviceTicket.subServiceTicketList.serviceTickets'}
        query={query} />;
}

export default ServiceTicketSubTicketList;
