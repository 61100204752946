// @flow
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import CreateServiceReportAttributeConfigMutation
  from "../../graphql/mutations/createServiceReportAttributeConfig.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceReportAttributeConfigListQuery from "../../graphql/queries/serviceReportAttributeConfigList.graphql";
import withResources from "../../hoc/withResources";
import ComponentTypeDefaultServiceTaskListQuery
  from "../../graphql/queries/componentTypeDefaultServiceTaskList.graphql";
import ComponentTypeQuery from "../../graphql/queries/componentType.graphql";


type Props = {
  index: string,
  t: Function,
  typeDefinitionAttributeListQuery: query,
  createServiceReportAttributeConfigMutation: Function,
  onCreateServiceReportConfig: (index: string) => void,
  history: {
    push: Function,
  },

};


class ComponentTypeCreateServiceReportConfig extends Component<Props> {

  render() {
    const {index, t, typeDefinitionAttributeListQuery, createServiceReportAttributeConfigMutation, onCreateServiceReportConfig} = this.props;

    const attributes = _.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);

    return <AttributesInlineForm
        attributes={attributes}
        t={t}
        useSubmit={true}
        onSubmit={(res) => {
          onCreateServiceReportConfig(index, res)
        }}
        mutation={createServiceReportAttributeConfigMutation}
        translateMutationOptions={({values}) => ({
          variables: {
            componentTypeIndex: index,
            input: values
          }
        })}
    />
  }
}

export default compose(
  withResources(["administrateServiceReports"]),
  withRouter,
  graphql(TypeDefinitionAttributeListQuery, {
    name: 'typeDefinitionAttributeListQuery',
    options: () => ({
      variables: {
        index: 'serviceReportAttributeConfig'
      }
    })
  }),
  waitWhileLoading('typeDefinitionAttributeListQuery', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
  graphql(CreateServiceReportAttributeConfigMutation, {
    name: "createServiceReportAttributeConfigMutation",
    options: (props: Props) => ({
      refetchQueries: [{
        query: ServiceReportAttributeConfigListQuery,
        variables: {
          params: {
            filter: {
              componentTypeIndex: {
                value: _.get(props, 'match.params.index')
              }
            }
          }
        }
      }, {
        query: ComponentTypeDefaultServiceTaskListQuery,
        variables: {
          componentTypeIndex: _.get(props, 'match.params.index')
        }
      }, {
        query: ComponentTypeQuery,
        variables: {
          componentTypeIndex: _.get(props, 'match.params.index')
        }
      }]
    }),
  }),
  withTranslation(),
)(ComponentTypeCreateServiceReportConfig);
