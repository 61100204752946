// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ProductLogTimeline from "../../components/ProductLogTimeline";
import ProductLogToolbar from "../../components/ProductLogToolbar";
import {graphql} from '@apollo/client/react/hoc';
import Enums from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    logLevelEnumsQuery: query,
    logTypeEnumsQuery: query,
    productId: any,
    onChange?: (values: any) => void
};

type State = {
    values: any,
}

class ProductLogs extends Component<Props, State> {


    static defaultProps = {
        values: {logLevel: [], logType: []},
    };

    onChangeToolbar = (changes) => {
        if (this.props.onChange) {
            this.props.onChange({
                ...this.props.values,
                ...changes
            })
        }
    };

    render() {
        const {logLevelEnumsQuery, productId, logTypeEnumsQuery} = this.props;


        return <div>
            <ProductLogToolbar values={this.props.values} productId={productId} onChange={this.onChangeToolbar}
                               logLevelEnums={_.get(logLevelEnumsQuery, '__type.enumValues')}
                               logTypeEnums={_.get(logTypeEnumsQuery, '__type.enumValues')}/>
            <ProductLogTimeline values={this.props.values} productId={productId}/>
        </div>
    }
}

export default compose(
    withRouter,
    graphql(Enums, {
        name: 'logLevelEnumsQuery',
        options: () => ({
            variables: {
                name: 'LogLevel',
            }
        }),
    }),
    waitWhileLoading('logLevelEnumsQuery', '__type.enumValues'),
    graphql(Enums, {
        name: 'logTypeEnumsQuery',
        options: () => ({
            variables: {
                name: 'LogType',
            }
        }),
    }),
    waitWhileLoading('logTypeEnumsQuery', '__type.enumValues'),
    withTranslation()
)(ProductLogs);
