// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import _ from 'lodash';

import ServiceActionTypeFormQuery from "./../../graphql/queries/serviceActionTypeForm.graphql";
import CreateServiceActionMutation from "./../../graphql/mutations/createServiceAction.graphql";
import AddServiceActionFilesMutation from "./../../graphql/mutations/addServiceActionFiles.graphql";
import type {serviceActionTypeForm} from "./../../graphql/operation-result-types.flow";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {getAttributeValues} from "../Form/fomValues-to-attributeValues";
import TDAAttributesForm from "../TDAAttributesForm";
import ServiceTicketCreateServicePlan from "../ServiceTicketCreateServicePlan";
import {Alert} from "antd";
import {categoryIndizesToGroups} from "../../utils/categoryHelper";
import {getAttributeGroups} from "../../utils/attributeGroups";

type Props = {
    t: Function,
    index: string,
    serviceTicketId: string | number,
    serviceTicketIds?: string[],
    useSubmit: boolean,
    onSubmit: Function,
    onUpload: Function,
    values: any,
    addServiceActionFilesMutation: Function,
    serviceActionTypeFormQuery: serviceActionTypeForm,
    mutation: Function,
    onChange: Function,
    onCustomCreateAction: Function
};

type State = {
    actionTypeValues: any,
    files: any[],
}

const hideItems = [
    'private',
    //'description',
    'doneById',
    'date',
];

class ServiceActionTypeForm extends Component<Props, State> {

    static defaultProps = {
        useSubmit: true
    };

    state = {
        actionTypeValues: {},
        files: [],
        /**
         * 0: Nicht erstellt
         * 1: Erstellen
         * -1: Erstellt
         */
        servicePlanStatus: 0,
    };

    formRef = React.createRef();


    handleFiles = (res) => {
        const {addServiceActionFilesMutation} = this.props;
        const id = _.get(res, 'data.createServiceAction.id');
        if (id && this.state.files && this.state.files.length) {
            return addServiceActionFilesMutation({
                variables: {
                    serviceActionId: id,
                    files: this.state.files.map((item) => ({
                        fileId: item.id
                    }))
                }
            }).then(() => {
                return res;
            })
        }

        return new Promise((result) => {
            result(res);
        })
    };

    handleSave(res) {
        const {onSubmit} = this.props;

        if (onSubmit) {
            onSubmit(res);
        }

        return this.handleFiles(res)
    }

    submitForm() {
        console.log(this.formRef);
    }

    renderServicePlan = () => {
        const {t, serviceTicketId, serviceTicketIds} = this.props;

        if (this.state.servicePlanStatus > -1){
            return <ServiceTicketCreateServicePlan onDone={() => {
                this.setState({
                    servicePlanStatus: -1
                })
            }} serviceTicketIds={serviceTicketIds || [serviceTicketId]}/>
        }
        if (this.state.servicePlanStatus === -1) {
            return <Alert message={t("Service plan was created")} type="success"/>
        }

    };

    render() {
        const {index, serviceActionTypeFormQuery, useSubmit, onSubmit, mutation, serviceTicketId, onChange, values, t} = this.props;

        let attributes = _.clone(_.get(serviceActionTypeFormQuery, 'serviceActionType.typeDefinitionAttributeList.typeDefinitionAttributes'));
        // attributes.push({
        //     index: 'files',
        //     name: t('Files'),
        //     inputType: 'file',
        //     multiple: true,
        //     type: 'string',
        // });

        // const collapse = attributes.map(item => item.index).filter(index => hideItems.indexOf(index) === -1);

        const composedValues = {
            ...this.state.actionTypeValues,
            ...getAttributeValues(attributes),
        };

        for (const attributeIndex in values) {
            if (composedValues[attributeIndex] === null) {
                composedValues[attributeIndex] = values[attributeIndex]
            }
        }

        const host = window["__HOST__"];
        const enableServicePlan = ["componentWorkCompleted"].indexOf(index) !== -1 && host !== "xenon";

        // Hide default category attributes
        attributes = attributes.map((attr) => {
            if (attr.categories.includes("default")) {
                return {
                    ...attr,
                    categories: [""]
                }
            } else {
                return attr
            }
        })

        return <div>
            <TDAAttributesForm
                groups={getAttributeGroups(attributes, t)}
                mutation={this.props.onCustomCreateAction ? this.props.onCustomCreateAction : mutation}
                useSubmit={useSubmit}
                layout={'vertical'}
                onError={(err) => {
                    console.error(err)
                }}
                attributes={attributes}
                submitTitle={t('Save')}
                translateMutationOptions={({values}) => {
                    let input = {
                        ..._.cloneDeep(values),
                        description: new RegExp('\\n', 'g').test(_.get(values, 'description', '')) ? undefined : description,
                        files: undefined
                    };

                    return {
                        variables: {
                            serviceActionTypeIndex: index,
                            serviceTicketId: serviceTicketId,
                            input
                        }
                    }
                }}
                onChange={onChange}
                onSubmit={(res) => this.handleSave(res)}
                values={composedValues}
            />
            {
                enableServicePlan ? this.renderServicePlan() : null
            }
        </div>


        /*<AttributesInlineForm
            collapse={collapse}
            inline={false}
            useSubmit={useSubmit}
            onSubmit={onSubmit}
            onSaved={this.handleSave}
            values={composedValues}
            mutation={(options) => {
                if (this.props.onCustomCreateAction) {
                    return this.props.onCustomCreateAction(options)
                }
                return mutation(options);
            }}
            onValuesChange={onChange}
            translateMutationOptions={(attributeValues) => {


                return {
                    variables: {
                        "serviceActionTypeIndex": index,
                        "serviceTicketId": serviceTicketId,
                        "input": {
                            ...attributeValues,
                            files: undefined
                        }
                    }
                }
            }
            }
            attributes={attributes}/>*/
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceActionTypeFormQuery, {
        name: 'serviceActionTypeFormQuery',
        options: (props: Props) => ({
            variables: {
                index: props.index,
                serviceTicketId: props.serviceTicketId
            }
        }),
    }),
    waitWhileLoading('serviceActionTypeFormQuery', 'serviceActionType'),
    graphql(CreateServiceActionMutation, {
        name: 'mutation'
    }),
    graphql(AddServiceActionFilesMutation, {
        name: 'addServiceActionFilesMutation'
    }),
)(ServiceActionTypeForm);
