// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import ContentHeader from "../../components/Typo/ContentHeader";
import NotificationSettingsCreateForm from "./form";
import Panel from "../Panel";
import _ from "lodash";

type Props = {
    history: {
        push: Function
    },
    t: Function,
}

class ProfileNotificationSettingsCreate extends Component<Props> {
    render() {
        const {t, history} = this.props;
        return <div style={{width: '100%'}}>
            <div style={{width: '100%'}}>
                <ContentHeader title={t('Add new Notificationsetting')}/>
            </div>
            <div style={{margin: '10px'}}>
                <Panel title={t('Create')}>
                    <NotificationSettingsCreateForm onSaved={(res) => {
                        const id = _.get(res, 'data.createNotificationSetting.id')
                        if (id) history.push(`/profile/notificationsettings/${id}`)
                    }}/>
                </Panel>
            </div>
        </div>;
    }
}

export default withTranslation()(withRouter(ProfileNotificationSettingsCreate));
