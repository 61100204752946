// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import AttributesInlineForm from "./../Form/attributes-inline-form";
import {compose} from "redux";
import Error from "./../Error";
import serviceTicketAttributes from "./../../graphql/queries/serviceTicketAttributes.graphql";
import updateServiceTicket from "./../../graphql/mutations/updateServiceTicket.graphql";

import type {ServiceTicketAttributes_serviceTicket} from "../../graphql/operation-result-types.flow";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    serviceTicketId: string,
    updateServiceTicketMutation: Function,
    serviceTicketAttributesQuery: {
        loading: boolean,
        error?: any,
        serviceTicket: ServiceTicketAttributes_serviceTicket
    },
};




class ServiceTicketContactInlineForm extends Component<Props, State> {


    translateMutationOptions = ({values}) => {

        return {
            variables: {
                id: this.props.serviceTicketId,
                attributes: values
            }
        }
    };

    render() {
        const {serviceTicketAttributesQuery, updateServiceTicketMutation, t, serviceTicketAssigneeQuery} = this.props;

        //const attributeKeys = ['serviceProviderId', 'serviceCenterId', 'clientId', 'contactName', 'contactPhone', 'contactEmail'];
        let attributes = [

            ..._.get(serviceTicketAttributesQuery, 'serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes')
                .filter(item => item.categories.indexOf("contact") !== -1 || item.categories.indexOf("serviceProvider") !== -1)
        ];


        if (_.find(attributes,{
            index: 'clientId'
        })){
            if (_.get(serviceTicketAttributesQuery,'serviceTicket.client.customerId')){
                attributes.unshift({
                    index: 'customerId',
                    name: t('Customer Id'),
                    inputType: 'label',
                    value: _.get(serviceTicketAttributesQuery,'serviceTicket.client.customerId')
                })
            }
        }


        if (!attributes) {
            return <Error title={t('No attributes found')}/>
        }

        /*
        const assignee = _.get(serviceTicketAssigneeQuery,'serviceTicket.assignee')
        if (assignee){

            console.log(assignee);
            attributes = [
                {
                    index: 'assignee',
                    name: t('Assignee'),
                    value: _.get(assignee,'name'),
                    inputType: 'LABEL',
                    targetType: 'string'
                },
                ...attributes
            ]
        }
        */


        return <AttributesInlineForm
            editable
            layout={'vertical'}
            mutation={updateServiceTicketMutation}
            translateMutationOptions={this.translateMutationOptions}
            attributes={attributes}/>
    }
}


export default compose(
    withRouter,

    graphql(serviceTicketAttributes, {
        name: 'serviceTicketAttributesQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketAttributesQuery', 'serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(updateServiceTicket, {
        name: 'updateServiceTicketMutation'
    }),
)(ServiceTicketContactInlineForm);

