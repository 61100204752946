import React from "react";
import QueryTable from "../QueryTable";
import NewsListQuery from "./../../graphql/queries/NewsList.graphql"
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import moment from "moment";
import {Tag} from "antd";
import Colors from "./../../themes/default/colors"
import {Link} from "react-router-dom";
import Loader from "./../Loader"

interface Props {

}

const NewsTable = (props: Props) => {

    const {t} = useTranslation();
    const query = useQuery(NewsListQuery,{
        variables: {
            params: {
                start: 0,
                limit: 40,
                filter: {
                    status: {
                        operator: 'in',
                        value: ['inReview','published']
                    }
                }
            }
        }
    })

    if (query.loading) return <Loader size={'small'}/>

    return <QueryTable
        query={query}
        itemsKeyPath={'newsList.news'}
        totalKeyPath={'newsList.total'}
        limitKeyPath={'params.limit'}
        startKeyPath={'params.start'}
        columns={[{
            dataIndex: 'title',
            title: t('Title'),
            render: (text, record) => <Link to={`/admin/news/${record.id}`}>{text}</Link>
        },{
            dataIndex: 'insert',
            title: t('Date'),
            render: (text) => moment(text).format('LLL')
        },{
            dataIndex: 'status',
            title: t('Status'),
            render: (text) => <Tag color={(text === 'published') ? Colors.success : Colors.warning }>{t(text)}</Tag>
        }]}
    />;
}

export default NewsTable;
