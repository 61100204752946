// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {message, Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";
import styled from "styled-components";
import LogisticianDashboardQuery from "./../../graphql/queries/LogisticianDashboard.graphql";
import DeleteLogisticianMutation from "../../graphql/mutations/DeleteLogistician.graphql";
import ContentHeader from "../Typo/ContentHeader";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Strings from "../../i18n/strings";

import Sizes from "../../themes/default/sizes";
import Panel from "../Panel";
import LogisticianUpdateForm from "../LogisticianUpdateForm";
import LogisticiansListQuery from "../../graphql/queries/LogisticiansListQuery.graphql";

type Props = {
    logisticianId: string,
    logistician: any,
    deleteLogisticianMutation: Function,
    history: {
        goBack: Function,
    }
};

type State = {
    removing: boolean
}

const ContentWrapper = styled.div`
    padding: ${Sizes.grid}px ${2 * Sizes.grid}px;
`;

class LogisticianDashboard extends Component<Props, State> {

    state = {
        removing: false
    };

    _handleConfirm = () => {
        const {deleteLogisticianMutation, t} = this.props;

        this.setState({
            removing: true,
        }, () => {
            deleteLogisticianMutation({
                variables: {
                    id: this.props.logisticianId
                }
            }).then((res) => {
                message.success(_.get(res, 'data.deleteLogistician.message'));
                setTimeout(() => {
                    this.props.history.goBack();
                }, 0)
            }).catch(() => {
                message.error(t(Strings.errorOccurred));
            }).finally(() => {
                this.setState({
                    removing: false
                })
            })
        })
    };

    render() {
        const {logistician, t} = this.props;

        return <div>
            <ContentHeader title={logistician.name}
                           tools={<Popconfirm placement="bottomRight" onConfirm={this._handleConfirm}
                                              title={t(Strings.areYouSure)}>
                               <Button type={"danger"} loading={this.state.removing}>{t('Delete')}</Button>
                           </Popconfirm>}/>
            <ContentWrapper>
                <Panel>
                    <LogisticianUpdateForm logisticianId={logistician.id}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(DeleteLogisticianMutation, {
        name: 'deleteLogisticianMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: LogisticiansListQuery,
                variables: {
                    params: {
                        limit: 40,
                        start: 0,
                        search: {
                            query: _.get(props, 'filter.query', ''),
                            fields: ['name']
                        }
                    }
                }
            }]
        })
    }),
    graphql(LogisticianDashboardQuery, {
        name: 'logisticianDashboardQuery',
        options: (props: Props) => ({
            variables: {
                id: props.logisticianId
            }
        }),
    }),
    waitWhileLoading('logisticianDashboardQuery', 'logistician'),
    withProps((props) => ({
        logistician: _.get(props, 'logisticianDashboardQuery.logistician')
    }))
)(LogisticianDashboard);
