// @flow

import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button} from "@dreebit/react-components";

type Props = {
    t: Function,
    title: string,
    printLink: string,
    onFinalize: () => ?Promise<any>,
    finalized:boolean
};


class SubHeaderWithTools extends Component<Props, State> {

    render() {
        const {t, title, printLink, onFinalize, finalized} = this.props;

        return <div className={"flex-row flex-space-between flex-align-items-center ph-20"}>
            <h2>{title}</h2>
            <div>
                {
                    printLink
                    ? <a target={"_BLANK"} href={printLink}>
                            <Button icon="printer" className="mr-10" >{t('Print')}</Button>
                        </a>
                        : null
                }

                {onFinalize ? <Button icon="lock" onClick={onFinalize}>{t('Finalize')}</Button> : undefined}

            </div>
        </div>;
    }
}

export default withTranslation()(SubHeaderWithTools);
