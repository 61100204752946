// @flow
import React, {Component} from "react"
import _ from 'lodash'
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import componentFiles from "./../../graphql/queries/component-Files.graphql"
import fileCategories from "./../../graphql/queries/componentFileCategoriesEnums.graphql"
import updateComponentFile from "./../../graphql/mutations/updateComponentFile.graphql"
import deleteComponentFile from "./../../graphql/mutations/deleteComponentFile.graphql"
import Error from "../Error";
import FilesTable from "../FilesTable";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import componentFileZipQuery from "../../graphql/queries/componentFileZip.graphql";
import ComponentClientListQuery from "../../graphql/queries/componentClientList.graphql"
import withProfile from "../../hoc/withProfile";

type Props = {
    componentId: number,
    getComponentFiles: {
        loading: boolean,
        component: any
    },
    showHeader: boolean,
    getComponentFileCategories: ?any,
    category: ?string,
    updateComponentFileMutation: Function,
    deleteComponentFileMutation: Function,
}


class ComponentFilesTable extends Component<Props> {

    onDownloadClick = (selectedRowIds: any[], onSuccess: Function, onError: Function) => {
        const {componentId} = this.props;

        this.setState({
            loading: true
        }, () => {
            this.props.client.query({
                query: componentFileZipQuery,
                variables:{
                    id: componentId,
                    params: {
                        filter: {
                            id: {
                                value: selectedRowIds
                            }
                        }
                    }
                }
            }).then(({data}) => {
                const link = _.get(data, 'component.componentFileList.zipFile.link');
                window.open(link, '_blank');
                onSuccess(link);
            })
                .catch((e) => {
                    onError(e);
                })
        });
    };

    onShareWithClientClick = (file, clientId) => {
        const {updateComponentFileMutation, componentId} = this.props;

        const sharedClients = _.get(file, 'sharedClients', [])
        let updatedSharedClientIds = sharedClients.map(client => client.id)

        // add/remove selected clientId to the array with shared clients
        if (!updatedSharedClientIds.includes(clientId)) {
            updatedSharedClientIds.push(clientId);
        } else {
            updatedSharedClientIds = updatedSharedClientIds.filter(x => x !== clientId);
        }

        return updateComponentFileMutation({
            variables: {
                componentId,
                componentFileId: file.id,
                input: {
                    clients: updatedSharedClientIds
                }
            }
        })
    }

    onCategoryClick = (file, category) => {
        const {updateComponentFileMutation, componentId} = this.props;

        return updateComponentFileMutation({
            variables: {
                componentId,
                componentFileId: file.id,
                input: {
                    category
                }
            }
        })
    };

    onDeleteClick = (file) => {
        const {deleteComponentFileMutation, componentId} = this.props;
        return deleteComponentFileMutation({
            variables: {
                componentId,
                id: file.id
            }
        })
    };

    onFileNameChange = (file, filename) => {
        const {updateComponentFileMutation, componentId} = this.props;
        return updateComponentFileMutation({
            variables: {
                componentId,
                componentFileId: file.id,
                input: {
                    filename,
                    category: file.category
                }
            }
        })
    };

    onCheckPrivateCondition = (file) => {
        const {profile} = this.props;
        const userClientId = _.get(profile, 'client.id')

        if (_.size(_.get(file, 'sharedClients', [])) > 0 && _.get(file, 'client.id') === userClientId) {
            return "shared";
        } else if (_.get(file, 'client.id') !== userClientId) {
            return "shared";
        } else {
            return "private";
        }
    }

    getCustomPrivateTooltipText = (file) => {
        const {profile, t} = this.props;
        const userClientId = _.get(profile, 'client.id')

        let tooltipText = undefined;

        if (_.size(_.get(file, 'sharedClients', [])) > 0 && _.get(file, 'client.id') === userClientId) {
            tooltipText = t('You are sharing this file');
        } else if (_.get(file, 'client.id') !== userClientId) {
            tooltipText = t('This file is shared by OWNER', {OWNER: _.get(file, 'client.name')});
        }

        return tooltipText;
    }

    render() {

        const {getComponentFiles, category, getComponentFileCategories, ComponentClientListQuery, profile, t} = this.props;

        const userClientId = _.get(profile, 'client.id')

        if (!getComponentFiles || !getComponentFileCategories) {
            return <Error/>
        }

        let files = [
            ..._.get(getComponentFiles, 'component.componentFileList.componentFiles', []),
            ..._.get(getComponentFiles, 'component.product.productFileList.productFiles', []).map((item) => {
                return {
                    ...item,
                    category: 'catalog'
                }
            }),
            ...[
                {
                    ..._.get(getComponentFiles, 'component.print.label'),
                    filename: t('Label')
                },
                {
                    ..._.get(getComponentFiles, 'component.print.sheet'),
                    filename: t('A4 Sheet')
                },
                {
                    ..._.get(getComponentFiles, 'component.print.qr'),
                    filename: t('QR Code')
                },
            ].map((item) => {
                return {
                    ...item,
                    category: 'qrcode'
                }
            })
        ];
        if (category) {
            files = files.filter(item => item.category === category)
        }

        let categories = [];
        if (getComponentFileCategories['__type'] && getComponentFileCategories['__type'].enumValues) {
            categories = getComponentFileCategories['__type'].enumValues.map(item => ({
                index: item.name,
                title: item.description
            }))
        }

        return <div className="white-bg p-20">
            <FilesTable
                userClientId={userClientId}
                showHeader={this.props.showHeader}
                files={files}
                groupCategories={categories && categories.length > 0}
                categories={categories}
                loading={getComponentFiles.loading}
                onCategoryClick={this.onCategoryClick}
                shareClientList={_.get(ComponentClientListQuery, 'componentClientList.clients', [])}
                onShareWithClientClick={this.onShareWithClientClick}
                onDeleteClick={this.onDeleteClick}
                onFileNameChange={this.onFileNameChange}
                onDownloadClick={this.onDownloadClick}
                checkPrivateCondition={(file) => this.onCheckPrivateCondition(file)}
                customPrivateTooltipText={(file) => this.getCustomPrivateTooltipText(file)}
            />
        </div>;
    }

}

export default compose(
    graphql(componentFiles, {
        name: 'getComponentFiles',
        options: props => ({
            variables: {
                id: props.componentId,
            }
        }),
    }),
    graphql(ComponentClientListQuery, {
        name: 'ComponentClientListQuery',
        options: props => ({
            variables: {
                componentId: props.componentId,
                params: {
                    filter: {
                        excludeSelf: true
                    }
                }
            }
        }),
    }),
    graphql(fileCategories, {
        name: 'getComponentFileCategories',
    }),
    graphql(updateComponentFile, {
        name: 'updateComponentFileMutation',
        options: (props) => (
            {
                refetchQueries: [
                    {
                        query: componentFiles,
                        variables: {
                            id: props.componentId,
                        }
                    }
                ]
            })
    }),
    graphql(deleteComponentFile, {
        name: 'deleteComponentFileMutation',
        options: (props) => (
            {
                refetchQueries: [
                    {
                        query: componentFiles,
                        variables: {
                            id: props.componentId,
                        }
                    }, {
                        query: componentQuery,
                        variables: {
                            id: props.componentId,
                        }
                    }
                ]
            })
    }),
    withTranslation(),
    withApollo,
    withProfile(),
)(ComponentFilesTable);
