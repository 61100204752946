// @flow
import React from 'react';
import styled from "styled-components";


const BorderWrapper = styled.div`
  border: 1px solid ${(props) => props.color || '#eee'}
  border-radius: ${(props) => props.radius || 0}px
`;

export default BorderWrapper;
