// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import CreateServiceCenterMutation from "../../graphql/mutations/CreateServiceCenter.graphql";
import ServiceCenterListQuery from "../../graphql/queries/serviceCenterList.graphql";
import statics from "../../statics";

type Props = {
    onCreate: Function,
    createServiceCenterMutation: () => Promise,
};


class ServiceCenterCreate extends Component<Props, State> {

    render() {
        const {createServiceCenterMutation, onCreate, t} = this.props;

        return <TypeDefinitionAttributesForm
            index={'serviceCenter'}
            formProps={{
                useSubmit: true,
                submitTitle: t('Create'),
                mutation: createServiceCenterMutation,
                onSubmit: (res) => {
                    if (onCreate) {
                        onCreate(res);
                    }
                },
                translateMutationOptions: ({values}) => ({
                    variables: {
                        input: values
                    }
                })
            }}/>;
    }
}

export default compose(
    graphql(CreateServiceCenterMutation, {
        name: 'createServiceCenterMutation',
        options: () => ({
            refetchQueries: [{
                query: ServiceCenterListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                    }
                }
            }]
        })
    }),
    withTranslation(),
)(ServiceCenterCreate);
