// @flow
import React, { Component } from 'react';
import NavigationContainer from "../../components/NavigationContainer";
import ContentHeader from "../../components/Typo/ContentHeader";
import Toolbar from "../../components/Toolbar";
import InvoiceListToolbar from "../../components/InvoiceList/toolbar";
import queryString from "query-string";
import InvoiceList from "../../components/InvoiceList";
import {compose, withProps} from "recompose";
import withResources from "../../hoc/withResources";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import HostList from "../../components/HostList";

type Props = {

};

type State = {

}

class HostsRoute extends Component<Props, State> {

  render() {
    const { t } = this.props;

    return <div>
        <div className={"full-size"}>
            <NavigationContainer navigationBar={
                <div>
                    <ContentHeader hideBreadcrumb title={t('Hosts')}/>
                </div>
            } >
                <HostList />
            </NavigationContainer>
        </div>
    </div>;
  }
}

export default compose(
    withResources(["administrateClients", "shareClients"]),
    withTranslation(),
    withRouter,
)(HostsRoute);
