// @flow
import React, {Component} from 'react';

import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ComponentTypeIcon from "../ComponentTypeIcon";

import './subselectionList.css';
import _ from "lodash";


type Props = {
    components: [{
        id: number,
        name: string,
        childrenCount: number,
    }],
    t: Function,
};

class SubselectionProductList extends Component<Props> {

    render() {

        const {components, t} = this.props;


        return <div className="list-wrapper">
            <div className="list-header">
                <div className="flex flex-space-between">
                    <div className="flex flex-align-items-center overflow-ellipsis">
                        <ComponentTypeIcon componentTypeIndex={'product'} size={12} color={'black'}/>
                        <span className="larger-text ml-5">{t('Products')}</span>
                    </div>
                    <Button className={"sub-button"} size={"small"}>
                        <FontAwesomeIcon style={{fontSize: "12px", marginRight: "3px"}} name={"sitemap"}/> {components.length}
                    </Button>
                </div>
            </div>

            <div className="list-body">
                {components.map((comp, index) => {
                    return <Link key={index} to={`/infrastructure/${comp.id}`}>
                        <div className="list-item p-5 flex flex-space-between flex-align-items-center">
                            <div className="flex-col ellipse-label">
                                <span className="text-dark-gray ellipse-label">{comp.name}</span>
                                <span className="subtitle ellipse-label">S/N: {_.get(comp, 'serialNo', '-')}</span>
                            </div>
                            <FontAwesomeIcon name={"angle-right"} circled={false} color={"#bdc6cf"} size={"2x"}/>
                        </div>
                    </Link>
                })}
            </div>
        </div>;
    }
}

export default withTranslation()(SubselectionProductList);
