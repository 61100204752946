// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import Error from "../Error";

type State = {
    hasError: Boolean,
    err: any,
    info: any
};

class ErrorBoundary extends Component<Props, State> {
  componentDidMount() {  }

  state = {
      hasError: false
  };

  componentDidCatch(err, info){
      this.setState({
          hasError: true,
          err,
          info
      });
  }

  render() {
      const {t} = this.props;
      const {hasError, err, info} = this.state;

      let text = _.get(info, 'componentStack');
      if (text) {
          text = text.replace(/\n/g, "<br />");
      }

      return hasError
          ? <div style={{margin: 10}}>
              <Error title={t("An error occurred")}
                     description={<div className={"flex-grow"} dangerouslySetInnerHTML={{__html: text}}/>}
                     feedbackData={err}
                     enableFeedback
                     backToHome
              />
          </div>
          : this.props.children;
  }
}

export default compose(
    withTranslation(),
)(ErrorBoundary);
