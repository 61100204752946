// @flow
import React, {Component} from 'react';
import ServiceCalendarFilterPanel from "../../components/ServiceCalendarFilterPanel";
import {Col, Row} from "antd";
import _ from 'lodash'
import queryString from "query-string";
import {Route, Switch, withRouter} from "react-router-dom";
import ServicePlanSidebar from "../../components/ServicePlanSidebar";
import ServiceControlSidebar from "../../components/ServiceControlSidebar";
import type {Filter} from "../../components/ServiceCalendar";
import type {History} from "../../types";
import {withTranslation} from "react-i18next";
import {compose, withProps} from "recompose";
import parseSearchQueryParams from "../../utils/searchParams";
import {getCurrentMonthRange} from "../../utils/dateHelper";
import moment from "moment";

type Props = {
    match: any,
    history: History
};

class ServiceCalendarRoute extends Component<Props> {

    handleChange = (filter: Filter) => {
        const {history, match} = this.props;
        let route = `/calendar`;

        if (filter.date && filter.date.length && filter.date.length === 2) {
            filter.date = [moment(filter.date[0]), moment(filter.date[1])]
        }

        const selectedId = _.get(match, 'params.selectedId', null);
        if (selectedId) {
            route += `/${selectedId}`;
        }

        const selectedIndex = _.get(match, 'params.selectedIndex', null);
        if (selectedIndex) {
            route += `/${selectedIndex}`;
        }

        if (filter) {
            route += `?${queryString.stringify(filter)}`
        }

        history.push(route)
    };

    handleViewChange = ({key}) => {
        this.handleChange({
            view: key
        });
    };

    closeSidebar = () => {
        let query = _.get(this.props, 'history.location.search');
        const filter = parseSearchQueryParams(query);

        this.props.history.push(`/calendar?${queryString.stringify(filter)}`);
    };

    render() {
        const {match, filter} = this.props;
        const selectedId = _.get(match, 'params.selectedId', null);

        return <div className={"white-bg full-height"}>
            <Row className={'full-height'}>
                <Col md={selectedId ? 16 : 24} className={'full-height'}>
                    <ServiceCalendarFilterPanel
                        filter={filter}
                        onViewChange={this.handleViewChange}
                        onChange={this.handleChange}/>

                </Col>
                {
                    selectedId ? <Col className={'full-height'} md={8}>
                        <div className={'full-height serviceCalendarSwitch'}>
                            <Switch>
                                <Route path="/calendar/:selectedId/servicePlan"
                                       component={() => <ServicePlanSidebar
                                           servicePlanId={selectedId}
                                           onClose={this.closeSidebar}
                                       />}/>
                                <Route path="/calendar/:selectedId/serviceControl"
                                       component={() => <ServiceControlSidebar
                                           onClose={this.closeSidebar}
                                           serviceControlId={selectedId}/>}/>
                            </Switch>
                        </div>

                    </Col> : null
                }
            </Row>
        </div>
    }
}


export default compose(
    withRouter,
    withTranslation(),
    withProps((props: Props) => {
        let query = _.get(props, 'history.location.search');

        const filter = parseSearchQueryParams(query);
        let range = getCurrentMonthRange(_.get(filter, 'date'), 7);

        return {
            filter: {
                date: [range[0], range[1]],
                ...filter
            }
        }
    }),
)(ServiceCalendarRoute);
