import _ from "lodash";

export function getPrintLink(deco: any) {

    if (!deco) {
        return null;
    }

    let link = _.get(deco, 'file.link');
    if (link) {
        return link;
    }

    if (_.get(deco, 'finalized')){
        link = _.get(deco, 'print.sheet.link');
        return link;
    }

    return null;

}

export function checkIfServiceTicketCanCreateContaminationDeclaration(serviceTicket) {
    return _.get(serviceTicket, 'serviceTicketType.contaminationDeclaration') && _.get(serviceTicket, 'component.componentType.contaminationDeclarationTypeDefinitionAttributeList.total')
}
