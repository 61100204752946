// @flow
import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import KpiDashboard from "../../components/KpiDashboard";
import KpiSettings from "../../components/KpiSettings";



class KpiRoute extends Component<Props, State> {

  render() {

    return <Switch>
      <Route exact path="/kpi/settings" component={KpiSettings}/>
      <Route exact path="/kpi/dashboard" component={KpiDashboard}/>
      <Redirect exact from="/kpi" to="/kpi/dashboard"/>
    </Switch>;
  }
}

export default KpiRoute;
