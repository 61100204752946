// @flow
import React, {Component} from "react"
import UserDashboard from "../../components/UserDashboard";
import Error from "../../components/Error";
import withResources from "../../hoc/withResources";

type Props = {
    match: {
        params: {
            id: string
        }
    },
    history: {
        push: Function
    }
}

class UserDashboardRoute extends Component<Props> {
    render() {
        const {match, history} = this.props;

        const userId = match.params.id;

        return (<div>
            {
                !userId
                    ? <Error type="404"/>
                    : <UserDashboard userId={userId} history={history}/>
            }
        </div>)
    }
}

export default withResources(["administrateUsers"])(UserDashboardRoute);