// @flow
import React, {Component} from "react"
import {List} from "antd";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import "./styles.css";
import {Link} from "react-router-dom";

type Props = {
    useLink?: boolean,
    onClick?: Function,
    hideComponent?: boolean,
    hideClient?: boolean,
    item: {
        id: string,
        name: string,
        key: string,
        serviceTicketType: {
            index: string
        },
        component: {
            serialNo?: string,
            name: string,
        }
    }
}

class ServiceTicketListItem extends Component<Props> {


    render() {

        const {item, onClick, t, hideComponent, hideClient} = this.props;

        if (!item) {
            return null;
        }

        return <List.Item className={`service-ticket-list-item ${onClick ? 'clickable' : ''}`} onClick={onClick}>
            <div className={'title-row'}>
                {item.serviceTicketType ?
                    <Link to={`/service/${item.id}`}><ServiceTicketTypeIcon
                        index={item.serviceTicketType.index}/> [{item.key}] {item.name}</Link>
                    : null}
            </div>
            {!hideComponent ? <div className={'component-row'}>
                {item.component ?
                    <div>
                        <span>{item.component.name} {item.component.serialNo ? `(${item.component.serialNo})` : null}</span>
                    </div>
                    : <span>{t('No component linked')}</span>}
            </div> : null}

            {!hideClient ? <div className={'client-row'}>
                {item.client ?
                    <div>
                        <span className={'bold'}>{t('Client')}:</span> <span>{item.client.name}</span>
                    </div>
                    : <span>{t('No client')}</span>}
            </div> : null}

            <div className={'service-action-row'}>
                {item.lastServiceAction ?
                    <div><span
                        className={'bold'}>{t('Last service action')}:</span> {_.get(item, 'lastServiceAction.serviceActionType.code')} - {_.get(item, 'lastServiceAction.serviceActionType.name')}
                    </div>
                    : <span>{t('Last service action not available')}</span>
                }
            </div>

        </List.Item>

    }

}

export default withTranslation()(ServiceTicketListItem);
