// @flow
import React, {Component} from "react"

import FileUploader from "../FileUploader";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import addComponentFiles from "./../../graphql/mutations/addComponentFiles.graphql";
import componentFilesQuery from "./../../graphql/queries/component-Files.graphql";
import {message} from "antd/lib/index";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";

type Props = {
    addComponentFiles: Function,
    componentId: number,
    category: string
};

class ComponentFileUploader extends Component<Props> {

    static defaultProps = {
        category: 'default'
    };

    onUploaded = (file) => {

        const {addComponentFiles, componentId, t, category} = this.props;
        addComponentFiles({
            variables: {
                id: componentId,
                files: [
                    {
                        fileId: file.response.fileId,
                        filename: file.name,
                        category
                    }
                ]
            }
        })
            .then(() => {

            })
            .catch(() => {
                message.error(t('File could not be linked'));
            })
    };

    render() {

        return <div className="white-bg p-20">
            <FileUploader multiple onUpload={this.onUploaded}/>
        </div>;
    }

}

export default compose(
    graphql(addComponentFiles, {
        name: 'addComponentFiles',
        options: (props) => ({
            refetchQueries: [
                {
                    query: componentFilesQuery,
                    variables: {
                        id: props.componentId,
                    },
                }, {
                    query: componentQuery,
                    variables: {
                        id: props.componentId,
                    }
                }
            ]
        })

    }),
)(withTranslation()(ComponentFileUploader));
