// @flow
import React, {useState} from "react"
import {Dropdown, Menu, Modal, Radio, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from 'lodash'
import FileSizeLabel from "../Utilities/FileSizeLabel";
import {useTranslation} from "react-i18next";
import FileIconFactory from "../../utils/FileIconFactory";
import EditableCell from "../Utilities/EditableCell";
import MaterialCommunityIcon from "./../Icons/MaterialCommunityIcon";
import GroupedTable from "../GroupedTable";
import {LightgalleryItem} from "react-lightgallery";
import Thumbnail from "../Thumbnail";

import {useLightgallery } from "react-lightgallery";

const SubMenu = Menu.SubMenu;
const { confirm } = Modal;

interface Props {
    userClientId?: string,
    files: any[],
    loading: boolean,
    groupCategories: boolean,
    categories: {
        title: string,
        index: string
    }[],
    t: Function,
    showHeader?: boolean,
    enableLanguage?: boolean,
    onCategoryClick: (category)=>void,
    shareClientList: any[],
    onShareWithClientClick: (file, client) => void,
    onFileNameChange: (file, name)=>void,
    onDeleteClick: (file)=>void,
    onDownloadClick: Function,
    onPrivateChange: (file, privacyStatus) => void,
    customActions: any,
    fileTableView?: boolean,
    selectedRowIds: any[],
    changeSelectedRowIds: any,
    checkPrivateCondition?: (file) => void,
    customPrivateTooltipText?: (file) => void,
}

const FilesTable = ({
        userClientId,
        files,
        loading,
        categories,
        groupCategories,
        onCategoryClick,
        shareClientList,
        onShareWithClientClick,
        fileTableView,
        selectedRowIds,
        changeSelectedRowIds,
        onDeleteClick,
        customActions,
        onFileNameChange,
        onTableChange,
        showHeader,
        onPrivateChange,
        enableLanguage,
        onDownloadClick,
        checkPrivateCondition,
        customPrivateTooltipText
}: Props) => {

    const [loadingState, setLoadingState] = useState(false);
    const [selectedRowIdState, setSelectedRowIdState] = useState([]);
    const {t} = useTranslation();
    const {openGallery} = useLightgallery();

    const getSelectedRowIds = () => {
        return selectedRowIds || selectedRowIdState
    }

    const onSetSelectedRowIds = (ids) => {
        if (changeSelectedRowIds){
            changeSelectedRowIds(ids)
        } else {
            setSelectedRowIdState(ids)
        }
    }

    const handlePromiseLoading = (promise) => {
        if (promise && promise["then"]) {
            promise
                .then(() => {
                    changeSelectedRowIds([]);
                    setLoadingState(false)
                })
                .catch(() => {
                    setLoadingState(false)
                });
        } else {
            setLoadingState(false)
        }
    }

    const handleDownload = () => {
        if(onDownloadClick){
            setLoadingState(true);
            onDownloadClick(
                getSelectedRowIds(),
                () => {
                    onSetSelectedRowIds([])
                    setLoadingState(false)
                },
                () => {
                    setLoadingState(false)
                }
            )
        }
    };

    const handleDelete = () => {
        if (onDeleteClick) {
            confirm({
                title: t('Are you sure to delete this files'),
                onOk() {
                    setLoadingState(true);
                    handlePromiseLoading(
                        Promise.all(
                            getSelectedRowIds().map((id: string) => {
                                const allFiles = files || [];
                                const file = _.find(allFiles, {id});
                                if(file) {
                                    if(file.access.remove) return onDeleteClick(file);
                                    return null;
                                }
                                return  onDeleteClick({id});
                            })
                        )
                    );
                },
                onCancel() {

                },
            });
        }
    };

    const onMenuClick = (file, {item, key, keyPath}) => {
        if (keyPath.indexOf('category') > -1) {
            if (onCategoryClick) {
                setLoadingState(true)
                const promise = onCategoryClick(file, key);
                handlePromiseLoading(promise);
            }
        } else if (keyPath.indexOf('clientShare') > -1) {
            if (onShareWithClientClick) {
                setLoadingState(true)
                const promise = onShareWithClientClick(file, key);
                handlePromiseLoading(promise);
            }
        } else {
            const privateCase = () => {
                if (onPrivateChange) {
                    setLoadingState(true)
                    const promise = onPrivateChange(file, key === 'private');
                    handlePromiseLoading(promise);
                }
            };

            const cases = {
                'private': privateCase,
                'public': privateCase,
                'download': () => {

                },
                'delete': () => {
                    if (onDeleteClick) {
                        confirm({
                            title: t('Are you sure to delete this file'),
                            onOk() {
                                setLoadingState(true);
                                const promise = onDeleteClick(file);
                                handlePromiseLoading(promise);
                            },
                            onCancel() {

                            },
                        });
                    }
                }
            };

            if(customActions){
                customActions.map((action) => {
                    cases[action.key] = () => action.onClick(file)
                })
            }

            if (cases[key]){
                cases[key]();
            }else {
                console.error(`Key: ${key} not found`)
            }

        }

    };

    const openFile = (file, index) => {
        if (file && file.link) {
            if (file.thumbnailLink) {
                openGallery("file-gallery", index)
            } else {
                window.open(file.link)
            }
        }
    }

    let columns = [
        {
            key: 'preview',
            width: 30,
            render: (text, record) => {
                const thumb = _.get(record, 'thumbnailLink');
                if (thumb) {
                    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Thumbnail popover link={thumb}/>
                    </div>
                }
                return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><MaterialCommunityIcon size={18} {...FileIconFactory.getIcon(record.contentType)}/></div>
            },
        },
        // we need an extra column for the lightgallery item so we can open it on thumbnail click aswell as on row click
        {
            key: 'lightgallery',
            width: 0,
            render: (text, record) => {
                const thumb = _.get(record, 'thumbnailLink');
                if (thumb) {
                    return <LightgalleryItem src={_.get(record, 'link')} group={"file-gallery"} />
                }
            },
        },
        {
            title: t('Name'),
            dataIndex: 'filename',
            key: 'filename',
            render: (text, record) => {

                if(_.get(record, 'access.write', false)){
                    return <div className={"max-width-600"}>
                        <EditableCell
                            oneRow={true}
                            value={text}
                            onChange={(val) => {
                                if (onFileNameChange) {
                                    return onFileNameChange(record, val)
                                }

                                return new Promise((res) => {
                                    res();
                                });
                            }}
                        />
                    </div>

                }
                return <span className={'ellipse-label'}>
                        {text}
                    </span>
            }
        }, {
            title: t('Typ'),
            dataIndex: 'contentType',
            key: 'contentType',
            render: (text, record) => {
                if (!text) return;
                if (text === 'application/octet-stream') {
                    return _.get(record, 'extension', text)
                }
                return _.last(text.split("/"));
            }
        }, {
            title: t('Size'),
            dataIndex: 'size',
            key: 'size',
            width: 60,
            render: (text, item) => {
                return <FileSizeLabel bytes={item.size}/>
            }
        }];

    if (enableLanguage) {

        let filters = _.chain(files).groupBy('language').keys().map((key) => ({
            value: key,
            text: t(key)
        })).value();

        if (filters.length <= 1) {
            filters = [{
                value: 'de_DE',
                text: t('German')
            }, {
                value: 'en_EN',
                text: t('English')
            }]
        }

        columns.push({
            title: t('Language'),
            dataIndex: 'language',
            key: 'language',
            filters,
            defaultFilteredValue: [],//getLanguageFromI18N(i18n)
            filterMultiple: false,
            onFilter: (value, record) => record.language === value,
            render: (text, item) => {
                return t(text)
            }
        })
    }

    columns = columns.map((col) => {
        return {
            ...col,
            onCell: (record) => {
                return {
                    onClick: (e) => {
                        const tmp = {...e};
                        const validNodes = ["TD", "DIV", "SPAN", "I", "IMG"];
                        const imageFiles = data.filter((file) => file.thumbnailLink);

                        if (tmp.target && validNodes.indexOf(tmp.target.nodeName) > -1) {
                            const index = imageFiles.findIndex((file) => file.id === record.id)
                            openFile(record, index)
                        }
                    }
                }
            }
        }
    });

    if (checkPrivateCondition) {
        columns.push({
            dataIndex: 'private',
            key: 'private',
            width: 40,
            render: (text, item) => {
                let tooltipText = null;
                let privateIcon = null;

                // currently checking for "private", "shared", undefined/null
                if (checkPrivateCondition(item) === "private") {
                    tooltipText = t('This file is private');
                    privateIcon = "lock";
                } else if (checkPrivateCondition(item) === "shared") {
                    tooltipText = t('You are sharing this file');
                    privateIcon = "share-variant";
                }

                if (customPrivateTooltipText && !_.isNil(customPrivateTooltipText(item))) {
                    tooltipText = customPrivateTooltipText(item);
                }

                if (tooltipText && privateIcon) {
                    return <Tooltip title={tooltipText}>
                        <span className="ant-dropdown-link">
                            <MaterialCommunityIcon name={privateIcon}/>
                        </span>
                    </Tooltip>
                }
            }
        });
    }

    columns.push({

        key: 'actions',
        width: 100,
        render: (text, item) => {
            let categoriesSubmenu = null;
            if (categories) {
                categoriesSubmenu =
                    <SubMenu
                        key="category"
                        title={<span><MaterialCommunityIcon name="tag"/> {t('Category')}</span>}
                    >
                        {categories.map(category => (
                            <Menu.Item key={category.index}>{item.category === category.index ?
                                <MaterialCommunityIcon name="checkbox-marked"/> :
                                <MaterialCommunityIcon name="checkbox-blank-outline"/>} {category.title}</Menu.Item>
                        ))}
                    </SubMenu>
            }

            let shareWithClientSubmenu = null;
            if (shareClientList && shareClientList.length > 0) {
                shareWithClientSubmenu = <SubMenu key="clientShare" title={<span><MaterialCommunityIcon name="share-variant"/> {t('Share')}</span>}>
                    {shareClientList.map(client => (
                        <Menu.Item key={client.id}>
                            {
                                item.sharedClients?.some(x => x.id === client.id) ?
                                    <MaterialCommunityIcon name="checkbox-marked"/>
                                    : <MaterialCommunityIcon name="checkbox-blank-outline"/>
                            }
                            {client.name}
                        </Menu.Item>
                    ))}
                </SubMenu>
            }

            const isFileOwner = userClientId === _.get(item, 'client.id')

            const menu = (
                <Menu onClick={(data) => {
                    onMenuClick(item, data)
                }}>
                    <Menu.Item key="download">
                        <a href={item.link} download={item.filename} target={"_BLANK"}>
                            <MaterialCommunityIcon name="download"/> {t('Download')}
                        </a>
                    </Menu.Item>
                    {
                        onCategoryClick && item.category !== "qrcode" ?
                            categoriesSubmenu
                            : undefined
                    }
                    {
                        isFileOwner && onShareWithClientClick && shareClientList?.length > 0 && item.category !== "qrcode" ?
                            shareWithClientSubmenu
                            : null
                    }
                    {
                        onDeleteClick && (!item.access || _.get(item, 'access.remove', false)) && item.category !== "qrcode"
                            ? <Menu.Item key="delete"><MaterialCommunityIcon name="delete"/> {t('Delete')}</Menu.Item>
                            : null
                    }
                    {
                        customActions ?
                            customActions.map((action) => {
                                return action.check ?
                                    <Menu.Item key={_.get(action, 'key')}>
                                        <Icon type={_.get(action, 'icon')}/> {_.get(action, 'text')}
                                    </Menu.Item>
                                    : null
                            })
                            : null
                    }
                </Menu>
            );

            return <Dropdown overlay={menu}>
          <span data-cy={'TEST_30002'} className="ant-dropdown-link">
            {t('Actions')} <Icon type="down"/>
          </span>
            </Dropdown>
        }
    });

    const data = files ? files.map(item => ({
        ...item,
        key: _.get(item, 'id')
    })) : null;

    const selectedRows = getSelectedRowIds();

    return <div>
        {selectedRows && selectedRows.length ?<div className={'flex-row mb-5'}>
            <Radio.Group value={null}>
                <Radio.Button
                    onClick={handleDownload}
                >
                            <span className={'flex-row'}>
                                <Icon
                                    type="download"
                                    className={'flex flex-center flex-align-items-center mr-10'}
                                />
                                {t('Download')}
                            </span>
                </Radio.Button>
                <Radio.Button
                    disabled={selectedRows.filter((id) => {
                        return _.get(files.find((item) => item.id === id),'access.remove',false)
                    }).length === 0}
                    onClick={handleDelete}
                >
                            <span className={'flex-row'}>
                                <Icon
                                    type="delete"
                                    className={'flex flex-center flex-align-items-center mr-10'}
                                />
                                {t('Delete')}
                            </span>
                </Radio.Button>
            </Radio.Group>
        </div> : null}
        <GroupedTable
            categories={categories}
            onChange={onTableChange}
            changeSelectedRowIds={onSetSelectedRowIds}
            selectedRowIds={selectedRows}
            rowKey={"id"}
            groupKey={'category'}
            groups={groupCategories ? categories : undefined}
            showHeader={showHeader === true}
            loading={loading || loadingState}
            pagination={false}
            locale={{
                filterReset: <Icon type="rest"/>
            }}
            size={'middle'}
            rowClassName="cursor-pointer"
            columns={columns}
            fileTableView={fileTableView}
            onCategoryClick={onCategoryClick}
            onPrivateChange={onPrivateChange ? onPrivateChange : null}
            onMenuClick={onMenuClick}
            dataSource={data}
            customActions={customActions}
        />
    </div>;
}

export default FilesTable;

FilesTable.defaultProps = {
    files: []
}
