// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import productComponentList from "../../graphql/queries/ProductComponentList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ContentWrapper from "../ContentWrapper";
import QueryTable from "../QueryTable";
import {Avatar, Breadcrumb, Col} from "antd";
import _ from 'lodash';

type Props = {
    t: Function,
    id: string,
    onClick: Function,
    productComponentListQuery: query
};

type State = {
    loading: boolean,
}

class ProductComponentList extends Component<Props, State> {
    state = {
        loading: false,
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {t, productComponentListQuery, onClick} = this.props;
        return <ContentWrapper>
            <QueryTable
                loading={this.state.loading}
                className={"link-table"}
                size={'middle'}
                query={productComponentListQuery}
                itemsKeyPath={'productComponentList.components'}
                totalKeyPath={'productComponentList.total'}
                onRow={(record) => {
                    return {
                        onClick: () => onClick ? onClick(record) : null
                    };
                }}
                rowKey={"id"}
                columns={[
                    {
                        dataIndex: 'image.thumbnailLink',
                        key: 'image.thumbnailLink',
                        width: 10,
                        render: (text) => <Avatar shape={"square"} src={text}/>
                    },
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: t('Name'),
                        render: (text, component) => <Col>
                            <span>{text}</span>
                            <Breadcrumb>
                                {_.get(component, 'path', []).map((item) => item.id < 0
                                    ? <Breadcrumb.Item key={item.id}>
                                        {item.name}
                                    </Breadcrumb.Item>
                                    : <Breadcrumb.Item key={item.id}>
                                        {item.name}
                                    </Breadcrumb.Item>)}
                            </Breadcrumb>
                        </Col>
                    },
                    {
                        dataIndex: 'serialNo',
                        key: 'serialNo',
                        sorter: true,
                        title: t('serialNo')
                    },
                    {
                        dataIndex: 'product.catalogNo',
                        key: 'product.catalogNo',
                        title: t('catalogNo')
                    },
                    {
                        dataIndex: 'client',
                        key: 'client',
                        title: t('Client'),
                        render: (client) => {
                            return _.get(client, 'name', '')
                        }
                    }
                ]}/>
        </ContentWrapper>;
    }
}

export default compose(
    graphql(productComponentList, {
        name: 'productComponentListQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    waitWhileLoading('productComponentListQuery', ["productComponentList.components"]),
    withTranslation(),
)(ProductComponentList);
