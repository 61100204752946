// @flow
import React, { Component } from 'react';
import MutationButton from "../MutationButton";
import {compose} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import ResetUserTourProgressMutation from "../../graphql/mutations/resetUserTourProgress.graphql";
import {withTranslation} from "react-i18next";
import TourProgressQuery from "../../graphql/queries/tourProgress.graphql";

type Props = {
  tourIndizes: [String],
  onDone?: Function,
  block?: boolean
};


class TourGuideResetButton extends Component<Props> {
  componentDidMount() {}

  render() {
    const { resetUserTourProgressMutation, t, onDone, block } = this.props;

    return <MutationButton
      mutation={resetUserTourProgressMutation}
      onDone={onDone}
      block={block}
      buttonProps={{"test-id": "tour-reset-button"}}
    >
      {t("Restart Tour")}
    </MutationButton>
  }
}

export default compose(
  graphql(ResetUserTourProgressMutation, {
    name: 'resetUserTourProgressMutation',
    options: (props) => ({
      variables: {
        tourIndizes: props.tourIndizes
      },
      refetchQueries: [{
        query: TourProgressQuery
      }]
    })
  }),
  withTranslation()
)(TourGuideResetButton);
