// @flow
import React, {Component, createElement} from "react"
import {Alert} from 'antd';
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import {Col, Grow, Row} from "../Flex";

type Props = {
    t: Function,
    title?: string,
    message?: string,
    description?: string | any,
    customButton?: any,
    closeText?: string,
    closeable?: boolean,
    onClose?: Function,
    backToHome?: Boolean,
    className?: string
};

class Warning extends Component<Props> {

    renderDescription() {

        const { t, description, backToHome, customButton} = this.props;

        if (customButton){
            return <div>
                <Row>
                    <Grow>
                        <div style={{
                            width: '100%',
                            maxHeight: 100,
                            overflow: 'auto',
                            marginTop: 10,
                            marginBottom: 10
                        }}>
                            {description}
                        </div>
                    </Grow>
                    <Col>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                            {
                                backToHome
                                    ? createElement(
                                    'a',
                                    {
                                        to: '/',
                                        href: '/',
                                    },
                                    <Button type="primary" icon={'home'}>{t('Back to home')}</Button>
                                    )
                                    : null
                            }
                            {
                                this.props.customButton
                            }
                        </div>
                    </Col>
                </Row>

            </div>
        }

        return description

    }

    render() {
        const {title, message, closeable, closeText, onClose, className} = this.props;

        return <Alert message={title || message}
                      description={this.renderDescription()}
                      type={'warning'}
                      closable={closeable}
                      closeText={closeText}
                      onClose={onClose}
                      className={className}
        />;
    }
}

export default compose(
    withTranslation()
)(Warning);
