//@flow

import * as _ from "lodash"

export default class Menu {
    id;
    roles;
    items = [];
    isPublic;
    name = "";
    subname;
    sortId = 0;
    icon = "";
    highlight = false;
    badge = false;
    route = "";
    toolMenu;
    action;
    customClasses;
    customConfig;

    constructor(id,
                roles = [],
                isPublic = false,
                name = "",
                sortId = 0,
                icon = "",
                route = "",
                action = null
    ) {
        this.id = id;
        this.roles = roles;
        this.isPublic = isPublic;
        this.name = name;
        this.sortId = sortId;
        this.icon = icon;
        this.route = route;
        this.action = action;
    }

    getRenderedMenu(roles, flatten = true) {
        let me = this;
        let menu = new Menu(me.id, me.roles, me.isPublic, me.name, me.sortId, me.icon, me.route, me.action);
        let items = [];

        for (let menuItem of this.items) {
            if (menuItem.shouldRender(roles))
                items.push(menuItem.getRenderedMenu(roles, flatten));
        }

        if (items.length === 1 && flatten) {
            menu = items[0];
        } else {
            menu.items = items;
        }

        return menu;
    };

    /**
     * Check if menu item should be rendered
     * @param roles
     * @returns {any}
     */
    shouldRender(roles) {

        if (this.isPublic || !this.roles || this.roles.length === 0) {
            return true;
        }

        let rolesArray;
        if (typeof roles === "string") {
            rolesArray = [roles];
        } else if (roles instanceof Array) {
            rolesArray = roles;
        }

        let result = false;
        if (rolesArray) {
            result = rolesArray.filter(item => {
                return this.roles.idOf(item) !== -1;
            }).length > 0;
        }


        return result;
    }

    addMenuItem(menu) {
        if (_.findIndex(this.items, {
            id: menu.id
        }) === -1) {
            this.items.push(menu);
        } else {

        }

        this.items = _.sortBy(this.items, (item) => item.sortId);

    }

    hasChildren() {
        return this.items.length > 0;
    }

    getMenuItemPosition(menuid) {
        let lookupIndex = _.findIndex(this.items, {
            id: menuid
        });
        return lookupIndex;
    }

    hasMenuItem(menuid) {
        let lookupIndex = this.getMenuItemPosition(menuid);
        return lookupIndex > -1;
    }

    findMenuItem(menuid) {

        if (this.hasMenuItem(menuid)) {
            let lookupIndex = this.getMenuItemPosition(menuid);
            return this.items[lookupIndex];
        }
        return null;

    }

    setToolMenu(menu) {
        this.toolMenu = menu;
    }

    hasToolMenu() {
        return !!this.toolMenu;
    }
}

