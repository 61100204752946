import Themes from "../provider/theme/themes";
import Logos from "../provider/theme/logos";
import {DEFAULT_THEME} from "../provider/theme";

export default function useTheme() {

    const selector = document.querySelector("meta[name='theme']");
    let theme = DEFAULT_THEME;
    if (selector){
        const content = selector.getAttribute("content");
        if (content && content !== '{{THEME}}'){
            theme = content;
        }else {
            theme = DEFAULT_THEME
        }
    }

    const themeConfig = {
        ...Themes.vsm,
        ...Themes[theme],
        ...Logos[theme],
    }


    return {
        themeConfig,
        theme
    };
};
