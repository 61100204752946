// @flow
import React, {Component} from 'react';
import Loader from "../Loader";
import ErrorComponent from "../Error";
import _ from "lodash";


type Config = {
    queryConfigs: {
        query: {
            loading: boolean,
            error?: any
        },
        checkProperties?: string[],
    }[],
    loaderProps?: any,
};


function queryWrapper(WrappedComponent: any, getConfig: (props: any)=> Config) {
    return class QueryWrapper extends Component<Props, State> {


        render() {
            const config = getConfig(this.props);
            const {queryConfigs, loaderProps} = config;

            const loading = queryConfigs.reduce((acc, {query}) => {
                if (acc) return acc;
                return query.loading;
            }, false);


            if (loading) {
                return <Loader {...loaderProps}/>
            }

            const error = queryConfigs.reduce((acc, {query}) => {
                if (acc) return acc;
                return query.error;
            }, false);


            if (error) {
                console.error(error);
                console.error(this.props, config);
                if (error.graphQLErrors) {
                    return <ErrorComponent title={'GraphQL API Error'}
                                           description={error.graphQLErrors.map(({message}, i) => (
                                               <span key={i}>{message}</span>
                                           ))}/>
                } else if (error.networkError) {
                    return <ErrorComponent title={'Network Error'} description={error.networkError}/>
                } else {
                    return <ErrorComponent/>
                }
            }

            const checkPropertyError = queryConfigs.reduce((acc, cur) => {
                if (acc) return acc;
                if (cur.checkProperties) {
                    acc = cur.checkProperties.reduce((acc2, cur2) => {
                        if (acc2) return acc2;
                        if (!_.get(cur.query, cur2)) {
                            return new Error(`${cur2} is not defined`);
                        }
                        return null;
                    }, false)
                }
                return acc;
            }, false);


            if (checkPropertyError) {
                console.error(checkPropertyError);
                console.error(this.props, config);
                return <ErrorComponent description={checkPropertyError.message}/>
            }

            return <WrappedComponent {...this.props} />;
        }
    };
}

export default queryWrapper;
