// @flow
import React, {Component} from 'react';
import {Waypoint} from 'react-waypoint';
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import styled from "styled-components";

import type {Query} from "../../types";
import Empty from "../Empty";
import Colors from "../../themes/default/colors";
import {Input, List} from "antd";
import {Icon} from "@dreebit/react-components";
import Loader from "../Loader";
import QueryErrorIndicator from "../QueryErrorIndicator";
import {updateCache} from "../../utils/loadMoreApolloCache";
import statics from "../../statics";

type Props = {
    query: Query,
    style: any,
    bordered: boolean,
    dataKeyPath: string,
    itemsKeyPath: string,
    totalKeyPath: string,
    renderItem: (item: any, index: number) => any,
    pagingSize: ?number,
    limitKeyPath: ?string,
    startKeyPath: ?string,
    searchKeyPath: ?string,
    searchFieldsKeyPath: ?string,
    searchFields: ?string[],
    empty?: any,
    listProps?: any,
    showSearchBar?: boolean,
    localSearch?: boolean,
    uniqueIdentifier?: any,
};

type State = {
    loadingMore: boolean,
}


const ListWrapper = styled.div`
    background: ${Colors.white};
    border-top: 1px solid ${Colors.greyLight}
`;

class QueryList extends Component<Props, State> {

    static defaultProps = {
        pagingSize: statics.defaultPagingSize,
        limitKeyPath: 'params.limit',
        startKeyPath: 'params.start',
        searchParamKeyPath: 'params.search',
        searchKeyPath: 'params.search.query',
        searchFieldsKeyPath: 'params.search.fields',
        uniqueIdentifier: 'id'
    };

    state = {
        loadingMore: false,
        localSearchText: null,
    };

    _handleWaypointEnter = () => {

        const total = _.get(this.props.query, this.props.totalKeyPath);
        const length = _.get(this.props.query, this.props.itemsKeyPath, []).length;

        if (length < total && total > 0) {

            const variables = _.clone(this.props.query.variables) || {};
            _.set(variables, this.props.limitKeyPath, this.props.pagingSize);
            _.set(variables, this.props.startKeyPath, length);

            const oldData = _.clone(this.props.query);

            this.setState({
                loadingMore: true
            }, () => {
                this.props.query.fetchMore({
                    variables,
                    updateQuery: (prev, next) => updateCache(oldData, next, this.props.itemsKeyPath, this.props.totalKeyPath, this.props.uniqueIdentifier)
                }).then(() => {
                    this.setState({
                        loadingMore: false
                    });
                })
            });


        }
    };

    _handleWaypointLeave = () => {

    };

    _handleSearch = (e) => {

        const {query, localSearch} = this.props;

        const text = _.get(e, 'currentTarget.value');

        if (localSearch){
            return this.setState({
                localSearchText: text
            })
        }

        const variables = {
            ...query.variables,
        };
        if (text && text.length){
            _.set(variables, this.props.searchKeyPath, text);
            if (this.props.searchFields){
                _.set(variables, this.props.searchFieldsKeyPath, this.props.searchFields);
            }
        }else {
            _.set(variables, this.props.searchParamKeyPath, undefined);
        }

        query.refetch(variables)
    };

    render() {
        const {query, itemsKeyPath, renderItem, bordered, style, listProps, showSearchBar, t, localSearch, searchFields} = this.props;
        const {localSearchText} = this.state;

        let items = _.get(query, itemsKeyPath, []);

        if (localSearch && localSearchText && localSearchText.length && searchFields && searchFields.length){
            items = items.filter((item) => {
                return searchFields.filter((key) => {
                    return new RegExp(localSearchText, 'gi').test(_.get(item,key,''))
                }).length > 0
            })
        }

        return <div style={style}><ListWrapper>
            <div style={{float: "right", zIndex: 1, position: "relative", padding: "0 16px"}}>
                <QueryErrorIndicator placement={"left"} query={query}/>
            </div>
            {showSearchBar ? <div className={"bb"}>
                <Input className={"no-border"} suffix={<Icon type={"search"}/>} onChange={this._handleSearch}
                       placeholder={t("Search")}/>
            </div> : null}
            {!items.length && !query.loading ? <div style={{padding: 8}}>{this.props.empty || <Empty/>}</div> :

               <div>
                    <List
                        {...listProps}
                        loading={!this.state.loadingMore && _.get(query, 'loading')}
                        bordered={bordered}
                    >
                        {items.map(renderItem)}
                    </List>
                    <Waypoint
                        onEnter={this._handleWaypointEnter}
                        onLeave={this._handleWaypointLeave}
                    />
                    {this.state.loadingMore || query.loading ? <Loader size={"small"}/> : null}
                </div>
            }
        </ListWrapper></div>;
    }
}


export default withTranslation()(QueryList);
