// @flow
import React, { Component } from 'react';
import FillQueryTable from "../QueryTable/fill";
import {compose} from "recompose";
import withResources from "../../hoc/withResources";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {graphql} from "@apollo/client/react/hoc";
import InvoiceStatisticQuery from "../../graphql/queries/invoiceStatistic.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import moment from "moment";

class InvoiceStatistic extends Component<Props, State> {

  render() {
    const { invoiceStatisticQuery, t } = this.props;

    const dateRenderer = text => moment(text).format('L');
    const currencyRenderer = text => text ? `${String(text).replace('.',',')} €` : '';

    const invoiceStatisticColumns = [
      {
        dataIndex: 'begin',
        title: t('Start'),
        key: 'begin',
        width: '10%',
        render: dateRenderer
      },
      {
        dataIndex: 'end',
        title: t('End'),
        key: 'end',
        width: '10%',
        render: dateRenderer
      },
      {
        dataIndex: 'amountBasicCharge',
        title: t('Basic charges in Euro'),
        key: 'amountBasicCharge',
        render: currencyRenderer
      },
      {
        dataIndex: 'amountComponent',
        title: t('Component charges in Euro'),
        key: 'amountComponent',
        render: currencyRenderer
      },
      {
        dataIndex: 'amountServiceTicket',
        title: t('Service Ticket charges in Euro'),
        key: 'amountServiceTicket',
        render: currencyRenderer
      },
      {
        dataIndex: 'amountTotal',
        title: t('Amount in Euro'),
        key: 'amountTotal',
        width: '10%',
        render: currencyRenderer
      },
    ];

    return <div className={"full-size"}>
      <FillQueryTable
        className={"link-table"}
        size={'middle'}
        query={invoiceStatisticQuery}
        itemsKeyPath={'invoiceStatistic.invoicePeriodList.invoicePeriods'}
        totalKeyPath={'invoiceStatistic.invoicePeriodList.total'}
        rowKey={"invoicePeriods.id"}
        columns={invoiceStatisticColumns}
      />
    </div>;
  }
}

export default compose(
  withResources('accounting'),
  withTranslation(),
  graphql(InvoiceStatisticQuery, {
    name: 'invoiceStatisticQuery',
  }),
    waitWhileLoading('invoiceStatisticQuery', 'invoiceStatistic')
)(InvoiceStatistic);
