// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import CreateNewsMutation from "./../../graphql/mutations/CreateNewsMutation.graphql";
import TypeDefinitionAttributesForm from "./../TypeDefinitionAttributesForm";
import NewsListQuery from "../../graphql/queries/NewsList.graphql";

type Props = {
    onCreate: Function,
};


class NewNews extends Component<Props, State> {

    render() {
        const {createNewsMutation} = this.props;

        return <TypeDefinitionAttributesForm
            index="news"
            formProps={{
                mutation: createNewsMutation,
                onSubmit: (res) => {
                    if (this.props.onCreate) {
                        this.props.onCreate(res);
                    }
                },
                translateMutationOptions: ({values}) => ({
                    variables: {
                        news: values
                    }
                })
            }}
        />
    }
}

export default compose(
    graphql(CreateNewsMutation, {
        name: 'createNewsMutation',
        options: props => ({
            refetchQueries: [{
                query: NewsListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    }),
)(NewNews);
