// @flow
import React, {Component} from 'react';
import ServiceActionQuery from '../../graphql/queries/serviceActionDashboard.graphql';
import _ from "lodash";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Link} from "react-router-dom";
import * as moment from "moment";

type Props = {
  serviceActionId: string,
  serviceActionQuery: any,
  t: Function,
};

type State = {

}

class ServiceActionLabel extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { serviceActionId, serviceActionQuery, t } = this.props;

    return <Link to={`/service/${_.get(serviceActionQuery, 'serviceAction.serviceTicket.id')}/actions/${serviceActionId}`}>
      <div className='rce-mbox-body mt-5 mb-5 border border-radius-5 p-5 bg-white'>
        <div className={"service-action-code-name-label"}>
          <span className={'service-action-code'}>
            {_.get(serviceActionQuery, 'serviceAction.serviceActionType.code')}
          </span>: <span className={'service-action-name'}>
            {_.get(serviceActionQuery, 'serviceAction.serviceActionType.name')}
          </span>
        </div>
        <div>
          <span className={'service-action-done-by'}>{t('Done by')} {_.get(serviceActionQuery, 'serviceAction.doneBy.name')}</span>
        </div>
        <div>
          <span className={'service-action-update'}>{t('Last updated at')} {moment(_.get(serviceActionQuery, 'serviceAction.update')).format('LLL')}</span>
        </div>
      </div>
    </Link>;
  }
}

export default compose(
  withTranslation(),
  graphql(ServiceActionQuery, {
    name: 'serviceActionQuery',
    options: (props) => ({
      variables: {
        id: props.serviceActionId
      }
    })
  }),
  waitWhileLoading('serviceActionQuery', 'serviceAction')
)(ServiceActionLabel)
