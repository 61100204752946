// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ServiceActionQuery from "../../graphql/queries/serviceActionDashboard.graphql"

const ServiceActionsBreadcrumb = ({serviceActionsQuery, t}) =>
    <span>{_.get(serviceActionsQuery, 'serviceAction.serviceActionType.code', t('Code'))}: {_.get(serviceActionsQuery, 'serviceAction.serviceActionType.name', t('Unknown'))}</span>;

export default compose(
    graphql(ServiceActionQuery, {
        name: 'serviceActionsQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.serviceActionId'),
            }
        })
    }),
)(withTranslation()(ServiceActionsBreadcrumb));
