import _ from "lodash";
import * as moment from "moment";


const createBoolRenderer = (tda) => {

    return (text) => {
        if (text === null || text === undefined){
            return '';
        }
        return _.chain(tda).get('optionValues',[]).find((optionValue) => {
            return !!optionValue.value === !!text
        }).get('name').value();
    }
}


const getRenderer = (tda) => {

    const renderInputTypeMap = {
        datetime: (text) => moment(text).format('L HH:mm'),
        date: (text) => moment(text).format('L'),
        checkbox: createBoolRenderer(tda),
        switch: createBoolRenderer(tda)
    }

    return renderInputTypeMap[_.get(tda,'inputType')]

}

export default (tda) => {

    return {
        dataIndex: tda.index,
        title: tda.name,
        render: getRenderer(tda)
    }
}
