// @flow
import React, {Component} from "react"
import {userIsAuthenticatedRedir} from "../../utils/auth";
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import {compose, withProps} from "recompose";
import type {Query} from "../../types";
import InfrastructureDetail from "./detail";
import InfrastructureMaster from "./master";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    setActiveInfrastructureTab,
    setComponentBrowserComponent,
    setComponentBrowserWidth,
    setInfrastructureSearchQuery,
    setStockSearchEnabled
} from "../../actions/settings";
import queryString from "query-string";
import parseSearchQueryParams from "../../utils/searchParams";
import NavigationContainer from "../../components/NavigationContainer";
import ServiceSearchTab from "../../components/ServiceSearchTab";
import OpenedServiceTicketsTabs from "../../components/OpenedServiceTicketsTabs";
import Search from "../service/search";
import ServiceTicket from "../service/ticket";
import Error404 from "../errorPages/error404";
import ComponentSearchTab from "../../components/ComponentSearchTab";
import OpenedComponentsTabs from "../../components/OpenedComponentsTabs";

type Props = {
    finderId: string,
    openedComponentListQuery: Query,
    history: any,
    showComponentBrowser: boolean,
    stockSearchEnabled: boolean,
}

export const MIN_SIZE = 50;
export const MAX_SIZE = 420;

class InfrastructureRoute extends Component<Props> {

    static defaultProps = {
        redirectTab: 'dashboard'
    }

    state = {
        searchQuery: this.props.query,
        siderHighlight: this.props.clientListView ? "clientList" : "ownInfrastructure",
        // open the search filters if the URL contains search params
        masterContent: this.props.location.pathname.includes("/infrastructure/search?") ? "search" : "componentBrowser",
        isMasterOpen: true
    };

    _onOpenMaster = () => {
        this.setState({isMasterOpen: true})
    }

    _onCloseMaster = () => {
        this.setState({isMasterOpen: false})
    }

    componentDidUpdate(prevProps) {
        if (this.props.setActiveTab && this.props.activeKey !== prevProps.activeKey) {
            this.props.setActiveTab(this.props.activeKey);
        }

        if (this.props.search !== prevProps.search) {
            const search = _.get(this.props, 'history.location.search');
            const parsed = queryString.parse(search);
            let browserComponentId = _.get(parsed, 'finder', this.props.componentId);
            if (browserComponentId && browserComponentId.length && browserComponentId !== this.props.componentId) {
                this.props.setComponentBrowserComponent(browserComponentId)
            }
        }
    }

    _handleClosedComponentTab = (tab) => {
        const {history} = this.props;
        const activeKey = _.get(this.props, 'activeKey');

        if (this.props.setActiveTab) {
            this.props.setActiveTab(null);
        }
        if (tab.id === activeKey) {
            history.push(`/infrastructure/dashboard`)
        }

    };

    _changeMasterContent = (content: string, siderHighlight: string) => {
        this.setState({
            isMasterOpen: true,
            masterContent: content,
            siderHighlight: siderHighlight
        });
    }

    render() {

        const {activeKey} = this.props;

        return <NavigationContainer
            navigationBar={<div className={"service-tabs-wrapper"}
                                style={{padding: "0 4px", display: 'flex', flexDirection: 'row'}}>
                <ComponentSearchTab onClick={(tab) => {
                    this.props.history.push(`/infrastructure/${tab}`);
                }} activeKey={activeKey}/>
                <OpenedComponentsTabs
                    onTabChange={(tab) => {
                        this.props.history.push(`/infrastructure/${tab}`);
                    }}
                    activeKey={activeKey}
                    closedTab={this._handleClosedComponentTab}
                    disabledTabs={['dashboard', 'stock', 'search']}
                />
            </div>}
        >
            <div className="flex full-size">
                <div className="full-height">
                    <InfrastructureMaster
                        masterContent={this.state.masterContent}
                        siderHighlight={this.state.siderHighlight}
                        onChangeContent={(content, siderHighlight) => this._changeMasterContent(content, siderHighlight)}
                        isOpen={this.state.isMasterOpen}
                        onOpen={this._onOpenMaster}
                        onClose={this._onCloseMaster}
                    />
                </div>
                <div
                    className={"full-height"}
                    style={{width: `calc(100% - ${this.state.isMasterOpen ? `${MAX_SIZE}px` : `${MIN_SIZE}px`}`}}
                >
                    <Switch>
                        <Route path="/infrastructure/:index" component={InfrastructureDetail}/>
                        <Redirect exact from="/infrastructure"
                                  to={`/infrastructure/${this.props.redirectTab || InfrastructureRoute.defaultProps.redirectTab}`}/>
                    </Switch>
                </div>
            </div>
        </NavigationContainer>
    }
}

function mapStateToProps(state, props: Props) {
    return {
        redirectTab: _.get(state, 'settings.activeInfrastructureTab'),
        componentId: _.get(state, 'settings.componentBrowser.componentId'),
        componentBrowserWidth: _.get(state, 'settings.componentBrowserWidth'),
    }
}


function mapDispatchToProps(dispatch) {
    return {
        saveWidth: (width) => {
            return dispatch(setComponentBrowserWidth(width))
        },
        setComponentBrowserComponent: (componentId) => {
            return dispatch(setComponentBrowserComponent(componentId))
        }
    }
}

export default compose(
    userIsAuthenticatedRedir,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
    withRouter,
    connect((state, props) => {

        let query = props.query;
        if (!query || !query.length) {
            query = _.get(state, 'settings.infrastructureSearchQuery');
        }

        return {
            query,
        }
    }, dispatch => ({
        setSearchQuery: (query) => {
            return dispatch(setInfrastructureSearchQuery(query))
        }
    })),
    withProps((props) => {
        const search = _.get(props, 'history.location.search');

        let defaultValues = {
            ..._.get(props, 'typeDefinitionAttributeListQuery.typeDefinitionAttributeList.typeDefinitionAttributes', []).reduce((acc, cur) => {
                if (cur.value) {
                    acc[_.get(cur, 'index')] = _.get(cur, 'value');
                }
                if (cur.addOnAttribute) {
                    acc[_.get(cur, 'addOnAttribute.index')] = _.get(cur, 'addOnAttribute.value');
                }
                return acc;
            }, {}),
        }

        return {
            search,
            defaultValues,
            queryParams: parseSearchQueryParams(props.query)
        }
    }),
    connect((state) => {
        return {
            clientListView: _.get(state, 'settings.componentBrowser.clientListView') === "true",
            redirectTab: _.get(state, 'settings.activeInfrastructureTab', 'dashboard')
        }
    }, (dispatch) => {
        return {
            setActiveTab: (tab) => dispatch(setActiveInfrastructureTab(tab))
        }
    }),
    withProps((props) => ({
        activeKey: _.get(props, 'match.params.index', props.redirectTab)
    }))
)(InfrastructureRoute);
