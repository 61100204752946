// @flow
import React, { Component } from 'react';
import Panel from "../../components/Panel";
import {withTranslation} from "react-i18next";
import QualityIssueForm from "../../components/QualityIssueForm";
import {compose, withProps} from "recompose";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {Col, Row} from "antd";
import QualityIssueEditorInfo from "../../components/QualityIssueEditorInfo";

type Props = {

};

type State = {

}

class ServiceTicketQualityIssueRoute extends Component<Props, State> {

  render() {
    const { t, serviceTicketId } = this.props;


    return <div className={"p-10"}>
        <Row gutter={16}>
            <Col md={16}>
                <Panel title={t('Quality Issues')}>
                    <QualityIssueForm serviceTicketId={serviceTicketId}/>
                </Panel>
            </Col>
            <Col md={8}>
                <div className={"mt-50"}>
                    <QualityIssueEditorInfo serviceTicketId={serviceTicketId}/>
                </div>
            </Col>
        </Row>
    </div>;
  }
}

export default compose(
    withTranslation(),
    withRouter,
    withProps((props) => ({
        serviceTicketId: _.get(props, 'match.params.id')
    }))
)(ServiceTicketQualityIssueRoute);

