// @flow
import React, {Component} from 'react';
import {compose, withProps} from "recompose";
import {graphql} from "@apollo/client/react/hoc";
import Query from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";

type Props = {
    enum: string,
    value: string,
};

type State = {

}

class EnumLabel extends Component<Props, State> {

  render() {
    const { text } = this.props;

    return <span>{text}</span>;
  }
}

export default compose(
    graphql(Query,{
        options: (props) => ({
            variables: {
                name: props.enum
            }
        })
    }),
    waitWhileLoading("data"),
    withProps((props)=>({
        text: _.chain(props).get("data.__type.enumValues").find({name: props.value}).get("description",props.value).value()
    }))
)(EnumLabel);

