// @flow
import React, {Component} from 'react';
import StarredComponentsList from "./../StarredComponentsList";
import type {PortletInterface} from "./PortletInterface";


class StarredComponentsPortlet extends Component<Props, State> implements PortletInterface {


    render() {


        return <StarredComponentsList/>;
    }
}

export default StarredComponentsPortlet;
