// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Popover, Tag} from "antd";

type Props = {
    activeAlerts?: any
}

const ComponentAlertBadges = ({activeAlerts}: Props) => {
    const {t} = useTranslation();

    if (_.get(activeAlerts, 'total', 0) < 1) return null;

    const errorCount = _.get(activeAlerts, 'errors');
    const warningCount = _.get(activeAlerts, 'warnings');
    const informationCount = _.get(activeAlerts, 'information');

    return <div className={'flex-row gap-12'}>
        {
            errorCount
                ? <Popover content={`${t('This component has')} ${errorCount} ${t('active error(s)')}`}>
                    <Tag color={'error'} className={'alertTextBadge errorAlerts'}>{errorCount}</Tag>
                </Popover>
                : null
        }
        {
            warningCount
                ? <Popover content={`${t('This component has')} ${warningCount} ${t('active warning(s)')}`}>
                    <Tag color={'warning'} className={'alertTextBadge errorAlerts'}>{warningCount}</Tag>
                </Popover>
                : null
        }
        {
            informationCount
                ? <Popover content={`${t('This component has')} ${informationCount} ${t('active information')}`}>
                    <Tag color={'processing'} className={'alertTextBadge errorAlerts'}>{informationCount}</Tag>
                </Popover>
                : null
        }
    </div>
};

export default ComponentAlertBadges
