export function getRemainingTourSteps(steps, progress) {
  return Object.keys(steps).reduce((acc, key) => {
    const clientSteps = steps[key];
    const serverGroup = progress.find((item) => item.index === key);
    if (serverGroup) {
      acc[key] = clientSteps.filter(({id}) => {
        const serverItem = serverGroup.steps.find((stepItem) => {
          return stepItem.index === id
        });
        if (!serverItem) return true;
        return !serverItem.status
      })
    } else {
      acc[key] = clientSteps
    }
    return acc;
  }, {});
}
