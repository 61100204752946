import React from "react";

const SvgComponentSubscriptionImage = props => (
    <svg viewBox="0 0 364.1 330.8" {...props}>
        <path
            d="M156.7 326.6c-10.5 5.6-27.6 5.6-38.2 0-10.5-5.6-10.5-14.6 0-20.1s27.6-5.6 38.2 0c10.5 5.6 10.5 14.6 0 20.1z"
            opacity={0.5}
            fill="#ccc"
        />
        <path
            d="M168.3 304.4v-7.9h-1.6c-1.5-2.3-3.9-4.5-7.4-6.3-12-6.3-31.4-6.3-43.4 0-3.5 1.8-5.9 4-7.4 6.3h-1.6v7.8c0 4.2 3 8.3 9 11.5 12 6.3 31.4 6.3 43.4 0 6-3.1 9-7.2 9-11.4z"
            fill="#878787"
        />
        <ellipse cx={137.6} cy={296.5} rx={30.7} ry={16.2} fill="#c9c9c9"/>
        <path
            d="M159.3 308c-12 6.3-31.4 6.3-43.4 0-5.7-3-8.7-7-8.9-10.9-.3 4.3 2.7 8.7 8.9 12 12 6.3 31.4 6.3 43.4 0 6.2-3.3 9.2-7.6 8.9-12-.2 3.9-3.2 7.9-8.9 10.9z"
            fill="#fff"
        />
        <path
            d="M122.8 301.9c-.5-.3-.8-.6-.8-1v-10.5c0-.8 1.2-1.5 2.8-1.5 1.5 0 2.8.7 2.8 1.5v8.7l27.8-3.1c1.5-.2 3 .3 3.3 1.1.3.8-.6 1.6-2.1 1.7l-31.1 3.5c-.8.1-1.7 0-2.3-.3-.3 0-.4-.1-.4-.1z"
            fill="#fff"
        />
        <path
            opacity={0.5}
            fill="#aaa"
            d="M338.5 316.7l-25.7-14.8 25.7-14.8 25.6 14.8z"
        />
        <path fill="#545454" d="M364.1 283.1l-25.6 14.8v-29.6l25.6-14.9z"/>
        <path fill="#c9c9c9" d="M338.5 268.3l-25.7-14.9 25.7-14.8 25.6 14.8z"/>
        <path fill="#969696" d="M338.5 297.9l-25.7-14.8v-29.7l25.7 14.9z"/>
        <path fill="#fff" d="M329.2 281.9l-7.1-4.2v-8.2l7.1 4.1z"/>
        <g>
            <path
                opacity={0.5}
                fill="#a5a5a5"
                d="M20.8 254.8L0 242.8l20.8-12 20.8 12z"
            />
            <path fill="#545454" d="M41.6 227.6l-20.8 12v-24l20.8-12.1z"/>
            <path fill="#c9c9c9" d="M20.8 215.6L0 203.5l20.8-12 20.8 12z"/>
            <path fill="#969696" d="M20.8 239.6L0 227.6v-24.1l20.8 12.1z"/>
            <path fill="#fff" d="M13.3 226.6l-5.8-3.4v-6.7l5.8 3.4z"/>
        </g>
        <g>
            <path
                opacity={0.5}
                fill="#b5b5b5"
                d="M73.8 126L58 116.9l15.8-9.1 15.7 9.1z"
            />
            <path fill="#545454" d="M89.5 105.3l-15.7 9.1V96.2l15.7-9.1z"/>
            <path fill="#c9c9c9" d="M73.8 96.2L58 87.1 73.8 78l15.7 9.1z"/>
            <path fill="#969696" d="M73.8 114.4L58 105.3V87.1l15.8 9.1z"/>
            <path fill="#fff" d="M68.1 104.6l-4.4-2.6v-5.1l4.4 2.6z"/>
        </g>
        <g>
            <path
                d="M201.3 26.4L156.2.4c-.9-.5-1.7-.5-2.3-.2l-4.4 2.5s1.9.7 3.1 1.1v32.8c0 2.7 1.6 5.8 3.6 6.9l43.1 24.9v3.9l4.4-2.5c.7-.5 1.2-1.5 1.2-2.9V33.4c0-2.7-1.6-5.8-3.6-7z"
                fill="#878787"
            />
            <path
                d="M196.9 72.2l-45.1-26c-2-1.2-3.6-4.3-3.6-6.9V5.7c0-2.7 1.6-3.9 3.6-2.7l45.1 26c2 1.2 3.6 4.3 3.6 6.9v33.5c0 2.7-1.6 4-3.6 2.8z"
                fill="#c9c9c9"
            />
            <path
                d="M197.8 27.9L152.3 2.6c-1.3-.7-2.4-.5-3 .5.6-.2 1.2-.1 1.9.3l45.5 25.3c2 1.1 3.7 4.2 3.7 6.9l.5 33.5c0 1-.2 1.8-.6 2.3 1-.3 1.7-1.4 1.7-3.1l-.5-33.5c0-2.7-1.7-5.8-3.7-6.9z"
                fill="#fff"
            />
            <g fill="#fff">
                <path d="M166.9 34.6c0 1.9-1.4 2.7-3 1.8-1.7-1-3-3.3-3-5.3 0-1.9 1.4-2.7 3-1.8 1.7 1 3 3.4 3 5.3z"/>
                <ellipse
                    transform="rotate(-30 173.117 38.198)"
                    cx={173.1}
                    cy={38.2}
                    rx={2.5}
                    ry={4.3}
                />
                <ellipse
                    transform="rotate(-30 182.33 43.519)"
                    cx={182.3}
                    cy={43.5}
                    rx={2.5}
                    ry={4.3}
                />
            </g>
        </g>
        <g>
            <g fill="#444">
                <path
                    d="M275 198.4v-8.7l10.3-51.8-10.2-6.3-20.3 11.2-.1 66.8c0 4.5 1.7 7.5 4.3 8.5l9 5.7 1.1-4.3 1.6-7.8c2.6-3.8 4.3-8.7 4.3-13.3z"/>
                <path d="M275 223.7c-5.6 3.1-10.2-.4-10.1-7.7l.1-66.8 20.3-11.2-.1 66.8c0 7.3-4.6 15.8-10.2 18.9z"/>
            </g>
            <g fill="#939393">
                <ellipse
                    transform="rotate(-30 303.35 86.68)"
                    cx={303.3}
                    cy={86.7}
                    rx={21.7}
                    ry={37.6}
                />
                <path d="M322.4 119.1l-77.3 44.3-41.6-62.4 80.6-46.6z"/>
            </g>
            <path
                d="M292 124.6c0-17.4-12.2-38.6-27.3-47.3-6.9-4-13.1-4.7-17.9-2.6h-.1s-.1 0-.1.1l-.6.3c-28.5 14-60.1 20.7-91.9 19.5l-9-.4 73.3 125.6 4-7.7c14.5-28 35.6-52 61.6-69.8 4.9-2.7 8-8.9 8-17.7z"
                fill="#cecece"
            />
            <path
                d="M226.8 191.5c0-33.4-23.5-74.1-52.4-90.8-14.4-8.3-27.5-9.1-37-3.7h-.1-.1l-8.6 5 3-.6c-6.1 6.4-9.7 16.4-9.7 29.5 0 33.4 23.5 74.1 52.4 90.8 11.4 6.6 21.9 8.5 30.5 6.3l-1.4 2 7.9-4.6s.1 0 .1-.1l.3-.2c9.4-5.5 15.1-17.1 15.1-33.6z"
                fill="#ededed"
            />
            <path
                d="M220.7 195.1c0-33.4-23.5-74.1-52.4-90.8-14.2-8.2-27.2-9.1-36.6-3.9l-1.3.8c-.4.3-.9.6-1.3.9l2.7-.6c-6.1 6.4-9.7 16.4-9.7 29.5 0 33.4 23.5 74.1 52.4 90.8 11.4 6.6 21.9 8.5 30.5 6.3l-1.2 1.8c10.3-5 16.9-17.1 16.9-34.8z"
                fill="#fff"
            />
            <ellipse
                transform="rotate(-30 166.526 165.82)"
                cx={166.5}
                cy={165.8}
                rx={42.8}
                ry={74.1}
                fill="#828282"
            />
            <ellipse
                transform="rotate(-30 166.526 165.82)"
                cx={166.5}
                cy={165.8}
                rx={37.6}
                ry={65}
                fill="#232323"
            />
            <path
                d="M168.7 218c-25.4-14.7-46-50.3-46-79.7 0-15.4 5.7-26 14.8-30.5-10.3 3.8-16.9 14.8-16.9 31.4 0 29.3 20.6 65 46 79.7 12.1 7 23 7.9 31.2 3.9-8 2.9-18.1 1.6-29.1-4.8z"
                fill="#fff"
            />
            <defs>
                <ellipse
                    id="ComponentSubscriptionImage_svg__a"
                    transform="rotate(-30 166.526 165.82)"
                    cx={166.5}
                    cy={165.8}
                    rx={37.6}
                    ry={65}
                />
            </defs>
            <clipPath id="ComponentSubscriptionImage_svg__b">
                <use
                    xlinkHref="#ComponentSubscriptionImage_svg__a"
                    overflow="visible"
                />
            </clipPath>
            <g clipPath="url(#ComponentSubscriptionImage_svg__b)">
                <path fill="#e2e2e2" d="M159.6 154.8l66.8-51.6 24.2 42.1-78.3 32.1z"/>
                <ellipse
                    transform="rotate(-30 166.529 165.822)"
                    cx={166.5}
                    cy={165.8}
                    rx={7.5}
                    ry={13}
                    fill="#919191"
                />
            </g>
            <g>
                <path
                    d="M260 199l-.4-.2c-1.3-.8-2.4-2.8-2.4-4.6v-12.9c0-1.8 1.1-2.6 2.4-1.8l.4.2c1.3.8 2.4 2.8 2.4 4.6v12.9c0 1.8-1.1 2.6-2.4 1.8z"
                    fill="#fff"
                />
            </g>
        </g>
    </svg>
);

export default SvgComponentSubscriptionImage;
