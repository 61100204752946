// @flow
import React, {Component} from 'react';
import SvgNewInvoice from "../../svg-graphics/SvgNewInvoice";
import SvgUpcomingServiceControls from "../../svg-graphics/SvgUpcomingServiceControls";
import SvgNews from "../../svg-graphics/SvgNews";
import SvgNewServiceAction from "../../svg-graphics/SvgNewServiceAction";
import SvgProductInReview from "../../svg-graphics/SvgProductInReview";
import SvgServiceTicketSetAssignee from "../../svg-graphics/SvgServiceTicketSetAssignee";

type Props = {
    width?: any,
    color?: any,
    type: any,
};


class NotificationSettingsListTableIcons extends Component<Props, State> {


    getIcon = (type, widthSetting, colorSetting) => {
        switch (type) {
            case'newInvoice':
                return <SvgNewInvoice width={widthSetting} color={colorSetting}/>;
            case'newServiceAction':
                return <SvgNewServiceAction width={widthSetting} color={colorSetting}/>;
            case'news':
                return <SvgNews width={widthSetting} color={colorSetting}/>;
            case'productInReview':
                return <SvgProductInReview width={widthSetting} color={colorSetting}/>;
            case'serviceTicketSetAssignee':
                return <SvgServiceTicketSetAssignee width={widthSetting} color={colorSetting}/>;
            case'upcomingServiceControls':
                return <SvgUpcomingServiceControls width={widthSetting} color={colorSetting}/>;
            default:
                return <SvgNews width={widthSetting} color={colorSetting}/>
        }
    };

    render() {
        const {width, color, type} = this.props;

        const widthSetting = width ? width : '150px';
        const colorSetting = color ? color : '#8394AE';

        return <div>
            {this.getIcon(type, widthSetting, colorSetting)}
        </div>
    }
}

export default NotificationSettingsListTableIcons;