// @flow
import React, {Component} from 'react';
import FileUploader from "../FileUploader";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import AddServiceTicketFilesMutation from "../../graphql/mutations/addServiceTicketFiles.graphql";
import queue from 'async/queue';
import BulkProgress from "../BulkProgress";

type Props = {
    addServiceTicketFilesMutation: Function,
    serviceTicketIds: string[],
    onFinished?: Function,
};

type State = {
    loading: boolean,
    errors?: any[],
    finished: boolean,
    finishedCount: number,
    total: number,
}

class ServiceTicketsBulkFileUpload extends Component<Props, State> {

    state = {
        loading: false,
        finished: false,
        finishedCount: 0,
        errors: [],
        total: 0,
    };

    constructor(props: Props) {
        super(props);

        const {addServiceTicketFilesMutation} = props;

        this.queue = queue(({serviceTicketId, fileId, filename}, callback) => {

            const fileCreateInputs = [{
                fileId,
                filename
            }];

            addServiceTicketFilesMutation({
                variables: {
                    serviceTicketId,
                    files: fileCreateInputs
                }
            })
                .then(() => {

                })
                .catch((err) => {
                    this.setState({
                        errors: [...this.state.errors, err]
                    })
                })
                .finally(() => {
                    this.setState({
                        finishedCount: this.state.finishedCount + 1
                    });
                    callback();
                })


        }, 1);


        // assign a callback
        this.queue.drain = () => {
            if (this.props.onFinished) {
                this.props.onFinished();
            }
        };
    }

    handleUpload = (uploadResult) => {

        const fileId = _.get(uploadResult, 'response.fileId');
        const filename = _.get(uploadResult, 'name');
        if (fileId) {
            this.setState({
                total: this.state.total + this.props.serviceTicketIds.length
            }, () => {
                this.props.serviceTicketIds.forEach(serviceTicketId => {
                    this.queue.push({fileId, serviceTicketId, filename});
                })
            })
        }

    };


    render() {


        return <div>
            {this.state.total ? <BulkProgress total={this.state.total} finished={this.state.finishedCount}/> : null}
            <FileUploader
                multiple
                onUpload={this.handleUpload}
            />
        </div>;
    }
}

export default compose(
    graphql(AddServiceTicketFilesMutation, {
        name: 'addServiceTicketFilesMutation',
    }),
)(ServiceTicketsBulkFileUpload);
