// @flow
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import withProfile from "../../hoc/withProfile";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {compose} from "redux";
import _ from "lodash";
import TDATable from "../TDATable";
import ContentHeader from "../../components/Typo/ContentHeader";
import ComponentTypeDefaultServiceTasks from "./defaultServiceTasks";
import ComponentTypeQuery from "../../graphql/queries/componentType.graphql";
import ContentWrapper from "../ContentWrapper";
import {Col, Row} from "antd";
import {Button} from "@dreebit/react-components";
import Panel from "../Panel";
import SplitPanel from "../SplitPanel";
import Colors from "../../themes/default/colors";
import ComponentTypeErrorPatterns from "../ComponentTypeErrorPatterns";
import withResources, {checkResource} from "../../hoc/withResources";


type Props = {
    index: string,
    componentTypeQuery: any,
    onServiceReportConfigRowClick: (row: any) => void,
};

class ComponentTypeDashboard extends Component<Props> {

    render() {
        const {index, componentTypeQuery, t, onServiceReportConfigRowClick, resources} = this.props;

        const componentType = _.get(componentTypeQuery, 'componentType');

        const canAdministrateServiceReports = checkResource(["administrateServiceReports"],resources);

        return <div>
            <ContentHeader title={_.get(componentType, 'name')}/>
            <ContentWrapper>
                <Row gutter={16}>
                    <Col lg={12}>
                        <Panel title={t("Attributes")}>
                            <TDATable attributes={_.get(componentType, 'typeDefinitionAttributeList.typeDefinitionAttributes')}/>
                        </Panel>
                    </Col>
                    <Col className={"pt-50"} lg={12}>

                        {_.get(componentType, 'contaminationDeclarationTypeDefinitionAttributeList.total', 0) > 0 ?
                            <SplitPanel
                                className={"mb-10"}
                                leftColor={Colors.success}
                                icon={"check"}
                                title={t('Contamination Declaration')}
                                description={t('Contamination Declaration is available')}
                            /> : null}

                        {_.get(componentType, 'finalTestTypeDefinitionAttributeList.total', 0) > 0 ? <SplitPanel
                            className={"mb-10"}
                            leftColor={Colors.success}
                            icon={"check"}
                            title={t('Final Test')}
                            description={t('Final test is available')}
                        /> : null}

                        {canAdministrateServiceReports ?
                          <Link to={`/catalog/componentTypes/detail/${index}/serviceReportConfig`}>
                              <SplitPanel
                                className={"mb-10"}
                                leftColor={Colors.info}
                                icon={"file-alt"}
                                title={t('Service report templates')}
                                description={t('Manage the templates for service reports')}
                              />
                          </Link>
                        : null}

                        <Panel title={t("Error Pattern")}>
                            <ComponentTypeErrorPatterns componentTypeIndex={index}/>
                        </Panel>

                        <Panel title={t('Default Services Tasks')}
                               topRight={<Link to={`/catalog/componentTypes/detail/${index}/create`}>
                                   <Button ghost size={'small'} type={'primary'} icon={'plus'}>{t('Add')}</Button>
                               </Link>}>
                            <ComponentTypeDefaultServiceTasks index={index}/>
                        </Panel>
                    </Col>
                </Row>
            </ContentWrapper>
        </div>;
    }
}


export default compose(
    withResources(),
    withProfile(),
    withTranslation(),
    withRouter,
    graphql(ComponentTypeQuery, {
        name: 'componentTypeQuery',
        options: (props: Props) => ({
            variables: {
                index: props.index,
            }
        }),
    }),
    waitWhileLoading('componentTypeQuery'),
)(ComponentTypeDashboard)
