import React from "react";
import {Grow} from "../Flex";
import {Radio} from 'antd';
import {useTranslation} from "react-i18next";

type Props = {
    type?: any[],
    onChange: any
}

const ListTypeButtons = ({type, onChange}: Props) => {
    const {t} = useTranslation();

    return <Grow>
        <Radio.Group
            value={type}
            onChange={(e) => onChange(e.target.value)}
        >
            <Radio.Button value="day">{t('Day')}</Radio.Button>
            <Radio.Button value="month">{t('Month')}</Radio.Button>
            <Radio.Button value="year">{t('Year')}</Radio.Button>
        </Radio.Group>
    </Grow>
};

export default ListTypeButtons
