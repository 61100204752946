// @flow
import React, {Component} from 'react';
import {Skeleton} from 'antd';

import './subselectionList.css';


class SubselectionListLoading extends Component<Props> {

    render() {

        // TODO : Skeleton style anpassen

        return <div className="list-wrapper">
            <div className="list-header pt-20">
                <Skeleton active title={false} paragraph={{rows: 2, width: ['85%', '40%']}}/>
            </div>

            <div className="list-body pt-20">
                <Skeleton active title={false} paragraph={{rows: 5, width: ['85%', '85%', '85%', '85%', '85%']}}/>
            </div>
        </div>;
    }
}

export default SubselectionListLoading;