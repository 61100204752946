import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Root from "./routes/root";
import {fetch as fetchPolyfill} from 'whatwg-fetch'


Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
};

Array.prototype.move = function (index, newIndex) {

    let removedElement = null;

    if (newIndex > -1 && newIndex < this.length) {
        removedElement = this.splice(index, 1)[0]
    } else {
        return;
    }

    return this.splice(newIndex, 0, removedElement);
};

window.__DEV__ = false;


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    window.__DEV__ = true;
}


window.fetch = fetchPolyfill;

ReactDOM.render(
    <Root/>,
    document.getElementById('root'),
);

serviceWorker.unregister();
