// @flow
import React, {Component} from 'react';
import {Tooltip} from "antd";
import {withTranslation} from "react-i18next";

type Props = {
    name: string,
    serialNo: string,
};


class ComponentTab extends Component<Props, State> {


    render() {
        let {name, serialNo, t} = this.props;

        if (!name) {
            name = "";
        }

        const shortName = name.length > 20 ? `${name.substring(0, 15)}...` : name;

        if (serialNo || shortName.length < name.length) {
            return <Tooltip title={<span>
        {shortName.length < name.length ? <span>{name}</span> : serialNo && serialNo.length ?
            <span>{t('Serial')}: {serialNo}</span> : ''}
        </span>}>

                <span>{shortName}</span>
            </Tooltip>;
        }

        return name;
    }
}

export default withTranslation()(ComponentTab);
