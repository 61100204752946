// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from "lodash";
import ContentHeader from "../Typo/ContentHeader";
import ContentWrapper from "../ContentWrapper";
import ProfileForm from "../ProfileForm";


class ProfileDashboard extends Component<Props, State> {

    render() {
        const {t, i18n} = this.props;

        return <div>
            <ContentHeader title={t('Profile')}/>
            <ContentWrapper>
                <ProfileForm onSubmit={(data) => {
                    const lang = _.get(data,'data.updateProfile.language');
                    i18n.changeLanguage(lang);
                }}/>
            </ContentWrapper>
        </div>;
    }
}

export default withTranslation()(ProfileDashboard);
