// @flow
import React, {Component} from "react"
import _ from 'lodash';
import LogisticianDashboard from "./../../components/LogisticianDashboard";
import Error from "../../components/Error";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {withProps} from "recompose";
import queryString from "query-string";
import withResources from "../../hoc/withResources";

type Props = {
    match: any,
    filter: any
}

class LogisticianDashboardRoute extends Component<Props> {

    render() {

        const {match, filter} = this.props;
        const logisticianId = _.get(match, 'params.id');

        if (!logisticianId) {
            return <Error type="404"/>
        }

        return <LogisticianDashboard logisticianId={logisticianId} filter={filter}/>;
    }

}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        const query = _.get(props, 'history.location.search');
        return {
            filter: queryString.parse(query),
        }
    }),
    withResources(["logistician"])
)(LogisticianDashboardRoute);
