// @flow
import React, {Component} from 'react';
import {Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";

type Props = {};

type State = {}

class DecoSuccessRoute extends Component<Props, State> {

  render() {
    const { t } = this.props;

    return <div className={"full-size bg-light-gray p-50"}>
        <div className={"bg-white br-10 bs-20 overflow-hidden"} style={{width: 500, margin: '0 auto'}}>
            <div className={"bg-primary text-center pv-20"}>
                <Icon type={"download"} style={{fontSize: 64, color: "white"}}/>
            </div>
            <div className={"p-20"}>
                <h2 className={"mt-0"}>{t('Contamination declaration created')}</h2>
                <hr/>
                <p>
                    {t('Contamination declaration success text')}
                </p>
            </div>
        </div>
    </div>;
  }
}

export default withTranslation()(DecoSuccessRoute);
