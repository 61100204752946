import React from "react";
import {useTime} from "../../hooks/useTime";
import moment from "moment";

interface Props {

}

const Clock = ({}: Props) => {

    const now = useTime(1000);

    return moment(now).toDate().toLocaleTimeString()
}

export default Clock;
