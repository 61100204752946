// @flow
import React, {Component} from 'react';
import _ from "lodash";
import PortletQuery from "./../../graphql/queries/portlet.graphql"
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Col, Row} from "antd";
import type {PortletInterface} from "../Portlets/PortletInterface";
import {PORTLET_COMPONENT_MAP} from "../DashboardPortletWrapper";
import DashboardPortletNameForm from "../DashboardPortletNameForm";
import {compose} from "recompose"
import Empty from "../Empty";
import Panel from "../Panel";
import {withTranslation} from "react-i18next";

type Props = {
    portletId: string
};

type State = {}

class DashboardPortletSettings extends Component<Props, State> {

    render() {
        const {
            t,
            portletQuery
        } = this.props;

        const userPortlet = portletQuery.userPortlet;
        const PortletComponent: PortletInterface = _.get(PORTLET_COMPONENT_MAP, _.get(userPortlet, 'portlet.index'));

        if (!PortletComponent) {
            return <Empty/>
        }

        let SettingsModal = null;
        if (PortletComponent.RENDER_SETTINGS_COMPONENT) {
            SettingsModal = PortletComponent.RENDER_SETTINGS_COMPONENT(userPortlet)
        }

        return <div>
            <Row gutter={16}>
                <Col md={12}>
                    <Panel
                        title={t('Info')}
                    >
                        <DashboardPortletNameForm portletId={this.props.portletId}/>
                    </Panel>

                    {SettingsModal ? <Panel
                        title={t('Settings')}
                    >
                        {SettingsModal}
                    </Panel> : null}
                </Col>
                <Col md={12}>
                    <Panel
                        title={t('Preview')}
                    >
                        <div style={{height: 500}}>
                            <PortletComponent portlet={userPortlet}/>
                        </div>
                    </Panel>
                </Col>
            </Row>
        </div>;
    }
}

export default compose(
    graphql(PortletQuery, {
        name: 'portletQuery',
        options: (props) => {
            return {
                variables: {
                    id: props.portletId
                }
            }
        }
    }),
    withTranslation(),
    waitWhileLoading('portletQuery')
)(DashboardPortletSettings);
