// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from "lodash";
import ContentHeader from "../../../components/Typo/ContentHeader";
import ContentWrapper from "../../../components/ContentWrapper";
import {compose} from "recompose";
import Panel from "../../../components/Panel";
import ErrorPatternCreateForm from "../../../components/ErrorPatternCreateForm";
import {withRouter} from "react-router-dom";


class ErrorPatternCreateRoute extends Component<Props, State> {

    render() {
        const {t, history} = this.props;

        return <div>
            <ContentHeader title={t('New Error Pattern')}/>
            <ContentWrapper>
                <Panel>
                    <ErrorPatternCreateForm onCreate={(res) => {
                        const id = _.get(res, 'data.createErrorPattern.id')
                        if (id) history.push(`/catalog/errorPatterns/${id}`)
                    }}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation()
)(ErrorPatternCreateRoute);
