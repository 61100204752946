import React, {useState} from 'react';
import ComponentSearchForm from "../ComponentSearchForm";
import {Button} from "@dreebit/react-components";
import {useTranslation} from "react-i18next";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import queryString from "query-string";
import {useMutation} from "@apollo/client";
import CreateBookmarkMutation from "./../../graphql/mutations/createBookmark.graphql";
import {message} from "antd";
import _ from "lodash";
import BookmarkListQuery from "../../graphql/queries/bookmarkList.graphql";
import {bookmarkListVariables} from "../BookmarkList";

interface Props {

}

const InfrastructureSearchFilter = ({params, onChange}: Props) => {

    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [createBookmarkMutation] = useMutation(CreateBookmarkMutation, {
        variables: {
            input: {
                title: t('New infrastructure search'),
                type: 'infrastructureSearch',
                data: JSON.stringify({
                    link: `/infrastructure/search?${queryString.stringify(params)}`,
                    params
                }),
            }
        },
        refetchQueries: [{
            query: BookmarkListQuery,
            variables: bookmarkListVariables(["infrastructureDetail", "infrastructureSearch", "componentSelection"])
        }]
    });

    const saveBookmark = () => {
        setLoading(true);
        createBookmarkMutation()
            .then((res) => {
                if (_.get(res, 'data.createBookmark.id')) {
                    message.success(t("Bookmark has been saved."));
                } else {
                    message.error(t("Failed to save bookmark."))
                }
            })
            .catch((err) => {
                message.error(t("Failed to save bookmark."))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <ComponentSearchForm
                disableBookmark
                cleanDefaultValues
                params={params}
                onChange={onChange}
            />
            <div className={"flex-col mt-20"}>
                <Button loading={loading} onClick={saveBookmark}>
                    <FontAwesomeIcon name={"bookmark"} className={"pr-5"}/>
                    {t('Save as bookmark')}
                </Button>
            </div>
        </div>
    );
}

export default InfrastructureSearchFilter;
