// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import TypeDefinitionAttributesForm from "./../TypeDefinitionAttributesForm";
import CreateCustomAttributeMutation from "./../../graphql/mutations/createCustomAttribute.graphql";
import customAttributeList from "../../graphql/queries/customAttributeList.graphql";

type Props = {
    onCreate: Function,
};

class CustomAttributeCreate extends Component<Props, State> {

    render() {
        const {createCustomAttributeMutation} = this.props;

        return <TypeDefinitionAttributesForm
            index="customAttribute"
            formProps={{
                mutation: createCustomAttributeMutation,
                onSubmit: (res) => {
                    if (this.props.onCreate) {
                        this.props.onCreate(res);
                    }
                },
                translateMutationOptions: ({values}) => {
                    if(!values.name.en){
                        values.name.en = values.name.de
                    }
                    if(!values.name.de){
                        values.name.de = values.name.en
                    }
                    return {
                        variables: {
                            input: values
                        }
                    }
                }
            }}
        />
    }
}

export default compose(
    graphql(CreateCustomAttributeMutation, {
        name: 'createCustomAttributeMutation',
        options: () => ({
            refetchQueries: [{
                query: customAttributeList,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    }),
)(CustomAttributeCreate);
