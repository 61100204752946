import {applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './../reducers'

const enhancers = [];

const middleware = [
    thunk
];

if (process.env.NODE_ENV === 'development') {
    // middleware.push(logger);

    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    rootReducer,
    undefined,
    composedEnhancers
);

export default store;
