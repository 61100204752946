// @flow
import React, {Component} from 'react';
import type {UserInfo} from "../../types";
import {Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";

type Props = {
    userInfo: UserInfo,
    showIcon: boolean,
    t: Function
};


class UserLabel extends Component<Props> {

    static defaultProps = {
        showIcon: false
    };

    render() {
        const {userInfo, showIcon, t} = this.props;

        if (!userInfo.active) {
            return <span>
        {!userInfo.email ?
            <span style={{opacity: '0.7'}}>
          {showIcon ? <Icon type={'user'}/> : null} {userInfo.name || t('User was deactivated')}
          </span>
            :
            <a href={`mailto:${userInfo.email}`} style={{opacity: '0.7'}}>
                {showIcon ? <Icon type={'user'}/> : null} {userInfo.name || t('User was deactivated')}
            </a>
        }
      </span>
        }

        if (!userInfo.email) {
            return <span style={{opacity: '1.0'}}>
          {showIcon ? <Icon type={'user'}/> : null} {userInfo.name}
      </span>
        }
        return <a href={`mailto:${userInfo.email}`} style={{opacity: '1.0'}}>
            {showIcon ? <Icon type={'user'}/> : null} {userInfo.name}
        </a>
    }

}

export default withTranslation()(UserLabel);
