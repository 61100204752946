// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import ServiceTicketDashboard from "../../components/ServiceTicketDashboard";
import Error from "../../components/Error";

type Props = {
    match: {
        params: any
    },
    history: {
        push: Function
    },
    t: Function,
}

class ServiceTicketDashboardRoute extends Component<Props> {


    render() {

        const {match} = this.props;
        const ticketId = _.get(match, 'params.id');
        if (!ticketId) {
            return <Error status={'404'}/>
        }
        return <ServiceTicketDashboard serviceTicketId={ticketId}/>
    }

}

export default withTranslation()(ServiceTicketDashboardRoute);

