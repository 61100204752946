import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {logout} from "../actions/auth";
import _ from "lodash";

// const networkInterface = createNetworkInterface({
//   uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql',
//   opts: {
//     credentials: 'same-origin',
//   },
// });


export default (token, language, dispatch, i18n) => {
    let cache = new InMemoryCache({
        possibleTypes: {
            ServiceTicketMaintenance: []
        }
    });

    const httpLink = createHttpLink({uri: process.env.REACT_APP_GRAPHQL_URI || `/graphql${window.__DEV__ ? '?' : ''}`});

    const middlewareLink = setContext((request, options) => {

        return {
            headers: {
                authorization: token ? `Bearer ${token}` : null,
                "Accept-Language": token ? `${language}` : ""
            }
        }
    });

    const errorLink = onError((args) => {
        const {networkError, graphQLErrors, response, operation} = args;


        if ((networkError && networkError.statusCode === 401) || (graphQLErrors && graphQLErrors.length && _.first(graphQLErrors).status === 401)) {
            dispatch(logout())
        }else if (response && response.errors) {
            response.errors = response.errors.map((err) => {
                return {
                    ...err,
                    operation
                }
            })
        }
    });


    const link = middlewareLink.concat(errorLink).concat(httpLink);

    const client = new ApolloClient({
        link,
        cache,
        defaultOptions: {
            query: {
                fetchPolicy: 'cache-first'
            }
        }
    });

    return client;
};
