// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";

import RoleListQuery from "./../../graphql/queries/roleList.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import FillQueryTable from "../QueryTable/fill";

type Props = {
    roleListQuery: query,
    t: Function,
    onClick: Function,
}

class RoleList extends Component<Props> {
    render() {
        const {roleListQuery, t, onClick} = this.props;

        return <FillQueryTable
            style={{borderRadius: 0}}
            className={"link-table"}
            columns={[
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: t('Name')
                },
                {
                    key: 'description',
                    dataIndex: 'description',
                    title: t('description')
                }
            ]}
            onRow={(record) => {
                return {
                    onClick: () => {
                        onClick(record);
                    },
                };
            }}
            size={'middle'}
            rowKey={"id"}
            query={roleListQuery}
            itemsKeyPath={'roleList.roles'}
            totalKeyPath={'roleList.total'}
            sortBy={'name'}/>
    }
}

export default compose(
    graphql(RoleListQuery, {
        name: 'roleListQuery',
        options: () => ({
            variables: {
                params: {
                    start: 0,
                    limit: 40,
                }
            }
        }),
    }),
    waitWhileLoading('roleListQuery', ['roleList.roles']),
    withTranslation(),
)(RoleList);
