// @flow
import roleListQuery from "./../../graphql/queries/roleList.graphql"
import userQuery from "./../../graphql/queries/userDetails.graphql"

const UserRoleLoaderConfig = {
    dataKey: 'roleList',
    totalKey: 'total',
    itemsKey: 'roles',
    valueKey: 'id',
    textKey: 'name',
    query: roleListQuery,
    getQueryVariables: (searchText?: string) => {
        return {
            params: {
                search: {
                    query: searchText,
                    fields: ['name']
                }
            }
        }
    },
    valueQuery: userQuery,
    getValueQueryVariables: (value) => {
        return {
            id: value
        }
    },
    valueQueryDataKey: 'user'
};

export default UserRoleLoaderConfig;
