// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {withProps} from "recompose";
import ComponentTdaQuery from "../../graphql/queries/component-FormAttributes.graphql";

const ComponentVersionalAttributeBreadcrumb = ({tda, t, index}) =>
    <span>{_.get(tda, 'name', t(index))}</span>;

export default compose(
    graphql(ComponentTdaQuery, {
        name: 'componentTdaQuery',
        options: props => ({
            variables: {
                id:_.get(props, 'match.params.componentId'),
            }
        }),
    }),
    withProps((props) => ({
        index: _.get(props, 'match.params.index'),
        tda: _.find(_.get(props,'componentTdaQuery.component.typeDefinitionAttributeList.typeDefinitionAttributes',[]),{
            index: _.get(props, 'match.params.index'),
        })
    })),
    withTranslation()
)(ComponentVersionalAttributeBreadcrumb);

