// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import {Query} from '@apollo/client/react/components';
import QueryTable from "../QueryTable";
import type {DataLoaderType} from "../DataLoader/DataLoaderType";
import SelectAsync from "../Form/fields/SelectAsync";
import Sizes from "../../themes/default/sizes";

import "./index.css"
import {Checkbox, Select} from "antd";
import {Button} from "@dreebit/react-components";
import {toastError, toastMessage} from "../../utils/toast";

type CRUDMethod = 'create' | 'update' | 'delete';

type Props = {
    options?: {
        title: string,
        value: string
    }[],
    multiple?: boolean,
    showSearch: boolean,
    addMutation: Function,
    translateMutationOptions: (method: CRUDMethod, userId, input) => any,
    removeMutation: Function,
    selectDataLoader: DataLoaderType,
    valuesDataLoader: {
        ...DataLoaderType,
        readKey: string,
        writeKey: string,
    },
};

type State = {
    adding: boolean
}

class AccessControlPicker extends Component<Props, State> {

    _handleSelect = (selected) => {

        if (selected) {

            const {addMutation, translateMutationOptions} = this.props;

            this.performMutation(() => {
                return addMutation(translateMutationOptions('create', selected, {
                    read: true,
                    write: false
                }))
            })

        }
    };

    _handleChange = (userId, value) => {

        const {updateMutation, translateMutationOptions} = this.props;


        let input = {
            read: value === 'read' || value === 'write',
            write: value === 'write',
        };

        if (this.props.multiple && this.props.options){

            input = this.props.options.reduce((acc, cur) => {
                acc[cur.value] = value.indexOf(cur.value) > -1;
                return acc;
            },{});

        }

        this.performMutation(() => {
            return updateMutation(translateMutationOptions('update', userId, input))
        })


    };

    _handleRemove = (aclId) => {
        const {removeMutation, translateMutationOptions} = this.props;

        this.performMutation(() => {
            return removeMutation(translateMutationOptions('delete', aclId))
        })

    };

    performMutation = (promise) => {
        this.setAdding(true)
            .then(promise)
            .then((res) => {
                toastMessage(_.get(res, 'data'));
                return this.setAdding(false);
            })
            .catch((err) => {
                toastError(err);
            })
    };

    setAdding = (adding) => {
        return new Promise((resolve) => {
            this.setState({
                adding
            }, () => {
                resolve(adding);
            })
        })
    };

    render() {
        const {t, selectDataLoader, valuesDataLoader, showSearch} = this.props;

        const columns = [
            {
                title: t('Name'),
                dataIndex: 'name',
                key: 'name',
                render: (text, item) => {
                    return _.get(item, valuesDataLoader.textKey);
                }
            }, {
                title: t('Access'),
                dataIndex: 'access',
                key: 'access',
                render: (text, item) => {


                    const options = this.props.options  || [<Select.Option value={'read'}>{t('Read only')}</Select.Option>,
                        <Select.Option value={'write'}>{t('Write')}</Select.Option>];

                    if (this.props.multiple){
                        const value = this.props.options.map((option) => {
                            return option.value;
                        }, {}).filter((key) => _.get(item, key));
                        return <Checkbox.Group options={options} value={value} onChange={(val) => this._handleChange(item.id, val)} />
                    }

                    let value = item[valuesDataLoader.readKey] ? "read" : null;
                    if (item[valuesDataLoader.writeKey]) {
                        value = "write";
                    }

                    return <Select mode={this.props.multiple ? "multiple" : undefined} onChange={(val) => this._handleChange(item.id, val)} value={value} size={'small'}>
                        {options}
                    </Select>
                }
            }, {
                dataIndex: 'remove',
                key: 'remove',
                render: (text, item) => {

                    return <Button onClick={() => this._handleRemove(item.id)} size={'small'} type={'danger'}
                                   icon={"delete"}/>
                }
            }
        ];

        return <div className={"access-control-picker"}>
            <SelectAsync
                style={{width: '100%'}}
                showSearch={showSearch}
                onSelect={this._handleSelect}
                loaderConfig={selectDataLoader}
            />
            <Query
                query={valuesDataLoader.query}
                variables={valuesDataLoader.getQueryVariables ? valuesDataLoader.getQueryVariables() : null}
                fetchPolicy="cache-and-network"
            >
                {(query) => (
                    <QueryTable
                        style={{marginTop: Sizes.grid}}
                        columns={columns}
                        size={"small"}
                        itemsKeyPath={`${valuesDataLoader.dataKey}.${valuesDataLoader.itemsKey}`}
                        totalKeyPath={`${valuesDataLoader.dataKey}.${valuesDataLoader.totalKey}`}
                        query={query}/>
                )}

            </Query>

        </div>;
    }
}


export default withTranslation()(AccessControlPicker);
