// @flow
import React, {Component} from 'react';
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";

import {Col, Grow, Row} from "../Flex";

type Props = {
    title: string,
    tools?: any,
    path: any,
};

class ContentHeaderWithBreadcrumb extends Component<Props, State> {
    render() {
        const {title, tools, path} = this.props;

        return <Col style={{width: '100%', background: '#fff', borderBottom: '1px solid #cfdbe2'}}>
            <Row style={{width: '100%', fontSize: '24px', color: '#656565', padding: '15px 15px 15px 25px'}}>
                <Grow style={{verticalAlign: 'middle'}}>
                    {title}
                </Grow>
                <Col style={{paddingRight: '10px', verticalAlign: 'middle'}}>
                    {tools ? tools : null}
                </Col>
            </Row>
            <Row style={{padding: '0px 25px 10px 20px', width: '100%'}}>
                <div className="border-radius-5 bg-light-grey" style={{padding: '5px 5px 5px 10px', width: '100%'}}>
                    <Breadcrumb>
                        {path.map((item) => {
                            return item.id && item.name && item.link
                                ? item.id < 0
                                    ? <Breadcrumb.Item key={item.id}>
                                        <Link to={item.link}>
                                            {item.name}
                                        </Link>
                                    </Breadcrumb.Item>
                                    : <Breadcrumb.Item key={item.id}>
                                        <Link to={item.link + `/${item.id}`}>
                                            {item.name}
                                        </Link>
                                    </Breadcrumb.Item>
                                : null
                        })}
                    </Breadcrumb>
                </div>
            </Row>
        </Col>
    }
}

export default ContentHeaderWithBreadcrumb;