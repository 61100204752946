// @flow
import React, {Component} from 'react';
import OpenedComponentsTabs from "../../components/OpenedComponentsTabs";
import {Route, Switch, withRouter} from "react-router-dom";
import InfrastructureDashboard from "../../components/InfrastructureDashboard";
import InfrastructureSearchRoute from "../infrastructureSearch";
import ComponentDashboardRoute from "../componentDashboard";
import StockManagement from "../stockManagement";
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {setActiveInfrastructureTab} from "../../actions/settings";
import ComponentSearchTab from "../../components/ComponentSearchTab";

type Props = {
    activeKey: string,
};


class InfrastructureDetail extends Component<Props, State> {

    render() {

        const {activeKey} = this.props;

        return <div className={"full-height overflow-hidden"}>
            <Switch>
                <Route path="/infrastructure/dashboard" component={InfrastructureDashboard} />
                <Route path="/infrastructure/search" component={InfrastructureSearchRoute}/>
                {/* TODO: Wieder hinzufügen oder komplett entfernen (Entscheidung noch offen) */}
                {/*<Route path="/infrastructure/stock/:index" component={StockManagement}/>*/}
                {/*<Route path="/infrastructure/stock" component={StockManagement}/>*/}
                <Route path="/infrastructure/:id" component={ComponentDashboardRoute} onDelete={this._handleClosedComponentTab}/>
            </Switch>
        </div>;
    }
}

export default compose(
    withRouter
)(InfrastructureDetail);
