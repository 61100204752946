import _ from "lodash";

export const updateCache = (prev, next, itemsKeyPath, totalKeyPath, uniqueBy: ?string = "id") => {

    const {fetchMoreResult} = next;

    if (!fetchMoreResult) return prev;

    const assign = _.cloneDeep(prev);
    let newList = _.get(fetchMoreResult, itemsKeyPath);
    const prevItems = _.get(prev, itemsKeyPath, prev);

    if (prevItems) {
        newList = [...prevItems, ...newList];
    }

    _.set(assign, itemsKeyPath, _.uniqBy(newList, uniqueBy));
    _.set(assign, totalKeyPath, _.get(fetchMoreResult, totalKeyPath));

    if (_.get(assign, itemsKeyPath, []).length !== (newList.length)) {
        console.warn('Length of merged list not as expected', _.get(assign, itemsKeyPath, []).length, newList.length);
        const countKeys = _.chain(newList)
            .groupBy(item => item.id)
            .mapValues((value) => value.length)
            .value();
        const duplicates = Object.keys(countKeys).filter((key) => countKeys[key] > 1);

        _.set(assign, totalKeyPath, _.get(fetchMoreResult, totalKeyPath) - duplicates.length);
        console.warn("Duplicate keys:",duplicates)
    }

    return assign;
};

