// @flow
import React, {Component} from 'react';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import TDAAttributesForm from "../TDAAttributesForm";
import _ from 'lodash'
import ServiceTicketQualityIssueFormQuery from "./../../graphql/queries/ServiceTicketQualityIssueForm.graphql"
import UpdateQualityIssueFormMutation from "./../../graphql/mutations/UpdateQualityIssueForm.graphql"

type Props = {
    serviceTicketId: string
};

type State = {

}

class QualityIssueForm extends Component<Props, State> {

  render() {
    const { attributes, mutation, serviceTicketId } = this.props;

    if (!attributes){
        return null;
    }

    return <TDAAttributesForm
        mutation={mutation}
        translateMutationOptions={({changes, values}) => {
            return {
                variables: {
                    serviceTicketId,
                    input: changes
                }
            }
        }}
        attributes={attributes}
    />
  }
}

export default compose(
    withTranslation(),
   graphql(ServiceTicketQualityIssueFormQuery,{
       name: 'serviceTicketQualityIssueFormQuery',
       options: ({serviceTicketId}) => {
           return {
               variables: {
                   serviceTicketId
               }
           }
       }
   }),
   graphql(UpdateQualityIssueFormMutation,{
       name: 'mutation',
   }),
   withProps((props) => ({
       attributes: _.get(props,'serviceTicketQualityIssueFormQuery.serviceTicket.qualityIssue.typeDefinitionAttributeList.typeDefinitionAttributes')
   }))
)(QualityIssueForm);
