import auth from './auth';
import app from './app';
import settings from './settings';
import {persistCombineReducers} from "redux-persist";
import storage from 'redux-persist/lib/storage'

const config = {
    key: 'primary',
    storage
};
const reducers = persistCombineReducers(config, {
    app,
    auth,
    settings,
});

export default reducers;
