// @flow
import React, {Component} from "react"

type Props = {
    color: string,
    height: number,
    theme: string
}

class ColorBar extends Component<Props> {


    static defaultProps = {
        color: '#f28300',
        height: 2,
        theme: 'vsm'
    };


    render() {
        const {theme, height, color} = this.props;

        return <div className={"main-navigation-divider"} style={{height: height}}>

        </div>;
    }

}

export default ColorBar;
