// @flow
import React, {Component} from 'react';
import ServicePlanForm from "../ServicePlanForm";
import PlanFormHeader from "./header"
import Panel from "../Panel";


type Props = {
    servicePlanId: string,
    componentId: string,
    servicePlanDashboardQuery: any,
    onDelete: Function,
};


export default class ServicePlanDashboard extends Component<Props> {

    render() {
        const {servicePlanId, onDelete, componentId} = this.props;
        return <div>
            <PlanFormHeader servicePlanId={servicePlanId} componentId={componentId} onDelete={onDelete}/>
            <div className={'ph-10'}>
                <Panel>
                    <ServicePlanForm servicePlanId={servicePlanId}/>
                </Panel>
            </div>
        </div>;
    }
}

