import React from 'react';
import _ from "lodash";
import {compose} from "redux";
import { graphql } from '@apollo/client/react/hoc';
import DefaultServiceTaskNameQuery from "../../graphql/queries/defaultServiceTask.graphql";
import {withTranslation} from "react-i18next";

const DefaultServiceTaskBreadcrumb = ({defaultServiceTaskNameQuery, t}) =>
    <span>{_.get(defaultServiceTaskNameQuery, 'defaultServiceTask.name', '...')}</span>;

export default compose(
    graphql(DefaultServiceTaskNameQuery, {
        name: 'defaultServiceTaskNameQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.defaultServiceTaskId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(DefaultServiceTaskBreadcrumb));
