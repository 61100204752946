import React from "react";
import _ from "lodash";
import ComponentTokenDetails from "../../components/ComponentTokenDetails";

interface Props {

}

const ComponentTokenRoute = (props: Props) => {

    const componentId = _.get(props, 'match.params.id');

    return <ComponentTokenDetails componentId={componentId} />;
}

export default ComponentTokenRoute;
