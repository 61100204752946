import React from "react";
import Styles from "styled-components";
import ServerErrorGif from './500.gif';
import NotFoundErrorGif from './404.gif';
import NotFoundMonsterErrorGif from './404Monster.gif';
import useProfile from "../../hooks/useProfile";
import {checkResource} from "../../hoc/withResources";
import useResources from "../../hooks/useResources";
import {useTranslation} from "react-i18next";

interface Props {
    type?: 404 | 500
}

const Wrapper = Styles.div`
     background: #fff;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
`

const TextWrapper = Styles.div`
    display:block;
    text-align: left;
    width: auto;
    margin:auto;
    padding: 30px;
    backgroundColor: #eaeff5;
    borderRadius: 6px;
`

const ErrorPage = (props: Props) => {

    const resources = useResources();
    const {t} = useTranslation();
    let GIF = ServerErrorGif;

    if (props.type === 404){
        if (checkResource(['developer'],resources)){
            GIF = NotFoundMonsterErrorGif
        }else {
            GIF = NotFoundErrorGif
        }
    }

    return  <Wrapper>
        <img style={{width: '100%', height: 'auto', maxWidth: 500}} src={GIF} alt={'Error 500'}/>
        <TextWrapper>
            <h1 style={{fontSize: '40px'}}>{t("Hoppla")}!</h1>
            <p style={{fontSize: '18px'}}>
                {t("Da scheint etwas schief gelaufen zu sein Hier geht's zurück zur")} <a href="https://www.vsm-cloud.com/">{t("Homepage")}</a>.
            </p>
        </TextWrapper>
    </Wrapper>;
}

export default ErrorPage;
