// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {Row} from "../Flex";

import MatchBookmarksToolbar from "../MatchBookmarksToolbar";
import MatchBookmarksList from "../MatchBookmarksList";
import Enums from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Button} from "@dreebit/react-components";
import BookmarkNameInput from "../BookmarkNameInput";
import UpdateAccessToken from "../../graphql/mutations/updateAccessToken.graphql";
import AccessToken from "../../graphql/queries/accessToken.graphql";

type Props = {
    t: Function,
    enumsQuery: query,
    id: any,
    dataJson: any,
    updateAccessTokenMutation: Function,
    onSubmit: Function,
}

type State = {
    values: any,
    selectedRowIds: any[],
    bookmarkName: any,
}

class MatchBookmarks extends Component<Props, State> {
    state = {
        values: {type: 'workflowBoard', query: ''},
        selectedRowIds: [],
        bookmarkName: {bookmarkName: 'bookmarkId'}
    };

    onChangeToolbar = (change) => {
        this.setState({
            values: {
                ...this.state.values,
                ...change
            }
        });
    };

    onChangeList = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowIds: selectedRows.map(item => item.id)
        })
    };

    onChangeBookmarkname = (change) => {
        this.setState(
            {bookmarkName: change}
        );
    };

    _matchBookmark = () => {
        const {id, dataJson, updateAccessTokenMutation, onSubmit} = this.props;
        const {selectedRowIds, bookmarkName} = this.state;

        const orignal = Object.keys(dataJson).length ? dataJson : {};
        const json = {
            ...orignal,
            ...{[bookmarkName.bookmarkName]: selectedRowIds.length < 2 ? selectedRowIds[0] : selectedRowIds}
        };

        updateAccessTokenMutation({variables: {id: id, attributes: {data: JSON.stringify(json)}}}).then(() => {
            onSubmit();
        })
    };

    render() {
        const {t, enumsQuery} = this.props;
        const {selectedRowIds, bookmarkName} = this.state;

        return <div>
            <MatchBookmarksToolbar values={this.state.values} onChange={this.onChangeToolbar}
                                   enums={_.get(enumsQuery, '__type.enumValues')}/>
            <div style={{padding: '0px 16px 0px 16px'}}>
                <MatchBookmarksList values={this.state.values} onChange={this.onChangeList}/>
            </div>

            {selectedRowIds.length ? <Row style={{justifyContent: 'space-between', padding: '20px 16px 0px 16px'}}>
                    <BookmarkNameInput value={bookmarkName} onChange={this.onChangeBookmarkname}/>
                    <Button type={"primary"} onClick={this._matchBookmark}>{t('Save')}</Button>
                </Row>
                : null}
        </div>
    }
}

export default compose(
    graphql(Enums, {
        name: 'enumsQuery',
        options: () => ({
            variables: {
                name: 'BookmarkType'
            }
        }),
    }),
    waitWhileLoading('enumsQuery', '__type.enumValues'),
    graphql(UpdateAccessToken, {
        name: 'updateAccessTokenMutation',
        options: props => ({
            refetchQueries: [{
                query: AccessToken,
                variables: {
                    id: props.id
                }
            }]
        })
    }),
    withTranslation(),
)(MatchBookmarks);
