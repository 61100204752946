// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import _ from "lodash"
import ServiceTicketListExportQuery from "../../graphql/queries/serviceTicketListExport.graphql";
import Values from "../../themes/default/values";
import {filterFromProps, orderFromProps, searchParamsFromProps} from "../ServiceSearch/results";
import BookmarkQuery from "../../graphql/queries/bookmarkData.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    t: Function,
    params: {
        parentId?: number,
    },
};

type State = {
    loading: boolean,
}

class ServiceSearchExport extends Component<Props> {

    state = {
        loading: false
    };

    handleOnClick = () => {

        const filter = filterFromProps(this.props);

        this.setState({
            loading: true
        }, () => {
            this.props.client.query({
                query: ServiceTicketListExportQuery,
                variables:{
                    params: {
                        filter: Object.keys(filter).length ? filter : undefined,
                        sort: orderFromProps(this.props),
                        search: searchParamsFromProps(this.props),
                        start: 0,
                        limit: Values.pagingSize * 2
                    }
                }
            }).then(({data}) => {

                const download = _.get(data, 'serviceTicketList.export.link');
                window.open(download, '_blank');

            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })

    };

    render() {
        const {t, serviceTicketListExportQuery} = this.props;

        return <Tooltip title={t('Export')}>
            <Button icon={"download"} loading={this.state.loading} onClick={this.handleOnClick} className="mr-10"/>
        </Tooltip>
    }
}

export default compose(
    withTranslation(),
    withRouter,
    withApollo,
    withTranslation(),
    graphql(BookmarkQuery,{
        name: 'bookmarkQuery',
        skip: (props) => !_.get(props,'params.selectionBookmark'),
        options: (props) => {
            return {
                variables: {
                    bookmarkId: _.get(props,'params.selectionBookmark')
                }
            }
        }
    }),
    waitWhileLoading('bookmarkQuery',null, {
        optional: true,
        allowErrors: true,
        allowToasts: true
    }),
)(ServiceSearchExport);
