// @flow
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {withProps} from "recompose";
import _ from "lodash";
import ErrorPatternFormAttributesQuery from "../../graphql/queries/errorPatternForm.graphql";
import ErrorPatternUpdateMutation from "../../graphql/mutations/updateErrorPattern.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributeInlineForm from "./../Form/attributes-inline-form";
import Sizes from "../../themes/default/sizes";
import errorPatternDetails from "../../graphql/queries/errorPatternDetails.graphql";


export default compose(
    withTranslation(),
    graphql(ErrorPatternFormAttributesQuery, {
        name: 'errorPatternFormAttributesQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    graphql(ErrorPatternUpdateMutation, {
        name: 'errorPatternUpdateMutation',
        options: props => ({
            refetchQueries: [{
                query: errorPatternDetails,
                variables: {
                    id: props.id
                }
            }]
        })
    }),
    waitWhileLoading('errorPatternFormAttributesQuery', 'errorPattern.typeDefinitionAttributeList.typeDefinitionAttributes'),
    withProps((props) => ({
        mutation: _.get(props, 'errorPatternUpdateMutation'),
        translateMutationOptions: (formValues) => {
            const {changes} = formValues;

            return {
                variables: {
                    input: changes,
                    id: props.id,
                }
            }
        },

        submitButtonWrapperStyle: {textAlign: "right", marginRight: Sizes.grid * 2},
        attributes: _.get(props, 'errorPatternFormAttributesQuery.errorPattern.typeDefinitionAttributeList.typeDefinitionAttributes', [])
    }))
)(AttributeInlineForm);
