// @flow
import React, {Component} from 'react';
import {SocketProvider} from 'socket.io-react';
import io from 'socket.io-client';
import _ from 'lodash';
import ProfileQuery from "../graphql/queries/profile.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import waitWhileLoading from "../hoc/waitWhileLoading";
import {connect} from "react-redux";
import VSMLoadingAnimation from "../components/VSMLoadingAnimation";


class SocketProviderWrapper extends Component<Props, State> {
	componentDidMount() {

	}

	render() {
		const {profileQuery, disabled} = this.props;
		if (disabled) {
			return this.props.children;
		}

		console.log('connect socket');
		const uniqueIdentifier = _.get(profileQuery, 'profile.uniqueIdentifier');
		const socket = io.connect();
        socket.on('error', e => {
        	console.warn("Socket connect error");
        	console.log(e);
		});
		socket.on("connect", function () {
			socket.emit("join", uniqueIdentifier)
		});

		return <SocketProvider socket={socket}>
			{this.props.children}
		</SocketProvider>;
	}
}

export default compose(
	connect((state) => {
		return {
			disabled: _.get(state, 'settings.forms.developerSettings.disableSocket', false)
		}
	}),
	graphql(ProfileQuery, {
		name: 'profileQuery',
	}),
	waitWhileLoading('profileQuery', 'profile', {
		renderLoader: () => <VSMLoadingAnimation/>
	}),
)(SocketProviderWrapper);
