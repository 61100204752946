// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import TDAAttributesForm from "../TDAAttributesForm";
import CreateMessageMutation from "./../../graphql/mutations/createMessage.graphql";
import {Badge, Tabs} from "antd";
import ConversationList from "../ConversationList";
import {withRouter} from "react-router-dom";
import ConversationListCountQuery from "../../graphql/queries/conversationListCount.graphql";
import ServiceTicketUserListQuery from "../../graphql/queries/serviceTicketUserList.graphql";
import ServiceTicketUserQuery from "../../graphql/queries/serviceTicketUser.graphql";
import ConversationListQuery from "../../graphql/queries/conversationList.graphql";
import {withProps} from "recompose";
import withProfile from "../../hoc/withProfile";
import statics from "../../statics";


type Props = {
    history: any,
    serviceTicketId: string,
    createMessageMutation: Function
};


class ServiceTicketMessageWidget extends Component<Props, State> {


    render() {
        const {t, createMessageMutation, serviceTicketId, history, conversationListCountQuery, profile, serviceTicketUserQuery} = this.props;

        const ticketUsers = [];


        const clientContactEmail = _.get(serviceTicketUserQuery,'serviceTicket.client.contactEmail');
        if (clientContactEmail){
            ticketUsers.push({
                name: _.get(serviceTicketUserQuery,'serviceTicket.client.contactName'),
                email: clientContactEmail
            })
        }

        const contactEmail = _.chain(serviceTicketUserQuery).get('serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes',[]).find({index: 'contactEmail'}).get("value").value();
        if (contactEmail){
            ticketUsers.push({
                name: _.chain(serviceTicketUserQuery).get('serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes',[]).find({index: 'contactName'}).get("value").value(),
                email: contactEmail
            })
        }


        const attributes = [{
            dataLoaderConfig: {
                dataQueryConfig: {
                    query: ServiceTicketUserListQuery,
                    getQueryOptions: (props: any) => {
                        return {
                            variables: {
                                serviceTicketId,
                                params: {
                                    start: 0,
                                    limit: 20,
                                    search: {
                                        query: props.query,
                                        fields: ["name","email","firstName", "lastName"]
                                    }
                                }
                            }
                        }
                    },
                    limitKeyPath:'params.limit',
                    startKeyPath:'params.start',
                    totalKeyPath: 'serviceTicketUserList.total',
                    dataKeyPath: 'serviceTicketUserList.users',
                    titleKeyPath: 'name',
                    valueKeyPath: 'email',
                },
                valueQueryConfig: {
                    query: `query userName($id: ID!) {
                user(userId: $id){
                    id
                    name
                    email
                }
            }
            `,
                    dataKeyPath: 'user',
                    titleKeyPath: 'name',
                    valueKeyPath: 'email',
                    getQueryOptions: (props: any) => {
                        return {
                            variables: {
                                id: props.value
                            }
                        }
                    },
                }
            },
            entityType: 'userEmail',
            index: "participants",
            inputType: "autocomplete",
            multiple: true,
            name: t('Receiver'),
            type: "string",
            rules: [{name: 'required'}, {name: 'email'}],
            optionValues: ticketUsers.map((item) => {
                return {
                    ...item,
                    name: item.name,
                    value: item.email,
                }
            })
        },
            {
                rules: [{name: 'required'}],
                index: "text",
                inputType: "textArea",
                name: t('Text'),
                type: "string",
            }];

        const count = _.get(conversationListCountQuery,'conversationList.total',0);

        return <div>
            <Tabs
                destroyInactiveTabPane
            >
                <Tabs.TabPane
                    key={'new'}
                    tab={t('New message')}
                >
                    <TDAAttributesForm
                        clearOnSubmit
                        cacheChanges={true}
                        mutation={createMessageMutation}
                        attributes={attributes}
                        successMessage={t('Message sent')}
                        submitTitle={t('Send')}
                        translateMutationOptions={(args) => {
                            const {values} = args;
                            return {
                                variables: {
                                    input: {
                                        serviceTicketId: serviceTicketId,
                                        participants: values.participants,
                                        text: values.text
                                    },
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    key={'conversations'}
                    tab={<span>
                        {t('Conversations')}
                        {count ? <Badge className={"ml-10"} count={count}/> : null}

                    </span>}
                >
                    <ConversationList
                        style={{maxHeight: 400, minHeight: 200}}
                        onItemClick={(item) => {
                            history.push(`/messages/${item.id}`)
                        }}
                        queryProps={{
                            variables: this.props.conversationVariables,
                            fetchPolicy: 'network-only'
                        }}
                    />

                </Tabs.TabPane>
            </Tabs>

        </div>
    }
}



export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        const conversationVariables = {
            params: {
                filter: {
                    serviceTicketId: {
                        value: props.serviceTicketId
                    }
                }
            }
        }
        return  {
            conversationVariables
        }
    }),
    graphql(ConversationListCountQuery, {
        name: 'conversationListCountQuery',
        options: (props: any) =>({
            fetchPolicy: 'cache-first',
            variables: props.conversationVariables
        }),
    }),
    graphql(ServiceTicketUserQuery, {
        name: 'serviceTicketUserQuery',
        options: (props: any) =>({
            fetchPolicy: 'cache-first',
            variables: {
                serviceTicketId: props.serviceTicketId
            }
        }),
    }),
    graphql(CreateMessageMutation, {
        name: 'createMessageMutation',
        options: (props: any) => ({
            refetchQueries: [
                {
                    query: ConversationListCountQuery,
                    variables: props.conversationVariables
                },
                {
                    query: ConversationListQuery,
                    variables: props.conversationVariables
                },
                {
                    query: ConversationListQuery,
                    variables: {
                        params: {
                            start: 0,
                            limit: statics.defaultPagingSize,
                            sort: {
                                lastMessageUpdate: "DESC"
                            },
                            filter: {
                                archive: {
                                    value: false
                                },
                                participant: {
                                    value: true
                                }
                            }
                        }
                    }
                }
            ]
        })
    }),
    withProfile()
)(ServiceTicketMessageWidget);
