// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Col, Row} from "antd";
import ServiceProviderAdminForm from "./../ServiceProviderAdminForm";
import ServiceProviderUserList from "./../ServiceProviderUserList";
import Panel from "../Panel";
import Sizes from "../../themes/default/sizes";

type Props = {
    serviceProviderId: string
};

class ServiceProviderUsersDashboard extends Component<Props, State> {
    render() {
        const {serviceProviderId, t} = this.props;


        return <div className={'ph-10'}>
            <Row gutter={2 * Sizes.grid}>
                <Col md={12}>
                    <Panel title={t('Users')} containerStyle={{height: 400}}>
                        <ServiceProviderUserList serviceProviderId={serviceProviderId}/>
                    </Panel>

                </Col>
                <Col md={12}>
                    <Panel title={t('Add admin')}>
                        <ServiceProviderAdminForm serviceProviderId={serviceProviderId}/>
                    </Panel>
                </Col>
            </Row>
        </div>;
    }
}


export default withTranslation()(ServiceProviderUsersDashboard);
