// @flow
import React, { Component } from 'react';
import _ from "lodash";
import ProductFilterList from "../ProductFilterList";
import {message, Modal} from "antd";
import ProductMatchPerformer from "../ProductMatchPerformer";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import ComponentProductChangePerformer from "../ComponentProductChangePerformer";
import {withRouter} from "react-router-dom";
import {toastSuccess} from "../../utils/toast";

type Props = {

};

type State = {

}

class ComponentProductChanger extends Component<Props, State> {

  state = {

  }

  render() {
    const { t, history, componentId } = this.props;
    const { visible, targetId} = this.state;

    return <div>
        <Modal
            title={t("New Product")}
            visible={visible && targetId}
            footer={null}
            onCancel={() => {
                this.setState({
                    visible: false
                })
            }}
        >
            <ComponentProductChangePerformer
                onDone={() => {

                    const target = this.state.targetId;

                    this.setState({
                        visible: false,
                        targetId: null
                    }, () => {
                        toastSuccess(t("Product was changed"))
                        history.push(`/infrastructure/${componentId}`)
                    })


                }}
                componentId={componentId}
                productId={targetId} />
        </Modal>
      <ProductFilterList
          filterValues={{
            fields: ["catalogNo","name"],
            status: "published"
          }}
          tableProps={{
            scroll: { y: 300 }
          }}
          onProductSelect={(productId) => {

            console.log("onProductSelect",productId)
            this.setState({
              targetId: productId,
              visible: true,
            })
          }}
      />
    </div>;
  }
}

export default compose(
    withTranslation(),
    withRouter
)(ComponentProductChanger);
