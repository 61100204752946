// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";
import SplitPanelList from "../SplitPanelList";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentServiceTicketsListQuery from "../../graphql/queries/ComponentServiceTicketsList.graphql";


type Props = {
    t: Function,
    componentId: string,
    iconSplitPanelServiceTicketQuery: any
}


class IconSplitPanelServiceTicket extends Component<Props> {

    render() {

        const {t, iconSplitPanelServiceTicketQuery, iconSplitPanelServiceTicketChildrenQuery,activeServiceTicketChildrenQuery, componentId} = this.props;
        const totalTickets = _.get(iconSplitPanelServiceTicketQuery, 'component.serviceTicketList.total');
        const subComponentsTotalTickets = _.get(iconSplitPanelServiceTicketChildrenQuery, 'component.serviceTicketList.total');
        const activeSubComponentsTotalTickets = _.get(activeServiceTicketChildrenQuery, 'component.serviceTicketList.total');

        return <SplitPanelList
            dataCy={'TEST_10000'}
            items={[
                {
                    link: `/infrastructure/${componentId}/servicetickets?children=true`,
                    largeTitle: totalTickets,
                    title: t('Service Ticket', {count: totalTickets}),
                    description: subComponentsTotalTickets ? `${t('Service tickets including sub components',{count: subComponentsTotalTickets})} ${activeSubComponentsTotalTickets ? `(${t('COUNT active',{count: activeSubComponentsTotalTickets})})` : ''}` : t('Click here to show all service tickets of this component'),
                    icon: "wrench",
                }
            ]}
        />
    }
}


export default compose(
    withTranslation(),
    graphql(ComponentServiceTicketsListQuery, {
        name: 'iconSplitPanelServiceTicketQuery',
        options: (props: Props) => ({
            variables: {
                componentId: props.componentId,
                direct: true,
                children: false
            }
        })
    }),

    graphql(ComponentServiceTicketsListQuery, {
        name: 'iconSplitPanelServiceTicketChildrenQuery',
        options: props => ({
            variables: {
                componentId: props.componentId,
                direct: false,
                children: true
            }
        }),
    }),

    graphql(ComponentServiceTicketsListQuery, {
        name: 'activeServiceTicketChildrenQuery',
        options: props => ({
            variables: {
                componentId: props.componentId,
                direct: false,
                children: true,
                filter: {
                    serviceTicketStateIndex: {
                        operator: 'in',
                        value: ['inProgress', 'open']
                    }
                }
            }
        }),
    }),
    waitWhileLoading('iconSplitPanelServiceTicketChildrenQuery',null,{
        loaderSize: 'small'
    }),
    waitWhileLoading('iconSplitPanelServiceTicketQuery', 'component',{
        loaderSize: 'small'
    }),
    waitWhileLoading('activeServiceTicketChildrenQuery',null, {
        loaderSize: 'small'
    }),
)(IconSplitPanelServiceTicket);




