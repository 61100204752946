// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {Row} from "../Flex";
import _ from "lodash";
import { Layout } from 'antd';
import './index.less';

const { Footer } = Layout;

type Props = {

}

const VSMFooter = ({}: Props) => {
    const {t, i18n} = useTranslation();

    let lang = i18n.language;
    try {
        lang = _.first(lang.split('-'));
    }
    catch (e) {
        lang = 'en';
    }

    return <Footer className={'grey-light-bg VSMFooter'}>
        <Row className={'full-width flex-align-items-center flex-center'}>
        <span style={{textAlign: 'center'}}>
            <span>Copyright © 2021 </span>
            <a href={'https://www.dreebit-service.eu/'}>
                <span>DREEBIT GmbH</span>
            </a>
            <span> | </span>
            <a href={`https://www.vsm-cloud.com/${lang}/${t('imprint')}.html`}>
                {t('Imprint')}
            </a>
            <span> | </span>
            <a href={`https://www.vsm-cloud.com/${lang}/${t('privacy-policy')}.html`}>
                {t('Privacy Policy')}
            </a>
        </span>
        </Row>
    </Footer>
};

export default VSMFooter
