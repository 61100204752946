// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import RoleCreateComponent from "../../components/RoleCreateComponent";
import {Col, Row} from "../../components/Flex";
import PanelComponent from "../../components/Panel";
import ContentHeaderWithBreadcrumb from "../../components/ContentHeaderWithBreadcrumb";
import withResources from "../../hoc/withResources";
import _ from "lodash";

type Props = {
    history: {
        push: Function
    },
    t: Function,
}

class RoleCreate extends Component<Props> {
    render() {
        const {t, history} = this.props;
        return <Col style={{width: '100%'}}>
            <ContentHeaderWithBreadcrumb
                title={t('New role')}
                path={[{
                    name: t("RoleList"),
                    id: -1,
                    link: '/admin/roles',
                }, {
                    name: t('New role'),
                    id: -1,
                    link: '/admin/roles/add',
                }]}
            />
            <Row className={"flex-center"}
                 style={{backgroundColor: '#F9F9F9', height: '100%', width: '100%', marginTop: '20px'}}>
                <PanelComponent
                    children={<RoleCreateComponent onSaved={(res) => {
                        const id = _.get(res, 'data.createRole.id');
                        if (id) history.push(`/admin/roles/${id}`)
                    }}/>}
                    style={{width: '90%'}}
                />
            </Row>
        </Col>;
    }
}

export default withResources(["administrateRoles"])(withTranslation()(withRouter(RoleCreate)));