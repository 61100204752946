// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose, withProps} from "recompose";
import {Tag} from "antd";


class Label extends Component {

    render() {
        return <Tag color={this.props.color}>{this.props.t(this.props.status)}</Tag>;
    }

}


export default compose(
    withTranslation(),
    withProps((props) => ({
        color: props.status === "inReview" ? "volcano" : "green",
    }))
)(Label);
