// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import ContentHeader from "../Typo/ContentHeader";
import {Col, Row} from "antd";
import InfrastructureDashboardBookmarks from "../InfrastructureDashboardBookmarks";
import ContentWrapper from "../ContentWrapper";
import ComponentStatistics from "../ComponentStatistics";

type Props = {
    t: Function
}

class InfrastructureDashboard extends Component<Props> {


    render() {

        const {t} = this.props;

        return <div className={'flex-col full-height'}>
            <ContentHeader title={t('Infrastructure')}/>
            <ContentWrapper className={'full-height mt-10'}>
                <ComponentStatistics />
            </ContentWrapper>
        </div>;
    }

}

export default withTranslation()(InfrastructureDashboard);
