// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import ContentHeader from "../../components/Typo/ContentHeader";
import ClientList from "../../components/ClientList";
import {Button} from "@dreebit/react-components";
import {withProps} from "recompose";
import _ from "lodash";
import queryString from "query-string";
import AttributesInlineForm from "../../components/Form/attributes-inline-form";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";
import Toolbar from "../../components/Toolbar";

type Props = {
    t: Function,
    history: {
        push: Function,
    },
    filter: any,
    search: any
};

class ClientListRoute extends Component<Props, State> {

    render() {
        const {t, history, search} = this.props;

        const attributes = [{
            index: "query",
            type: "string",
            inputType: "text",
            inputTypeOptions: {
                placeholder: t("Search")
            }
        }];

        const filter = {
            hidden: {
                value: [true, false]
            }
        }

        return <NavigationContainer navigationBar={
                <div>
                    <ContentHeader title={t('Client Administration')}
                                   tools={[<Link key={'add'} to={'/admin/clients/new'}><Button>
                                       {t('Add')}
                                   </Button></Link>]}
                    />
                    <Toolbar
                        borderBottom
                        className={"white-bg"}
                        renderLeft={() => <AttributesInlineForm
                            className={"ph-5 pt-5"}
                            attributes={attributes}
                            layout={'vertical'}
                            useSubmit={false}
                            disableToasts
                            mutation={() => { return new Promise((res) => {res()})}}
                            onChange={(attributeValues) => {
                                history.push(`/admin/clients/search?${queryString.stringify(attributeValues)}`)
                            }}
                            values={filter}
                        />}/>
                </div>
            }
        >
                <ClientList onClick={(record) => history.push(`/admin/clients/${record.id}`)}
                            search={search}
                            filter={filter}
                />
        </NavigationContainer>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        const query = _.get(props, 'history.location.search');
        return {
            search: queryString.parse(query),
        }
    }),
)(ClientListRoute);
