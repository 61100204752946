// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import moment from "moment";

import QueryTable from "../QueryTable";
import AcceptLookup from "../../graphql/mutations/acceptLookup.graphql";
import LookupList from "../../graphql/queries/lookupList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Toolbar from "../Toolbar";
import {Button} from "@dreebit/react-components";
import {toastError} from "../../utils/toast";

type Props = {
    t: Function,
    lookupListQuery: query,
    acceptLookupMutation: Function,
};

type State = {
    selectedRowIds: any[],
    loading: boolean,
};

class LookupListTable extends Component<Props, State> {

    state = {
        selectedRowIds: [],
        loading: false,
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    _handleAccept = () => {

        const {acceptLookupMutation} = this.props;

        this.setLoading(true)
            .then(() => {
                const promises = this.state.selectedRowIds.map(id => acceptLookupMutation({variables: {id}}));
                return Promise.all(promises);
            })
            .catch(toastError)
            .finally(() => {
                this.setState({
                    selectedRowIds: [],
                });
                this.setLoading(false);
            })

    };

    render() {
        const {t, lookupListQuery} = this.props;
        const {selectedRowIds} = this.state;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {

                this.setState({
                    selectedRowIds: selectedRows.map(item => item.id)
                })
            },
            getCheckboxProps: record => ({
                disabled: record.lookupState.index === 'accepted',
                name: record.name,
            }),
        };

        return <div>
            {selectedRowIds.length ? <Toolbar
                renderLeft={() => <Button onClick={this._handleAccept}>{t('Accept')}</Button>}
            /> : null}
            <QueryTable
                loading={this.state.loading}
                className={"table"}
                size={'middle'}
                rowSelection={rowSelection}
                query={lookupListQuery}
                itemsKeyPath={'lookupList.lookups'}
                totalKeyPath={'lookupList.total'}
                rowKey={"id"}
                columns={[
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: t('Name')
                    },
                    {
                        dataIndex: 'insert',
                        key: 'insert',
                        title: t('Login'),
                        render: (text) => moment(text).format('L LT')
                    },
                    {
                        dataIndex: 'uuid',
                        key: 'uuid',
                        title: t('uuid')
                    },
                    {
                        dataIndex: 'lookupState.name',
                        key: 'lookupState.name',
                        title: t('state')
                    }
                ]}/>
        </div>;
    }
}


export default compose(
    graphql(LookupList, {
        name: 'lookupListQuery',
    }),
    waitWhileLoading('lookupListQuery', 'lookupList'),
    graphql(AcceptLookup, {
        name: 'acceptLookupMutation',
        options: () => ({
            pollInterval: 5000,
            fetchPolicy: "no-cache",
            refetchQueries: [{
                query: LookupList
            }]
        })
    }),
    withTranslation()
)(LookupListTable);

