// @flow
import React, {Component} from 'react';
import {SizeMe} from "react-sizeme";
import QueryTable from "./index";
import {QueryTable as ReactQueryTable} from "@dreebit/react-components";
import './index.css';

type Props = {
  useReactComponents?: boolean,
  footerHeight?: number
};

type State = {

}

class FillQueryTable extends Component<Props, State> {

  render() {
    let {scrollWidth, useReactComponents, footerHeight} = this.props;

    if(!footerHeight) footerHeight = 75;

    return <SizeMe
        refreshMode={"throttle"}
        monitorHeight
      >
          {({size}) => {

              return <div className={"full-height overflow-hidden"}>
                {
                  useReactComponents
                    ? <ReactQueryTable
                          className={"link-table"}
                          style={size ? {maxHeight: size.height - footerHeight} : null}
                          scroll={size ? {
                            y: size.height ? size.height - footerHeight : 500,
                            x: size.width - 5
                          } : undefined}
                          {...this.props}
                    />
                    : <QueryTable
                        scroll={ size ? {
                          y: size.height ? size.height - footerHeight : 500,
                          x: scrollWidth ? scrollWidth : size.width - 5
                        } : undefined}
                        {...this.props}
                    />
                }
              </div>
          }}
      </SizeMe>
  }
}

export default FillQueryTable;
