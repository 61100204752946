// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {Form, message, Modal, Select} from 'antd';
import {withTranslation} from "react-i18next";
import _ from 'lodash'

import serviceActionTypeForm from './../../graphql/queries/serviceActionTypeList.graphql';
import CreateServiceActionMutation from "./../../graphql/mutations/createServiceAction.graphql";
import AddServiceActionFilesMutation from "./../../graphql/mutations/addServiceActionFiles.graphql";
import ServiceTicketTypeInfoQuery from "../../graphql/queries/ServiceTicketTypeInfo.graphql";
import ServiceActionTypeForm from "../ServiceActionTypeForm";

import type {
    serviceActionTypeList_serviceActionTypeList,
    serviceActionTypeList_serviceActionTypeList_serviceActionTypes
} from "../../graphql/operation-result-types.flow";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withProps} from "recompose";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import ReactDOM from "react-dom";
import Loader from "../Loader";
import serviceActionsTimeline from "../../graphql/queries/serviceActionsTimeline.graphql";

const FormItem = Form.Item;

type Props = {
    t: Function,
    visible: boolean,
    modal: boolean,
    useSubmit: boolean,
    onSaved: Function,
    onCancel: Function,
    defaultServiceActionTypeIndex?: string,
    serviceTicketId: string | number,
    serviceTicketIds: string[],//for multiple serviceplans
    serviceTicketTypeIndex: string,
    createServiceActionMutation: Function,
    addServiceActionFilesMutation: Function,
    serviceActionTypeFormQuery: {
        loading: boolean,
        error?: any,
        serviceActionTypeList?: serviceActionTypeList_serviceActionTypeList
    },
    onCustomCreateAction: Function,
    defaultOpen?: boolean,
    serviceTicketActionsListQuery: any
};

type State = {
    selectedActionTypeIndex?: string,
    serviceActionChanges: any,
    visible: boolean,
    files: any[],
    loading: boolean
}

class ServiceActionWizardForm extends Component<Props, State> {

    static defaultProps = {
        defaultOpen: true
    };

    state = {
        selectedActionTypeIndex: this.props.defaultServiceActionTypeIndex,
        serviceActionChanges: {},
        visible: true,
        files: [],
        loading: true
    };

    selectRef = React.createRef();
    node = null;

    componentDidMount() {
        new Promise(resolve => {
            setTimeout(resolve, 100);
        }).then(() => {
            this.setState({loading: false});
            // const el = this.selectRef.current;
            // if (!el) return;
            // const node = _.first(el.getElementsByClassName('ant-select-selection-search-input'));
            // console.log(node);
            // if(node){
            //     node.focus();
            // }
        });
    }

    handleSelect = (value) => {
        this.setState({
            selectedActionTypeIndex: value
        })
    };

    handleOk = (e) => {
        const {createServiceActionMutation, serviceTicketId, t, onCustomCreateAction} = this.props;
        const {selectedActionTypeIndex, serviceActionChanges} = this.state;

        return new Promise((res) => {
            this.setState({
                loading: true
            }, res)
        }).then(() => {
            const tmpServiceActionChanges = _.cloneDeep(serviceActionChanges);
            const files = _.get(tmpServiceActionChanges, 'files');
            delete tmpServiceActionChanges["files"];

            if (onCustomCreateAction) {
                return onCustomCreateAction(selectedActionTypeIndex, tmpServiceActionChanges, files)
            } else {

                return createServiceActionMutation({
                    variables: {
                        input: tmpServiceActionChanges,
                        serviceActionTypeIndex: selectedActionTypeIndex,
                        serviceTicketId
                    }
                })
                    .then((res) => {
                        return this.handleOnCreated(res, files)
                    })
                    .then((res) => {
                        if (this.props.onSaved) {
                            return this.props.onSaved(res);
                        }
                    })
                    .then(() => {
                        return new Promise((r) => {
                            this.setState({
                                loading: false
                            }, () => {
                                r();
                            });
                        })
                    })
                    .then(() => {
                        this.hideModal();
                    })
                    .catch((e) => {
                        console.error(e);
                        message.error(t('An error occurred while creating this service action'))
                    })

            }
        })


    };

    hideModal = () => {
        if (this.props.modal) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
            this.setState({
                visible: false
            });
        }

    };

    handleChange = (changes, {allValues}) => {

        this.setState({
            serviceActionChanges: {
                ...this.state.serviceActionChanges,
                ...allValues
            },
        })
    };

    handleSubmit = (res, attributeValues) => {
        return this.handleOnCreated(res, attributeValues && attributeValues.files).then(() => {
            if (this.props.onSaved) {
                this.props.onSaved(res);
            }
        });
    };

    renderForm() {
        const {modal, serviceTicketIds, serviceActionTypeFormQuery, t, serviceTicketId, defaultOpen} = this.props;
        const {selectedActionTypeIndex, loading} = this.state;

        const serviceActionTypes = _.get(serviceActionTypeFormQuery, "serviceActionTypeList.serviceActionTypes", []);

        if (loading) {
            return <Loader/>
        }

        return <div ref={this.selectRef}>
            <FormItem style={{marginBottom: 16}} label={null} labelCol={{span: 0}} wrapperCol={{span: 24}}>
                <Select
                    data-cy={'TEST_20002'}
                    onSelect={this.handleSelect}
                    value={selectedActionTypeIndex}
                    autoFocus={true}
                    defaultOpen={true}
                    showSearch
                    filterOption={true}
                    optionFilterProp={'title'}
                    size={'large'}
                    placeholder={t('Please select a service action')}
                    style={{width: '100%'}}>
                    {serviceActionTypes.map((item: serviceActionTypeList_serviceActionTypeList_serviceActionTypes) => (
                        <Select.Option key={item.index} value={item.index} title={`${item.code} - ${item.name}`}>
                            <span><b>{item.code}</b>: {item.name}</span>
                        </Select.Option>
                    ))}
                </Select>
                {!selectedActionTypeIndex ? <p style={{
                    lineHeight: 1.5,
                    padding: '10px 0'
                }}>{t('Use the arrow keys to navigate through the list of service actions Type the code or name of the action your are looking for')}</p> : null}
            </FormItem>

            {selectedActionTypeIndex ?
                <div>
                    <ServiceActionTypeForm
                        serviceTicketId={serviceTicketId}
                        serviceTicketIds={serviceTicketIds}
                        values={this.state.serviceActionChanges}
                        index={selectedActionTypeIndex}
                        onCustomCreateAction={(a) => this.handleOk(a)}
                        onSubmit={(a, b) => {
                            this.handleSubmit(a, b)
                            this.setState({selectedActionTypeIndex: undefined});
                        }}
                        onChange={(a, b) => this.handleChange(a, b)}/>
                </div>

                : null}
        </div>
    }


    render() {
        const {t, modal, visible} = this.props;
        const {loading} = this.state;

        if (modal) {
            return <Modal
                width={800}
                title={t('New Service Action')}
                open={visible !== undefined ? visible : this.state.visible}
                confirmLoading={loading}
                onCancel={this.hideModal}
                okButtonProps={{id: "TEST_20003"}}
                footer={null}
            >
                {this.renderForm()}

            </Modal>
        }

        return this.renderForm();
    }

    handleOnCreated(res, files) {
        const {addServiceActionFilesMutation} = this.props;
        const id = _.get(res, 'data.createServiceAction.id');
        if (id && files && files.length) {
            return addServiceActionFilesMutation({
                variables: {
                    serviceActionId: id,
                    files: files.map(item => ({
                        fileId: item,
                    }))
                }
            }).then(() => {
                return res;
            })
        }

        return new Promise((result) => {
            result(res);
        })
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceTicketTypeInfoQuery, {
        name: 'serviceTicketInfoQuery',
        options: props => ({
            skip: props.serviceTicketTypeIndex,
            variables: {
                id: props.serviceTicketId,
            }
        }),
    }),
    waitWhileLoading('serviceTicketInfoQuery', ['serviceTicket.serviceTicketType.index'], {
        optional: true
    }),
    withProps((props) => ({
        serviceTicketTypeIndex: _.get(props, 'serviceTicketInfoQuery.serviceTicket.serviceTicketType.index', props.serviceTicketTypeIndex)
    })),
    graphql(serviceActionTypeForm, {
        name: 'serviceActionTypeFormQuery',
        options: (props) => ({
            fetchPolicy: _.get(props, 'serviceActionTypesFetchPolicy', 'cache-and-network'),
            variables: {
                serviceTicketId: props.serviceTicketId,
                serviceTicketTypeIndex: props.serviceTicketTypeIndex
            }
        }),
    }),
    waitWhileLoading('serviceActionTypeFormQuery', ['serviceActionTypeList.serviceActionTypes']),
    graphql(CreateServiceActionMutation, {
        name: 'createServiceActionMutation',
        options: (props: Props) => ({
            skip: props.onCustomCreateAction,
            refetchQueries: [{
                query: serviceTicketDashboard,
                variables: {
                    id: props.serviceTicketId
                }
            }, {
                query: serviceActionsTimeline,
                variables: {
                    id: props.serviceTicketId
                }
            }]
        })
    }),
    graphql(AddServiceActionFilesMutation, {
        name: 'addServiceActionFilesMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: serviceTicketDashboard,
                variables: {
                    id: props.serviceTicketId
                }
            }, {
                query: serviceActionsTimeline,
                variables: {
                    id: props.serviceTicketId
                }
            }]
        })
    }),
)(ServiceActionWizardForm);

