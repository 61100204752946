// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import ServiceActionWizardForm from "./index";
import BulkProgress from "../BulkProgress";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import CreateServiceActionMutation from "../../graphql/mutations/createServiceAction.graphql";
import AddServiceActionFilesMutation from "../../graphql/mutations/addServiceActionFiles.graphql";
import Bold from "../Utilities/Bold";
import Error from "../Error";
import {Collapse} from "antd";
import async from "async";

const Panel = Collapse.Panel;

type Props = {
    preferredServiceTicketType?: string,
    serviceTicketIds: string[],
    createServiceActionMutation: Function,
    addServiceActionFilesMutation: Function,
    onFinished?: Function,
};

type State = {
    loading: boolean,
    errors?: any[],
    finished: boolean,
    finishedCount: number
}

class BulkServiceActionWizardForm extends Component<Props, State> {

    state = {
        loading: false,
        finished: false,
        finishedCount: 0,
        errors: [],
    };

    _handleOnCreateAction = (serviceActionTypeIndex, data, files) => {

        const {serviceTicketIds, createServiceActionMutation, addServiceActionFilesMutation, onFinished} = this.props;
        const {errors} = this.state;

        let count = 0;
        const me = this;
        this.setLoading(true)
            .then(() => {

                return new Promise((resolve, reject) => {
                    async.everySeries(serviceTicketIds, function (serviceTicketId, callback) {

                        createServiceActionMutation({
                            variables: {
                                serviceActionTypeIndex,
                                serviceTicketId,
                                input: data
                            }
                        }).then((res) => {
                            const id = _.get(res, 'data.createServiceAction.id');
                            if (id && files && files.length) {
                                return addServiceActionFilesMutation({
                                    variables: {
                                        serviceActionId: id,
                                        files: files.map(item => ({
                                            fileId: item,
                                        }))
                                    }
                                }).then(() => res)
                            }

                            return res;
                        }).catch((err) => {
                            me.setState({
                                errors: [...errors, err]
                            })
                        }).finally(() => {
                            count++;
                            me.setState({
                                finishedCount: count
                            });
                            callback(null, true)
                        })

                    }, function (err, result) {
                        if (err) {
                            return reject(err)
                        }
                        resolve(result);
                    });
                })

            })
            .finally(() => {
                me.setState({
                    finished: true,
                    loading: false,
                }, () => {
                    if (onFinished) {
                        onFinished();
                    }
                });
            })


    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {serviceTicketIds, t, preferredServiceTicketType} = this.props;
        const {loading, finished, finishedCount, errors} = this.state;

        return <div>
            {loading || finished ?
                <BulkProgress total={serviceTicketIds.length} finished={finishedCount}>
                    <Bold>{t('SOME of TOTAL service actions has been created', {
                        SOME: finishedCount,
                        TOTAL: serviceTicketIds.length
                    })}</Bold>
                    {errors.length ? <Collapse>
                        <Panel header={t('Error')} key="1">
                            {errors.map((err) => <Error error={err}/>)}
                        </Panel>

                    </Collapse> : null}
                </BulkProgress> :
                <ServiceActionWizardForm
                    serviceTicketTypeIndex={preferredServiceTicketType}
                    serviceTicketId={_.first(serviceTicketIds)}
                    serviceTicketIds={serviceTicketIds}
                    onCustomCreateAction={this._handleOnCreateAction}
                />}

        </div>;
    }

}

export default compose(
    withTranslation(),
    graphql(CreateServiceActionMutation, {
        name: 'createServiceActionMutation',
    }),
    graphql(AddServiceActionFilesMutation, {
        name: 'addServiceActionFilesMutation'
    }),
)(BulkServiceActionWizardForm);
