import React from "react";
import {Grow, Row} from "../Flex";
import './index.css';
import _ from "lodash";

type Props = {
    title: string,
    count?: number | string,
    icon?: any,
    loading?: boolean
}

const StatisticsPanel = ({count, icon, title, loading}: Props) => {

    return <Row className={`StatisticsPanelWrapper ${loading ? 'loading' : ''}`}>
        {
            icon
                ? <div className={'StatisticsPanelIcon'}>
                    {loading ? null : icon}
                </div>
                : null
        }

        <Grow className={'StatisticsPanelTextWrapper'}>
            {
                !_.isNil(count)
                    ? <div className={'StatisticsPanelCount'}>
                        {loading ? null : count}
                    </div>
                    : null
            }

            <div className={'StatisticsPanelTitle'}>
                {loading ? null : title}
            </div>
        </Grow>
    </Row>
};

export default StatisticsPanel
