import React from "react"
import {Menu} from 'antd';
import _ from 'lodash';
import {Link} from "react-router-dom";
import MaterialCommunityIcon from "../components/Icons/MaterialCommunityIcon";

const SubMenu = Menu.SubMenu;

export default class MenuFactory {


    static getMenuItems(menu, t, caretDown = true, resources: string[] = [], showName = true) {

        return menu.filter((item) => {

            const itemResources = _.get(item, 'resources', []);

            if (!itemResources.length) return true;

            return itemResources.reduce((acc, cur) => acc || resources.indexOf(cur) > -1, false)

        }).map(item => {


            if (item.items && item.items.length > 0) {
                return <SubMenu key={item.id} title={
                    <span data-cy={item.id} tour-id={item.tourId}>
                        {
                            item.icon
                                ? typeof item.icon === 'string'
                                ? <MaterialCommunityIcon name={item.icon} size={item.size ? item.size : 14}/>
                                : item.icon
                                : null
                        }
                        {t(item.name)}
                        {
                            caretDown
                                ? <MaterialCommunityIcon name="caret-down"
                                                         style={{fontSize: 8, position: 'relative', top: -2}}/>
                                : null
                        }
                    </span>
                }>
                    {MenuFactory.getMenuItems(item.items, t, caretDown, resources)}
                </SubMenu>
            }

            if (item.custom) {
                return <Menu.Item key={item.id}>
                    {
                        showName && item.name
                            ? <div className={'flex-row flex-align-items-center'}>
                                {item.custom()}
                                <span data-cy={item.id} tour-id={item.tourId} className={'mh-10 mv-5'}>{t(item.name)}</span>
                            </div>
                            : item.custom()
                    }
                </Menu.Item>;
            }

            const content = <div className={`rightMenuIcon ${item.icon ? 'flex-row flex-align-items-center' : ''}`}>
                {
                    item.icon
                        ? typeof item.icon === 'string'
                        ? <MaterialCommunityIcon name={item.icon} size={item.size ? item.size : 14}/>
                        : item.icon
                        : null
                }
                {
                    showName && item.name
                        ? <span data-cy={item.id} tour-id={item.tourId} className={'mh-10 mv-5'}>{t(item.name)}</span>
                        : null
                }
            </div>;

            return <Menu.Item key={item.id}>
                {
                    item.route
                        ? <Link to={item.route}>
                            {content}
                        </Link>
                        : item.link
                        ? <a href={item.link} target={"_BLANK"}>
                            {content}
                        </a>
                        : content
                }
            </Menu.Item>
        })
    }
}
