import React from "react";
import {useParams} from "react-router-dom"
import ComponentProductSpareParts from "../../../components/ComponentProductSpareParts";

interface Props {

}

const ProductSparePartsRoute = ({}: Props) => {

    const {id} = useParams();


    return  <ComponentProductSpareParts productId={id} />
}

export default ProductSparePartsRoute;
