import React from "react";
import AccessControlPicker from "../AccessControlPicker/serviceAction";
import ServiceActionTypeRoleListQuery from "../../graphql/queries/serviceActionTypeRoleList.graphql";
import RoleListQuery from "../../graphql/queries/roleList.graphql";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import UpdateServiceActionTypeAcl from "../../graphql/mutations/UpdateServiceActionTypeAcl.graphql";
import RemoveComponentAclMutation from "../../graphql/mutations/RemoveServiceActionTypeAcl.graphql";
import AddRoleToComponentMutation from "../../graphql/mutations/AddRoleToServiceActionType.graphql";
import {withTranslation} from "react-i18next";


interface Props {
    serviceActionTypeId: string,
    serviceActionTypeIndex: string
}

class ServiceActionTypeRoleAccess extends React.Component<Props, State> {

    render() {
        const {serviceActionTypeId,serviceActionTypeIndex,t, addRole, update, remove} = this.props;

        return <AccessControlPicker
            showSearch
            multiple
            addMutation={addRole}
            updateMutation={update}
            removeMutation={remove}
            options={[
                {
                    label: t('Create'),
                    value: 'create'
                },{
                    label: t('Write'),
                    value: 'write'
                },{
                    label: t('Delete'),
                    value: 'remove'
                }
            ]}
            translateMutationOptions={(method, id, input) => {

                const variables = {
                    input
                };

                if (method === 'create') {
                    variables.index = serviceActionTypeIndex;
                    variables.input = {
                        create: true,
                        write: true,
                        remove: true
                    }
                    variables.roleId = id;
                }

                if (method === 'update') {
                    variables.id = id;
                }

                if (method === 'delete') {
                    variables.id = id;
                }

                return {
                    variables,
                    refetchQueries: [
                        {
                            query: ServiceActionTypeRoleListQuery,
                            variables: {
                                index: serviceActionTypeIndex,
                            }
                        }
                    ]
                }
            }}
            selectDataLoader={{
                query: RoleListQuery,
                dataKey: 'roleList',
                getQueryVariables: () => {
                    return {
                        params: {
                            start: 0,
                            filter: {
                                type: {
                                    value: ['role']
                                }
                            }
                        }
                    };
                },
                local: true,
                itemsKey: 'roles',
                totalKey: 'total',
                textKey: 'name',
                valueKey: 'id',
            }}
            valuesDataLoader={{
                query: ServiceActionTypeRoleListQuery,
                getQueryVariables: () => ({
                    index: serviceActionTypeIndex
                }),
                dataKey: 'serviceActionTypeAclList',
                itemsKey: 'serviceActionTypeAclItems',
                totalKey: 'total',
                textKey: 'role.name',
                valueKey: 'id',
                readKey: 'read',
                writeKey: 'write',
            }}/>;
    }
}

export default compose(
    graphql(UpdateServiceActionTypeAcl, {
        name: 'update'
    }),
    graphql(RemoveComponentAclMutation, {
        name: 'remove'
    }),
    graphql(AddRoleToComponentMutation, {
        name: 'addRole',
    }),
    withTranslation(),
)(ServiceActionTypeRoleAccess);
