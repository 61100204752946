// @flow
import React, {Component} from 'react';
import _ from "lodash";
import Error from "../../components/Error";
import ServiceProviderDashboard from "../../components/ServiceProviderDashboard";
import withResources from "../../hoc/withResources";
import ServiceProviderDetailRoute from "./serviceProvider/detail";
import {Redirect, Route, Switch} from "react-router-dom";
import ServiceProviderModules from "../../components/ServiceProviderModules";
import ServiceProviderRoleList from "../../components/ServiceProviderRoleList";
import ServiceProviderUsersDashboard from "../../components/ServiceProviderUsersDashboard";
import ServiceProviderUpdateForm from "../../components/ServiceProviderUpdateForm";

type Props = {
    match: {
        id: string,
    },
    resources?: any
};

class ServiceProviderDashboardRoute extends Component<Props, State> {

    render() {

        const {match, history, resources} = this.props;
        const id = _.get(match, 'params.id');

        if (!id) {
            return <Error type="404"/>
        }

        return <Switch>
            <Route path="/admin/serviceproviders/:id/" render={() =>
                <ServiceProviderDetailRoute serviceProviderId={id} onDelete={() => {
                    history.push(`/admin/serviceproviders`)
                }}/>}
            />
        </Switch>
    }
}

export default withResources(["administrateServiceProviders"])(ServiceProviderDashboardRoute);
