export default class FileIconFactory {

    static getIcon(type) {

        let icon = {
            name: 'file',
        };

        switch (type) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/jpg':
            case 'image/tiff':
            case 'image/gif':

                icon.name = 'file-image';
                break;

            case 'video/mpeg':
            case 'video/quicktime':
                icon.name = 'file-video';
                break;


            case 'application/pdf':
                icon.name = 'file-pdf';
                break;

            case 'application/msexcel':
                icon.name = 'file-excel';
                break;

            case 'application/msword':
                icon.name = 'file-word';
                break;

            case 'application/octet-stream':
                icon.name = 'code-tags';
                break;

            case 'application/zip':
            case 'application/x-tar':
            case 'application/x-ustar':
                icon.name = 'zip-box';
                break;

            default:

                break;
        }

        return icon;
    }

}
