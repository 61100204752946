//@flow

import React from "react"
import {Col, Row} from 'antd'
import {Icon} from "@dreebit/react-components";
import _ from "lodash"
import FontAwesomeIcon from '../Icons/FontAwesomeIcon';
import FileIconFactory from '../../utils/FileIconFactory'
import type {LogItem} from "../../types";
import config from './config.json'
import './index.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ConfirmActiveAlertButton from "../ConfirmActiveAlertButton";

type Props = {
    log: LogItem,
    componentId: string,
    refetchQueries?: any[]
};

const LogDetailAttachment = ({log, componentId, refetchQueries}: Props) => {
    const {t} = useTranslation();

    const {actionKey, data, status, id} = log;
    const {newValue, oldValue, serviceAction, serviceTicket, serviceTicketFile} = data;

    const attachmentType = _.get(config, actionKey, 'none');

    switch (attachmentType) {
        case 'componentAlert':
            if (status === 'active') return <ConfirmActiveAlertButton
                componentId={componentId}
                logIds={[id]}
                refetchQueries={refetchQueries}
            />

            return null;

        case 'valueChange':

            if (_.isString(newValue && oldValue) === true) {
                return <div className="attachment-box">
                    <Row className="new-value">
                        <Col className="left-side" span={4}>
                            {t('New')}
                        </Col>
                        <Col className="right-side" span={20}>
                            {newValue ? newValue : '-'}
                        </Col>
                    </Row>
                    <Row className="old-value">
                        <Col className="left-side" span={4}>
                            {t('Old')}
                        </Col>
                        <Col className="right-side" span={20}>
                            {oldValue ? oldValue : '-'}
                        </Col>
                    </Row>
                </div>;
            } else {
                return null;
            }

        case 'file':

            const {contentType, basename} = serviceTicketFile;
            const fileIcon = contentType ? FileIconFactory.getIcon(contentType).name : 'file';


            return <div className="attachment-box">
                <div className="file-preview-flex">
                    <div className="file-icon">
                        <FontAwesomeIcon name={fileIcon} circled={false}/>
                    </div>
                    <div className="file-link">
                        <span>{basename}</span>
                    </div>
                </div>
            </div>;


        case 'decontamination':


            return <div className="attachment-box">
                <div className="file-preview-flex">
                    <div className="file-link">
                        <Link to={`/service/${data.serviceTicket.id}/contaminationDeclaration`}> <Icon
                            type="link"/> {t('Open contamination declaration')}</Link>
                    </div>
                </div>
            </div>;


        case 'serviceActionLink':


            return <div className="attachment-box">
                <div className="link-box">
                    <div className="ticket-props">
                        <span><b>{t('Service action')}</b></span>
                        <a href={`/#/service/${serviceTicket.id}/actions/${serviceAction.id}`}><Icon
                            type="link"/> {t('Open service action')}</a>
                    </div>
                </div>
            </div>;


        case 'serviceTicketLink':

            return <div className="attachment-box">
                <div className="link-box">
                    <span>{t('Service Ticket')}</span>
                    <div className="ticket-props">
                        <span><b>{serviceTicket.key} - {serviceTicket.name}</b></span>
                        <a href={`/#/service/${serviceTicket.id}`}><Icon type="link"/> {t('Open service ticket')}
                        </a>
                    </div>
                </div>
            </div>;

        case 'componentNoteLink':

            // TODO@Jonny sobald Notes im graphql
            return null;

        case 'none':
            return null;
        default: {
        }

    }
}

export default LogDetailAttachment;
