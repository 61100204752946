// @flow
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';

import ManufacturerDetailViewBody from "../../components/ManufacturerDetailViewBody"
import ManufacturerQuery from "../../graphql/queries/manufacturer.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {message, Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ContentHeader from "../../components/Typo/ContentHeader";
import DeleteManufacturer from "../../graphql/mutations/removeManufacturer.graphql";
import ManufacturerListQuery from "../../graphql/queries/manufacturerList.graphql";
import {Col, Row} from "../../components/Flex";

type Props = {
    match: {
        params: {
            filter: any,
            id: string,
        }
    },
    manufacturerQuery: any,
    t: Function,
};

class ManufacturerDashboard extends Component<Props, State> {

    handleDelete = () => {
        const {deleteManufacturerMutation, match, history} = this.props;
        deleteManufacturerMutation({variables: {id: match.params.id}}).then((res) => {
            message.success(res.data.deleteManufacturer.message);
            history.push('/catalog/manufacturer');
        })
    };

    render() {
        const {match, manufacturerQuery, t} = this.props;

        const id = _.get(match, 'params.id');
        const filter = _.get(match, 'params.filter');

        return _.isEmpty(id)
            ? null
            : <div className={"flex-col flex-center full-width"} style={{backgroundColor: '#F9F9F9'}}>
                <ContentHeader title={_.get(manufacturerQuery, 'manufacturer.name', "")}
                               tools={<Row>
                                   <Col>
                                       <Link to={`/catalog/manufacturer/${id}/match`}>
                                           <Button type="warning" icon="branches">
                                               {t('Match')}
                                           </Button>
                                       </Link>
                                   </Col>
                                   <Col style={{paddingLeft: '15px'}}>
                                       <Popconfirm placement="bottomRight" title={t("Are you sure delete this task?")}
                                                   onConfirm={this.handleDelete}
                                                   okText={t("Yes")} cancelText={t("No")}
                                                   icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                                           <Button type="ant-btn-dashed" icon="delete">{t('Delete')}</Button>
                                       </Popconfirm>
                                   </Col>
                               </Row>}
                />

                <ManufacturerDetailViewBody
                    id={id}
                    attributes={_.get(manufacturerQuery, 'manufacturer.typeDefinitionAttributeList.typeDefinitionAttributes', {})}
                    filter={filter}
                    name={_.get(manufacturerQuery, 'manufacturer.name', "")}
                    status={_.get(manufacturerQuery, 'manufacturer.status', "")}
                />
            </div>
    }
}

export default compose(
    withRouter,
    graphql(ManufacturerQuery, {
        name: 'manufacturerQuery',
        options: (props: Props) => ({
            variables: {
                id: _.get(props.match, 'params.id')
            }
        }),
    }),
    waitWhileLoading('manufacturerQuery'),
    graphql(DeleteManufacturer, {
        name: 'deleteManufacturerMutation',
        options: props => ({
            refetchQueries: [{
                query: ManufacturerListQuery,
                variables: {
                    params: {
                        filter: {
                            status: {
                                operator: "in",
                                value: _.get(_.has(props, "match.params.filter") ? JSON.parse(_.get(props, "match.params.filter")) : {}, 'status')
                            }
                        },
                        limit: 20,
                        search: {
                            query: _.get(_.has(props, "match.params.filter") ? JSON.parse(_.get(props, "match.params.filter")) : {}, 'query')
                        }
                    }
                }
            }]
        })
    }),
    withTranslation(),
)(ManufacturerDashboard)
