// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import customAttributeDetails from "../../graphql/queries/customAttributeDetails.graphql";

const CustomAttributeBreadcrumb = ({customAttributeDetailsQuery, t}) =>
    <span>{_.get(customAttributeDetailsQuery, 'customAttribute.name', t('Unknown'))}</span>;

export default compose(
    graphql(customAttributeDetails, {
        name: 'customAttributeDetailsQuery',
        options: ({match}) => ({
            variables: {
                index: _.get(match, 'params.index')
            }
        }),
    }),
)(withTranslation()(CustomAttributeBreadcrumb));
