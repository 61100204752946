// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import UpdateComponentMutation from "./../../graphql/mutations/updateComponent.graphql";
import Error from "../Error";
import MultiActionWrapper from "../MultiActionWrapper";
import componentAttributes from "../../graphql/queries/componentAttributes.graphql";
import TDAAttributesForm from "../TDAAttributesForm";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    componentIds: string,
    onFinished?: () => void
};



class ComponentsMultiForm extends Component<Props> {

    _handleChange = (selectedId) => {
        this.setState({
            selectedId
        })
    };


    render() {
        const {componentIds, updateComponentMutation,attributes, t, onFinished} = this.props;

        if (!componentIds || !componentIds.length){
            return <Error title={t('No components to move')} />
        }

        return <MultiActionWrapper
            onFinished={onFinished}
            total={componentIds.length}
            mutation={updateComponentMutation}>
            {({queue}) => {
                return <TDAAttributesForm
                    disableToasts
                    attributes={attributes}
                    translateMutationOptions={({changes}) => {
                        return changes
                    }}
                    mutation={(changes) => {
                        return new Promise((resolve, reject) => resolve(changes));
                    }}
                    onSubmit={(values) => {
                        componentIds.forEach((componentId) => {
                            queue.push({
                                variables: {id: componentId, input: values}
                            })
                        })
                    }}
                />
            }}
        </MultiActionWrapper>
    }
}

export default compose(
    graphql(componentAttributes, {
        name: 'componentAttributesQuery',
        options: (props) => ({
            variables: {
                id: _.first(props.componentIds)
            }
        })
    }),
    waitWhileLoading('componentAttributesQuery','component.typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(UpdateComponentMutation, {
        name: 'updateComponentMutation'
    }),
    withTranslation(),
    withProps((props) => ({
        attributes: _.get(props.componentAttributesQuery,'component.typeDefinitionAttributeList.typeDefinitionAttributes',[])
            .map((item) => ({
                ...item,
                rules: null,
                value: null
            }))
    }))
)(ComponentsMultiForm);
