// @flow
import React, {Component} from 'react';
import FillQueryTable from "../QueryTable/fill";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ClientRoleListQuery from "../../graphql/queries/clientRoleList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";

type Props = {
    roleListQuery: query,
    t: Function,
    onClick?: Function,
}

class ClientRoleList extends Component<Props> {
    render() {
        const {roleListQuery, t, onClick} = this.props;

        return <FillQueryTable
            style={{borderRadius: 0}}
            columns={[
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: t('Name')
                },
                {
                    key: 'description',
                    dataIndex: 'description',
                    title: t('Description')
                }
            ]}

            // className={"link-table"}
            onRow={(record) => {
                return {
                    onClick: () => {
                        onClick ? onClick(record) : () => {};
                    },
                };
            }}
            size={'middle'}
            rowKey={"id"}
            query={roleListQuery}
            itemsKeyPath={'clientRoleList.roles'}
            totalKeyPath={'clientRoleList.total'}
            sortBy={'name'}/>
    }
}

export default compose(
    graphql(ClientRoleListQuery, {
        name: 'roleListQuery',
        options: (props) => ({
            variables: {
                clientId: props.clientId
            }
        }),
    }),
    waitWhileLoading('roleListQuery', ['clientRoleList.roles']),
    withTranslation(),
)(ClientRoleList);

