import React, {Component} from 'react';
import _ from 'lodash'
import LogDetailView from "../LogDetailView"
import ServiceTicketLogTimelineQuery from "./../../graphql/queries/serviceTicketLogTimeline.graphql"
import QueryLogList from "../QueryLogList";

type Props = {
    serviceTicketId: string,
    serviceTicketLogTimelineQuery: any,
    values: any,
};

class ServiceTicketLogTimeline extends Component<Props> {

    render() {

        return <QueryLogList
            query={ServiceTicketLogTimelineQuery}
            variables={{
                id: this.props.serviceTicketId,
                params: {
                    filter: {
                        level: this.props.values.logLevel ? {
                            operator: 'in',
                            value: _.get(this.props, 'values.logLevel', [])
                        } : null,
                        logType: this.props.values.logActionKey ? {
                            operator: 'in',
                            value: _.get(this.props, 'values.logActionKey', [])
                        } : null
                    },
                    sort: {
                        insert: 'DESC'
                    },
                    start: 0,
                    limit: 15
                }
            }}
            itemsKeyPath={'serviceTicket.logList.logs'}
            totalKeyPath={'serviceTicket.logList.total'}
            renderItems={(items) => <LogDetailView logs={items}/>}
        />
    }
}


export default ServiceTicketLogTimeline
