// @flow
import React  from 'react';
import TokenLandingPageContent from '../../components/TokenLandingPageContent'
import VSMFooter from "../../components/VSMFooter";
import { Layout } from 'antd';

const InfrastructureCreateTokenLandingPage = () => {

  //<MobileHeader />
  //<TokenLandingPageContent />
  return <Layout className={'full-height'} style={{overflow: 'auto'}}>
    <TokenLandingPageContent/>
    <VSMFooter/>
  </Layout>
};

export default InfrastructureCreateTokenLandingPage;
