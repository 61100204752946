// @flow
import React, {Component} from "react"
import ContentHeader from "../../components/Typo/ContentHeader";
import CustomAttributeList from "./../../components/CustomAttributeList";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";

type Props = {
    t: Function,
}

class CustomAttributeListRoute extends Component<Props> {


    render() {

        const {t} = this.props;

        return <NavigationContainer navigationBar={<ContentHeader title={t('CustomAttributes')} tools={<Link to={"/admin/attributes/new"}>
            <Button>{t('Add')}</Button>
        </Link>}/>}>
            <CustomAttributeList/>
        </NavigationContainer>
    }

}

export default withResources(["administrateCustomAttributes"])(withTranslation()(CustomAttributeListRoute));
