import React from 'react';
import {useQuery} from "@apollo/client";
import ClientAccessRequestListQuery from "../../graphql/queries/clientAccessRequestList.graphql";
import {useTranslation} from "react-i18next";
import FillQueryTable from "../QueryTable/fill";
import {Tag} from "antd";
import moment from "moment";

interface Props {
    onClick: (id) => void
}

const ClientAccessRequestList = ({onClick}: Props) => {

    const clientAccessRequestListQuery = useQuery(ClientAccessRequestListQuery);
    const {t} = useTranslation();

    return <FillQueryTable
        className={"link-table"}
        columns={[
            {
                key: 'client',
                dataIndex: 'client.name',
                title: t('Client')
            },
            {
                key: 'requestedBy',
                dataIndex: 'requestedBy',
                title: t('Requested by'),
                render: (data) => `${_.get(data, 'name')} (${_.get(data, 'client.name')})`
            },
            {
                key: 'insert',
                dataIndex: 'insert',
                title: t('Request date'),
                render: (text) => moment(text).format('L LT')
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: t('Status'),
                render: (status) => {
                    const tagColor = status === "accepted" ? "success"
                        : status === "rejected" ? "error"
                        : "processing";

                    return <Tag color={tagColor}>{t(status)}</Tag>
                },
                width: "15%"
            },
            {
                key: 'respondedBy',
                dataIndex: 'respondedBy',
                title: t('Responded by'),
                render: (data) => {
                    if (data && data.name && data.client.name) {
                        return `${_.get(data, 'name')} (${_.get(data, 'client.name')})`
                    } else {
                        return "-"
                    }
                }
            },
        ]}
        onRow={(record) => {
            return {
                onClick: () => {
                    onClick(record.id);
                },
            };
        }}
        size={'middle'}
        rowKey={"id"}
        query={clientAccessRequestListQuery}
        itemsKeyPath={'clientAccessRequestList.clientAccessRequests'}
        totalKeyPath={'clientAccessRequestList.total'}
    />
}

export default ClientAccessRequestList;
