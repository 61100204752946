// @flow
import React, {Component} from "react"
import classnames from 'classnames';
import {Input, Spin} from 'antd';
import {Icon} from "@dreebit/react-components";
import "./EditableCell.less"

type Props = {
    value: string,
    onChange: (changes: string) => Promise<any>,
    onRemove?: Function,
    className?: any,
    oneRow?: boolean
}

type State = {
    value: string,
    editable: boolean,
    loading: boolean
}

export default class EditableCell extends Component<Props, State> {

    _ismounted: boolean = false;

    state = {
        value: this.props.value,
        editable: false,
        loading: false
    };

    escFunction = (event: { keyCode: number }) => {
        if (event.keyCode === 27 && this.state.editable) {
            this.setState({
                editable: false
            })
        }
    };

    componentDidMount() {
        this._ismounted = true;
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        this._ismounted = false;
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleChange = (e) => {
        const value = e.target.value;
        this.setState({value});
    };
    check = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({editable: false});
        if (this.props.onChange) {
            this.setState({
                loading: true
            }, () => {
                const promise = this.props.onChange(this.state.value);
                if (promise) {
                    promise.then(() => {
                        this.setState({
                            loading: false
                        });
                    }).catch(() => {
                        this.setState({
                            loading: false
                        });
                    })
                }
            });

        }
    };
    edit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({editable: true});
    };

    onRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onRemove) {
            this.setState({
                loading: true
            }, () => {
                const promise = this.props.onRemove();
                if (promise) {
                    promise.then(() => {

                    }).finally(() => {
                        if (this._ismounted) {
                            this.setState({
                                loading: false
                            });
                        }
                    })
                }
            });

        }
    };

    render() {
        const {value, editable, loading} = this.state;
        return (
            <div className={classnames('editable-cell', this.props.className)} style={this.props.style}>

                {
                    editable ?
                        <div className="editable-cell-input-wrapper">
                            <Input
                                value={value}
                                onChange={this.handleChange}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}
                                onPressEnter={this.check}
                            />
                            <Icon
                                type="check"
                                className="editable-cell-icon-check"
                                onClick={this.check}
                            />
                        </div>
                        :
                        <div className="flex-row full-width" style={{minWidth: 0}}>
                            <span style={{flexGrow: 1}}
                                  className={`${this.props.oneRow ? 'ellipse-label' : null}`}>{value || ' '}</span>
                            <div className="flex-self-center" style={{flexShrink: 0}}>
                                {loading
                                    ? <Spin size={'small'}/>
                                    : <div
                                        className="flex-row flex-self-center flex-content-center editable-cell-icon-wrapper"
                                        style={{
                                            display: 'inline-flex'
                                        }}
                                    >
                                        <Icon
                                            type="edit"
                                            onClick={this.edit}
                                        />
                                        {
                                            this.props.onRemove
                                                ? <Icon
                                                    type="delete"
                                                    onClick={this.onRemove}
                                                />
                                                : null
                                        }
                                    </div>
                                }
                            </div>

                        </div>
                }

            </div>
        );
    }
}
