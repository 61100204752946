//@flow

import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import '../ComponentLogHistoryHeader/index.css';
import ProductLogHistoryHeaderQuery from "./../../graphql/queries/productLogHistoryHeader.graphql"
import InsertUpdateInfo from "./../InsertUpdateInfo";
import waitWhileLoading from "../../hoc/waitWhileLoading";


type Props = {
  productId: string,
};


export default compose(
  withTranslation(),
  graphql(ProductLogHistoryHeaderQuery, {
    name: 'productLogHistoryHeaderQuery',
    options: (props: Props) => ({
      variables: {
        id: props.productId
      }
    })
  }),
  waitWhileLoading('productLogHistoryHeaderQuery', 'product'),
  withProps((props) => ({
    insert: _.get(props, 'productLogHistoryHeaderQuery.product.insert'),
    update: _.get(props, 'productLogHistoryHeaderQuery.product.update')
  }))
)(InsertUpdateInfo);
