// @flow
import React, {Component} from 'react';
import {Modal} from 'antd';
import {Button} from "@dreebit/react-components";
import ContentHeader from "../../../components/Typo/ContentHeader";
import {withTranslation} from 'react-i18next';
import ContentWrapper from "../../../components/ContentWrapper";
import DashboardPortlets from "../../../components/DashboardPortlets";
import AddDashboardPortlets from "../../../components/DashboardPortlets/add-dashboard-portlets";
import NavigationContainer from "../../../components/NavigationContainer";

type Props = {
    t: Function,
}

type State = {
    settingsOpen: boolean
}

class Dashboard extends Component<Props, State> {

    state = {
        settingsOpen: false
    };

    render() {

        const {t} = this.props;

        return <NavigationContainer
            scroll
            navigationBar={<ContentHeader
                title={t('Dashboard')}
                hideBreadcrumb
                tools={<div>
                    <Button
                        onClick={() => this.setState({settingsOpen: true})}
                        icon={"plus"}
                        tour-id="dashboard__addPortlet">
                        {t('Personalize Dashboard')}
                    </Button>
                </div>}/>}
        >
            <ContentWrapper>
                <DashboardPortlets/>
                <Modal
                    onCancel={() => this.setState({settingsOpen: false})}
                    footer={null}
                    visible={this.state.settingsOpen}
                >
                    <AddDashboardPortlets onCreate={() => this.setState({settingsOpen: false})}/>
                </Modal>
            </ContentWrapper>
        </NavigationContainer>

    }
}

export default withTranslation()(Dashboard);
