// @flow
import React, {Component} from 'react';
import {Divider, Steps} from "antd";
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {compose} from "recompose"
import MoveTicketToDepartment from "../MoveTicketToDepartment";
import ServiceTicketCreateServicePlan from "../ServiceTicketCreateServicePlan";

const {Step} = Steps;


type Props = {
  serviceTicketIds: string[],
  onDone?: () => void
};

type State = {
  current: number
}

class ServiceWorkflowDoneActionSteps extends Component<Props, State> {

  state = {
    current: 0
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onChange = current => {
    this.setState({ current });
  };

  render() {
    const { t, serviceTicketIds, onDone } = this.props;
    const { current } = this.state;

    const steps = [
      {
        title: t("Select department"),
        description: t("Move the ticket in a different department"),
        content: <div style={{position: 'relative'}}>
          <Divider>
            {t("Select a service department")}
          </Divider>
          <MoveTicketToDepartment
              onSubmit={() => this.next()}
              serviceTicketIds={serviceTicketIds}/>
          <div style={{position: "absolute", marginTop: -20, right: 0}}>
            <Button type={"dashed"} onClick={() => this.next()}>{t("Skip this step")}</Button>
          </div>
        </div>,
      },
      {
        title: t("Add service plan"),
        description: t("Plan a service recommendation"),
        content: <div style={{position: 'relative'}}>
          <Divider>
            {t("Do you want to plan a service recommendation")}
          </Divider>
          <ServiceTicketCreateServicePlan onDone={onDone}
                                          serviceTicketIds={serviceTicketIds}/>
          <div style={{position: "absolute", marginTop: -20, right: 0}}>
            <Button type={"dashed"} onClick={() => {
              if (onDone) {
                onDone()
              }
            }}>{t("Skip this step")}</Button>
          </div>
        </div>,
      },
    ];


    return <div>
      <Steps

          onChange={this.onChange} current={this.state.current}>
        {steps.map((item, index) => (
            <Step
                key={`${index}`}
                title={item.title}
                description={item.description}
            />
        ))}
      </Steps>
      <div className="mt-15">{steps[current].content}</div>
    </div>
        ;
  }
}

export default compose(
    withTranslation()
)(ServiceWorkflowDoneActionSteps);
