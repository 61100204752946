// @flow
import React, {useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom'

import {Button, Col, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";
import _ from 'lodash';
import serviceTicketDashboard from './../../graphql/queries/serviceTicketDashboard.graphql';
import ServiceActionsTimeline from "./../ServiceActionsTimeline";
import Error from "./../Error";
import ContaminationDeclarationFinalizeWidget from "./contaminationDeclarationFinalizeWidget";
import ComponentInfoBox from "./../ComponentInfoBox";
import ServiceTicketDetailsInlineForm from "./detailsInlineForm";
import FinalTestFinalizeWidget from "./finaltestFinalizeWidget";
import ServiceTicketContactInlineForm from "./contactInlineForm";
import ServiceTicketMessageWidget from "./newMessageWidget";
import ServiceTicketLogHistory from "../../components/ServiceTicketLogHistory";
import AssigneeWidget from "./assigneeWidget";
import Panel from "../Panel";
import {checkIfServiceTicketCanCreateContaminationDeclaration} from "../../utils/ContaminationDeclarationHelper";
import ServiceTicketDashboardFilesWidget from "./filesWidget";
import ReconDetailView from "../ReconDetailView";
import {checkIfServiceTicketCanCreateFinalTest} from "../../utils/FinaltestHelper";
import ComponentLink from "../ComponentLink";
import {checkIfServiceTicketCanCreateWarrantyReport} from "../../utils/WarrantyReportHelper";
import WarrantyReportFinalizeWidget from "./warrantyReportFinalizeWidget";
import {checkIfServiceTicketCanViewQualityIssues} from "../../utils/QualityIssueHelper";
import QualityIssueWidget from "./qualityIssueWidget";
import ServiceReportWidget from "./serviceReportWidget"
import ServiceTicketSubTicketList from "../ServiceTicketSubTicketList";
import {checkResource} from "../../hoc/withResources";
import ServiceTicketInfo from "../ServiceTicketInfo";
import {Loader} from "@dreebit/react-components";
import {useQuery} from "@apollo/client";
import useResources from "../../hooks/useResources";
import ServiceTicketDocuSignModal from "../ServiceTicketDocuSignModal";
import ContaminationDeclarationSignWidget from "./contaminationDeclarationSignWidget";

type Props = {
    serviceTicketId: string,
};


const ServiceTicketDashboard = (props: Props) => {

    const { serviceTicketId } = props;

    const {t} = useTranslation();
    const resources = useResources();
    const history = useHistory();
    const serviceTicketDashboardQuery = useQuery(serviceTicketDashboard,{
        variables: {
            id: serviceTicketId
        }
    });
    if (serviceTicketDashboardQuery.loading) return <Loader/>

    const serviceTicket = _.get(serviceTicketDashboardQuery, 'data.serviceTicket');

    if (!serviceTicket) {
        return <Error status={'404'}/>
    }
    const {component, assignee, externalServiceProvider, orderStatusLink} = serviceTicket;

    const readOnly = !_.get(serviceTicket, 'access.write')
    const canAddServiceActions = !readOnly && _.get(serviceTicket, 'serviceProvider.serviceProviderConfig.serviceActionCreate')
    const showServiceReports = checkResource(["serviceReports", 'administrateServiceReports'], resources);

    const serviceMessaging = _.get(serviceTicketDashboardQuery, 'data.serviceTicket.serviceProvider.serviceProviderConfig.serviceMessaging', false);
    const showConversations = checkResource(["conversation"], resources) && serviceMessaging;

    const params = new URLSearchParams(useLocation().search);
    const eventParam = params.get('event');
    const isPvRequest = _.get(serviceTicket, 'serviceTicketType.index') === "pvRequest";
    const isDecoFinalized = _.get(serviceTicket, 'contaminationDeclaration.finalized', true);
    const modalState = eventParam ? eventParam : !isDecoFinalized ? "notFinalized" : null;
    const [isDocuSignModalOpen, setDocuSignModalOpen] = useState(isPvRequest && modalState)

    const setDocuSignModalVisibility = () => {
        setDocuSignModalOpen(!isDocuSignModalOpen)
    }

    //Finaltest und Konta. Panels
    const cfRow1 = [];
    const cfRow2 = [];

    let subServiceTicketsPanel = null;
    let parentServiceTicketsPanel = null;

    if (_.get(serviceTicket, 'parentServiceTicket')) {

        parentServiceTicketsPanel = <Panel
            title={t('Parent Service Ticket')}
        >
            <ServiceTicketInfo serviceTicketId={_.get(serviceTicket,'parentServiceTicket.id')} />
        </Panel>
    }


    if (_.get(serviceTicket,'subServiceTicketList.total',0) > 0) {
        const openedLinkedTickets = _.chain(serviceTicket).get('subServiceTicketList.serviceTickets',[]).filter((item) => _.get(item,'serviceTicketState.index') !== 'done').value();

        subServiceTicketsPanel = <Panel
            title={t('Linked Tickets')}
            bannerType={openedLinkedTickets.length ? 'warning' : null}
            bannerText={openedLinkedTickets.length ? t('This ticket has links to tickets that are in progress') : null}
        >
            <ServiceTicketSubTicketList serviceTicketId={serviceTicketId} />
        </Panel>
    }

    let componentInfoBox = null;
    if (component) {

        if (!readOnly && checkIfServiceTicketCanCreateContaminationDeclaration(serviceTicket)) {
            if (!isDecoFinalized && isPvRequest) {
                cfRow2.push(<Panel containerStyle={{padding: 0}} title={t('Contamination Declaration')}>
                    <ContaminationDeclarationSignWidget serviceTicket={serviceTicket} />
                </Panel>);
            } else {
                cfRow2.push(<Panel containerStyle={{padding: 0}} title={t('Contamination Declaration')}>
                    <ContaminationDeclarationFinalizeWidget serviceTicketId={serviceTicket.id} isPvRequest={isPvRequest}/>
                </Panel>);
            }
        }
        if (!readOnly && checkIfServiceTicketCanCreateFinalTest(serviceTicket)) {
            cfRow2.push(<Panel containerStyle={{padding: 0}} key={'finaltest'} title={t('Finaltest')}>
                <FinalTestFinalizeWidget serviceTicketId={serviceTicket.id} disableEdit={_.get(serviceTicket, 'finalTest.finalized', true)}/>
            </Panel>);
        }

        if (!readOnly && checkIfServiceTicketCanCreateWarrantyReport(serviceTicket)) {
            cfRow2.push(<Panel containerStyle={{padding: 0}} key={'warrantyReport'} title={t('Warranty Report')}>
                <WarrantyReportFinalizeWidget serviceTicketId={serviceTicket.id} disableEdit={_.get(serviceTicket, 'warrantyReport.finalized', true)}/>
            </Panel>);
        }

        if (!readOnly && checkIfServiceTicketCanViewQualityIssues(serviceTicket)) {
            cfRow2.push(<Panel containerStyle={{padding: 0}} key={'warrantyReport'} title={t('Quality Issue')}>
                <QualityIssueWidget serviceTicketId={serviceTicket.id} />
            </Panel>);
        }

        if(!readOnly && showServiceReports) {
            cfRow2.push(<Panel containerStyle={{padding: 0}} key={'serviceReports'} title={t('Service Reports')}>
                <ServiceReportWidget serviceTicketId={serviceTicket.id} />
            </Panel>);
        }

        let componentContainer = null;

        if (component){
            componentContainer = <ComponentInfoBox wrapperStyle={{padding: 0}} componentId={component.id} componentData={component}/>
        }else {
            componentContainer = <ComponentLink
                serviceTicketId={serviceTicketId}
            />
        }


        componentInfoBox =  <div>
            <Panel
                bannerText={_.get(serviceTicket,'inQuarantineStore') ? t('Component in quarantine store') : null}
                bannerType={_.get(serviceTicket,'inQuarantineStore') ? 'warning' : null}
                title={t('Component')}>
                {componentContainer}
            </Panel>
        </div>

    }

    let externalServiceProviderPanel = null;
    if (externalServiceProvider){
        externalServiceProviderPanel = <Panel
            containerStyle={{marginTop: 34}}
            bannerText={`${t('External Service')}: ${_.get(externalServiceProvider,'name')}`}
            bannerType={'info'}>
            {t(`This service ticket is handled by an external service provider`)}
        </Panel>
    }

    let assigneeBox = null;
    if (assignee) {
        assigneeBox = <Panel containerStyle={{padding: '10px 0'}} title={t('Assignee')}>
            <AssigneeWidget serviceTicketId={props.serviceTicketId}/>
        </Panel>
    }

    let orderLinkBox = null;
    /*
    if(orderStatusLink){
        orderLinkBox = <Panel containerStyle={{padding: '10px'}} title={t('Order status link')}>
            <CopyCode code={orderStatusLink}/>
        </Panel>
    }
       */

    return <div className={'ServiceTicketDashboard'} onDoubleClick={() => {
        if (process.env.NODE_ENV === 'development') console.log('serviceTicket', serviceTicket)
    }}>

        <div className={'ServiceTicketDashboard-content'}>
            <ServiceActionsTimeline
                hideAdd={!canAddServiceActions}
                serviceTicketStateIndex={serviceTicket.serviceTicketState.index}
                serviceTicketTypeIndex={serviceTicket.serviceTicketType.index}
                serviceTicketId={serviceTicketId}
                onClick={(action) => {
                    history.push(`/service/${serviceTicketId}/actions/${action.id}`);
                }}
            />
            {serviceTicket.serviceTicketType.index === 'recon' ?
                <ReconDetailView serviceTicketId={serviceTicketId} />
                : null}
            <div style={{padding: 16}}>
                <Row gutter={16}>
                    <Col md={16}>

                        <ServiceTicketDetailsInlineForm serviceTicketId={serviceTicketId}/>
                        {cfRow2}
                        <Panel title={t('Files')}>
                            <ServiceTicketDashboardFilesWidget
                                serviceTicketId={serviceTicketId}
                                contaminationDeclaration={_.get(serviceTicket, 'contaminationDeclaration', {})}
                                isPvRequest={isPvRequest}
                            />
                        </Panel>
                    </Col>
                    <Col md={8}>
                        {parentServiceTicketsPanel}
                        {subServiceTicketsPanel}
                        {externalServiceProviderPanel}
                        {assigneeBox}
                        {componentInfoBox}
                        {orderLinkBox}

                        {showConversations ?
                            <Panel title={t('Conversations')}>
                                <ServiceTicketMessageWidget serviceTicketId={serviceTicketId}/>
                            </Panel> : null
                        }

                        <Panel title={t('Contact')}>
                            <ServiceTicketContactInlineForm serviceTicketId={serviceTicketId}/>
                        </Panel>


                        <Panel containerStyle={{padding: 0}} title={t('History')} topRight={<Link
                            to={`/service/${serviceTicketId}/logs`}>{t('Show all entries')}</Link>}>
                            <ServiceTicketLogHistory serviceTicketId={serviceTicketId}/>
                        </Panel>
                    </Col>
                </Row>
            </div>
        </div>
        {
            isPvRequest && modalState && _.get(serviceTicket, 'contaminationDeclaration', null) ?
                <Modal
                    open={isDocuSignModalOpen}
                    title={eventParam ? "DocuSign®" : t("Please sign the contamination declaration")}
                    onCancel={setDocuSignModalVisibility}
                    footer={null}
                    width={650}
                >
                    <ServiceTicketDocuSignModal
                        serviceTicket={serviceTicket}
                        state={modalState}
                        onCloseModal={setDocuSignModalVisibility}
                    />
                </Modal>
            : null
        }
    </div>
}

export default ServiceTicketDashboard;
/*
export default compose(
    withResources(),
    withRouter,
    withTranslation(),
    graphql(serviceTicketDashboard, {
        name: 'serviceTicketDashboardQuery',
        options: (props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketDashboardQuery', ['serviceTicket']),
)(ServiceTicketDashboard);
*/
