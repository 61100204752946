// @flow
import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import './index.css';

import type {Query} from "../../types";
import {Checkbox, Col, Pagination, Table, Tooltip} from "antd";
import QueryErrorIndicator from "../QueryErrorIndicator";
import {useTranslation} from "react-i18next";
import {Row} from "../Flex";
import statics from "../../statics";
import {CheckboxChangeEvent} from "antd/es/checkbox";

type Props = {
    defaultAutoRefetch?: boolean,
    autoRefetchInterval?: number,
    query: Query,
    queryVariables?: any,
    itemsKeyPath: string,
    totalKeyPath: string,
    limitKeyPath?: string,
    startKeyPath?: string,
    pagingSize?: number,
    columns?: any[],
    renderCustomFooter?: (variables?: any) => any,
};

const PaginationQueryTable = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [pagingSize, setPagingSize] = useState<number>(_.get(props, 'pagingSize', statics.defaultPagingSize));
    const [page, setPage] = useState<number>(1);
    const [autoRefetchInterval, setAutoRefetchInterval] = useState<number>(_.get(props, 'autoRefetchInterval', 1 * 60 * 1000));
    const [autoRefetch, setAutoRefetch] = useState<boolean>(_.get(props, 'defaultAutoRefetch', false));
    const {t} = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoRefetch) {
                setLoading(true);
                props.query
                    .refetch()
                    .then(() => {
                        setLoading(false);
                    })
                    .catch(() => {
                       setLoading(false);
                    });
            }
        }, autoRefetchInterval);
        return () => clearInterval(interval);
    }, [autoRefetch]);

    const TableFooter = () => {
        return <div style={{padding: "5px 0"}}>
            <Row>
                <Col span={12}>
                    {
                        (props.query.error)
                            ? <QueryErrorIndicator query={props.query}/>
                            : <Tooltip title={t("Auto refetch at {{time}}", {time: `${autoRefetchInterval / 1000 / 60} min`})}>
                                <Checkbox
                                    checked={autoRefetch}
                                    onChange={(e: CheckboxChangeEvent) => {
                                        setAutoRefetch(e.target.checked);
                                    }}>
                                    {t("Auto refetch")}
                                </Checkbox>
                            </Tooltip>
                    }
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <Pagination
                        current={page}
                        total={_.get(_.get(props.query, 'data', props.query), props.totalKeyPath, 0)}
                        pageSize={pagingSize}
                        showTotal={(total) => `Total ${total} items`}
                        onChange={(page) => {
                            setPage(page);
                            setLoading(true);

                            const variables = {
                                ...props.queryVariables,
                                ...props.query.variables,
                            };

                            _.set(variables, props.limitKeyPath, pagingSize);
                            _.set(variables, props.startKeyPath, (page - 1) * pagingSize);

                            props.query
                                .refetch(variables)
                                .then(() => {
                                    setLoading(false);
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                        }}
                    />
                </Col>
            </Row>
        </div>;
    }

    return <Table
        rowKey={'id'}
        loading={loading || props.query.loading}
        showHeader={true}
        footer={() =>
            <TableFooter/>
        }
        pagination={false}
        {...props}
        columns={props.columns}
        dataSource={_.get(_.get(props.query, 'data', props.query), props.itemsKeyPath, [])}
    />;
}

export default PaginationQueryTable;