// @flow
import React, {Component} from 'react';
import MultiActionWrapper from "../MultiActionWrapper";
import {compose} from "recompose";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import {withTranslation} from "react-i18next";
import DeleteServiceTicketMutation from "../../graphql/mutations/deleteServiceTicket.graphql";
import {Alert, Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";

type Props = {
  serviceTicketIds: string[],
  onFinished: () => void,
};

class ServiceTicketsDelete extends Component<Props> {
  componentDidMount() {
  }

  render() {
    const {onFinished, serviceTicketIds, t, deleteServiceTicketMutation} = this.props;

    return <MultiActionWrapper
        onFinished={onFinished}
        total={serviceTicketIds.length}
        mutation={deleteServiceTicketMutation}>
      {({queue}) => {
        return <div>
          <Alert message={t('All selected service tickets will be deleted')} type="error" showIcon/>

          <div className={'flex-row flex-center'}>
            <Button
                type={'primary'}
                className={"mt-10"}
                icon={'delete'}
                onClick={() => {
                  serviceTicketIds.forEach((serviceTicketId) => {
                        queue.push({
                          variables: {serviceTicketId: serviceTicketId}
                        })
                      }
                  )
                }}
            >
              {t('Delete' + ` ${serviceTicketIds.length} ` + t("service tickets"))}
            </Button>
          </div>
        </div>
      }}
    </MultiActionWrapper>
  }
}

export default compose(
    graphql(DeleteServiceTicketMutation, {
      name: 'deleteServiceTicketMutation'
    }),
    withApollo,
    withTranslation(),
)(ServiceTicketsDelete)
