// @flow
import React, {Component} from 'react';
import {Tag} from "antd";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc'
import {withTranslation} from "react-i18next";
import {compose} from "recompose"
import ServiceTicketComponentInQuarantineStoreQuery
    from "./../../graphql/queries/ServiceTicketComponentInQuarantineStore.graphql";
import Colors from "../../themes/default/colors";

type Props = {
    serviceTicketId: string,
};

type State = {}

class ServiceTicketComponentInQuarantineStoreBadge extends Component<Props, State> {

  render() {
    const { t, serviceTicketComponentInQuarantineStoreQuery, style } = this.props;
    if (_.get(serviceTicketComponentInQuarantineStoreQuery,'serviceTicket.inQuarantineStore')){
        return <Tag style={style} className={''}
                    color={Colors.warning}>{t("Component in quarantine store")}</Tag>
    }

    return null;

  }
}

export default compose(
    withTranslation(),
    graphql(ServiceTicketComponentInQuarantineStoreQuery,{
        name: 'serviceTicketComponentInQuarantineStoreQuery',
        options: (props: any) => {
            return {
                serviceTicketId: props.serviceTicketId
            }
        }
    }),
)(ServiceTicketComponentInQuarantineStoreBadge);
