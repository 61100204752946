// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Modal} from "antd";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import ManufacturerQuery from "../../graphql/queries/manufacturer.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ManufacturerFilterList from "../ManufacturerFilterList";
import ManufacturerMatchPerformer from "../ManufacturerMatchPerformer";
import ContentHeader from "../Typo/ContentHeader";

type Props = {
    match: {
        params: {
            id: string,
        }
    },
    t: Function,
};

type State = {
    visible?: boolean,
    targetId?: string,
    filter: any
}

class ManufacturerMatchRoute extends Component<Props, State> {
  componentDidMount() {  }

    state = {
        visible: false,
        targetId: undefined,
        filter: null
    };

    onClick = () => {

    };

  render() {
      const {match, t, manufacturerQuery} = this.props;
      const {visible, targetId, filter} = this.state;

      const ManufacturerName = _.get(manufacturerQuery, 'manufacturer.name');

      return <div>
          <ContentHeader title={`${t('Match')} ${ManufacturerName}`}
                         hideBreadcrumb
          />

          <ManufacturerFilterList
              filterValues={{
                  query: _.get(this.props, 'manufacturerQuery.manufacturer.name'),
                  fields: ["name"],
                  status: "published"
              }}
              tableProps={{
                  scroll: {y: 300}
              }}
              onManufacturerSelect={(manufacturerId) => {

                  this.setState({
                      targetId: manufacturerId,
                      visible: true,
                  })
              }}
              removeKeyById={match.params.id}
          />

          <Modal
              title={t("Match Manufacturer")}
              visible={!!(visible && targetId)}
              footer={null}
              onCancel={() => {
                  this.setState({
                      visible: false
                  })
              }}
          >
              <ManufacturerMatchPerformer
                  onMatched={() => {

                      const target = this.state.targetId;

                      this.setState({
                          visible: false,
                          targetId: null
                      }, () => {
                          this.props.history.push(`/catalog/manufacturer/${target}`)
                      })


                  }}
                  sourceId={this.props.match.params.id}
                  targetId={targetId}
              />
          </Modal>
    </div>;
  }
}

export default compose(
    withTranslation(),
    graphql(ManufacturerQuery, {
        name: 'manufacturerQuery',
        options: (props: Props) => ({
            variables: {
                id: _.get(props.match, 'params.id')
            }
        }),
    }),
    waitWhileLoading('manufacturerQuery'),
)(ManufacturerMatchRoute);
