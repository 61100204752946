// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import Panel from "../Panel";

import {Button} from "@dreebit/react-components";
import FillQueryTable from "../QueryTable/fill";
import {Link} from "react-router-dom";
import ComponentImage from "../ComponentImage";
import OneLineLabel from "../OneLineLabel";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import Toolbar from "../Toolbar";
import {toastError} from "../../utils/toast";
import DeleteComponentSubscription from "../../graphql/mutations/deleteComponentSubscription.graphql";
import NotificationSetting from "../../graphql/queries/notificationSetting.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";

type Props = {
    t: Function,
    deleteComponentSubscriptionMutation: Function,
    notificationSettingQuery: any,
    id: any
}

type State = {
    selectedRowIds: any,
    loading: boolean
}

class SubscribedComponents extends Component<Props, State> {

    state = {
        selectedRowIds: [],
        loading: false
    };

    _handleUnsubscribe = () => {
        const {deleteComponentSubscriptionMutation} = this.props;

        this.setLoading(true)
            .then(() => {
                const promises = this.state.selectedRowIds.map(id => deleteComponentSubscriptionMutation({
                    variables: {
                        id
                    }
                }));
                return Promise.all(promises);
            })
            .catch(toastError)
            .finally(() => {
                this.setState({
                    selectedRowIds: [],
                });
                this.setLoading(false);
            })
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {t, notificationSettingQuery} = this.props;
        const {selectedRowIds} = this.state;

        const setting = _.get(notificationSettingQuery, 'notificationSetting', {});

        const rowSelection = {
            selectedRowKeys: selectedRowIds,
            hideDefaultSelections: true,
            onSelect: (record, selected, selectedRows) => {
                this.setState({
                    selectedRowIds: selectedRows.map(item => item.id || item)
                });
            },
        };

        return <Panel title={t(setting.name)} style={{margin: 13}}>
            {selectedRowIds.length ? <Toolbar
                renderLeft={() => <Button onClick={this._handleUnsubscribe}>{t('Unsubscribe')}</Button>}
            /> : null}
            <FillQueryTable
                loading={this.state.loading}
                rowSelection={rowSelection}
                style={{borderRadius: 0}}
                columns={[
                    {
                        key: 'image.thumbnailLink',
                        dataIndex: 'image.thumbnailLink',
                        width: 50,
                        render: (text, record) => {
                            return <Link to={`/infrastructure/${record.id}`}><ComponentImage size={30} link={text}/></Link>
                        }
                    }, {
                        key: 'name',
                        dataIndex: 'name',
                        title: t('Name'),
                        sorter: true,
                        render: (text, record) => {
                            return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: 500 }}>
                                <Link to={`/infrastructure/${record.id}`}>
                                    {text}
                                </Link>
                                <div style={{maxWidth: 500}}>
                                    <OneLineLabel >
                                        <ComponentPathBreadcrumb hideLastItem path={record.path}/>
                                    </OneLineLabel>
                                </div>
                            </div>
                        }
                    }, {
                        key: 'product.manufacturer.name',
                        dataIndex: 'product.manufacturer.name',
                        width: 150,
                        title: t('Manufacturer'),
                        renderItem: (text, record) => {
                            let link = <Link to={`/infrastructure/${record.id}`}>
                                <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>{text}</div>
                            </Link>;
                            if (!text && record.manufacturerName) {
                                link = <Link to={`/infrastructure/${record.id}`}>
                                    <div style={{
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-word'
                                    }}>{record.manufacturerName}</div>
                                </Link>;
                            }
                            return <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>
                                {link}
                            </div>
                        }
                    }, {
                        key: 'serialNo',
                        dataIndex: 'serialNo',
                        width: 150,
                        title: t('serialNo')
                    }, {
                        key: 'product.catalogNo',
                        dataIndex: 'product.catalogNo',
                        width: 150,
                        title: t('catalogNo')
                    }
                ]}
                size={'middle'}
                rowKey={"id"}
                query={notificationSettingQuery}
                itemsKeyPath={'notificationSetting.componentList.components'}
                totalKeyPath={'notificationSetting.componentList.total'}
            />
        </Panel>
    }
}

export default compose(
    graphql(NotificationSetting, {
        name: 'notificationSettingQuery',
        options: (props: Props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only'
        }),
    }),
    waitWhileLoading('notificationSettingQuery', 'notificationSetting.componentList.components'),
    graphql(DeleteComponentSubscription, {
        name: 'deleteComponentSubscriptionMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: NotificationSetting,
                variables: {
                    id: props.id
                },
                fetchPolicy: 'network-only'
            }]
        }),
    }),
    withTranslation(),
)(SubscribedComponents);
