import React from 'react';
import TDAAttributesForm from "../TDAAttributesForm";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import Bold from "../Utilities/Bold";
import moment from "moment";
import {Descriptions, Tag} from "antd";

interface Props {
    clientAccessRequest: any
}

const ClientAccessRequestDetailsContent = ({clientAccessRequest}: Props) => {

    const {t} = useTranslation();

    const requestedBy = _.get(clientAccessRequest, 'requestedBy');
    const client = _.get(clientAccessRequest, 'client.name');
    const status = _.get(clientAccessRequest, 'status');
    const date = _.get(clientAccessRequest, 'insert');
    const respondComment = _.get(clientAccessRequest, 'respondComment', '-');

    const tagColor = status === "accepted" ? "success"
        : status === "rejected" ? "error"
            : "processing";

    return <div>
        <Descriptions bordered column={1}>
            <Descriptions.Item label={t("Requested by")}>
                {_.get(requestedBy, 'name')} ({_.get(requestedBy, 'client.name')})
            </Descriptions.Item>
            <Descriptions.Item label={t("Access to")}>
                {client}
            </Descriptions.Item>
            <Descriptions.Item label={t("Request date")}>
                {moment(date).format('L LT')}
            </Descriptions.Item>
            <Descriptions.Item label={t("Status")}>
                <Tag color={tagColor}>{t(status)}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label={t("Comment")}>
                {respondComment}
            </Descriptions.Item>
        </Descriptions>
    </div>
}

export default ClientAccessRequestDetailsContent;
