// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {message} from "antd";
import {Icon} from "@dreebit/react-components";
import FileUploadDropZone from "./../FileUploadDropZone";
import AddFilesToServiceActionMutation from "./../../graphql/mutations/addServiceActionFiles.graphql";
import ServiceActionFilesQuery from "./../../graphql/queries/serviceActionFiles.graphql";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import * as moment from "moment";
import _ from 'lodash';

import type {serviceActionsTimeline_serviceActionList_serviceActions} from "../../graphql/operation-result-types.flow";

import "./timelineItem.less";
import {FaAsterisk, FaBuilding, FaHashtag, FaTruck, FaUser} from "react-icons/lib/fa/index";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import FileLink from "../FileLink";
import Error from "../Error";
import OneLineLabel from "../OneLineLabel";
import type {FileUploadResponse} from "../../types";
import ReactMarkdown from "react-markdown";
import isHTML from "../../utils/isHTML";
import serviceActionsTimeline from "../../graphql/queries/serviceActionsTimeline.graphql";

type Props = {
    serviceTicketId?: string,
    serviceAction: serviceActionsTimeline_serviceActionList_serviceActions,
    hideArrowAndDot?: boolean,
    t: Function,
    onClick?: (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, event: any)=>void,
};

type State = {}

const actionTypeInfoComponentsMap = {
    'changeServiceTicketType': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, props: Props) => {
        return serviceAction.serviceTicketType ? <span><ServiceTicketTypeIcon
            index={serviceAction.serviceTicketType.index}/> {props.t('Service ticket type changed to')}: {serviceAction.serviceTicketType.name}</span> : null;
    },
    'componentScrapped': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions) => {
        //Use: spf- as id prefix
        const file = _.find(serviceAction.files, (file) => {
            return _.startsWith(file.id, "spf-")
        });
        return file ? <FileLink file={file}/> : null;
    },
    'deliveryShipped': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, props: Props) => {
        if (serviceAction.link && serviceAction.link.length) {
            return <a rel="noopener noreferrer" target="_BLANK" href={serviceAction.link}><FaTruck
                style={{}}/> {serviceAction.logistician ? serviceAction.logistician.name : 'Tracking Info'} - {serviceAction.trackingId}
            </a>
        }
        return serviceAction.logistician ?
            <span><FaTruck style={{}}/> {props.t('Shipped with')} {serviceAction.logistician.name}</span> : null;

    },
    'serviceTicketAssignedToServiceDepartment': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, props: Props) => {
        return <span><FaBuilding
            style={{}}/> {props.t('New department')}: {_.get(serviceAction, 'department.name')}</span>;
    },
    'setAssignee': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions) => {
        return serviceAction.assignee ? <span><FaUser style={{}}/> {serviceAction.assignee.name}</span> : null;
    },
    'setCommissionNumber': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, props: Props) => {
        return serviceAction.commissionNumber ? <span><FaHashtag
            style={{}}/> {props.t('Commission Number')}: {serviceAction.commissionNumber}</span> : null;
    },
    'warrantyStatusCheck': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, props: Props) => {
        return serviceAction.warrantyStatus ?
            <span><FaAsterisk style={{}}/> {props.t('Warrenty status')}: {serviceAction.warrantyStatus}</span> : null;
    },
    'subServiceTicket': (serviceAction: serviceActionsTimeline_serviceActionList_serviceActions, props: Props) => {
        return serviceAction.subServiceTicket ?
            <span>{_.get(serviceAction, 'subServiceTicket.key')}: {_.get(serviceAction, 'subServiceTicket.name')}</span> : null;
    },
};


class ServiceActionTimelineItem extends Component<Props, State> {

    lastMouseDown = 0;

    componentDidMount() {
    }

    renderServiceActionInfo(serviceAction: serviceActionsTimeline_serviceActionList_serviceActions) {
        const renderFunction = actionTypeInfoComponentsMap[serviceAction.serviceActionType.index];
        let rendered = null;
        if (renderFunction) {
            rendered = renderFunction(serviceAction, this.props);
        }

        if (!rendered && serviceAction.description && serviceAction.description.length > 1 && !(serviceAction.description.includes('|') || serviceAction.description.includes('<table>') )) {
            if (isHTML(serviceAction.description)){
                rendered = <div dangerouslySetInnerHTML={{__html: serviceAction.description}} />
            }else {
                rendered = <ReactMarkdown source={serviceAction.description}/>
            }

        }

        return rendered ? rendered : <span>{this.props.t('Done by')} {serviceAction.doneBy.name}</span>
    }

    render() {
        const {serviceAction, t, onClick, hideArrowAndDot, addFilesToServiceActionMutation} = this.props;

        if (!serviceAction) {
            return <Error title={'No Service Action'}/>
        }

        let borderType = "";
        if (serviceAction.private === true) {
            borderType = "private"
        }


        return <div className={'ServiceActionTimelineItem'}>

            <div className={'dateContainer'}>
                <span>{moment(serviceAction.date).format('LLL')}</span>
            </div>
            <div className={'timeline'}>
                {hideArrowAndDot ? null : <span className={`timelineBubble ${borderType}`}></span>}
            </div>
            <div
                onMouseDown={onClick ? () => {
                    this.lastMouseDown = new Date().getTime();
                } : null}
                onMouseUp={onClick ? (event) => {
                    const diff = new Date().getTime() - this.lastMouseDown;
                    if (diff > 0 && diff < 200) {
                        const validNodes = ['DIV', 'SPAN'];
                        if (validNodes.indexOf(event.target.tagName) !== -1) {
                            onClick(serviceAction, event)
                        }
                    }
                } : null} className={'contentWrapper'}>
                <div className={hideArrowAndDot ? `noArrowBox ${borderType}` : `arrowBox ${borderType}`}>

                    <FileUploadDropZone
                        loaderSize={'small'}
                        dropzoneProps={{
                            style: {
                                height: '100%',
                                width: '100%'
                            }
                        }}
                        multiple
                        onUploaded={(responses: [FileUploadResponse]) => {
                            Promise.all(responses.map((response) => {
                                addFilesToServiceActionMutation({
                                    refetchQueries: [{
                                        query: ServiceActionFilesQuery,
                                        variables: {
                                            id: this.props.serviceAction.id,
                                        }
                                    }],
                                    variables: {
                                        serviceActionId: this.props.serviceAction.id,
                                        files: [{
                                            fileId: response.fileId,
                                        }]
                                    }
                                })
                            })).then(() => {
                                message.success(t('Upload finished'))
                            })

                        }}
                    >
                        <div className={'actionTitle'}>
                            <div
                                className={"code-name-label"}
                            ><span
                                className={'serviceActionTypeCode'}>{serviceAction.serviceActionType.code}</span>: <OneLineLabel
                                className={'serviceActionTypeName'}
                                style={{flexGrow: 1}}>{serviceAction.serviceActionType.name}</OneLineLabel>
                                <span>{serviceAction.serviceActionFileList.total > 0 ?
                                    <Icon type="paper-clip"/> : null}</span></div>
                            <div className={'serviceActionInfo'}>
                                {this.renderServiceActionInfo(serviceAction)}
                            </div>
                            <div className={'credentials'}>
                                <span>{t('Last updated at')} {moment(serviceAction.update).format('LLL')}</span>
                            </div>
                        </div>
                    </FileUploadDropZone>

                </div>
            </div>

        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(AddFilesToServiceActionMutation, {
        name: 'addFilesToServiceActionMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: serviceActionsTimeline,
                    variables: {
                        id: props.serviceTicketId
                    }
                }
            ]
        })
    })
)(ServiceActionTimelineItem);

