// @flow
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {message, Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ContentHeader from "../../components/Typo/ContentHeader";
import ProfileNotificationSettingDashboard from "../ProfileNotificationSettingDashboard";
import DeleteNotificationSettingMutation from "../../graphql/mutations/deleteNotificationSetting.graphql";
import {withRouter} from "react-router-dom";
import NotificationSettingList from "../../graphql/queries/notificationSettingList.graphql";
import NotificationSetting from "../../graphql/queries/notificationSetting.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from 'lodash';
import NavigationContainer from "../NavigationContainer";

type Props = {
    match: {
        params: {
            id: string,
        }
    },
    history: {
        push: Function,
    },
    t: Function,
    deleteNotificationSetting: Function,
    notificationSettingQuery: query,
}

class ProfileNotificationSettingDetail extends Component<Props, State> {

    handleDelete = () => {
        const {deleteNotificationSetting, history, match} = this.props;
        deleteNotificationSetting({variables: {id: match.params.id}}).then((res) => {
            message.success(res.data.deleteNotificationSetting.message);
            history.push('/profile/notificationsettings');
        })
    };

    render() {
        const {t, match, notificationSettingQuery} = this.props;
        const setting = _.get(notificationSettingQuery, 'notificationSetting', '');

        return <NavigationContainer
        navigationBar={ <ContentHeader title={setting.name} tools={
            <Popconfirm placement="bottomRight" title={t("Are you sure?")}
                        onConfirm={this.handleDelete} okText={t("Yes")} cancelText={t("No")}
                        icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                <Button
                    type={'danger'}
                    disabled={!setting.access.remove}
                >
                    {t('Delete')}
                </Button>
            </Popconfirm>
        }/>}
        scroll
        >

            <ProfileNotificationSettingDashboard id={match.params.id}/>
        </NavigationContainer>
    }
}

export default compose(
    withRouter,
    graphql(NotificationSetting, {
        name: 'notificationSettingQuery',
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        }),
    }),
    waitWhileLoading('notificationSettingQuery', 'notificationSetting.name'),
    graphql(DeleteNotificationSettingMutation, {
        name: 'deleteNotificationSetting',
        options: () => ({
            refetchQueries: [{
                query: NotificationSettingList
            }]
        })
    }),
    withTranslation(),
)(ProfileNotificationSettingDetail);
