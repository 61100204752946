// @flow
import React, {Component} from 'react';
import AttributesInlineForm from "../Form/attributes-inline-form";
import {withTranslation} from "react-i18next";
import './index.css';

type Props = {
    onChange: Function,
    values: any,
    t: Function
};


class ManufacturerToolbar extends Component<Props> {

    render() {
        const {onChange, values, t} = this.props;


        const attributes = [{
            index: "query",
            type: "string",
            inputType: "text",
            debounceTime: 500,
            placeholder: t('Search'),
        }, {
            index: "status",
            type: "string",
            inputType: "select",
            entityType: "enum",
            enumType: "ProductStatus",
            placeholder: t('Status'),
        }];

        return <div className={'ManufacturerFilterWrapper'}>
            <AttributesInlineForm
                attributes={attributes}
                layout={'inline'}
                useSubmit={false}
                onChange={(attributeValues, allValues) => {
                    if (onChange)
                        onChange(attributeValues, allValues)
                }}
                values={values}
            />
        </div>
    }
}

export default withTranslation()(ManufacturerToolbar)
