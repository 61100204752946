import _ from 'lodash'

export const compareObjectKeys = (objectA, objectB, keys) => {
    let equal = true;

    keys.forEach((key) => {
        if (equal) {
            equal = _.eq(_.get(objectA, key), _.get(objectB, key))
        }
    });

    return equal;
};

export const isEquivalent = (a, b) => {
    // Create arrays of property names
    const aProps = a ? Object.getOwnPropertyNames(a) : [];
    const bProps = b ? Object.getOwnPropertyNames(b) : [];

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
};

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export function shallowEqual(objA: mixed, objB: mixed): boolean {
    if (objA === objB) {
        return true;
    }

    if (typeof objA !== 'object' || objA === null ||
        typeof objB !== 'object' || objB === null) {
        return false;
    }

    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    // Test for A's keys different from B.
    var bHasOwnProperty = hasOwnProperty.bind(objB);
    for (var i = 0; i < keysA.length; i++) {
        if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
            return false;
        }
    }

    return true;
}

export function shallowCompare(instance, nextProps, nextState) {
    return (
        !shallowEqual(instance.props, nextProps) ||
        !shallowEqual(instance.state, nextState)
    );
}

export function jsonCompare(instance, nextProps, nextState) {
    return (
        JSON.stringify(instance.props) !== JSON.stringify(nextProps) ||
        JSON.stringify(instance.state) !== JSON.stringify(nextState)
    );
}
