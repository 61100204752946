// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import WorkflowToolbar from "./toolbar";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import type {MutationFunction} from "../../types";
import ServiceTicketWorkflowBoardResults from "./results";
import NavigationContainer from "../NavigationContainer";

type Props = {
    filter: any,
    openServiceTicketList: any,
    inProgressServiceTicketList: any,
    doneServiceTicketList: any,
    updateServiceTicketRankMutation: MutationFunction,
    onChange?: Function,
};

class ServiceTicketWorkflowBoard extends Component<Props, State> {

    render() {
        const {filter, onChange} = this.props;

        return <NavigationContainer
            navigationBar={
                <WorkflowToolbar params={filter} onChange={onChange}/>
            }
            scroll
            >
            <ServiceTicketWorkflowBoardResults
              filter={filter}
              pollInterval={60000}
            />
        </NavigationContainer>
    }
}

export default compose(
    withTranslation(),
    withRouter,
)(ServiceTicketWorkflowBoard);
