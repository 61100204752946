// @flow
import React, {Component} from 'react';
import {Waypoint} from 'react-waypoint';
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import styled from "styled-components";
import {compose} from "recompose";
import type {Query} from "../../types";
import Empty from "../Empty";
import Loader from "../Loader";
import QueryErrorIndicator from "../QueryErrorIndicator";
import {withApollo} from "@apollo/client/react/hoc";
import statics from "../../statics";

type Props = {
    query: Query,
    variables?: any,
    style: any,
    bordered: boolean,
    itemsKeyPath: string,
    totalKeyPath: string,
    renderItems: (items: any[]) => any,
    pagingSize: ?number,
    limitKeyPath: ?string,
    startKeyPath: ?string,
    searchKeyPath: ?string,
    listProps?: any,
    uniqueIdentifier?: any,
    onQueryMounted?: (queryLogList: any) => void
};

type State = {
    loadingMore: boolean,
}

const ListWrapper = styled.div`
    background: #f8f8f8;
`;


class QueryLogList extends Component<Props, State> {

    static defaultProps = {
        pagingSize: statics.defaultPagingSize,
        limitKeyPath: 'params.limit',
        startKeyPath: 'params.start',
        searchKeyPath: 'params.search.query'
    };

    state = {
        total: -1,
        loading: false,
        items: [],
    };

    _handleWaypointEnter = () => {

        const total = this.state.total;
        const length = this.state.items.length;


        if (length < total && total > 0) {

            this.loadPage(length)

        }
    };

    _handleWaypointLeave = () => {

    };

    componentDidMount() {


        this.loadPage(0);
        if (this.props.onQueryMounted){
            this.props.onQueryMounted(this);
        }
    }



    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.variables !== prevProps.variables) {
            this.loadPage(0);
        }
    }

    loadPage(start){

        let {client, query, itemsKeyPath, totalKeyPath} = this.props;

        const variables = _.clone(this.props.variables) || {};
        _.set(variables, this.props.limitKeyPath, this.props.pagingSize);
        _.set(variables, this.props.startKeyPath, start);

        this.setState({
            loading: true
        }, () => {
            client.query({
                query: query,
                variables,
                fetchPolicy: 'network-only'
            })
                .then((res) => {

                    const newItems = _.get(res,`data.${itemsKeyPath}`,[]);

                    this.setState({
                        total: _.get(res,`data.${totalKeyPath}`,-1),
                        items: !start ? newItems: [
                            ...this.state.items,
                            ...newItems
                        ],
                        loading: false
                    });
                })
                .catch(() => {
                    this.setState({
                        loading: false
                    });
                })
        })
    }

    render() {
        const {query,  renderItems, style} = this.props;

        const {items, loading} = this.state;

        return <div style={style}><ListWrapper>
            <div style={{float: "right", zIndex: 1, position: "relative", padding: "0 16px"}}>
                <QueryErrorIndicator placement={"left"} query={query}/>
            </div>
            {!items.length && !loading ? <Empty/> :

                !items.length && loading ? <Loader size={'small'}/> : <div>
                    {renderItems(items)}
                    <Waypoint
                        bottomOffset={-100}
                        onEnter={this._handleWaypointEnter}
                        onLeave={this._handleWaypointLeave}
                    />
                    {items.length && loading ? <Loader size={"small"}/> : null}
                </div>
            }
        </ListWrapper></div>;
    }
}


export default compose(
    withTranslation(),
    withApollo,
)(QueryLogList);
