import React, {Component} from 'react';
import ServiceTicketLogHistoryHeader from "../ServiceTicketLogHistoryHeader/index"
import ServiceTicketLogHistoryList from "../ServiceTicketLogHistoryList/index"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import Enums from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import _ from "lodash";


type Props = {
    serviceTicketId: string,
    enumsQuery: query,
};


class ServiceTicketLogHistory extends Component<Props> {

    render() {

        const {serviceTicketId, enumsQuery} = this.props;

        const values = {
            logLevel: _.get(enumsQuery, '__type.enumValues').map((el) => {
                return el.name;
            })
        };

        return <div>
            <ServiceTicketLogHistoryHeader serviceTicketId={serviceTicketId}/>
            <ServiceTicketLogHistoryList serviceTicketId={serviceTicketId} values={values}/>
        </div>
    }
}

export default compose(
    graphql(Enums, {
        name: 'enumsQuery',
        options: () => ({
            fetchPolicy: 'cache-first',
            variables: {
                name: 'LogLevel'
            }
        }),
    }),
    waitWhileLoading('enumsQuery', '__type.enumValues'),
    withTranslation()
)(ServiceTicketLogHistory);

