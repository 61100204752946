// @flow
import React, {Component} from 'react';

import ManufacturerList from "../ManufacturerList"
import ManufacturerToolbar from "../ManufacturerToolbar"
import {Col} from "../../components/Flex";

type Props = {
    filter: any,
    onChange: (changes: any) => void
};

type State = {
    changes: any
}

class ManufacturerListComponent extends Component<Props, State> {

    state = {
        changes: this.props.filter
    }

    render() {
        const {filter, onChange} = this.props;
        const {changes} = this.state;

        return (
            <Col style={{overflow: 'hidden', height: '100%'}}>
                <div style={{borderBottom: '1px solid #cfdbe2', marginLeft: '5px'}}>
                    <ManufacturerToolbar
                        onChange={(changes) => {
                            this.setState({
                                changes: {
                                    ...this.state.changes,
                                    ...changes
                                }
                            })
                        }}
                        values={changes}
                    />
                </div>
                <ManufacturerList
                    filterValues={changes}
                />
            </Col>
        );
    }
}

export default ManufacturerListComponent