// @flow
import React, {Component} from "react"
import {List} from "antd";
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import Empty from "../Empty";
import Loader from "../Loader";
import Error from "../Error";
import * as _ from "lodash";
import "./style.css";

const defaultTextRenderer = (item) => {

    const renderString = (string) => {
        return <List.Item>{string}</List.Item>;
    };

    if (_.isString(item)) {
        return renderString(item);
    }

    if (_.isObject(item)) {
        return renderString(item.title || item.name || item.toString());
    }

    return null;
};

type Props = {
    renderItem: Function,
    onDataChanged?: Function,
    useWindow: boolean,
    query: { loading: boolean },
    dataKey: string,
    totalKey?: string,
    itemsKey?: string,
    itemHeight?: number,
    customScrollingElement: any,
}

class InfiniteList extends Component<Props> {

    static defaultProps = {
        limit: 50,
        renderItem: defaultTextRenderer,
        totalKey: 'total',
        itemsKey: 'items',
        itemHeight: 55
    };
    _windowScroller;
    loadedRowsMap = {};

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }


    handleInfiniteOnLoad = ({startIndex}) => {


        const {query, itemsKey, dataKey, limit} = this.props;
        if (query.loading || (this.loadedRowsMap[startIndex] && this.loadedRowsMap[startIndex + limit])) {
            return;
        }

        for (let i = startIndex; i <= startIndex + limit; i++) {
            // 1 means loading
            this.loadedRowsMap[i] = 1;
        }

        const variables = query.variables || {};
        variables.params = {
            ...variables.params,
            start: startIndex,
            limit
        };

        this.setState({
            loading: true
        });
        query.fetchMore({
            variables,
            updateQuery: (previousResult, {fetchMoreResult}) => {

                if (Object.keys(previousResult).length === 0) {
                    previousResult = this.props.query[this.props.dataKey];
                }
                this.setState({
                    loading: false
                });
                const tmp = {};
                if (previousResult[dataKey] && previousResult[dataKey][itemsKey] && fetchMoreResult && fetchMoreResult[dataKey] && fetchMoreResult[dataKey][itemsKey]) {
                    tmp[dataKey] = fetchMoreResult[dataKey];
                    tmp[dataKey][itemsKey] = [...previousResult[dataKey][itemsKey], ...fetchMoreResult[dataKey][itemsKey]];
                    const result = Object.assign({}, previousResult, tmp);
                    if (this.props.onDataChanged) {
                        this.props.onDataChanged(result);
                    }
                    return result;
                }
                return false;
            },
        })

    };

    isRowLoaded = ({index}) => {

        let dataRes = false;
        let data = this.props.query[this.props.dataKey];
        if (data) {
            let items = data[this.props.itemsKey];
            dataRes = !!items[index];
        }

        const res = dataRes || !!this.loadedRowsMap[index];
        return res;

        //return true;
        // let data = this.props.query[this.props.dataKey];
        // if (data){
        //   let items = data[this.props.itemsKey];
        //   return !!items[index];
        // }
        // return false;
    };

    renderItem = ({index, key, style}) => {


        // const data = getComponentList.componentList.components;
        const result = this.props.query[this.props.dataKey];
        const placeholder = <div style={style} key={key}><Loader size={'small'} mask/></div>;
        if (!result) {
            return placeholder
        }

        const data = result[this.props.itemsKey];
        if (!data || data.length < index) {
            return placeholder
        }

        const item = data[index];
        if (!item) {
            return placeholder;
        }
        return <div key={key} style={style}>
            {this.props.renderItem(item, index)}
        </div>;
    };

    _setRef = windowScroller => {
        this._windowScroller = windowScroller;
    };

    render() {

        const {query, itemsKey, totalKey, dataKey, useWindow, customScrollingElement, itemHeight} = this.props;
        const result = query[dataKey];

        if (query.loading) {
            return <Loader/>
        }

        if (!result || result[totalKey] === 0) {
            return <Empty/>
        }

        const data = result[itemsKey];

        if (!data || data.length === 0) {
            return <Error/>
        }

        const loadingCellCount = this.state.loading || query.loading ? 1 : 0;

        if (useWindow || customScrollingElement) {
            return <WindowScroller
                ref={this._setRef}
                scrollElement={customScrollingElement ? customScrollingElement : window}>
                {({height, isScrolling, registerChild, onChildScroll, scrollTop}) => (
                    <InfiniteLoader
                        isRowLoaded={(arg) => this.isRowLoaded(arg)}
                        loadMoreRows={(arg) => this.handleInfiniteOnLoad(arg)}
                        rowCount={result[totalKey]}
                    >
                        {({onRowsRendered, registerChild}) => (
                            <AutoSizer disableHeight>
                                {({width}) => (
                                    <VList
                                        className={'infinity-list'}
                                        height={height}
                                        onRowsRendered={onRowsRendered}
                                        ref={registerChild}
                                        rowCount={data.length + loadingCellCount}
                                        rowHeight={itemHeight}
                                        isScrolling={isScrolling}
                                        scrollTop={scrollTop}
                                        rowRenderer={(arg) => this.renderItem(arg)}
                                        width={width}
                                    />
                                )}
                            </AutoSizer>

                        )}
                    </InfiniteLoader>
                )}
            </WindowScroller>;
        } else {
            return <InfiniteLoader
                isRowLoaded={(arg) => this.isRowLoaded(arg)}
                loadMoreRows={(arg) => this.handleInfiniteOnLoad(arg)}
                rowCount={result[totalKey]}
            >
                {({onRowsRendered, registerChild}) => (
                    <AutoSizer>
                        {({height, width}) => (
                            <VList
                                className={'infinity-list'}
                                height={height}
                                onRowsRendered={onRowsRendered}
                                ref={registerChild}
                                rowCount={data.length + loadingCellCount}
                                rowHeight={itemHeight}
                                rowRenderer={(arg) => this.renderItem(arg)}
                                width={width}
                            />
                        )}
                    </AutoSizer>

                )}
            </InfiniteLoader>;
        }


    }


}

export default InfiniteList;
