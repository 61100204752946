// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import ServiceControlCreateForm from "./../../components/ServiceControlCreateForm";
import Panel from "../../components/Panel";

type Props = {
    match: any,
    history: {
        push: Function
    },
    t: Function,
};


class ComponentServiceControlCreate extends Component<Props, State> {


    render() {
        const {t, match, history} = this.props;

        return <div className={'ph-10'}>
            <Panel title={t('New service control')}>
                <ServiceControlCreateForm
                    componentId={_.get(match, 'params.id')}
                    onSaved={(res) => {
                        const id = _.get(res, 'data.createServiceControl.id');
                        if (id) history.push(`/infrastructure/${_.get(match, 'params.id')}/servicecontrols/${id}`)
                    }}/>
            </Panel>

        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
)(ComponentServiceControlCreate);
