import React, {useState} from "react";
import statisticList from "../../graphql/queries/statisticList.graphql";
import {getFilterVariables} from "../../routes/admin/StatisticsRoute";
import {Button, Switch} from "antd";
import FillQueryTable from "../QueryTable/fill";
import {Col, Row} from "../Flex";
import ListTypeButtons from "./ListTypeButtons";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
import {DownloadOutlined} from "@ant-design/icons";

type Props = {
    filter?: any,
}

export type StatisticListType = 'month' | 'year' | 'day';

const StatisticsHistoryTable = ({filter}: Props) => {
    const {t} = useTranslation();

    const [type, setType]: StatisticListType = useState('month');
    const [cumulative, setCumulative]: boolean = useState(false);

    let defaultColumns = [
        {
            key: 'componentCount',
            dataIndex: 'componentCount',
            title: t('Components')
        },
        {
            key: 'serviceTicketCount',
            dataIndex: 'serviceTicketCount',
            title: t('Service Ticket')
        }
    ];

    if (!filter || _.isEmpty(_.get(filter, 'manufacturerId', []))) defaultColumns = [
        ...defaultColumns,
        {
            key: 'clientCount',
            dataIndex: 'clientCount',
            title: t('Clients')
        },
        {
            key: 'userCount',
            dataIndex: 'userCount',
            title: t('User')
        }
    ]

    let columns = [];

    switch (type) {
        case 'year': columns = [
            {
                key: 'year',
                dataIndex: 'year',
                title: t('Year')
            },
            ...defaultColumns
        ]; break;

        case 'month': columns = [
            {
                key: 'month',
                dataIndex: 'month',
                title: t('Month'),
                render: (item, record) => {
                    if (item) {
                        const date = moment(`${_.get(record, 'year')} ${t(item)}`, 'YYYY MMMM');
                        return date.format('MMMM YYYY');
                    }
                }
            },
            ...defaultColumns
        ]; break;

        case 'day': columns = [
            {
                key: 'day',
                dataIndex: 'day',
                title: t('Day'),
                render: (item, record) => {
                    const date = moment(`${_.get(record, 'year')} ${t(_.get(record, 'month'))} ${item}`, 'YYYY MMMM DD')
                    return date.format('DD. MMMM YYYY');
                }
            },
            ...defaultColumns
        ]
    }

    return <Col className={'flex-grow-1 pt-10'}>
        <Row className={'ph-10 pv-5'}>
            <ListTypeButtons
                type={type}
                onChange={setType}
            />

            <Switch
                checkedChildren={t('Cumulative')}
                unCheckedChildren={t('Cumulative')}
                onChange={setCumulative}
                checked={cumulative}
            />
        </Row>

        <FillQueryTable
            useReactComponents={true}
            queryData={{
                query: statisticList,
                itemsKeyPath: 'statisticList.statistics',
                totalKeyPath: 'statisticList.total',
                limitKeyPath: 'params.limit',
                startKeyPath: 'params.start',
                pagingSize: 25,
                queryVariables: {
                    params: {
                        filter: getFilterVariables(filter),
                        type,
                        cumulativeCount: cumulative
                    }
                },
                queryOptions: {
                    fetchPolicy: 'network-only'
                }
            }}
            columns={columns}
            footerHeight={95}
            renderExtraFooter={(query) => {
                const exportLink = _.get(query, 'data.statisticList.export.link');
                if(!exportLink) return;

                return <a
                    href={exportLink}
                    target={"_BLANK"}
                >
                    <Button
                        icon={<DownloadOutlined />}
                    >
                        {t('CSV Export')}
                    </Button>
                </a>
            }}
        />
    </Col>
};

export default StatisticsHistoryTable
