// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import BookmarkListQuery from "./../../graphql/queries/bookmarkList.graphql"
import updateBookmark from "./../../graphql/mutations/updateBookmark.graphql"
import deleteBookmark from "./../../graphql/mutations/deleteBookmark.graphql"
import {withRouter} from "react-router-dom";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import BookmarkQueryList from "./BookmarkQueryList";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

type Props = {
	type: string[],
	history: any,
	onSelect?: (id: string) => void,
	bookmarkListQuery?: any,
	onBookmarkClick?: (bookmark: any) => void
}

class BookmarkList extends Component<Props> {
	render() {
		const {
			onSelect,
			history,
			type,
			bookmarkListQuery,
			updateBookmarkMutation,
			deleteBookmarkMutation,
			t,
			onBookmarkClick
		} = this.props;

		const items = _.get(bookmarkListQuery, 'bookmarkList.bookmarks', []);

		return <div className={'flex-col overflow-auto flex-grow-1'}
		>
			{
				type && type.length
					? type.map((bookmarkType: string, index: number) => {
						const filteredItems = _.filter(items, {type: bookmarkType});
						if (!filteredItems || _.isEmpty(filteredItems)) return null;

						return <div className={'flex-col'} key={index}>
						<span style={{
							fontWeight: 'bold',
							padding: '10px 0px 5px 5px'
						}}>
							{t(bookmarkType)}
						</span>
							<DndProvider backend={HTML5Backend}>
								<BookmarkQueryList
									history={history}
									onSelect={onSelect}
									type={bookmarkType}
									data={filteredItems}
									updateBookmarkMutation={updateBookmarkMutation}
									deleteBookmarkMutation={deleteBookmarkMutation}
									onBookmarkClick={onBookmarkClick}
								/>
							</DndProvider>
						</div>
					})
					: null
			}
		</div>
	}

}

export const bookmarkListVariables = (type) => {
	return {
		params: {
			filter: {
				type: {
					value: type
				}
			},
			start: 0,
			limit: 50
		}
	}
};

export default compose(
	withTranslation(),
	graphql(updateBookmark, {
		name: 'updateBookmarkMutation',
		options: props => ({
			refetchQueries: [
				{
					query: BookmarkListQuery,
					variables: bookmarkListVariables(_.get(props, 'type', null) || _.get(props, 'bookmarkTypes', null))
				},
				{
					query: BookmarkListQuery,
					variables: bookmarkListVariables("infrastructureSearch"),
				}]
		})
	}),
	graphql(deleteBookmark, {
		name: 'deleteBookmarkMutation',
		options: props => ({
			refetchQueries: [
				{
					query: BookmarkListQuery,
					variables: bookmarkListVariables(_.get(props, 'type', null) || _.get(props, 'bookmarkTypes', null))
				},
				{
					query: BookmarkListQuery,
					variables: bookmarkListVariables("infrastructureSearch"),
				}]
		})
	}),
	graphql(BookmarkListQuery, {
		name: 'bookmarkListQuery',
		options: props => ({
			variables: bookmarkListVariables(_.get(props, 'type', null) || _.get(props, 'bookmarkTypes', null))
		}),
	}),
	waitWhileLoading('bookmarkListQuery', ['bookmarkList.bookmarks']),
	withRouter
)(BookmarkList);
