// @flow
import React, {Component} from "react"
import _ from 'lodash';
import {Tabs} from "antd";
import ComponentFilesTable from "./../../components/ComponentFilesTable"
import fileCategories from "./../../graphql/queries/componentFileCategoriesEnums.graphql"
import EmpolisFilesQuery from "./../../graphql/queries/productEmpolisFiles.graphql"
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import Error from "../../components/Error";
import componentFiles from "../../graphql/queries/component-Files.graphql";
import FilesTable from "../../components/FilesTable";

type Props = {
    match: {
        params: {
            id: string,
            index: string,
        }
    },
    getComponentFileCategories: {
        __type: any
    }
}

class ComponentFilesTabsRoute extends Component<Props> {

    onChange = (key) => {

        const {history, match} = this.props;
        const componentId = _.get(match, 'params.id');
        history.push(`/infrastructure/${componentId}/files/${key}`);

    };

    render() {

        const {t, getComponentFileCategories, getEmpolisFiles, getComponentFiles, componentId, index} = this.props;

        if (!getComponentFileCategories) {
            return <Error/>
        }

        let categories = [];
        if (getComponentFileCategories['__type'] && getComponentFileCategories['__type'].enumValues) {
            categories = getComponentFileCategories['__type'].enumValues.map(item => ({
                index: item.name,
                title: item.description
            }))
        }

        categories.push({
            index: 'qrcode',
            title: t('QR Code')
        })

        if (getEmpolisFiles) {
            categories.push({
                index: 'manufacturer',
                title: t('Manufacturer Files')
            })
        }

        const empolisFiles = _.get(getEmpolisFiles, 'product.empolisFileList.productFiles', [])

        return <div>
            <div className="white-bg">
                <Tabs tabBarStyle={{marginBottom: 0, padding: '0 20px'}} defaultActiveKey={'default'} activeKey={index}
                      onChange={this.onChange}>
                    {categories.map(category => {

                        if (category.index === 'manufacturer') {
                            return <Tabs.TabPane tab={`${category.title} (${empolisFiles.length})`}
                                                 key={category.index}/>;
                        }

                        const count = _.chain(getComponentFiles)
                            .get('component.componentFileList.componentFiles')
                            .filter({
                                category: category.index
                            })
                            .size()
                            .value();
                        return <Tabs.TabPane tab={`${category.title} ${count ? `(${count})` : ''}`}
                                             key={category.index}/>
                    })}
                </Tabs>
            </div>

            {index === 'manufacturer' ? <FilesTable
                    loading={_.get(getEmpolisFiles, "loading")}
                    showHeader={true} enableLanguage onTableChange={(_paging, filters) => {


                    getEmpolisFiles.refetch({
                        productId: _.get(this.props, 'getComponentFiles.component.product.id'),
                        language: _.first(filters.language)
                    })

                }} files={empolisFiles}/> :
                <ComponentFilesTable showHeader={false} componentId={componentId} category={index}/>}

        </div>;
    }

}


export default compose(
    withProps(({match}) => {
        return {
            componentId: match.params.id,
            index: match.params.index
        }
    }),
    graphql(fileCategories, {
        name: 'getComponentFileCategories',
    }),
    graphql(componentFiles, {
        name: 'getComponentFiles',
        options: props => ({
            variables: {
                id: props.componentId,
            }
        }),
    }),
    withTranslation(),
    graphql(EmpolisFilesQuery, {
        name: 'getEmpolisFiles',
        skip: (props) => {
            return !_.get(props, 'getComponentFiles.component.product.manufacturer.empolis', false) || !_.get(props, 'getComponentFiles.component.product.id', false)
        },
        options: props => ({
            variables: {
                productId: _.get(props, 'getComponentFiles.component.product.id')
            }
        }),
    })
)(ComponentFilesTabsRoute);
