import React from "react";
import {isDateInNearFuture, isDateInPast} from "./dateRangeCheck";
import Colors from "../../themes/default/colors";
import _ from "lodash";
import moment from "moment/moment";
import CalendarDate from "../CalendarDate";
import {SplitPanelModel} from "../SplitPanel";

function getNextControl(serviceControls) {
    return _.chain(serviceControls).sortBy('upcomingControl').first().value();
}

function getNextServicePlan(servicePlans) {
    return _.chain(servicePlans).sortBy('dueDate').first().value();
}

type BannerItem = SplitPanelModel;


export function getServiceControlDescription(nextControl, t) {

    if (isDateInPast(nextControl.upcomingControl)) {
        return `${t('Last Control')} ${moment(nextControl.upcomingControl).toNow()}: ${moment(nextControl.upcomingControl).format('L')}`;
    }

    return `${t('Next Control')} ${moment(nextControl.upcomingControl).fromNow()}: ${moment(nextControl.upcomingControl).format('L')}`
}

export function getNextBannerItem(servicePlans: any[], serviceControls: [], componentId, t): BannerItem {

    const nextControl = getNextControl(serviceControls);

    if (nextControl && isDateInPast(nextControl.upcomingControl)) {

        return {
            title: t('Inspection'),
            link: `/infrastructure/${componentId}/servicecontrols/${nextControl.id}`,
            description: getServiceControlDescription(nextControl, t),
            showLinkIndicator: true,
            leftComponent: <CalendarDate date={nextControl.upcomingControl} size={42} borderColor={'white'}/>,
            leftColor: Colors.danger,
        };
    }

    if (nextControl && isDateInNearFuture(_.get(nextControl, 'upcomingControl'), 7)) {
        return {
            title: t('Inspection'),
            link: `/infrastructure/${componentId}/servicecontrols/${nextControl.id}`,
            description: getServiceControlDescription(nextControl, t),
            showLinkIndicator: true,
            leftComponent: <CalendarDate date={nextControl.upcomingControl} size={42} borderColor={'white'}/>,
            leftColor: Colors.danger,
        };
    }

    const nextServicePlan = getNextServicePlan(servicePlans);
    if (nextServicePlan) {

        const past = isDateInPast(_.get(nextServicePlan, 'dueDate'));
        const result = {
            title: t('Service Plan'),
            link: `/infrastructure/${componentId}/serviceplans/${nextServicePlan.id}`,
            description: past ? `${t('Due for')} ${moment(nextServicePlan.dueDate).toNow()}: ${moment(nextServicePlan.dueDate).format('L')}` : `${t('Due in')} ${moment(nextServicePlan.dueDate).fromNow()}: ${moment(nextServicePlan.dueDate).format('L')}`,
            showLinkIndicator: true,
            leftComponent: <CalendarDate date={nextServicePlan.dueDate} size={42} borderColor={'white'}/>,
            leftColor: past ? Colors.danger : Colors.warning,
        };


        if (!past && isDateInNearFuture(_.get(nextServicePlan, 'dueDate'), 14)) {
            result.color = Colors.danger;
            return result;
        }

        if (isDateInNearFuture(_.get(nextServicePlan, 'dueDate'), 90)) {
            return result;
        }
    }

    return null;

}

