export default {
    "data": {
        "typeDefinitionAttributeList": {
            "total": 14,
            "typeDefinitionAttributes": [
                {
                    "index": "invoiceNo",
                    "name": "Invoice Number",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "shippingInfo"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "deliveryDate",
                    "name": "Delivery Date",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "date",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "shippingInfo"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "reasonForReturn",
                    "name": "Reason For Return",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "textArea",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "reason"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "componentUsed",
                    "name": "Component Used",
                    "type": "bool",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "radio",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "stateOfComponent"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "freeFromHarmfulSubstances",
                    "name": "Free from harmful substances",
                    "type": "bool",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "radio",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [
                        {
                            "value": "0",
                            "name": "No (go to section 4)"
                        },
                        {
                            "value": "1",
                            "name": "Yes (go to section 5)"
                        }
                    ],
                    "autocomplete": null,
                    "categories": [
                        "stateOfComponent"
                    ],
                    "value": false,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "oil",
                    "name": "Welches Betriebsmittel wurde verwendet?",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "stateOfComponent"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "harmfulSubstances",
                    "name": "Harmful Substances",
                    "type": "string",
                    "disabled": false,
                    "entityType": "enum",
                    "dataLoaderConfig": null,
                    "enumType": "HarmfulSubstances",
                    "multiple": true,
                    "inputType": "checkbox",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [
                        {
                            "operator": "$eq",
                            "value": false,
                            "key": "freeFromHarmfulSubstances"
                        }
                    ],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [
                        {
                            "value": "toxic",
                            "name": "toxic"
                        },
                        {
                            "value": "microbiologicalHazard",
                            "name": "microbiological Hazard"
                        },
                        {
                            "value": "radioActive",
                            "name": "radioactive"
                        },
                        {
                            "value": "corrosive",
                            "name": "corrosive"
                        },
                        {
                            "value": "explosive",
                            "name": "explosive"
                        },
                        {
                            "value": "otherHarmfulSubstances",
                            "name": "Other Harmful Substances"
                        }
                    ],
                    "autocomplete": null,
                    "categories": [
                        "processOfContamination"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "substances",
                    "name": "Substances",
                    "type": "object",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": true,
                    "inputType": "table",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [
                        {
                            "operator": "$eq",
                            "value": false,
                            "key": "freeFromHarmfulSubstances"
                        }
                    ],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "contaminants"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": [
                        {
                            "index": "name",
                            "name": "Tradename, Product Name, Manufacturer",
                            "type": "string",
                            "disabled": false,
                            "entityType": null,
                            "dataLoaderConfig": null,
                            "enumType": null,
                            "multiple": false,
                            "inputType": "text",
                            "uploadPath": null,
                            "hint": "",
                            "addOnAfter": null,
                            "debounceTime": null,
                            "format": null,
                            "placeholder": "",
                            "addOnAttribute": null,
                            "timeIntervalButtons": null,
                            "markdownButtons": null,
                            "allowNoOption": false,
                            "localSearch": false,
                            "rules": [],
                            "conditions": [],
                            "options": {
                                "searchable": false,
                                "versionable": false,
                                "exportable": false,
                                "componentCreate": false,
                                "tco": false
                            },
                            "optionValues": [],
                            "autocomplete": null,
                            "categories": [
                                "contaminants"
                            ],
                            "value": null,
                            "valueDetails": null,
                            "unit": null,
                            "file": null,
                            "subAttributes": []
                        },
                        {
                            "index": "precautions",
                            "name": "Precautions associated with substance",
                            "type": "string",
                            "disabled": false,
                            "entityType": null,
                            "dataLoaderConfig": null,
                            "enumType": null,
                            "multiple": false,
                            "inputType": "text",
                            "uploadPath": null,
                            "hint": "",
                            "addOnAfter": null,
                            "debounceTime": null,
                            "format": null,
                            "placeholder": "",
                            "addOnAttribute": null,
                            "timeIntervalButtons": null,
                            "markdownButtons": null,
                            "allowNoOption": false,
                            "localSearch": false,
                            "rules": [],
                            "conditions": [],
                            "options": {
                                "searchable": false,
                                "versionable": false,
                                "exportable": false,
                                "componentCreate": false,
                                "tco": false
                            },
                            "optionValues": [],
                            "autocomplete": null,
                            "categories": [
                                "contaminants"
                            ],
                            "value": null,
                            "valueDetails": null,
                            "unit": null,
                            "file": null,
                            "subAttributes": []
                        },
                        {
                            "index": "chemicalElement",
                            "name": "Chemical name or Symbol",
                            "type": "string",
                            "disabled": false,
                            "entityType": null,
                            "dataLoaderConfig": null,
                            "enumType": null,
                            "multiple": false,
                            "inputType": "text",
                            "uploadPath": null,
                            "hint": "",
                            "addOnAfter": null,
                            "debounceTime": null,
                            "format": null,
                            "placeholder": "",
                            "addOnAttribute": null,
                            "timeIntervalButtons": null,
                            "markdownButtons": null,
                            "allowNoOption": false,
                            "localSearch": false,
                            "rules": [],
                            "conditions": [],
                            "options": {
                                "searchable": false,
                                "versionable": false,
                                "exportable": false,
                                "componentCreate": false,
                                "tco": false
                            },
                            "optionValues": [],
                            "autocomplete": null,
                            "categories": [
                                "contaminants"
                            ],
                            "value": null,
                            "valueDetails": null,
                            "unit": null,
                            "file": null,
                            "subAttributes": []
                        },
                        {
                            "index": "helpActions",
                            "name": "Action if spillage or human contact",
                            "type": "string",
                            "disabled": false,
                            "entityType": null,
                            "dataLoaderConfig": null,
                            "enumType": null,
                            "multiple": false,
                            "inputType": "text",
                            "uploadPath": null,
                            "hint": "",
                            "addOnAfter": null,
                            "debounceTime": null,
                            "format": null,
                            "placeholder": "",
                            "addOnAttribute": null,
                            "timeIntervalButtons": null,
                            "markdownButtons": null,
                            "allowNoOption": false,
                            "localSearch": false,
                            "rules": [],
                            "conditions": [],
                            "options": {
                                "searchable": false,
                                "versionable": false,
                                "exportable": false,
                                "componentCreate": false,
                                "tco": false
                            },
                            "optionValues": [],
                            "autocomplete": null,
                            "categories": [
                                "contaminants"
                            ],
                            "value": null,
                            "valueDetails": null,
                            "unit": null,
                            "file": null,
                            "subAttributes": []
                        },
                        {
                            "index": "dangerClass",
                            "name": "Danger Class",
                            "type": "string",
                            "disabled": false,
                            "entityType": null,
                            "dataLoaderConfig": null,
                            "enumType": null,
                            "multiple": false,
                            "inputType": "text",
                            "uploadPath": null,
                            "hint": "",
                            "addOnAfter": null,
                            "debounceTime": null,
                            "format": null,
                            "placeholder": "",
                            "addOnAttribute": null,
                            "timeIntervalButtons": null,
                            "markdownButtons": null,
                            "allowNoOption": false,
                            "localSearch": false,
                            "rules": [],
                            "conditions": [],
                            "options": {
                                "searchable": false,
                                "versionable": false,
                                "exportable": false,
                                "componentCreate": false,
                                "tco": false
                            },
                            "optionValues": [],
                            "autocomplete": null,
                            "categories": [
                                "contaminants"
                            ],
                            "value": null,
                            "valueDetails": null,
                            "unit": null,
                            "file": null,
                            "subAttributes": []
                        }
                    ]
                },
                {
                    "index": "company",
                    "name": "Company",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [
                        {
                            "name": "required"
                        }
                    ],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "declaration"
                    ],
                    "value": "Dreebit GmbH",
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "contactName",
                    "name": "Name of the authorized person",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [
                        {
                            "name": "required"
                        }
                    ],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "declaration"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "contactJobTitle",
                    "name": "Position",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "declaration"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "address",
                    "name": "Adress",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "declaration"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "phone",
                    "name": "Phone",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "declaration"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                },
                {
                    "index": "fax",
                    "name": "Fax",
                    "type": "string",
                    "disabled": false,
                    "entityType": "",
                    "dataLoaderConfig": null,
                    "enumType": "",
                    "multiple": false,
                    "inputType": "text",
                    "uploadPath": null,
                    "hint": "",
                    "addOnAfter": null,
                    "debounceTime": null,
                    "format": null,
                    "placeholder": "",
                    "addOnAttribute": null,
                    "timeIntervalButtons": null,
                    "markdownButtons": null,
                    "allowNoOption": false,
                    "localSearch": false,
                    "rules": [],
                    "conditions": [],
                    "options": {
                        "searchable": false,
                        "versionable": false,
                        "exportable": false,
                        "componentCreate": false,
                        "tco": false
                    },
                    "optionValues": [],
                    "autocomplete": null,
                    "categories": [
                        "declaration"
                    ],
                    "value": null,
                    "valueDetails": null,
                    "unit": null,
                    "file": null,
                    "subAttributes": []
                }
            ]
        }
    },
    "time": {
        "start": 27.179956436157227,
        "schema": 4.4879913330078125,
        "query_execution": 174.30710792541504
    },
    "typeLoaderCalls": [
        "Application\\Type\\Definition\\TypeDefinitionAttribute\\TypeDefinitionAttributeListTypeDefinition",
        "Application\\Type\\Definition\\TypeDefinitionAttribute\\TypeDefinitionAttributeListOptionsInputTypeDefinition",
        "TypeDefinitionAttributeList",
        "TypeDefinitionAttribute"
    ]
}
