// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ManufacturerQuery from "../../graphql/queries/manufacturerName.graphql"

const ManufacturerBreadcrumb = ({manufacturerQuery, t}) =>
    <span>{_.get(manufacturerQuery, 'manufacturer.name', t('Unknown'))}</span>;

export default compose(
    graphql(ManufacturerQuery, {
        name: 'manufacturerQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.manufacturerId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(ManufacturerBreadcrumb));
