import React from 'react';
import "./index.css";
import _ from "lodash";
import {useDispatch} from "react-redux";
import {setComponentBrowserClientList, setComponentBrowserComponent} from "../../actions/settings";
import {Button} from "@dreebit/react-components";
import {MenuFoldOutlined, MenuUnfoldOutlined, HomeOutlined} from "@ant-design/icons";
import QuickAccessItem from "./quickAccessItem";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

interface Props {
    rootComponent: any,
    onChangeContent: (content: string) => void,
    isOpen: boolean,
    onClose: () => void,
    onOpen: () => void,
    siderHighlight?: string,
    showClientList: boolean
}

const InfrastructureMasterSider = ({rootComponent, onChangeContent, isOpen, onOpen, onClose, siderHighlight, showClientList}: Props) => {

    const {t} = useTranslation();
    const history = useHistory();

    const dispatch = useDispatch();

    const setClientListView = () => {
        onChangeContent("componentBrowser", "clientList")
        dispatch(setComponentBrowserClientList(true))
        dispatch(setComponentBrowserComponent(null))
    }

    const setComponentBrowserId = (id) => {
        onChangeContent("componentBrowser", "ownInfrastructure")
        dispatch(setComponentBrowserClientList(false))
        dispatch(setComponentBrowserComponent(id))
    }

    return (
        <div className={"infrastructure-sider-wrapper full-height white-bg p-10 flex-col flex-align-items-center"}>
            <div className={"flex-grow-1"} tour-id="infrastructure__componentBrowserSider">
                {
                    rootComponent?.id && rootComponent?.name ?
                        <QuickAccessItem
                            name={_.get(rootComponent, 'name')}
                            onClick={() => setComponentBrowserId(_.get(rootComponent, 'id'))}
                            highlight={siderHighlight === "ownInfrastructure"}
                        />
                        : null
                }
                {
                    showClientList ?
                        <QuickAccessItem
                            name={t("Clients")}
                            onClick={setClientListView}
                            highlight={siderHighlight === "clientList"}
                        />
                    : null
                }
                <QuickAccessItem
                    name={t("Search")}
                    onClick={() => {
                        history.push('/infrastructure/search')
                        onChangeContent("search", "search")
                    }}
                    highlight={siderHighlight === "search"}
                />
                <QuickAccessItem
                    name={t("Bookmarks")}
                    onClick={() => onChangeContent("bookmarks", "bookmarks")}
                    highlight={siderHighlight === "bookmarks"}
                />
            </div>
            <div>
                <Button
                    data-cy={'TEST_componentBrowserHeaderFoldButton'}
                    onClick={isOpen ? onClose : onOpen}
                    style={{justifyContent: 'center', alignItems: 'center'}}
                    size={"small"}
                    icon={isOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                />
            </div>
        </div>
    );
}

export default InfrastructureMasterSider;
