import React from 'react';
import {Result} from 'antd';
import {Button} from "@dreebit/react-components";
import config from './typeConfig';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";


type Props = {
    type?: '403' | '404' | '500';
    title?: React.ReactNode;
    desc?: React.ReactNode;
    img?: string;
    actions?: React.ReactNode;
    linkElement?: string | React.ComponentType;
    style?: React.CSSProperties;
    className?: string;
    backText?: React.ReactNode;
    redirect?: string;
}

class Exception extends React.PureComponent<Props> {
    static defaultProps = {
        redirect: null,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            type,
            title,
            desc,
            t,
            history,
            redirect
        } = this.props;

        const pageType = type in config ? type : '404';

        return (
            <Result
                status={pageType}
                title={title}
                subTitle={desc}
                extra={redirect ? <Button><Link to={redirect}>{t("Home")}</Link></Button> : <Button onClick={() => history.goBack()}>{t("Back")}</Button>}
            />
        );
    }
}

export default compose(
    withTranslation(),
    withRouter
)(Exception);
