// @flow
import React, { Component } from 'react';
import QueryListAdvanced from "../QueryListAdvanced";
import {List} from "antd";
import ErrorPatternListQuery from "./../../graphql/queries/errorPatternList.graphql"

import {Link} from "react-router-dom";
import classNames from "classnames";

type Props = {
    highlightKeys: string[]
};

type State = {

}

class ErrorPatternsList extends Component<Props, State> {

    static defaultProps = {
        highlightKeys: []
    }

  render() {
    const { } = this.props;

    return  <QueryListAdvanced
        style={{height: "100%"}}
        itemsKeyPath={'errorPatternList.errorPatterns'}
        totalKeyPath={'errorPatternList.total'}
        renderItem={(item) =>
          <Link className={classNames({active: this.props.highlightKeys.indexOf(item.id) > -1})} key={item.id} to={`/catalog/errorPatterns/${item.id}`}>
            <List.Item>
            {item.name}
            </List.Item>
          </Link>
        }
        /*
        queryProps={{
            variables: {
               params: {
                   sort: {
                       name: 'ASC'
                   }
                }
            }
        }}

         */
        query={ErrorPatternListQuery}

    />
  }
}

export default ErrorPatternsList;
