//@flow

import React, {Component} from "react"
import {compose} from "redux";
import _ from 'lodash';
import QueryListAdvanced from "../QueryListAdvanced";
import LogEntry from "../LogEntry";
import {Row} from "../Flex";
import Panel from "../Panel";
import LogDetailAttachment from "../LogDetailAttachment";
import UserLabel from "../UserLabel";
import {Modal} from "antd";
import {withTranslation} from "react-i18next";
import moment from 'moment';
import ServiceTicketLogTimelineQuery from "../../graphql/queries/serviceTicketLogTimeline.graphql";


type Props = {
    serviceTicketId: string,
    values: any,
};

type State = {
    visible: boolean,
    item: any
}

class ServiceTicketLogHistoryList extends Component<Props, State> {
  componentDidMount() {  }

  state = { visible: false };

  render() {
    const { serviceTicketId, values, t } = this.props;
    const { visible, item } = this.state;

      return <div style={{height: 300}}>
        <QueryListAdvanced
            showSearchBar={false}
            itemsKeyPath={'serviceTicket.logList.logs'}
            totalKeyPath={'serviceTicket.logList.total'}
            startKeyPath={'params.start'}
            limitKeyPath={'params.limit'}
            style={{height: "100%"}}
            query={ServiceTicketLogTimelineQuery}
            pagingSize={8}
            queryProps={{
                variables: {
                    id: serviceTicketId,
                    params: {
                        sort: {
                            insert: 'DESC'
                        },
                        filter: {
                            level: {
                                operator: 'in',
                                value: _.get(values, 'logLevel', [])
                            }
                        }
                    }
                }
            }}
            renderItem={(item) => {
                return <LogEntry key={item.id} item={item} serviceTicketId={serviceTicketId} showModal={(item) => {
                    this.setState({
                        visible: true,
                        item
                    })
                }}/>
            }}
        />
        {
            item
                ? <Modal
                    width={800}
                    visible={visible}
                    title={item.title}
                    footer={null}
                    onCancel={() => this.setState({
                        visible: false
                    })}
                >
                    <Row style={{width: '100%'}}>
                        <Panel bordered={false} style={{width: '100%'}} containerStyle={{width: '100%', minHeight: 100}}
                               title={t('Message')}>
                            {item.message}
                        </Panel>
                    </Row>
                    <Row style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <Panel bordered={false} style={{width: '45%'}} containerStyle={{width: '100%', minHeight: 40}}
                               title={t('Links')}>
                            <LogDetailAttachment log={item}/>
                        </Panel>
                        <Panel bordered={false} style={{width: '45%'}}
                               containerStyle={{width: '100%', minHeight: 40, display: 'flex', flexDirection: 'column'}}
                               title={t('Details')}>
                            <span><UserLabel userInfo={item.user} showIcon={true}/></span>
                            <span>{moment(item.insert).format('L LT')}</span>
                        </Panel>
                    </Row>
                </Modal>
                : null
        }
    </div>;
  }
}

export default compose(
    withTranslation()
)(ServiceTicketLogHistoryList);
