// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import CreateMessageMutation from "./../../graphql/mutations/createMessage.graphql";
import withProfile from "../../hoc/withProfile";


type Props = {
    serviceTicketId: string,
    serviceActionId: string,
    createMessageMutation: Function
};


class ServiceActionMessageWidget extends Component<Props, State> {

    render() {
        const {t, createMessageMutation, serviceActionId, serviceTicketId} = this.props;

        const userAttribute = {
            dataLoaderConfig: {
                dataQueryConfig: {
                    query: `query ServiceActionTicketUserList($serviceTicketId: ID!, $params: UserListParams){
    serviceTicketUserList(serviceTicketId: $serviceTicketId, params: $params) {
        total
        users {
            id
            insert
            update
            name
            firstName
            lastName
            email
            language
            phone
            timezone
            client{
                id
                name
            }
        }
    }
}`,
                    getQueryOptions: (props: any) => {
                        return {
                            variables: {
                                serviceTicketId,
                                params: {
                                    start: 0,
                                    limit: 20,
                                    search: {
                                        query: props.query,
                                        fields: ["name", "email", "firstName", "lastName"]
                                    }
                                }
                            }
                        }
                    },
                    limitKeyPath: 'params.limit',
                    startKeyPath: 'params.start',
                    totalKeyPath: 'serviceTicketUserList.total',
                    dataKeyPath: 'serviceTicketUserList.users',
                    titleKeyPath: 'name',
                    valueKeyPath: 'email',
                },
                valueQueryConfig: {
                    query: `query userName($id: ID!) {
                user(userId: $id){
                    id
                    name
                    email
                }
            }
            `,
                    dataKeyPath: 'user',
                    titleKeyPath: 'name',
                    valueKeyPath: 'email',
                    getQueryOptions: (props: any) => {
                        return {
                            variables: {
                                id: props.value
                            }
                        }
                    },
                }
            },
            entityType: 'userEmail',
            index: "participants",
            inputType: "autocomplete",
            multiple: true,
            name: t('Receiver'),
            type: "string",
            rules: [{name: 'required'}, {name: 'email'}]
        };

        return <TypeDefinitionAttributesForm
            mapAttributes={(item) => {
                return item.index === 'participants' ? userAttribute : item
            }}
            index={"message"}
            filter={(item) => {
                const blackList = ["serviceActionId", "serviceTicketId"];
                return blackList.indexOf(item.index) < 0;
            }}
            options={{
                serviceActionId: this.props.serviceActionId
            }}
            formProps={{
                clearOnSubmit: true,
                mutation: createMessageMutation,
                successMessage: t('Message sent'),
                submitTitle: t('Send'),
                translateMutationOptions: (args) => {
                    const {values} = args;
                    return {
                        variables: {
                            input: {
                                serviceActionId: serviceActionId,
                                participants: values.participants,
                                text: values.text
                            },
                        }
                    }
                }
            }}
        />
    }
}

export default compose(
    withTranslation(),
    graphql(CreateMessageMutation, {
        name: 'createMessageMutation',
    }),
    withProfile()
)(ServiceActionMessageWidget);
