// @flow
import React, {Component} from 'react';
import {Card, Collapse, message, Switch} from "antd";
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

import addModuleToServiceProviderMutation from "./../../graphql/mutations/addModuleToServiceProvider.graphql";
import removeModuleFromServiceProviderMutation from "./../../graphql/mutations/removeModuleFromServiceProvider.graphql";
import ServiceProviderModulesQuery from "../../graphql/queries/ServiceProviderModules.graphql";
import Strings from "../../i18n/strings";
import Empty from "../Empty";
import ReactMarkdown from "react-markdown";

const Panel = Collapse.Panel;

type Props = {
    serviceProviderId: string,
    active: boolean,
    module: {
        key: string,
        name: string,
        resources: {
            key: string,
            name: string,
            description: string
        }
    },
    style: ?any,
    t: Function,
    addModuleToServiceProviderMutation: Function,
    removeModuleFromServiceProviderMutation: Function,
};


class ServiceProviderModuleListItem extends Component<Props> {


    state = {
        loading: false,
    };

    handleActiveChange = (checked) => {

        const {t} = this.props;

        this.setState({
            loading: true
        }, () => {

            let promise = null;
            let resKey = "";
            if (checked) {
                resKey = "addModuleToServiceProvider";
                promise = this.props.addModuleToServiceProviderMutation();
            } else {
                resKey = "removeModuleFromServiceProvider";
                promise = this.props.removeModuleFromServiceProviderMutation();
            }

            promise.then((res) => {
                message.success(_.get(res, `data.${resKey}.message`))
            }).catch(() => {
                message.error(t(Strings.errorOccurred))
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })

    };

    render() {
        const {module, active, style} = this.props;

        return <Card
            title={module.name}
            extra={<Switch loading={this.state.loading} checked={active} onChange={this.handleActiveChange}/>}
            style={style}
        >
            {module.resources && module.resources.length ?
                <Collapse>
                    {module.resources.map(item => (<Panel header={item.name} key={item.key}>
                       <ReactMarkdown source={item.description} />
                    </Panel>))}
                </Collapse> : <Empty/>}
        </Card>
    }

}

export default compose(
    graphql(removeModuleFromServiceProviderMutation, {
        name: 'removeModuleFromServiceProviderMutation',
        options: (props: Props) => ({
            variables: {
                serviceProviderId: props.serviceProviderId,
                moduleKey: props.module.key
            },
            refetchQueries: [{
                query: ServiceProviderModulesQuery,
                variables: {
                    id: props.serviceProviderId
                }
            }]
        }),
    }),
    graphql(addModuleToServiceProviderMutation, {
        name: 'addModuleToServiceProviderMutation',
        options: (props: Props) => ({
            variables: {
                serviceProviderId: props.serviceProviderId,
                moduleKey: props.module.key
            },
            refetchQueries: [{
                query: ServiceProviderModulesQuery,
                variables: {
                    id: props.serviceProviderId
                }
            }]
        }),
    }),
    withTranslation()
)(ServiceProviderModuleListItem);
