// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import ContentHeader from "../../components/Typo/ContentHeader";
import ServiceCenterCreate from "../../components/ServiceCenterCreate";
import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import withResources from "../../hoc/withResources";

const ContentWrapper = styled.div`
   padding: ${Sizes.grid}px;
`;

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};


class ServiceCenterCreateRoute extends Component<Props, State> {


    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader title={t('New Service Center')}/>
            <ContentWrapper>
                <Panel>
                    <ServiceCenterCreate onCreate={(res) => {
                        const serviceCenterId = _.get(res, 'data.createServiceCenter.id');
                        if (serviceCenterId) {
                            this.props.history.push(`/admin/servicecenters/${serviceCenterId}`);
                        }
                    }}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default withResources(["administrateServiceCenters"])(withTranslation()(ServiceCenterCreateRoute));
