// @flow
import React, {Component} from "react"
import {Route, Switch, withRouter} from "react-router-dom";
import Search from "./search";
import ServiceTicket from "./ticket";
import Redirect from "./../Redirect";
import OpenedServiceTicketsTabs from "../../components/OpenedServiceTicketsTabs";
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {setActiveServiceTab} from "../../actions/settings";
import ServiceSearchTab from "../../components/ServiceSearchTab";
import NavigationContainer from "../../components/NavigationContainer";
import Error404 from "../errorPages/error404";


class ServiceRoute extends Component<Props> {

    static defaultProps = {
        redirectTab: 'search'
    }

    componentDidUpdate(prevProps) {
        if (this.props.setActiveTab && this.props.index !== prevProps.index) {
            // this.props.setActiveTab(this.props.index);
        }
    }

    render() {

        const activeKey = _.get(this.props, 'match.params.index', this.props.redirectTab);

        return <NavigationContainer
            navigationBar={
                <div className={"service-tabs-wrapper"} style={{padding: "0 4px", display: 'flex', flexDirection: 'row'}}>
                    <ServiceSearchTab
                        onClick={() => {this.props.history.push(`/service/search`);}}
                        activeKey={activeKey}
                    />
                    <OpenedServiceTicketsTabs
                        onTabChange={(tab) => {
                            if (activeKey !== tab) {
                                this.props.history.push(`/service/${tab}`);
                            }
                        }}
                        activeKey={activeKey}
                        disabledTabs={['search']}
                    />
                </div>
            }
        >
            <Switch>
                <Route path="/service/search" component={Search}/>
                <Redirect exact from="/service/workflow"
                          to={`/workflow`}/>
                <Route path="/service/:id" component={ServiceTicket}/>
                <Redirect exact from="/service"
                          to={`/service/${this.props.redirectTab || ServiceRoute.defaultProps.redirectTab}`}/>
                <Route component={Error404}/>
            </Switch>
        </NavigationContainer>



    }

}

export default compose(
    withTranslation(),
    withRouter,
    connect((state) => {
        return {
            redirectTab: _.get(state, 'settings.activeServiceTicketTab')
        }
    }, (dispatch) => {
        return {
            setActiveTab: (tab) => dispatch(setActiveServiceTab(tab))
        }
    }),
    withProps((props) => ({
        index: _.get(props,'match.params.index')
    }))
)(ServiceRoute);

