import React, {useEffect, useState} from 'react';
import _ from "lodash";
import TDAAttributesForm from "../TDAAttributesForm";
import MultiActionWrapper from "../MultiActionWrapper";
import {useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import CreateServiceTicketMutation from "../../graphql/mutations/createServiceTicket.graphql";
import UpdateOpenServiceTicketsListMutation from "../../graphql/mutations/addOpenedServiceTicket.graphql";
import CreateBookmarkMutation from "../../graphql/mutations/createBookmark.graphql";
import UpdateBookmarkMutation from "../../graphql/mutations/updateBookmark.graphql";
import Error from "../Error";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import {toastError, toastSuccess} from "../../utils/toast";
import bookmarkList from "../../graphql/queries/bookmarkList.graphql";
import openedServiceTicketList from "../../graphql/queries/openedServiceTicketList.graphql";
import {useHistory} from "react-router-dom";
import {cleanKeys} from "../../utils/cleanObject";
import ComponentClientIdsQuery from "../../graphql/queries/componentClientIds.graphql";

interface Props {
    componentIds: string[],
    onFinished?: () => void
}

const ComponentsMultiServiceTicketCreate = ({componentIds, onFinished}: Props) => {

    const {t} = useTranslation();
    const [createdServiceTicketIds, setCreateServiceTicketIds] = useState([])
    const [createBookmark, setCreateBookmark] = useState(undefined)
    const [openTickets, setOpenTickets] = useState(false)
    const [name, setName] = useState("")
    const history = useHistory();

    const typeDefinitionAttributesQuery = useQuery(TypeDefinitionAttributeListQuery, {
        variables: {
            index: "batchServiceTicketCreate"
        },
    })

    const componentClientIdsQuery = useQuery(ComponentClientIdsQuery, {
        variables: {
            params: {
                filter: {
                    id: {
                        value: componentIds
                    }
                }
            }
        }
    })

    const [createServiceTicketMutation] = useMutation(CreateServiceTicketMutation)
    const [updateOpenServiceTicketsListMutation] = useMutation(UpdateOpenServiceTicketsListMutation)
    const [createBookmarkMutation] = useMutation(CreateBookmarkMutation)
    const [updateBookmarkMutation] = useMutation(UpdateBookmarkMutation)

    const attributes = _.get(typeDefinitionAttributesQuery, 'data.typeDefinitionAttributeList.typeDefinitionAttributes')

    const clientIds = _.map(_.get(componentClientIdsQuery, 'data.componentList.components', []), (component) => {
        const clientId = _.get(component, 'client.id');
        return {
            componentId: component.id,
            clientId: clientId
        }
    });

    useEffect(() => {
        const name = _.find(attributes, {index: 'name'});
        setName(name?.value)
    }, [attributes]);

    const onMultiActionFinished = () => {
        if (openTickets && createdServiceTicketIds.length > 0) {
            updateOpenServiceTicketsListMutation({
                variables: {
                    serviceTicketIds: createdServiceTicketIds
                },
                refetchQueries: [{
                    query: openedServiceTicketList
                }]
            })
        }
        if (createBookmark && typeof createBookmark === "string" && createdServiceTicketIds.length > 0) {
            const initialInput = {
                data: "{}",
                title: createBookmark,
                type: "serviceTicketSelection"
            }

            createBookmarkMutation({
                variables: {
                    input: initialInput
                }
            })
                .then((res) => {
                    if (_.get(res, 'data.createBookmark.id')) {
                        updateBookmarkMutation({
                            variables: {
                                id: _.get(res, 'data.createBookmark.id'),
                                input: {
                                    data: JSON.stringify({
                                        serviceTicketIds: createdServiceTicketIds,
                                        link: `/service/search?selectionBookmark=${_.get(res, 'data.createBookmark.id')}`
                                    })
                                }
                            },
                            refetchQueries: [{
                                query: bookmarkList,
                                variables: {
                                    params: {
                                        filter: {
                                            type: {
                                                value: ['serviceTicketSearch', 'serviceTicketSelection']
                                            }
                                        },
                                        start: 0,
                                        limit: 50
                                    }
                                }
                            }]
                        }).then(() => {
                            toastSuccess(t('Bookmark created successfully'));
                        }).catch((error) => {
                            toastError(t('Failed to create bookmark'));
                            console.error(error);
                        });
                    }
                })
                .catch((error) => {
                    toastError(t('Failed to create bookmark'));
                })
        }

        if (onFinished) {
            onFinished()
        }

        if (openTickets && createdServiceTicketIds.length > 0) {
            setTimeout(() => {
                history.push(`/service/${createdServiceTicketIds[0]}`)
            }, 600)

        }
    }

    const handleSubmit = (queue, values, allValues) => {
        if (_.get(allValues, 'values.openServiceTickets')) {
            setOpenTickets(true);
        }

        if (_.get(allValues, 'values.saveServiceTicketsAsBookmark') && _.get(allValues, 'values.batchServiceTicketBookmarkName')) {
            setCreateBookmark(_.get(allValues, 'values.batchServiceTicketBookmarkName'))
        }

        // we need to remove those attributes from the input because they are not required for the mutation input
        const blackListAttributes = ["saveServiceTicketsAsBookmark", "batchServiceTicketBookmarkName", "openServiceTickets"]

        let modifiedValues = cleanKeys(allValues.values)

        blackListAttributes.forEach((index) => {
            delete modifiedValues[index];
        })

        componentIds.forEach((componentId) => {
            let input = {...modifiedValues}
            const client = clientIds.find(c => c.componentId === componentId);
            if (client) {
                input.clientId = client.clientId;
            }

            queue.push({
                variables: {componentId: componentId, input: input}
            })
        })
    }

    if (!componentIds || !componentIds.length) {
        return <Error title={t('No components selected')}/>
    }

    const formGroups = [
        {
            name: t("Service Provider"),
            index: "serviceProvider",
            colProps: {
                lg: 24
            }
        },
        {
            name: t("Default"),
            index: "default",
            colProps: {
                lg: 24
            }
        },
        {
            name: t("Component data"),
            index: "componentData",
            colProps: {
                lg: 24
            }
        },
        {
            name: t("Contamination declaration"),
            index: "contaminationDeclaration",
            colProps: {
                lg: 24
            }
        },
        {
            name: t("Contact"),
            index: "contact",
            colProps: {
                lg: 24
            },
            footer: <div className={"mv-50"}>

            </div>
        },
        {
            name: t("Options"),
            index: "batchOptions",
            colProps: {
                lg: 24
            },
        },
        {
            name: t("Client"),
            index: "client",
            colProps: {
                lg: 24
            }
        },
        {
            name: t("Error"),
            index: "error",
            colProps: {
                lg: 24
            }
        },
        {
            name: t("Parts"),
            index: "parts",
            colProps: {
                lg: 24
            }
        }
    ];

    const getTitle = (serviceTicketTypeIndex) => {
        return _.find(_.find(attributes, {index: 'serviceTicketTypeIndex'})?.optionValues, {value: serviceTicketTypeIndex}).name
    }

    return (
        <MultiActionWrapper
            onFinished={onMultiActionFinished}
            onStepFinished={(resData) => {
                const createdId = _.get(resData, 'createServiceTicket.id')
                let currentIds = createdServiceTicketIds;

                if (createdId) {
                    currentIds.push(createdId)
                    setCreateServiceTicketIds(currentIds)
                }

            }}
            total={componentIds.length}
            mutation={createServiceTicketMutation}>
            {({queue}) => {
                return <TDAAttributesForm
                    onChange={(values) => {
                        if (values.serviceTicketTypeIndex) {
                            setName(getTitle(values.serviceTicketTypeIndex))
                        }
                    }}
                    values={{name: name}}
                    groups={formGroups}
                    disableToasts
                    attributes={attributes}
                    translateMutationOptions={({changes}) => {
                        return changes
                    }}
                    mutation={(changes) => {
                        return new Promise((resolve, reject) => resolve(changes));
                    }}
                    onSubmit={(values, allValues) => handleSubmit(queue, values, allValues)}
                />
            }}
        </MultiActionWrapper>
    );
}

export default ComponentsMultiServiceTicketCreate;
