// @flow
import React, {Component, useCallback, useRef, useState} from 'react';
import {message, Table, Tag, Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import update from 'immutability-helper';
import {compose} from "recompose";
import ServiceReportAttributeConfigListQuery from '../../graphql/queries/serviceReportAttributeConfigList.graphql';
import EnumsQuery from '../../graphql/queries/enums.graphql';
import CopyServiceReportAttributeConfigMutation from '../../graphql/mutations/copyServiceReportAttributeConfig.graphql';
import DeleteServiceReportAttributeConfigMutation
  from '../../graphql/mutations/deleteServiceReportAttributeConfig.graphql';
import UpdateServiceReportAttributeConfigRankMutation
  from '../../graphql/mutations/updateServiceReportAttributeConfigRank.graphql';
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import './styles.less';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import ComponentTypeServiceReportAttributeCreateButton from "../ComponentTypeServiceReportAttributeCreateButton";
import MutationButton from "../MutationButton";

type Props = {
  serviceReportAttributeConfigListQuery: query,
  enumsQuery: query,
  updateServiceReportAttributeConfigRankMutation: Function,
  onServiceReportConfigRowClick: (row: any) => void,
  t: Function
};

type State = {
  selectedRowKeys: [],
  selectedIndices: string[],
  componentType: string,
  loading: boolean
}


class ComponentTypeServiceReportConfig extends Component<Props, State> {

  state = {
    selectedRowKeys: [],
    selectedIndices: [],
    componentType: null,
    loading: false
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedIndices: selectedRows.map(x => x.index)
    });
  };

  onComponentTypeChange = (change) => {
    this.setState({
      componentType: change.componentTypeIndex
    });
  };

  copyServiceReportConfig = () => {

    const { copyServiceReportAttributeConfigMutation } = this.props;

    this.setState({loading: true});
    copyServiceReportAttributeConfigMutation({
      variables: {
        indices: _.get(this.state, 'selectedIndices'),
        from: _.get(this.props, 'match.params.index'),
        to: _.get(this.state, 'componentType')
      }
    }).then((res) => {
      message.success(_.get(res, 'data.copyServiceReportAttributeConfig.message'))
    }).catch((err) => {
      message.error(_.get(err, 'data.copyServiceReportAttributeConfig.message'))
    }).finally(() => {
      this.setState({
        loading: false,
        selectedRowKeys: [],
        selectedIndices: [],
        componentType: null,
      })
    })
  };

  render() {
    const {
      match,
      params,
      serviceReportAttributeConfigListQuery,
      serviceReportTypesQuery,
      updateServiceReportAttributeConfigRankMutation,
      copyServiceReportAttributeConfigMutation,
      onServiceReportConfigRowClick,
      t
    } = this.props;
    const {selectedRowKeys} = this.state;

    const serviceReportAttributeConfigs = _.get(serviceReportAttributeConfigListQuery, 'serviceReportAttributeConfigList.serviceReportAttributeConfigs');
    const serviceReportTypeEnums = _.get(serviceReportTypesQuery, '__type.enumValues');

    const type = 'DragableBodyRow';

    const DragableBodyRow = ({index, moveRow, className, style, ...restProps}) => {
      const ref = React.useRef();
      const [{isOver, dropClassName}, drop] = useDrop({
        accept: type,
        collect: monitor => {
          const {index: dragIndex} = monitor.getItem() || {};
          if (dragIndex === index) {
            return {};
          }
          return {
            isOver: monitor.isOver(),
            dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
          };
        },
        drop: item => {
          moveRow(item.index, index);
        },
      });
      const [, drag] = useDrag({
        item: { type, index },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
      });
      drop(drag(ref));
      return (
          <tr
              ref={ref}
              className={`${className}${isOver ? dropClassName : ''}`}
              style={{cursor: 'move', ...style}}
              {...restProps}
          />
      );
    };

    const columns = [
      {
        title: t('Name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('Category'),
        dataIndex: 'categories[0].name',
        key: 'category'
      },
      {
        title: t('Type'),
        dataIndex: 'inputType',
        key: 'inputType',
      },
      {
        title: t('Service Report Types'),
        dataIndex: 'serviceReportTypes',
        key: 'serviceReportTypes',
        render: tags => (
            tags ?
                tags.map(tag => (
                    serviceReportTypeEnums.map((item) => {
                      if (item.name === tag) {
                        return <Tag color="#8394ae" key={tag} style={{marginTop: '3px'}}>{item.description}</Tag>
                      } else {
                        return null
                      }
                    })
                )) : null
        )
      },
    ];

    const DragSortingTable: React.FC = () => {
      const [data, setData] = useState(serviceReportAttributeConfigs);

      const components = {
        body: {
          row: DragableBodyRow,
        },
      };

      const moveRow = useCallback(
          (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];
            setData(
                update(data, {
                  $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                  ],
                }),
            );
            if (hoverIndex < dragIndex) {
              updateServiceReportAttributeConfigRankMutation({
                variables: {
                  indizes: data[dragIndex].index,
                  afterId: data[hoverIndex].id
                }
              })
            } else {
              updateServiceReportAttributeConfigRankMutation({
                variables: {
                  indizes: data[dragIndex].index,
                  beforeId: data[hoverIndex].id
                }
              })
            }
          },
          [data],
      );

      const manager = useRef(this.props.context);

      const attr = [{
        "index": "componentTypeIndex",
        "type": "string",
        "entityType": "enum",
        "enumType": "ProductComponentTypeIndex",
        "multiple": false,
        "inputType": "select",
        "value": _.get(this.state, 'componentType'),
        "placeholder": t('Component Type')
      }];
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange
      };

      const hasSelected = selectedRowKeys.length > 0;

      const componentTypeIndex = _.get(match, 'params.index');

      return (
          <>
            <div className={'mb-10 flex-row'}>
              <AttributesInlineForm
                  layout={'inline'}
                  useSubmit={false}
                  attributes={attr}
                  onChange={this.onComponentTypeChange}
                  values={params}
              />
              {hasSelected ?
                  <Button
                      onClick={this.copyServiceReportConfig}
                      loading={this.state.loading}
                      style={{marginTop: '4px'}}
                  >
                    {t('Copy')}
                  </Button> :
                  <Tooltip title='Select the items you want to copy'>
                    <Button
                        disabled
                        style={{marginTop: '4px'}}
                    >
                      {t('Copy')}
                    </Button>
                  </Tooltip>
              }
              <MutationButton
                  onDone={() => {
                    this.props.serviceReportAttributeConfigListQuery.refetch()
                        .finally(() => {
                          this.setState({
                            loading: false,
                            selectedRowKeys: [],
                            selectedIndices: [],
                            componentType: null,
                          })
                        })
                  }}
                  mutation={() => {
                    const requests = this.state.selectedIndices.map((id) => {

                      return this.props.deleteServiceReportAttributeConfigMutation({
                        variables: {
                          index: id
                        }
                      })
                    });

                    return Promise.all(requests)
                  }}
                  buttonProps={{
                    disabled: !hasSelected,
                    style: {
                      marginTop: '4px',
                      marginLeft: '16px'
                    }
                  }}
              >
                {t('Delete')}
              </MutationButton>
              <div className={'flex-grow-1'}/>
              <ComponentTypeServiceReportAttributeCreateButton componentTypeIndex={componentTypeIndex}/>
            </div>
            <DndProvider manager={manager.current.dragDropManager}>
              <Table
                  columns={columns}
                  dataSource={data}
                  components={components}
                  onRow={(record, index) => ({
                    index,
                    moveRow,
                    onClick: () => onServiceReportConfigRowClick(record)
                  })}
                  rowSelection={rowSelection}
                  pagination={false}
                  scroll={{y: 300, scrollToFirstRowOnChange: false}}
              />
            </DndProvider>
          </>
      )
    };

    return <div>
      <DragSortingTable />
    </div>
  }
}
export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceReportAttributeConfigListQuery, {
      name: 'serviceReportAttributeConfigListQuery',
      options: (props: Props) => ({
        variables: {
          params: {
            filter: {
              componentTypeIndex: {
                value: _.get(props, 'match.params.index')
              }
            }
          }
        }
      })
    }),
    waitWhileLoading('serviceReportAttributeConfigListQuery', 'serviceReportAttributeConfigList.serviceReportAttributeConfigs'),
    graphql(CopyServiceReportAttributeConfigMutation, {
      name: "copyServiceReportAttributeConfigMutation",
    }),
    graphql(DeleteServiceReportAttributeConfigMutation, {
      name: "deleteServiceReportAttributeConfigMutation",
    }),
    graphql(UpdateServiceReportAttributeConfigRankMutation, {
      name: "updateServiceReportAttributeConfigRankMutation",
    }),
    graphql(EnumsQuery, {
      name: 'serviceReportTypesQuery',
      options: (props: Props) => ({
        variables: {
          name: "ServiceReportType"
        }
      })
    }),
    waitWhileLoading('serviceReportTypesQuery', '__type.enumValues'),
)(ComponentTypeServiceReportConfig);
