import React from "react";
import _ from "lodash";
import ContentHeader from "../../components/Typo/ContentHeader";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import NewsDetailQuery from "../../graphql/queries/NewsDashboard.graphql";
import Loader from "../../components/Loader";
import {useParams} from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import ReactMarkdown from "react-markdown";
import Panel from "../../components/Panel";
import moment from "moment";

interface Props {

}

const NewsDetailRoute = (props: Props) => {

    let { id } = useParams();

    const {t} = useTranslation();
    const query = useQuery(NewsDetailQuery, {
        variables: {
            id
        }
    })

    if (query.loading) return <Loader/>

    const news = _.get(query,'data.news');

    if (!news){
        return <ErrorPage type={500} />
    }

    return <div>
        <ContentHeader hideBreadcrumb title={news.title}/>
        <div className={"ph-10"}>
            <Panel title={moment(news.insert).format('LLL')}>
                <ReactMarkdown>{news.text}</ReactMarkdown>
            </Panel>
        </div>
    </div>
}

export default NewsDetailRoute;
