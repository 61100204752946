// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import NewsDashboardQuery from "../../graphql/queries/NewsDashboard.graphql";

const NewsBreadcrumb = ({newsQuery, t}) =>
    <span>{_.get(newsQuery, 'news.title', t('Unknown'))}</span>;

export default compose(
    graphql(NewsDashboardQuery, {
        name: 'newsQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.id')
            }
        }),
    }),
)(withTranslation()(NewsBreadcrumb));
