// @flow
import React, {Component} from 'react';
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import BookmarkList, {bookmarkListVariables} from "./../BookmarkList";
import CreateBookmarkMutation from '../../graphql/mutations/createBookmark.graphql';
import BookmarkListQuery from "./../../graphql/queries/bookmarkList.graphql"
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import queryString from "query-string";
import _ from "lodash";
import {withRouter} from "react-router-dom";

type Props = {
    t: Function,
    createBookmarkMutation: Function,
    params: any,
};

type State = {
    loadingCreate: boolean,
}

class WorkflowBoardBookmarks extends Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            loadingCreate: false
        }
    }


    _createBookmark = () => {

        const {t, createBookmarkMutation, params} = this.props;

        this.setState({
            loadingCreate: true
        }, () => {
            createBookmarkMutation({
                variables: {
                    input: {
                        title: t('New workflow board filter'),
                        type: 'workflowBoard',
                        data: JSON.stringify({
                            link: `/workflow?${queryString.stringify(params)}`
                        })
                    }
                }
            }).then(() => {

            }).finally(() => {
                this.setState({
                    loadingCreate: false
                })
            })
        })
    };

    render() {
        const {t, history} = this.props;
        const {loadingCreate} = this.state;

        return <div className={'full-height'}>
            <div className={'white-bg h-50 bb p-10'}>
                <h3 className={'m-0 text-dark'}>{t('Bookmarks')}</h3>
            </div>

            <div className={"text-center pv-10 ph-10"}>
                <Button loading={loadingCreate} onClick={this._createBookmark} type={'default'}
                        className={'full-width dark-button'} ghost>{t('Save current filter')}</Button>
            </div>
            <div className={"ph-10"}>
                <div className={"bb"}/>
            </div>
            <h4 className={'ph-10 pv-5 text-dark'}>{t('Saved filter')}</h4>
            <div style={{maxHeight: '450px', overflow: 'auto'}}>
                <BookmarkList
                    type={['workflowBoard']}
                    onBookmarkClick={(bookmark) => {
                        const bookmarkData = JSON.parse(bookmark.data)
                        history.push(_.get(bookmarkData, 'link', '/workflow'))
                    }}
                />
            </div>

        </div>
    }
}


export default compose(
    withRouter,
    graphql(CreateBookmarkMutation, {
        name: 'createBookmarkMutation',
        options: (props) => ({
            refetchQueries: [
                {
                    query: BookmarkListQuery,
                    variables: bookmarkListVariables(['workflowBoard'])
                }
            ]
        })
    }),
)(withTranslation()(WorkflowBoardBookmarks));
