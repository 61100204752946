// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Tabs} from "antd";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import './index.css';
import {graphql} from "@apollo/client/react/hoc";
import ProfileQuery from "../../graphql/queries/profile.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import * as _ from "lodash";

const TabPane = Tabs.TabPane;

const defaultTabKeys = ['dashboard', 'search']; // ['dashboard', 'stock', 'search']

type Props = {
    activeKey?: string,
    t: Function,
    onClick: Function,
    profileQuery?: any
};

type State = {

}

class ComponentSearchTab extends Component<Props, State> {
    componentDidMount() {  }

    state = {  };

    getTabIcon = (key) => {
        switch(key) {
            case 'dashboard':
                return 'speedometer';
            // case 'stock':
            //     return 'forklift';
            case 'search':
                return 'magnify';
        }
    };

    render() {
        const {t, activeKey, onClick, profileQuery} = this.props;
        const {} = this.state;

        let tabs = _.compact(defaultTabKeys.map((key) => {
            const TabContent = <TabPane key={key}
                                        tab={<span><MaterialCommunityIcon size={14}
                                                                          name={this.getTabIcon(key)}/></span>}
                                        closable={false}
            />;

            // if (key === 'stock') {
            //     const userResources = _.get(profileQuery, 'profile.resources', []).reduce((acc, cur) => {
            //         return [...acc, cur.key]
            //     }, []);
            //     const showStock = userResources.indexOf('administrateStocks') > -1;
            //
            //     if (showStock) {
            //         return TabContent;
            //     } else return null;
            // }

            return TabContent;
        }));

        return <div>
            <Tabs
                className="tabs-no-bottom-margin tabs-top-padding full-height"
                hideAdd
                onTabClick={onClick}
                activeKey={activeKey}
                type="editable-card"
            >
                {tabs}
            </Tabs>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ProfileQuery, {
        name: 'profileQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first'
        })
    }),
    waitWhileLoading('profileQuery', 'profile.resources', {
        optional: true
    }),
)(ComponentSearchTab);
