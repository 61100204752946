// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ComponentLogHistoryHeader from "../ComponentLogHistoryHeader";
import ComponentLogHistoryList from "../ComponentLogHistoryList";
import _ from "lodash";
import Enums from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    componentId: string,
    enumsQuery: query,
}

class ComponentHistoryPanel extends Component<Props> {

    render() {

        const {componentId, enumsQuery} = this.props;

        const values = {
            logLevel: _.get(enumsQuery, '__type.enumValues').map((el) => {
                return el.name;
            })
        };

        return <div className="bg-white">
            <ComponentLogHistoryHeader componentId={componentId}/>
            <ComponentLogHistoryList componentId={componentId}/>
        </div>;
    }

}

export default compose(
    graphql(Enums, {
        name: 'enumsQuery',
        options: () => ({
            variables: {
                name: 'LogLevel'
            }
        }),
    }),
    waitWhileLoading('enumsQuery', '__type.enumValues'),
    withTranslation()
)(ComponentHistoryPanel);
