// @flow
import React, { Component } from 'react';
import {Table} from "antd";
import {withTranslation} from "react-i18next";
import EnumLabel from "../EnumLabel";

type Props = {
    items: any[],
    onItemClick?: (key: string, record: any) => void
};

type State = {

}

class DefaultServiceTasksTable extends Component<Props, State> {

  render() {
    const { items ,t } = this.props;
/*
                name
                defaultPrice
                description
                recommendedServiceCycle
                statusInformation
                update
 */
    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('Default Price'),
            dataIndex: 'defaultPrice',
            key: 'defaultPrice',
        },
        {
            title: t('Service Cycle'),
            dataIndex: 'recommendedServiceCycle',
            key: 'recommendedServiceCycle',
        },
        {
            title: t('Status Information'),
            dataIndex: 'statusInformation',
            key: 'statusInformation',
            render: (text) => <EnumLabel value={text} enum={"DefaultServiceTaskStatusInformation"} />
        },
        {
            title: t('Last update'),
            dataIndex: 'update',
            key: 'update',
        },
    ]

    return <Table
        rowKey={"id"}
        onRow={(record, rowIndex) => {
            return {
                onClick: event => {
                    if (this.props.onItemClick){
                        this.props.onItemClick(record.id, record);
                    }
                }, // click row
            };
        }}
        dataSource={items}
        columns={columns} />
  }
}

export default withTranslation()(DefaultServiceTasksTable);
