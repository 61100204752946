import React from "react";

const SvgUpcomingServiceControls = props => (
    <svg
        id="upcomingServiceControls_svg__Ebene_1"
        x={0}
        y={0}
        viewBox="0 0 150 100"
        xmlSpace="preserve"
        width={props.width}
        {...props}
    >
        <style>{".upcomingServiceControls_svg__st1{fill:#fff}"}</style>
        <path fill={props.color} d="M0 0h150v100H0z"/>
        <path
            className="upcomingServiceControls_svg__st1"
            d="M78.1 44.9c2.2-1.6 4.7-2.9 7.4-3.7 6.5-2.1 13.3-1.5 19.1 1.2 2.2-2.2 3.9-4.8 4.9-7.8.7-2.1 1.1-3.7 1.1-4.9 0-.5-.1-.9-.4-1.2-.3-.3-.6-.5-1.1-.5-.3 0-1.4.5-3.3 1.6s-4 2.4-6.3 3.8-3.5 2.2-3.7 2.3l-9-5V20.4l13.6-7.8c.5-.3.7-.8.7-1.3s-.2-1-.7-1.3c-1.4-.9-3-1.6-5-2.2-2-.5-3.8-.8-5.6-.8-5.7 0-10.6 2-14.7 6.1s-6.1 9-6.1 14.7 2 10.6 6.1 14.7c1 .9 2 1.7 3 2.4zM75.8 46.8c-.9-.7-1.8-1.4-2.7-2.3-2.3-2.3-4.1-5-5.3-8L36.2 68.1c-1.2 1.2-1.8 2.6-1.8 4.2 0 1.7.6 3.1 1.8 4.2l4.9 5c1.2 1.1 2.6 1.7 4.2 1.7s3-.6 4.2-1.7l17.8-17.8c.6-6.4 3.6-12.5 8.5-16.9zM48.3 72.3c-.8 0-1.5-.3-2.1-.9s-.9-1.3-.9-2.1.3-1.5.9-2.1c.6-.6 1.3-.9 2.1-.9s1.5.3 2.1.9c.6.6.9 1.3.9 2.1s-.3 1.5-.9 2.1c-.6.6-1.3.9-2.1.9z"
        />
        <g>
            <path
                className="upcomingServiceControls_svg__st1"
                d="M101 88.3c-4 1.3-8.3 1.4-12.4.5l1.6-6.8c2.8.7 5.8.6 8.6-.3 8.4-2.7 13.1-11.7 10.4-20.2C106.6 53 97.6 48.3 89.1 51 81 53.6 76.4 62.1 78.5 70.2l1 3.3 6.1-5c.2-.2.5-.2.7 0 .2.2.3.4.2.7l-6.2 17.1c-.1.2-.2.3-.4.4-.2.1-.4 0-.5-.1L64.5 76.2c-.1-.1-.2-.2-.2-.3v-.4c.1-.2.3-.4.6-.4l7.9.5-.8-2.3c-.3-.8-.9-3.8-.9-4.3-1.3-10.8 5.2-21.3 15.9-24.7 12.1-3.9 25.2 2.9 29 15 3.9 12.1-2.8 25.1-15 29zm-7-36c-.7 0-1.2.5-1.2 1.2v16.3c0 .5.3.9.7 1.1.1.1.3.1.5.1s.5-.1.7-.2l11-7.6c.6-.4.7-1.1.3-1.7-.4-.6-1.1-.7-1.7-.3l-9.1 6.3v-14c0-.7-.5-1.2-1.2-1.2z"
                id="upcomingServiceControls_svg__Layer_8_10_"
            />
        </g>
    </svg>
);

export default SvgUpcomingServiceControls;