// @flow
import React, {Component} from 'react';
import _ from "lodash";
import MultiActionWrapper from "../MultiActionWrapper";
import {compose} from "recompose";
import {Alert} from "antd";
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {withApollo} from "@apollo/client/react/hoc";
import ServiceTicketPDFQuery from "../../graphql/queries/serviceTicketListPDF.graphql";

type Props = {
    serviceTicketIds: string[],
    onDone: () => void,
};

type State = {}


class ServiceTicketsPDFExport extends Component<Props, State> {


  render() {
    const { onFinished, serviceTicketIds, t, client} = this.props;

    return <MultiActionWrapper
        onFinished={onFinished}
        total={1}
        mutation={() => {

            return client.query({
                query: ServiceTicketPDFQuery,
                variables: {
                    params: {
                        filter: {
                            id: {
                                value: serviceTicketIds,
                                operator: 'in'
                            }
                        }
                    }
                }
            }).then(({data})=>{
                const link = _.get(data,'serviceTicketList.multiSheet.link');
                if (!link){
                    const error = new Error('PDF could not be generated');
                    throw error
                }
                window.open(link,'_newtab')
            })

        }}>
        {({queue}) => {
            /**
             * queue.push({
                              variables: {id: serviceTicketId, attributes: values}
                          })
             */
            return <div>
                <Alert
                description={<Button type={'primary'} className={"mt-10"} onClick={() => {
                    queue.push({
                        serviceTicketIds
                    })
                }}>{t('Download of')} {serviceTicketIds.length} {t("service tickets")}</Button>}
                    message={t('Pressing download will start multiple downloads with service ticket pdfs')}
                    type="info"
                    showIcon />
            </div>
        }}
    </MultiActionWrapper>
  }
}

export default compose(
    withApollo,
    withTranslation(),
)(   ServiceTicketsPDFExport)
