// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import MutationTable from "./../MutationTable";

import AddProductToCatalogAttributeMutation from "../../graphql/mutations/addProductToCatalogAttribute.graphql"
import RemoveProductFromCatalogAttribute from "../../graphql/mutations/removeProductFromCatalogAttribute.graphql"
import AttributeProductionsQueryList from "../../graphql/queries/CatalogAttributeProductList.graphql"
import ProductListQuery from "../../graphql/queries/productList.graphql"
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import ProductSearchItem from "../ProductSerachBar/ProductSearchItem";
import {Link} from "react-router-dom";

type Props = {

};

type State = {

}

class CatalogAttributeProducts extends Component<Props, State> {

  render() {
    const { index, removeProductFromCatalogAttributeMutation, t, addProductToCatalogAttributeMutation } = this.props;

    return <MutationTable
        renderAddSelectOption={(item) => <ProductSearchItem product={item}/>}
        onAdd={(productId) => {
          return addProductToCatalogAttributeMutation({
            variables: {
              catalogAttributeIndex: index,
              productId
            }
          })
        }}
        onDelete={(ids) => {
          return Promise.all(ids.map((productId) => {
            return removeProductFromCatalogAttributeMutation({
              variables: {
                catalogAttributeIndex: index,
                productId
              }
            })
          }))
        }}
        addQueryConfig={{
          query: ProductListQuery,
          dataKeyPath: 'productList.products',
          valueKeyPath: 'id',
          titleKeyPath: 'name',
          totalKeyPath: 'productList.total',
          getQueryOptions: (props) => {
            return {
              params: {
                start: 0,
                limit: 15,
                search: {
                  query: props.query,
                  fields: ["name","catalogNo"]
                }
              }
            }
          }
        }}
        dataSourceQueryConfig={{
          query: AttributeProductionsQueryList,
          dataKeyPath: 'catalogAttribute.productList.products',
          valueKeyPath: 'id',
          titleKeyPath: 'name',
          totalKeyPath: 'catalogAttribute.productList.total',
          variables: {
            index,
          }
        }}
        tableProps={{
          columns: [
            {
              title: t('Name'),
              dataIndex: 'name',
              render: (text, record) => <Link to={`/catalog/products/${record.id}`}>{text}</Link>
            },
            {
              title: t('Catalog No'),
              dataIndex: 'catalogNo',
            },
            {
              title: t('Manufacturer'),
              dataIndex: 'manufacturer.name',
            },
          ]
        }}
    />
  }
}

export default compose(
    withTranslation(),
    graphql(AddProductToCatalogAttributeMutation, {
      name: 'addProductToCatalogAttributeMutation',
    }),
    graphql(RemoveProductFromCatalogAttribute, {
      name: 'removeProductFromCatalogAttributeMutation',
      options: ({index}) => {
        return {
          variables: {
            index
          }
        }
      }
    })
)(CatalogAttributeProducts);
