// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {message} from "antd";
import {Button} from "@dreebit/react-components";
import ServiceTicketRemoveMutation from "../../graphql/mutations/deleteServiceTicket.graphql";
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import OpenedServiceTicketListQuery from "../../graphql/queries/openedServiceTicketList.graphql";
import ServiceTicketAccessQuery from "../../graphql/queries/serviceTicketAccess.graphql";
import ComponentServiceTicketsListQuery from "./../../graphql/queries/ComponentServiceTicketsList.graphql";
import IconSplitPanelServiceTicketQuery from "../../graphql/queries/iconSplitPanelServiceTicket.graphql";
import activeServiceTicket from "../../graphql/queries/activeServiceTicket.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Error from "../Error";

type Props = {
	match: {
		params: any
	},
	history: {
		push: Function
	},
	t: Function,
}

type State = {
	isLoading: Boolean
}


class ServiceTicketRemove extends Component<Props, State> {

	state = {
		isLoading: false
	};

	_deleteServiceTicket = (ticketId) => {
		const {serviceTicketRemoveMutation, history, client} = this.props;
		const params = new URLSearchParams(this.props.location.search);
		const componentId = params.get('componentId');

		// @TODO [JB]: Was ist wenn der netzwerkrequest fehl schlägt? Fehlerbehandlung fehlt hier.

		this.setState({isLoading: true});
		serviceTicketRemoveMutation(
			{
				variables: {serviceTicketId: ticketId},
				refetchQueries: [
					{
						query: OpenedServiceTicketListQuery,
					},
                    {
                        query: ComponentServiceTicketsListQuery,
                        variables: {componentId},
                        skip: !componentId
                    },
                    {
                        query: IconSplitPanelServiceTicketQuery,
                        variables: {componentId},
                        skip: !componentId
                    },
					{
						query: activeServiceTicket,
						variables: {
							id: componentId,
						},
						skip: !componentId
					}
                ]
			}
		)
			.then( (res) => {
				return client.clearStore().then(() => res)
			} )
			.then((res) => {

				message.success(res.data.deleteServiceTicket.message);
				//@TODO [JB]: Die Logik was passieren soll nachdem ein ticket gelöscht wurde sollte im Routing passieren. Diese Funktion sollte nicht zum Scope dieser ReactComponent gehören
				history.push(`/service/search`)
			});
	};


	render() {

		const {t, match, serviceTicketRemoveQuery} = this.props;

		if (!_.get(serviceTicketRemoveQuery,'serviceTicket.access.remove')){
			return <Error title={t("No access")}/>
		}

		const {isLoading} = this.state;
		const ticketId = _.get(match, 'params.id');

		return <div className={'flex-col p-50'} style={{backgroundColor: 'white', margin: 'auto'}}>
            <span className={'larger-text'}
				  style={{margin: 'auto'}}>{t('Are you sure to delete this service ticket?')}</span>
			<div className={'flex-row flex-space-evenly mt-20'}>
				{isLoading ?
					<Button type={'danger'} icon={'loading'}>
						{t('Remove')}
					</Button>
					:
					<Button data-cy={'TEST_10003'} type={'danger'} onClick={() => this._deleteServiceTicket(ticketId)} icon={'delete'}>
						{t('Remove')}
					</Button>
				}
			</div>
		</div>
	}
}

export default compose(
	withApollo,
	graphql(ServiceTicketAccessQuery,{
		name: 'serviceTicketRemoveQuery',
		options: props => ({
			variables: {
				id: _.get(props, 'match.params.id')
			}
		})
	}),
	waitWhileLoading('serviceTicketRemoveQuery'),
	graphql(ServiceTicketRemoveMutation, {
		name: 'serviceTicketRemoveMutation',
		options: props => ({
			refetchQueries: [{
				query: OpenedServiceTicketListQuery,
			}]
		})

	}),
	withTranslation(),
)(ServiceTicketRemove);
