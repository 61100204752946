// @flow
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import IconSplitPanelServicePlanQuery from "../../graphql/queries/iconSplitPanelServicePlan.graphql";
import _ from "lodash";
import {getNextBannerItem} from "./helper";
import SplitPanelList from "../SplitPanelList";
import waitWhileLoading from "../../hoc/waitWhileLoading";


type Props = {
    t: Function,
    componentId: string,
    iconSplitPanelServicePlanQuery: any
}


class IconSplitPanelServicePlan extends Component<Props> {

    render() {

        const {t, componentId, iconSplitPanelServicePlanQuery} = this.props;
        const totalPlans = _.get(iconSplitPanelServicePlanQuery, 'component.servicePlanList.total');
        const totalControls = _.get(iconSplitPanelServicePlanQuery, 'component.serviceControlList.total');

        const bannerItem = getNextBannerItem(_.get(iconSplitPanelServicePlanQuery, 'component.servicePlanList.servicePlans'), _.get(iconSplitPanelServicePlanQuery, 'component.serviceControlList.serviceControls'), componentId, t);
        const items = [];
        if (bannerItem) {
            items.push(bannerItem);
        }
        items.push({
            icon: "calendar-alt",
            title: t('Service Plan', {count: totalPlans + totalControls}),
            description: `${totalPlans ? totalPlans : 0 } ${t('Service plan', {count: totalPlans})} & ${totalControls ? totalControls : 0} ${t('Inspection', {count: totalControls})}`,
            link: `/infrastructure/${componentId}/serviceplans`,
            largeTitle: totalPlans + totalControls,
        });

        return <SplitPanelList
            items={items}
        />
    }
}

export default compose(
    withTranslation(),
    graphql(IconSplitPanelServicePlanQuery, {
        name: 'iconSplitPanelServicePlanQuery',
        options: (props: Props) => ({
            variables: {
                componentId: props.componentId
            }
        })
    }),
    waitWhileLoading('iconSplitPanelServicePlanQuery', 'component')
)(IconSplitPanelServicePlan);
