// @flow
import React, {Component} from "react"
import _ from 'lodash';
import {Button, Dropdown, Menu} from 'antd';
import pathToRegexp from 'path-to-regexp';
import {queryArray} from './../../utils';
import {withRouter} from "react-router-dom";
import {MenuOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import './Header.less'
import ColorBar from "../Utilities/ColorBar";
import MenuFactory from "../../utils/menu";
import {withTranslation} from "react-i18next";
import GlobalSearchInput from "../GloablSearchInput";
import {Col, Grow, Row} from "../Flex";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ProfileQuery from "../../graphql/queries/profile.graphql";
import {withSize} from "react-sizeme";
import Logos from "../../provider/theme/logos";
import withTheme from "../../hoc/withTheme";

type Props = {
    mainMenu: [],
    rightMenu: [],
    user: ?any,
    logout: ?Function,
    isNavbar: boolean,
    menuPopoverVisible: boolean,
    location: ?any,
    switchMenuPopover: ?Function,
    navOpenKeys: [],
    changeOpenKeys: ?Function,
    onToggleRightSidebar: ?Function,
    collapsed: boolean,
}

const getPathArray = (array, current, pid, id) => {
    const result = [String(current[id])];
    const getPath = item => {
        if (item && item[pid]) {
            result.unshift(String(item[pid]));
            getPath(queryArray(array, item[pid], id));
        }
    };
    getPath(current);
    return result;
};


class VSMHeader extends Component<Props> {


    render() {

        const {t, size, location, themeConfig, theme, rightMenu} = this.props;

        const handleClickMenu = e => e.key === 'logout' && this.props.logout();

        let currentMenu;
        let defaultSelectedKeys;
        if (this.props.mainMenu && location) {
            for (const item of this.props.mainMenu) {
                if (item.route && pathToRegexp(item.route).exec(location.hash.replace('#', ''))) {
                    currentMenu = item;
                    break;
                }
            }
        }


        if (currentMenu) {
            defaultSelectedKeys = getPathArray(this.props.mainMenu, currentMenu, 'mpid', 'id');
        }

        if (!defaultSelectedKeys) {
            defaultSelectedKeys = ['1'];
        }

        const userResources = _.get(this.props, 'profileQuery.profile.resources', []).reduce((acc, cur) => {
            return [...acc, cur.key]
        }, []);

        const menuItems = MenuFactory.getMenuItems(this.props.mainMenu, t, null, userResources);

        let leftMenu = <Menu
            selectedKeys={defaultSelectedKeys}
            mode="horizontal"
            onClick={handleClickMenu}
            tour-id="global__navigationLeft"
            className={'AppHeader'}
        >
            {menuItems}
        </Menu>;

        const windowWith = window.innerWidth;
        const rightMenuItems = MenuFactory.getMenuItems(rightMenu, t, null, userResources, false);
        let Logo = _.get(Logos[theme], "@navigation-logo", Logos.vsm["@navigation-logo"]);

        return (
            <div id={"header"}>
                <Row className={'flex-align-items-center'}>
                    <div onClick={() => {
                        window.location.reload();
                    }} className={`logo link ${theme}`}>
                        <img src={Logo}/>
                    </div>
                    <Grow className={'HeaderLeftMenu'}>
                        {leftMenu}
                    </Grow>
                    <Col className={"flex-center"}>
                        <GlobalSearchInput/>
                    </Col>
                    {
                        rightMenu && rightMenuItems && rightMenuItems.length
                            ? <Col className={'NavigationRightMenu'} tourId="global__navigationRight">
                                {
                                    windowWith >= 1000
                                        ? <Menu
                                            selectedKeys={defaultSelectedKeys}
                                            mode="horizontal"
                                            onClick={handleClickMenu}
                                            className={'flex-row flex-align-items-center'}
                                            style={{
                                                flexWrap: 'nowrap'
                                            }}
                                        >
                                            {MenuFactory.getMenuItems(rightMenu, t, null, userResources, false)}
                                        </Menu>
                                        : <Dropdown overlay={
                                            <Menu
                                                selectedKeys={defaultSelectedKeys}
                                                onClick={handleClickMenu}
                                                className={'HeaderDropdownMenu'}
                                            >
                                                {MenuFactory.getMenuItems(rightMenu, t, null, userResources)}
                                            </Menu>
                                        }
                                                    placement="bottomRight"
                                                    trigger={['click']}
                                        >
                                            <Button
                                                className={'ml-10 mr-15'}
                                                icon={<MenuOutlined/>}
                                            />
                                        </Dropdown>
                                }
                            </Col>
                            : null
                    }
                </Row>

                <ColorBar theme={theme}/>
            </div>

        );
    }

}


export default compose(
    withRouter,
    graphql(ProfileQuery, {
        name: 'profileQuery',
    }),
    connect((state, ownProps) => {
        return {
            location: ownProps.location || window.location,
            user: state.auth.user
        }
    }),
    withTranslation(),
    withSize({
        refreshRate: 200,
        refreshMode: "debounce"
    }),
    withTheme()
)(VSMHeader);

