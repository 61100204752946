// @flow
import React, {Component} from "react"
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {Empty as EmptyComponent} from "antd"

type Props = {
    text: string,
    t: Function,
}

class Empty extends Component<Props> {

    static defaultProps = {
        text: 'No data found',
        translate: true
    };

    render() {

        const {t, text} = this.props;

        return <EmptyComponent

            image={EmptyComponent.PRESENTED_IMAGE_SIMPLE}
            description={_.isString(text) ? t(text) : text}>{this.props.children}</EmptyComponent>;
    }

}

export default withTranslation()(Empty);
