import React, {ReactNode} from 'react';
import styled from 'styled-components';
import Colors from "../../themes/default/colors";
import type {
    DraggableProvided,
    DraggableStateSnapshot,
    DroppableProvided,
    DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import WorkflowItem from './item';

import Title from './title';
import {Spin} from "antd";
import Loader from "../Loader";

export const getBackgroundColor = (
    isDraggingOver: boolean,
    isDraggingFrom: boolean,
): string => {
    if (isDraggingOver) {
        return Colors.greyLight;
    }
    if (isDraggingFrom) {
        return Colors.greyLight;
    }
    return Colors.greyLighter;
};

const grid = 5;

const Wrapper = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  overflow: auto;
  flex-grow: 1;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
`;

const scrollContainerHeight: number = 250;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

type Props = {|
    renderItem: (item: any) => ReactNode,
    listId?: string,
    listType?: string,
    filter: any,
    title?: string,
    internalScroll?: boolean,
    scrollContainerStyle?: Object,
    isDropDisabled?: boolean,
    isCombineEnabled?: boolean,
    style?: Object,
    // may not be provided - and might be null
    ignoreContainerClipping?: boolean,
    toggleSelection: (itemId: string) => void,
    toggleSelectionInGroup: (itemId: string) => void,
    selectionCount?: number,
    useClone?: boolean,
    loadingMore?: boolean
|};

type WorkflowListProps = {|
    items: Workflow[],
    toggleSelection: (itemId: string) => void,
    toggleSelectionInGroup: (itemId: string) => void,
    selectionCount?: number,
    loadingMore?: boolean
|};

const InnerWorkflowList = React.memo(function InnerWorkflowList(
    props: WorkflowListProps,
) {


    let content = props.items.map((item: Workflow, index: number) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
            {(
                dragProvided: DraggableProvided,
                dragSnapshot: DraggableStateSnapshot,
            ) => (
                <WorkflowItem
                    key={item.id}
                    item={item}
                    renderItem={props.renderItem}
                    isDragging={dragSnapshot.isDragging}
                    isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                    multiSelectTo={props.multiSelectTo}
                    provided={dragProvided}
                    selectedItemIds={props.selectedItemIds}
                    toggleSelectionInGroup={props.toggleSelectionInGroup}
                    toggleSelection={props.toggleSelection}
                    selectionCount={props.selectionCount}
                />
            )}
        </Draggable>
    ));

    if (props.loadingMore) {
        content.push(<span key={'loading'} className={"mr-10"}>
                            <Loader size={"small"}/>
                        </span>);
    }

    return content;
});

type InnerListProps = {|
    dropProvided: DroppableProvided,
    filters: any,
    title: ?string,
|};

function InnerList(props: InnerListProps) {
    const {items, dropProvided, renderItem, selectedItemIds, loadingMore} = props;
    const title = props.title ? <Title>{props.title}</Title> : null;

    if (props.loading || !items) return <Spin/>

    return (
        <Container>
            {title}
            <DropZone ref={dropProvided.innerRef}>
                <InnerWorkflowList toggleSelectionInGroup={props.toggleSelectionInGroup}
                                   toggleSelection={props.toggleSelection}
                                   multiSelectTo={props.multiSelectTo}
                                   selectedItemIds={props.selectedItemIds}
                                   selectionCount={props.selectionCount}
                                   renderItem={renderItem}
                                   loadingMore={loadingMore || props.loading}
                                   items={items} />
                {dropProvided.placeholder}
            </DropZone>
        </Container>
    );
}

export default function WorkflowList(props: Props) {
    const {
        loadingMore,
        onScroll,
        ignoreContainerClipping,
        internalScroll,
        scrollContainerStyle,
        isDropDisabled,
        isCombineEnabled,
        listId = 'LIST',
        listType,
        style,
        items,
        title,
        useClone,

    } = props;


    return (
        <Droppable
            droppableId={listId}
            type={listType}
            ignoreContainerClipping={ignoreContainerClipping}
            isDropDisabled={isDropDisabled}
            isCombineEnabled={isCombineEnabled}
            renderClone={
                useClone
                    ? (provided, snapshot, descriptor) => (
                        <WorkflowItem
                            snapshot={snapshot}
                            renderItem={props.renderItem}
                            item={items[descriptor.source.index]}
                            provided={provided}
                            toggleSelectionInGroup={props.toggleSelectionInGroup}
                            toggleSelection={props.toggleSelection}
                            selectionCount={props.selectionCount}
                            selectedItemIds={props.selectedItemIds}
                            isDragging={snapshot.isDragging}
                            multiSelectTo={props.multiSelectTo}
                            isClone
                        />
                    )
                    : null
            }
        >
            {(
                dropProvided: DroppableProvided,
                dropSnapshot: DroppableStateSnapshot,
            ) => (
                <Wrapper
                    style={style}
                    className={'WorkflowBoardListWrapper'}
                    isDraggingOver={dropSnapshot.isDraggingOver}
                    isDropDisabled={isDropDisabled}
                    isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                    onScroll={onScroll}
                    {...dropProvided.droppableProps}
                >
                    {internalScroll ? (
                        <ScrollContainer style={scrollContainerStyle}>
                            <InnerList
                                loadingMore={props.loadingMore}
                                loading={props.loading}
                                renderItem={props.renderItem}
                                items={items}
                                title={title}
                                snapshot={dropSnapshot}
                                toggleSelectionInGroup={props.toggleSelectionInGroup}
                                toggleSelection={props.toggleSelection}
                                selectionCount={props.selectionCount}
                                multiSelectTo={props.multiSelectTo}
                                selectedItemIds={props.selectedItemIds}
                                dropProvided={dropProvided}
                            />
                        </ScrollContainer>
                    ) : (
                        <InnerList
                            loadingMore={props.loadingMore}
                            loading={props.loading}
                            renderItem={props.renderItem}
                            items={items}
                            title={title}
                            snapshot={dropSnapshot}
                            toggleSelectionInGroup={props.toggleSelectionInGroup}
                            toggleSelection={props.toggleSelection}
                            selectionCount={props.selectionCount}
                            multiSelectTo={props.multiSelectTo}
                            selectedItemIds={props.selectedItemIds}
                            dropProvided={dropProvided}
                        />
                    )}
                </Wrapper>
            )}
        </Droppable>
    );
}
