// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentServiceTicketsListQuery from "./../../graphql/queries/ComponentServiceTicketsList.graphql";
import Empty from "../Empty";
import QueryTable from "../QueryTable";
import {withTranslation} from "react-i18next";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import moment from "moment";
import ServiceTicketStateBadge from "../ServiceTicketStateBadge";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import {objectToFilter} from "../../utils/queryFilter";
import {Tag} from "antd";

type Props = {
    componentId: string,
    onClick: Function,
    t: Function,
    filter: {
        children: boolean
    },
    showComponentPath: boolean,
    componentServiceTicketsListQuery: query
};

class ComponentServiceTicketsList extends Component<Props, State> {

    render() {
        const {
            serviceTickets,
            filter,
            onClick,
            t,
            componentServiceTicketsListQuery,
            serviceTicketsListQuery
        } = this.props;


        if (!serviceTickets.length) {
            return <Empty/>;
        }

        const columns = [
            {
                title: t('Key'),
                dataIndex: 'key',
                key: 'key',
                render: (text, record) => {
                    const index = _.get(record, 'serviceTicketType.index');
                    const name = _.get(record, 'name');

                    return <div>
                        <div>
                            {text} - {name}
                        </div>
                        <div style={{color: "rgba(0, 0, 0, 0.45)"}}>
                            <ServiceTicketTypeIcon index={index}/> {_.get(record, 'serviceTicketType.name')}
                        </div>

                    </div>
                },
            },
            {
                title: t('Status'),
                dataIndex: 'status',
                key: 'status',
                width: 140,
                render: (text, record) => {
                    return <ServiceTicketStateBadge state={_.get(record,'serviceTicketState')} />
                },
            },
            {
                key: 'insert',
                dataIndex: 'insert',
                title: t('Insert'),
                render: (text, record) => {
                    return <div>
                        <div>
                            {_.get(record, 'createdBy.name')}
                        </div>
                        <div style={{color: "rgba(0, 0, 0, 0.45)"}}>
                            <span>{moment(text).format('L LT')}</span>
                        </div>
                    </div>

                }
            },
            {
                key: 'update',
                dataIndex: 'update',
                title: t('Update'),
                render: (text, record) => {
                    return <div>
                        <div>
                            {_.get(record, 'updatedBy.name')}
                        </div>
                        <div style={{color: "rgba(0, 0, 0, 0.45)"}}>
                            <span>{moment(text).format('L LT')}</span>
                        </div>
                    </div>

                }
            },
            {
                key: 'lastServiceAction.update',
                dataIndex: 'lastServiceAction.update',
                title: t('Last service action'),
                render: (text, record) => {
                    return <div>
                        <div>
                            {_.get(record, 'lastServiceAction.serviceActionType.code')}: {_.get(record, 'lastServiceAction.serviceActionType.name')}
                        </div>
                        <div style={{color: "rgba(0, 0, 0, 0.45)"}}>
                            <span>{moment(_.get(record, 'lastServiceAction.update')).format('LLL')}</span>
                        </div>
                    </div>

                }
            }
        ]


        if (this.props.children) {

            const basePathIds = _.get(componentServiceTicketsListQuery, 'component.path', []).map(item => item.id);
            columns.insert(1, {
                title: t('Component'),
                dataIndex: 'component',
                key: 'component',
                width: 200,
                render: (text, record) => {

                    const isSelf = _.chain(record).get('component.path', []).last().get('id').value() === this.props.componentId;

                    return <div>
                        <div>
                            <Tag
                                className={isSelf ? "blue-grey-bg" : "blue-grey-dark-bg"}>{_.get(record, 'component.name')}</Tag>
                        </div>
                        {!isSelf ? <div style={{color: "rgba(0, 0, 0, 0.45)"}}>
                            <ComponentPathBreadcrumb
                                path={_.get(record, 'component.path', []).filter(item => basePathIds.indexOf(item.id) < 0)}/>
                        </div> : null}


                    </div>
                },
            },)
        }


        return <div>
            <QueryTable
                style={{borderRadius: 0}}
                className={"link-table"}
                columns={columns}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onClick(record);
                        },
                    };
                }}

                size={'small'}
                rowKey={"id"}
                query={componentServiceTicketsListQuery}
                itemsKeyPath={'component.serviceTicketList.serviceTickets'}
                totalKeyPath={'component.serviceTicketList.total'}/>

        </div>
    }
}


export default compose(
    graphql(ComponentServiceTicketsListQuery, {
        name: 'componentServiceTicketsListQuery',
        options: (props: Props) => ({
            variables: {
                filter: objectToFilter({
                    serviceTicketStateIndex: _.get(props.filter, 'serviceTicketStateIndex'),
                    serviceTicketTypeIndex: _.get(props.filter, 'serviceTicketTypeIndex'),
                }),
                componentId: props.componentId,
                direct: props.direct,
                children: props.children,
                // children: _.get(props.filter,'children')
            }
        }),
    }),
    waitWhileLoading('componentServiceTicketsListQuery'),


    withProps((props) => ({
        serviceTickets: _.get(props, 'componentServiceTicketsListQuery.component.serviceTicketList.serviceTickets', [])
    })),
    withTranslation(),
)(ComponentServiceTicketsList);
