import React from 'react';
import {useParams} from "react-router-dom";
import ClientAccessRequestDashboard from "../../components/ClientAccessRequestDashboard";

interface Props {

}

const ClientAccessRequestDetailRoute = ({}: Props) => {

    const {id} = useParams();

    console.log(id, 'ID')

    return <ClientAccessRequestDashboard id={id} />
}

export default ClientAccessRequestDetailRoute;
