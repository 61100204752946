import React from "react";

const SvgNews = props => (
    <svg viewBox="0 0 150 100" width={props.width} {...props}>
        <path fill={props.color} d="M0 0h150v100H0z"/>
        <path
            d="M130 29H22V16h108v13zm-85 8H22v49h23V37zm41 0H53v4h33v-4zm0 9H53v4h33v-4zm0 9H53v4h33v-4zm0 9H53v4h33v-4zm0 9H53v4h33v-4zm0 9H53v4h33v-4zm44-45H97v4h33v-4zm0 9H97v4h33v-4zm0 9H97v4h33v-4zm0 9H97v4h33v-4zm0 9H97v4h33v-4zm0 9H97v4h33v-4z"
            fill="#fff"
        />
    </svg>
);

export default SvgNews;