// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from "lodash";
import StarredComponentsQuery from "../../graphql/queries/starredComponents.graphql";
import ComponentImage from "../ComponentImage";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import OneLineLabel from "../OneLineLabel";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import FillQueryTable from "../QueryTable/fill";

type Props = {
    showPath?: boolean,
}

class StarredComponentsList extends Component<Props, State> {

    static defaultProps = {
        showPath: true,
    }

    render() {
        const {starredComponentsQuery, t, history, showPath} = this.props;

        return <FillQueryTable
            className={"link-table table-layout-fixed"}
            query={starredComponentsQuery}
            size={"middle"}
            onRow={(record, rowIndex) => ({
                onClick: (event) => history.push(`/infrastructure/${record.id}`)
            })}
            itemsKeyPath={'starredComponentList.components'}
            totalKeyPath={'starredComponentList.total'}
            columns={[{
                key: 'image',
                dataIndex: 'image',
                width: 70,
                render: (text, item) => <ComponentImage size={50} link={_.get(text, 'thumbnailLink')}/>
            }, {
                key: 'name',
                dataIndex: 'name',
                title: t('Name'),
                width: "40%",
                render: (text, item) => <div>
                    <div>{item.name || '-'}</div>
                    {showPath ? <OneLineLabel><ComponentPathBreadcrumb path={item.path}/></OneLineLabel> : null}
                </div>
            }, {
                key: 'manufacturer',
                dataIndex: 'manufacturer',
                title: t('Manufacturer'),
                render: (text, item) => <div>
                    <div>{item.product && item.product.manufacturer ? item.product.manufacturer.name : ''}</div>
                </div>
            }, {
                key: 'serialNo',
                dataIndex: 'serialNo',
                title: t('Serial')
            }, {
                key: 'catalogNo',
                dataIndex: 'product.catalogNo',
                title: t('Catalog No'),
                render: (_text, item) => _.get(item, 'product.catalogNo', _.get(item, 'catalogNo'))
            }]}
        />
    }
}

export default compose(
    graphql(StarredComponentsQuery, {
        name: 'starredComponentsQuery',
        options: (props: Props) => ({
            variables: {
                thumbnailWidth: 50,
                thumbnailHeight: 50
            }
        }),
    }),
    withRouter,
    withTranslation(),
)(StarredComponentsList);
