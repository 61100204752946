import React, {useState} from 'react';
import _ from "lodash";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {useLazyQuery, useQuery} from "@apollo/client";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql"
import {useTranslation} from "react-i18next";
import ClientSearchQuery from "../../graphql/queries/clientSearch.graphql";
import ClientSuggestionItem from "../ClientSuggestionItem";
import {Button} from "antd";
import Loader from "../Loader";

interface Props {
    onNextStep: () => void,
    searchClientQuery: string,
    isServiceProvider: boolean
}

const CreateClientAddressStep = ({onNextStep, client, addressData, setClient, setAddressData, searchClientQuery, isServiceProvider}: Props) => {

    const {t} = useTranslation();

    const [listViewActive, setListViewActive] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [clientSearch, {loading, data, error}] = useLazyQuery(ClientSearchQuery)

    const attributesFromProps = _.get(client, 'typeDefinitionAttributeList.typeDefinitionAttributes', null)
    const typeDefinitionAttributesQuery = useQuery(TypeDefinitionAttributeListQuery, {
        variables: {
            index: "client"
        },
        skip: attributesFromProps
    })

    if (typeDefinitionAttributesQuery?.loading) {
        return <Loader />
    }

    let attributes = attributesFromProps ?
        attributesFromProps
        : _.get(typeDefinitionAttributesQuery, 'data.typeDefinitionAttributeList.typeDefinitionAttributes')
    ;

    attributes = attributes.filter(x => x.categories.includes("client") || x.categories.includes("default"))

    const onSubmit = (values) => {
        const valuesObj = _.get(values, 'variables', {});
        setAddressData(valuesObj);
        setCompanyName(_.get(valuesObj, 'name'))

        if (isServiceProvider) {
            clientSearch({
                variables: {
                    params: {
                        search: {
                            extended: {
                                name: _.get(valuesObj, 'name'),
                                street: _.get(valuesObj, 'street'),
                                number: _.get(valuesObj, 'number'),
                                city: _.get(valuesObj, 'city'),
                                zip: _.get(valuesObj, 'zip'),
                            }
                        }
                    }
                }
            }).then((res) => {
                const clients = _.get(res, 'data.clientSearch.clients', []);

                if (clients.length > 0) {
                    setListViewActive(true)
                } else {
                    onNextStep()
                }
            })
        } else {
            onNextStep()
        }

    }

    const onClientItemClick = (client) => {
        setClient(client)
        onNextStep()
    }

    const list = _.get(data, 'clientSearch.clients', [])

    if (listViewActive && !loading) {
        return <div>
            {
                list.length > 0 ?
                    <div className={"mt-10"}>{t("similarClientHint")}</div>
                    : null
            }
            {
                list.map((client) => {
                    const clientStreet = _.get(client, 'street', '')
                    const clientNumber = _.get(client, 'number', '')
                    const clientZip = _.get(client, 'zip', '')
                    const clientCity = _.get(client, 'city', '')
                    const subtitle = `${clientStreet} ${clientNumber}, ${clientZip} ${clientCity}`

                    return <ClientSuggestionItem
                        key={client.id}
                        className={'mt-10'}
                        title={_.get(client, 'name')}
                        subtitle={subtitle}
                        imageSrc={_.get(client, 'avatar.thumbnailLink')}
                        onClick={() => onClientItemClick(client)}
                    />
                })
            }
            <div className={"mt-20"}>
                <span>{t("or create a new client")}:</span>
                <ClientSuggestionItem
                    className={"mt-10"}
                    title={companyName}
                    subtitle={t("register as a new client in the system")}
                    imageSrc={null}
                    onClick={onNextStep}
                />
            </div>

            <Button className={"mt-10"} type="link" onClick={() => setListViewActive(false)}>
                {'< ' + t('Back')}
            </Button>
        </div>

    }

    let attributesModified = _.cloneDeep(attributes)
    const nameIndex = attributesModified.findIndex((attr => attr.index === "name"));

    if (!attributesFromProps) {
        attributesModified[nameIndex].value = searchClientQuery
    }

    return <div className={"w-75"} style={{margin: "0 auto"}}>
        {
            attributesFromProps ?
                <div className={'mb-20'}>
                    {t('Please check the client data for correctness. If you wanted to create another client, take another step back.')}
                </div>
            :
                <div className={'mb-20'}>
                    {t('Please fill out the client details to create a new client in the system. The data can also be added later.')}
                </div>
        }
        <AttributesInlineForm
            attributes={attributesModified}
            mutation={(values) => {
                onSubmit(values)
            }}
            useSubmit
            submitTitle={t('Next')}
        />
    </div>
}

export default CreateClientAddressStep;
