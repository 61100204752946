import React from "react";
import {ThemeContext} from "../provider/theme";
import {compose, fromRenderProps} from "recompose";
import Themes from "../provider/theme/themes";
import Logos from "../provider/theme/logos";

export default () => compose(
    fromRenderProps(ThemeContext.Consumer, ({ theme }) => {

        const themeConfig = {
            ...Themes.vsm,
            ...Themes[theme],
            ...Logos[theme],
        }


        return {
            themeConfig,
            theme
        }
    }),

)
