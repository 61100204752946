import React from "react";
import Toolbar from "../../../components/Toolbar";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import ServiceReportList from "../../../components/ServiceReportList";
import _ from "lodash";
import {useTranslation} from "react-i18next";

interface Props {

}

const ServiceReportListRoute = (props: Props) => {

    const history = useHistory();
    const {t} = useTranslation();
    const match = useRouteMatch();
    const serviceTicketId = _.get(match, 'params.id');


    return  <div><Toolbar renderRight={() => <Link to={`/service/${serviceTicketId}/serviceReports/create`}><Button>{t('Add')}</Button></Link>}/>
        <ServiceReportList onServiceReportRowClick={(row) => {
            return history.push(`/service/${serviceTicketId}/serviceReports/${row.id}`)
        }}/>
    </div>;
};

export default ServiceReportListRoute;
