import React from 'react';
import {Avatar} from "antd";
import _ from "lodash";
import "./index.css";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

interface Props {
    title: string,
    subtitle: string,
    imageSrc: string,
    onClick?: () => void
}

const ClientSuggestionItem = ({title, subtitle, imageSrc, onClick, className}: Props) => {

    return (
        <div className={`client-suggestion-item ${className}`} onClick={onClick || null}>
            <Avatar src={imageSrc} />
            <div className={"client-suggestion-content"}>
                <div className={"title"}>{title}</div>
                <div className={"subtitle"}>{subtitle}</div>
            </div>
            <MaterialCommunityIcon className={"icon"} name={"chevron-right"} />
        </div>
    );
}

export default ClientSuggestionItem;
