// @flow
import React, { useState } from 'react';
import VSMPanel from "../../components/VSMPanel";
import {Row, Col} from "../../components/Flex";
import Logos from "../../provider/theme/logos";
import _ from 'lodash';
import {useTranslation} from "react-i18next";
import TokenInput from "../../components/TokenInput";
import {Link, useParams, useHistory} from "react-router-dom";
import {Divider} from "antd";
import {Button} from "@dreebit/react-components";
import useTheme from "../../hooks/useTheme";
import './index.less';
import {SizeMe} from "react-sizeme";

type Props = {

}

const TokenLandingPageContent = ({}: Props) => {
  let { token } = useParams();
  let { theme } = useTheme();
  const history = useHistory();
  let { t, i18n } = useTranslation();

  const [valid, setValidation] = useState(!!(token && token.length === 25));

  let lang = i18n.language;
  try {
    lang = _.first(lang.split('-'));
  }
  catch (e) {
    lang = 'en';
  }

  return <SizeMe
      refreshMode={"debounce"}
      monitorHeight
  >
    {({size}) => {
      return <Row className={`full-height full-width overflow-auto ${size.width < 670 ? 'tokenMobileWrapper' : 'tokenWrapper'}`}>
        <VSMPanel>
          <Col className={'full-width full-height flex-center flex-align-items-center'}>
            <img src={_.get(Logos[theme], '@big-logo', Logos[theme]['@navigation-logo'])}
                 width={260}
            />

            <h2 className={'pt-5'} style={{textAlign: 'center', fontWeight: 500}}>{t('welcomeVSM')}</h2>
            <span style={{maxWidth: 550, textAlign: 'center'}}>{t('cmtLandingText')}</span>

            <TokenInput
                className={'pv-25 full-width'}
                token={token}
                showError={!valid}
                showSuccess={valid}
                successMessage={t('Please log in to use the token.')}
                onChange={(newToken, valid) => {
                  history.push(`/infrastructure/cmt/${newToken}`);
                  setValidation(valid);
                }}
            />

            {
              valid
                  ? <>
                    <Link to={`/login?redirect=/infrastructure/cmt/${token}`} className={`login-button`}>
                      <Button type={'primary'}
                              style={{
                                width: 250,
                                height: 38
                              }}
                      >
                        {t('Login')}
                      </Button>
                    </Link>
                    <Divider/>
                    <a href={`/${lang}/register?redirect=/infrastructure/cmt/${token}`}
                    >
                      <Button type={'link'}>
                        {t('Create new Account')}
                      </Button>
                    </a>
                  </>
                  : null
            }
          </Col>
        </VSMPanel>
      </Row>
    }}
  </SizeMe>;
};

export default TokenLandingPageContent;
