// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {Query} from "@apollo/client/react/components";
import Loader from "./../Loader";
import ComponentTCOQuery from "../../graphql/queries/componentTCO.graphql";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {Line, LineChart, XAxis, YAxis} from "recharts";
import {withSize} from "react-sizeme";
import moment from "moment";
import Error from "../Error";
import withTheme from "../../hoc/withTheme";

type Props = {

};

type State = {
    dateRange: string[],
}

export function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
}


class ComponentTCOHistoryChart extends Component<Props, State> {

    state = {
        date: [addMonths(new Date(), -6).toISOString(),new Date().toISOString()]
    }

  render() {
    const { size, t, tcoSettings, theme } = this.props;

    return <div>

        <Query query={ComponentTCOQuery} variables={{
            id: this.props.componentId,
            filter: {
                date: {
                    operator: "range",
                    range: {
                        from: _.first(this.state.date),
                        to: _.last(this.state.date)
                    }
                }
            },
            ...tcoSettings
        }}>
            {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error) return <Error error={error}/>;

                const entries =  _.get(data,'component.tco.componentTcoValueList.tcoValues',[]).map((item) => ({
                    value: item.value,
                    date: new Date(item.date).getTime()
                }))

                return <LineChart
                    width={size.width}
                    height={300}
                    data={entries}
                >
                    <XAxis
                        scale={'time'}
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        dataKey={"date"}
                        tickFormatter={(val) => moment(val).format('DD.MM.YYYY')}
                    />
                    <YAxis
                        dataKey={"value"}
                    />
                    <Line type="monotone" dataKey="value" stroke={theme["@primary-color"]} />

                </LineChart>
            }}
        </Query>


    </div>;
  }
}


export default compose(

    /*
    graphql(ComponentTCOQuery,{
        name: 'componentTCOQuery',
        options: (props) => ({
            variables: {
                id: props.componentId,
                ...props.tcoSettings
            }
        } )
    }),
    withProps((props) => ({
        data: _.get(props,'componentTCOQuery.tco.componentTcoValueList.tcoValues',[]).map((item) => ({
            value: item.value,
            date: new Date(item.date).getTime()
        }))
    })),

     */
    withTranslation(),
    withSize(),
    withTheme()
)(ComponentTCOHistoryChart);

