// @flow
import React, {Component} from 'react';
import moment from "moment";
import FillQueryTable from "../QueryTable/fill";
import InvoiceListQuery from "../../graphql/queries/invoiceList.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import withResources from "../../hoc/withResources";

type Props = {
  t: Function,
  history: {
    push: Function
  },
};


class InvoiceList extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { invoiceListQuery, t, history } = this.props;

    const invoiceListColumns = [
      {
        dataIndex: 'insert',
        title: t('Date'),
        key: 'insert',
        width: '15%',
        render: text => moment(text).format('L')
      },
      {
        dataIndex: 'name',
        title: t('Name'),
        key: 'name'
      },
      {
        dataIndex: 'totalAmount',
        title: t('Total amount in Euro'),
        key: 'totalAmount',
        width: '15%',
      }
    ];


    return <div className={"full-size"}>
      <FillQueryTable
          className={"link-table"}
          size={'middle'}
          query={invoiceListQuery}
          itemsKeyPath={'invoiceList.invoices'}
          totalKeyPath={'invoiceList.total'}
          rowKey={"invoices.id"}
          columns={invoiceListColumns}
          onRow={(record) => {
            return {
            onClick: () => {
              history.push(`/admin/invoices/${record.id}`)
            },
          };
        }}
      />
    </div>;
  }
}

const getParams = (filter) => {
  const params = {};

  if (filter.insert) {
    const dateFrom = new Date(_.get(filter, 'insert')[0]);
    const dateTo = new Date(_.get(filter, 'insert')[1]);

    _.set(params, 'filter.insert', {
      operator: 'range',
      range: {
        from: dateFrom.toISOString(),
        to: dateTo.toISOString()
      }
    })
  }

  return params;
};

export default compose(
  withResources('accounting'),
  withRouter,
  withTranslation(),
  graphql(InvoiceListQuery, {
    name: 'invoiceListQuery',
    options: props => ({
      variables: {
        params: {
          ...getParams(props.filterValues),
          start: 0,
          limit: 30,
        }
      }
    }),
  }),
  waitWhileLoading('invoiceListQuery', 'invoiceList')
)(InvoiceList);

