// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import {Col, Row} from "antd";
import ComponentTdaQuery from "../../graphql/queries/component-FormAttributes.graphql";
import Panel from "../../components/Panel";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Error from "../../components/Error";
import VersionalAttributeCreateEntryForm from "../../components/VersionalAttributeCreateEntryForm";
import {withTranslation} from "react-i18next";
import VersionableChart from "../../components/VersionableChart";
import VersionableTable from "../../components/VersionableTable";

type Props = {

};

type State = {

}

class ComponentVersionalAttributeRoute extends Component<Props, State> {

  render() {
    const { t, index, componentId, tda } = this.props;

    if (!tda){
        return <Error status={404}/>
    }

    const showChart = ['int', 'float'].indexOf(tda.type) !== -1;


    return <Row gutter={16} className={"p-10"}>
        { showChart ? <Col md={24}>
            <Panel title={tda.name}>
                <VersionableChart tda={tda} index={index} componentId={componentId} />
            </Panel>
        </Col>: null}
        <Col md={16} >
            <Panel containerStyle={{height: 500}} title={!showChart ? tda.name : t('Data list')}>
                <VersionableTable index={index} title={_.get(tda,'name')} componentId={componentId}/>
            </Panel>
        </Col>
        <Col md={8} >
            <Panel title={t('New entry')}>
                <VersionalAttributeCreateEntryForm componentId={componentId} tda={tda}/>
            </Panel>

        </Col>
    </Row>
  }
}

export default compose(
    withProps((props) => ({
      index: _.get(props,'match.params.index'),
      componentId: _.get(props,'match.params.id'),
    })),
    graphql(ComponentTdaQuery, {
        name: 'componentTdaQuery',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentTdaQuery'),
    withProps((props) => ({
        tda: _.find(_.get(props,'componentTdaQuery.component.typeDefinitionAttributeList.typeDefinitionAttributes',[]),{
            index: props.index
        })
    })),
    withTranslation()
)(ComponentVersionalAttributeRoute);
