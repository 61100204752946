// @flow
import React from "react"
import {Spin, Tabs} from "antd";
import {Route, Switch, useHistory} from "react-router-dom";
import {useQuery} from "@apollo/client";
import ContentHeader from "../../../components/Typo/ContentHeader";
import ClientForm from "../../../components/ClientForm/index";
import ClientDetailsQuery from "../../../graphql/queries/clientDetails.graphql";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import "./styles.css"
import ClientUsersDashboard from "../../../components/ClientUsersDashboard";
import './styles.less';
import ClientAccessList from "../../../components/ClientAccessList";
import useProfile from "../../../hooks/useProfile";
import useResourceCheck from "../../../hooks/useResourceCheck";
import Error from "../../../components/Error";
import Empty from "../../../components/Empty";

interface Props {
    clientId: number,
    tab: string
}

const ClientDashboard = (props: Props) => {
    const history = useHistory();
    const profile = useProfile()
    const canAdministrateClients = useResourceCheck("administrateClients");
    const {t} = useTranslation();
    const clientDetailsQuery = useQuery(ClientDetailsQuery, {
        variables: {
            id: props.clientId
        }
    })

    if (clientDetailsQuery.loading) {
        return <Spin/>
    }

    const handleTabChange = (key) => {
        history.push(`/admin/clients/${props.clientId}/${key}`)
    };

    const client = _.get(clientDetailsQuery, "data.client")
    const currentUserClientId = _.get(profile, 'client.id')
    const hasActivatedClient = _.get(client, 'activatedBy.id') === _.get(profile, 'client.defaultServiceProvider.id')
    const defaultServiceProviderId = _.get(profile, 'client.defaultServiceProvider.id')

    let items = [
        {
            label: t('Core Data'),
            key: 'form',
        }
    ];

    if (_.get(client, 'active') && _.get(client, 'component.id')) {
        if (canAdministrateClients && hasActivatedClient) {
            items.push({
                label: t('Admins'),
                key: 'admins',
            });
        }

        items.push({
            label: t('Access'),
            key: 'access',
        });
    }

    if (!client) {
        const description = clientDetailsQuery.error && _.get(clientDetailsQuery, 'error.message');
        return <Error status={'404'} description={description}/>
    }

    const clientActiveTabs = <>
        {/*<Route path="/admin/clients/:id/modules" component={() => <ClientModules clientId={clientId}/>}/>*/}
        {/*<Route path="/admin/clients/:id/roles" component={() => <ClientRoleList clientId={clientId}/>} />*/}
        <Route path="/admin/clients/:id/form" component={() => <ClientForm clientId={props.clientId} currentUserClientId={currentUserClientId} />}/>
        <Route path="/admin/clients/:id/admins" component={() => <ClientUsersDashboard clientId={props.clientId} />}/>
        <Route path="/admin/clients/:id/access" component={() => <ClientAccessList clientId={props.clientId} defaultServiceProviderId={defaultServiceProviderId} />}/>
    </>

    const clientInactiveTabs = <>
        <Route path="/admin/clients/:id/form" component={() => <ClientForm clientId={props.clientId} currentUserClientId={currentUserClientId} />}/>
    </>

    return <div>
        <ContentHeader title={client.name}/>
        <div className={'full-height-from-content-header bg-light-grey'}>
            <Tabs
                activeKey={props.tab}
                onChange={handleTabChange}
                items={items}
            />
            <Switch>
                {(client.active && client.component?.id) ? clientActiveTabs : clientInactiveTabs}
            </Switch>
        </div>
    </div>;
}

export default ClientDashboard;
