// @flow
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import EditableCell from "../Utilities/EditableCell";
import {useMutation} from "@apollo/client";
import updateBookmark from "../../graphql/mutations/updateBookmark.graphql";
import BookmarkListQuery from "../../graphql/queries/bookmarkList.graphql";
import {Popconfirm, Switch, Tag} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import deleteBookmark from "../../graphql/mutations/deleteBookmark.graphql";
import {bookmarkListVariables} from "../BookmarkList";

type Props = {
    bookmark: any,
    onClose: Function
}

const BookmarkDetailModalHeader = (props: Props) => {

    const { bookmark, onClose } = props;
    const {t} = useTranslation();

    const [isUpdating, setUpdating] = useState(false);

    const [updateBookmarkMutation] = useMutation(updateBookmark, {
        refetchQueries: [
            {
                query: BookmarkListQuery,
                variables: bookmarkListVariables(['serviceTicketSearch','serviceTicketSelection'])
            },{
                query: BookmarkListQuery,
                variables: bookmarkListVariables(['infrastructureSearch','componentSelection'])
            }
        ]
    });

    const [deleteBookmarkMutation] = useMutation(deleteBookmark, {
        refetchQueries: [
            {
                query: BookmarkListQuery,
                variables: bookmarkListVariables(['serviceTicketSearch','serviceTicketSelection'])
            },{
                query: BookmarkListQuery,
                variables: bookmarkListVariables(['infrastructureSearch','componentSelection'])
            }
        ]
    });

    const _handleDelete = () => {
        deleteBookmarkMutation({
            variables: {
                id: bookmark.id
            }
        })
            .then(() => {
                onClose();
            })
    };

    const _handleChange = (changes: string) => {
        setUpdating(true)

        return updateBookmarkMutation({
            variables: {
                id: bookmark.id,
                input: changes
            }
        }).then(() => {
            // The switch takes some time to get the new data and re-render, so we added a longer loading animation as user feedback
            setTimeout(()=> {
                setUpdating(false)
            }, 1000)
        })
    };

    return <div className={'full-width full-height flex-row flex-space-between pr-20'}>
        <div className={'flex-row flex-grow'}>
            {
                bookmark.access.write
                    ? <EditableCell
                        className={'mh-20'}
                        value={bookmark.title}
                        onChange={(title: string) => _handleChange({title})}
                    />
                    : bookmark.title
            }
        </div>
        <div className={'flex-row'}>
            {
                bookmark.access.write
                    ? <Switch
                        checked={bookmark.public}
                        loading={isUpdating}
                        className={'flex-self-center'}
                        checkedChildren={t('public')}
                        unCheckedChildren={t('private')}
                        onChange={(checked: boolean) => {
                            _handleChange({public: checked})
                        }}
                    />
                    : <Tag color={'#238cff'}>
                        {t('public')}
                    </Tag>
            }
            {
                bookmark.access.remove
                    ? <Popconfirm placement="bottomRight"
                                  title={t("Are you sure delete this bookmark?")}
                                  onConfirm={_handleDelete}
                                  okText={t("Yes")}
                                  cancelText={t("No")}
                                  icon={
                                      <Icon type="question-circle-o" style={{color: 'red'}}/>
                                  }
                    >
                        <Button type="danger"
                                icon="delete"
                                style={{marginLeft: 20}}
                        >
                            {t('Delete')}
                        </Button>
                    </Popconfirm>
                    : null
            }
        </div>
    </div>
};

export default BookmarkDetailModalHeader
