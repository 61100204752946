import React from "react";
import logo from "../../assets/logo/virtual-service-management-w240.png";
import useTheme from "../../hooks/useTheme";

interface Props {
    style?: any
}

const Logo = ({style}: Props) => {

    const {themeConfig} = useTheme();

    const loginLogo = themeConfig["@login-logo"] || logo;

    return <img style={style} className="logo" alt={'logo'} src={loginLogo}/>
}

export default Logo;
