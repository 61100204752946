import React, {useEffect, useState} from "react";
import KeycloakService from "../service/keycloak";
import VSMLoadingAnimation from "../components/VSMLoadingAnimation";
import {useDispatch} from "react-redux";
import {login as saveLogin} from "../actions/auth";
import _ from "lodash";
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import {Loader} from "@dreebit/react-components";

interface Props {
    directKeycloakLogin?: boolean,
    language?: string,
    onSetUserRole?: (role: string) => void,
}

const KeycloakProvider = ({children, directKeycloakLogin, language, onSetUserRole}: Props) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);

    const history = useHistory();


    useEffect(()=> {
        const keycloak = KeycloakService.getInstance();
        if (keycloak) {
            setLoading(true);

            try {
                KeycloakService.init().then(function (authenticated) {
                    if (authenticated) {
                        return KeycloakService.performVSMLogin()
                            .then((result) => {
                                const token = result.access_token;
                                const user = result.user;

                                if (result.errors && result.registrationStatus?.code) {
                                    onSetUserRole(result.registrationStatus.code)
                                }

                                dispatch(saveLogin(user, token));
                                setTimeout(() => {
                                    setLoading(false)
                                },0)


                            })
                    } else if (directKeycloakLogin) {
                        return keycloak.login({
                            locale: language || "en"
                        })
                    } else {
                        setLoading(false);
                    }
                }).catch(function(err) {
                    console.error(err);
                    setLoading(false);
                });
            }catch (err){
                console.error(err);
                setLoading(false);
            }


        }
    },[])

    if (loading){
        return <VSMLoadingAnimation/>
    }

    return children;
}

export default KeycloakProvider;
