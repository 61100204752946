// @flow
import React from 'react';
import TDAAttributesForm from "../TDAAttributesForm";
import './index.css';

export default (props) => {

    return <TDAAttributesForm
        cacheChanges={props.cacheChanges}
        submit={props.useSubmit}
        collapse={props.collapse}
        editable={props.editable || props.inline}
        submitOnChange={props.submitOnChange || props.editable || props.inline}
        onChange={props.onChange || props.onValuesChange}
        values={props.values}
        {...props}
        attributes={props.attributes}
    />
}
