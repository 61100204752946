// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import {Link, withRouter} from "react-router-dom";
import {compose, withProps} from "recompose";
import {Button} from "@dreebit/react-components";
import ComponentServiceTicketsList from "./../../components/ComponentServiceTicketsList";
import Toolbar from "../../components/Toolbar";
import ComponentServiceTicketFilter from "../../components/ComponentServiceTicketFilter";
import queryString from "query-string";
import parseSearchQueryParams from "../../utils/searchParams";

type Props = {
    history: Function
}

class ComponentServiceTicketsRoute extends Component<Props, State> {

    onFilterChange = (params) => {
        if (!params) {
            this.props.history.push({
                search: ``
            })
        } else {
            const query = queryString.stringify(params);
            this.props.history.push({
                search: `?${query}`
            })
        }
    };

    render() {
        const {t, history, filter} = this.props;

        const componentId = _.get(this.props, 'match.params.id');

        return <div>
            <div className={"ph-15"}>
                <h2>{t('Service Tickets')}</h2>
            </div>
            <Toolbar
                renderRight={() =>
                    <Link to={`/infrastructure/${componentId}/servicetickets/new`}>
                        <Button icon={"plus"} data-cy={'TEST_10001'} type={'primary'}>
                            {t('Add Serviceticket')}
                        </Button>
                    </Link>
                }
                renderLeft={() =>
                    <ComponentServiceTicketFilter params={filter} onChange={this.onFilterChange}/>
                }
            />

            <ComponentServiceTicketsList componentId={componentId}
                                         filter={filter}
                                         children={false}
                                         direct={true}
                                         onClick={(record) => history.push(`/service/${record.id}`)}/>

            {
                filter.children ? <div>
                    <h3 style={{margin: '10px 16px 5px'}}>{t("Service tickets of sub components")}</h3>
                    <ComponentServiceTicketsList componentId={componentId}
                                                 filter={filter}
                                                 children={true}
                                                 direct={false}
                                                 onClick={(record) => history.push(`/service/${record.id}`)}/>
                </div> : null
            }

        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        let query = _.get(props, 'history.location.search');
        return {
            filter: parseSearchQueryParams(query)
        }
    }),
)(ComponentServiceTicketsRoute);
