//@flow

import React, {Component} from "react"
import LogEntryDetailed from "../LogEntryDetailed"
import _ from 'lodash';
import moment from 'moment';
import './index.css'
import type {LogItem} from "../../types";


type Props = {
    logs: LogItem,
    onRemoved: (log: LogItem) => void,
    componentId: string,
    refetchQueries?: any[]
};

export default class LogDetailView extends Component<Props> {

    render() {

        const {logs, onRemoved, componentId, refetchQueries} = this.props;

        const logsByDate = _.groupBy(logs, (item) => {
            return moment(item.insert).format('LL');
        });


        const result = Object.keys(logsByDate).reduce((acc, key) => {
            acc = [...acc, {
                type: "section",
                day: key
            }, ...logsByDate[key].map((item) => ({
                ...item,
                type: "log"
            }))];
            return acc;
        }, []);


        return <div className="timeline-wrapper">
            {result.map((logs, index) => {
                if (result[index].type === "section") {
                    return <div className="date-section" key={index}>
                        {result[index].day}
                    </div>
                } else if (result[index].type === "log") {
                    return <LogEntryDetailed componentId={componentId}
                                             onRemoved={onRemoved ? () => onRemoved(result[index]) : null} key={index}
                                             refetchQueries={refetchQueries}
                                             log={result[index]}/>
                }
                return null
            })}
        </div>
    }

}

