// @flow
import React, {Component} from 'react';
import _ from "lodash";
import NavigationContainer from "../../components/NavigationContainer";
import ContentHeader from "../../components/Typo/ContentHeader";
import HostDetailQuery from "./../../graphql/queries/HostDetailQuery.graphql"
import {compose} from "recompose";
import withResources from "../../hoc/withResources";
import {withTranslation} from "react-i18next";
import {Route, withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ClientUsersDashboard from "../../components/ClientUsersDashboard";
import {Tabs} from "antd";
import HostForm from "../../components/HostForm";
import HostModules from "../../components/HostModules";
import ClientRoleList from "../../components/ClientRolesList";

type Props = {

};

type State = {

}

const TabPane = Tabs.TabPane;

class HostDetailRoute extends Component<Props, State> {

    handleTabChange = (key) => {
        const hostId = _.get(this.props,'match.params.id');
        if (hostId) this.props.history.push(`/admin/hosts/${hostId}/${key}`)
    };

  render() {
    const { t, hostDetailQuery, match } = this.props;

    const tab = _.get(match, 'params.tab', 'form');
    const hostId = _.get(match,'params.id');

      const host = _.get(hostDetailQuery, "host");

      let tabs = [<TabPane tab={t('Core Data')} key="form">
          <Route path="/admin/hosts/:id/form" render={() => <HostForm hostId={hostId}/>}/>
      </TabPane>];
      if (host.active) {

          tabs.push(<TabPane tab={t('Modules')} key="modules">
              <Route path="/admin/hosts/:id/modules" component={() => <HostModules hostId={hostId}/>}/>
          </TabPane>);
          tabs.push(<TabPane tab={t('Roles')} key="roles">
              <div className={'full-height'}><Route path="/admin/hosts/:id/roles"
                                                    component={() => <ClientRoleList clientId={hostId}/>}/></div>
          </TabPane>);
          tabs.push(<TabPane tab={t('User')} key="user">
              <Route path="/admin/hosts/:id/user" component={() => <ClientUsersDashboard clientId={hostId}/>}/>
          </TabPane>);

      }

      return <NavigationContainer scroll navigationBar={<div><ContentHeader title={host.name}/></div>}>

          <Tabs
              className={'full-height'}
              activeKey={tab}
              onChange={this.handleTabChange}>
              {tabs}
          </Tabs>

      </NavigationContainer>;
  }
}

export default compose(
    withResources('hostAdministration'),
    withRouter,
    graphql(HostDetailQuery,{
        name: 'hostDetailQuery',
        options: props => {
            return {
                variables: {
                    id: _.get(props,'match.params.id')
                }
            }
        }
    }),
    waitWhileLoading('hostDetailQuery'),
    withTranslation(),
)(HostDetailRoute);
