import React from "react";
import {Carousel, List} from "antd";
import OneLineLabel from "../OneLineLabel";
import ReactMarkdown from "react-markdown";
import Loader from "../Loader";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import NewsListQuery from "../../graphql/queries/NewsList.graphql";
import _ from "lodash";

interface Props {

}

const NewsSlider = (props: Props) => {

    const {t} = useTranslation();
    const query = useQuery(NewsListQuery, {
        variables: {
            params: {
                limit: 5,
                start: 0
            }
        }
    })

    if (query.loading) return <Loader/>

    const data = _.get(query,'data.newsList.news',[])

    return <Carousel dots={true}>

        {data.map((item: any) => {
            return <List.Item
                className={"ph-10"}
                key={item.id}>
                <List.Item.Meta
                    title={item.title}
                    description={<OneLineLabel><ReactMarkdown>{item.text}</ReactMarkdown></OneLineLabel>}
                />
            </List.Item>
        })}

    </Carousel>;
}

export default NewsSlider;
