import React from 'react';
import {Tabs} from 'antd';
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Col} from "../../components/Flex";
import StatisticsDashboard from "../../components/StatisticsDashboard";
import ContentHeader from "../../components/Typo/ContentHeader";
import _ from "lodash";
import './index.css';
import StatisticsServiceProvider from "../../components/StatisticsServiceProvider";
import StatisticsComponentTypes from "../../components/StatisticsComponentTypes";
import StatisticsHistory from "../../components/StatisticsHistory";

const {TabPane} = Tabs;

type Props = {};

const StatisticsRoute = ({}: Props) => {
    const {tab} = useParams();
    const history = useHistory();
    const {t} = useTranslation();

    return <Col className={'full-height StatisticsRoute'}>
        <ContentHeader hideBreadcrumb
                       title={t('Statistics')}
        />

        <Tabs defaultActiveKey={tab}
              type="card"
              onChange={(key) => history.push(`/admin/statistics/${key}`)}
              className={'flex-grow-1 pt-15'}
              destroyInactiveTabPane={true}
        >
            <TabPane tab={t('Dashboard')}
                     key={'dashboard'}
                     className={'flex-grow-1 full-height'}
            >
                <StatisticsDashboard
                />
            </TabPane>

            <TabPane tab={t('History')}
                     key={'history'}
                     className={'full-height'}
            >
                <StatisticsHistory
                />
            </TabPane>

            <TabPane tab={t('Service Provider')}
                     key={'serviceProvider'}
                     className={'full-height'}
            >
                <StatisticsServiceProvider
                />
            </TabPane>

            <TabPane tab={t('Component Types')}
                     key={'componentTypes'}
                     className={'full-height'}
            >
                <StatisticsComponentTypes
                />
            </TabPane>
        </Tabs>

    </Col>
}

export const getFilterVariables = (filter: any): any => {
    if (!filter) return {}

    let queryFilter = {};

    Object.keys(filter).forEach((key: string) => {

        if (key === 'insert') {
            if (!filter.insert) return;

            _.set(queryFilter, 'insert.operator', 'range');
            _.set(queryFilter, 'insert.range', {
                from: _.first(filter.insert),
                to: _.last(filter.insert)
            });
            return;
        }

        if (key !== 'operator' && _.get(filter, key, []).length > 0) {
            _.set(queryFilter, key, {
                operator: _.get(filter, 'operator') ? 'nin' : 'in',
                value: _.get(filter, key, [])
            });

        }
    })

    return queryFilter;
}

export default StatisticsRoute;
