// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ProductLogHistoryHeader from "./header";
import ProductLogHistoryList from "./list";
import _ from "lodash";
import Enums from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";


type Props = {
  productId: string,
  enumsQuery: query,
}

class ProductHistoryPanel extends Component<Props> {

  render() {

    const {productId, enumsQuery} = this.props;

    const values = {
      logLevel: _.get(enumsQuery, '__type.enumValues').map((el) => {
        return el.name;
      })
    };

    return <div className="bg-white">
      <ProductLogHistoryHeader productId={productId}/>
      <ProductLogHistoryList productId={productId} values={values}/>
    </div>;
  }

}

export default compose(
  graphql(Enums, {
    name: 'enumsQuery',
    options: () => ({
      variables: {
        name: 'LogLevel'
      }
    }),
  }),
  waitWhileLoading('enumsQuery', '__type.enumValues'),
  withTranslation()
)(ProductHistoryPanel);
