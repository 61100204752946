// @flow
import React, {Component} from 'react';
import {List} from "antd";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';

import waitWhileLoading from "../../hoc/waitWhileLoading";
import QueryList from "../QueryList";
import ComponentTypeListQuery from "../../graphql/queries/componentTypeList.graphql";
import _ from 'lodash';

type Props = {
    componentTypeListQuery: query,
    value: boolean
}

class ComponentTypesList extends Component<Props> {

    render() {

        return <QueryList
            showSearchBar
            localSearch
            query={this.props.componentTypeListQuery}
            searchFields={["name"]}
            dataKeyPath={'componentTypeList'}
            itemsKeyPath={'componentTypeList.componentTypes'}
            totalKeyPath={'componentTypeList.total'}
            renderItem={(item) =>
                <a key={item.index} href={`#/catalog/componentTypes/detail/${item.index}`}>
                    <List.Item>
                        <List.Item.Meta
                            title={item.name}
                        />
                    </List.Item>
                </a>
            }
        />;
    }
}

export default compose(
    graphql(ComponentTypeListQuery, {
        name: 'componentTypeListQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: {
                        product: {
                            operator: 'in',
                            value: _.get(props, 'value',true)
                        }
                    },
                    sort: {
                        name: 'ASC'
                    }
                }
            }
        }),
    }),
    waitWhileLoading('componentTypeListQuery'),
)(ComponentTypesList)
