// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import queryString from "query-string";
import _ from "lodash";
import {connect} from "react-redux";
import {compose, withProps} from "recompose";
import InfrastructureSearch from "./../../components/InfrastructureSearch"
import {setInfrastructureSearchQuery} from "../../actions/settings";
import {withRouter} from "react-router-dom";
import withProfile from "../../hoc/withProfile";
import {graphql} from "@apollo/client/react/hoc";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import parseSearchQueryParams from "../../utils/searchParams";

type Props = {
    match: {
        params: any
    },
    history: {
        push: Function
    },
    t: Function,
}

class InfrastructureSearchRoute extends Component<Props, State> {


    render() {
        const {filter, defaultValues, setSearchQuery, isDefaultFilter} = this.props;

        return <InfrastructureSearch
            isDefaultFilter={isDefaultFilter}
            params={filter}
            onChange={(changes) => {
                if (!changes) {
                    const query = queryString.stringify(defaultValues);

                    setSearchQuery(query);
                    this.props.history.push({
                        search: query
                    })
                } else {

                    const params = {...filter, ...changes};
                    let values = Object.keys(params).reduce((acc, cur) => {
                        if (Array.isArray(params[cur]) && !params[cur].length) {
                            acc[cur] = null;
                        } else {
                            acc[cur] = params[cur];
                        }
                        return acc;
                    }, {})

                    const query = queryString.stringify(values, {skipNull: false});
                    setSearchQuery(query)

                    this.props.history.push({
                        search: `?${query}`
                    })
                }
            }}
        />
    }

}


export default compose(
    withRouter,
    withTranslation(),
    withProfile({
        fetchPolicy: 'cache-first'
    }),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props) => {
            return {
                variables: {
                    index: 'componentFilter'
                }
            }
        }
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery'),
    withProps((props) => {
        let query = _.get(props, 'history.location.search');
        return {
            query
        }
    }),
    connect((state, props) => {

        let query = props.query;
        if (!query || !query.length) {
            query = _.get(state, 'settings.infrastructureSearchQuery');
        }

        return {
            query,
        }
    }, dispatch => ({
        setSearchQuery: (query) => {
            return dispatch(setInfrastructureSearchQuery(query))
        }
    })),
    withProps((props) => {

        let defaultValues = {
            ..._.get(props, 'typeDefinitionAttributeListQuery.typeDefinitionAttributeList.typeDefinitionAttributes', []).reduce((acc, cur) => {
                if (cur.value) {
                    acc[_.get(cur, 'index')] = _.get(cur, 'value');
                }
                if (cur.addOnAttribute) {
                    acc[_.get(cur, 'addOnAttribute.index')] = _.get(cur, 'addOnAttribute.value');
                }
                return acc;
            }, {}),
        }

        return {
            defaultValues,
            queryParams: parseSearchQueryParams(props.query)
        }
    }),
    withProps((props) => {
        const filter = props.query && props.query.length ? props.queryParams : props.defaultValues;
        return {
            filter
        }
    })
)(InfrastructureSearchRoute);

