// @flow
import React, {Component} from 'react';
import FileUploadList from "../FileUploadList";
import type {FileCreateInput} from "../../types";
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import WarrantyReportFilesQuery from "../../graphql/queries/warrantyReportFiles.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AddWarrantyReportFilesMutation from "../../graphql/mutations/addWarrantyReportFiles.graphql";
import UpdateWarrantyReportFileMutation from "../../graphql/mutations/updateWarrantyReportFile.graphql";
import DeleteWarrantyReportFileMutation from "../../graphql/mutations/deleteWarrantyReportFile.graphql";

type Props = {
    serviceTicketId: string,
};

type State = {

}

class WarrantyReportFiles extends Component<Props, State> {

  render() {
      const {
          warrantyReportId,
          addWarrantyReportFilesMutation,
          updateWarrantyReportFileMutation,
          deleteWarrantyReportFileMutation,
          warrantyReportFilesQuery
      } = this.props;

      return <FileUploadList
          addFileMutation={addWarrantyReportFilesMutation}
          addFileMutationOptions={(fileInputs: FileCreateInput[]) => ({
              variables: {
                  warrantyReportId,
                  files: fileInputs
              }
          })}
          editFileMutation={updateWarrantyReportFileMutation}
          editFileMutationOptions={(file, input) => ({
              variables: {
                  warrantyReportId,
                  fileId: file.id,
                  input
              }
          })}
          removeFileMutation={deleteWarrantyReportFileMutation}
          removeFileMutationOptions={(file) => ({
              variables: {
                  warrantyReportId,
                  fileId: file.id
              }
          })}
          fileListQuery={warrantyReportFilesQuery}
          fileListKey={'serviceTicket.warrantyReport.warrantyReportFileList.warrantyReportFiles'}
      />;
  }
}

export default compose(
    withTranslation(),
    graphql(WarrantyReportFilesQuery, {
        name: 'warrantyReportFilesQuery',
        options: (props) => ({
            variables: {
                serviceTicketId: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('warrantyReportFilesQuery', ['serviceTicket.warrantyReport']),
    withProps((props) => ({
        warrantyReportId: _.get(props, 'warrantyReportFilesQuery.serviceTicket.warrantyReport.id')
    })),
    graphql(AddWarrantyReportFilesMutation, {
        name: 'addWarrantyReportFilesMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: WarrantyReportFilesQuery,
                    variables: {
                        serviceTicketId: props.serviceTicketId
                    }
                }
            ]
        })
    }),
    graphql(UpdateWarrantyReportFileMutation, {
        name: 'updateWarrantyReportFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: WarrantyReportFilesQuery,
                    variables: {
                        serviceTicketId: props.serviceTicketId
                    }
                }
            ]
        })
    }),
    graphql(DeleteWarrantyReportFileMutation, {
        name: 'deleteWarrantyReportFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: WarrantyReportFilesQuery,
                    variables: {
                        serviceTicketId: props.serviceTicketId
                    }
                }
            ]
        })
    }),
)(WarrantyReportFiles);
