import React from "react";
import ContentHeader from "../Typo/ContentHeader";
import {Col, Row} from "antd";
import ContentWrapper from "../ContentWrapper";
import Sizes from "../../themes/default/sizes";
import ServiceActionQuery from "./../../graphql/queries/serviceActionTypeDetails.graphql";
import Panel from "../Panel";
import Loader from "../Loader";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useQuery} from "@apollo/client";
import ServiceActionTypeRoleAccess from "../ServiceActionTypeRoleAccess";
import ServiceActionTypeInfo from "../ServiceActionTypeInfo";

interface Props {
    index: string
}

const ServiceActionTypeDashboard = (props: Props) => {

    const { t } = useTranslation();

    const query = useQuery(ServiceActionQuery, {
        variables: {
            index: props.index
        }
    })

    if (query.loading) return <Loader/>

    const serviceActionType = _.get(query,'data.serviceActionType')

    return <div>
        <ContentHeader title={_.get(serviceActionType,'name')}/>
        <ContentWrapper>
            <Row gutter={Sizes.grid}>
                <Col lg={12}>
                    <Panel title={t('Details')}>
                        <ServiceActionTypeInfo index={props.index}/>
                    </Panel>
                </Col>
                <Col lg={12}>
                    <Panel title={t('Roles')} >
                        <ServiceActionTypeRoleAccess serviceActionTypeIndex={props.index} serviceActionTypeId={_.get(serviceActionType,"id")} />
                    </Panel>
                </Col>
            </Row>

        </ContentWrapper>
    </div>
}

export default ServiceActionTypeDashboard;
