import React from "react";

const SvgNewInvoice = props => (
    <svg viewBox="0 0 150 100" width={props.width} {...props}>
        <path fill={props.color} d="M0 0h150v100H0z"/>
        <path
            d="M115.9 15.8H33.5c-4.7 0-8.5 3.8-8.5 8.5v51.2c0 4.7 3.8 8.5 8.5 8.5h82.4c4.7 0 8.5-3.8 8.5-8.5V24.3c0-4.7-3.9-8.5-8.5-8.5zm.6 56.7c1.1.8 1.3 2.3.5 3.4-.5.7-1.2 1-2 1-.5 0-1-.2-1.4-.5L81.5 52.9l-5.4 3.9c-.4.3-.9.5-1.4.5-.5 0-1-.2-1.4-.5l-5.4-3.9-32 23.5c-.4.3-.9.5-1.4.5-.8 0-1.5-.3-2-1-.8-1.1-.6-2.6.5-3.4L63.7 50 33 27.6c-1.1-.8-1.3-2.3-.5-3.4s2.3-1.3 3.4-.5l38.8 28.2 38.9-28.4c1.1-.8 2.6-.6 3.4.5.8 1.1.6 2.6-.5 3.4L85.6 49.8l30.9 22.7z"
            fill="#fff"
        />
    </svg>
);

export default SvgNewInvoice;