import React, {useState} from 'react';
import BookmarkList from "../BookmarkList";
import {Modal} from "antd";
import {Button} from "@dreebit/react-components";
import {useTranslation} from "react-i18next";
import InfastructureDashboardBookmarkCreatePanel from "../InfrastructureDashboardBookmarks/addBookmark";

interface Props {
    onBookmarkClick: (bookmark: any) => void
}

const InfrastructureBookmarks = ({onBookmarkClick}: Props) => {

    const {t} = useTranslation();
    const [popoverVisible, setPopoverVisible] = useState(false);

    return (
        <div className={"p-10"}>
            <BookmarkList
                type={["infrastructureDetail", "infrastructureSearch", "componentSelection"]}
                onBookmarkClick={onBookmarkClick}
            />
            <div className={"flex-col mt-20"}>
                <Button
                    onClick={() => {
                        setPopoverVisible(true)
                    }}
                    icon={'plus'}
                >
                    {t('Add')}
                </Button>
            </div>
            <Modal
                trigger="click"
                visible={popoverVisible}
                onCancel={() => setPopoverVisible(false)}
                footer={false}
                title={t('New Bookmark')}
            >
                <InfastructureDashboardBookmarkCreatePanel onCreated={() => {
                    setPopoverVisible(false);
                }}/>
            </Modal>
        </div>
    );
}

export default InfrastructureBookmarks;
