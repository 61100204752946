import React from 'react';
import TypeDefinitionAttributesListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql"
import {useQuery} from "@apollo/client";
import {Spin, Tooltip} from "antd";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {useTranslation} from "react-i18next";

interface Props {
    serviceProviderId: string,
    onChangeServiceProviderId: (id: string) => void
}

const ComponentClientListFilter = ({serviceProviderId, onChangeServiceProviderId}: Props) => {

    const {t} = useTranslation();

    const tdaQuery = useQuery(TypeDefinitionAttributesListQuery, {
        variables: {
            index: "componentFilter"
        }
    });

    if (tdaQuery.loading) {
        return <Spin />
    }

    const attributes = _.get(tdaQuery, 'data.typeDefinitionAttributeList.typeDefinitionAttributes');
    let filteredAttributes = attributes.filter((attribute) => attribute.index === "serviceProviderId")

    filteredAttributes = filteredAttributes.map((attr) => {
        return {
            ...attr,
            // hier wird die gleiche TDA genutzt wie an anderen Stellen, wo es ein Multi-Select ist --> hier aber nur Single Select
            multiple: false,
            value: serviceProviderId
        }
    })

    return (
        <Tooltip
            placement="right"
            title={t("Client_list_serviceProvider_hint")}
        >
            <div>
                <AttributesInlineForm
                    attributes={filteredAttributes}
                    layout={'vertical'}
                    hideLabels={true}
                    useSubmit={false}
                    onChange={(attributeValues) => {
                        onChangeServiceProviderId(attributeValues.serviceProviderId)
                    }}
                />
            </div>
        </Tooltip>
    );
}

export default ComponentClientListFilter;
