// @flow
import React, {Component} from 'react';
import {Calendar} from "antd";

import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import moment from "moment";
import CalendarItemListQuery from "./../../graphql/queries/calendarItemList.graphql";
import {getCurrentMonthRange} from "../../utils/dateHelper";
import {mapServiceCalendarBadgeItemProps} from "../../utils/calendarItemHelper";
import DotLabel from "../DotLabel";
import {Link} from "react-router-dom";
import queryString from "query-string";
import {objectToFilter} from "../../utils/queryFilter";

import "./index.css";
import waitWhileLoading from "../../hoc/waitWhileLoading";

export type Filter = {
    date: ?string
}


type Props = {
    calendarItemListQuery: any,
    filter: ?Filter,
    onChange: ?(Filter) => void
};

type State = {
    mode: 'month' | 'year'
}

class ServiceCalendar extends Component<Props, State> {
    state = {
        mode: 'month'
    }

    dateCellRender = (value) => {

        const {calendarItemListQuery, filter} = this.props;

        const listData = _.chain(calendarItemListQuery)
            .get('calendarItemList.calendarItems', [])
            .filter((item) => value.isSame(new Date(_.get(item, 'date')), "day"))
            .map(mapServiceCalendarBadgeItemProps)
            .value();

        if (filter.date && filter.date.length && filter.date.length === 2) {
            filter.date = [moment(filter.date[0]), moment(filter.date[1])]
        }

        return <div>
            {listData.map((item) => (<div key={item.id} style={{marginBottom: 3}}>
                <Link to={`/calendar/${item.dataId}/${item.calendarItemIndex}?${queryString.stringify(filter)}`}>
                    <DotLabel fullWidth bordered color={item.color} text={item.text}/>
                </Link>
            </div>))}
        </div>
    };

    handlePanelChange = (value, mode) => {
        this.setState({
            mode
        })
    };

    handleChange = (date) => {
        const {onChange} = this.props;
        const range = getCurrentMonthRange(new Date(date), 7, false);
        if (onChange) {
            onChange([moment(range[0]), moment(range[1])]);
        }
    };

    render() {

        const {filter} = this.props;
        const date = _.get(filter, 'date');

        return <div>
            <Calendar
                className={"service-calendar"}
                mode={this.state.mode}
                value={date && date.length && date.length === 2 ? moment(new Date((new Date(date[0]).getTime() + new Date(date[1]).getTime()) / 2)) : moment(new Date())}
                disabledDate={() => true}
                onChange={this.handleChange}
                onPanelChange={this.handlePanelChange}
                monthCellRender={this.dateCellRender}
                dateCellRender={this.dateCellRender}/>

        </div>;
    }
}

export function getQueryFilter(queryFilter) {
    let filter = {
        ...queryFilter,
        view: undefined
    };

    let range = _.get(filter, 'date', []);
    if (range.length !== 2) range = getCurrentMonthRange(null, 7, true);

    return {
        ...objectToFilter(filter),
        date: {
            operator: "range",
            range: {
                from: range[0],
                to: range[1],
            }
        }
    };
}

export default compose(
    graphql(CalendarItemListQuery, {
        name: 'calendarItemListQuery',
        options: (props: Props) => {
            return {
                variables: {
                    params: {
                        filter: getQueryFilter(props.filter)
                    }
                }
            }
        },
    }),
    waitWhileLoading('calendarItemListQuery', 'calendarItemList.calendarItems')
)(ServiceCalendar);
