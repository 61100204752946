// @flow
import React, {Component} from 'react';
import _ from "lodash";
import Error from "../../components/Error";
import NewsDashboard from "../../components/NewsDashboard";
import withResources from "../../hoc/withResources";

type Props = {
    match: {
        id: string,
    },
    history: {
        goBack: Function,
    }
};

class NewsDashboardRoute extends Component<Props, State> {

    render() {

        const {match} = this.props;
        const id = _.get(match, 'params.id');

        if (!id) {
            return <Error type="404"/>
        }

        return <NewsDashboard newsId={id} onDelete={() => {
            this.props.history.goBack();
        }}/>;
    }
}

export default withResources(["newsEditor"])(NewsDashboardRoute);
