//@flow

import React, {Component} from "react"
import FontAwesomeIcon from '../Icons/FontAwesomeIcon';
import LogLevelIconFactory from '../../utils/LogLevelIconFactory';
import moment from 'moment';
import './index.less';
import {Row} from "../Flex";
import OneLineLabel from "../OneLineLabel";
import {compose} from "redux";
import {withTranslation} from "react-i18next";


type Props = {
    item: any,
    serviceTicketId?: string,
    componentId?: string,
    productId?: string,
    showModal: Function
};

type State ={

}

class LogEntry extends Component<Props, State> {
    state= {

    };

    render() {
        const {item, serviceTicketId, componentId, productId, t, showModal} = this.props;
        const {insert, actionKey, level, message, user, id, title} = item;
        const {} = this.state;

        /*let link = '';
        if(serviceTicketId) link = `/service/${serviceTicketId}/logs/${id}/detail`;
        if(componentId) link = `/infrastructure/${componentId}/logs/${id}/detail`;
        if(productId) link = `/catalog/products/${productId}/logs/${id}/detail`;*/

        const typeIcon = LogLevelIconFactory.getIcon(actionKey);
        const levelColor = LogLevelIconFactory.getColor(level);

        return <a key={id} onClick={() => showModal(item)} className="log-entry-box full-width" style={{display: 'flex', flexDirection: 'row'}}>
            <div className="log-entry-icon">
                <FontAwesomeIcon style={{fontSize: 18}} name={typeIcon} circled={true} color={levelColor}/>
            </div>

            <div className="log-entry-box-body">
                <Row>
                    <OneLineLabel style={{maxWidth: '100%'}}>
                        <span>{message}</span>
                    </OneLineLabel>
                </Row>
                <Row>
                    <OneLineLabel className="log-entry-box-date" style={{maxWidth: '100%'}}>
                        <small>{moment(insert).format('L LT')} - {user.name}</small>
                    </OneLineLabel>
                </Row>
            </div>
        </a>;
    }
}

export default compose(
    withTranslation()
)(LogEntry);
