// @flow
import React, {Component} from 'react';
import {compose, withProps} from "recompose";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc'
import ProductDefaultServiceTasksQuery from "../../graphql/queries/productDefaultServiceTasks.graphql"
import DefaultServiceTasksTable from "../DefaultServiceTasksTable";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withRouter} from "react-router-dom";

type Props = {
    productId: string

};

type State = {

}

class ProductDefaultServiceTasksTable extends Component<Props, State> {

  render() {
    const { items, history, productId } = this.props;

    return <div className={"link-table"}>
        <DefaultServiceTasksTable
            onItemClick={(id) => {
                history.push(`/catalog/products/${productId}/defaultServiceTasks/${id}`)
            }}
            items={items}
            />
    </div>;
  }
}

export default compose(
    withRouter,
    graphql(ProductDefaultServiceTasksQuery,{
        options: (props) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                productId: props.productId
            }
        })
    }),
    waitWhileLoading("data"),
    withProps((props)=>({
        items: _.get(props,'data.defaultServiceTaskList.defaultServiceTasks')
    }))
)(ProductDefaultServiceTasksTable);
