// @flow
import React, {Component} from 'react';

import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ComponentTypeIcon from "../ComponentTypeIcon";

import '../StockList/subselectionList.css';


type Props = {
    site: string,
    subComponents: [{}],
    t: Function,
};

class SubselectionSiteList extends Component<Props> {

    render() {

        const {site, subComponents, t} = this.props;

        return <div className="list-wrapper">
            <div className="list-header">
                <div className="flex flex-align-items-center ellipse-label">
                    <ComponentTypeIcon componentTypeIndex={'site'} size={12} color={'black'}/>
                    <span className="larger-text ml-5 ellipse-label"> {site === 'undefined' ? t('No location assigned') : site}</span>
                </div>
                <div className="flex flex-align-items-center ellipse-label">
                    <FontAwesomeIcon name={"user"} circled={false} color={"black"} size={"1x"}/>
                    <span className="ml-5 ellipse-label">{t("Person Responsible")}: {site.owner ? site.owner : '-'}</span>
                </div>
            </div>

            <div className="list-body">
                {subComponents.map((subComp, index) => {

                    return <Link key={index}
                                 to={`/infrastructure/stock/${subComp.id}`}>
                        <div className="list-item p-5 flex flex-space-between flex-align-items-center">
                            <div className="flex-col ellipse-label">
                                <span className="text-dark-gray ellipse-label">{subComp.name}</span>
                                <span className="subtitle ellipse-label">{subComp.componentType.name}</span>
                            </div>
                            <div className="flex flex-align-items-center">
                                <Button className={"sub-button mh-5"} size={"small"}>
                                    <FontAwesomeIcon style={{fontSize: "12px", marginRight: "3px"}} name={"sitemap"}/>  {subComp.childrenCount}
                                </Button>
                                <FontAwesomeIcon name={"angle-right"} circled={false} color={"#bdc6cf"} size={"2x"}/>
                            </div>
                        </div>
                    </Link>
                })}
            </div>
        </div>;
    }
}

export default withTranslation()(SubselectionSiteList);
