// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";

import UpdateServiceControlMutation from "./../../graphql/mutations/updateServiceControl.graphql"
import ServiceControlQuery from "./../../graphql/queries/ServiceControlInfo.graphql";
import AttributesInlineForm from "./../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {getServiceControlDescription} from "../IconSplitPanelServicePlan/helper";

type Props = {
    serviceControlId: string,
    serviceControlQuery: any,
    updateServiceControlMutation: Function,
    t: Function
};


const attributeWhiteList = ['start', 'description', 'name', 'dailyCycle', 'excludeWeekend'];

class ServiceControlInfoBox extends Component<Props, State> {

    render() {
        const {serviceControlQuery, t, updateServiceControlMutation, serviceControlId} = this.props;

        const attributes = _.chain(serviceControlQuery)
            .get('serviceControl.typeDefinitionAttributeList.typeDefinitionAttributes', [])
            .filter(item => {
                if (_.get(item, 'index') === 'defaultServiceTaskId' && !_.get(item, 'defaultServiceTask')) {
                    return false;
                }
                return attributeWhiteList.indexOf(_.get(item, 'index')) !== -1
            })
            .value();

        return <div>
            <p>{getServiceControlDescription(_.get(serviceControlQuery, 'serviceControl'), t)}</p>
            <AttributesInlineForm
                inline
                attributes={attributes}
                translateMutationOptions={({values}) => ({
                    variables: {
                        attributes: values,
                        serviceControlId
                    }
                })}
                mutation={updateServiceControlMutation}
            />
        </div>;
    }
}

export default compose(
    graphql(ServiceControlQuery, {
        name: 'serviceControlQuery',
        options: (props: Props) => ({
            variables: {
                serviceControlId: props.serviceControlId
            }
        }),
    }),
    graphql(UpdateServiceControlMutation, {
        name: 'updateServiceControlMutation'
    }),
    withTranslation(),
    waitWhileLoading('serviceControlQuery', 'serviceControl.typeDefinitionAttributeList.typeDefinitionAttributes')
)(ServiceControlInfoBox);
