// @flow
import userListQuery from "./../../graphql/queries/userList.graphql"
import roleQuery from "./../../graphql/queries/roleDetails.graphql"

const RolesLoaderConfig = {
    dataKey: 'userList',
    totalKey: 'total',
    itemsKey: 'users',
    valueKey: 'id',
    textKey: 'name',
    query: userListQuery,
    getQueryVariables: (query) => {
        return {
            params: {
                search: {
                    query,
                    fields: ["email" ,"name"]
                }
            }
        }
    },
    valueQuery: roleQuery,
    getValueQueryVariables: (value) => {
        return {
            id: value
        }
    },
    valueQueryDataKey: 'role'
};

export default RolesLoaderConfig;
