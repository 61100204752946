export const attributeConfig = [
    {
        "index": "date-time",
        "name": "Datum",
        "type": "string",
        "inputType": "DATEPICKER",
        "value": "2021-03-05T12:25:31+00:00",
        "targetType": "STRING",
        "showTime": true
    },
    {
        categories: ["component"],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "name",
        inputType: "text",
        inputTypeOptions: {hint: "hi", placeholder: null, addonAfter: null},
        list: false,
        name: "Name",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "string"
    },
    {
        categories: ["component"],
        index: 'button',
        inputType: 'button',
        groups: ["shippingInfo"],
        name: 'Button',
        icon: 'user',
        onClick: () => console.log('hi'),
        type: 'danger'
    },
    {
        categories: ["component"],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "status",
        inputType: "switch",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "Status",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "bool"
    },
    {
        index: 'user',
        inputType: 'autocomplete',
        name: 'User',
        dataLoaderConfig: {
            dataQueryConfig: {
                query: `query userList($params: UserListParams) {
                userList(params: $params){
                    total
                    users {
                        id
                        email
                        name
                        roles{
                            id
                            name
                        }
                        avatar {
                          thumbnailLink(crop: fit, h: 100, w: 100)
                        }
                    }
                }
            }`,
                getQueryOptions: (props) => {
                    return {
                        variables: {
                            params: {
                                start: 0,
                                limit: 40,
                                search: {
                                    query: props.query,
                                    fields: ["name","email","firstName", "lastName"]
                                }
                            }
                        }
                    }
                },
                dataKeyPath: 'userList.users',
                titleKeyPath: 'name',
                valueKeyPath: 'id',
            },
            valueQueryConfig: {
                query: `query user($id: ID!) {
                user(userId: $id){
                    id
                    name
                    email
                    avatar {
                      thumbnailLink(crop: fit, h: 100, w: 100)
                    }
                }
            }
            `,
                dataKeyPath: 'user',
                titleKeyPath: 'name',
                valueKeyPath: 'id',
                getQueryOptions: (props) => {
                    return {
                        variables: {
                            id: props.value
                        }
                    }
                },
            }
        }
    },
    {
        categories: ["component"],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "date",
        inputType: "date",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null, timeIntervalButtons: null},
        list: false,
        name: "Datum",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "string"
    }, {
        categories: ["default"],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "cuContaminated",
        inputType: "checkbox",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "Kupfer-kontaminiert",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "bool"
    },  {
        categories: ["component"],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "productionDate",
        inputType: "date",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null, timeIntervalButtons: [
                {
                    interval: 24,
                    type: 'h',
                    direction: 'add',
                    name: '1 Tag'
                },
                {
                    interval: 1,
                    type: 'M',
                    direction: 'add',
                    name: '1 Monat'
                },
                {
                    interval: 6,
                    type: 'M',
                    direction: 'add',
                    name: '6 Monate'
                },
                {
                    interval: 1,
                    type: 'y',
                    direction: 'add',
                    name: '1 Jahr'
                }
            ]},
        list: false,
        name: "Herstellungsdatum",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "string"
    }, {
        categories: ["component"],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "productionTime",
        inputType: "time",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "Herstellungszeit",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "string"
    }, {
        categories: ["component"],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "runtime",
        inputType: "hours",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "Laufzeit (in h)",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "int"
    }, {
        name: 'Component',
        rules: [{name: "required"}],
        index: 'componentId',
        type: 'string',
        inputType: 'component',
        entityType: 'component',
        inputTypeOptions: {
            useNavigator: false
        }

    }, {
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "textArea",
        inputType: "textArea",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "textArea",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "string"
    }, {
        disabled: false,
        entityType: "enum",
        enumType: "ServiceTicketCreateTypeIndex",
        file: null,
        index: "componentTypeIndex",
        inputType: "select",
        inputTypeOptions: {hint: "", placeholder: "", addonAfter: ""},
        list: false,
        name: "select",
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        type: "string"
    }, {
        categories: [],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "description",
        inputType: "markdown",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "Beschreibung",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "string"
    }, {
        disabled: false,
        entityType: "user",
        file: null,
        index: "multiselect",
        inputType: "multiselect",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "multiSelect",
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "string"
    }, {
        categories: [],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "table",
        inputType: "table",
        inputTypeOptions: {hint: null, placeholder: null, addonAfter: null},
        list: false,
        name: "table",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        type: "object",
        subAttributes: [
            {
                index: "name",
                inputType: "text",
                name: "Handelsname, Produktname, Hersteller",
                type: "string"
            },
            {
                index: "precautions",
                inputType: "text",
                name: "Maßnahmen bei Freiwerden der Schadstoffe",
                type: "string"
            },
            {
                index: "chemicalElement",
                inputType: "text",
                name: "Chemische Bezeichnung (evtl. auch Formel)",
                type: "string"
            },
            {
                index: "helpActions",
                inputType: "text",
                name: "Erste Hilfe bei Unfällen",
                type: "string"
            },
            {
                index: "dangerClass",
                inputType: "text",
                name: "Gefahrenklasse",
                type: "string"
            }
        ]
    }, {
        categories: [],
        disabled: false,
        entityType: "",
        enumType: "",
        file: null,
        index: "multilang",
        inputType: "multilang",
        inputTypeOptions: {hint: "", placeholder: "", addonAfter: ""},
        list: false,
        name: "Text",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [{name: "required"}],
        subAttributes: [
            {
                categories: [],
                disabled: false,
                entityType: "",
                enumType: "",
                file: null,
                index: "de_DE",
                inputType: "text",
                inputTypeOptions: {hint: "", placeholder: "", addonAfter: ""},
                list: false,
                name: "Deutsch",
                optionValues: [],
                options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
                rules: [{name: "required"}],
                subAttributes: [],
                type: "string"
            }, {
                categories: [],
                disabled: false,
                entityType: "",
                enumType: "",
                file: null,
                index: "en_EN",
                inputType: "text",
                inputTypeOptions: {hint: "", placeholder: "", addonAfter: ""},
                list: false,
                name: "Englisch",
                optionValues: [],
                options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
                rules: [{name: "required"}],
                subAttributes: [],
                type: "string"
            }
        ],
        type: "object"
    }, {
        categories: [],
        disabled: false,
        entityType: null,
        enumType: null,
        file: null,
        index: "number",
        inputType: "int",
        inputTypeOptions: {hint: "", placeholder: "", addonAfter: ""},
        list: true,
        name: "Number",
        optionValues: [],
        options: {searchable: false, versionable: false, exportable: false, componentCreate: false},
        rules: [],
        subAttributes: [],
        type: "number"
    }
];
