// @flow
import React, {Component} from "react"
import {compose} from "redux";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Popover} from "antd";
import {Button} from "@dreebit/react-components";
import Toolbar from "../Toolbar";
import WorkflowBoardBookmarks from '../WorkflowBoardBookmarks';
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {graphql} from '@apollo/client/react/hoc';
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import {withSize} from "react-sizeme";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    params?: {
        [string]: any
    },
    onChange?: Function,
    t: Function,
    history: {
        push: Function
    }
}

type State = {}

class WorkflowToolbar extends Component<Props, State> {


    constructor(props) {
        super(props);

    }

    onReset() {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    render() {

        const {onChange, params, size, typeDefinitionAttributeListQuery} = this.props;
        /*
                const attributes = [
                    {
                        index: 'serviceTicketTypeIndex',
                        type: 'string',
                        multiple: true,
                        maxTagCount: 0,
                        entityType: 'enum',
                        enumType: 'ServiceTicketTypeIndex',
                        inputType: 'select',
                        placeholder: t('Ticket Type')
                    },
                    {
                        index: 'serviceDepartmentId',
                        type: 'string',
                        multiple: true,
                        maxTagCount: 0,
                        entityType: 'serviceDepartment',
                        inputType: 'select',
                        placeholder: t('Department'),
                    },
                    {
                        index: 'clientId',
                        type: 'string',
                        multiple: true,
                        maxTagCount: 0,
                        entityType: 'client',
                        inputType: 'select',
                        placeholder: t('Client'),
                    },
                    {
                        index: 'assigneeId',
                        type: 'string',
                        multiple: true,
                        maxTagCount: 0,
                        entityType: 'user',
                        inputType: 'select',
                        placeholder: t('Assignee'),
                    },
                    {
                        index: 'serviceProviderId',
                        type: 'string',
                        multiple: true,
                        maxTagCount: 0,
                        entityType: 'serviceProvider',
                        inputType: 'select',
                        placeholder: t('Service Provider'),
                    },
                    {
                        index: 'componentTypeIndex',
                        type: 'string',
                        multiple: true,
                        maxTagCount: 0,
                        entityType: 'enum',
                        enumType: 'ComponentTypeIndex',
                        inputType: 'select',
                        placeholder: t('ComponentType'),

                    },
                    {
                       index: 'manufacturerId',
                       type: 'string',
                       entityType: 'manufacturer',
                       inputType: 'select',
                       placeholder: 'Manufacturer',
                       config: {
                         mode: 'default',
                         showSearch: true
                       }
                     },
                    // {
                    //   index: 'componentId',
                    //   type: 'int',
                    //   inputType: 'component',
                    // },
                    {
                        index: 'warrantyStatus',
                        type: 'string',
                        multiple: true,
                        maxTagCount: 0,
                        entityType: 'enum',
                        enumType: 'WarrantyStatus',
                        inputType: 'select',
                        placeholder: t('Warranty Status'),
                    }
                ];

                const inQuarantineStoreAttribute = _.chain(typeDefinitionAttributeListQuery).get('typeDefinitionAttributeList.typeDefinitionAttributes').find({
                    index: 'inQuarantineStore'
                }).value();

                if (inQuarantineStoreAttribute){
                    attributes.push( {
                        index: 'inQuarantineStore',
                        type: 'string',
                        optionValues: [{
                            value: 'true',
                            title: t('Component in quarantine store')
                        }],
                        inputType: 'select',
                        placeholder: t('In quarantine store'),
                    })
                }
        */

        let attributes = _.cloneDeep(_.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', []));

        let collapse = attributes.slice(0, Math.floor(size.width / 200)).map(item => item.index);
        if (collapse.length === attributes.length) {
            collapse = [];
        }

        if (Object.keys(params).length && _.findIndex(attributes, {index: 'resetFilters'}) === -1) {
            attributes.push({
                index: 'resetFilters',
                inputType: 'button',
                icon: 'CloseOutlined',
                onClick: () => this.onReset(),
                type: 'danger',
            });

            if (collapse.length) {
                collapse.push('resetFilters')
            }
        }


        return <div style={{position: 'relative'}} className={"white-bg ph-10 pv-5 bb"}>
            <Toolbar renderLeft={() => <AttributesInlineForm
                layout={'inline'}
                hideLabels={true}
                onChange={(changes, info) => {
                    return new Promise((res) => {
                        if (onChange) {
                            onChange(info.allValues)
                        }
                        res();
                    })
                }
                }
                collapse={collapse}
                useSubmit={false}
                values={{
                    ...params,
                    inQuarantineStore: params.inQuarantineStore ? 'true' : undefined
                }}
                attributes={attributes}
                cacheChanges={false}
            />} renderRight={() => <Popover placement="bottomRight" content={<WorkflowBoardBookmarks params={params}/>}
                                            trigger="click">
                <Button>
                    <FontAwesomeIcon name={"bookmark"}/>
                </Button>
            </Popover>}/>
        </div>;
    }

}


export default compose(
  withTranslation(),
  withRouter,
    graphql(TypeDefinitionAttributeListQuery,{
        name: 'typeDefinitionAttributeListQuery',
        options: () => {
            return {
                variables: {
                    index: 'serviceTicketWorkflowBoardFilter'
                }
            }
        }
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery', ['typeDefinitionAttributeList.typeDefinitionAttributes']),
    withSize()
)(WorkflowToolbar);
