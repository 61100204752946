import React from "react";
import useAuthToken from "../../hooks/useAuthToken";
import AuthTokenLookup from "../../components/AuthTokenLookup";
import Panel from "../../components/Panel";
import {useTranslation} from "react-i18next";
import Logo from "../../components/Logo";
import AuthTokenWorkflowBoard from "../../components/AuthTokenWorkflowBoard";

interface Props {

}

const WorkflowboardRoute = ({}: Props) => {

    const token = useAuthToken();
    const {t} = useTranslation();

    if (token) {
        return <AuthTokenWorkflowBoard className={'monitorWorkflowBoard'}/>
    }

    return <div className={"full-size workflowboard"}>
        <div className={"bg-light-grey flex flex-center full-height flex-align-items-center"}>
            <Panel>
                <div className={"ph-10"}>
                    <div className={"mv-20"} style={{textAlign: 'center'}}>
                        <Logo/>
                        <h3 className={"mt-10"}>{t("Authentication Lookup")}</h3>
                        <div>{t("Please visit accept the following connection in your VSM connectors setup page")}:</div>
                        <div>{t("Profile > Connectors > Create")}</div>
                    </div>
                    <AuthTokenLookup />
                </div>
            </Panel>
        </div>
    </div>
}

export default WorkflowboardRoute;
