// @flow
import React, {Component} from 'react';
import moment from "moment";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import ServiceTicketQualityIssueFormQuery from "../../graphql/queries/ServiceTicketQualityIssueInfo.graphql";
import _ from "lodash";
import Error from "../Error";

type Props = {

};

type State = {

}

class QualityIssueEditorInfo extends Component<Props, State> {

  render() {
    const { serviceTicketQualityIssueFormQuery, t } = this.props;

    const qualityIssue = _.get(serviceTicketQualityIssueFormQuery,'serviceTicket.qualityIssue');

    if (!qualityIssue){
        return <Error type={404}/>
    }

    return <div className="log-header border border-radius-5">

        <div className="log-last-user">
            <span><b>{t('Created at')}:</b> {moment(qualityIssue.insert).format('LLL')} ({_.get(qualityIssue,'createdBy.name')})</span><br/>
            <span><b>{t('Updated at')}:</b> {moment(qualityIssue.update).format('LLL')} ({_.get(qualityIssue,'updatedBy.name')})</span>
        </div>
    </div>

  }
}


export default compose(
    withTranslation(),
    graphql(ServiceTicketQualityIssueFormQuery,{
        name: 'serviceTicketQualityIssueFormQuery',
        options: ({serviceTicketId}) => {
            return {
                variables: {
                    serviceTicketId
                }
            }
        }
    }),
)(QualityIssueEditorInfo);

