// @flow
import React, {Component} from 'react';
import Colors from "../../themes/default/colors";
import {Tag} from "antd";

type Props = {
    state: {
        index: string,
        name: string,
    },
    style: any,
    defaultColor?: string
};

const colorsMap = {
    open: Colors.open,
    inProgress: Colors.inProgress,
    done: Colors.done,
};

class ServiceTicketStateBadge extends Component<Props, State> {

    static defaultProps = {
        defaultColor: '#ccc',
    };


    render() {
        const {state, defaultColor, style} = this.props;

        let color = colorsMap[state.index];

        if (!color) {
            color = defaultColor;
        }

        return <Tag style={style} color={color}>{state.name}</Tag>;
    }
}

export default ServiceTicketStateBadge;
