// @flow
import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {compose, withProps} from "recompose";
import ConversationRoute from "./conversation";
import {Col, Row, Tabs} from "antd";
import ConversationList from "../../components/ConversationList";
import _ from "lodash";
import queryString from "query-string";
import {withTranslation} from "react-i18next";
import Redirect from "../Redirect";
import {Query} from '@apollo/client/react/components';
import ConversationListQuery from "../../graphql/queries/conversationListId.graphql";

import Empty from "../../components/Empty";
import Error from "../../components/Error";
import Loader from "../../components/Loader";
import Error404 from "../errorPages/error404";
import './index.css';
import withResources from "../../hoc/withResources";
import {graphql} from "@apollo/client/react/hoc";
import UpdateConversationMutation from "../../graphql/mutations/updateConversation.graphql";

const {TabPane} = Tabs;


type Props = {};

type State = {}

class MessagesRoute extends Component<Props, State> {

    render() {
        const {tab, history, t, params, activeKey, conversationListQuery, updateConversationMutation} = this.props;


        const content = <ConversationList
            archive={tab === 'archive'}
            activeKey={activeKey}
            onItemClick={(item) => {
                updateConversationMutation({
                    variables: {
                        conversationId: item.id,
                        input: {
                            read: true
                        }
                    },
                })

                return history.push(`/messages/${item.id}?${queryString.stringify(params)}`)
            }}
        />;

        return <Row className={"overflow-hidden calculatedBody"}>
            <Col className="full-height overflow-hidden white-bg br" sm={8} md={6}>
                <Tabs className={"tabs-no-bottom-margin full-height"} defaultActiveKey="active" activeKey={tab} onChange={(key) => {
                    history.push({
                        search: queryString.stringify({
                            ...params,
                            tab: key
                        })
                    })
                }}>
                    <TabPane tab={t('My Conversations')} key="active">
                        {content}
                    </TabPane>
                    <TabPane tab={t('Archive')} key="archive">
                        {content}
                    </TabPane>
                </Tabs>,

            </Col>
            <Col className="full-height" sm={16} md={18}>
                <Switch>
                    <Route path="/messages/new" component={() => <Empty/>}/>
                    <Route path="/messages/:id" component={ConversationRoute}/>
                    <Route path={"/messages"} component={({match: {params}}) => {

                        return <Query
                          fetchPolicy={'network-only'}
                            query={ConversationListQuery}
                            variables={{
                                params: {
                                    start: 0,
                                    limit: 1,
                                    sort: {lastMessageUpdate: "DESC"},
                                    filter: {
                                        archive: {value: false},
                                        participant: {
                                            value: true
                                        }
                                    }
                                }
                            }}
                        >
                            {(({loading, error, data, fetchMore, networkStatus, refetch}) => {

                                if (error) {
                                    return <Error error={error} enableFeedback/>
                                }
                                return loading ? <Loader/> : <Redirect
                                    to={`/messages/${_.get(data, 'conversationList.conversations[0].id', 'new')}`}/>

                            })}
                        </Query>


                    }}/>
                    <Route component={Error404}/>
                </Switch>
            </Col>
        </Row>


    }
}

export default compose(
    withRouter,
    graphql(UpdateConversationMutation, {
        name: 'updateConversationMutation',
        options: (props) => {
            return {
                refetchQueries: ["conversationList"]
            }
        }
    }),
    withProps((props) => {

        const search = _.get(props, 'history.location.search');
        const params = queryString.parse(search);
        return {
            params,
            activeKey: _.get(props,'match.params.id'),
            tab: params.tab || 'active'
        }
    }),
    withTranslation(),
    withResources(["conversation"])
)(MessagesRoute);
