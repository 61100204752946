// @flow
import React, { Component } from 'react';
import ReduxAttributeForm from "../ReduxAttributeForm";
import {withTranslation} from "react-i18next";

type Props = {

};

type State = {

}

class DeveloperSettings extends Component<Props, State> {

  render() {
    const { t } = this.props;

    return <div>
        <ReduxAttributeForm
            index={'developerSettings'}
            attributes={[
                {
                    index: 'disableSocket',
                    inputType: 'switch',
                    type: 'boolean',
                    name: t('Disable Socket')
                }
            ]}
            />
    </div>;
  }
}

export default withTranslation()(DeveloperSettings);
