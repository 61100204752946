import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import _ from "lodash";
import AuthTokenQuery from "./../../graphql/queries/currentAccessToken.graphql"
import ProfileQuery from "./../../graphql/queries/profile.graphql"
import VSMLoadingAnimation from "../VSMLoadingAnimation";
import {useTranslation} from "react-i18next";
import {Result} from "antd";
import ServiceTicketBookmarkWorkflowBoard from "../ServiceTicketBookmarkWorkflowBoard";

interface Props {
    className?: string
}

const AuthTokenWorkflowBoard = ({className}: Props) => {

    const {t} = useTranslation();
    const [pollInterval, setPollInterval] = useState(5000)
    const authTokenQuery = useQuery(AuthTokenQuery, {
        pollInterval
    });
    const profileQuery = useQuery(ProfileQuery);

    let bookmarkId;
    let showError = false;
    const authTokenInfo = _.get(authTokenQuery,'data.currentAccessToken');
    const errorView =  <Result
        status="500"
        title={t("Your session has no valid setup for workflow boards")}
        subTitle={<div>
            <div>{t("Please ask the following user to setup a bookmark for this session")}:</div>
            <div>{t("User")}: {_.get(profileQuery,'data.profile.name','Unknown')}</div>
            <div>{t("Connection Name")}: {_.get(authTokenInfo,'name','Unknown')}</div>
        </div>}
    />
    const authTokenData = _.get(authTokenQuery,'data.currentAccessToken.data');
    if (!authTokenData) {
        showError = true;
    }

    try {
        const json = JSON.parse(authTokenData);
        bookmarkId = _.get(json,'bookmarkId')
        if (!bookmarkId){
            showError = true;
        }
    }catch {
        showError = true;
    }

    useEffect(()=> {
        if (!showError){
            setPollInterval(null);
        }
    },[showError])

    if (profileQuery.loading || authTokenQuery.loading) return <VSMLoadingAnimation/>


    if (showError && process.env.NODE_ENV !== 'development') return errorView;

    return <ServiceTicketBookmarkWorkflowBoard className={className} bookmarkId={bookmarkId}/>
}

export default AuthTokenWorkflowBoard;
