// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {List, message, Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";
import {graphql} from '@apollo/client/react/hoc';
import _ from 'lodash'
import {compose} from "redux";
import {withProps} from "recompose";
import ClientServiceContractQuery from "./../../graphql/queries/clientServiceContractQuery.graphql"
import CreateServiceContractMutation from "../../graphql/mutations/createServiceContract.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import type {Query} from "../../types";
import styled from 'styled-components';
import {Link, withRouter} from "react-router-dom";

const Wrapper = styled.section`
  padding: 4px 16px;
`;

type Props = {
    clientId: string,
    t: Function,
    createServiceContractMutation: Function,
    clientServiceContractQuery: Query,
    isServiceContract: boolean,
};

type State = {
    loading: boolean,
}

class ServiceContractBanner extends Component<Props, State> {

    state = {
        loading: false
    };

    handleActivate = () => {
        const {createServiceContractMutation, clientId, t, clientServiceContractQuery} = this.props;

        createServiceContractMutation({
            variables: {
                clientId
            }
        }).then(() => {
            return clientServiceContractQuery.refetch();
        }).catch(() => {
            message.error(t('Could not active client'))
        })
    };

    render() {
        const {t, clientServiceContractQuery, isServiceContract} = this.props;

        return <Wrapper
            className={isServiceContract ? 'primary-lighter-bg primary-darker-border primary-darker-color' : 'grey-lighter-bg grey-darker-color grey-darker-border'}>
            <List.Item actions={!isServiceContract ? [

                <Popconfirm title={t('Are you sure')} onConfirm={this.handleActivate} okText={t('Yes')}
                            cancelText={t('No')}>
                    <Button loading={this.state.loading} type="primary" ghost>{t('Active service contract')}</Button>
                </Popconfirm>
            ] : [<Link to={`/contractpartners/${_.get(clientServiceContractQuery, 'client.serviceContract.id')}`}>
                <Button type="primary">{t('Service contract')}</Button>
            </Link>]}>
                <List.Item.Meta
                    title={t('Service contract')}
                    description={`${_.get(clientServiceContractQuery, 'client.name')} ${isServiceContract ? t('is a contract customer') : t('is not a contract customer')}`}
                />
            </List.Item>
        </Wrapper>
    }

}

export default compose(
  withRouter,
    graphql(ClientServiceContractQuery, {
        name: 'clientServiceContractQuery',
        options: (props) => ({
            variables: {
                id: props.clientId
            }
        }),
    }),
    waitWhileLoading('clientServiceContractQuery', ['client']),
    graphql(CreateServiceContractMutation, {
        name: 'createServiceContractMutation',
    }),
    withTranslation(),
    withProps(props => ({
        isServiceContract: _.get(props, 'clientServiceContractQuery.client.contractCustomer', false)
    }))
)(ServiceContractBanner);

