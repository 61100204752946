// @flow
import React, {Component} from "react"
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import AddOpenedComponentMutation from "./../../graphql/mutations/addOpenedComponent.graphql";
import ComponentNameQuery from "./../../graphql/queries/component-Name.graphql";
import {withRouter} from "react-router-dom";
import {Spin} from "antd";
import {toastError} from "../../utils/toast";
import openedComponentList from "../../graphql/queries/openedComponentList.graphql";


type Props = {
    addOpenedComponentMutation: Function,
    componentNameQuery: any,
    componentId: string,
}

class AddOpenedComponentTab extends Component<Props> {

    state = {
        loading: true,
        title: undefined
    };

    componentDidMount() {

        const {addOpenedComponentMutation} = this.props;
        addOpenedComponentMutation().then(() => {

        }).catch((err) => {
            toastError(err);
        }).finally(() => {
            this.setState({
                loading: false,
            })
        })

    }

    render() {
        const {loading} = this.state;
        const {componentNameQuery} = this.props;

        return <span>{_.get(componentNameQuery, 'component.name')} {loading || componentNameQuery.loading ?
            <Spin size={'small'}/> : null} </span>
    }
}

export default compose(
    graphql(AddOpenedComponentMutation, {
        name: 'addOpenedComponentMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: openedComponentList
            }],
            variables: {
                componentIds: [props.componentId]
            }
        })
    }),
    graphql(ComponentNameQuery, {
        name: 'componentNameQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        })
    }),
)(withRouter(AddOpenedComponentTab));
