import _ from "lodash";
import { useSelector } from 'react-redux'


export default function useAuthToken() {

    return useSelector((state) => {
        return _.get(state, 'auth.workflowboard_uuid')
    })
};
