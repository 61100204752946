// @flow
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";

import ProductDefaultServiceTaskTDAQuery from "../../graphql/queries/productDefailtServiceTaskTDA.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import CreateProductDefaultServiceTask from "../../graphql/mutations/CreateProductDefaultServiceTask.graphql";
import ComponentTypeProduct from "../../graphql/queries/componentTypeProduct.graphql";
import AttributesInlineForm from "../Form/attributes-inline-form";
import Panel from "../Panel";
import DefaultServiceTaskQuery from "../../graphql/queries/defaultServiceTask.graphql";
import ProductComponentType from "../../graphql/queries/productComponentType.graphql";

type Props = {
    productDefaultServiceTaskTDAQuery: query,
    t: Function,
    createProductDefaultServiceTask: Function,
    productId: string,
    history: {
        push: Function,
    },
};


type State = {
    values: any,
}

class ProductDefaultServiceTasksCreate extends Component<Props, State> {


    state = {
        values: {}
    }

    render() {
        const {
            productDefaultServiceTaskTDAQuery,
            t,
            createProductDefaultServiceTask,
            match,
            productId,
            history,
            client,
            componentTypeIndex
        } = this.props;

        const {values} = this.state;

        const attributes = [..._.get(productDefaultServiceTaskTDAQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', [])];

        return <Panel title={t('New default service plan')}>
            <AttributesInlineForm
                className={'pt-20 pr-20'}
                attributes={attributes}
                layout={'horizontal'}
                useSubmit={true}
                values={values}
                t={t}
                onChange={(vals) => {
                    const tmp = {
                        ...this.state.values,
                        ...vals
                    };
                    if (vals.componentTypeDefaultServiceTaskId !== undefined && !_.isEqual(this.state.values.componentTypeDefaultServiceTaskId, vals.componentTypeDefaultServiceTaskId)) {
                        const query = client.query({
                            query: DefaultServiceTaskQuery,
                            variables: {
                                id: vals.componentTypeDefaultServiceTaskId
                            }
                        });
                        query.then(({data}) => {
                            const {defaultServiceTask} = data;
                            this.setState({
                                values: {
                                    ...tmp,
                                    name: defaultServiceTask.name,
                                    description: defaultServiceTask.description,
                                    recommendedServiceCycle: defaultServiceTask.recommendedServiceCycle,
                                    defaultPrice: defaultServiceTask.defaultPrice,
                                    statusInformation: defaultServiceTask.statusInformation,
                                }
                            })
                        })

                    }
                }}
                mutation={createProductDefaultServiceTask}
                translateMutationOptions={({values}) => {
                    const input = {...values};
                    return {
                        variables: {
                            productId,
                            input
                        }
                    }
                }}
                onSubmit={() => {
                    history.push(`/catalog/products/${_.get(match, 'params.id')}/defaultServiceTasks`);
                }}
            />
        </Panel>
    }
}

export default compose(
    withRouter,
    withApollo,
    graphql(CreateProductDefaultServiceTask, {
        name: "createProductDefaultServiceTask",
        options: props => ({
            refetchQueries: [{
                query: ComponentTypeProduct,
                variables: {
                    id: props.productId
                }
            }]
        })
    }),
    graphql(ProductComponentType, {
        name: 'productComponentTypeQuery',
        options: (props) => ({
            variables: {
                id: props.productId
            }
        })
    }),
    waitWhileLoading('productComponentTypeQuery'),
    withProps(({productComponentTypeQuery}) => {
        return {
            componentTypeIndex: _.get(productComponentTypeQuery, 'product.componentType.index')
        }
    }),
    graphql(ProductDefaultServiceTaskTDAQuery, {
        name: 'productDefaultServiceTaskTDAQuery',
        options: (props) => ({
            variables: {
                options: {
                    componentTypeIndex: props.componentTypeIndex
                }
            }
        })
    }),
    waitWhileLoading('productDefaultServiceTaskTDAQuery', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
    withTranslation(),
)(ProductDefaultServiceTasksCreate);
