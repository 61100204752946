import React, {ReactNode} from 'react';
import styled from 'styled-components';
import Colors from "../../themes/default/colors";
import type {DraggableProvided} from 'react-beautiful-dnd';
import _ from "lodash";

type Props = {
    renderItem: (item: any) => ReactNode,
    item: any,
    isDragging: boolean,
    provided: DraggableProvided,
    toggleSelection: (itemId: string) => void,
    toggleSelectionInGroup: (itemId: string) => void,
    selectionCount?: number,
    isClone?: boolean,
    isGroupedOver?: boolean,
    style?: Object,
    index?: number,
};

const keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9,
};

const getBackgroundColor = (
    isDragging: boolean,
    isGroupedOver: boolean,
    isSelected: boolean
) => {
    if (isDragging) {
        return Colors.white;
    }

    return Colors.white;
};

const getBorderColor = (isDragging: boolean, isSelected: boolean) => {
    if (isDragging) {
        return Colors.grey
    }

    if (isSelected) {
        return Colors.greyLight
    }

    return Colors.greyLighter
}


const imageSize: number = 40;
const primaryButton = 0;
const grid = 5;
const size = 20;

const SelectionCount = styled.div`
  right: -${grid}px;
  top: -${grid}px;
  color: ${Colors.greyDark};
  background: ${Colors.white};
  border-radius: 50%;
  height: ${size}px;
  width: ${size}px;
  line-height: ${size}px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
`;

const CloneBadge = styled.div`
  background: ${Colors.greyLight};
  bottom: 5px;
  border: 2px solid ${Colors.greyLight};
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);
  height: ${imageSize}px;
  width: ${imageSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.span`
  border-radius: 5px;
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.isSelected)};
  background-color: ${(props) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.isSelected)};
  box-sizing: border-box;
  margin-bottom: 5px;
  user-select: none;
  /* anchor overrides */
  color: ${Colors.greyDarker};
  &:hover,
  &:active {
    color: ${Colors.greyDark};
    text-decoration: none;
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.colors.hard};
    box-shadow: none;
  }
  /* flexbox */
  display: flex;
`;

const Avatar = styled.img`
  width: ${imageSize}px;
  height: ${imageSize}px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`;


function getStyle(provided: DraggableProvided, style: ?Object) {
    if (!style) {
        return provided.draggableProps.style;
    }

    return {
        ...provided.draggableProps.style,
        ...style,
    };
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function WorkflowItem(props: Props) {
    const {
        renderItem,
        item,
        isDragging,
        isGroupedOver,
        provided,
        style,
        isClone,
        snapshot,
        index,
    } = props;

    const onKeyDown = (
        event: KeyboardEvent,
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
    ) => {
        if (event.defaultPrevented) {
            return;
        }

        if (isDragging) {
            return;
        }

        if (event.keyCode !== keyCodes.enter) {
            return;
        }

        // we are using the event for selection
        event.preventDefault();

        performAction(event);
    };

    const wasToggleInSelectionGroupKeyUsed = (event: MouseEvent | KeyboardEvent) => {
        const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) => event.shiftKey;

    const performAction = (event: MouseEvent | KeyboardEvent) => {
        const {
            item,
            toggleSelection,
            multiSelectTo,
            toggleSelectionInGroup,
        } = props;

        if (wasToggleInSelectionGroupKeyUsed(event)) {
            toggleSelectionInGroup(item.id);
            return;
        }

        if (wasMultiSelectKeyUsed(event)) {
            multiSelectTo(item.id);
            return;
        }

        toggleSelection(item.id);
    };

    // Using onClick as it will be correctly
    // preventing if there was a drag
    const onClick = (event: MouseEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.button !== primaryButton) {
            return;
        }

        // marking the event as used
        event.preventDefault();

        performAction(event);
    };

    const onTouchEnd = (event: TouchEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        // marking the event as used
        // we would also need to add some extra logic to prevent the click
        // if this element was an anchor
        event.preventDefault();
        props.toggleSelectionInGroup(props.item.id);
    };

    const shouldShowSelection: boolean =
        isDragging && props.selectionCount > 1;

    const isSelected = props.selectedItemIds && props.selectedItemIds.indexOf(item.id) > -1;

    return (
        <Container
            onClick={onClick}
            onTouchEnd={onTouchEnd}
            onKeyDown={(event: KeyboardEvent) =>
                onKeyDown(event, provided, snapshot)
            }
            isDragging={isDragging}
            isSelected={isSelected}
            isGroupedOver={isGroupedOver}
            isClone={isClone}
            colors={{}}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided, style)}
            data-is-dragging={isDragging}
            data-testid={item.id}
            data-index={index}
            aria-label={`${item.name}`}
        >
            {shouldShowSelection ? (
                <SelectionCount>{props.selectionCount}</SelectionCount>
            ) : null}

            {renderItem ? renderItem(item) : <>
                <Avatar src={_.get(item, 'component.image.thumbnailLink')} alt={item.name}/>
                {isClone ? <CloneBadge>Clone</CloneBadge> : null}
                <Content>
                    {item.name}
                </Content>
            </>}

        </Container>
    );
}

export default React.memo(WorkflowItem);
