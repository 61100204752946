// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {compose, withProps} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import ContentHeader from "../Typo/ContentHeader";
import NewsDashboardQuery from "./../../graphql/queries/NewsDashboard.graphql";
import NewsPublishMutation from "./../../graphql/mutations/newsPublish.graphql";
import DeleteNewsMutation from "./../../graphql/queries/deleteNews.graphql";
import NewsListQuery from "./../../graphql/queries/NewsList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {message, Popconfirm} from "antd";
import {Button} from "@dreebit/react-components";
import Strings from "../../i18n/strings";
import Error from "../Error";
import Panel from "../Panel";
import ContentWrapper from "../ContentWrapper";
import NewsUpdateForm from "../NewsUpdateForm";
import statics from "../../statics";


type Props = {
    newsId: string,
    t: Function,
    news: any,
    newsPublishMutation: () => Promise,
    deleteNewsMutation: () => Promise,
    onDelete: ?Function,
};

type State = {
    removing: boolean,
    publishing: boolean,
}

class NewsDashboard extends Component<Props, State> {

    state = {
        removing: false,
        publishing: false
    };

    _handleConfirmPublish = () => {

        const {t} = this.props;

        this.setState({
            publishing: true,
        }, () => {
            this.props.newsPublishMutation().then(() => {
                message.success(t('News published'));
            }).catch(() => {
                message.error(Strings.errorOccurred);
            }).finally(() => {
                this.setState({
                    publishing: false,
                })
            })
        })

    };

    _handleConfirmDelete = () => {
        const {t} = this.props;

        this.setState({
            removing: true,
        }, () => {
            this.props.deleteNewsMutation().then((res) => {
                message.success(_.get(res, 'data.message') || t('News removed'));
                setTimeout(() => {
                    if (this.props.onDelete) {
                        this.props.onDelete(res);
                    }
                }, 0)
            }).catch(() => {
                message.error(Strings.errorOccurred);
            }).finally(() => {
                this.setState({
                    removing: false,
                })
            })
        })
    };

    render() {
        const {t, news} = this.props;

        if (!news) {
            return <Error/>
        }

        const tools = [];

        if (news.status === 'inReview')
            tools.push(<Popconfirm className={"mr-10"} key={"status"} onConfirm={this._handleConfirmPublish}
                                   title={t(Strings.areYouSure)}>
                <Button type={'primary'} loading={this.state.publishing}>{t('Publish')}</Button>
            </Popconfirm>);

        tools.push(<Popconfirm key={"remove"} onConfirm={this._handleConfirmDelete} title={t(Strings.areYouSure)}>
            <Button type={'danger'} loading={this.state.removing}>{t('Delete')}</Button>
        </Popconfirm>);

        return <div>
            <ContentHeader title={news.title} tools={tools}/>

            <ContentWrapper>
                <Panel title={t('Edit')}>
                    <NewsUpdateForm newsId={news.id}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(NewsPublishMutation, {
        name: 'newsPublishMutation',
        options: (props) => ({
            variables: {
                id: props.newsId
            }
        })
    }),
    graphql(DeleteNewsMutation, {
        name: 'deleteNewsMutation',
        options: (props) => ({
            variables: {
                id: props.newsId
            },
            refetchQueries: [{
                query: NewsListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                    }
                }
            }]
        })
    }),
    graphql(NewsDashboardQuery, {
        name: 'query',
        options: (props: Props) => ({
            variables: {
                id: props.newsId
            }
        }),
    }),
    waitWhileLoading('query', 'news'),
    withProps((props) => ({
        news: _.get(props, 'query.news')
    }))
)(NewsDashboard);
