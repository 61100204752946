// @flow
import React, {Component} from 'react';
import {EditableWrapper} from "@dreebit-registry/form-attributes-vsm";
import FileUploader from "./../../FileUploader";

type Props = {

};

type State = {
    addedFiles: []
}

class AttributeFiles extends Component<Props, State> {


    addFileId(id){
        return new Promise((resolve) => {
            this.setState({
                addedFiles: [
                    ...this.state.addedFiles,
                    id
                ]
            }, () => {
                resolve(this.state.addedFiles)
            })
        })
    }

    render() {
        const { form, attribute, style, translations } = this.props;

        return <EditableWrapper
            form={form}
            attribute={attribute}
            style={style}
        >
            { ({value, onChange}) => {
                return <FileUploader
                    onChange={onChange}
                />
            }}
        </EditableWrapper>;
    }
}

export default AttributeFiles;
