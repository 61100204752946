import styled from 'styled-components';
import Colors from "../../themes/default/colors";


export default styled.h4`
  padding: 10px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  &:focus {
    outline: 2px solid ${Colors.darkHalf};
    outline-offset: 2px;
  }
`;
