// @flow
import React, {Component} from "react"
import RouterBreadcrumbs from "../../routes/RouterBreadcrumbs";
import classNames from "classnames";

import './ContentHeader.css'

type Props = {
    toolsClassName?: string,
    hideBreadcrumb?: boolean,
    description?: string,
    breadcrumb?: {
        title: string,
        link: string,
    }[],
    title: string,
    tools?: ?any,
    size?: string,
}

class ContentHeader extends Component<Props> {


    render() {
        return <div
            className={classNames("content-header-component full-width white-bg", this.props.className, 'size-' + this.props.size)}>
            <div className="content-header-content flex-row flex-content-strech pv-5">
                <div className="flex-grow-1"><span className="content-header-headline">{this.props.title}</span></div>
                <div className={this.props.toolsClassName}>{this.props.tools}</div>
            </div>
            {!this.props.hideBreadcrumb ? <div className={'pb-5'}>
                <RouterBreadcrumbs/>
            </div> : null}
            {this.props.description ? <div className={'pb-5'}><span>{this.props.description}</span></div> : null}

        </div>;
    }
}

export default ContentHeader;
