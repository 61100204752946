// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import {Query} from '@apollo/client/react/components';
import QueryTable from "../QueryTable";
import type {DataLoaderType} from "../DataLoader/DataLoaderType";
import Sizes from "../../themes/default/sizes";

import "./index.css"
import {Checkbox, Form, Select, Tag} from "antd";
import {Button} from "@dreebit/react-components";
import {toastError, toastMessage} from "../../utils/toast";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import SelectAsync from "../Form/fields/SelectAsync";
import withProfile from "../../hoc/withProfile";

type CRUDMethod = 'create' | 'update' | 'delete';

type Props = {
    componentId: string,
    options?: {
        title: string,
        value: string
    }[],
    showSearch: boolean,
    addMutation: Function,
    updateMutation: Function,
    translateMutationOptions: (method: CRUDMethod, userId, input) => any,
    removeMutation: Function,
    selectDataLoader: DataLoaderType,
    valuesDataLoader: {
        ...DataLoaderType,
        readKey: string,
        writeKey: string,
        serviceTicketsKey: string,
    },
    disableAddSelect: boolean,
    addSelectHint: any,
    componentPath: string,
    serviceTickets: boolean,
};

type State = {
    adding: boolean
}

class AccessControlPicker extends Component<Props, State> {

    _handleSelect = (selected) => {

        if (selected) {

            const {addMutation, translateMutationOptions} = this.props;

            this.performMutation(() => {
                return addMutation(translateMutationOptions('create', selected, {
                    componentAccess: 'read',
                    serviceTicketAccess: false,
                }))
            })

        }
    };

    _handleComponentAccess = (item: any, value: string) => {

        const {updateMutation, translateMutationOptions} = this.props;

        this.performMutation(() => {
            return updateMutation(translateMutationOptions('update', item.id, {
                componentAccess: value,
            }))
        })
    };

    _handleServiceTicketAccess = (item: any, value: boolean) => {
        const {updateMutation, translateMutationOptions} = this.props;

        this.performMutation(() => {
            return updateMutation(translateMutationOptions('update', item.id, {
                serviceTicketAccess: value
            }))
        })
    };

    _handleRemove = (aclId) => {
        const {removeMutation, translateMutationOptions} = this.props;

        this.performMutation(() => {
            return removeMutation(translateMutationOptions('delete', aclId))
        })

    };

    performMutation = (promise) => {
        this.setAdding(true)
            .then(promise)
            .then((res) => {
                toastMessage(_.get(res, 'data'));
                return this.setAdding(false);
            })
            .catch((err) => {
                toastError(err);
            })
    };

    setAdding = (adding) => {
        return new Promise((resolve) => {
            this.setState({
                adding
            }, () => {
                resolve(adding);
            })
        })
    };

    render() {
        const {t, disableAddSelect, addSelectHint, showSearch, selectDataLoader, valuesDataLoader, componentPath, serviceTickets, history, profile} = this.props;

        const columns = [
            {
                title: t('Name'),
                dataIndex: 'name',
                key: 'name',
                render: (text, item) => {
                    const isDefaultServiceProvider = _.get(item, 'defaultServiceProvider');
                    return <>{_.get(item, valuesDataLoader.textKey)} {isDefaultServiceProvider ? <Tag className={"ml-5"} color={'blue'}>{t("Default Service Provider")}</Tag> : null}</>
                }
            }, {
                title: t('Access inherited from'),
                dataIndex: 'component',
                key: 'component',
                width: 200,
                render: (text, item) => {
                    return !_.get(item, 'direct', false) ?
                        <Button
                            type={"link ph-0"}
                            onClick={() => {
                                history.push(componentPath.replace(':componentId', _.get(item, 'component.id')));
                            }}
                        >
                            {_.get(item, 'component.name')}
                        </Button>
                    : "-"
                }
            }, {
                title: t('Component access'),
                dataIndex: 'access',
                key: 'access',
                width: 200,
                render: (text, item) => {
                    let value = item[valuesDataLoader.readKey] ? 'read' : null;
                    if (item[valuesDataLoader.writeKey]) {
                        value = 'write';
                    }
                    return <Select
                        onChange={(val) => this._handleComponentAccess(item, val)}
                        value={value}
                        size={'small'}
                        disabled={!_.get(item, 'access.write')}
                    >
                        <Select.Option value={'read'}>{t('Read only')}</Select.Option>,
                        <Select.Option value={'write'}>{t('Write')}</Select.Option>
                    </Select>
                }
            }
        ];

        if (serviceTickets) {
            columns.push({
                title: t('Serviceticket access'),
                dataIndex: 'serviceTickets',
                key: 'serviceTickets',
                width: 200,
                render: (text, item) => {
                    let value = item[valuesDataLoader.readKey] ? 'read' : null;
                    if (item[valuesDataLoader.writeKey]) {
                        value = 'write';
                    }
                    return <Select
                        onChange={(val) => this._handleServiceTicketAccess(item, val)}
                        value={item.serviceTickets}
                        size={'small'}
                        disabled={!_.get(item, 'access.write')}
                    >
                        <Select.Option value={false}>{t('No permissions')}</Select.Option>
                        <Select.Option value={true}>{t('Read only')}</Select.Option>,
                    </Select>
                }
            });
        }

        columns.push({
            dataIndex: 'remove',
            key: 'remove',
            width: 50,
            render: (text, item) => {
                return <Button onClick={() => this._handleRemove(item.id)} size={'small'} type={'danger'} icon={"delete"} disabled={!_.get(item, 'access.write')}/>
            }
        });

        return <div className={"access-control-picker"}>
            {
                disableAddSelect ?
                    null :
                    <div>
                        {addSelectHint}
                        <SelectAsync
                            style={{width: '100%', paddingBottom: "15px"}}
                            showSearch={showSearch}
                            onSelect={this._handleSelect}
                            loaderConfig={selectDataLoader}
                        />
                    </div>
            }
            <Query
                query={valuesDataLoader.query}
                variables={valuesDataLoader.getQueryVariables ? valuesDataLoader.getQueryVariables() : null}
                fetchPolicy="cache-and-network"
            >
                {(query) => (
                    <QueryTable
                        style={{marginTop: Sizes.grid}}
                        columns={columns}
                        size={"small"}
                        itemsKeyPath={`${valuesDataLoader.dataKey}.${valuesDataLoader.itemsKey}`}
                        totalKeyPath={`${valuesDataLoader.dataKey}.${valuesDataLoader.totalKey}`}
                        query={query}
                    />
                )}
            </Query>
        </div>;
    }
}

export default compose(
    withTranslation(),
    withProfile(),
    withRouter,
)(AccessControlPicker);
