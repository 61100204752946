// @flow
import React, {useMemo, useState} from 'react';
import ProductList from "../ProductList";
import ProductToolbar from "../ProductToolbar";
import Toolbar from "../Toolbar";

interface Props {
    onProductSelect: (productId: string ) => void,
    filterValues: any,
    tableProps: any
}

const ProductFilterList = ({onProductSelect, filterValues, tableProps}: Props) => {

    const [filter, setFilter] = useState(filterValues || {});

    return (
        <div>
            <Toolbar
                borderBottom
                className={"white-bg"}
                renderLeft={() => <ProductToolbar
                    values={filter}
                    onChange={(changedValues) => {
                        setFilter({
                            ...filter,
                            ...changedValues,
                        })
                    }}
                />}
            />
            <ProductList
                filterValues={filter}
                useLink={false}
                hidePublished
                tableProps={{
                    onRow: (record, rowIndex) => {
                        return {
                            onClick: event => {
                                if (onProductSelect) {
                                    onProductSelect(record.id)
                                }
                            }
                        };
                    },
                    ...tableProps
                }}
            />
        </div>
    );
}

export default ProductFilterList;
