// @flow
import React, {Component} from 'react';
import {Input, MessageBox, SystemMessage} from "react-chat-elements";
import moment from "moment";
import _ from "lodash";
import {compose} from "redux";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import MessageListQuery from "../../graphql/queries/messageList.graphql";
import ConversationQuery from "../../graphql/queries/conversation.graphql";
import ProfileQuery from "../../graphql/queries/profile.graphql";
import CreateMessageMutation from "../../graphql/mutations/createMessage.graphql";
import UpdateConversationMutation from "../../graphql/mutations/updateConversation.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import MessageDashboardSettings from "./../MessageDashboardSettings";
import ContentHeader from "../Typo/ContentHeader";
import {Col, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import "./messengerStyles.css"
import UnreadConversationsQuery from "../../graphql/queries/UnreadConversations.graphql";
import MessageBoxContent from "./messageBoxContent";
import {socketConnect} from "socket.io-react";
import statics from "../../statics";
import ConversationListQuery from "../../graphql/queries/conversationList.graphql";


type Props = {
  conversationId: string,
  messageListQuery: any,
  profileQuery: any,
  createMessageMutation: Function,
};

type State = {
  infoVisible: boolean,
  loadingMore: boolean,
  messageList: []
}

class MessageDashboardDetail extends Component<Props, State> {

  state = {
    infoVisible: false,
    loadingMore: false,
    messageList: _.get(this.props, 'messageListQuery.messageList.messages', [])
  };

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      messageList: _.uniqBy([
        ..._.get(props, 'messageListQuery.messageList.messages', []),
        ...state.messageList,
      ],"id")
    }
  }

  constructor(props: Props) {
    super(props);
    this.chatWindowContainer = React.createRef();
  }

  componentDidMount() {

    const {socket, messageListQuery, conversationQuery, conversationId, updateConversationMutation} = this.props;
    const conversation = _.get(conversationQuery, 'conversation');

    if (!conversation.read) {
      updateConversationMutation({
        variables: {
          conversationId,
          input: {
            read: true
          }
        }
      })
    }
    if(this.chatWindowContainer.current) {
      this.chatWindowContainer.current.scrollTop = this.chatWindowContainer.current.scrollHeight;
      this.chatWindowContainer.current.addEventListener('scroll', this.onContainerScroll, false);
    }

    const me = this;
    if (socket){
      socket.on("updateConversation", function(data) {
        if(_.get(data, 'conversationId').toString() === conversationId) {
          messageListQuery.refetch().then(() => {
            if(me.chatWindowContainer.current){
              me.chatWindowContainer.current.scrollTop = me.chatWindowContainer.current.scrollHeight;
            }
          });
        }
      });
    }


  }

  componentWillUnmount() {
    if(this.chatWindowContainer.current){
      this.chatWindowContainer.current.removeEventListener('scroll', this.onContainerScroll, false);
    }
  }

  onContainerScroll= () => {

    if(this.state.loadingMore) {
      return;
    }
    if(!this.chatWindowContainer && !this.chatWindowContainer.current){
      return;
    }
    if(this.chatWindowContainer.current.scrollTop > 80) {
      return;
    }
    const {messageListQuery, conversationId} = this.props;
    const {messages} = _.get(messageListQuery, 'messageList', []);
    if(messageListQuery.total > messages.length){
      return;
    }

    this.loadMoreMessages();

  };

  loadMoreMessages() {

    const {messageListQuery, ConversationQuery, conversationId, client} = this.props;
    const {messages} = _.get(messageListQuery, 'messageList', []);

    this.setState({
      loadingMore: true
    }, () => {
      client.query({
        query: MessageListQuery,
        variables: {
          conversationId: conversationId,
          params: {
            start: messages.length,
            limit: statics.defaultPagingSize,
          },
        },
      }).then(({data}) => {
        this.setState({
          messageList: _.uniqBy([
            ...this.state.messageList,
            ..._.get(data,'messageList.messages',[])
          ],"id")
        })
      }).finally(() => {
        this.setState({
          loadingMore: false
        })
      });

    });
  }



  addMessage(input) {

    const {createMessageMutation, conversationId, conversationQuery, history, messageListQuery} = this.props;
    const isArchiveConversation = _.get(conversationQuery, 'conversation.archive');

    createMessageMutation({
      variables: {
        conversationId: conversationId,
        input: {
          text: input,
        }
      },
      refetchQueries: isArchiveConversation ? [
        {
          query: ConversationListQuery,
          variables: {
            params: {
              start: 0,
              limit: statics.defaultPagingSize,
              sort: {
                lastMessageUpdate: "DESC"
              },
              filter: {
                archive: {
                  value: false
                },
                participant: {
                  value: true
                }
              }
            }
          }
        },
        {
          query: ConversationListQuery,
          variables: {
            params: {
              start: 0,
              limit: statics.defaultPagingSize,
              sort: {
                lastMessageUpdate: "DESC"
              },
              filter: {
                archive: {
                  value: true
                },
                participant: {
                  value: true
                }
              }
            }
          }
        }
      ] : []
    }).then(() => {
      return messageListQuery.refetch()
    }).finally(() => {
      if (isArchiveConversation) {
        setTimeout(() => {
          history.push(`/messages/${conversationId}?tab=active`)
        },450)
      }
    });
  }

  groupByDates(messages) {
    const messagesByDate = _.groupBy(messages, (item) => {
      return moment(_.get(item, 'update')).format('LL');
    });

    return Object.keys(messagesByDate).reduce((acc, key) => {
      acc = [...acc, {
        type: "date",
        date: key
      }, ...messagesByDate[key].map((item) => ({
        ...item,
      }))];
      return acc;
    }, []);
  };



  render() {
    const { conversationId, messageListQuery, profileQuery, conversationQuery, t, socket} = this.props;
    const {infoVisible} = this.state;
    const messages = _.get(this.state, 'messageList', []);
    const reverseMessageArray = _.reverse(_.cloneDeep(messages));
    const conversation = _.get(conversationQuery, 'conversation');
    const serviceTicket = _.get(conversation,'serviceTicket');


    return <Row className={"full-height"}>
      <Col className={"full-height"} md={infoVisible ? 18 : 24}>
        <div className={'flex-col full-height'}>
          <ContentHeader
            size={'small'}
            title={<div>
              {_.get(conversation,'title')}
              { serviceTicket ? <span>
                                &nbsp; > <Link to={`/service/${serviceTicket.id}`}>{serviceTicket.key}</Link>
                            </span> : null }
            </div>}
            hideBreadcrumb
            tools={!infoVisible ? [
              <Icon key={'info-circle'} type={'setting'} onClick={() => this.setState({infoVisible: true})} />
            ] : null}
          />

          <div ref={this.chatWindowContainer} className={'flex-grow-1 chat-window-container'}>
            {this.groupByDates(reverseMessageArray).map(item => {
              if(_.get(item, 'author.id') === "0") {
                return <SystemMessage key={item.id} text={item.text}/>
              }else if(_.get(item, 'type') === "date"){
                return <SystemMessage key={item.date} text={item.date}/>
              }else{
                return <MessageBox
                  key={item.id}
                  position={profileQuery.profile.id === item.author.id ? 'right' : 'left'}
                  type={'text'}
                  text={<MessageBoxContent message={item}/>}
                  dateString={moment(item.update).format("HH:mm")}
                />
              }
            })}
          </div>
          <div>
            <Input
              placeholder={t("Write a message")}
              multiline={true}
              ref='input'
              onChange={(text) => {
                this.setState({
                  text: text.currentTarget ? text.currentTarget.value : null
                })
              }}
              onKeyPress={(e) => {
                if (e.shiftKey && e.charCode === 13) {
                  return true;
                }
                if (e.charCode === 13) {
                  this.addMessage(this.state.text);
                  this.refs.input.clear();
                  e.preventDefault();
                  return false;
                }
              }}
              rightButtons={
                <Button
                    icon="send"
                    type="link"
                    onClick={() => {
                      this.addMessage(this.state.text);
                      this.refs.input.clear();
                    }}
                />
              }/>
          </div>
        </div>
      </Col>
      {infoVisible ?
        <Col className={"full-height white-bg bl"} md={6}>
          <ContentHeader
              size={'small'}
              title={t('Settings')} hideBreadcrumb tools={
            [
              <Icon key={'close'} type={'close'} onClick={() => this.setState({infoVisible: false})}/>
            ]
          }/>
          <MessageDashboardSettings conversationId={this.props.conversationId}/>
        </Col> : null }
    </Row>

  }
}

export default compose(
  graphql(ProfileQuery, {
    name: 'profileQuery',
  }),
  waitWhileLoading('profileQuery', 'profile.id'),
  graphql(ConversationQuery, {
    name: 'conversationQuery',
    options: (props: Props) => ({
      variables: {
        conversationId: props.conversationId,
      }
    }),
  }),
  waitWhileLoading('conversationQuery'),
  graphql(MessageListQuery, {
    name: 'messageListQuery',
    options: (props: Props) => ({
      fetchPolicy: 'network-only',
      variables: {
        conversationId: props.conversationId,
        params: {
          start: 0,
          limit: 40,
        }
      }
    }),
  }),
  waitWhileLoading('messageListQuery', 'messageList'),
  graphql(CreateMessageMutation, {
    name: 'createMessageMutation',
  }),
  graphql(UpdateConversationMutation, {
    name: 'updateConversationMutation',
    options: () => {
      return {
        refetchQueries: [
          {
            query: UnreadConversationsQuery
          }
        ]
      }
    }
  }),
  withTranslation(),
  withApollo,
  socketConnect,
  withRouter,
)(MessageDashboardDetail);
