// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import moment from "moment";

import AccessTokenList from "../../graphql/queries/accessTokenList.graphql";
import QueryTable from "../QueryTable";

type Props = {
    t: Function,
    onClick: Function,
    accessTokenListQuery: query,
};

type State = {
    loading: boolean,
}

class ConnectorsListTable extends Component<Props, State> {

    state = {
        loading: false,
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {accessTokenListQuery, t, onClick} = this.props;

        return <QueryTable
            loading={this.state.loading}
            className={"link-table"}
            size={'middle'}
            query={accessTokenListQuery}
            itemsKeyPath={'accessTokenList.accessTokens'}
            totalKeyPath={'accessTokenList.total'}
            onRow={(record) => {
                return {
                    onClick: () => onClick ? onClick(record) : null
                };
            }}
            rowKey={"id"}
            columns={[
                {
                    dataIndex: 'name',
                    key: 'name',
                    title: t('Name')
                },
                {
                    dataIndex: 'insert',
                    key: 'insert',
                    title: t('Login'),
                    render: (text) => moment(text).format('L LT')
                },
                {
                    dataIndex: 'expires',
                    key: 'expires',
                    title: t('Expires'),
                    render: (text) => moment(text).format('L LT')
                }
            ]}/>;
    }
}


export default compose(
    withTranslation(),
    graphql(AccessTokenList, {
        name: 'accessTokenListQuery',
        options: () => ({
            fetchPolicy: "cache-and-network",
            variables: {
                params: {
                    filter: {
                        type: {
                            operator: 'in',
                            value: 'apiToken'
                        }
                    }
                }
            }
        }),
    }),
)(ConnectorsListTable);

