// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import activeServiceTicket from "../../graphql/queries/activeServiceTicket.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import SplitPanelList from "../SplitPanelList";
import {withTranslation} from "react-i18next";
import moment from "moment";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";

type Props = {
    componentId: string
};

type State = {

}

class ComponentActiveServiceTicket extends Component<Props, State> {


    activeTicketPanelProps = (lastActiveServiceTicket) => {

            const index = _.get(lastActiveServiceTicket, 'serviceTicketType.index');
            const id = _.get(lastActiveServiceTicket, 'id');
            const key = _.get(lastActiveServiceTicket, 'key');
            const name = _.get(lastActiveServiceTicket, 'name');
            const lastServcieActionCode = _.get(lastActiveServiceTicket, 'lastServiceAction.serviceActionType.code');
            const lastServcieActionName = _.get(lastActiveServiceTicket, 'lastServiceAction.serviceActionType.name');
            const insertDate = _.get(lastActiveServiceTicket, 'lastServiceAction.date',_.get(lastActiveServiceTicket, 'lastServiceAction.update'));
            const insertName = _.get(lastActiveServiceTicket, 'lastServiceAction.doneBy.name');

            return {
                link: `/service/${id}/dashboard`,
                title: `[${key}] ${name}`,
                description: `${lastServcieActionCode}: ${lastServcieActionName} (${insertName}, ${moment(insertDate).format('L LT')})`,
                leftComponent: <ServiceTicketTypeIcon index={index} size={25}/>,
                leftClass: "open-bg"
            }
    }

  render() {
    const { activeServiceTicketQuery, activeServiceTicketChildrenQuery, componentId, t } = this.props;


    const items = [];

    const lastActiveServiceTicket = _.last(_.get(activeServiceTicketQuery, 'component.serviceTicketList.serviceTickets', []));
    if (lastActiveServiceTicket){
        items.push(this.activeTicketPanelProps(lastActiveServiceTicket))
    }
/*
    const total = _.get(activeServiceTicketChildrenQuery, 'component.serviceTicketList.total', []);
      if(total && total > 0){
        items.push({
            link: `/infrastructure/${componentId}/servicetickets?children=true`,
            largeTitle: total,
            title: t('Open Service Ticket', {count: total}),
            description: t('Including tickets of sub components'),
            leftColor: Colors.open,
            icon: "wrench",
        })
      }
*/
    if (!items.length) return null;

    return <SplitPanelList items={items}/>
  }
}


export default compose(
    graphql(activeServiceTicket, {
        name: 'activeServiceTicketQuery',
        options: props => ({
            variables: {
                id: props.componentId,
                children: false
            }
        }),
    }),
    waitWhileLoading('activeServiceTicketQuery', null, {
        showLoader: false
    }),
    /*
    graphql(activeServiceTicketTotal, {
        name: 'activeServiceTicketChildrenQuery',
        options: props => ({
            variables: {
                id: props.componentId,
                children: true,
                params: {
                    filter:{
                        serviceTicketStateIndex:{
                            value: ["open", "inProgress"]
                        }
                    }
                }
            }
        }),
    }),
    waitWhileLoading('activeServiceTicketChildrenQuery', null, {
        showLoader: false
    }),

     */
    withTranslation()
)(ComponentActiveServiceTicket);
