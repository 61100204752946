import * as actionTypes from './actionTypes';

export const saveForm = (formName, values) => dispatch => {
    dispatch({
        type: actionTypes.SAVE_FORM,
        form: formName,
        data: values,
    });
};

export const setComponentBrowserComponent = (componentId) => dispatch => {
    dispatch({
        type: actionTypes.SET_COMPONENT_BROWSER_COMPONENT,
        componentId
    });
};

export const setComponentBrowserClientList = (enabled) => dispatch => {
    dispatch({
        type: actionTypes.SET_COMPONENT_BROWSER_CLIENT_LIST,
        enabled
    })
};

export const setInfrastructureSearchQuery = (query) => dispatch => {
    dispatch({
        type: actionTypes.SET_LAST_INFRASTRUCTURE_SEARCH,
        query
    });
};

export const setServiceSearchQuery = (query) => dispatch => {
    dispatch({
        type: actionTypes.SET_LAST_SERVICE_SEARCH,
        query
    });
};

export const setProductSearchQuery = (query) => dispatch => {
    dispatch({
        type: actionTypes.SET_LAST_PRODUCT_SEARCH,
        query
    });
};

export const setManufacturerSearchQuery = (query) => dispatch => {
    dispatch({
        type: actionTypes.SET_LAST_MANUFACTURER_SEARCH,
        query
    });
};

export const setServiceWorkflowQuery = (query) => dispatch => {
    dispatch({
        type: actionTypes.SET_LAST_WORKFLOW_SEARCH,
        query
    });
};

export const setServiceBudgetQuery = (query) => dispatch => {
    dispatch({
        type: actionTypes.SET_LAST_BUDGET_SEARCH,
        query
    });
};

export const setComponentBrowserWidth = (width: number) => dispatch => {
    dispatch({
        type: actionTypes.SET_COMPONENT_BROWSER_WIDTH,
        width
    })
};

export const setStockSearchEnabled = (enabled) => dispatch => {
    dispatch({
        type: actionTypes.SET_COMPONENT_BROWSER_STOCK_SEARCH,
        enabled
    })
};


export const setActiveServiceTab = (tab) => dispatch => {
    dispatch({
        type: actionTypes.SET_ACTIVE_SERVICE_TAB,
        tab
    })
};

export const setActiveInfrastructureTab = (tab) => dispatch => {
    dispatch({
        type: actionTypes.SET_ACTIVE_INFRASTRUCTURE_TAB,
        tab
    })
};

export const setProfileResources = (resources) => dispatch => {
    dispatch({
        type: actionTypes.SET_PROFILE_RESOURCES,
        resources
    });
};

export const setProfileLanguage = (language) => dispatch => {
    dispatch({
        type: actionTypes.SET_PROFILE_LANGUAGE,
        language
    });
};

export const setFileTableView = (fileTableView) => dispatch => {
    dispatch({
        type: actionTypes.SET_FILE_TABLE_VIEW,
        fileTableView
    })
};
