// @flow
import React, {Component} from "react"
import styled from "styled-components";
import Colors from "../../themes/default/colors";
import classNames from "classnames";
import {Alert} from "antd";

type Props = {
    title?: string,
    topRight?: any,
    children?: any,
    style?: any,
    containerStyle?: any,
    bordered: boolean,
    removeBackground?: boolean,
    bannerText?: string,
    bannerType?: string,
}

const Container = styled.div`
  padding: 10px;
  overflow: hidden;
  border: ${(props) => (props.bordered ? `1px solid ${Colors.greyDark}` : 'none')}
  border-radius: ${(props) => (props.bordered ? `5px` : '0')}
`;

const Wrapper = styled.div`
  margin-top: 16px;
`;

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

class Panel extends Component<Props> {

    render() {

        const {children, title, topRight, style, containerStyle, bordered, removeBackground, bannerType, bannerText} = this.props;

        const ClassNames = removeBackground ? "border relative" : "white-bg border relative";

        return <Wrapper className={'Panel'} style={style}>
            <Headline>
                {title ? <p className="text-bold ph-10">{title}</p> : <div></div>}
                {topRight}
            </Headline>
            <Container bordered={bordered} style={containerStyle} className={classNames(ClassNames, { "border-warning": bannerType === 'warning' })}>
                { bannerText ? <Alert style={{
                    margin: -11,
                    marginBottom: 8,
                }} message={bannerText} type={bannerType} showIcon /> : null}
                {children}
            </Container>
        </Wrapper>;
    }

}

export default Panel;
