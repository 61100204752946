import React from 'react';
import BorderWrapper from "../Border";
import {Grow, Row} from "../Flex";
import {Icon} from "@dreebit/react-components";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import FinalizeContaminationDeclarationMutation from "../../graphql/mutations/finalizeContaminationDeclaration.graphql";
import CreateDocuSignEnvelopeMutation from "../../graphql/mutations/createDocuSignEnvelope.graphql";
import _ from "lodash";

interface Props {
    serviceTicket: any
}

const ContaminationDeclarationSignWidget = ({serviceTicket}: Props) => {

    const {t} = useTranslation();

    const [finalizeContaminationDeclarationMutation] = useMutation(FinalizeContaminationDeclarationMutation)
    const [createDocuSignEnvelopeMutation] = useMutation(CreateDocuSignEnvelopeMutation)

    const finalizeContaminationDeclaration = () => {
        finalizeContaminationDeclarationMutation({
            variables: {
                contaminationDeclarationId: _.get(serviceTicket, 'contaminationDeclaration.id')
            }
        }).then((res) => {
            if (_.get(res, 'data.finalizeContaminationDeclaration.finalized')) {
                window.open(_.get(res, 'data.finalizeContaminationDeclaration.print.sheet.link'), '_blank');
            }
        })
    }

    const openDocuSign = () => {
        createDocuSignEnvelopeMutation({
            variables: {
                serviceTicketId: _.get(serviceTicket, 'id')
            }
        }).then((res) => {
            if (_.get(res, 'data.createDocuSignEnvelope.link')) {
                window.location.replace(_.get(res, 'data.createDocuSignEnvelope.link'));
            }
        })
    }

    return <BorderWrapper radius={5} className={`warning-border`}>
        <Row className={"p-10"}>
            <Grow>
                <div style={{lineHeight: "2em"}}>
                    <span>
                        <Icon className={`warning-color`} type={"warning"} style={{marginRight: 6}}/>
                    </span>
                    <span className={"text-dark"}>
                        {t('Contamination declaration still needs to be signed')}
                    </span>
                </div>
            </Grow>
            <div>
                <Button className={"mr-5"} onClick={finalizeContaminationDeclaration}>
                    {t('Print')}
                </Button>
                <Button type={"primary"} onClick={openDocuSign}>
                    {t('Sign via DocuSign®')}
                </Button>
            </div>
        </Row>
    </BorderWrapper>;
}

export default ContaminationDeclarationSignWidget;
