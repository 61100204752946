// @flow
import React, {Component} from 'react';
import {Table} from "antd";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

type Props = {
    attributes: any[],
};

type State = {

}

class TDATable extends Component<Props, State> {

    render() {
        const { t } = this.props;

        const columns = [
            {
                title: t('Name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: t('Index'),
                dataIndex: 'index',
                key: 'index',
            },
            {
                title: t('Type'),
                dataIndex: 'type',
                key: 'type',
            }
        ];

        return <Table style={{overflow:'scroll'}} rowKey={'index'} columns={columns} dataSource={this.props.attributes} />;
    }
}


export default compose(
    withTranslation()
)(TDATable);
