// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import FileUploadList from "../FileUploadList";
import ServiceTicketFilesQuery from "./../../graphql/queries/serviceTicketFiles.graphql";
import AddServiceTicketFilesMutation from "./../../graphql/mutations/addServiceTicketFiles.graphql";
import DeleteServiceTicketFileMutation from "./../../graphql/mutations/deleteServiceTicketFile.graphql";
import UpdateServiceTicketFileMutation from "./../../graphql/mutations/updateServiceTicketFile.graphql";

import type {FileCreateInput} from "../../types";
import SetContaminationDeclarationFileMutation
    from "../../graphql/mutations/setContaminationDeclarationServiceTicketFile.graphql";
import CreateContaminationDeclarationMutation from "../../graphql/mutations/createContaminationDeclaration.graphql";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import {checkIfServiceTicketCanCreateContaminationDeclaration} from "../../utils/ContaminationDeclarationHelper";
import RemoveContaminationDeclarationFileMutation
    from "../../graphql/mutations/removeContaminationDeclarationFile.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    addServiceTicketFilesMutation: Function,
    serviceTicketFilesQuery: any,
    deleteServiceTicketFileMutation: Function,
    updateServiceTicketFileMutation: Function,
    serviceTicketId: string,
    contaminationDeclaration?: any,
    setContaminationDeclarationFileMutation: Function,
    createContaminationDeclarationMutation: Function,
    isPvRequest?: boolean
};


class ServiceTicketDashboardFilesWidget extends Component<Props, State> {

    addDecoFile = (file) => {
        if(file && this.props.serviceTicketId){
            const {contaminationDeclaration, setContaminationDeclarationFileMutation, createContaminationDeclarationMutation, serviceTicketId} = this.props;
            const contaminationDeclarationId = _.get(contaminationDeclaration, 'id');
            if(!contaminationDeclarationId){
                createContaminationDeclarationMutation({
                    variables: {
                        serviceTicketId
                    }
                }).then(({data}) => {
                    setContaminationDeclarationFileMutation({
                        variables: {
                            contaminationDeclarationId: _.get(data, 'createContaminationDeclaration.id'),
                            fileId: file.id
                        }
                    })
                })
            }else{
                setContaminationDeclarationFileMutation({
                    variables: {
                        contaminationDeclarationId,
                        fileId: file.id
                    }
                })
            }
        }
    };

    checkFilePrivateCondition = (file) => {
        if (file.category === 'serviceActionFile') {
            return file.private ? "private" : undefined;
        } else {
            return undefined;
        }
    }

    render() {
        const {t, addServiceTicketFilesMutation, removeContaminationDeclarationFileMutation, serviceTicketFilesQuery, updateServiceTicketFileMutation, serviceTicketId, deleteServiceTicketFileMutation, contaminationDeclaration, isPvRequest} = this.props;

        const actions = [];

        const serviceTicket = _.get(serviceTicketFilesQuery, 'serviceTicket');
        const readOnly = !_.get(serviceTicket, 'access.write')

        if (!readOnly && !isPvRequest && checkIfServiceTicketCanCreateContaminationDeclaration(serviceTicket)){
            actions.push({
                key: 'addDeco',
                icon: 'diff',
                text: t('Use as contamination declaration'),
                onClick: (file) => {
                    if (serviceTicketId) {
                        this.addDecoFile(file)
                    }
                },
                check: !_.get(contaminationDeclaration, 'finalized') && !_.get(contaminationDeclaration, 'file')
            })
        }

        const contaminationDeclarationFileId = _.get(serviceTicketFilesQuery, 'serviceTicket.contaminationDeclaration.file.id');

        return <FileUploadList
            readOnly={readOnly}
            addFileMutation={addServiceTicketFilesMutation}
            addFileMutationOptions={(fileInputs: FileCreateInput[]) => ({
                variables: {
                    serviceTicketId,
                    files: fileInputs
                }
            })}
            editFileMutation={updateServiceTicketFileMutation}
            editFileMutationOptions={(file, input) => {
                return {
                    variables: {
                        serviceTicketId,
                        serviceTicketFileId: file.id,
                        input
                    }
                }
            }}
            removeFileMutation={(args) => {
                const {variables} = args;
                if (variables.serviceTicketFileId === contaminationDeclarationFileId) {
                    return removeContaminationDeclarationFileMutation({
                        variables: {
                            fileId: variables.serviceTicketFileId
                        }
                    });
                }
                return deleteServiceTicketFileMutation(args);
            }}
            removeFileMutationOptions={(file) => ({
                variables: {
                    serviceTicketId,
                    serviceTicketFileId: file.id
                }
            })}
            fileListQuery={serviceTicketFilesQuery}
            fileListKey={'serviceTicket.serviceTicketFileList.serviceTicketFiles'}
            customActions={actions}
            checkPrivateCondition={(file) => this.checkFilePrivateCondition(file)}
        />;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceTicketFilesQuery, {
        name: 'serviceTicketFilesQuery',
        options: (props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketFilesQuery', ['serviceTicket.serviceTicketFileList.serviceTicketFiles']),
    graphql(AddServiceTicketFilesMutation, {
        name: 'addServiceTicketFilesMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceTicketFilesQuery,
                    variables: {
                        id: props.serviceTicketId
                    }
                }
            ]
        })
    }),
    graphql(UpdateServiceTicketFileMutation, {
        name: 'updateServiceTicketFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceTicketFilesQuery,
                    variables: {
                        id: props.serviceTicketId
                    }
                }
            ]
        })
    }),
    graphql(RemoveContaminationDeclarationFileMutation, {
        name: 'removeContaminationDeclarationFileMutation'
    }),
    graphql(DeleteServiceTicketFileMutation, {
        name: 'deleteServiceTicketFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceTicketFilesQuery,
                    variables: {
                        id: props.serviceTicketId
                    }
                }
            ]
        })
    }),
    graphql(SetContaminationDeclarationFileMutation, {
        name: 'setContaminationDeclarationFileMutation',
    }),
    graphql(CreateContaminationDeclarationMutation, {
        name: 'createContaminationDeclarationMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: serviceTicketDashboard,
                    variables: {
                        id: props.serviceTicketId
                    }
                }
            ]
        })
    }),
)(ServiceTicketDashboardFilesWidget);
