import * as actionTypes from '../actions/actionTypes';

const initialState = {
    menuOpen: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MENU_TOGGLE:
            return {menuOpen: !state.menuOpen};

        case actionTypes.MENU_OPEN:
            return {menuOpen: true};

        case actionTypes.MENU_CLOSED:
            return {menuOpen: false};
        default:
            return state;
    }
};

export default reducer;
