import React from 'react';

import {Menu} from 'antd';
import {Icon} from "@dreebit/react-components";
import {Link} from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import {arrayToTree, queryArray} from './../../utils';

/*type Props = {
  menu: [],
  siderFold: boolean,
  darkTheme: boolean,
  handleClickNavMenu: ?Function,
  navOpenKeys: [],
  changeOpenKeys: ?Function,
  location: ?any,
}*/

const Menus = ({
                   siderFold,
                   darkTheme,
                   handleClickNavMenu,
                   navOpenKeys,
                   changeOpenKeys,
                   menu,
                   location,
               }) => {
    // 生成树状
    const menuTree = arrayToTree(menu.filter(_ => _.mpid !== '-1'), 'id', 'mpid');
    const levelMap = {};

    // 递归生成菜单
    const getMenus = (menuTreeN, siderFoldN) =>
        menuTreeN.map(item => {
            if (item.children) {
                if (item.mpid) {
                    levelMap[item.id] = item.mpid;
                }
                return (
                    <Menu.SubMenu
                        key={item.id}
                        title={
                            <span>
                {item.icon && <Icon type={item.icon}/>}
                                {(!siderFoldN || !menuTree.includes(item)) && item.name}
              </span>
                        }
                    >
                        {getMenus(item.children, siderFoldN)}
                    </Menu.SubMenu>
                );
            }
            return (
                <Menu.Item key={item.id}>
                    <Link to={item.route || '#'}>
                        {item.icon && <Icon type={item.icon}/>}
                        {(!siderFoldN || !menuTree.includes(item)) && item.name}
                    </Link>
                </Menu.Item>
            );
        });
    const menuItems = getMenus(menuTree, siderFold);


    const menuProps = !siderFold
        ? {
            openKeys: navOpenKeys,
        }
        : {};

    // 寻找选中路由
    let currentMenu;
    let defaultSelectedKeys;
    for (const item of menu) {
        if (item.route && pathToRegexp(item.route).exec(location.pathname)) {
            currentMenu = item;
            break;
        }
    }
    const getPathArray = (array, current, pid, id) => {
        const result = [String(current[id])];
        const getPath = item => {
            if (item && item[pid]) {
                result.unshift(String(item[pid]));
                getPath(queryArray(array, item[pid], id));
            }
        };
        getPath(current);
        return result;
    };
    if (currentMenu) {
        defaultSelectedKeys = getPathArray(menu, currentMenu, 'mpid', 'id');
    }

    if (!defaultSelectedKeys) {
        defaultSelectedKeys = ['1'];
    }

    return (
        <Menu
            {...menuProps}
            mode={siderFold ? 'vertical' : 'inline'}
            theme={darkTheme ? 'dark' : 'light'}
            onClick={handleClickNavMenu}
            selectedKeys={defaultSelectedKeys}
        >
            {menuItems}
        </Menu>
    );
};


export default Menus;
