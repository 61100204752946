import React, {useState} from 'react';
import {Button, Input} from "antd";
import {useTranslation} from "react-i18next";
import ClientSuggestionItem from "../ClientSuggestionItem";
import ClientSearchQuery from "../../graphql/queries/clientSearch.graphql"
import {useLazyQuery} from "@apollo/client";
import AttributesInlineForm from "../Form/attributes-inline-form";

interface Props {
    onNextStep: () => void,
    setClient: (client: string) => void,
    searchClientQuery: string,
    setSearchClientQuery: (query: string) => void
}

const CreateClientGeneralStep = ({onNextStep, setClient, searchClientQuery, setSearchClientQuery }: Props) => {

    const {t} = useTranslation();
    const [listViewActive, setListViewActive] = useState(false);

    const [clientSearch, {loading, data, error}] = useLazyQuery(ClientSearchQuery)

    const onSearch = (query) => {
        setSearchClientQuery(query);

        clientSearch({
            variables: {
                params: {
                    search: {
                        query: query
                    },
                    start: 0,
                    limit: 5
                }
            }
        })
        .then((res) => {
            const clients = _.get(res, 'data.clientSearch.clients', []);
            if (clients.length > 0) {
                setListViewActive(true)
            } else {
                onNextStep()
            }
        })
    }

    const list = _.get(data, 'clientSearch.clients', [])

    const { Search } = Input;

    const onClientItemClick = (client) => {
        setClient(client)
        onNextStep()
    }

    if (listViewActive && !loading) {
        return <div className={"w-75"} style={{margin: "auto"}}>
            {
                list.length > 0 ?
                    <div>{t("Did you mean an existing client")}?</div>
                : null
            }
            {
                list.map((client) => {
                    const clientStreet = _.get(client, 'street', '')
                    const clientNumber = _.get(client, 'number', '')
                    const clientZip = _.get(client, 'zip', '')
                    const clientCity = _.get(client, 'city', '')

                    const subtitle =
                        (clientStreet ? clientStreet : '') +
                        (clientNumber ? ` ${clientNumber}` : '') +
                        (((clientStreet || clientNumber) && (clientZip || clientCity)) ? ", " : "") +
                        (clientZip ? clientZip : '') +
                        (clientCity ? ` ${clientCity}` : '')

                    return <ClientSuggestionItem
                        key={client.id}
                        className={'mt-10'}
                        title={_.get(client, 'name')}
                        subtitle={subtitle}
                        imageSrc={_.get(client, 'avatar.thumbnailLink')}
                        onClick={() => onClientItemClick(client)}
                    />
                })
            }
            <div className={"mt-20"}>{t("or create a new client")}:</div>
            <ClientSuggestionItem
                className={"mt-10"}
                title={searchClientQuery}
                subtitle={t("register as a new client in the system")}
                imageSrc={null}
                onClick={onNextStep}
            />

            <Button
                className={"mt-10"}
                type="link"
                onClick={() => setListViewActive(false)}
            >
                {'< ' + t('Back')}
            </Button>
        </div>
    }

    const attributes = [
        {
            index: "searchQuery",
            inputType: "text",
            type: "string"
        }
    ]

    return (
        <div className={"flex-col w-75"} style={{ margin: "auto" }}>
            <div className={'mb-20'}>
                {t('Please enter the name of the client you would like to create. The VSM will check whether the client already exists in the system.')}
            </div>
            <AttributesInlineForm
                attributes={attributes}
                mutation={(values) => {
                    onSearch(_.get(values, 'variables.searchQuery'))
                }}
                useSubmit
                submitTitle={t('Next')}
            />
        </div>
    );

}

export default CreateClientGeneralStep;
