import React from "react";
import Panel from "../Panel";
import {useTranslation} from "react-i18next";

interface Props {

}

const ComponentMoveTokenInfoText = (props: Props) => {

    const {t} = useTranslation();

    return <div>
        <div>{t("Users can use this token to move the component to their own infrastructure")}</div>
        <br/>
        <ul>
            <li>{t("The token can only be used once")}</li>
            <li>{t("The token does not expire")}</li>
            <li>{t("You are able to remove the token anytime")}</li>
            <li className={"bold"}>{t("Once the token was used by a third party user you might not be able to access this component anymore")}</li>
        </ul>
    </div>;
}

export default ComponentMoveTokenInfoText;
