// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import StarredServiceTicketsQuery from "./../../graphql/queries/serviceTicketStarred.graphql";
import type {Query} from "../../types";
import {withRouter} from "react-router-dom";
import ServiceTicketsTable from "../ServiceTicketsTable";

type Props = {
    starredServiceTicketsQuery: Query,
};


class StarredServiceTicketsList extends Component<Props, State> {


    render() {
        const {starredServiceTicketsQuery} = this.props;

        return <ServiceTicketsTable
            itemsKeyPath={'starredServiceTicketList.serviceTickets'}
            totalKeyPath={'starredServiceTicketList.total'}
            query={starredServiceTicketsQuery}/>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(StarredServiceTicketsQuery, {
        name: 'starredServiceTicketsQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    start: 0,
                    limit: 50,
                }
            }
        }),
    }),
)(StarredServiceTicketsList);
