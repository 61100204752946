// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import TDAAttributesForm from "../TDAAttributesForm";
import {Col, Row} from "antd";
import JSONPretty from "react-json-pretty"

import {attributeConfig} from "./attributes";
import ContentWrapper from "../ContentWrapper";
import Panel from "../Panel";

type Props = {
    t: Function
};

type State= {
    values: any
}

class FormTest extends Component<Props, State> {
    state = {
        values: {

        }
    };

    render() {
        const {t} = this.props;

        return <ContentWrapper>
            <Row gutter={16}>
                <Col md={14}>
                    <Panel bordered={false} title={t('form')}>
                        <ContentWrapper>
                            <TDAAttributesForm
                                layout={'horizontal'}
                                onChange={(changedValues, allValues)=>{
                                    this.setState({
                                        values: allValues
                                    })
                                }}
                                submit={({values})=>{
                                    return new Promise((resolve, reject)=>{
                                        setTimeout(()=>{
                                            resolve({
                                                message: 'Nice!',
                                                successIndicators: Object.keys(values)
                                            });
                                        },1000)
                                    })
                                }}
                                attributes={attributeConfig}
                                values={this.state.values}
                            />
                        </ContentWrapper>
                    </Panel>
                </Col>
                <Col md={10}>
                    <Panel bordered={false} title={t('data')}>
                        <ContentWrapper>
                            <JSONPretty data={this.state.values}/>
                        </ContentWrapper>
                    </Panel>
                </Col>
            </Row>
        </ContentWrapper>;
    }
}

export default compose(
    withTranslation()
)(FormTest);