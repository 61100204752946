// @flow
import React, { Component } from 'react';
import _ from "lodash";
import withProfile from "../../hoc/withProfile";
import isServiceProvider from "../../utils/isServiceProvider";

type Props = {

};

type State = {

}

class ServiceProviderOnly extends Component<Props, State> {

  render() {
    const { children, resources } = this.props;
    if (isServiceProvider(resources)){
        return children;
    }

    return null;
  }
}

export default withProfile()(ServiceProviderOnly);
