// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import Empty from "../Empty";
import moment from "moment";
import {Query} from '@apollo/client/react/components';
import VersionableAttributeListQuery from "../../graphql/queries/VersionableAttributeList.graphql";
import statics from "../../statics";
import TDAAttributesForm from "../TDAAttributesForm";
import {withSize} from "react-sizeme";
import {Col, Row} from "antd";
import Toolbar from "../Toolbar";
import Loader from "../Loader";

type Props = {
    t: Function,
    tda?: any,
    componentId: string,
    index: string,
    startDate: any,
    endDate: any,
    onDateChange: Function
};

type State = {

}

class VersionableChart extends Component<Props, State> {
    state= {
        chartSettings: {
            scale: 'linear'
        },
        filterChanges: null
    };

    render() {
        const { t, size, index,componentId, tda } = this.props;

        const filter = this.state.filterChanges && this.state.filterChanges.range ? {
            update: {
                operator: 'range',
                range: {
                    from: _.get(this.state.filterChanges,"range[0]"),
                    to: _.get(this.state.filterChanges,"range[1]")
                }
            }
        } : undefined;


        return <div>
            <Toolbar
                renderLeft={() => <Row>
                    <Col md={16}>
                        <TDAAttributesForm
                            layout={'horizontal'}
                            formStyles={{
                                formItemLayout: {
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 4 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 20 },
                                    },
                                }
                            }}
                            onChange={(val, {allValues}) => this.setState({
                                filterChanges: allValues
                            })}
                            useSubmit={false}
                            values={this.state.filterChanges}
                            attributes={[
                                {
                                    index: 'range',
                                    type: "string",
                                    inputType: "daterangepicker",
                                    name: t('date range'),
                                }
                            ]}
                        />
                    </Col>
                </Row>}
                renderRight={() => {

                    return <div style={{width: 300}}>
                        <TDAAttributesForm
                            layout={'horizontal'}
                            formStyles={{
                                formItemLayout: {
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 4 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 20 },
                                    },
                                }
                            }}
                            onChange={(val, {allValues}) => this.setState({
                                chartSettings: allValues
                            })}
                            useSubmit={false}
                            values={this.state.chartSettings}
                            attributes={[
                                {
                                    index: 'scale',
                                    type: "string",
                                    inputType: "select",
                                    optionValues: [{
                                        value: 'linear',
                                        title: t('Linear')
                                    },{
                                        value: 'log',
                                        title: t('Logarithmic')
                                    },{
                                        value: 'pow',
                                        title: t('Pow')
                                    },{
                                        value: 'ordinal',
                                        title: t('Ordinal')
                                    }],
                                    name: t('Scale'),
                                }
                            ]}
                        />
                    </div>

                }}
            />

            <Query
                query={VersionableAttributeListQuery}
                variables={{
                    id: componentId,
                    index,
                    params: {
                        filter,
                        start: 0,
                        limit: !filter ? statics.defaultPagingSize : undefined,
                        sort: {
                            update: 'DESC'
                        }
                    }
                }
                }
            >
                {(query) => {

                    if (query.loading){
                        return <Loader/>
                    }

                    let chartData = _.get(query,'data.versionableAttributeList.versionableAttributes',[]);

                    if (!chartData.length){
                        return <Empty/>
                    }

                    chartData = chartData.slice(0, 20);
                    chartData = chartData.map((el) => ({
                        date: new Date(el.update).getTime(),
                        value: _.get(el, 'floatValue', _.get(el,'intValue'))
                    }));
                    chartData = chartData.reverse();

                    return <LineChart width={size.width} height={300} data={chartData}>
                        <Line dataKey='value' stroke="#8884d8" />
                        <Tooltip
                            label={tda ? tda.name : t('Value')}
                            labelFormatter={(label)=> moment(label).format('DD.MM.YYYY hh:mm:ss')}/>
                        <XAxis
                            tickCount={10}
                            type="number"
                            scale={'time'}
                            name={t("Date")}
                            domain={['dataMin', 'dataMax']}
                            tickFormatter={(val) => moment(val).format('DD.MM.YYYY hh:mm:ss')}
                            dataKey="date"
                        />
                        <YAxis
                            type="number"
                            domain={[dataMin => (dataMin * 0.95), dataMax => (dataMax * 1.05)]}
                            name={tda ? tda.name : t('Value')}
                            scale={this.state.chartSettings.scale}
                            dataKey='value'/>
                        <Tooltip />
                    </LineChart>
                }}
            </Query>
        </div>
    }
}

export default compose(
    withTranslation(),
    withSize()
)(VersionableChart);
