// @flow
import React, {Component} from "react"
import {Dropdown} from "antd";
import {Button, Icon} from "@dreebit/react-components";


type Props = {
    menu: any,
    title: string,
    style: any,
}

class MenueButton extends Component<Props, State> {
    render() {
        const {menu, title, style} = this.props;

        return <div style={style}>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button style={{width: '130px'}}>
                    {title} <Icon type="down"/>
                </Button>
            </Dropdown>
        </div>
    }
}

export default MenueButton;
