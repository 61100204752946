// @flow
import React, {Component} from "react"
import _ from 'lodash'
import ComponentBrowser from "./../../ComponentBrowser";
import {Button as AntdButton, Popover} from "antd";
import {Button} from "@dreebit/react-components";
import {compose} from "redux";
import componentName from "./../../../graphql/queries/component-Name.graphql";
import {Query} from "@apollo/client/react/components";
import {withTranslation} from "react-i18next";
import FontAwesomeIcon from "../../Icons/FontAwesomeIcon";
import {compareObjectKeys} from "../../../utils/compare";
import isServiceProvider from "../../../utils/isServiceProvider";
import withProfile from "../../../hoc/withProfile";
import {graphql} from "@apollo/client/react/hoc";
import ComponentListTotalQuery from "../../../graphql/queries/componentListTotal.graphql";


type Props = {
    value?: number,
    onChange?: Function,
    placeholder?: string,
    title?: string,
    useNavigator: ?boolean,
    addonAfter: any,
    componentNameQuery?: {
        loading: boolean,
        component: {
            id: string,
            name: string
        }
    },
    hideDeleteButton?: boolean,
    showOnlyComponent?: boolean,
    titleOnly?: boolean
}

type State = {
    browserComponentId?: number,
    selectedComponent?: number,
    visible?: boolean | null,
    clientListView: boolean
}

class ComponentPicker extends Component<Props, State> {

    static defaultProps: Props = {
        title: 'Select Component',
        hideDeleteButton: false,
        titleOnly: false
    };

    constructor(props) {
        super(props);

        this.state = {
            browserComponentId: props.value,
            selectedComponent: props.value,
            visible: null,
            clientListView: _.get(props.clientComponentsTotalQuery, 'componentList.total') > 1
        }

    }

    shouldComponentUpdate(nextProps, nextState) {

        const res = !compareObjectKeys(this.props, nextProps, [
            "value",
            "useNavigator",
            "placeholder",
            "title",
            "showOnlyComponent",
            "componentNameQuery",
        ]) || !compareObjectKeys(this.state, nextState, [
            "browserComponentId",
            "selectedComponent",
            "visible",
            "clientListView"
        ]);
        return res;
    }

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    };

    renderPopOver() {

        const {title, componentNameQuery, t, hideDeleteButton, titleOnly} = this.props;
        const {visible, selectedComponent} = this.state;



        return <div style={{position: 'relative'}}>
            <AntdButton.Group>

                <Popover visible={visible} onVisibleChange={(visible) => {
                    this.setState({visible})
                }} overlayClassName={"no-padding-popover"} trigger={'click'} placement="bottom"
                         content={<div style={{height: 400, width: 300, overflow: 'hidden'}} className={"white-bg"}>
                             {this.renderBrowser()}
                         </div>}>

                    {
                        !titleOnly && selectedComponent ? <Query query={componentName} variables={{
                            id: selectedComponent
                        }} >
                            { ({data, loading}) => {
                                return <Button data-cy={'TEST_componentPickerComponentNameButton'} loading={loading}>
                                    {_.get(data, 'component.name')}
                                </Button>
                            }
                            }
                        </Query> :  <Button data-cy={'TEST_componentPickerComponentNameButton'}>{t(title)}</Button>
                    }


                </Popover>
                {this.state.selectedComponent ? hideDeleteButton ? null :
                    <Button data-cy={'TEST_componentPickerCloseButton'} onClick={() => {
                        this.setState({
                            selectedComponent: null
                        });
                        this.triggerChange(null);
                    }}><FontAwesomeIcon name={'times'}/></Button> : null}
            </AntdButton.Group>
        </div>

    }

    render() {


        if (this.props.useNavigator) {
            return <div style={{minHeight: 300, position: "relative", border: "1px solid #ececec"}}>
                {this.renderBrowser()}
            </div>;
        }

        const {addonAfter} = this.props;

        if (addonAfter) {
            return <div className={"ant-input-group-wrapper"}>
                <AntdButton.Group className={"ant-input-group"}>
                    {this.renderPopOver()}
                    <span className={"ant-input-group-addon"}>
          {addonAfter}
        </span>
                </AntdButton.Group>
            </div>
        }

        return this.renderPopOver();
    }


    renderBrowser() {

        const {browserComponentId, selectedComponent, clientListView} = this.state;

        return <ComponentBrowser
            size={'small'}
            hideTools
            hidePathButton
            defaultSearchOption={"global"}
            showOnlyComponent={this.props.showOnlyComponent}
            selectedComponentId={selectedComponent}
            onSelectChange={({id}, selected) => {
                let selectedComponent = null;
                if (selected) {
                    selectedComponent = id
                }
                this.setState({
                    selectedComponent,
                    visible: false
                });
                this.triggerChange(selectedComponent);
            }}
            finderId={browserComponentId}
            onPathItemClick={(component) => {
                this.setState({
                    browserComponentId: component.id
                })
            }}
            onClick={(component) => {
                if (this.state.clientListView) {
                    this.setState({
                        clientListView: false
                    });
                }
                this.setState({
                    browserComponentId: component.id
                })
            }}
            onSetClientListViewActive={() => {
                this.setState({
                    clientListView: true
                });
            }}
            clientListView={clientListView}
        />
    }
}

export default compose(
    withTranslation(),
    withProfile(),
    graphql(ComponentListTotalQuery, {
        name: 'clientComponentsTotalQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: {
                        componentTypeIndex: {
                            value: ["client"]
                        }
                    }
                }
            }
        })
    }),
)(ComponentPicker);
