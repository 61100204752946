// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {Grow, Row} from "../Flex";
import {Avatar} from "antd";
import Bold from "../Utilities/Bold";
import {UserOutlined} from "@ant-design/icons";

type Props = {
    user: any,
};

type State = {

}

class UserInfoBox extends Component<Props, State> {

  render() {
    const { user } = this.props;

    const email = _.get(user,'email');

    return <Row>
        <div className={"ph-10"}>
            <Avatar size={48} icon={<UserOutlined/>}/>
        </div>
        <Grow className={"ph-5"}>
            <div>
                <Bold>{_.get(user,'name')}</Bold>
            </div>
            {
                email ? <div>
                    <a href={`mailto:${email}`}>
                        {email}
                    </a>
                </div> : null
            }

        </Grow>
    </Row>;
  }
}

export default UserInfoBox;
