// @flow

import {message} from "antd";
import _ from 'lodash'
import Strings from "../i18n/strings";
import findKey from "./findKey";
import React from "react";
import FeedbackButton from "../components/FeedbackButton";
import TranslatedLabel from "../components/TranslatedLabel";
import GraphQLProvider from "../graphql/provider";
import store from "../store/store";
import {Provider} from "react-redux";

export const toastError = (error, data) => {

    let content = _.get(error, 'message', error || Strings.errorOccurred);
    if (!_.isString(content)){
        content = JSON.stringify(content);
    }

    if (data){
        return message.error(<div>
            <span>{content}</span>
            {
                error && ( 400 >= error.status < 500 )
                    ? null
                    : <div>
                        <Provider store={store}>
                            <GraphQLProvider>
                                <FeedbackButton
                                    title={<TranslatedLabel>Feedback</TranslatedLabel>}
                                    feedbackData={data}
                                />
                            </GraphQLProvider>
                        </Provider>
                    </div>
            }
        </div>);
    }
    return message.error(content);
};

export const toastSuccess = (string: String | any) => {

    let text = _.isString(string) ? string : findKey(string, 'message');
    if (_.isString(text)) {
        message.success(text);
    }
};

export const toastMessage = (string: String | any) => {

    let text = _.isString(string) ? string : findKey(string, 'message');
    if (_.isString(text)) {
        message.success(text);
    }
};


