// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ServiceTicketListInsertUpdateQuery from "../../graphql/queries/serviceTicketListInsertUpdate.graphql";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {getAvgLeadTimeInDays} from "./helperFunctions";

type Props = {
  userParams: {
    dateRange: {
      from: string,
      to: string
    },
    componentTypes: [string]
  }
};

type State = {

}

class ServiceTicketLeadTime extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { serviceTicketListInsertUpdateQuery } = this.props;

    let serviceTickets = _.get(serviceTicketListInsertUpdateQuery, 'serviceTicketList.serviceTickets');
    console.log(getAvgLeadTimeInDays(serviceTickets));

    return <div className={'flex flex-col flex-align-items-center mt-20'}>
      <span style={{fontSize: 40, fontWeight: 'bold'}}>{getAvgLeadTimeInDays(serviceTickets)} d</span>
    </div>;
  }
}

export default compose(
  graphql(ServiceTicketListInsertUpdateQuery, {
    name: 'serviceTicketListInsertUpdateQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          start: 0,
          limit: 100000,
          sort: {
            update: 'ASC'
          },
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            clientId: {operator: 'nin', value: 602},
            componentTypeIndex: {operator: 'in', value: _.get(props, 'userParams.componentTypes')},
            update: {operator: 'range', range: _.get(props, 'userParams.dateRange')}
          }
        }
      }
    }),
  }),
  waitWhileLoading('serviceTicketListInsertUpdateQuery', 'serviceTicketList.serviceTickets'),
)(ServiceTicketLeadTime);
