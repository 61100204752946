// @flow
import React, {Component} from 'react';
import AttributesInlineForm from "../Form/attributes-inline-form";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import CreateServiceActionMutation from "../../graphql/mutations/createServiceAction.graphql";

type Props = {
    serviceTicketIds: string[],
    onSubmit: Function
};




class MoveTicketToDepartment extends Component<Props, State> {


    render() {
        const {t, serviceTicketIds, createServiceActionMutation, onSubmit} = this.props;

        const attributes = [{
            index: 'serviceDepartmentId',
            name: t('Department'),
            rules: [{name: 'required'}],
            inputType: 'select',
            entityType: 'serviceDepartment',
            type: 'string'
        },{
            index: 'assigneeId',
            name: t('Assignee'),
            inputType: 'select',
            entityType: 'user',
            type: 'string'
        }];

        return <div className={"pv-10"}>

            <AttributesInlineForm
                attributes={attributes}
                translateMutationOptions={(values) => {
                    return values
                }}
                mutation={({values}) => {
                    const promises = serviceTicketIds.map((serviceTicketId) => {

                        return createServiceActionMutation({
                            variables: {
                                serviceActionTypeIndex: 'serviceTicketAssignedToServiceDepartment',
                                serviceTicketId,
                                input: values
                            }
                        })
                    });

                    return Promise.all(promises);
                }}
                onSubmit={onSubmit}/>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(CreateServiceActionMutation, {
        name: 'createServiceActionMutation',
    }),
)(MoveTicketToDepartment);
