// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import UpdateServiceTicketMutation from "./../../graphql/mutations/updateServiceTicket.graphql";
import Error from "../Error";
import MultiActionWrapper from "../MultiActionWrapper";
import serviceTicketAttributes from "../../graphql/queries/serviceTicketAttributes.graphql";
import TDAAttributesForm from "../TDAAttributesForm";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    componentIds: string,
    onFinished?: () => void
};



class ServiceTicketsMultiForm extends Component<Props> {

    _handleChange = (selectedId) => {
        this.setState({
            selectedId
        })
    };


    render() {
        const {serviceTicketIds, updateServiceTicketMutation,attributes, t, onFinished} = this.props;

        if (!serviceTicketIds || !serviceTicketIds.length){
            return <Error title={t('No components to move')} />
        }

        return <MultiActionWrapper
            onFinished={onFinished}
            total={serviceTicketIds.length}
            mutation={updateServiceTicketMutation}>
            {({queue}) => {
                return <TDAAttributesForm
                    disableToasts
                    attributes={attributes}
                    translateMutationOptions={({changes}) => {
                        return changes
                    }}
                    mutation={(changes) => {
                        return new Promise((resolve, reject) => resolve(changes));
                    }}
                    onSubmit={(values) => {
                        serviceTicketIds.forEach((serviceTicketId) => {
                            queue.push({
                                variables: {id: serviceTicketId, attributes: values}
                            })
                        })
                    }}
                />
            }}
        </MultiActionWrapper>
    }
}

export default compose(
    graphql(serviceTicketAttributes, {
        name: 'serviceTicketAttributesQuery',
        options: (props) => ({
            variables: {
                id: _.first(props.serviceTicketIds)
            }
        })
    }),
    waitWhileLoading('serviceTicketAttributesQuery','serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(UpdateServiceTicketMutation, {
        name: 'updateServiceTicketMutation'
    }),
    withTranslation(),
    withProps((props) => ({
        attributes: _.get(props.serviceTicketAttributesQuery,'serviceTicket.typeDefinitionAttributeList.typeDefinitionAttributes',[])
            .map((item) => ({
                ...item,
                rules: null,
                value: null
            }))
    }))
)(ServiceTicketsMultiForm);
