// @flow
import React from "react"
import {useTranslation} from "react-i18next";
import SplitPanelList from "../SplitPanelList";

type Props = {
    componentId: string,
    alertType?: string,
    count?: any,
    icon?: string
}

const IconSplitPanelActiveAlerts = ({componentId, alertType, count, icon}: Props) => {
    const {t} = useTranslation();

    return <SplitPanelList
        items={[
            {
                link: `/infrastructure/${componentId}/logs?logType=componentAlert&status=active&logLevel=${alertType}`,
                largeTitle: count,
                title: `${t(`active ${t(alertType)}`, {count})}`,
                description: t('Click here to show all active alerts of this component'),
                icon: icon ? icon : "alert",
                leftClass: `${alertType}-bg`
            }
        ]}
    />
}


export default IconSplitPanelActiveAlerts;




