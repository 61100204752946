// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import type {Attribute} from "../../types";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Toolbar from "../Toolbar";
import {withRouter} from "react-router-dom";
import withProfile from "../../hoc/withProfile";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import BookmarkQuery from "../../graphql/queries/bookmarkData.graphql";
import TDAAttributesForm from "../TDAAttributesForm";

type Props = {
    params?: {
        [string]: any
    },
    renderRight?: Function,
    onChange?: Function,
    history: {
        push: Function
    }
}

type State = {
    filterAttributes: Array<Attribute>
}

export const getComponentAttributes = (t, props?) => ([
    {
        index: 'query',
        type: 'string',
        inputType: 'text',
        placeholder: t('Search'),
        debounceTime: 500,
        addOnAttribute: {
            index: 'fields',
            type: 'string',
            inputType: 'select',
            placeholder: t('Fields'),
            multiple: true,
            maxTagCount: 0,
            optionValues: [
                {
                    value: 'name',
                    name: t('Name')
                }, {
                    value: 'serialNo',
                    name: t('Serial')
                }, {
                    value: 'catalogNo',
                    name: t('Catalog No')
                }
            ]
        },
    },
    {
        index: 'parentId',
        type: 'string',
        inputType: 'component',
    },
    {
        index: 'componentOptions',
        placeholder: t('Options'),
        type: 'string',
        inputType: 'select',
        maxTagCount: 0,
        multiple: true,
        optionValues: [
            {
                value: 'subComponentsOnly',
                name: t('Search only for direct sub components')
            }, {
                value: 'findAllComponentTypes',
                name: t('Search for all component types')
            }, {
                value: 'stockSearch',
                name: t('Search in stock') //@TODO: [TM] Check resource stockManagement!
            }
        ]
    },

    {
        index: 'componentTypeIndex',
        type: 'string',
        entityType: 'enum',
        enumType: 'ComponentTypeIndex',
        maxTagCount: 1,
        inputType: 'select',
        multiple: true,
        placeholder: t('Component Type'),
    }, {
        index: 'manufacturerId',
        type: 'string',
        entityType: 'manufacturer',
        inputType: 'select',
        placeholder: t('Manufacturer'),
    }
]);

class InfrastructureSearchToolbar extends Component<Props, State> {

    constructor(props) {
        super(props);
        const {t} = this.props;

        this.state = {
            filterAttributes: getComponentAttributes(t, this.props)
        }
    }

    onReset() {
        this._handleChanges();
    }

    _handleChanges = (changes) => {
        const {onChange, params} = this.props;

        if (onChange) {
            if (!changes) {
                onChange(null)
            } else {
                const values = {
                    ...params,
                    ...changes
                };
                onChange(values)
            }
        }
    };

    render() {
        const {params, t, renderRight, bookmarkQuery, typeDefinitionAttributeListQuery} = this.props;

        const attributes = _.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', [])
        const attr = [...attributes];

        if (bookmarkQuery && _.get(bookmarkQuery, 'bookmark.data')) {
            const bookmarkData = JSON.parse(_.get(bookmarkQuery, 'bookmark.data'));
            const count = _.get(bookmarkData, 'componentIds', []).length;

            attr.unshift({
                index: 'selectionBookmark',
                inputType: 'button',
                name: t("Selection") + `: ${_.get(bookmarkQuery, 'bookmark.title')} (${count})`,
                icon: 'delete',
                onClick: () => {
                    this._handleChanges({
                        selectionBookmark: null
                    })
                }
            })
        }

        return <Toolbar renderRight={renderRight}
                        renderLeft={() => {
                            return <div data-cy='infrastructure-search-toolbar' className={"inline-child"}>
                                {
                                    this.props.renderLeft
                                        ? this.props.renderLeft()
                                        : null
                                }
                                <TDAAttributesForm
                                    containerClassName={"inline"}
                                    formType={'modal'}
                                    valueToolbarOnly={true}
                                    cacheChanges={false}
                                    layout={'inline'}
                                    onChange={this._handleChanges}
                                    useSubmit={false}
                                    values={params}
                                    attributes={attr.map((item) => ({...item, value: undefined}))}
                                />
                            </div>
                        }}
        />
    }
}

export default compose(
    withTranslation(),
    withRouter,
    withProfile(),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props) => {
            return {
                variables: {
                    index: 'componentFilter'
                }
            }
        }
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery'),
    graphql(BookmarkQuery, {
        name: 'bookmarkQuery',
        skip: (props) => !_.get(props, 'params.selectionBookmark'),
        options: (props) => {
            return {
                variables: {
                    bookmarkId: _.get(props, 'params.selectionBookmark')
                }
            }
        }
    }),
    waitWhileLoading('bookmarkQuery', null, {
        optional: true,
        allowErrors: true
    })
)(InfrastructureSearchToolbar);
