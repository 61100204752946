// @flow
import React, { Component } from 'react';
import {compose} from "recompose";
import {graphql} from "@apollo/client/react/hoc";
import {Col, Row} from "antd";
import _ from "lodash";
import Sizes from "../../themes/default/sizes";
import ComponentProductAttributesQuery from "../../graphql/queries/componentProductAttributes.graphql";
import ComponentProductSpareParts from "../ComponentProductSpareParts";
import Panel from "../Panel";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
  componentId: number,
  productId: number
};

class ComponentProductDetailsDashboard extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { componentId, t, componentProductAttributesQuery } = this.props;

    const attributes = _.get(componentProductAttributesQuery, 'component.product.typeDefinitionAttributeList.typeDefinitionAttributes', [])
    const attributesCopy = _.cloneDeep(attributes)
    let defaultAttributes = []
    let catalogAttributes = []

    attributesCopy.filter((item) => !['imageId', 'status', 'websiteStatus'].includes(item.index)).forEach(el => {
      el.disabled = true
      if (el.categories.includes('default')) {
        defaultAttributes.push(el)
      } else if (el.categories.includes('catalogData')) {
        catalogAttributes.push(el)
      }
    })

    return <div className={'p-10'}>
      <Row>
        <Col sm={24}>
          <div className='mv-10'>
            <Panel title={t('Related products')}>
              <ComponentProductSpareParts productId={_.get(componentProductAttributesQuery, 'component.product.id')} />
            </Panel>
          </div>
        </Col>
      </Row>
      <Row gutter={2 * Sizes.grid}>
        { catalogAttributes.length > 0 ?
          <Col sm={24} md={12}>
            <div className='mv-10'>
              <Panel title={t('Catalog data')}>
                <div className='p-10'>
                  <AttributesInlineForm
                    inline
                    attributes={catalogAttributes}
                    layout={'horizontal'}
                    t={t}
                  />
                </div>
              </Panel>
            </div>
          </Col>
        : null}
        <Col sm={24} md={12}>
          <div className='mv-10'>
            <Panel title={t('Product data')}>
              <div className='p-10'>
                <AttributesInlineForm
                  inline
                  attributes={defaultAttributes}
                  layout={'horizontal'}
                  t={t}
                />
              </div>
            </Panel>
          </div>
        </Col>
      </Row>
    </div>;
  }
}

export default compose(
  withTranslation(),
  graphql(ComponentProductAttributesQuery, {
    name: 'componentProductAttributesQuery',
    options: (props: Props) =>({
      variables: {
        componentId: props.componentId
      }
    }),
  }),
  waitWhileLoading('componentProductAttributesQuery','component.product'),
)(ComponentProductDetailsDashboard);
