// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import ContentHeader from "../../components/Typo/ContentHeader";
import ServiceProviderCreate from "../../components/ServiceProviderCreate";
import ContentWrapper from "../../components/ContentWrapper";
import withResources from "../../hoc/withResources";


type Props = {
    t: Function,
    history: {
        push: Function,
    }
};


class ServiceProviderCreateRoute extends Component<Props, State> {


    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader title={t('New ServiceProvider')}/>
            <ContentWrapper>
                <ServiceProviderCreate onCreate={(res) => {
                    const serviceProviderId = _.get(res, 'data.createServiceProvider.id');
                    if (serviceProviderId) {
                        this.props.history.push(`/admin/serviceproviders/${serviceProviderId}`);
                    }
                }}/>
            </ContentWrapper>
        </div>;
    }
}

export default withResources(["administrateServiceProviders"])(withTranslation()(ServiceProviderCreateRoute));
