// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";
import AttributeInlineForm from "./../Form/attributes-inline-form";
import ProfileQuery from "./../../graphql/queries/profile.graphql";
import UpdateProfileMutation from "./../../graphql/mutations/updateProfile.graphql";
import _ from "lodash";
import {Col, message, Row} from "antd";
import {Icon} from "@dreebit/react-components";
import TourGuideResetButton from "../TourGuideManagementButton/reset";
import TourGuideSkipButton from "../TourGuideManagementButton/skip";
import {getAllTourSteps} from "../../utils/getAllTourSteps";
import PasswordResetButton from "../PasswordResetButton";
import './index.css';

type Props = {
    t: Function,
    ProfileQuery: query,
    updateProfileMutation: Function,
    onSubmit?: Function
}

class ProfileForm extends Component<Props, State> {


    render() {
        const {t, ProfileQuery, updateProfileMutation, onSubmit} = this.props;

        const categoryWhiteList = ["default"];

        const attributes = _.get(ProfileQuery, 'profile.typeDefinitionAttributeList.typeDefinitionAttributes', []).filter((attribute) => {
            if (attribute.categories) {
                return attribute.categories.filter(element => categoryWhiteList.includes(element)).length > 0
            }
        });

        const email = _.get(ProfileQuery, 'profile.email');
        const tourProgress = _.get(ProfileQuery, 'profile.tourProgress', []);
        const tourIndizes = tourProgress.map(item => item.index);

        let avatarAttribute = _.get(ProfileQuery, 'profile.typeDefinitionAttributeList.typeDefinitionAttributes', []).map((attribute) => {
            if (attribute.index === 'avatar') {
                return {
                    ...attribute,
                    inputProps: {
                        customContent: <div>
                            <p className="ant-upload-drag-icon">
                                <Icon type="user"/>
                            </p>
                            <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
                            <p className="ant-upload-hint">
                                {t('a square picture is recommended')}
                            </p>
                        </div>
                    },
                    name: '',
                    formItemLayout: {
                        colon: false,
                        labelCol: {
                            md: {
                                span: 0
                            }
                        },
                        wrapperCol: {
                            md: {
                                span: 24
                            }
                        }
                    },
                }
            }
        }).filter(function (el) {
            return el != null;
        });

        return <Row gutter={16}>
            <Col md={18} className={'ProfileWrapper'}>
                <Panel title={t('Details')} topRight={email && <PasswordResetButton email={email}/>}>
                    <AttributeInlineForm
                        attributes={attributes}
                        useSubmit={true}
                        mutation={updateProfileMutation}
                        onSubmit={onSubmit}
                        translateMutationOptions={({values, changes}) => ({
                            variables: {
                                input: changes
                            }
                        })}
                    />
                </Panel>
            </Col>
            <Col md={6}>
                <Panel title={t('Introductory tour')}>
                    <div className={'flex-col flex-align-items-center'}>
                        <span>{t('If you need further information on using the VSM, you can start the introductory tour again here.')}</span>
                        <div className={'mt-10 w-75'}>
                            <TourGuideResetButton
                                block
                                tourIndizes={tourIndizes}
                                onDone={() => message.success(t('The tour was restarted'))}
                            />
                        </div>
                        <div className={'mt-10 w-75'}>
                            <TourGuideSkipButton
                                block
                                tourSkipInput={getAllTourSteps()}
                                onDone={() => message.success(t('The tour was skipped'))}
                            />
                        </div>
                    </div>
                </Panel>
            </Col>
        </Row>
    }
}


export default compose(
    withTranslation(),
    graphql(ProfileQuery, {
        name: 'ProfileQuery',
        options: (props: Props) => ({
            variables: {
                profile: props.profile
            }
        }),
    }),
    waitWhileLoading('ProfileQuery'),
    graphql(UpdateProfileMutation, {
        name: 'updateProfileMutation',
    }),
)(ProfileForm);

