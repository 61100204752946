//@flow

import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import './index.css';
import ComponentLogHistoryHeaderQuery from "./../../graphql/queries/componentLogHistoryHeader.graphql"
import InsertUpdateInfo from "./../InsertUpdateInfo";
import waitWhileLoading from "../../hoc/waitWhileLoading";


type Props = {
    componentId: string,
};


export default compose(
    withTranslation(),
    graphql(ComponentLogHistoryHeaderQuery, {
        name: 'componentLogHistoryHeaderQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        })
    }),
    waitWhileLoading('componentLogHistoryHeaderQuery', 'component'),
    withProps((props) => ({
        insert: _.get(props, 'componentLogHistoryHeaderQuery.component.insert'),
        update: _.get(props, 'componentLogHistoryHeaderQuery.component.update')
    }))
)(InsertUpdateInfo);
