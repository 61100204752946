export const objectToFilter = (values) => {

    if (!values) {
        return null
    }

    return Object.keys(values).reduce((acc, key) => {

        let val = values[key];
/*
        if (val === "true") val = true;
        if (val === "false") val = false;
*/
        if (val && val.value) {
            acc[key] = val;
        } else if (val !== undefined && val !== "") {
            if (val === "true"){
                acc[key] = {value: true};
            }else if (val === "false"){
                acc[key] = {value: false};
            }else {
                acc[key] = {
                    value: val
                };
            }

        }

        return acc;
    }, {})
};
