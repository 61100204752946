// @flow
import React, {Component, useEffect} from "react"
import FileUploader from "../../components/FileUploader";
import {Redirect, Route, Switch} from "react-router-dom";
import VersionableAttributesModal from "../../components/VersionableAttributesTabs";
import FormTest from "../../components/FormTest";
import CreateServiceTicketTest from "../../components/CreateServiceTicketTest";
import NewServiceActionTest from "../../components/NewServiceActionTest";
import DynamicForms from '../../components/DynamicForms';
import ServiceWorkflowDoneActionSteps from '../../components/ServiceWorkflowDoneActionSteps';
import InfrastructureSearch from "../../components/InfrastructureSearch";
import NavigationContainerRoute from "./NavigationContainer";
import ServiceActionTypeForm from "../../components/ServiceActionTypeForm";
import ComponentPicker from "../../components/Form/fields/ComponentPicker";
import ComponentsMultiAction from "../../components/ComponentsMultiAction";
import Error404 from "../errorPages/error404";
import {useDispatch} from "react-redux";

type State={
    componentPickerId: String
}

class Dev extends Component<Props, State> {
    state={
        componentPickerId: 0
    };

    render() {
        const {componentBrowserId} = this.state;

        return  <Switch>
            <Route path="/dev/resetLocalStorage" component={() => {

                const dispatch = useDispatch()
                useEffect(()=> {
                    window.localStorage.clear()
                    dispatch({
                        type: "USER_LOGGED_OUT"
                    })
                },[])
                return <div>
                    Reset Local Storage
                </div>
            }}/>
            <Route path="/dev/fileUploader" component={() => <FileUploader/>}/>
            <Route path="/dev/componentMultiAction" component={() => <div className={"bg-white"} style={{margin: "0 auto", width: 500}}>
                <ComponentsMultiAction componentIds={["28602"]}/>
            </div>}/>
            <Route path="/dev/componentPicker" component={() => <div className={"pt-5"}>
                <div data-cy={'TEST_componentPickerCloseDiv'} style={{height:10, width: 10}}/>
                <span data-cy={'TEST_componentBrowser'}>{componentBrowserId}</span>
                <ComponentPicker
                    data-cy={'TEST_componentPicker'}
                    value={componentBrowserId ? componentBrowserId : 0}
                    showOnlyComponent
                    onChange={(id) => {
                        this.setState({
                            componentBrowserId: id
                        });
                    }}/>
            </div>
            }/>
            <Route path="/dev/versionable/:index" component={VersionableAttributesModal}/>
            <Route path="/dev/form" component={FormTest}/>
            <Route path="/dev/createServiceTicket" component={CreateServiceTicketTest}/>
            <Route path="/dev/newServiceAction" component={NewServiceActionTest}/>
            <Route path="/dev/serviceActionDepartment" component={() => {

                return <div className={"bg-white p-10"} style={{marginTop: 50, marginLeft: 50, width: 500, height: 500}}>
                    <ServiceActionTypeForm
                        index={"serviceTicketAssignedToServiceDepartment"}
                        serviceTicketId={"1"} />
                </div>
            }}/>
            <Route path="/dev/infrastructuresearch" component={InfrastructureSearch}/>
            <Route path="/dev/forms/:componentName" component={DynamicForms}/>
            <Route path="/dev/navigationcontainer" component={NavigationContainerRoute}/>
            <Route path="/dev/workflowDone" component={() => <ServiceWorkflowDoneActionSteps
                serviceTicketIds={["16435"]}
                onDone={(res) => {
                    console.log("workflowDone", res)
                }
                }
            /> }/>
            <Redirect exact from="/dev" to="/dev/infrastructuresearch"/>
            <Route component={Error404}/>
        </Switch>;
    }

}

export default Dev;
