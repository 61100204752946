// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import Warning from "../Warning";
import {Button} from "@dreebit/react-components";
import ServiceActionWizardForm from "../ServiceActionWizardForm";

type Props = {
    serviceTicketId?: any
}

type State = {
    visible: boolean
}


class ComponentLink extends Component<Props, State> {

    state={
        visible: false
    };

    handleChangeVisible = () => {
        this.setState({
            visible: !this.state.visible,
        });
    };

    render() {

        const {t, serviceTicketId} = this.props;
        const {visible} = this.state;

        return <div>
            <Warning        title={t('No component')}
                            description={t('Please link a component')}
                            customButton={<Button
                                onClick={this.handleChangeVisible}
                                type={"default"}
                                icon={"code-sandbox"}
                            >
                                {t("Link component")}
                            </Button>}
            />
            {
                visible
                    ? <ServiceActionWizardForm modal
                                               onSaved={this.handleChangeVisible}
                                               onCancel={this.handleChangeVisible}
                                               defaultOpen={false}
                                               visible={visible}
                                               serviceTicketId={serviceTicketId}
                                               defaultServiceActionTypeIndex={'setComponent'}/>
                    : null
            }
        </div>;
    }

}


export default compose(
    withRouter,
    withTranslation()
)(ComponentLink);
