// @flow
import React, {Component} from "react"
import {Button as AntdButton, Dropdown, Menu, Tooltip} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {Link} from "react-router-dom";
import * as _ from "lodash";
import EllipseLabel from "../EllipseLabel";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import ComponentPathQuery from "../../graphql/queries/ComponentPath.graphql";
import {withTranslation} from "react-i18next";
import ProfileQuery from "../../graphql/queries/profile.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

const styles = {
    textOverflowButton: {maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis'}
};

type Props = {
    componentId?: string,
    pathItems: Array<any>,
    onBackPress: Function,
    onMenuItemPress: Function,
    onButtonPress: Function,
    hidePathButton: boolean,
    hideBackButton: boolean,
    onStockSearch?: Function,
    stockSearchEnabled: boolean,
    profileQuery?: any
}

class ComponentPathButton extends Component<Props> {


    render() {
        const {
            pathItems,
            profileQuery,
            onBackPress,
            onButtonPress,
            onMenuItemPress,
            hideBackButton,
            hidePathButton,
            onStockSearch,
            stockSearchEnabled,
            t
        } = this.props;

        if (!pathItems || !pathItems.length) {

            return null
        }

        const firstItem = _.first(pathItems);
        const userResources = _.get(profileQuery, 'profile.resources', []).reduce((acc, cur) => {
            return [...acc, cur.key]
        }, []);

        const showStock = userResources.indexOf('administrateStocks') > -1;
        const isServiceProvider = userResources.indexOf('serviceProvider') > -1;

        const menu = (
            <Menu>
                {
                    pathItems.map(item => <Menu.Item key={item.id}>
                        {onMenuItemPress
                            ? <div onClick={() => onMenuItemPress(item)} rel={item.id}>{item.name}</div>
                            : <Link to={`/infrastructure/${item.id}`}><span rel={item.id}>{item.name}</span></Link>
                        }
                    </Menu.Item>)
                }
            </Menu>
        );

        const stockButton = onStockSearch && showStock ?
            <Tooltip title={t("Search in stock")}>
                <Button data-cy={'TEST_componentBrowserHeaderStockButton'}
                        type={stockSearchEnabled ? 'primary' : 'default'} onClick={onStockSearch}>
                    <MaterialCommunityIcon size={13} name={'forklift'}/>
                </Button>
            </Tooltip>
            : null;

        return (pathItems.length > 1 || (pathItems.length === 1 && isServiceProvider) ?
                <AntdButton.Group tour-id="infrastructure__componentBrowserLevel">
                    {!hideBackButton && onBackPress ?
                        <Button data-cy={'TEST_componentBrowserHeaderBackButton'} onClick={() => onBackPress(pathItems)}
                                style={{padding: '0 8px'}}>
                            <Icon type="left"/>
                        </Button>
                        : null}
                    {
                        !hidePathButton ? <Dropdown overlay={menu}>
                            <Button data-cy={'TEST_componentBrowserHeaderDropdownButton'} onClick={onButtonPress}
                                    style={styles.textOverflowButton}>
                                <EllipseLabel>{firstItem.name}</EllipseLabel> <Icon type="down"/>
                            </Button>
                        </Dropdown> : <Button data-cy={'TEST_componentBrowserHeaderButton'} onClick={onButtonPress}
                                              style={styles.textOverflowButton}>
                            <EllipseLabel>{firstItem.name}</EllipseLabel>
                        </Button>
                    }
                    {/*{stockButton}*/}
                </AntdButton.Group>
                :
                <AntdButton.Group>
                    <Button data-cy={'TEST_componentBrowserHeaderButton'} onClick={() => onMenuItemPress(firstItem)}
                            style={styles.textOverflowButton}>
                        <EllipseLabel>{firstItem.name}</EllipseLabel>
                    </Button>
                    {/*{stockButton}*/}
                </AntdButton.Group>
        )
    }

}

export default compose(
    graphql(ComponentPathQuery, {
        name: 'componentPathQuery',
        skip: (props: Props) => props.pathItems || !props.componentId,
        options: (props: Props) => ({
            variables: {
                id: props.componentId,
            }
        })
    }),
    graphql(ProfileQuery, {
        name: 'profileQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first'
        })
    }),
    waitWhileLoading('profileQuery', 'profile.resources', {
        optional: true
    }),
    withProps((props) => {
        return {
            loading: _.get(props, 'componentPathQuery.loading', false),
            pathItems: props.pathItems || _.chain(props).get('componentPathQuery.componentInfo.path', []).clone().reverse().value()
        }
    }),
    withTranslation()
)(ComponentPathButton);
