// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";

import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import CreateAccessToken from "../../graphql/mutations/createAccessToken.graphql";
import {withTranslation} from "react-i18next";
import AccessTokenList from "../../graphql/queries/accessTokenList.graphql";

type Props = {
    t: Function;
    createAccessTokenMutation: Function,
    onSaved: Function
}

class ConnectorCreateForm extends Component<Props> {

    render() {

        const {onSaved, createAccessTokenMutation, t} = this.props;

        return <TypeDefinitionAttributesForm
            index={'accessToken'}
            mapAttributes={(attribute) => {

                if (attribute.index === "data"){
                    return  {
                        ...attribute,
                        value: "{}",
                        inputType: 'textarea',
                        rules: [
                            ...attribute.rules,
                            {
                                name: "required"
                            },
                            {
                                name: "json"
                            }
                        ]
                    }
                }

                return attribute
            }}
            formProps={{
                useSubmit: true,
                submitTitle: t('Create'),
                mutation: createAccessTokenMutation,
                onSubmit: (res) => {
                    onSaved(res);
                },
                translateMutationOptions: ({values}) => {
                    if(values.data === "") delete values.data;
                    return {
                        variables: {
                            attributes: values
                        }
                    }
                }
            }}
        />
    }
}

export default compose(
    withRouter,
    graphql(CreateAccessToken, {
        name: 'createAccessTokenMutation',
        options: () => ({
            refetchQueries: [{
                query: AccessTokenList,
                variables: {
                    params: {
                        filter: {
                            type: {
                                operator: 'in',
                                value: 'apiToken'
                            }
                        }
                    }
                }
            }]
        })
    }),
    withTranslation()
)(withRouter(ConnectorCreateForm));
