// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import {graphql} from '@apollo/client/react/hoc';
import ComponentQuery from "../../graphql/queries/ComponentPath.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    t: Function,
    componentId: string,
    getComponent: {
        loading: boolean,
        component: any
    }
};


class ReconComponentPathBreadcrumb extends Component<Props, State> {
    state = {};

    componentDidMount() {
    }

    render() {
        const {getComponent, path} = this.props;

        return <div className="pv-5 ph-10 border-radius-5 bg-light-grey">
            <ComponentPathBreadcrumb path={path || _.get(getComponent, 'componentInfo.path')}/>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ComponentQuery, {
        name: 'getComponent',
        skip: (props) => props.path,
        options: props => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('getComponent', ['componentInfo'], {
        optional: true
    }),
)(ReconComponentPathBreadcrumb);
