import React from "react";

const SvgServiceTicketSetAssignee = props => (
    <svg
        id="serviceTicketSetAssignee_svg__Ebene_1"
        x={0}
        y={0}
        viewBox="0 0 150 100"
        xmlSpace="preserve"
        width={props.width}
        {...props}
    >
        <style>{".serviceTicketSetAssignee_svg__st1{fill:#fff}"}</style>
        <path fill={props.color} d="M0 0h150v100H0z"/>
        <path
            className="serviceTicketSetAssignee_svg__st1"
            d="M84.2 47.9c-.2-.5-.5-1-.8-1.6-.8-1.3-1.6-2.5-2.6-3.4-1-.9-2.2-1.7-3.6-2.2s-3-.8-4.7-.8c-.3 0-.8.3-1.8.9-.9.6-2 1.3-3.1 2-1.2.7-2.7 1.4-4.5 2-1.9.6-3.7.9-5.6.9s-3.7-.3-5.6-.9c-1.9-.6-3.4-1.3-4.5-2-1.2-.7-2.2-1.4-3.1-2-.9-.6-1.5-.9-1.8-.9-1.7 0-3.3.3-4.7.8s-2.6 1.3-3.6 2.2c-1 .9-1.8 2.1-2.6 3.4-.8 1.3-1.4 2.7-1.8 4.1s-.8 3-1.1 4.6c-.3 1.6-.5 3.2-.6 4.6-.1 1.4-.1 2.9-.1 4.3 0 3.4 1 6 3.1 7.9s4.7 2.9 8.1 2.9h18.1l26.9-26.8z"
        />
        <path
            className="serviceTicketSetAssignee_svg__st1"
            d="M57.5 42.6c4.4 0 8.2-1.6 11.4-4.7s4.7-6.9 4.7-11.4-1.6-8.2-4.7-11.4-6.9-4.7-11.4-4.7-8.2 1.6-11.4 4.7-4.7 6.9-4.7 11.4 1.6 8.2 4.7 11.4 7 4.7 11.4 4.7z"
        />
        <g>
            <path
                className="serviceTicketSetAssignee_svg__st1"
                d="M21.2 1139.5c0 .7-.3 1.3-.8 1.9-.5.5-1.1.8-1.9.8-.7 0-1.3-.3-1.9-.8s-.8-1.1-.8-1.9.3-1.3.8-1.9c.5-.5 1.1-.8 1.9-.8.7 0 1.3.3 1.9.8.5.6.8 1.2.8 1.9zm26.5 17.3l-28.1-28.1c-1-1-2.3-1.5-3.7-1.5-1.4 0-2.7.5-3.7 1.5l-4.4 4.4c-1 1-1.6 2.2-1.6 3.7s.5 2.7 1.6 3.7l28.1 28.1c1.1-2.7 2.6-5.1 4.7-7.1s4.4-3.6 7.1-4.7zm26.2 18c0-1.1-.3-2.5-.9-4.4-1.3-3.7-3.5-6.7-6.8-9-3.2-2.3-6.8-3.4-10.6-3.4-5.1 0-9.4 1.8-13 5.4-3.6 3.6-5.4 8-5.4 13s1.8 9.4 5.4 13c3.6 3.6 8 5.4 13 5.4 1.6 0 3.3-.2 5-.7 1.7-.5 3.2-1.1 4.4-1.9.4-.3.7-.7.7-1.2s-.2-.9-.7-1.2l-12.1-7v-9.2l8-4.4c.1.1 1.2.7 3.3 2 2 1.2 3.9 2.4 5.6 3.3s2.7 1.5 2.9 1.5c.4 0 .7-.1 1-.4 0-.1.2-.4.2-.8z"
                transform="matrix(1 0 0 -1 53.153 1217.085)"
            />
        </g>
    </svg>
);

export default SvgServiceTicketSetAssignee;