// @flow
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import ServiceDepartmentFormQuery from "./../../graphql/queries/ServiceDepartmentForm.graphql";
import UpdateServiceDepartmentMutation from "./../../graphql/mutations/updateServiceDepartment.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributeInlineForm from "./../Form/attributes-inline-form";

type Props = {
    serviceDepartmentId: string,
};

export default compose(
    graphql(ServiceDepartmentFormQuery, {
        name: 'serviceDepartmentFormQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceDepartmentId
            }
        }),
    }),
    waitWhileLoading('serviceDepartmentFormQuery'),
    graphql(UpdateServiceDepartmentMutation, {
        name: 'updateServiceDepartmentMutation',
        options: props => ({
            refetchQueries: [{
                query: ServiceDepartmentFormQuery,
                variables: {
                    id: props.serviceDepartmentId
                }
            }]
        }),
    }),
    withProps((props) => ({
        attributes: _.get(props, 'serviceDepartmentFormQuery.serviceDepartment.typeDefinitionAttributeList.typeDefinitionAttributes', []),
        mutation: _.get(props, 'updateServiceDepartmentMutation'),
        translateMutationOptions: ({values}) => ({
            variables: {
                id: props.serviceDepartmentId,
                input: values
            }
        }),
        useSubmit: true,
    }))
)(AttributeInlineForm);
