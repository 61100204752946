//@flow
import _ from "lodash"

export default function groupByProducts(components: any[]): any {

    return _.groupBy(components, (component) => {
        if (_.get(component, 'componentType.product')) {
            return 'product';
        } else {
            return 'noProduct';
        }
    });
}