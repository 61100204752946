import {Button} from "@dreebit/react-components";
import React from "react";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import i18n from "i18next";

export const tourConfig = {
  global: [
    {
      id: "global__welcome",
      tourIndex: "global",
      content: "tour__global__welcome"
    },
    {
      id: "global__navigation__dashboard",
      tourIndex: "global",
      selector: `[tour-id="global__navigation__dashboard"]`,
      content: "global__navigation__dashboard",
    },
    {
      id: "global__navigation__infrastructure",
      tourIndex: "global",
      selector: `[tour-id="global__navigation__infrastructure"]`,
      content: "global__navigation__infrastructure",
    },
    {
      id: "global__navigation__service",
      tourIndex: "global",
      selector: `[tour-id="global__navigation__service"]`,
      content: "global__navigation__service",
    },
    {
      id: "global__navigation__calendar",
      tourIndex: "global",
      selector: `[tour-id="global__navigation__calendar"]`,
      content: "global__navigation__calendar",
    },
    {
      id: "global__search",
      tourIndex: "global",
      selector: `[tour-id="global__search"]`,
      content: "tour__global__search"
    },
    {
      id: "global__navigationRight",
      tourIndex: "global",
      selector: `[tour-id="global__navigationRight"]`,
      content: "tour__global__navigationRight",
    }
  ],

  componentDashboard: [
    {
      id: "componentDashboard__welcome",
      tourIndex: "componentDashboard",
      content: "tour__componentDashboard__welcome"
    },
    {
      id: "componentDashboard__attributes",
      tourIndex: "componentDashboard",
      selector: `[tour-id="componentDashboard__attributes"]`,
      content: "tour__componentDashboard__attributes"
    },
    {
      id: "componentDashboard__qrButton",
      tourIndex: "componentDashboard",
      selector: `[tour-id="componentDashboard__qrButton"]`,
      content: "tour__componentDashboard__qrButton"
    },
    {
      id: "componentDashboard__starButton",
      tourIndex: "componentDashboard",
      selector: `[tour-id="componentDashboard__starButton"]`,
      content: "tour__componentDashboard__starButton"
    },
    {
      id: "componentDashboard__notificationButton",
      tourIndex: "componentDashboard",
      selector: `[tour-id="componentDashboard__notificationButton"]`,
      content: "tour__componentDashboard__notificationButton"
    },
    {
      id: "componentDashboard__moreButton",
      tourIndex: "componentDashboard",
      selector: `[tour-id="componentDashboard__moreButton"]`,
      content: "tour__componentDashboard__moreButton"
    },
  ],

  createComponent: [
    {
      id: "createComponent__newDevice",
      tourIndex: "createComponent",
      selector: `[tour-id="createComponent__newDevice"]`,
      content: "tour__createComponent__newDevice"
    },
    {
      id: "createComponent__newUnit",
      tourIndex: "createComponent",
      selector: `[tour-id="createComponent__newUnit"]`,
      content: "tour__createComponent__newUnit"
    }
  ],

  dashboard: [
    {
      id: "dashboard__welcome",
      tourIndex: "dashboard",
      content: "tour__dashboard__welcome"
    },
    {
      id: "dashboard__addPortlet",
      tourIndex: "dashboard",
      selector: `[tour-id="dashboard__addPortlet"]`,
      content: "tour__dashboard__addPortlet"
    }
  ],

  infrastructure: [
    {
      id: "infrastructure__dashboard",
      tourIndex: "infrastructure",
      content: "tour__infrastructure__dashboard"
    },
    {
      id: "infrastructure__componentBrowser",
      tourIndex: "infrastructure",
      selector: `[tour-id="infrastructure__componentBrowser"]`,
      content: "tour__infrastructure__componentBrowser"
    },
    {
      id: "infrastructure__componentBrowserSider",
      tourIndex: "infrastructure",
      selector: `[tour-id="infrastructure__componentBrowserSider"]`,
      content: "tour__infrastructure__componentBrowserSider"
    },
    {
      id: "infrastructure__componentBrowserLevel",
      tourIndex: "infrastructure",
      selector: `[tour-id="infrastructure__componentBrowserLevel"]`,
      content: "tour__infrastructure__componentBrowserLevel"
    },
    {
      id: "infrastructure__componentBrowserEntries",
      tourIndex: "infrastructure",
      selector: `[tour-id="infrastructure__componentBrowser"]`,
      content: () => (
        <div>
          { i18n.t('tour__infrastructure__componentBrowser__1') }
          <div className={'flex-col'}>
            <span className={'mt-5'}>
              <Button className={"sub-button"} size={"small"} >
                <FontAwesomeIcon style={{fontSize: "8px"}} name={"sitemap"}/> 6
              </Button> { i18n.t('tour__infrastructure__componentBrowser__2') }
            </span>
            <span className={'mt-5'}>
              <Button className={"sub-button mr-5"} shape={'circle'} size={'small'}>
                <FontAwesomeIcon style={{fontSize: 12, marginBottom: 5, marginLeft: 3}} name={"chevron-right"}/>
              </Button> { i18n.t('tour__infrastructure__componentBrowser__3') }
            </span>
          </div>
        </div>
      )
    },
    {
      id: "infrastructure__addComponentButton",
      tourIndex: "infrastructure",
      selector: `[tour-id="infrastructure__addComponentButton"]`,
      content: "tour__infrastructure__addComponentButton"
    }
  ],

  serviceSearch: [
    {
      id: "serviceSearch__welcome",
      tourIndex: "serviceSearch",
      content: "tour__serviceSearch__welcome"
    },
    {
      id: "serviceSearch__filter",
      tourIndex: "serviceSearch",
      selector: `[tour-id="serviceSearch__filter"]`,
      content: "tour__serviceSearch__filter"
    }
  ],

  calendar: [
    {
      id: "calendar__welcome",
      tourIndex: "calendar",
      content: "tour__calendar__welcome"
    },
    {
      id: "calendar__newEntries",
      tourIndex: "calendar",
      content: "tour__calendar__newEntries"
    },
    {
      id: "calendar__timeRange",
      tourIndex: "calendar",
      selector: `.ant-picker-calendar-header`,
      content: "tour__calendar__timeRange"
    },
    {
      id: "calendar__filter",
      tourIndex: "calendar",
      selector: `[tour-id="calendar__filter"]`,
      content: "tour__calendar__filter"
    },
    {
      id: "calendar__view",
      tourIndex: "calendar",
      selector: `[tour-id="calendar__view"]`,
      content: "tour__calendar__view"
    }
  ]
};
