// @flow
import React, { Component } from 'react';
import _ from "lodash";
import CustomAttributeList from "../CustomAttributeList";

type Props = {

};

type State = {

}

class TCOAdminAttributes extends Component<Props, State> {

    render() {
        const { } = this.props;

        return <div style={{height: 500}}>
            <CustomAttributeList filter={{
                tco: {
                    value: true
                }
            }} />
    </div>;
    }
}

export default TCOAdminAttributes;
