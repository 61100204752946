import React from "react";

const SvgProductInReview = props => (
    <svg
        id="productInReview_svg__Ebene_1"
        x={0}
        y={0}
        viewBox="0 0 150 100"
        xmlSpace="preserve"
        width={props.width}
        {...props}
    >
        <style>{".productInReview_svg__st1{fill:#fff}"}</style>
        <path fill={props.color} d="M0 0h150v100H0z"/>
        <path
            className="productInReview_svg__st1"
            d="M23 63.7l29.6 14.8V41.4L23 26.5zM54.1 9L24 24.1l30.1 15.2 30.2-15.2zM77.7 37.5c0-1.4 1.1-2.5 2.5-2.5h4.7v-8.5L55.4 41.4v37.1l22.4-11.2V37.5z"
        />
        <g>
            <path
                className="productInReview_svg__st1"
                d="M92.1 50.6c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2zM96.9 51h20.9v3.6H96.9zM92.1 59.1c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2zM96.9 59.5h20.9v3.6H96.9zM92.1 68.5c1.2 0 2.2 1
2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2zM96.9 68.9h20.9v3.6H96.9zM92.1 77c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2zM96.9 77.4h20.9V81H96.9z"
            />
            <path
                className="productInReview_svg__st1"
                d="M124.2 37.8H115v-2.9s-6.9-1.8-11.5-1.8S92 34.9 92 34.9v2.9h-9c-1.3 0-2.4 1.1-2.4 2.4v49.3c0 1.3 1.1 2.4 2.4 2.4h41.1c1.3 0 2.4-1.1 2.4-2.4V40.3c.1-1.4-1-2.5-2.3-2.5zm-20.6-2.6c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2zm
18.2 51.9H85.5V42.7H92v1.7h23v-1.7h6.8v44.4z"
            />
        </g>
    </svg>
);

export default SvgProductInReview;