// @flow
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

import ContentHeader from "../Typo/ContentHeader";
import AttributesInlineForm from "../Form/attributes-inline-form";
import ServiceReportAttributeConfigQuery from "../../graphql/queries/serviceReportAttributeConfig.graphql";
import UpdateServiceReportAttributeConfigMutation
  from "../../graphql/mutations/updateServiceReportAttributeConfig.graphql";
import DeleteServiceReportAttributeConfigMutation
  from "../../graphql/mutations/deleteServiceReportAttributeConfig.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";
import ServiceReportAttributeConfigListQuery from "../../graphql/queries/serviceReportAttributeConfigList.graphql";
import withResources from "../../hoc/withResources";
import {Modal, Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import NewCategoryModal from './newCategoryModal'


type Props = {
  index: string,
  t: Function,
  onUpdateServiceReportConfig: (index: string) => void,
  handleDeleteRedirect: (index: string) => void,
  serviceReportAttributeConfigQuery: query,
  updateServiceReportAttributeConfigMutation: Function,
  deleteServiceReportAttributeConfigMutation: Function,
};


class ComponentTypeCreateServiceReportConfig extends Component<Props> {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleDelete = () => {
    const {index, deleteServiceReportAttributeConfigMutation, handleDeleteRedirect, match} = this.props;

    deleteServiceReportAttributeConfigMutation({
      variables: {
        index: _.get(match, 'params.serviceReportConfigIndex')
      }
    }).then(
      handleDeleteRedirect(index)
    )
  };

  render() {
    const {index, match, t, serviceReportAttributeConfigQuery, updateServiceReportAttributeConfigMutation, onUpdateServiceReportConfig} = this.props;

    const serviceReportAttributeConfig = _.get(serviceReportAttributeConfigQuery, 'serviceReportAttributeConfig');
    const attributes = _.get(serviceReportAttributeConfigQuery, 'serviceReportAttributeConfig.typeDefinitionAttributeList.typeDefinitionAttributes', []);
    _.set(
        attributes[_.findIndex(attributes, function (o) {
          return o.index === 'categories'
        })],
        'hint',
        <Button className={'mt-5'}
                onClick={this.showModal}>
          {t('New Category')}
        </Button>
    );

    return <>
      <div>
        <ContentHeader
          title={_.get(serviceReportAttributeConfig, 'name', 'Service Report Config')}
          tools={<Popconfirm
            title={t('Please confirm to delete this item')}
            icon={<Icon type="delete" style={{color: 'red'}}/>}
            onConfirm={this.handleDelete}
            okText={t('Confirm')}
            cancelText={t('Cancel')}
            placement="bottomRight"
          >
            <Button icon="delete" type="danger">{t('Remove')}</Button>
          </Popconfirm>}
        />
        <div className={'m-20'}>
          <Panel>
            <AttributesInlineForm
              attributes={attributes}
              t={t}
              useSubmit={true}
              onSubmit={() => {
                onUpdateServiceReportConfig(index);
              }}
              mutation={updateServiceReportAttributeConfigMutation}
              translateMutationOptions={({values}) => ({
                variables: {
                  index: _.get(match, 'params.serviceReportConfigIndex'),
                  input: values
                }
              })}
            />
          </Panel>
        </div>
      </div>
      <Modal
        title={t('New Category')}
        visible={this.state.visible}
        footer={null}
        onCancel={this.handleCancel}
      >
        <NewCategoryModal/>
      </Modal>
    </>
  }
}

export default compose(
  withResources(["administrateServiceReports"]),
  withRouter,
  graphql(ServiceReportAttributeConfigQuery, {
    name: 'serviceReportAttributeConfigQuery',
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        index: _.get(props.match, 'params.serviceReportConfigIndex')
      }
    })
  }),
  waitWhileLoading('serviceReportAttributeConfigQuery', ["serviceReportAttributeConfig"]),
  graphql(UpdateServiceReportAttributeConfigMutation, {
    name: "updateServiceReportAttributeConfigMutation",
    options: (props: Props) => ({
      refetchQueries: [{
        query: ServiceReportAttributeConfigListQuery,
        variables: {
          params: {
            filter: {
              componentTypeIndex: {
                value: _.get(props, 'match.params.index')
              }
            }
          }
        }
      }]
    }),
  }),
  graphql(DeleteServiceReportAttributeConfigMutation, {
    name: "deleteServiceReportAttributeConfigMutation",
    options: (props: Props) => ({
      refetchQueries: [{
        query: ServiceReportAttributeConfigListQuery,
        variables: {
          params: {
            filter: {
              componentTypeIndex: {
                value: _.get(props, 'match.params.index')
              }
            }
          }
        }
      }]
    }),
  }),
  withTranslation(),
)(ComponentTypeCreateServiceReportConfig);
