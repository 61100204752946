// @flow
import React, {Component} from 'react';
import _ from "lodash";
import query from "./../../graphql/queries/contaminationDeclarationForm.graphql";
import UpdateContaminationDeclarationMutation from "./../../graphql/mutations/updateContaminationDeclaration.graphql";

import ContaminationDeclarationForm from "./form";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import ServiceTicketContaminationDeclarationQuery
    from "../../graphql/queries/serviceTicketContaminationDeclaration.graphql";

type Props = {
    contaminationDeclarationId: string,
    contaminationDeclarationFormQuery: any,
    updateContaminationDeclarationMutation: Function,
    file: boolean,
    serviceTicketId: any
};


class ContaminationDeclarationUpdateForm extends Component<Props, State> {


    render() {
        const {contaminationDeclarationFormQuery, updateContaminationDeclarationMutation, contaminationDeclarationId, file, serviceTicketId} = this.props;

        const contaminationDeclaration = _.get(contaminationDeclarationFormQuery, 'contaminationDeclaration');
        const attributes = _.get(contaminationDeclaration, 'typeDefinitionAttributeList.typeDefinitionAttributes')
        .map((item) => {
            let newItem = _.cloneDeep(item);
/*
            if (item.index === 'freeFromHarmfulSubstances'){
                item.value = false;
            } else
            */
            if (newItem.index === 'substances'){
                newItem.rules = [{name: 'required'}];
        //        item.conditions = [ {operator: "$eq", value: false, key: "freeFromHarmfulSubstances"}];
            }else if (newItem.index === 'harmfulSubstances'){
                newItem.rules = [{name: 'required'}];
            }
            return newItem;
        })



        return <ContaminationDeclarationForm
            mutation={updateContaminationDeclarationMutation}
            translateMutationOptions={({values}) => {
                if (values.substances) {
                    delete values.substances["key"]
                }
                return {
                    variables: {
                        attributes: values,
                        contaminationDeclarationId
                    }
                }
            }}
            attributes={attributes}/>
    }
}

export default compose(
    graphql(query, {
        name: 'contaminationDeclarationFormQuery',
        options: props => ({
            variables: {
                id: props.contaminationDeclarationId
            }
        }),
    }),
    waitWhileLoading('contaminationDeclarationFormQuery', 'contaminationDeclaration'),
    graphql(UpdateContaminationDeclarationMutation, {
        name: 'updateContaminationDeclarationMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: serviceTicketDashboard,
                variables: {
                    id: props.serviceTicketId
                }
            }, {
                query: query,
                variables: {
                    id: props.contaminationDeclarationId
                }
            }, {
                query: ServiceTicketContaminationDeclarationQuery,
                variables: {
                    id: props.serviceTicketId
                }
            }]
        }),
    }),
    withTranslation()
)(ContaminationDeclarationUpdateForm);
