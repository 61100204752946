// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentListSearchQuery from "../../graphql/queries/componentList-Search.graphql"
import ComponentNameQuery from "../../graphql/queries/component-Name.graphql"
import SubselectionList from "./subselectionList";
import SubselectionProductList from "./subselectionProductList";
import _ from "lodash";
import ContentHeader from "../Typo/ContentHeader";
import StockSearchInput from "../StockSearchInput";

import groupByProducts from "./groupByProducts";


type Props = {
    filter: {},
    match: {
        params: {
            componentId: string
        }
    }
};

class StockList extends Component<Props> {

    render() {
        const {componentListSearchQuery, componentNameQuery} = this.props;

        const components = _.get(componentListSearchQuery, 'componentList.components');
        const componentsSortedByProduct = groupByProducts(components);
        const parentComponentName = _.get(componentNameQuery, 'component.name');

        return <div>
            <ContentHeader title={parentComponentName} tools={<StockSearchInput/>}/>
            <div className="flex-flow-row-wrap flex-space-around">
                {_.get(componentsSortedByProduct, 'product') ?
                    <SubselectionProductList components={_.get(componentsSortedByProduct, 'product')}/> : null}
                {_.get(componentsSortedByProduct, 'noProduct') ? _.get(componentsSortedByProduct, 'noProduct').map((comp, index) => {
                    return <SubselectionList
                        key={index}
                        component={comp}
                        subtitle={comp.owner ? comp.owner : null}
                        childrenCount={comp.childrenCount}
                    />
                }) : null}
            </div>
        </div>
    }
}

export default compose(
    graphql(ComponentListSearchQuery, {
        name: 'componentListSearchQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: {
                        parentId: {
                            value: _.get(props, 'componentId'),
                            direct: true
                        }
                    }
                }
            }
        })
    }),
    waitWhileLoading('componentListSearchQuery', 'componentList'),
    graphql(ComponentNameQuery, {
        name: 'componentNameQuery',
        options: (props: Props) => ({
            variables: {
                id: _.get(props, 'componentId')
            }
        })
    }),
    waitWhileLoading('componentNameQuery', 'component')
)(StockList);

