// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ServiceTicketAssigneeQuery from "../../graphql/queries/ServiceTicketAssigneeQuery.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceTicketStateBadge from "./../../components/ServiceTicketStateBadge"
import UserInfoBox from "../UserInfoBox";
import {withTranslation} from "react-i18next";
import Bold from "../Utilities/Bold";
import ServiceProviderOnly from "../ServiceProviderOnly";

type Props = {
    serviceTicketAssigneeQuery: any
};

type State = {

}

class AssigneeWidget extends Component<Props, State> {

  render() {
    const { serviceTicketAssigneeQuery, t } = this.props;

    const serviceDepartment = _.get(serviceTicketAssigneeQuery,'serviceTicket.serviceDepartment.name');
    const state = _.get(serviceTicketAssigneeQuery,'serviceTicket.serviceDepartmentState');

    return <div>
        <UserInfoBox user={_.get(serviceTicketAssigneeQuery,'serviceTicket.assignee')}/>

        { state && serviceDepartment ?
            <ServiceProviderOnly>
                <div style={{marginLeft: 68, paddingLeft: 5}}>
                    <Bold>{t("Service Department")}:</Bold> <span>{serviceDepartment}</span> <ServiceTicketStateBadge state={state} />
                </div>
            </ServiceProviderOnly>
             : null }
    </div>
  }
}


export default compose(
    withTranslation(),
    graphql(ServiceTicketAssigneeQuery,{
        name: 'serviceTicketAssigneeQuery',
        options: (props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketAssigneeQuery')
)(AssigneeWidget);
