// @flow
import React, {Component} from 'react';
import {Col, Row} from "antd";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import Panel from "../Panel";
import ComponentUserAccess from "./userAccess";
import ComponentRoleAccess from "./roleAccess";
import withProfile from "../../hoc/withProfile";

type Props = {
    componentId: string,
    componentPath: string,
};

class ComponentAccess extends Component<Props, State> {

    state = {
        modal: false
    }

    render() {
        const {t, componentId, resources, componentPath} = this.props;


        return <div>
            <Row gutter={16}>
                {resources.indexOf("addUserToComponentAcl") > -1 ? <Col md={12}>
                    <Panel title={t('Users')} bordered={false}>
                        <ComponentUserAccess componentId={componentId} componentPath={componentPath}/>
                    </Panel>
                </Col> : null}
                {resources.indexOf("addRoleToComponentAcl") > -1 ? <Col md={12}>
                    <Panel title={t('Roles')} bordered={false}>
                        <ComponentRoleAccess componentId={componentId} componentPath={componentPath}/>
                    </Panel>
                </Col> : null}
            </Row>
        </div>
    }
}

export default compose(
    withTranslation(),
    withProfile()
)(ComponentAccess);
