// @flow
import React, {Component} from 'react';
import {AutoComplete, Input, Spin} from "antd";
import {Icon} from "@dreebit/react-components";
import ComponentListSearchQuery from "../../graphql/queries/componentList-Search.graphql"
import {withTranslation} from "react-i18next";
import {Query} from '@apollo/client/react/components';
import {compose} from "recompose";
import {Link, withRouter} from "react-router-dom";
import _ from "lodash";

type Props = {
    t: Function,
};

type State = {
    query: string,
}

const { Search } = Input;



class StockSearchInput extends Component<Props, State> {

    state = {
        query: null
    };

    _handleSearch = (input) => {
        this.setState({
            query: input
        });
    };

    _renderOption = (item) => {
        return (
            <AutoComplete.Option key={item.id} text={item.name}>
                <Link
                    to={item.componentType.product ? `/infrastructure/${item.id}` : `/infrastructure/stock/${item.id}`}>
                    <div className="flex-col mb-5">
                        <span className="text-dark-gray">{_.get(item, 'name')}</span>
                        <span style={{color: 'rgba(0, 0, 0, 0.5'}}>S/N: {_.get(item, 'serialNo', '-')}</span>
                        <span style={{color: 'rgba(0, 0, 0, 0.5'}}>Kat.Nr.{_.get(item, 'product.catalogNo', '-')}</span>
                    </div>
                </Link>
            </AutoComplete.Option>
        );
    };

    render() {
        const {t, history} = this.props;

        return <Search
            placeholder={t("Search in Stock")}
            size="large"
            filterOption={false}
            onSearch={
                (value) => {
                    history.push(`/infrastructure/search?filterOptions=searchInStock&query=${value}`)
                }
            }
        />;

        return <Query
            query={ComponentListSearchQuery}
            variables={{
                params: {
                    start: 0,
                    limit: 5,
                    search: {
                        query: this.state.query,
                        fields: ['name', 'serialNo', 'catalogNo']
                    },
                    filter: {
                        parentComponentTypeIndex: {
                            value: 'stock',
                            operator: 'in'
                        }
                    }
                }
            }}
            skip={!this.state.query}
        >
            {({loading, error, data}) => {
                if (loading) {
                    return <AutoComplete
                        size='large'
                        dataSource={[]}
                    >
                        <Input suffix={<Spin/>}/>
                    </AutoComplete>;
                }

                if (error) return `Error!: ${error}`;

                return <AutoComplete
                    size='large'
                    dataSource={_.get(data, 'componentList.components', []).map(this._renderOption)}
                    placeholder={t('Search in Stock')}
                    onSearch={_.debounce(this._handleSearch, 700, {
                        'leading': false,
                        'trailing': true
                    })}
                    filterOption={false}
                >
                    <Input suffix={<Icon type="search"/>}/>
                </AutoComplete>
            }}
        </Query>
    }
}


export default compose(
    withTranslation(),
    withRouter
)(StockSearchInput);

