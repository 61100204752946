// @flow
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {List, Tag} from "antd";
import {compose} from "redux";
import _ from "lodash";
import {withTranslation} from "react-i18next";

import ManufacturerListQuery from "../../graphql/queries/manufacturerList.graphql";
import {Grow} from "../../components/Flex";
import QueryLogList from "../QueryLogList";
import statics from "../../statics";

type Props = {
    manufacturerListQuery: query,
    t: Function,
    filterValues: any,
    onClick: Function,
    tableProps?: any
};

class ManufacturerList extends Component<Props, State> {
    render() {
        const {productListQuery, t, onClick, tableProps} = this.props;

        return <div style={{overflow: 'scroll'}} className={'full-size'}>
            <QueryLogList
                query={ManufacturerListQuery}
                variables={{
                    params: {
                        filter: _.get(this.props, 'filterValues.status') ? {
                            status: {
                                operator: "in",
                                value: _.get(this.props, 'filterValues.status')
                            }
                        } : undefined,
                        limit: statics.defaultPagingSize,
                        search: {
                            query: _.get(this.props, 'filterValues.query')
                        }
                    }
                }}
                itemsKeyPath={'manufacturerList.manufacturers'}
                totalKeyPath={'manufacturerList.total'}
                renderItems={(items) => items.map((item, index) => <Link
                    to={`/catalog/manufacturer/${item.id}`} key={index}>
                    <List.Item style={{width: '100%'}}>
                        <Grow style={{marginLeft: '0px'}}>
                            <List.Item.Meta
                                title={item.name}
                            />
                        </Grow>
                        <div>
                            {item.status === 'inReview' ?
                                <Tag color="red" style={{marginRight: 0}}>{t('In Review')}</Tag> :
                                <Tag color="blue" style={{marginRight: 0}}>{t('Published')}</Tag>}
                        </div>
                    </List.Item>
                </Link>)}
            />
        </div>


    }
}

export default compose(

    withTranslation(),
)(ManufacturerList)
