// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import {Col, Row} from "antd";
import {withTranslation} from "react-i18next";
import errorPatternDetails from "./../../graphql/queries/errorPatternDetails.graphql";
import EnumsQuery from "./../../graphql/queries/enums.graphql";
import AddComponentTypesToErrorPatternMutation from "./../../graphql/mutations/addComponentTypesToErrorPattern.graphql";
import deleteErrorPattern from "./../../graphql/mutations/deleteErrorPattern.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ErrorPatternForm from "../ErrorPatternForm";
import Panel from "../Panel";
import Sizes from "../../themes/default/sizes";
import errorPatternList from "../../graphql/queries/errorPatternList.graphql";
import {withRouter} from "react-router-dom";
import statics from "../../statics";
import ErrorPatternComponentTypes from "../ErrorPatternComponentTypes";
import QuerySelect from "../QuerySelect";

type Props = {
    index: string,
    errorPatternDetailsQuery: any,
    deleteErrorPatternMutation: Function,
    history: {
        push: Function
    }
}

class ErrorPatternDashboard extends Component<Props> {

    render() {

        const {addComponentTypesToErrorPatternMutation, id, t} = this.props;


        return <Row gutter={Sizes.grid * 2}>
            <Col xl={12}>
                <Panel
                    title={t('Details')}
                >
                    <ErrorPatternForm id={id}/>
                </Panel>

            </Col>
            <Col xl={12}>
                <Panel
                    title={t('Component Types')}
                >
                    <QuerySelect
                        className={"mb-10"}
                        onSelect={(index) => {
                            return addComponentTypesToErrorPatternMutation({
                                variables: {
                                    errorPatternId: id,
                                    componentTypeIndices: [index]
                                }
                            })
                        }}
                        queryConfig={{
                            query: EnumsQuery,
                            variables: {
                                name: 'ProductComponentTypeIndex'
                            },
                            dataKeyPath: '__type.enumValues',
                            titleKeyPath: 'description',
                            valueKeyPath: 'name',
                        }} />
                    <ErrorPatternComponentTypes errorPatternId={id}/>
                </Panel>

            </Col>
        </Row>
    }
}

export default compose(
    withTranslation(),
    withRouter,
    graphql(AddComponentTypesToErrorPatternMutation,{
        name: 'addComponentTypesToErrorPatternMutation'
    }),
    graphql(errorPatternDetails, {
        name: 'errorPatternDetailsQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    waitWhileLoading('errorPatternDetailsQuery', 'errorPattern'),
    graphql(deleteErrorPattern, {
        name: 'deleteErrorPatternMutation',
        options: props => ({
            refetchQueries: [{
                query: errorPatternList,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                    }
                }
            }]
        })
    }),
    withProps((props) => ({
        errorPattern: _.get(props, 'errorPatternDetailsQuery.errorPattern'),
    }))
)(ErrorPatternDashboard);
