// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {message, Tooltip} from 'antd';
import {Button} from "@dreebit/react-components";

import AttributesInlineForm from "../Form/attributes-inline-form";
import UpdateManufacturerMutation from "../../graphql/mutations/updateManufacturer.graphql";
import ManufacturerQuery from "../../graphql/queries/manufacturer.graphql";
import ManufacturerListQuery from "../../graphql/queries/manufacturerList.graphql";
import ProductListCountQuery from "../../graphql/queries/productListCount.graphql";
import {Col, Row} from "../Flex";
import SplitPanelList from "../SplitPanelList";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";

type Props = {
    attributes: any,
    t: Function,
    updateManufacturerMutation: Function,
    id: ?string,
    filter: any,
    status: string,
    name: string,
    productListQuery: query,
};

class ManufacturerDetailViewBody extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {visible: false}
    }

    setPublished = () => {
        const {updateManufacturerMutation, id} = this.props;

        updateManufacturerMutation({
            variables: {
                manufacturerId: id,
                input: {status: 'published'}
            }
        }).then((res) => {
            message.success(res.data.updateManufacturer.message);
        });
    };

    getMatch = () => {
        this.setState({
            visible: true,
        });
    };

    render() {
        const {updateManufacturerMutation, t, id, attributes, status, name, productListQuery} = this.props;

        const attributeFilter = attributes.filter((el) => {
            return ['status'].indexOf(el.index) < 0
        });
        const matchWidth = status === 'inReview' ? '50%' : '100%';
        const totalProducts = _.get(productListQuery, 'productList.total', 0);
        let title, description;

        if (totalProducts === 1) {
            title = 'product';
            description = 'has been added to this manufacturer';
        } else {
            title = 'products';
            description = 'have been added to this manufacturer';
        }

        return (
            <Row className={"flex-center full-width"}
                 style={{backgroundColor: '#F9F9F9', padding: '20px 10px 0', gap: 20}}>
                <Col style={{width: '100%'}} className={"flex-center flex-align-items-center"}>
                    <Panel bordered={false}
                           title={t('Edit')}
                    >
                        <AttributesInlineForm
                            attributes={attributeFilter}
                            layout={'horizontal'}
                            useSubmit={true}
                            t={t}
                            mutation={updateManufacturerMutation}
                            translateMutationOptions={({values}) => ({
                                variables: {
                                    manufacturerId: id,
                                    input: values
                                }
                            })}
                        />
                    </Panel>
                </Col>

                <Col style={{paddingTop: '25px'}} className={"flex-center flex-align-items-center full-width"}>
                    <Row style={{paddingBottom: '10px'}}
                         className={"flex-center flex-align-items-center"}>
                        <SplitPanelList
                            items={[{
                                leftComponent: <div style={{fontSize: '30px'}}>{totalProducts}</div>,
                                showLinkIndicator: true,
                                title: t(title),
                                description: t(description),
                                link: `/catalog/products?fields=name&fields=catalogNo&manufacturerId=${id}`,
                                largeTitle: totalProducts
                            }]}
                        />
                    </Row>

                    <Row style={{paddingTop: '10px'}} className={'full-width'}>
                        {
                            status === 'inReview'
                                ? <Tooltip title={t("This manufacturer will be published")}>
                                    <Button style={{width: '100%', height: '40px'}} onClick={() => {
                                        this.setPublished()
                                    }}>
                                        {t('Publish')}
                                    </Button>
                                </Tooltip>
                                : null
                        }
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(
    graphql(ProductListCountQuery, {
        name: 'productListQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: {
                        manufacturerId: {
                            value: props.id,
                        }
                    }
                }
            }
        }),
    }),
    waitWhileLoading('productListQuery', ['productList']),
    graphql(UpdateManufacturerMutation, {
        name: 'updateManufacturerMutation',
        options: props => ({
            refetchQueries: [{
                query: ManufacturerQuery,
                variables: {
                    id: props.id
                }
            }, {
                query: ManufacturerListQuery,
                variables: {
                    params: {
                        filter: {
                            status: {
                                operator: "in",
                                value: _.get(props, 'filter.status')
                            }
                        },
                        limit: 40,
                        search: {
                            query: _.get(props, 'filter.query')
                        }
                    }
                }
            }, {
                query: ManufacturerListQuery
            }]

        })
    }),
    withTranslation(),
)(ManufacturerDetailViewBody)
