// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import UpdateLogisticianMutation from "../../graphql/mutations/UpdateLogistician.graphql";
import LogisticianDashboardQuery from "../../graphql/queries/LogisticianDashboard.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributesInlineForm from "./../Form/attributes-inline-form";

type Props = {
    logisticianId: string,
    attributes: any[],
    updateLogisticianMutation: Function,
};


class LogisticianUpdateForm extends Component<Props, State> {
    componentDidMount() {
    }

    render() {
        const {updateLogisticianMutation, attributes, logisticianId} = this.props;

        const config = {
            useSubmit: true,
            mutation: updateLogisticianMutation,
            onSubmit: () => {

            },
            attributes,
            translateMutationOptions: ({values}) => ({
                variables: {
                    id: logisticianId,
                    input: values
                }
            })
        };

        return <AttributesInlineForm
            {...config}
        />;
    }
}

export default compose(
    withTranslation(),
    graphql(UpdateLogisticianMutation, {
        name: 'updateLogisticianMutation'
    }),
    graphql(LogisticianDashboardQuery, {
        name: 'query',
        options: (props: Props) => ({
            variables: {
                id: props.logisticianId
            }
        }),
    }),
    waitWhileLoading('query', 'logistician.typeDefinitionAttributeList.typeDefinitionAttributes'),
    withProps((props) => ({
        attributes: _.get(props, 'query.logistician.typeDefinitionAttributeList.typeDefinitionAttributes', [])
    }))
)(LogisticianUpdateForm);
