import React from "react";
import {useQuery} from "@apollo/client";
import ComponentTCOValueQuery from "../../graphql/queries/ComponentTCOValueList.graphql";
import moment from "moment";
import Loader from "../Loader";
import _ from "lodash";
import {Descriptions} from "antd";
import CurrencyLabel from "../CurrencyLabel";

interface Props {
    date: string,
    componentId: string,
    tcoSettings: any,
}

const ComponentTCOValueInfo = ({componentId, date, tcoSettings}: Props) => {

    var start = moment(date).set("hour", 0).set("minute", 0);
    var end = moment(date).set("hour", 23).set("minute", 59);

    const query = useQuery(ComponentTCOValueQuery, {
        variables: {
            componentId,
            params: {
                includeSubComponents: !!tcoSettings.includeSubComponents,
                considerServiceTickets: !!tcoSettings.considerServiceTickets,
                filter: {
                    date: {
                        operator: "range",
                        range: {
                            from: start.toISOString(),
                            to: end.toISOString()
                        }
                    }
                }
            }
        }
    })

    if (query.loading) return <Loader size={"small"}/>

    return  <Descriptions column={1}  title={moment(date).format('LL')}>
        {_.get(query,'data.componentTcoValueList.componentTcoValues',[]).map((item) => {
            return <Descriptions.Item label={item.name}><CurrencyLabel number={item.value}/></Descriptions.Item>
        }) }

    </Descriptions>
}

export default ComponentTCOValueInfo;
