// @flow
import React, {Component} from 'react';
import {Legend, Line, LineChart, XAxis, YAxis} from "recharts";
import _ from 'lodash';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ServiceTicketListUpdateQuery from '../../graphql/queries/serviceTicketListUpdate.graphql'
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {groupByMomentFormat} from "./helperFunctions";

type Props = {
  width: number,
  height: number
};

type State = {

}

class ServiceTicketsPerMonth extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { width, height, serviceTicketListUpdateQuery } = this.props;

    const serviceTicketsPerMonth = _.get(serviceTicketListUpdateQuery, 'serviceTicketList.serviceTickets');
    const serviceTicketsPerMonthGrouped = groupByMomentFormat(serviceTicketsPerMonth, 'DD.M.');

    let chartWidth = (width * window.innerWidth * 0.2) - 40;
    let chartHeight = (height * window.innerHeight * 0.33) - 40;

    return <div>
      <LineChart  width={chartWidth} height={chartHeight} data={serviceTicketsPerMonthGrouped} margin={{top: 20, right: 20, bottom: 20, left: 20}}>
        <XAxis dataKey="date" />
        <YAxis />
        <Legend />
        <Line type="monotone" name="Servicetickets" dataKey="tickets" stroke="#8394ae" />
      </LineChart>
    </div>;
  }
}

export default compose(
  graphql(ServiceTicketListUpdateQuery, {
    name: 'serviceTicketListUpdateQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          start: 0,
          limit: 600,
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: {from: "2019-08-01T00:00:00.000Z", to: "2019-08-31T23:59:59.999Z"}}
          },
          sort: {
            update: 'ASC'
          }
        }
      }
    }),
  }),
  waitWhileLoading('serviceTicketListUpdateQuery', 'serviceTicketList.serviceTickets'),
)(ServiceTicketsPerMonth);
