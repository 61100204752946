// @flow
import React, { Component } from 'react';
import DeveloperSettings from "../../components/DeveloperSettings";
import Panel from "../../components/Panel";
import {withTranslation} from "react-i18next";
import withResources from "../../hoc/withResources";

type Props = {

};

type State = {

}

class DeveloperSettingsRoute extends Component<Props, State> {

  render() {
    const { t } = this.props;

    return <div className={"p-10"}>
        <Panel title={t("Settings")}>
            <DeveloperSettings/>
        </Panel>
    </div>;
  }
}

export default withResources(["developer"])(withTranslation()(DeveloperSettingsRoute));
