// @flow
import React, {Component} from 'react';
import _ from "lodash";
import Error from "../../components/Error";
import ServiceDepartmentDashboard from "../../components/ServiceDepartmentDashboard";
import withResources from "../../hoc/withResources";

type Props = {
    match: {
        params: {
            id: string,
            serviceDepartmentId: string,
        }
    },
    history: {
        goBack: Function,
        push: Function
    }
};

class ServiceDepartmentDashboardRoute extends Component<Props, State> {

    render() {

        const {match, history} = this.props;
        const serviceDepartmentId = _.get(match, 'params.serviceDepartmentId');
        const serviceCenterId = _.get(match, 'params.id');

        if (!serviceDepartmentId) {
            return <Error type="404"/>
        }

        return <ServiceDepartmentDashboard serviceDepartmentId={serviceDepartmentId}
                                           onDelete={() => history.push(`/admin/servicecenters/${serviceCenterId}`)}
                                           serviceCenterId={serviceCenterId}/>;
    }
}

export default withResources(["administrateServiceCenters"])(ServiceDepartmentDashboardRoute);
