// @flow
import React, {Component} from 'react';
import {Tooltip} from "antd";
import {withTranslation} from "react-i18next";
import "./index.css"

type Props = {
    code: string,
    onCopy?: Function
};

type State = {}

class CopyCode extends Component<Props, State> {

    copy = () => {
        const textarea = this.textarea;
        let {code, onCopy} = this.props;

        textarea.value = code;
        textarea.select();

        try {
            const successful = document.execCommand("copy");
            if (onCopy) onCopy(successful);
        } catch (err) {
            console.error(`Unable to copy (${err.message})`);
        }
    }

    render() {
        const {code, t} = this.props;

        return <div className={"copy-code"}>
            <Tooltip title={t('Copy to clipboard')}>
                <span className={"copy-code-code"} onClick={this.copy}>{code}</span>
                <textarea
                    ref={textarea => {
                        this.textarea = textarea;
                    }}
                    style={{position: "absolute", top: "-1000px"}}
                />
            </Tooltip>
        </div>;
    }
}

export default withTranslation()(CopyCode);
