// @flow
import React, {Component} from 'react';
import {Tooltip} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import UpdateServiceTicketSubscription from "../../graphql/mutations/UpdateServiceTicketSubscription.graphql";
import ServiceTicketSubscription from "../../graphql/queries/serviceTicketSubscription.graphql";
import _ from 'lodash';


type Props = {
    serviceTicketId: string,
    t: Function,
    updateServiceTicketSubscriptionMutation: Function,
    serviceTicketSubscriptionQuery: query,
};

type State = {
    loading: boolean,
}

const defaultSubscriptionValuesActive = {
    newServiceAction: true
};

class ServiceTicketSubscriptionButton extends Component<Props, State> {

    state = {
        loading: false
    };

    _toggleSubscription = () => {
        const {t, updateServiceTicketSubscriptionMutation, serviceTicketSubscriptionQuery, serviceTicketId} = this.props;
        const flag = !_.get(serviceTicketSubscriptionQuery, 'serviceTicket.serviceTicketSubscription.active');
        this.setState({
            loading: true
        }, () => {
            updateServiceTicketSubscriptionMutation({
                variables: {
                    id: serviceTicketId,
                    input: Object.keys(defaultSubscriptionValuesActive).reduce((acc, key) => {
                        const val = defaultSubscriptionValuesActive[key];
                        if (val === true) {
                            acc[key] = flag;
                        } else {
                            acc[key] = val;
                        }

                        return acc;
                    }, {})
                }
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })

    };

    render() {
        const {t, serviceTicketSubscriptionQuery} = this.props;
        const active = _.get(serviceTicketSubscriptionQuery, 'serviceTicket.serviceTicketSubscription.active', false);

        return <Tooltip title={t('click to subscribe')}>
            <Button loading={this.state.loading || serviceTicketSubscriptionQuery.loading} shape="circle" size='large'
                    type={active ? "primary" : "default"}
                    onClick={this._toggleSubscription}>
                <Icon type="notification"/>
            </Button>
        </Tooltip>;
    }
}


export default compose(
    graphql(ServiceTicketSubscription, {
        name: 'serviceTicketSubscriptionQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceTicketId
            }
        }),
    }),
    graphql(UpdateServiceTicketSubscription, {
        name: 'updateServiceTicketSubscriptionMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ServiceTicketSubscription,
                variables: {
                    id: props.serviceTicketId
                }
            }]
        })
    }),
    withTranslation(),
)(ServiceTicketSubscriptionButton);
