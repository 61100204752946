// @flow
import React, {Component} from "react"
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import ComponentAccess from "../../components/ComponentAccess";
import {Tabs} from "antd";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import ComponentServiceProviderAccess from "../../components/ComponentAccess/serviceProviderAccess";
import withProfile from "../../hoc/withProfile";
import withResources from "../../hoc/withResources";

type Props = {
    match: {
        params: {
            id: string,
        }
    }
}

class ComponentAccessRoute extends Component<Props> {

    render() {

        const {match, t, history, location, resources} = this.props;

        const componentId = match.params.id;

        return <div className={"bg-white"}>
            <Tabs
                className={"ph-10"}
                onChange={(key) => {
                    history.push(`/infrastructure/${componentId}/access/${key}`)
                }}
                defaultActiveKey={location?.pathname.includes("serviceprovider") ? "serviceprovider" : "user"}
            >
                {
                    resources.includes("addRoleToComponentAcl") || resources.includes("addUserToComponentAcl") ?
                        <Tabs.TabPane tab={t("User and Roles")} key="user"/>
                    : null
                }
                {
                    resources.includes("addServiceProviderToComponentAcl") ?
                        <Tabs.TabPane tab={t("Service Provider")} key="serviceprovider"/>
                    : null
                }
            </Tabs>
            <Switch>
                <Route
                    path="/infrastructure/:id/access/user"
                    component={() => <ComponentAccess
                        componentId={componentId}
                        componentPath='/infrastructure/:componentId/access/user'
                    />}
                />
                <Route
                    path="/infrastructure/:id/access/serviceprovider"
                    component={() => <ComponentServiceProviderAccess
                        componentId={componentId}
                        componentPath='/infrastructure/:componentId/access/serviceprovider'
                    />}
                />
                <Redirect exact from={"/infrastructure/:id/access"} to={"/infrastructure/:id/access/user"}/>
            </Switch>
        </div>
    }

}

export default compose(
    withTranslation(),
    withRouter,
    withProfile(),
    withResources(["addRoleToComponentAcl" || "addUserToComponentAcl" || "addServiceProviderToComponentAcl"])
)(ComponentAccessRoute);
