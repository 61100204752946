import React from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import _ from "lodash";
import ServiceReportDetail from "../../../components/ServiceReportDetail";

interface Props {

}

const ServiceReportDetailRoute = (props: Props) => {

    const history = useHistory();

    const match = useRouteMatch();

    const serviceTicketId = _.get(match, 'params.id');
    const serviceReportId = _.get(match, 'params.serviceReportId');

    return  <ServiceReportDetail
        serviceTicketId={serviceTicketId}
        serviceReportId={serviceReportId}
        handleDeleteRedirect={() => {
        return history.push(`/service/${serviceTicketId}/serviceReports`)
    }}/>;
}

export default ServiceReportDetailRoute;
