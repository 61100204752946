import React from "react";
import {useQuery} from "@apollo/client";
import _ from "lodash";
import Loader from "../Loader";
import ComponentTCOValueListQuery from "../../graphql/queries/ComponentTCOValueList.graphql";
import {withSize} from "react-sizeme";
import {Cell, Legend, Pie, PieChart, Tooltip} from "recharts";
import Empty from "../Empty";

interface Props {
    size?: any,
    componentId: string,
    tcoSettings: any
}
const RADIAN = Math.PI / 180;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const ComponentTCOPieChart = ({size, componentId, tcoSettings}: Props) => {

    const query = useQuery(ComponentTCOValueListQuery, {
        variables: {
            componentId,
            params: {
                includeSubComponents: !!tcoSettings.includeSubComponents,
                considerServiceTickets: !!tcoSettings.considerServiceTickets,
                filter: tcoSettings.date && tcoSettings.date.length === 2 ? {
                    date: {
                        operator: "range",
                        range: {
                            from: _.first(tcoSettings.date),
                            to: _.last(tcoSettings.date)
                        }
                    }
                } : undefined
            }
        }
    })

    if (query.loading) return <Loader />

    const data = _.get(query, 'data.componentTcoValueList.componentTcoValues',[])

    if (!data.length) return <Empty/>

    /*

    ({
                cx, cy, midAngle, innerRadius, outerRadius, percent, index,
            }) => {
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                const item = data[index];
                return <text x={x} y={y} >{item.name}: <CurrencyLabel number={item.value}/></text>
            }
     */


    return <PieChart width={size.width} height={300}>
        <Legend verticalAlign="bottom" height={36}/>

        <Tooltip />

        <Pie
            data={data}
            nameKey="name"
            innerRadius={80}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
        >
            {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
        </Pie>

    </PieChart>;
}

export default withSize()(ComponentTCOPieChart);
