// @flow
import React, {Component} from "react"
import ComponentCreateForm from "../../components/ComponentCreate/ComponentCreateForm";
import _ from "lodash";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

type Props = {
    match: {
        params: {
            id: string
        }
    }
}

class InfrastructureCreateComponentDetails extends Component<Props> {

    render() {

        const {match, history} = this.props;
        const componentId = _.get(match, 'params.id');

        const search = _.get(this.props, 'location.search');
        const params = queryString.parse(search) || {};
        const product = queryString.parse(params.product) || {};

        return <ComponentCreateForm
            componentTypeIndex={params.componentTypeIndex}
            parentComponentId={componentId}
            product={product}
            productId={params.productId}
            onCreated={(res) => {
                const id = _.get(res, 'data.createComponent.id');
                if(id) history.push(`/infrastructure/${_.get(res, 'data.createComponent.id')}`)
            }}
        />;
    }

}

export default withRouter(withTranslation()(InfrastructureCreateComponentDetails));
