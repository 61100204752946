// @flow
import React, {Component} from 'react';
import ComponentServiceProviderListQuery from "../../graphql/queries/componentServiceProviderList.graphql";
import ServiceProviderListQuery from "../../graphql/queries/serviceProviderList.graphql";
import ComponentClientId from "../../graphql/queries/componentClientId.graphql";
import AccessControlPicker from "../AccessControlPicker";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import UpdateComponentAclMutation from "../../graphql/mutations/UpdateComponentAcl.graphql";
import RemoveComponentAclMutation from "../../graphql/mutations/RemoveComponentAcl.graphql";
import AddServiceProviderToComponentMutation from "../../graphql/mutations/AddServicePoviderToComponentMutation.graphql";
import {withTranslation} from "react-i18next";
import withProfile from "../../hoc/withProfile";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";
import withResources from "../../hoc/withResources";

type Props = {
    componentId: string,
    addMutation: Function,
    updateMutation: Function,
    removeMutation: Function,
    componentPath: string,
};

class ComponentServiceProviderAccess extends Component<Props, State> {

    render() {
        const {t, componentId, addMutation, updateMutation, removeMutation, profile, componentClientIdQuery, componentPath} = this.props;

        const componentClientId = _.get(componentClientIdQuery, 'component.client.id');
        const profileClientId = _.get(profile, 'client.id');
        const isServiceProvider = _.get(profile, 'client.isServiceProvider');
        const host = window["__HOST__"];

        let disableServiceProviderSelect = componentClientId !== profileClientId;

        if (host === "xenon" && isServiceProvider) {
            disableServiceProviderSelect = false;
        }


        return <Panel title={t('Service Provider')} bordered={false}>
            <AccessControlPicker
                componentId={componentId}
                showSearch
                addMutation={addMutation}
                updateMutation={updateMutation}
                removeMutation={removeMutation}
                translateMutationOptions={(method, id, input) => {

                    const variables = {
                        input
                    };

                    if (method === 'create') {
                        variables.componentId = componentId;
                        variables.serviceProviderId = id;
                    }

                    if (method === 'update') {
                        variables.id = id;
                    }

                    if (method === 'delete') {
                        variables.id = id;
                    }

                    return {
                        variables,
                        refetchQueries: [
                            {
                                query: ComponentServiceProviderListQuery,
                                variables: {
                                    id: this.props.componentId
                                }
                            }
                        ]
                    }
                }}
                selectDataLoader={{
                    query: ServiceProviderListQuery,
                    dataKey: 'serviceProviderList',
                    getQueryVariables: (query) => {

                        return {
                            params: {
                                start: 0,
                                limit: 40,
                                search: {
                                    query,
                                    fields: ["name"]
                                }
                            }
                        };
                    },
                    itemsKey: 'serviceProviders',
                    totalKey: 'total',
                    textKey: 'name',
                    valueKey: 'id',
                }}
                valuesDataLoader={{
                    query: ComponentServiceProviderListQuery,
                    getQueryVariables: () => ({
                        id: this.props.componentId
                    }),
                    dataKey: 'componentServiceProviderList',
                    itemsKey: 'componentServiceProviders',
                    totalKey: 'total',
                    textKey: 'serviceProvider.name',
                    valueKey: 'id',
                    readKey: 'read',
                    writeKey: 'write',
                    serviceTicketsKey: 'serviceTickets',
                }}
                componentPath={componentPath}
                serviceTickets={true}
                disableAddSelect={disableServiceProviderSelect}
                addSelectHint={<p>
                    {t("Share component with service provider text")}
                </p>}
            />
        </Panel>
    }
}

export default compose(
    graphql(UpdateComponentAclMutation, {
        name: 'updateMutation'
    }),
    graphql(RemoveComponentAclMutation, {
        name: 'removeMutation'
    }),
    graphql(AddServiceProviderToComponentMutation, {
        name: 'addMutation',
    }),
    withTranslation(),
    graphql(ComponentClientId, {
        name: 'componentClientIdQuery',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentClientIdQuery', 'component'),
    withProfile(),
    withResources(["addServiceProviderToComponentAcl"])
)(ComponentServiceProviderAccess);
