// @flow
import styled from "styled-components";
import Colors from "../../themes/default/colors";

const Dot = styled.span`
  height: ${props => props.size ? `${props.size}px` : "5px"};
  width: ${props => props.size ? `${props.size}px` : "5px"};
  background-color: ${props => props.color ? props.color : Colors.greyDark};
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  position: relative;
`;

export default Dot;
