import React, {Component} from 'react';
import styled from 'styled-components';
import Colors from "../../themes/default/colors";

import type {DraggableLocation, DroppableProvided, DropResult,} from 'react-beautiful-dnd';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';

import Column from './column';
import _ from "lodash";

const ParentContainer = styled.div`
  height: ${({height}) => height};
  overflow-x: hidden;
  overflow-y: auto;
`;

const Container = styled.div`
  background-color: ${Colors.white};
  height: 100%;
  /* like display:flex but will allow bleeding over the window width */
  width: 100%;
  overflow: hidden;
  display: inline-flex;
`;

type Props = {|
    renderItem: (item: any) => React.ReactNode,
    columns: any,
    withScrollableColumns?: boolean,
    isCombineEnabled?: boolean,
    containerHeight?: string,
    useClone?: boolean,
    onChange?: (ids, index: number, oldColumnId: string, newColumnId: string) => Promise<any>,
|};

type State = {
    selectedItemIds: string[],
    draggingItemId?: string,
}


export default class Board extends Component<Props, State> {
    /* eslint-disable react/sort-comp */
    static defaultProps = {
        isCombineEnabled: false,
    };

    state: State = {
        selectedItemIds: [],
        draggingItemId: null,
    };

    boardRef: ?HTMLElement;

    componentDidMount() {
        window.addEventListener('click', this.onWindowClick);
        window.addEventListener('keydown', this.onWindowKeyDown);
        window.addEventListener('touchend', this.onWindowTouchEnd);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
        window.removeEventListener('keydown', this.onWindowKeyDown);
        window.removeEventListener('touchend', this.onWindowTouchEnd);
    }

    onDragStart = (start: DragStart) => {
        const id: string = start.draggableId;
        const selected: ?Id = this.state.selectedItemIds.find(
            (taskId: Id): boolean => taskId === id,
        );

        // if dragging an item that is not selected - unselect all items
        if (!selected) {
            this.unselectAll();
        }
        this.setState({
            draggingItemId: start.draggableId,
        });
    };

    onDragEnd = (result: DropResult) => {

        const destination: ?DraggableLocation = result.destination;
        // nothing to do
        if (!destination || result.reason === 'CANCEL') {
            this.setState({
                draggingItemId: null,
            });
            return;
        }

        const itemIds = this.state.selectedItemIds && this.state.selectedItemIds.length ? this.state.selectedItemIds : [result.draggableId];

        this.props.onChange(itemIds, _.get(result, 'destination.index'), _.get(result, 'source.droppableId'), _.get(result, 'destination.droppableId'))


        this.setState({
            draggingItemId: null,
            selectedItemIds: []
        });
    };

    onWindowKeyDown = (event: KeyboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.key === 'Escape') {
            this.unselectAll();
        }
    };

    onWindowClick = (event: KeyboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }
        this.unselectAll();
    };

    onWindowTouchEnd = (event: TouchEvent) => {
        if (event.defaultPrevented) {
            return;
        }
        this.unselectAll();
    };

    toggleSelection = (taskId: Id) => {
        const selectedItemIds: Id[] = this.state.selectedItemIds;
        const wasSelected: boolean = selectedItemIds.includes(taskId);

        const newTaskIds: Id[] = (() => {
            // Task was not previously selected
            // now will be the only selected item
            if (!wasSelected) {
                return [taskId];
            }

            // Task was part of a selected group
            // will now become the only selected item
            if (selectedItemIds.length > 1) {
                return [taskId];
            }

            // task was previously selected but not in a group
            // we will now clear the selection
            return [];
        })();

        this.setState({
            selectedItemIds: newTaskIds,
        });
    };

    toggleSelectionInGroup = (taskId: Id) => {
        const selectedItemIds: Id[] = this.state.selectedItemIds;
        const index: number = selectedItemIds.indexOf(taskId);

        // if not selected - add it to the selected items
        if (index === -1) {
            this.setState({
                selectedItemIds: [...selectedItemIds, taskId],
            });
            return;
        }

        // it was previously selected and now needs to be removed from the group
        const shallow: Id[] = [...selectedItemIds];
        shallow.splice(index, 1);
        this.setState({
            selectedItemIds: shallow,
        });
    };


    unselect = () => {
        this.unselectAll();
    };

    unselectAll = () => {
        this.setState({
            selectedItemIds: [],
        });
    };

    multiSelectTo = (newTaskId) => {

        const firstId = _.first(this.state.selectedItemIds);
        if (!firstId) return;
        const col = _.find(this.props.columns, (column) => {
            return _.find(column.items, {id: firstId})
        })
        if (!col) return;
        const firstIndex = _.findIndex(col.items, {id: firstId})
        const lastIndex = _.findIndex(col.items, {id: newTaskId})

        this.setState({
            selectedItemIds: col.items.filter((item, index) => index >= firstIndex && index <= lastIndex).map((item) => item.id),
        });

    }

    render() {


        const {
            columns,
            containerHeight,
            useClone,
            renderItem,
            isCombineEnabled,
            withScrollableColumns,
        } = this.props;

        const board = (
            <Droppable
                droppableId="board"
                type="COLUMN"
                direction="horizontal"
                ignoreContainerClipping={Boolean(containerHeight)}
                isCombineEnabled={isCombineEnabled}
            >
                {(provided: DroppableProvided) => (
                    <Container ref={provided.innerRef} {...provided.droppableProps}>
                        {columns.map((item: any, index: number) => {
                            return <Column
                                key={item.id}
                                id={item.id}
                                filter={item.filter}
                                queryName={item.queryName}
                                loading={item.loading}
                                index={item.id}
                                title={`${item.title}`}
                                renderItem={renderItem}
                                items={item.items}
                                total={item.total}
                                query={item.query}
                                itemsKeyPath={item.itemsKeyPath}
                                totalKeyPath={item.totalKeyPath}
                                draggingItemId={this.state.draggingItemId}
                                toggleSelection={this.toggleSelection}
                                selectedItemIds={this.state.selectedItemIds}
                                multiSelectTo={this.multiSelectTo}
                                isScrollable={withScrollableColumns}
                                isCombineEnabled={isCombineEnabled}
                                useClone={useClone}
                            />
                        })}
                        {provided.placeholder}
                    </Container>
                )}
            </Droppable>
        );

        return (
            <React.Fragment>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    {containerHeight ? (
                        <ParentContainer height={containerHeight}>{board}</ParentContainer>
                    ) : (
                        board
                    )}
                </DragDropContext>

            </React.Fragment>
        );
    }
}
