// @flow
import React, {Component} from 'react';
import FillQueryTable from "../QueryTable/fill";
import {withTranslation} from "react-i18next";
import ServiceTicketActionsListQuery from "../../graphql/queries/ServiceTicketActionsList.graphql";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import moment from "moment";
import {Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import Bold from "../Utilities/Bold";

type Props = {};

type State = {}

class ServiceActionsTable extends Component<Props, State> {

  render() {
    const { t, serviceTicketActionsListQuery, history, serviceTicketId } = this.props;

    const columns = [{

        dataIndex: 'private',
        key: 'private',
        render: (text, record) => record.private ? <Tooltip title={t("This service action is hidden")}>
            <Icon type={"lock"}/>
        </Tooltip> : null,
        width: 40
    },
        {
            title: t('Name'),
            dataIndex: 'serviceActionType.name',
            key: 'serviceActionType.name',
            render: (text, record) => {
                return <span><Bold>{_.get(record,'serviceActionType.code')}</Bold>: {text}</span>
            }
        }, {
            title: t('Done by'),
            dataIndex: 'doneBy.name',
            key: 'doneBy.name',
        }, {
            title: t('Date'),
            dataIndex: 'date',
            key: 'date',
            render: (text) => moment(text).format("LLL")
        }
    ];

    return <div>
        <FillQueryTable
            rowKey={"id"}
            columns={columns}
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push(`/service/${serviceTicketId}/actions/${record.id}`)
                    },
                };
            }}
            itemsKeyPath={'serviceActionList.serviceActions'}
            totalKeyPath={'serviceActionList.total'}
            query={serviceTicketActionsListQuery}

        />
    </div>;
  }
}
export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceTicketActionsListQuery, {
        name: 'serviceTicketActionsListQuery',
        options: (props: Props) => ({
            variables: {
                sort: {
                    insert: "DESC"
                },
                serviceTicketId: props.serviceTicketId
            }
        }),
    }),
)(ServiceActionsTable);
