// @flow
import React, {Component} from 'react';
import styled from "styled-components";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import ComponentDuplicationPanel from "../../components/ComponentDuplicationPanel";
import ComponentDuplicationInfoPanel from "../../components/ComponentDuplicationInfoPanel";
import {Col, Collapse, Row} from "antd";

type Props = {
    match: {
        params: {
            id: string
        }
    }
};


const Wrapper = styled.div`
  padding: 0 ${2 * Sizes.grid}px
`;

class ComponentDuplicate extends Component<Props, State> {

    state = {
        changes: null
    }

    render() {
        const {t, match} = this.props;
        const {changes} = this.state;

        const componentId = _.get(match, 'params.id');

        return <Wrapper>
            <Panel title={t('Duplicate')}>
                <Row gutter={4 * Sizes.grid}>
                    <Col md={24}>
                        <Row className={"mb-10"}>
                            <Col sm={8} className={"text-right pv-10"}><span
                                className={"ph-10"}>{t("Duplicate Attributes")}:</span></Col>
                            <Col sm={16}>
                                <Collapse accordion={false}>
                                    <Collapse.Panel key={'attributes'} header={t("Click to expand")}>
                                        <ComponentDuplicationInfoPanel

                                            onValuesChange={(vals) => {

                                                this.setState({
                                                    changes: {
                                                        ...changes,
                                                        ...vals
                                                    }
                                                })
                                            }}
                                            componentId={componentId}/>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <ComponentDuplicationPanel values={changes} componentId={componentId}/>
                    </Col>
                </Row>

            </Panel>
        </Wrapper>;
    }
}

export default withTranslation()(ComponentDuplicate);

