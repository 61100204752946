import styled from "styled-components";
import Sizes from "../../themes/default/sizes";

const Label = styled.span`
    font-weight: bold;
    padding-bottom: ${Sizes.grid / 2}px;
    display: inline-block;
    
    ::after { 
        content: ":";
    }
`;

export default Label;
