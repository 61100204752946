// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import MoveComponentMutation from "./../../graphql/mutations/MoveComponent.graphql";
import TDAAttributesForm from "../TDAAttributesForm";
import _ from "lodash";
import Error from "../Error";
import MultiActionWrapper from "../MultiActionWrapper";

type Props = {
    componentIds: string,
    onFinished?: () => void
};



class ComponentMultiMove extends Component<Props> {

    _handleChange = (selectedId) => {
        this.setState({
            selectedId
        })
    };


    render() {
        const {componentIds, moveComponentMutation, t, onFinished} = this.props;

        if (!componentIds || !componentIds.length){
            return <Error title={t('No components to move')} />
        }

        return <MultiActionWrapper
            onFinished={onFinished}
            total={componentIds.length}
            mutation={moveComponentMutation}>
            {({queue}) => {
                return <TDAAttributesForm
                    attributes={[{
                        index: 'parentComponentId',
                        inputType: "select",
                        name: t('Target'),
                        entityType: 'component',
                        type: "string",
                    }]}
                    disableToasts
                    mutation={(values) => {
                        return new Promise((resolve, reject) => resolve(values.variables));
                    }}
                    onSubmit={(values) => {
                        componentIds.forEach((componentId) => {
                            queue.push({
                                variables: {componentId, parentComponentId: _.get(values,'parentComponentId')}
                            })
                        })
                    }}
                />
            }}
        </MultiActionWrapper>
    }
}

export default compose(
    graphql(MoveComponentMutation, {
        name: 'moveComponentMutation',
    }),
    withTranslation()
)(ComponentMultiMove);
