// @flow
import React, {Component} from 'react';
import _ from "lodash";
import CreateServicePlanMutation from "./../../graphql/mutations/createServicePlan.graphql"
import {graphql, withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import {withRouter} from "react-router-dom";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import DefaultServiceTaskQuery from "../../graphql/queries/defaultServiceTask.graphql";
import Panel from "../Panel";
import ServicePlanAndControlQuery from "../../graphql/queries/servicePlanAndControlList.graphql";

type Props = {
    history: {
        push: Function
    },
    componentId: string,
    createServicePlanMutation: any,
    t: Function,
};

type State = {
    values: any,
}


class CreateServicePlan extends Component<Props, State> {

    state = {
        values: {}
    }

    render() {
        const {componentId, mutation, t, history, client} = this.props;
        const {values} = this.state;

        return <div className={'ph-10'}>
            <Panel title={t('New service recommendation')}>
                <TypeDefinitionAttributesForm
                    index={"servicePlan"}
                    options={{
                        componentId
                    }}
                    formProps={{
                        useSubmit: true,
                        submitTitle: t('Create'),
                        mutation: mutation,
                        values,
                        submitChangesOnly: false,
                        onChange: (vals) => {
                            const tmp = {
                                ...this.state.values,
                                ...vals
                            };
                            if (vals.defaultServiceTaskId !== undefined && !_.isEqual(this.state.values.defaultServiceTaskId, vals.defaultServiceTaskId)) {
                                const query = client.query({
                                    query: DefaultServiceTaskQuery,
                                    variables: {
                                        id: vals.defaultServiceTaskId
                                    }
                                });
                                query.then(({data}) => {
                                    const {defaultServiceTask} = data;
                                    this.setState({
                                        values: {
                                            ...tmp,
                                            name: defaultServiceTask.name,
                                            description: defaultServiceTask.description,
                                        }
                                    })
                                })

                            }
                        },
                        onSubmit: (res) => {
                            const id = _.get(res, 'data.createServicePlan.id');
                            if (id) history.push(`/infrastructure/${componentId}/serviceplans/${id}`)
                        },
                        translateMutationOptions: ({values}) => {
                            return {
                                variables: {
                                    id: componentId,
                                    input: values
                                }
                            }
                        }
                    }}
                />
            </Panel>
        </div>;
    }
}

export default compose(
    withRouter,
    withApollo,
    withTranslation(),
    graphql(CreateServicePlanMutation, {
        name: 'mutation',
        skip: props => !!props.mutation,
        options: props => ({
            refetchQueries: [{
                query: componentQuery,
                variables: {
                    id: props.componentId
                }
            }, {
                query: ServicePlanAndControlQuery,
                variables: {
                    params: {
                        filter: {
                            componentId: {
                                value: props.componentId,
                            }
                        }
                    }
                }
            }]
        })
    }),
)(CreateServicePlan)
