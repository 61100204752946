//@flow
import _ from "lodash";
import {compose} from "recompose";
import ProfileQuery from "./../graphql/queries/profile.graphql";
import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../hoc/waitWhileLoading";
import {graphql} from '@apollo/client/react/hoc';
import {setProfileLanguage, setProfileResources} from "../actions/settings";
import {Modal} from "antd";
import AGB from "../components/AGB";
import VSMLoadingAnimation from "../components/VSMLoadingAnimation";
import moment from "moment-timezone";
import ErrorPage from "../components/ErrorPage";

class UserProvider extends Component<Props, State> {

    state = {
        accepted: false
    }


    componentDidMount() {

        const { profileQuery, i18n } = this.props;
        const lang = _.first(_.get(profileQuery,'profile.language',"en").split("_"));


        if (i18n.language !== lang){
            i18n.changeLanguage(lang);
        }

        if (profileQuery && profileQuery.profile) {

            moment.locale(lang);
            moment.tz.setDefault(_.get(profileQuery.profile, 'timezone', 'Europe/Berlin'));

            const resources = _.get(profileQuery, 'profile.resources', []).reduce((acc, cur) => {
                return [...acc, cur.key]
            }, []);

            this.props.setProfileResources(resources)
            this.props.setProfileLanguage(lang)
        }

    }

  render() {
      const { children, profileQuery, t } = this.props;
      const { accepted } = this.state;

      let result = children;

      const acceptBusinessTerms = _.get(profileQuery,'profile.acceptBusinessTerms', true);
      if (acceptBusinessTerms === false && !accepted){
          result = <div>
              <Modal
                  closable={false}
                  footer={null}
                  width={800}
                  visible={!acceptBusinessTerms}
                  title={t("Please accept our business terms")}
                  bodyStyle={{
                      padding: 0
                  }}
              >
                  <AGB
                      onAccept={() => {
                          this.setState({
                              accepted: true
                          })
                      }}
                      width={800}
                  />
              </Modal>
              {children}
          </div>
      }

      return result
  }
}

function mapDispatchToProps(dispatch) {
    return {
        setProfileResources: (resources) => {
            return dispatch(setProfileResources(resources))
        },
        setProfileLanguage: (language) => {
            return dispatch(setProfileLanguage(language))
        }
    }
}

export default compose(
    graphql(ProfileQuery, {
        name: 'profileQuery'
    }),
    waitWhileLoading('profileQuery', 'profile', {
        renderLoader: () => <VSMLoadingAnimation/>,
        renderError: (error, feedback) => <ErrorPage type={500}/>
    }),
    withTranslation(),
    connect(null, mapDispatchToProps)
)(UserProvider);
