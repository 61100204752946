//@flow

import React, {Component} from "react"
import {compose} from "redux";
import _ from 'lodash';
import QueryListAdvanced from "../QueryListAdvanced";
import LogEntry from "../LogEntry";
import {Row} from "../Flex";
import Panel from "../Panel";
import LogDetailAttachment from "../LogDetailAttachment";
import UserLabel from "../UserLabel";
import {Modal} from "antd";
import {withTranslation} from "react-i18next";
import moment from 'moment';
import ComponentLogTimelineQuery from "../../graphql/queries/componentLogTimeline.graphql";

type Props = {
    componentId: string,
    values: any,
};

type State = {
    visible: boolean,
    item: any
}

class ComponentLogHistoryList extends Component<Props, State> {
    componentDidMount() {  }

    state = { visible: false };

    render() {
        const { componentId, values, t } = this.props;
        const { visible, item } = this.state;

        return <div style={{height: 300}}>
            <QueryListAdvanced
                showSearchBar={false}
                itemsKeyPath={'component.logList.logs'}
                totalKeyPath={'component.logList.total'}
                startKeyPath={'params.start'}
                limitKeyPath={'params.limit'}
                style={{height: "100%"}}
                query={ComponentLogTimelineQuery}
                queryProps={{
                    variables: {
                        id: componentId,
                        params: {
                            filter: values ?  {
                                level: {
                                    operator: 'in',
                                    value: _.get(values, 'logLevel', [])
                                }
                            } : undefined,
                            sort: {
                                insert: 'DESC'
                            }
                        }
                    }
                }}
                renderItem={(item) => {
                    return <LogEntry key={item.id} item={item} componentId={componentId} showModal={(item) => {
                        this.setState({
                            visible: true,
                            item
                        })
                    }}/>
                }}
            />
            {
                item
                    ? <Modal
                        width={800}
                        visible={visible}
                        title={item.title}
                        footer={null}
                        onCancel={() => this.setState({
                            visible: false
                        })}
                    >
                        <Row style={{width: '100%'}}>
                            <Panel bordered={false} style={{width: '100%'}} containerStyle={{width: '100%', minHeight: 100}} title={t('Message')}>
                                {item.message}
                            </Panel>
                        </Row>
                        <Row style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                            <Panel bordered={false} style={{width: '45%'}} containerStyle={{width: '100%', minHeight: 50}} title={t('Links')}>
                                <LogDetailAttachment log={item}/>
                            </Panel>
                            <Panel bordered={false} style={{width: '45%'}} containerStyle={{width: '100%', minHeight: 50, display: 'flex', flexDirection: 'column'}} title={t('Details')}>
                                <span><UserLabel userInfo={item.user} showIcon={true}/></span>
                                <span>{moment(item.insert).format('L LT')}</span>
                            </Panel>
                        </Row>
                    </Modal>
                    : null
            }
        </div>;
    }
}

export default compose(
    withTranslation()
)(ComponentLogHistoryList);
