// @flow
import React, {Component} from 'react';
import {Col, Grow, Row} from "../Flex";
import ComponentTypeIcon from "../ComponentTypeIcon";
import OneLineLabel from "../OneLineLabel";
import _ from "lodash";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import {Dropdown, Menu} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ComponentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentStatusButton from "../ComponentStatusButton";
import ComponentSubscriptionButton from "../ComponentSubscriptionButton";
import ComponentStarredButton from "../ComponentStarredButton";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import CULabel from "./../CULabel";
import ComponentImage from "../ComponentImage";
import RouterBreadcrumbs from "../../routes/RouterBreadcrumbs";
import withProfile from "../../hoc/withProfile";


type Props = {
    componentId: string,
    componentQuery: query,
    t: Function,
}

class ComponentDashboardHeader extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {visible: false}
    }

    render() {
        const {componentQuery, t, componentId, resources} = this.props;

        const component = componentQuery.component;
        const read = _.get(component, 'access.read',false);
        const write = _.get(component, 'access.write',false);

        const menuCategoryStyle = {
            background: '#e9e9e9',
            color: '#444',
            padding: '5px 0px 5px 10px',
            cursor: 'default',
            fontSize: '15px'
        }

        let items = [];

        if (read){
            items = [
                <Menu.Item
                    key="1"
                    disabled
                    style={menuCategoryStyle}
                >
                    {t('Component')}
                </Menu.Item>,
                <Menu.Item key="11">
                    <Link to={`/infrastructure/${componentId}/forms`}>
                        <div style={{paddingLeft: '5px'}}>
                            {t('Edit')}
                        </div>
                    </Link>
                </Menu.Item>,
                <Menu.Item key="12">
                    <Link to={`/infrastructure/${componentId}/files`}>
                        <div style={{paddingLeft: '5px'}}>
                            {t('Files & QR-Code')}
                        </div>
                    </Link>
                </Menu.Item>,
                <Menu.Item key="13">
                    <Link to={`/infrastructure/create/${componentId}`}>
                        <div style={{paddingLeft: '5px'}}>
                            {t('Add subcomponent')}
                        </div>
                    </Link>
                </Menu.Item>,
                <Menu.Item key="14">
                    <Link to={`/infrastructure/${componentId}/duplicate`}>
                        <div style={{paddingLeft: '5px'}}>
                            {t('Duplicate')}
                        </div>
                    </Link>
                </Menu.Item>,
                <Menu.Item key="15">
                    <Link to={`/infrastructure/${componentId}/move`}>
                        <div style={{paddingLeft: '5px'}}>
                            {t('Move')}
                        </div>
                    </Link>
                </Menu.Item>,
                <Menu.Item key="16">
                    <Link to={`/infrastructure/${componentId}/delete`}>
                        <div style={{paddingLeft: '5px'}}>
                            {t('Delete')}
                        </div>
                    </Link>
                </Menu.Item>,
                <Menu.Item
                    key="2"
                    disabled
                    style={menuCategoryStyle}
                >
                    {t('Service')}
                </Menu.Item>,
                <Menu.Item key="21">
                    <Row>
                        <Grow>
                            <Link to={`/infrastructure/${componentId}/serviceplans`}>
                                <div style={{paddingLeft: '5px'}}>
                                    {t('Serviceplans')}
                                </div>
                            </Link>
                        </Grow>
                        <Col>
                            <Link to={`/infrastructure/${componentId}/serviceplans/new`}>
                                <Icon type="plus-circle" />
                            </Link>
                        </Col>
                    </Row>
                </Menu.Item>,
                <Menu.Item key="22">
                    <Row>
                        <Grow>
                            <Link to={`/infrastructure/${componentId}/servicetickets`}>
                                <div style={{paddingLeft: '5px'}}>
                                    {t('Servicetickets')}
                                </div>
                            </Link>
                        </Grow>
                        <Col>
                            <Link to={`/infrastructure/${componentId}/servicetickets/new`}>
                                <Icon type="plus-circle" />
                            </Link>
                        </Col>
                    </Row>
                </Menu.Item>,
                <Menu.Item
                    key="3"
                    disabled
                    style={menuCategoryStyle}
                >
                    {t('Admin')}
                </Menu.Item>,
                resources.includes("addRoleToComponentAcl") || resources.includes("addUserToComponentAcl") || resources.includes("addServiceProviderToComponentAcl") ?
                    <Menu.Item key="31">
                        <Link to={`/infrastructure/${componentId}/access`}>
                            <div style={{paddingLeft: '5px'}}>
                                {t('Access protection')}
                            </div>
                        </Link>
                    </Menu.Item>
                : null
            ]
        }

        /*

                    <Menu.Item key="token">
                    <Link to={`/infrastructure/${componentId}/token`}>
                        <div style={{paddingLeft: '5px'}}>
                            {t('Token')}
                        </div>
                    </Link>
                </Menu.Item>,

                     */

        const DropdownMenu = <Menu style={{width: 300}}>
            {items}
        </Menu>;

        return (
            <Col style={{width: '100%', padding: '10px'}}>
                <Row style={{overflow: 'hidden'}}>
                    <Col>
                        <ComponentImage editable componentId={componentId}/>
                    </Col>
                    <Grow className={"ph-10 pv-5"}>
                        <div style={{fontSize: 18}}>
                            <ComponentTypeIcon componentTypeIndex={component.componentType.index}/>
                            <span style={{paddingLeft: '5px'}}>
                        {component.name}
                    </span>
                        </div>
                        {
                            component.product
                                ? <div>
                                    <OneLineLabel>
                                        <span>{t('Serial')}: </span>
                                        <span>{_.get(component, 'serialNo')}</span>
                                    </OneLineLabel>
                                    <OneLineLabel>
                                        <span>{t('Catalog No')}: </span>
                                        <span>{_.get(component, 'product.catalogNo')} ({_.get(component, 'product.manufacturer.name')})</span>
                                    </OneLineLabel>
                                </div>
                                : <div>
                                    <FontAwesomeIcon name={'sitemap'}/> {_.get(component, 'childrenCount')} {t('Sub Components')}
                                </div>
                        }
                    </Grow>
                    <Col>
                        <Row>
                            <div style={{paddingLeft: '10px', alignSelf: 'center'}}>
                                <CULabel componentId={componentId} style={{padding: 5}}/>
                            </div>
                            {
                                _.get(component, 'componentType.product') ?
                                    <div style={{paddingLeft: '10px', paddingRight: '10px', alignSelf: 'center'}}>
                                        <ComponentStatusButton componentId={componentId}/>
                                    </div>
                                : null
                            }
                            <div
                                style={{paddingLeft: '10px', paddingRight: '10px'}}
                                tour-id="componentDashboard__qrButton"
                            >
                                <Button size={'large'} shape="circle"
                                        href={`#/infrastructure/${componentId}/files/qrcode`}>
                                    <Icon type="qrcode"/>
                                </Button>
                            </div>
                            <div
                                style={{paddingLeft: '10px', paddingRight: '10px'}}
                                tour-id="componentDashboard__starButton"
                            >
                                <ComponentStarredButton componentId={componentId}/>
                            </div>
                            <div
                                style={{paddingLeft: '10px', paddingRight: '10px'}}
                                tour-id="componentDashboard__notificationButton"
                            >
                                <ComponentSubscriptionButton componentId={componentId}/>
                            </div>
                            <div
                                style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '19px'}}
                                tour-id="componentDashboard__moreButton"
                            >
                                <Dropdown overlay={DropdownMenu} trigger={['click']}>
                                    <Button theme="outlined" shape="circle" icon="ellipsis" size={'large'}
                                            onClick={() => {

                                            }}/>
                                </Dropdown>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="border-radius-5 bg-light-grey mt-5 component-header-breadcrumb" style={{padding: '5px'}}>
                    <ComponentPathBreadcrumb showSubComponents hideLastItem componentId={componentId}/>
                    <span className={"mh-5"}>/</span>
                    <RouterBreadcrumbs/>
                </Row>
            </Col>
        );
    }
}


export default compose(
    graphql(ComponentQuery, {
        name: 'componentQuery',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentQuery', ['component'], {
        showLoader: false
    }),
    withTranslation(),
    withProfile(),
)(ComponentDashboardHeader);
