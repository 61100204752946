// @flow
import React, {Component} from 'react';
import {Col, Row} from "../Flex";
import {Avatar} from "antd";
import * as _ from "lodash";
import {withTranslation} from "react-i18next";

type Props = {
  product: any,
};

type State = {

}

class ProductSearchItem extends Component<Props, State> {

  render() {
    const { product, t } = this.props;

    return <Row style={{width: '100%'}}>
        <Col style={{alignItems: 'center'}}>
            <Avatar shape={'square'}
                    style={{verticalAlign: 'middle'}}
                    icon={"code-sandbox"}
                    size={70}
                    src={_.get(product, 'image.thumbnailLink')}/>
        </Col>
        <Col style={{paddingLeft: 10}}>
            <span style={{fontSize: 18}}>{product.name}</span>
            <span style={{fontSize: 12}}>{t('Catalog No')}: {product.catalogNo}</span>
            <span style={{fontSize: 12}}>{t('Manufacturer')}: {product.manufacturer.name}</span>
        </Col>
    </Row>;
  }
}

export default withTranslation()(ProductSearchItem);
