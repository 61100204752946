import React, {Component} from "react";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ComponentTCOQuery from "../../graphql/queries/componentTCOBasis.graphql";
import UpdateComponentTcoValueMutation from "../../graphql/mutations/updateComponentTcoValue.graphql";
import DeleteComponentTcoValueMutation from "../../graphql/mutations/deleteComponentTcoValue.graphql";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import QueryTable from "../QueryTable";
import moment from "moment";
import EditableMutation from "../EditableMutation";
import {Link} from "react-router-dom";
import Toolbar from "../Toolbar";
import {Modal, Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import ComponentTCOCreateForm from "../ComponentTCOCreateForm";
import MutationButton from "../MutationButton";
import CurrencyLabel from "../CurrencyLabel";
import Bold from "../Utilities/Bold";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";

type Props = {
    componentId: string,
    tcoSettings: any
}

type State = {
    modal: boolean
}

class ComponentTCOValuesTable extends Component<Props, State> {

    state = {
        modal: false
    };

    render() {
        const { t , componentTCOQuery, updateComponentTcoValueMutation, componentId, deleteComponentTcoValueMutation} = this.props;

        console.log(componentTCOQuery);

        return <div>
            <Toolbar renderLeft={() => <div></div>} renderRight={() => {
                return <div>
                    <Button icon={'plus'} onClick={() => this.setState({modal: true})}>
                        {t("Create")}
                    </Button>
                    <Modal
                        title={t('New TCO value')}
                        footer={null}
                        onOk={() => this.setState({modal: false})}
                        onCancel={() => this.setState({modal: false})}
                        visible={this.state.modal}>
                        <ComponentTCOCreateForm onDone={() => {

                            componentTCOQuery.refetch();
                            this.setState({modal: false})
                        }} componentId={componentId}/>
                    </Modal>
                </div>
            }}/>
            <QueryTable
                query={componentTCOQuery}
                columns={[{
                    title: t('Name'),
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record) => {

                        if (record.type === 'serviceTicket'){
                            return <Link to={`/service/${record.id}`}>{text}</Link>
                        }
                        if (record.type === 'component'){
                            return <span><Tooltip title={<div>
                                <ComponentPathBreadcrumb componentId={_.get(record,'component.id')}/>
                            </div> }>
                            <Bold>{_.get(record,'component.name')}</Bold></Tooltip>: {text}</span>
                        }
                        if (record.type === 'manual'){
                            return <div><EditableMutation value={text}
                                                     mutation={updateComponentTcoValueMutation}
                                                     translateMutationOptions={(name) => {
                                                         return {
                                                             variables: {
                                                                 componentTcoValueId: record.id,
                                                                 input: {
                                                                     name
                                                                 }
                                                             }
                                                         }
                                                     }}
                                                     onDone={() => {
                                                         componentTCOQuery.refetch();
                                                     }} /></div>
                        }
                        return text
                    }
                },
                    {
                        title: t('Value'),
                        dataIndex: 'value',
                        key: 'value',
                        render: (text, record) => {

                            if (record.type === 'formula'){
                                return <CurrencyLabel number={text} />
                            }

                            return <EditableMutation value={text}
                                                     renderDisplay={(value) => <CurrencyLabel number={value} />}
                                                     mutation={updateComponentTcoValueMutation}
                                                     translateMutationOptions={(value) => {
                                                         return {
                                                             variables: {
                                                                 componentTcoValueId: record.id,
                                                                 input: {
                                                                     value
                                                                 }
                                                             }
                                                         }
                                                     }}
                                                     onDone={() => {
                                                         componentTCOQuery.refetch();
                                                     }} />
                        }
                    }, {
                        title: t('Date'),
                        dataIndex: 'date',
                        key: 'date',
                        render: (text) => moment(text).format('DD.MM.YYYY HH:mm')
                    },{
                        title: null,
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <MutationButton mutation={() => deleteComponentTcoValueMutation({
                            variables: {
                                id
                            }
                        }).then(() => componentTCOQuery.refetch() )} buttonProps={{icon: 'delete', type: 'link'}} confirm={true} />
                    }]}
                itemsKeyPath={"component.componentTcoValueList.componentTcoValues"}
                totalKeyPath={"component.componentTcoValueList.total"}
            />
        </div>;
    }
}


export default compose(
    graphql(ComponentTCOQuery,{
        name: 'componentTCOQuery',
        options: (props) => ({
            variables: {
                id: props.componentId,
                params: {
                    // ...props.tcoSettings
                    includeSubComponents: !!props.tcoSettings.includeSubComponents,
                    considerServiceTickets: !!props.tcoSettings.considerServiceTickets,
                    filter: props.tcoSettings.date && props.tcoSettings.date.length === 2 ? {
                        date: {
                            operator: "range",
                            range: {
                                from: _.first(props.tcoSettings.date),
                                to: _.last(props.tcoSettings.date)
                            }
                        }
                    } : undefined
                }
            }
        } )
    }),
    graphql(UpdateComponentTcoValueMutation,{
        name: 'updateComponentTcoValueMutation'
    }),
    graphql(DeleteComponentTcoValueMutation,{
        name: 'deleteComponentTcoValueMutation',
    }),
    withTranslation()
)(ComponentTCOValuesTable);
