import {tourConfig} from "../components/TourGuide/tourConfig";

export function getAllTourSteps() {
  let tourSkipInput = [];
  Object.keys(tourConfig).map((item, index) => {
    tourSkipInput.push({
      tourIndex: item,
      steps: tourConfig[item].map((o) => o.id)
    })
  });

  return tourSkipInput;
}

export function formatTourInput(steps) {
  let tourSkipInput = [];
  steps.map((item) => {
    tourSkipInput.push({
      tourIndex: item.tourIndex,
      steps: [item.id]
    })
  });

  return tourSkipInput;
}
