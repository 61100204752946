// @flow
import React from "react";
import {Modal} from "antd";
import BookmarkDetailView from '../BookmarkDetailView';
import {useQuery} from "@apollo/client";
import bookmarkData from "../../graphql/queries/bookmarkData.graphql";
import Loader from "../Loader";
import BookmarkDetailModalHeader from "../BookmarkDetailModalHeader";
import BookmarkDetailModalFooter from "../BookmarkDetailModalFooter";
import _ from 'lodash';

type Props = {
    visible: boolean,
    onClose: () => void,
    id: string
}

const BookmarkDetailModal = (props: Props) => {

    const { visible, onClose, id } = props;

    const {data, loading} = useQuery(bookmarkData, {
        variables: {
            bookmarkId: id
        }
    });

    if(loading) return <Loader/>;

    return <Modal
        title={<BookmarkDetailModalHeader
            bookmark={_.get(data, 'bookmark')}
            onClose={onClose}
        />}
        visible={visible}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        footer={<BookmarkDetailModalFooter bookmark={_.get(data, 'bookmark')}/>}
        width={'75%'}
    >
        <BookmarkDetailView
            bookmark={_.get(data, 'bookmark')}
        />
    </Modal>
};

export default BookmarkDetailModal
