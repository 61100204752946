import React from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import ComponentTCOValuesTable from "../ComponentTCOValuesTable";
import ComponentTCOCumulativeChart from "../ComponentTCOCumulativeChart";
import ComponentTCODailyChart from "../ComponentTCODailyChart";
import ComponentTCOPieChart from "../ComponentTCOPieChart";

interface Props {
    tcoSettings: any,
    componentId: string,
}

const ComponentTCOTabs = ({tcoSettings, componentId}: Props) => {

    const {t} = useTranslation();

    return <Tabs>
        <Tabs.TabPane tab={t("Cumulative")} key="cumulative">
            <ComponentTCOCumulativeChart tcoSettings={tcoSettings} componentId={componentId} resultFormat={'cumulative'} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Daily")} key="daily">
            <ComponentTCODailyChart tcoSettings={tcoSettings} componentId={componentId} resultFormat={'daily'} />
        </Tabs.TabPane>

        <Tabs.TabPane tab={t("Relative")} key="relative">
            <ComponentTCOPieChart tcoSettings={tcoSettings} componentId={componentId} />
        </Tabs.TabPane>
    </Tabs>;
}

export default ComponentTCOTabs;
