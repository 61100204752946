// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Switch} from "antd";
import {Icon} from "@dreebit/react-components";
import AttributesInlineForm from "../Form/attributes-inline-form";
import _ from "lodash";
import {categoryIndizesToGroups} from "../../utils/categoryHelper";
import UpdateComponentSubscription from "../../graphql/mutations/UpdateComponentSubscription.graphql";
import ComponentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import ComponentSubscriptionQuery from "../../graphql/queries/componentSubscription.graphql";
import ComponentSubscriptionImage from "./ComponentSubscriptionImage";
import Label from "../Utilities/Label";
import {toastError} from "../../utils/toast";


type Props = {
    id: any,
    onSubmit: Function,
    data: any,
    t: Function,
    index: any,
    mutation: Function,
}

type State = {
    custom: boolean
}

const defaultNotificationValuesActive = {
    children: true,
    logLevel: ["error", "warning", "information"],
    serviceTicket: true,
    days: 7,
    planned: true,
    unplanned: true,
};

const defaultNotificationValuesInactive = {
    children: false,
    logLevel: [],
    serviceTicket: false,
    days: 0,
    planned: false,
    unplanned: false,
};


class ComponentSubscribeForm extends Component<Props, State> {

    state = {
        custom: false,
        loading: false,
    };

    _handleActivationChange = (checked: boolean) => {

        const {mutation, componentId} = this.props;

        const input = Object.keys(defaultNotificationValuesActive).reduce((acc, cur) => {
            const val = checked ? defaultNotificationValuesActive[cur] : defaultNotificationValuesInactive[cur];
            acc[cur] = val;
            return acc;
        }, {});
        this.setState({
            loading: true
        }, () => mutation({
            variables: {
                id: componentId,
                input
            }
        })
            .catch((err) => {
                toastError(err);
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            }))

    };

    renderSimple() {

        const {t, query} = this.props;

        const active = _.get(query, 'component.componentSubscription.active', false);

        return <div style={{position: 'relative'}}>
            <div style={{textAlign: 'center', padding: 16}}>
                <ComponentSubscriptionImage width={350} style={{opacity: 0.8}}/>
            </div>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div className={"bg-translucent-white-8 p-10"}
                     style={{borderRadius: 5, maxWidth: "70%", border: "1px solid #ccc"}}>
                    <p>{t('Subscription Info Text')}</p>
                    <div className={"text-center"}>
                        <Label className={"mr-10"}>{t('Activate')}</Label>
                        <Switch
                            checked={active}
                            loading={this.state.loading}
                            onChange={this._handleActivationChange}
                            checkedChildren={<Icon type="check"/>}
                            unCheckedChildren={<Icon type="close"/>}/>
                    </div>
                    <div className={"mt-10"}>
                        <div>{t('Component Subscription Default Features')}</div>
                        <ul>
                            <li>{t('Changes and log entries')}</li>
                            <li>{t('Changes on service tickets')}</li>
                            <li>{t('Upcoming service plans in the next 7 days')}</li>
                        </ul>
                        <span onClick={() => {
                            this.setState({
                                custom: true
                            })
                        }} className={"link"}>{t('Click here to edit custom notification settings')}</span>
                    </div>
                </div>
            </div>
        </div>
    }

    equalsDefaultSubscription(componentSubscription: any) {
        return true;

    }

    render() {
        const {t, mutation, query, componentId, onSubmit} = this.props;
        let {custom} = this.state;

        const componentSubscription = _.get(query, 'component.componentSubscription');
        const attributes = _.get(query, 'component.componentSubscription.typeDefinitionAttributeList.typeDefinitionAttributes', []);

        if (componentSubscription && !this.equalsDefaultSubscription(componentSubscription)) {
            custom = true;
        }

        const groups = categoryIndizesToGroups(_.chain(attributes.map(item => item.categories))
                .flatten()
                .uniq()
                .value()
            , t, true);

        return <div>
            {custom ? <div className={'mh-20'}><AttributesInlineForm
                groups={groups}
                attributes={attributes}
                useSubmit={true}
                t={t}
                submitButtonWrapperStyle={{textAlign: 'right', paddingRight: 16}}
                mutation={mutation}
                translateMutationOptions={({values}) => ({
                    variables: {
                        id: componentId,
                        input: values
                    }
                })}
                onSubmit={onSubmit}
            /></div> : this.renderSimple()}

        </div>
    }
}

export default compose(
    graphql(ComponentSubscriptionQuery, {
        name: 'query',
        options: (props) => ({
            variables: {
                id: props.componentId
            }
        })
    }),
    graphql(UpdateComponentSubscription, {
        name: 'mutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ComponentQuery,
                variables: {
                    id: props.componentId
                }
            }]
        })
    }),
    withTranslation(),
)(ComponentSubscribeForm);
