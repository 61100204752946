//@flow
import _ from "lodash"

export default function groupSites(components: any[]): any {

    return _.groupBy(components, (component) => {

        const attributeSite = _.chain(component)
            .get("location")
            .value();

        if (attributeSite) {
            return attributeSite;
        }

        return _.chain(component)
            .get("path")
            .find((pathEntry) => _.get(pathEntry, 'componentType.index') === 'site')
            .get("name")
            .value();
    })
}