// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {Link} from "react-router-dom";
import {Button as AntdButton, Checkbox, Tooltip} from "antd";
import ComponentPathButton from "../ComponentPathButton";
import {Button, Icon} from "@dreebit/react-components";
import ComponentAccessQuery from "../../graphql/queries/componentAccess.graphql"
import {compose} from "recompose";
import _ from "lodash"

const ButtonGroup = AntdButton.Group;


type Props = {
    componentId: string,
    hideTools: boolean,
    hidePathButton: boolean,
    hideBackButton: boolean,
    onBackPress: Function,
    onAddPress: Function,
    onInfoPress: Function,
    onPathItemClick: Function,
    onSelectChange?: Function,
    onClose?: Function,
    stockSearchEnabled?: boolean,
    onStockSearch?: Function,
}

class ComponentBrowserHeader extends Component<Props> {

    static defaultProps = {
        showClose: true,
    };

    render() {

        const {
            t,
            onAddPress,
            onBackPress,
            onStockSearch,
            onClose,
            onPathItemClick,
            hideBackButton,
            hideTools,
            onSelectChange,
            selectedComponent,
            stockSearchEnabled,
            componentId,
            componentAccessQuery
        } = this.props;

        const componentWriteAccess = _.get(componentAccessQuery, 'component.access.write')

        return <div className="component-browser-header flex-row flex-content-space-between">

            {onSelectChange && componentId ?
                <div style={{marginRight: 5, paddingTop: 5, marginLeft: 3}} className="mr-5 pt-5 ml-3">
                    <Checkbox
                        data-cy={'TEST_componentBrowserHeaderCheckbox'}
                        checked={selectedComponent === componentId}
                        onChange={(event) => {
                            onSelectChange({id: componentId}, event)
                        }
                        }
                    />
                </div> : null}

            <div className="flex-grow-1">
                <ComponentPathButton
                    stockSearchEnabled={stockSearchEnabled}
                    onStockSearch={onStockSearch}
                    hideBackButton={hideBackButton}
                    onMenuItemPress={onPathItemClick}
                    onBackPress={onBackPress}
                    componentId={componentId}/>
            </div>
            <div className={"pv-5"}>
                {!hideTools ?
                    <ButtonGroup>
                        {/*{onInfoPress ? <Button onClick={onInfoPress} icon={'info-circle-o'}/> : pathItems && pathItems.length > 0 ? <Button><Link to={`/infrastructure/${pathItems[0].id}`}><Icon type={'info-circle-o'} /></Link></Button> : null}*/}
                        {onAddPress ?
                            <Button
                                data-cy={'TEST_componentBrowserHeaderAddButton'}
                                size={"small"}
                                onClick={onAddPress}
                                icon={'plus'}
                            /> : componentId ?
                                <Tooltip
                                    title={componentWriteAccess ? undefined : t('componentCreatePermissionTooltip')}>
                                    <div>
                                        <Button
                                            data-cy={'TEST_componentBrowserHeaderAddButton'}
                                            size={"small"}
                                            style={{justifyContent: 'center', alignItems: 'center'}}
                                            tour-id="infrastructure__addComponentButton"
                                            disabled={!componentWriteAccess}
                                        >
                                            <Link to={`/infrastructure/create/${componentId}`}>
                                                <Icon type={'plus'}/>
                                            </Link>
                                        </Button>
                                    </div>
                                </Tooltip> : null}
                    </ButtonGroup> : null}
            </div>
        </div>;
    }

}

export default compose(
    graphql(ComponentAccessQuery, {
        name: 'componentAccessQuery',
        options: (props: Props) => ({
            variables: {
                componentId: props.componentId
            }
        }),
    }),
    withTranslation()
)(ComponentBrowserHeader);
