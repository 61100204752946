import _ from "lodash";
import { useSelector } from 'react-redux'


export default function useResourceCheck(resource: string) {

    return useSelector((state) => {
        const resources = _.get(state, 'settings.resources')

        return resources.indexOf(resource) > -1;
    })
};
