// @flow
import React, {Component} from 'react';
import styled from "styled-components";
import moment from "moment";

type Props = {
    date: string,
    size: number,
    borderColor?: string,
};


const CalendarWrapper = styled.div`
  width: ${(props) => (props.size)}px;
  height: ${(props) => (props.size)}px;
  border-radius: ${(props) => (0.1 * props.size)}px;
  border: 1px solid ${(props) => (props.borderColor)};
  overflow: hidden;
`;

const CalendarHeader = styled.div`
  background-color: red;
  text-align: center;
  width: 100%;
  height: 35%;
`;

const CalendarMain = styled.div`      
  background-color: white;
  width: 100%;
  height: 65%;
`;

const Month = styled.span`
  color: white;
  vertical-align: middle;
  font-size: ${(props) => (0.2 * props.size)}px;
`;

const Day = styled.span`
  color: black;
  vertical-align: middle; 
  font-size: ${(props) => (0.5 * props.size)}px;
`;


class CalendarDate extends Component<Props> {

    static defaultProps = {
        borderColor: 'black',
    };

    render() {
        const {date, size, borderColor} = this.props;

        return <CalendarWrapper size={size} borderColor={borderColor}>
            <CalendarHeader className={'flex flex-center flex-align-items-center'}>
                <Month size={size}>{moment(date).format('MMM')}</Month>
            </CalendarHeader>
            <CalendarMain className={'flex flex-center flex-align-items-center'}>
                <Day size={size}>{moment(date).format('D')}</Day>
            </CalendarMain>
        </CalendarWrapper>;
    }
}

export default CalendarDate;
