import React from "react";
import {useQuery} from "@apollo/client";
import _ from "lodash";
import Loader from "./../Loader";
import ComponentTokenPreviewQuery from "./../../graphql/queries/ComponentTokenPreview.graphql"
import ComponentInfoBox from "../ComponentInfoBox";
import {Alert} from "antd";
import {useTranslation} from "react-i18next";

interface Props {
    token?: string,

}

const ComponentTokenPreview = ({token}: Props) => {

    const {t} = useTranslation();
    const query = useQuery(ComponentTokenPreviewQuery, {
        variables: {
            token
        }
    })

    if (query.loading) return <Loader size={'small'}/>

    const component = _.get(query,'data.componentMoveToken.component');

    if (!component){
        return <Alert
            showIcon
            type={'error'}
            message={t("The requested component could not be found")}
            description={_.get(query, 'error.message') || t("Are you sure you used the right token")}
        />
    }

    return <div>
        <ComponentInfoBox componentData={component} />
    </div>;
}

export default ComponentTokenPreview;
