import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ServiceContractClientNameQuery from "../../graphql/queries/serviceContractClientName.graphql"

const ServiceContractsBreadcrumb = ({serviceContractClientNameQuery}) =>
  <span>{_.get(serviceContractClientNameQuery, 'serviceContract.client.name', '...')}</span>;

export default compose(
  graphql(ServiceContractClientNameQuery, {
    name: 'serviceContractClientNameQuery',
    options: ({match}) => ({
      variables: {
        id: _.get(match, 'params.id'),
      }
    })
  }),
)(withTranslation()(ServiceContractsBreadcrumb));
