import React from "react";
import _ from "lodash";
import ComponentTokenQuery from "./../../graphql/queries/componentToken.graphql"
import ComponentTokenInfoPanel from "../ComponentTokenInfoPanel";
import Loader from "./../Loader";
import {useQuery} from "@apollo/client";
import ComponentTokenCreateForm from "../ComponentTokenCreateForm";
import {Col, Row} from "antd";

interface Props {
    componentId: string,
}

const ComponentTokenDetails = ({componentId}: Props) => {

    const query = useQuery(ComponentTokenQuery,{
        fetchPolicy: 'network-only',
        variables: {
            componentId
        }
    })



    if (query.loading) return <Loader/>

    const token = _.get(query,'data.component.componentMoveToken');

    if (token){
        return <ComponentTokenInfoPanel componentId={componentId} />
    }
    return <Row>
        <Col xl={{ span: 16, offset: 4 }} lg={{ span: 18, offset: 3 }} md={{ span: 24, offset: 0 }} className={'p-10'}>
            <ComponentTokenCreateForm onDone={() => {
                query.refetch();
            }} componentId={componentId} />
        </Col>
    </Row>
}

export default ComponentTokenDetails;
