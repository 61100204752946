import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Breadcrumb} from "antd";
import ComponentPathInfoQuery from "../../graphql/queries/ComponentPathInfo.graphql"
import {NavLink} from "react-router-dom";

const BreadcrumbItem = Breadcrumb.Item;

// @TODO : Breadcrumb mit mehreren Pfadeinträgen fixen

const StockBreadcrumb = ({ComponentPathInfoQuery, t}) => {

    const componentPath = _.get(ComponentPathInfoQuery, 'componentInfo.path', []);
    const filteredPath = componentPath.slice(_.findIndex(componentPath, (o) => {
        return o.componentType.index === 'stock'
    }), componentPath.length);

    return filteredPath.map((pathEntry, index) => {
        if (index < filteredPath.length - 1) {
            return <BreadcrumbItem key={pathEntry.id}><NavLink
                to={`/infrastructure/stock/${pathEntry.id}`}>{pathEntry.name}</NavLink></BreadcrumbItem>
        }
        return <span key={pathEntry.id}>{pathEntry.name}</span>
    });
};


export default compose(
    graphql(ComponentPathInfoQuery, {
        name: 'ComponentPathInfoQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.componentId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(StockBreadcrumb));
