// @flow
import React, { Component } from 'react';
import packageJSON from "./../../../package.json";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from "lodash";

type Props = {

};

type State = {

}

class VersionLabel extends Component<Props, State> {

  render() {
    const { t } = this.props;

    const beVersion = window["__BE_VERSION__"];
    const host = window["__HOST__"];
    const cnIcpData = _.get(window, '__CN_ICP__');

      return <div>
        <div className={"flex-col"}>
            <span className={"text-gray"}>
                <span>API: {beVersion}</span>
                <span> | </span>
                <span>Web: {process.env.REACT_APP_VERSION || packageJSON.version}</span>
            </span>
            {cnIcpData && <a target="_BLANK" href={_.get(cnIcpData, 'icp.url')}>{_.get(cnIcpData, 'icp.number')}</a>}
            {cnIcpData && <a target="_BLANK" href={_.get(cnIcpData, 'psb.url')}>{_.get(cnIcpData, 'psb.icon')}{_.get(cnIcpData, 'psb.number')}</a>}
        </div>
        {
            host === "pv" ?
                <div>
                    <span className={"text-gray"}>
                        <span><a target="_BLANK" href={t('data_privacy_link_pv')}>{t("Privacy Policy")}</a></span>
                        <span> | </span>
                        <span><a target={"_BLANK"} href={t("legal_notice_link_pv")}>{t("Legal Notice")}</a></span>
                    </span>
                </div>
            : null
        }
    </div>

  }
}

export default compose(
    withTranslation(),
)(VersionLabel);
