// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Tabs} from 'antd';
import VersionableChart from "../VersionableChart";
import VersionableTable from "../VersionableTable";
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import VersionableAttributeList from "../../graphql/queries/VersionableAttributeList.graphql";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import statics from "../../statics";
import VersionalAttributeCreateEntryForm from "../VersionalAttributeCreateEntryForm";

const { TabPane } = Tabs;

type Props = {
    t: Function,
    index: string,
    componentId: string,
    tda?: any,
    onClose: Function,
    versionableAttributeListQuery: query,
    attributes: any
};

type State = {

}

class VersionableAttributesTabs extends Component<Props, State> {


  componentDidMount() {  }

  render() {
    const { t, index, componentId, title, versionableAttributeListQuery, attributes, tda } = this.props;

    return <Tabs>
        { tda ? <TabPane tab={t("New")} key="new">
            <VersionalAttributeCreateEntryForm componentId={componentId} tda={tda}/>
        </TabPane> : undefined }

        <TabPane tab={t("Chart")} key="chart">
            <VersionableChart index={index} query={versionableAttributeListQuery}  />
        </TabPane>
        <TabPane tab={t("Table")} key="table">
            <VersionableTable index={index} title={_.get(tda,'name')} query={versionableAttributeListQuery} id={componentId} attributes={attributes}/>
        </TabPane>

    </Tabs>
  }
}

export default compose(
    withTranslation(),
    withRouter,
    graphql(VersionableAttributeList, {
        name: 'versionableAttributeListQuery',
        options: (props: Props) => {
            return {
                variables: {
                    id: _.get(props, 'componentId'),
                    index: _.get(props, 'index'),
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                        sort: {
                            update: 'DESC'
                        }
                    }
                }
            }
        },
    }),
    waitWhileLoading('versionableAttributeListQuery', ['versionableAttributeList.versionableAttributes']),
)(VersionableAttributesTabs);
