// @flow
import React, {Component} from "react"
import {Button, Dropdown, Menu, Tabs, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import openedServiceTicketList from "./../../graphql/queries/openedServiceTicketList.graphql";
import removeOpenedServiceTicket from "./../../graphql/mutations/removeOpenedServiceTicket.graphql";
import {withRouter} from 'react-router-dom'
import AddOpenedServiceTicketTab from "./AddOpenedServiceTicketTab";
import OneLineLabel from "../OneLineLabel";
import Colors from "../../themes/default/colors";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import './index.css';

const TabPane = Tabs.TabPane;
const {SubMenu} = Menu;

type Props = {
    activeKey?: string,
    getOpenedServiceTicketList: {
        openedServiceTicketList: any,
        loading: boolean,
        error: any,
    },
    removeOpenedServiceTicket: Function,
    addOpenedServiceTicket: Function,
    history: {
        push: Function
    },
    disabledTabs?: Array,
    profileQuery?: any
}

const defaultTabKeys = [];

class OpenedServiceTicketsTabs extends Component<Props> {


    constructor(props) {
        super(props);

        this.state = {
            removedTabs: [],
            removedServiceTickets: []
        }

    }


    _handleCloseAll = (blackListIds: string[] = [], disableNotify) => {

        const {removeOpenedServiceTicket, getOpenedServiceTicketList} = this.props;

        const serviceTicketIds = _.chain(getOpenedServiceTicketList)
            .get('openedServiceTicketList.serviceTickets', [])
            .map(item => item.id)
            .filter(id => blackListIds.indexOf(id) === -1)
            .value();

        return removeOpenedServiceTicket({
            variables: {
                serviceTicketIds
            }
        }).then(() => {
            getOpenedServiceTicketList.refetch();
        })
    };


    onTabClick(tab) {
        if (this.props.onTabChange) this.props.onTabChange(tab)
    }


    render() {
        const {getOpenedServiceTicketList, activeKey, t, disabledTabs} = this.props;
        const {removedServiceTickets, removedTabs} = this.state;

        const serviceTickets = _.chain(getOpenedServiceTicketList).get('openedServiceTicketList.serviceTickets', []).clone().reverse().value();

        let tabs = [...serviceTickets
            .filter(item => !!item && this.state.removedTabs.indexOf(item.id) === -1)
            .map(item => {
                let content = <OneLineLabel style={{maxWidth: '220px'}}>{item.key}: {item.name}</OneLineLabel>;
                if (item.component) {

                    content = <Tooltip
                        title={`${_.get(item, 'component.name')} (SN: ${_.get(item, 'component.serialNo', '-')})`}>{content}</Tooltip>
                }
                return <TabPane tab={content} key={String(item.id)}/>;
            })
        ];

        if (activeKey && tabs.map(item => item.key).indexOf(activeKey) === -1 && disabledTabs.indexOf(activeKey) === -1 && removedServiceTickets.indexOf(activeKey) === -1 && removedTabs.indexOf(activeKey) === -1) {
            tabs = [
                ...tabs,
                <TabPane tab={<AddOpenedServiceTicketTab serviceTicketId={activeKey}/>} key={activeKey}/>
            ]
        }

        const menu = (
            <Menu
                mode="inline"
            >
                <Menu.Item onClick={() => {
                    this._handleCloseAll().then(() => {
                        this.props.history.push(`/service/search`)
                    })
                }}
                           key="1">{t('Close all')}</Menu.Item>
                <Menu.Item onClick={() => this._handleCloseAll([activeKey], true).then(() => {
                    this.onTabClick(activeKey)
                })}
                           key="2">{t('Close all expect current')}</Menu.Item>
                <SubMenu
                    key="serviceTicketMenu"
                    title={t('opened Servicetickets')}
                    popupClassName={'componentSubMenu'}
                >
                    {
                        _.map(serviceTickets, (ticket, index) => <Menu.Item onClick={() => this.onTabClick(ticket.id)}
                                                                            key={index + 3}
                                                                            style={{backgroundColor: activeKey === ticket.id ? Colors.greyLighter : null}}
                                                                            disabled={activeKey === ticket.id}
                        >
                            <div style={{
                                display:' flex',
                                flexDirection: 'row',
                                fontSize: 14
                            }}>
                                <ServiceTicketTypeIcon style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 5}} index={ticket.serviceTicketType.index} size={20}/>
                                <span style={{display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>
                                        {ticket.key}: {ticket.name}
                                    </span>
                            </div>
                            {
                                ticket.component
                                    ? <div>
                                        <OneLineLabel>
                                            <span>{t('Component')}: </span>
                                            <span>{_.get(ticket.component, 'name', '-')}</span>
                                        </OneLineLabel>
                                        <OneLineLabel>
                                            <span>{t('Serial')}: </span>
                                            <span>{_.get(ticket.component, 'serialNo', '-')}</span>
                                        </OneLineLabel>
                                        <OneLineLabel>
                                            <span>{t('Catalog No')}: </span>
                                            <span>{_.get(ticket.component, 'product.catalogNo', '-')}</span>
                                        </OneLineLabel>
                                    </div>
                                    : null
                            }
                        </Menu.Item>)
                    }
                </SubMenu>
            </Menu>
        );

        return <div style={{flex: 1, overflowX: 'hidden'}}>
            <Tabs
                className="tabs-no-bottom-margin tabs-top-padding tabs-hide-more-button"
                hideAdd
                tabBarExtraContent={<div className={'flex-row flex-align-items-center'}>
                    <Dropdown overlay={menu} className={"serviceTicketDropdownMenu"}>
                        <Icon style={{height: '42px', display: 'flex'}} className={"flex-align-items-center ph-5"}
                              type={"menu"}/>
                    </Dropdown>
                </div>}
                onTabClick={(tab) => {
                    this.onTabClick(tab)
                }}
                onEdit={(key) => {
                    const serviceTicketIds = serviceTickets.map(item => item.id);
                    const index = serviceTicketIds.indexOf(key);
                    this._handleCloseAll(serviceTicketIds.filter(id => id !== key)).then(() => {
                        if (index > 0) {
                            this.props.history.push(`/service/${serviceTicketIds[index - 1]}`)
                        } else {
                            this.props.history.push(`/service/search`)
                        }
                    })
                }}
                activeKey={this.props.activeKey}
                type="editable-card"
            >
                {tabs}
            </Tabs>
        </div>;
    }

}


export default compose(
    withTranslation(),
    graphql(openedServiceTicketList, {
        name: 'getOpenedServiceTicketList'
    }),
    waitWhileLoading('getOpenedServiceTicketList', 'openedServiceTicketList.serviceTickets',  { showLoader: false }),
    graphql(removeOpenedServiceTicket, {
        name: 'removeOpenedServiceTicket',
        options: () => {
            return {
                refetchQueries: [{
                    query: openedServiceTicketList
                }]
            }
        }
    }),
    withRouter
)(OpenedServiceTicketsTabs);
