// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import CatalogAttributesListQuery from "./../../graphql/queries/CatalogAttributesList.graphql";
import CreateCustomAttributeMutation from "./../../graphql/mutations/createCatalogAttribute.graphql";
import _ from 'lodash';
import statics from "../../statics";


type Props = {
    t: Function,
    onCreated: () => void,
    createCustomAttributeMutation: Function,
    history: {
        push: Function,
    },
};


class CatalogAttributeCreateForm extends Component<Props> {

    render() {
        const {t, createCustomAttributeMutation, onCreated} = this.props;

        return <div className={'m-20'}>
            <TypeDefinitionAttributesForm
                index={'catalogAttribute'}
                formProps={{
                    useSubmit: true,
                    submitTitle: t('Create'),
                    mutation: createCustomAttributeMutation,
                    onSubmit: (res) => {

                        if (onCreated){
                            onCreated(res)
                        }
                    },
                    translateMutationOptions: (values) => {
                        if(!_.get(values, 'values.name.en')){
                            values.values.name.en = _.get(values, 'values.name.de')
                        }
                        if(!_.get(values, 'values.name.de')){
                            values.values.name.de = _.get(values, 'values.name.en')
                        }

                        return {
                            variables: {
                                input: values.values
                            }
                        }
                    }
                }}
            />
        </div>;
    }
}

export default compose(
    graphql(CreateCustomAttributeMutation, {
        name: 'createCustomAttributeMutation',
        options: () => ({
            refetchQueries: [{
                query: CatalogAttributesListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: statics.defaultPagingSize,
                        sort: {
                            name: 'ASC'
                        }
                    }
                }
            }]
        })
    }),
    withTranslation(),
)(CatalogAttributeCreateForm);
