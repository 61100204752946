// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import _ from "lodash";
import {Alert, Tabs} from "antd";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import ServiceTicketContaminationDeclarationQuery
    from "./../../graphql/queries/serviceTicketContaminationDeclaration.graphql"
import ContaminationDeclarationForm from "./../ContaminationDeclarationForm";
import ContaminationDeclarationUpload from "../ContaminationDeclarationUpload";
import ContaminationDeclarationAutoCreate from "../ContaminationDeclarationAutoCreate";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceTicketContaminationDeclarationFinalized from "../ServiceTicketContaminationDeclarationFinalized";

type Props = {
    serviceTicketId: string,
    serviceTicketContaminationDeclarationQuery: any,
};

type State = {
    createdContaminationDeclaration?: any
}

class ServiceTicketContaminationDeclaration extends Component<Props, State> {

    state = {
        createdContaminationDeclaration: null
    };

    render() {
        const {serviceTicketContaminationDeclarationQuery, t, serviceTicketId} = this.props;

        let deco = _.get(serviceTicketContaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration');

        if (!deco) {
            return <ContaminationDeclarationAutoCreate
                onCreated={(createdContaminationDeclaration) => {
                    serviceTicketContaminationDeclarationQuery.refetch();
                }}
                serviceTicketId={serviceTicketId}/>
        }

        if (deco.finalized){
            return  <div>
                <ServiceTicketContaminationDeclarationFinalized
                    contaminationDeclarationId={_.get(this.props, 'serviceTicketContaminationDeclarationQuery.serviceTicket.contaminationDeclaration.id')}/>
                {!deco.file ?  <ContaminationDeclarationForm serviceTicketId={serviceTicketId} contaminationDeclarationId={deco.id}/> : null }
            </div>
        }

        const tabPanes = [

        ];

        if (!_.get(deco,'file.id')){
            tabPanes.push(<Tabs.TabPane tab={t('Form')} key="form">
                {
                    !!_.get(serviceTicketContaminationDeclarationQuery, 'serviceTicket.contaminationDeclaration.file', false)
                        ? <Alert
                            message={t('A File was uploaded. You can`t edit the form.')}
                            type="success"
                            showIcon
                        />
                        : null
                }
                <ContaminationDeclarationForm serviceTicketId={serviceTicketId} contaminationDeclarationId={deco.id}/>
            </Tabs.TabPane>)

        }

        tabPanes.push( <Tabs.TabPane tab={t('File Upload')} key="upload">
            <div style={{padding: '0 8px 8px'}}>
                <ContaminationDeclarationUpload contaminationDeclarationId={deco.id} serviceTicketId={serviceTicketId}/>
            </div>

        </Tabs.TabPane> )


        const tabs = <Tabs defaultActiveKey={deco && deco.file ? 'upload' : 'form'}>
            {tabPanes}
        </Tabs>;




        return tabs
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceTicketContaminationDeclarationQuery, {
        name: 'serviceTicketContaminationDeclarationQuery',
        options: (props) => ({
            fetchPolicy: 'network-only',
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketContaminationDeclarationQuery', ["serviceTicket.contaminationDeclaration"]),
)(ServiceTicketContaminationDeclaration);

