// @flow
import React, {Component} from "react"

import ComponentFileUploader from "../../components/ComponentFileUploader";
import {Redirect, Route, Switch} from "react-router-dom";
import ComponentFilesTabsRoute from "./componentFilesTabs";
import {Col, Row} from 'antd';
import Panel from "../../components/Panel";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

type Props = {
    match: {
        params: {
            id: string,
        }
    },
    t: Function,
}

class ComponentFilesRoute extends Component<Props> {

    render() {

        const {match} = this.props;

        const componentId = match.params.id;

        return <Row>
            <Col xl={24}>
                <div style={{padding: '0px 10px 0px 20px'}}>
                    <Panel title={this.props.t('Files')}>
                        <ComponentFileUploader componentId={componentId}/>
                        <Switch>
                            <Route from="/infrastructure/:id/files/:index" component={ComponentFilesTabsRoute}/>
                            <Redirect exact from="/infrastructure/:id/files"
                                      to={`/infrastructure/${componentId}/files/default`}/>
                        </Switch>
                    </Panel>
                </div>
            </Col>
        </Row>
    }

}

export default compose(
    withTranslation()
)(ComponentFilesRoute);
