//@flow
import statics from "../statics";

const  axios = require("axios")
const  _ = require('lodash')

const checkHosts = (email, options) => {

    return new Promise((resolve, reject) => {

        let url = _.get(options, 'url', statics.hostsURL);
        const env = _.get(window, '__BE__.env', 'testing');
        if (process.env.NODE_ENV === 'development') return resolve();

        const bodyFormData = new FormData();
        bodyFormData.set('email', email);
        if (env) {
            bodyFormData.set('env', env)
        }

        axios({
            method: 'post',
            headers:{
                "Content-Type": 'multipart/form-data'
            },
            url,
            data: bodyFormData
        }).then((res) => {
            if (res.status === 200) {
                const data = res.data;
                if (data.error) {
                    return reject(new Error(data['error_description'] || data.error));
                }
                resolve(data)
            } else {
                reject(new Error('Server Error'));
            }
        }).catch((err) => {
            reject(err);
        })

    })

};

export default checkHosts;
