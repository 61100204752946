// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import productNameQuery from "../../graphql/queries/productName.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ProductComponentList from "../ProductComponentList";
import _ from 'lodash';
import {withRouter} from "react-router-dom";
import Panel from "../Panel";
import ProductComponentsExport from "../ProductComponentsExport";

type Props = {
    t: Function,
    productId: string,
    history: {
        push: Function
    },
    getProductName: query
};

class ProductComponents extends Component<Props, State> {
    render() {
        const {t, getProductName, match} = this.props;

        return <Panel
            title={`${t('Components of')} ${_.get(getProductName,'product.name')}`}
            topRight={<ProductComponentsExport productId={match.params.id} /> }>
            <ProductComponentList id={match.params.id}
                                  onClick={(component) => this.props.history.push(`/infrastructure/${_.get(component, 'id', 0)}/details`)}
            />
        </Panel>
    }
}

export default compose(
    graphql(productNameQuery, {
        name: 'getProductName',
        options: props => ({
            variables: {
                id: props.productId
            }
        }),
    }),
    withRouter,
    waitWhileLoading('getProductName', ["product.name"]),
    withTranslation(),
)(ProductComponents);
