import React from 'react';
import Bold from "../Utilities/Bold";
import "./index.css"
import useTheme from "../../hooks/useTheme";

interface Props {
    name: string,
    icon?: React.ReactNode,
    highlight?: boolean,
    onClick: () => void
}

const QuickAccessItem = ({name, icon, highlight, onClick}: Props) => {
    const {themeConfig} = useTheme();

    return (
        <div className={"quick-access-item-wrapper"} onClick={onClick}>
            <span style={{color: highlight ? themeConfig["@primary-color"] : undefined}}>
                {icon}
            </span>
            <span
                className={"quick-access-item-text vertical-text"}
                style={{color: highlight ? themeConfig["@primary-color"] : undefined}}
            >
                <Bold>{name.toUpperCase()}</Bold>
            </span>
        </div>
    );
}

export default QuickAccessItem;
