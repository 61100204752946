// @flow
import React, {Component} from 'react';
import {Badge} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import UnreadConversationsQuery from "../../graphql/queries/UnreadConversations.graphql";
import {Link} from "react-router-dom";
import {socketConnect} from "socket.io-react";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

type Props = {
    notificationListQuery: any,
    theme?: any
};


class MessagesIcon extends Component<Props> {

  componentDidMount() {
    const { notificationListQuery, socket } = this.props;

    if (socket){
        socket.on("updateConversation", function(data) {
            notificationListQuery.refetch();
        });
    }

  }

  render() {
    const { notificationListQuery, theme } = this.props;



    return <Link to={"/messages"}>
      <Badge count={_.get(notificationListQuery,'conversationList.total')}>
          {
              theme === 'pv'
                  ? <MaterialCommunityIcon size={20} name={"message-processing"}/>
                  : <Icon type={"MessageOutlined"}/>
          }
      </Badge>
    </Link>
  }
}

export default compose(
    graphql(UnreadConversationsQuery, {
      name: 'notificationListQuery',
      options: () => ({
        fetchPolicy: "cache-and-network"
      }),
    }),
    socketConnect
)(MessagesIcon);
