// @flow
import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import StockSiteList from "../../components/StockSiteList/index"
import StockList from "../../components/StockList"
import _ from "lodash"
import {compose} from "recompose";
import withResources from "../../hoc/withResources";
import {graphql} from '@apollo/client/react/hoc';
import ProfileQuery from "../../graphql/queries/profile.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    match: {
        params: {
            componentId: string
        }
    }
}


class StockManagement extends Component<Props, State> {


    render() {
        const {match, profileQuery} = this.props;

        return <Switch>
            <Route path="/infrastructure/stock/dashboard" component={() => (

                <StockSiteList
                    filter={{
                        clientId: {
                            value: _.get(profileQuery,'profile.client.id')
                        },
                        componentTypeIndex: {
                            value: 'stock'
                        }
                    }}/>
            )}/>
            <Route path="/infrastructure/stock/:componentId" component={(props) => (

                <StockList
                    componentId={_.get(props, 'match.params.componentId')}
                />
            )}/>
            <Redirect exact from="/infrastructure/stock" to="/infrastructure/stock/dashboard"/>
        </Switch>;
    }
}

export default compose(
    withRouter,
    withResources('administrateStocks'),
    graphql(ProfileQuery, {
        name: 'profileQuery',
    }),
    waitWhileLoading('profileQuery')
)(StockManagement);

