import _ from "lodash";
import React, {Component} from 'react'
import IconSplitPanelServiceTicket from "../IconSplitPanelServiceTicket"
import IconSplitPanelServicePlan from "../IconSplitPanelServicePlan"
import IconSplitPanelFiles from "../IconSplitPanelFiles"
import IconSplitPanelNotes from "../IconSplitPanelNotes"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ComponentQuery from "../../graphql/queries/component-componentType.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import IconSplitPanelTCO from "../IconSplitPanelTCO";
import IconSplitPanelSharedClient from "../IconSplitPanelSharedClient";
import withProfile from "../../hoc/withProfile";
import {checkResource} from "../../hoc/withResources";
import IconSplitPanelActiveAlerts from "../IconSplitPanelActiveAlerts";


type Props = {
    componentId: string,
}

class ComponentWidgets extends Component<Props> {

    render() {
        const {componentId, componentQuery, resources} = this.props;

        const product = _.get(componentQuery, 'component.componentType.product', false);
        const clientId = _.get(componentQuery, 'component.client.id');
        const activeAlerts = _.get(componentQuery, 'component.activeAlerts');

        const errorCount = _.get(activeAlerts, 'errors');
        const warningCount = _.get(activeAlerts, 'warnings');
        const informationCount = _.get(activeAlerts, 'information');

        const hasTCO = checkResource("tcoAdministration", resources)

        return <div className="component-widgets-wrapper">
            {/* //TODO: VSM-10917 Zugriffe auf eine Komponente darstellen */}
            {/*<IconSplitPanelSharedClient componentType={_.get(componentQuery, 'component.componentType.index')}*/}
            {/*                            componentId={componentId}*/}
            {/*                            clientId={clientId}/>*/}

            {errorCount ? <IconSplitPanelActiveAlerts componentId={componentId} alertType={'error'} count={errorCount}
                                                      icon={'exclamation-triangle'}/> : undefined}
            {warningCount ?
                <IconSplitPanelActiveAlerts componentId={componentId} alertType={'warning'} count={warningCount}
                                            icon={'exclamation-triangle'}/> : undefined}
            {informationCount ?
                <IconSplitPanelActiveAlerts componentId={componentId} alertType={'information'} count={informationCount}
                                            icon={'info'}/> : undefined}

            {product ? <IconSplitPanelServiceTicket key={'ticket'} componentId={componentId}/> : undefined}
            {product ? <IconSplitPanelServicePlan key={'plan'} componentId={componentId}/> : undefined}
            {hasTCO ? <IconSplitPanelTCO componentId={componentId}/> : undefined}

            <IconSplitPanelFiles componentId={componentId}/>
        </div>

        // <IconSplitPanelTCO componentId={componentId}/>
    }
}

export default compose(
    withTranslation(),
    withProfile(),
    graphql(ComponentQuery, {
        name: 'componentQuery',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentQuery', 'component')
)(ComponentWidgets)
