import React from 'react';
import _ from "lodash";
import {compose} from "redux";
import { graphql } from '@apollo/client/react/hoc';
import ServiceReportNameQuery from "../../graphql/queries/serviceReportName.graphql";
import {withTranslation} from "react-i18next";

const ServiceReportBreadcrumb = ({serviceReportNameQuery, t}) =>
  <span>{_.get(serviceReportNameQuery, 'serviceReport.name', '...')}</span>;

export default compose(
  graphql(ServiceReportNameQuery, {
    name: 'serviceReportNameQuery',
    options: ({match}) => ({
        fetchPolicy: "cache-only",
      variables: {
        serviceReportId: _.get(match, 'params.serviceReportId'),
      }
    })
  }),
)(withTranslation()(ServiceReportBreadcrumb));
