// @flow
import React, {Component} from 'react';
import BorderWrapper from "../Border";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import ServiceTicketQualityIssueQuery from "./../../graphql/queries/ServiceTicketQualityIssue.graphql"
import {Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

type Props = {};

type State = {

}

class QualityIssueWidget extends Component<Props, State> {

  render() {
    const { t, qualityIssue } = this.props;

    if (!qualityIssue){
      return null;
    }

    const colorClass = qualityIssue.status === 'solved' ? "success" : "warning";
    const iconType = qualityIssue.status !== 'solved' ? 'warning' : 'check-circle';

    return <BorderWrapper className={'p-10 ' + colorClass + "-border"} radius={5}>
      <Link className={"text-dark"} to={`/service/${this.props.serviceTicketId}/qualityIssue`}>
        <Tooltip title={(t(qualityIssue.status))}>
          <Icon className={"mr-5 " + colorClass + "-color"} type={iconType}/>
        </Tooltip>

        <span>
        {t('Last updated at {{date}} by {{user}}', {
          user: _.get(qualityIssue, 'updatedBy.name'),
          date: moment(_.get(qualityIssue, 'update')).format('LL HH:mm'),
        })}
      </span>
      </Link>
    </BorderWrapper>
  }
}

export default compose(
    graphql(ServiceTicketQualityIssueQuery,{
      name: 'serviceTicketQualityIssueQuery',
      options: (props) => ({
        fetchPolicy: 'cache-first',
        variables: {
          serviceTicketId: props.serviceTicketId
        }
      })
    }),
    withProps((props) => ({
      qualityIssue: _.get(props, 'serviceTicketQualityIssueQuery.serviceTicket.qualityIssue')
    })),
    withTranslation()
)(QualityIssueWidget) ;
