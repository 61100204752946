// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ServicePlanAndControlQuery from "../../graphql/queries/servicePlanAndControlList.graphql"
import _ from 'lodash';
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServicePlanListItem from "./../ServicePlanListItem";
import ServiceControlListItem from "./../ServiceControlListItem";
import Empty from "./../Empty";
import {withRouter} from "react-router-dom";

type Props = {
    componentId: string,
    componentServicePlansQuery: any,
};


class ComponentServicePlansList extends Component<Props, State> {


    render() {
        const {servicePlanAndControlQuery, componentId, history} = this.props;
        const servicePlansAndControls = _.get(servicePlanAndControlQuery, 'calendarItemList.calendarItems', []);

        return <div>
            {servicePlansAndControls.length ? servicePlansAndControls.map(item => {
                if (item.calendarItemIndex === 'servicePlan') {
                    return <div className={"link"}
                                onClick={() => history.push(`/infrastructure/${componentId}/serviceplans/${item.servicePlan.id}`)}
                                key={item.id}>
                        <ServicePlanListItem item={item.servicePlan}/>
                    </div>
                } else if (item.calendarItemIndex === 'serviceControl') {
                    return <div className={"link"}
                                onClick={() => history.push(`/infrastructure/${componentId}/servicecontrols/${item.serviceControl.id}`)}
                                key={item.id}>
                        <ServiceControlListItem item={item.serviceControl}/>
                    </div>
                }
            }) : <Empty/>}
        </div>;
    }
}

export default compose(
    graphql(ServicePlanAndControlQuery, {
        name: 'servicePlanAndControlQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: {
                        componentId: {
                            value: props.componentId,
                        }
                    }
                }
            }
        }),
    }),
    waitWhileLoading('servicePlanAndControlQuery', ['calendarItemList.calendarItems']),
    withRouter
)(ComponentServicePlansList);
