// @flow
import React, {Component} from 'react';
import {FaPaperclip} from "react-icons/lib/fa/index";

type Props = {
    file: {
        id: string,
        link: string,
        filename: string,
    },
    text?: string
};

class FileLink extends Component<Props, State> {

    render() {
        const {file, text} = this.props;

        return <a target="_blank" rel="noopener noreferrer" href={file.link}><FaPaperclip
            style={{}}/> {text || file.filename}</a>;
    }
}

export default FileLink;
