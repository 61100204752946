import * as React from "react"

function ComponentDefaultImage(props) {
  return (
    <svg
      viewBox="0 0 408 390"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M203.73 0L0 57.369l203.73 57.369 203.731-57.369L203.73 0zM.001 333.458l197.356 55.571V126.19L.001 70.618v262.84zM210.105 126.19v262.839l197.356-55.571V70.618L210.105 126.19z"
        fill="#ced7e4"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default ComponentDefaultImage
