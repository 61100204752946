// @flow
import React, {Component, ReactNode} from 'react';
import styled from 'styled-components';
import Colors from "../../themes/default/colors";
import _ from "lodash";
import WorkflowList from "./list";
import Title from "./title";
import {Badge} from "antd";
import statics from "../../statics";
import {updateCache} from "../../utils/loadMoreApolloCache";
import {compose} from "recompose";
import {graphql} from "@apollo/client/react/hoc";
import ServiceTicketListQuery from "../../graphql/queries/ServiceTicketWorkFlowList.graphql";
import {getVariablesForFilter} from "../ServiceTicketWorkflowBoard/results";

const Container = styled.div`
  margin: 4px 3px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: background-color 0.2s ease;
  background-color:  ${(props) => Colors.greyLighter};
`;

type Props = {|
    id?: any,
    title: string,
    filter?: any,
    selectedTaskIds?: string[],
    toggleSelection: (itemId: string) => void,
    toggleSelectionInGroup: (itemId: string) => void,
    multiSelectTo: (itemId: string) => void,
    renderItem: (item: any) => ReactNode,
    index: number,
    isScrollable?: boolean,
    isCombineEnabled?: boolean,
    useClone?: boolean,
    pagingScrollHeight?: number,
    pagingSize?: number,
    sortKeyPath?: string,
    limitKeyPath?: string,
    startKeyPath?: string,
    itemsKeyPath?: string,
    totalKeyPath?: string,
    uniqueIdentifier?: any,
    loading?: boolean
|};

type State = {
    loadingMore: boolean
}

class Column extends Component<Props, State> {
    static defaultProps = {
        pagingScrollHeight: 50,
        pagingSize: statics.defaultPagingSize,
        sortKeyPath: 'params.sort',
        limitKeyPath: 'params.limit',
        startKeyPath: 'params.start'
    };
    state = {
        loadingMore: false
    };

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this._handleWaypointEnter();
        }
    };

    _handleWaypointEnter = () => {
        const oldData = _.cloneDeep(this.getItemsForColumn(this.props.id));
        if (!oldData) return null;

        const total = _.get(this.props[this.props.queryName], this.props.totalKeyPath);
        const length = oldData.length;

        if (length < total) {
            const variables = {
                ...this.props[this.props.queryName].variables,
            };
            _.set(variables, this.props.limitKeyPath, this.props.pagingSize);
            _.set(variables, this.props.startKeyPath, length);

            this.setState({
                loadingMore: true
            }, () => {
                this.props[this.props.queryName].fetchMore({
                    variables,
                    updateQuery: (prev, next) => {
                        const result = updateCache(oldData, next, this.props.itemsKeyPath, this.props.totalKeyPath, this.props.uniqueIdentifier);
                        return result;
                    },
                }).then(() => this.setState({loadingMore: false}));
            });
        }
    };

    getTotalForColumn(column) {
        return _.get(this.props, `${this.props.queryName}.serviceTicketList.total`)
    };

    getItemsForColumn(column) {
        return _.get(this.props, `${this.props.queryName}.serviceTicketList.serviceTickets`)
    };

    render() {
        const title: string = this.props.title;
        const index: number = this.props.index;
        const items = this.getItemsForColumn(this.props.id);
        const total = this.getTotalForColumn(this.props.id);
        const query = this.props[this.props.queryName];

        return (
            <Container>
                <Header>
                    <Title
                        aria-label={`${title} quote list`}
                    >
                        {title}
                    </Title>
                    {
                        total
                            ? <Badge className={"p-10"} style={{backgroundColor: Colors.highlight}} count={total}/>
                            : null
                    }
                </Header>
                <WorkflowList
                    listId={index}
                    onScroll={this.handleScroll}
                    loading={_.get(query, 'loading', false) || this.props.loading}
                    loadingMore={this.state.loadingMore}
                    selectionCount={_.size(this.props.selectedItemIds)}
                    selectedItemIds={this.props.selectedItemIds}
                    toggleSelection={this.props.toggleSelection}
                    toggleSelectionInGroup={this.props.toggleSelectionInGroup}
                    multiSelectTo={this.props.multiSelectTo}
                    listType="TICKET"
                    items={items}
                    renderItem={this.props.renderItem}
                    internalScroll={this.props.isScrollable}
                    isCombineEnabled={Boolean(this.props.isCombineEnabled)}
                    useClone={Boolean(this.props.useClone)}
                />
            </Container>
        );
    }
}

export default compose(
    graphql(ServiceTicketListQuery, {
        name: 'openServiceTicketList',
        options: (props) => ({
            variables: getVariablesForFilter('open', props.filter)
        }),
    }),
    graphql(ServiceTicketListQuery, {
        name: 'inProgressServiceTicketList',
        options: (props) => ({
            variables: getVariablesForFilter('inProgress', props.filter)
        }),
    }),
    graphql(ServiceTicketListQuery, {
        name: 'doneServiceTicketList',
        options: (props) => ({
            variables: getVariablesForFilter('done', props.filter)
        }),
    })
)(Column);
