// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import ContentHeader from "../../components/Typo/ContentHeader";
import ProductCreate from "./../../components/ProductCreate";
import NavigationContainer from "../../components/NavigationContainer";
import _ from "lodash";

type Props = {
    history: {
        push: Function
    }
}

class AddProductRoute extends Component<Props> {
    render() {
        const {t, history} = this.props;
        return <NavigationContainer scroll navigationBar={<ContentHeader title={t('Add new product')}/>}>
            <div className={"bg-white p-10"}>
                <ProductCreate onSaved={(res) => {
                    const id = _.get(res, 'data.createProduct.id')
                    if (id) history.push(`/catalog/products/${id}`)
                }}/>
            </div>
        </NavigationContainer>;
    }
}

export default withTranslation()(withRouter(AddProductRoute));
