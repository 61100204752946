// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import TypeDefinitionAttributeListQuery from "./../../graphql/queries/typeDefinitionAttributeList.graphql";
import CreateClientUserMutation from "./../../graphql/mutations/createClientUser.graphql";
import type {Query} from "../../types";
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ClientUsersQuery from "../../graphql/queries/ClientUsers.graphql";
import {withTranslation} from "react-i18next";
import {Alert} from "antd";

type Props = {
    t: Function,
    clientId: string,
    typeDefinitionAttributeListQuery: Query,
    createClientUserMutation: Function,
    history: {
        push: Function,
    }
};

class ClientAdminForm extends Component<Props, State> {
    render() {
        const {typeDefinitionAttributeListQuery, createClientUserMutation, clientId, t} = this.props;

        return <div>
            <Alert className={"mv-10"} message={t("You are able to create a new client user Created uses will own the admin role")} type={"info"} />
            <AttributesInlineForm
                attributes={_.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes')}
                mutation={createClientUserMutation}
                useSubmit
                submitTitle={t('Add')}
                translateMutationOptions={({values}) => {

                    return {
                        variables: {
                            clientId: clientId,
                            user: values
                        }
                    }
                }}
            />
        </div>;
    }
}

export default compose(
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props: Props) => ({
            variables: {
                index: 'user',
            }
        }),
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery', 'typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(CreateClientUserMutation, {
        name: 'createClientUserMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ClientUsersQuery,
                variables: {
                    id: props.clientId
                }
            }]
        })
    }),
    withTranslation()
)(ClientAdminForm);
