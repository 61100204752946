// @flow
import React, {Component} from 'react';

type Props = {
  navigationBar?: any,
    scroll?: boolean
};

type State = {

}

class NavigationContainer extends Component<Props, State> {

  render() {

    return <div className={`full-height flex-column${this.props.classNames ? ` ${this.props.classNames}` : ''}`}>
        {this.props.navigationBar}
        <div style={{
            flex: 1,
            overflowX: 'hidden',
            overflow: this.props.scroll ? 'auto' : 'hidden',
            position: 'relative'
        }}>
            {this.props.children}
        </div>
    </div>;
  }
}

export default NavigationContainer;
