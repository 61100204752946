// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import ComponentCUQuery from "./../../graphql/queries/ComponentCU.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Tag} from "antd";
import Colors from "../../themes/default/colors";

type Props = {
    componentId: string,
};

class CULabel extends Component<Props, State> {

    render() {
        const {cu, t, style} = this.props;

        return cu ? <Tag color={Colors.orange} style={style}>{t('CU Contaminated')}</Tag> : null;
    }
}

export default compose(
    withTranslation(),
    graphql(ComponentCUQuery, {
        name: 'componentCUQuery',
        options: (props: Props) => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentCUQuery', 'component'),
    withProps((props) => ({
        cu: _.get(props, 'componentCUQuery.component.cuContaminated'),
    }))
)(CULabel);
