// @flow
import React, {Component} from 'react';
import {Collapse} from "antd";
import _ from 'lodash';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import ServiceCenterDepartmentListQuery from "./../../graphql/queries/serviceCenterDepartmentList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceDepartmentUserTable from "../ServiceDepartmentUserTable";
import Toolbar from "../Toolbar";
import {Link} from "react-router-dom";
import './index.css';

const Panel = Collapse.Panel;


type Props = {
    serviceCenterId: string,
};


class ServiceCenterDepartmentCollapseList extends Component<Props, State> {


    render() {
        const {serviceCenterId, serviceDepartments, t} = this.props;

        return <Collapse bordered={false} className={'ServiceCenterDepartmentCollapse'}>
            {serviceDepartments.map(serviceDepartment => (<Panel
                header={<Toolbar
                    renderLeft={() => serviceDepartment.name}
                    renderRight={() => <Link
                        to={`/admin/servicecenters/${serviceCenterId}/servicedepartments/${serviceDepartment.id}`}>{t('Edit')}</Link>}/>}
                key={serviceDepartment.id}>
                <ServiceDepartmentUserTable serviceDepartmentId={serviceDepartment.id}/>
            </Panel>))}
        </Collapse>
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceCenterDepartmentListQuery, {
        name: 'serviceCenterDepartmentListQuery',
        options: (props: Props) => ({
            variables: {
                serviceCenterId: props.serviceCenterId
            }
        }),
    }),
    waitWhileLoading('serviceCenterDepartmentListQuery'),
    withProps(props => ({
        serviceDepartments: _.get(props, 'serviceCenterDepartmentListQuery.serviceCenter.serviceDepartmentList.serviceDepartments', [])
    }))
)(ServiceCenterDepartmentCollapseList);
