// @flow
import React, {Component} from "react"

import ProgressImage from "./ProgressImage";
import {Spin} from "antd";

type Props = {
    src: string,
    placeholder: string,
    style: ?any,
    width: number,
    height: number,
    frame: ?boolean,
}

class Image extends Component<Props> {


    static defaultProps = {
        placeholder: "./images/default_image_component.png"
    };

    render() {

        const {src, placeholder, style, height, width, frame} = this.props;
        return <ProgressImage height={height} width={width} src={src} placeholder={placeholder}>
            {(src) => {
                const loading=false;
                return <div className={`relative ${frame ? 'frame' : 'frameless'}`}>
                    <img height={height} width={width} style={style} src={src} alt={'logo'}/>
                    {loading ? <div className="mask bg-translucent-white">
                        <Spin style={{
                            top: '50%',
                            position: 'absolute',
                            left: '50%',
                            marginLeft: -7,
                            marginTop: -5
                        }} size={'small'}/>
                    </div> : null}
                </div>;
            }}
        </ProgressImage>;
    }

}

export default Image;
