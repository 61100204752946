// @flow
import React, {Component} from 'react';
import {Table} from "antd";
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";

import ComponentTypeDefaultServiceTaskListQuery
    from "../../graphql/queries/componentTypeDefaultServiceTaskList.graphql";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import "./styles.css";

type Props = {
    index: string,
    componentTypeDefaultServiceTaskListQuery: query,
    t: Function,
};

class ComponentTypeDefaultServiceTasks extends Component<Props> {

    render() {
        const {index, componentTypeDefaultServiceTaskListQuery, t} = this.props;

        const data = _.get(componentTypeDefaultServiceTaskListQuery, 'defaultServiceTaskList.defaultServiceTasks');

        const columns = [{
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, item) => <Link
                to={`/catalog/componentTypes/detail/${index}/defaultServiceTasks/${item.id}`}>{text}</Link>,
        }, {
            title: t('Service cycle'),
            dataIndex: 'recommendedServiceCycle',
            key: 'recommendedServiceCycle',
        }, {
            title: t('Service information'),
            dataIndex: 'description',
            key: 'description',
        }];

        return <div style={{height: 500, overflow: 'scroll'}}><Table dataSource={data} rowKey={record => record.id} columns={columns} pagination={false}/></div>;
    }
}

export default compose(
    graphql(ComponentTypeDefaultServiceTaskListQuery, {
        name: 'componentTypeDefaultServiceTaskListQuery',
        options: (props: Props) => ({
            fetchPolicy: 'network-only',
            variables: {
                "componentTypeIndex": props.index
            }
        }),
    }),
    waitWhileLoading('componentTypeDefaultServiceTaskListQuery', 'defaultServiceTaskList.defaultServiceTasks'),
)(withTranslation()(ComponentTypeDefaultServiceTasks))
