// @flow
import React, { Component } from 'react';
import _ from "lodash";
import {Avatar, Modal} from "antd";

type Props = {
    thumbnail?: string,
    src?: string,
};

type State = {

}

class AvatarLightBox extends Component<Props, State> {

    state = {
        visible: false
    }

  render() {
    const { shape, icon, size, src, thumbnail } = this.props;

    const avatar =  <Avatar
        shape={shape}
        icon={icon}
        size={size}
        src={thumbnail || src}/>;

    if (!src){
        return avatar;
    }

    return <div>
        <Modal
            visible={this.state.visible}
            footer={null}
            onCancel={() => this.setState({visible: false})}
        >
            <img src={src} style={{width: "100%"}}/>
        </Modal>
        <div className={"pointer"} onClick={() => this.setState({visible: true})}>

            {avatar}
        </div>
    </div>;
  }
}

export default AvatarLightBox;
