// @flow
import React, {Component} from 'react';
import ComponentPicker from "./ComponentPicker";
import {EditableWrapper} from "@dreebit-registry/form-attributes-vsm";
import _ from "lodash";

type Props = {
  attribute: any,
  form: any,
  style: any,
  translations: any
};

type State = {

}

class AttributeComponent extends Component<Props, State> {

  render() {
    const { form, attribute, style, translations } = this.props;

    return <EditableWrapper
      form={form}
      attribute={attribute}
      style={style}
    >
      { ({value, onChange}) => {
        return <ComponentPicker
            showOnlyComponent={_.get(attribute, 'showOnlyComponent')}
            {..._.get(attribute, 'inputTypeOptions', {})}
            value={value}
            onChange={onChange}
        />
      }}
    </EditableWrapper>;
  }
}

export default AttributeComponent;
