import React, {Component} from 'react';
import {message, Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ContentHeader from "../../../../components/Typo/ContentHeader";
import _ from "lodash";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import AttributeCategoryQuery from '../../../../graphql/queries/attributeCategory.graphql';
import AttributeCategoryListQuery from '../../../../graphql/queries/attributeCategoryList.graphql';
import UpdateAttributeCategoryMutation from '../../../../graphql/mutations/updateAttributeCategory.graphql';
import DeleteAttributeCategoryMutation from '../../../../graphql/mutations/deleteAttributeCategory.graphql';
import waitWhileLoading from "../../../../hoc/waitWhileLoading";
import AttributesInlineForm from "../../../../components/Form/attributes-inline-form";
import TypeDefinitionAttributeListQuery from "../../../../graphql/queries/typeDefinitionAttributeList.graphql";
import {withRouter} from "react-router-dom";
import Panel from "../../../../components/Panel";

type Props = {
    attributeCategoryQuery: query,
    attributeCategoryListQuery: query,
    typeDefinitionAttributeListQuery: query,
    updateAttributeCategoryMutation: Function,
    deleteAttributeCategoryMutation: Function,
    t: Function,
    onUpdateAttributeCategory: () => void,
    handleDeleteRedirect: () => void,
    id: string,
    categoryType: string,
    // index: string,

};

class CatalogCategoriesDetailRoute extends Component<Props>{

    handleDelete = () => {
        const { deleteAttributeCategoryMutation, handleDeleteRedirect, match, t} = this.props;

        deleteAttributeCategoryMutation({
            variables: {
                id: this.props.id || _.get(match, 'params.id')
            }
        }).then(
            handleDeleteRedirect(),
            message.success(t('Removed'))
        )
    };

render(){

    const {attributeCategoryQuery,typeDefinitionAttributeListQuery,onUpdateAttributeCategory, updateAttributeCategoryMutation, match, t} = this.props;

    const attributeCategories = _.get(attributeCategoryQuery, 'attributeCategory');
    const attributes = _.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes');
    const id = id || _.get(attributeCategories, 'id');

        return (
            <div>
                <ContentHeader
                    // hideBreadcrumb
                    title={_.get(attributeCategories, 'name', 'Attribute Category Name')}
                    tools={<Popconfirm
                        title={t('Please confirm to delete this item')}
                        icon={<Icon type="delete" style={{color: 'red'}}/>}
                        onConfirm={() => this.handleDelete()}
                        okText={t('Confirm')}
                        cancelText={t('Cancel')}
                        placement="bottomRight"
                    >
                        <Button icon="delete" type="danger">{t('Remove')}</Button>
                    </Popconfirm>}
                />
                <div className={'m-20'}>
                    <Panel>
                    <AttributesInlineForm
                        attributes={attributes}
                        t={t}
                        useSubmit={true}
                        onSubmit={() => {
                            onUpdateAttributeCategory(id);
                        }}
                        mutation={updateAttributeCategoryMutation}
                        translateMutationOptions={({values}) => ({
                            variables: {
                                id: id || _.get(match, 'params.id'),
                                input: values
                            }
                        })}
                    />
                    </Panel>
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter,
    graphql(AttributeCategoryQuery, {
        name: 'attributeCategoryQuery',
        options: (props) => ({
            fetchPolicy: "cache-and-network",
            variables: {
                id: props.id || _.get(props.match, 'params.id'),
            }
        })
    }),
    graphql(UpdateAttributeCategoryMutation, {
        name: "updateAttributeCategoryMutation",
        options: (props: Props) => ({
            refetchQueries: [{
                query: AttributeCategoryListQuery,
                variables: {
                    params: {
                        filter: {
                            categoryType: {
                                value: props.categoryType || _.get(props, 'categoryType')
                            }
                        }
                    }
                }
            }]
        }),
    }),
    graphql(DeleteAttributeCategoryMutation, {
        name: "deleteAttributeCategoryMutation",
        options: (props: Props) => ({
            refetchQueries: [{
                query: AttributeCategoryListQuery,
                variables: {
                    params: {
                        filter: {
                            categoryType: {
                                value: props.categoryType || _.get(props, 'categoryType')
                            }
                        }
                    }
                }
            }]
        }),
    }),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: () => ({
            variables: {
                index: "attributeCategory"
            }
        }),
    }),
    waitWhileLoading('attributeCategoryQuery', ["attributeCategory"]),
    withTranslation(),
)(CatalogCategoriesDetailRoute);
