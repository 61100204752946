// @flow
import React, {Component} from 'react';
import {Cell, Legend, Pie, PieChart} from "recharts";
import _ from 'lodash';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ServiceTicketListTotalQuery from '../../graphql/queries/serviceTicketListTotal.graphql'
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
  width: number,
  height: number
};

type State = {

}

class ServiceTicketsPerYearProportional extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { width, height, repairTotalQuery, reconTotalQuery, loanTotalQuery, maintenanceTotalQuery } = this.props;

    const repairTotal = _.get(repairTotalQuery, 'serviceTicketList.total');
    const reconTotal = _.get(reconTotalQuery, 'serviceTicketList.total');
    const loanTotal = _.get(loanTotalQuery, 'serviceTicketList.total');
    const maintenanceTotal = _.get(maintenanceTotalQuery, 'serviceTicketList.total');

    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const data = [
      {
        name: 'Reparaturen',
        total: repairTotal,
        color: '#8394ae'
      },
      {
        name: 'Austausche',
        total: reconTotal,
        color: '#7FB685'
      },{
        name: 'Leihen',
        total: loanTotal,
        color: '#FFBB28'
      },{
        name: 'Wartungen',
        total: maintenanceTotal,
        color: '#ee8126'
      }
    ]

    let chartWidth = (width * window.innerWidth * 0.2) - 40;
    let chartHeight = (height * window.innerHeight * 0.33) - 40;
    let radius = (chartHeight / 2) - 60;

    return <div>
      <PieChart width={chartWidth} height={chartHeight} margin={{top: 20, right: 20, bottom: 20, left: 20}}>
        <Legend/>
        <Pie data={data} dataKey="total" nameKey="name" cx="50%" cy="50%" outerRadius={radius} fill="#8884d8" label>
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
          }
        </Pie>
      </PieChart>
    </div>;
  }
}

export default compose(
  graphql(ServiceTicketListTotalQuery, {
    name: 'repairTotalQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair']},
            clientId: {operator: 'nin', value: '602'},
            update: {operator: 'range', range: {
                from: `${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}`,
                to: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
              }}
          }
        }
      }
    }),
  }),
  waitWhileLoading('repairTotalQuery', 'serviceTicketList'),
  graphql(ServiceTicketListTotalQuery, {
    name: 'reconTotalQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['recon']},
            clientId: {operator: 'nin', value: '602'},
            update: {operator: 'range', range: {
                from: `${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}`,
                to: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
              }}
          }
        }
      }
    }),
  }),
  waitWhileLoading('reconTotalQuery', 'serviceTicketList'),
  graphql(ServiceTicketListTotalQuery, {
    name: 'loanTotalQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['loan']},
            clientId: {operator: 'nin', value: '602'},
            update: {operator: 'range', range: {
                from: `${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}`,
                to: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
              }}
          }
        }
      }
    }),
  }),
  waitWhileLoading('loanTotalQuery', 'serviceTicketList'),
  graphql(ServiceTicketListTotalQuery, {
    name: 'maintenanceTotalQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['maintenance']},
            clientId: {operator: 'nin', value: '602'},
            update: {operator: 'range', range: {
                from: `${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}`,
                to: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
              }}
          }
        }
      }
    }),
  }),
  waitWhileLoading('maintenanceTotalQuery', 'serviceTicketList.total'),
)(ServiceTicketsPerYearProportional);
