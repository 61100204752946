import React, {useEffect, useState} from "react";
import useAuthUUID from "../../hooks/useAuthUUID";
import {Result, Progress, Descriptions, Spin} from "antd";
import {useDispatch} from "react-redux";
import {login, userUUID} from "../../actions/auth";
import {useTranslation} from "react-i18next";

interface Props {

}


const AuthTokenLookup = ({}: Props) => {

    const timeout = 5000;
    const animation = 100;
    const uuid = useAuthUUID();

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [status, setStatus] = useState();
    const [fetchedToken, setFetchedToken] = useState();
    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!uuid || status === "already delivered"){
            dispatch(userUUID())
        }
    },[status])

    useEffect(() => {
        if (!loading){
            setTimeout(() => {
                const tmp = counter + animation;
                setCounter(tmp > timeout ? 0 : tmp)
            },animation)
        }

    },[counter, loading])

    useEffect(() => {
        if (counter === 0 && uuid){
            const data = new FormData();
            data.append("uuid", uuid);
            data.append("name", "VSM Web Workflow" ||  window.navigator.userAgent);

            setLoading(true)
            fetch(`/auth/lookup` , {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
                },
                body: data
            })
                .then((response) => {
                    if (response.ok){
                        return response.json()
                    }
                    throw new Error("Lookup was not successful")
                })
                .then((json) => {
                    if (json.accessToken){
                        setFetchedToken(json.accessToken);
                        dispatch(login({},json.accessToken))
                    }
                    setStatus(json.message)
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    },1000)
                })
        }
    },[counter, uuid])

    if (fetchedToken){
        return <div>
            <Result
                status="success"
                title={t("Successfully authenticated")}
                subTitle={t("Please wait, you will be redirected")}
            />
        </div>
    }
    return <div>
        <Progress status={loading ? 'active' : "normal"} percent={loading ? 100 : counter / timeout * 100} showInfo={false}/>
        <div className={"mt-20"}>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="UUID">
                    {
                        uuid ? uuid : <Spin />
                    }
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                    {
                        status ? status : <Spin />
                    }
                </Descriptions.Item>
            </Descriptions>
        </div>
    </div>;
}

export default AuthTokenLookup;
