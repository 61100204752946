// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import IconSplitPanelNotesQuery from "../../graphql/queries/iconSplitPanelNotes.graphql"
import _ from "lodash";
import SplitPanelList from "../SplitPanelList";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    t: Function,
    componentId: string,
    iconSplitPanelNotesQuery: any
}


class IconSplitPanelNotes extends Component<Props> {

    render() {

        const {t, iconSplitPanelNotesQuery, componentId} = this.props;
        const totalNotes = _.get(iconSplitPanelNotesQuery, 'component.logList.total');

        return <SplitPanelList
            items={[
                {
                    link: `/infrastructure/${componentId}/logs?logType=componentNote`,
                    largeTitle: totalNotes,
                    title: t('Note', {count: totalNotes}),
                    description: t('Click to edit component notes'),
                    icon: "sticky-note",
                }
            ]}
        />
    }
}


export default compose(
    withTranslation(),
    graphql(IconSplitPanelNotesQuery, {
        name: 'iconSplitPanelNotesQuery',
        options: (props: Props) => ({
            variables: {
                componentId: props.componentId
            }
        })
    }),
    waitWhileLoading('iconSplitPanelNotesQuery', 'component', {
        loaderSize: 'small'
    })
)(IconSplitPanelNotes);


