// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import ServiceDepartmentDashboardQuery from "./../../graphql/queries/ServiceDepartmentDashboard.graphql";
import ContentHeader from "../Typo/ContentHeader";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Col, Popconfirm, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ContentWrapper from "../ContentWrapper";
import Panel from "../Panel";
import ServiceDepartmentForm from "./../ServiceDepartmentForm";
import ServiceDepartmentUserTable from "../ServiceDepartmentUserTable";
import Sizes from "../../themes/default/sizes";
import ServiceDepartmentDeleteMutation from "../../graphql/mutations/ServiceDepartmentDeleteMutation.graphql";
import ServiceCenterDashboardQuery from "../../graphql/queries/ServiceCenterDashboard.graphql";


type Props = {
    serviceDepartmentId: string,
    serviceCenterId: string,
    serviceDepartmentDeleteMutation: Function,
    onDelete: Function
};


class ServiceDepartmentDashboard extends Component<Props, State> {

    _handleDelete = () => {
        const {serviceDepartmentDeleteMutation, serviceDepartmentId, history, onDelete} = this.props;

        serviceDepartmentDeleteMutation({variables: {id: serviceDepartmentId}}).then(() => {
            onDelete()
        });
    };


    render() {
        const {serviceDepartment, t, serviceDepartmentId} = this.props;

        return <div>
            <ContentHeader title={serviceDepartment.name}
                           tools={<Popconfirm placement="bottomRight" title={t("Are you sure delete this user?")}
                                              onConfirm={this._handleDelete} okText={t("Yes")} cancelText={t("No")}
                                              icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                               <Button type="danger" icon="delete">{t('Delete')}</Button>
                           </Popconfirm>}/>
            <ContentWrapper>
                <Row gutter={Sizes.grid}>
                    <Col lg={12}>
                        <Panel title={t('Details')}>
                            <ServiceDepartmentForm serviceDepartmentId={serviceDepartmentId}/>
                        </Panel>
                    </Col>
                    <Col lg={12}>
                        <Panel title={t('Users')}>
                            <ServiceDepartmentUserTable serviceDepartmentId={serviceDepartmentId}/>
                        </Panel>
                    </Col>
                </Row>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceDepartmentDeleteMutation, {
        name: 'serviceDepartmentDeleteMutation',
        options: props => ({
            refetchQueries: [{
                query: ServiceCenterDashboardQuery,
                variables: {
                    id: props.serviceCenterId
                }
            }]
        }),
    }),
    graphql(ServiceDepartmentDashboardQuery, {
        name: 'serviceDepartmentDashboardQuery',
        options: props => ({
            variables: {
                id: props.serviceDepartmentId
            }
        }),
    }),
    waitWhileLoading('serviceDepartmentDashboardQuery'),
    withProps((props) => ({
        serviceDepartment: _.get(props, 'serviceDepartmentDashboardQuery.serviceDepartment')
    })),
)(ServiceDepartmentDashboard);
