import _ from 'lodash';

const halfColProps = {
    md: 12
};
const fullColProps = {
    md: 24
};

function capitalizeFirstLetter(string) {
    if (_.isString(string)) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
}

export const getAttributeGroups = (attributes, t) => {

    let groups = attributes.reduce((acc, attribute) => {
        if (Array.isArray(attribute.categories)) return [...acc, ...attribute.categories];
        return acc;
    }, []);

    groups = _.uniq(groups);

    return groups.map(item => ({
        name: t ? t(capitalizeFirstLetter(item)) : capitalizeFirstLetter(item),
        categoryIndex: item,
        index: item,
    }));
};

export const getServiceReportAttributeGroups = (attributes, categories) => {
  let groups = attributes.reduce((acc, attribute) => {
    if (Array.isArray(attribute.categories)) return [...acc, ...attribute.categories];
    return acc;
  }, []);

  groups = _.uniq(groups);

    const defaultCategory = _.find(categories, {index: 'serviceReportDefaultCategory'}) || _.first(categories);
  groups = groups.map(item => ({
      index: item,
      colProps: fullColProps,
      name: _.get(categories.find(o => o.index === item), 'name', _.get(defaultCategory, 'name', 'Default')),
      sortId: _.get(categories.find(o => o.index === item), 'sortId', 0),
  }));

  return _.orderBy(groups, ['sortId'], ['desc'])
}


