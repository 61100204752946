// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {Badge, Popover, Tooltip} from 'antd';
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import NotificationList from "../NotificationList";
import MarkAllNotificationsMutation from "../../graphql/mutations/markAllNotifications.graphql";
import _ from 'lodash';
import {socketConnect} from "socket.io-react";
import MutationButton from "../MutationButton";
import NotificationListQuery from "../../graphql/queries/notificationList.graphql";
import withTheme from "../../hoc/withTheme";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

type Props = {
    t: Function,
    notificationListQuery: query,
    theme?: string
};

type State = {
    visible: boolean
}

class NotificationPopover extends Component<Props, State> {
    state = {
        visible: false
    };

    componentDidMount() {
        const {notificationListQuery, socket} = this.props;

        if (socket){
            socket.on("updateNotifications", function(data) {
                notificationListQuery.refetch();
            })
        }

    }

    render() {
        const {t, notificationListQuery, readNotificationsMutation, themeConfig, theme} = this.props;
        const {visible} = this.state;

        const count = _.get(notificationListQuery, 'notificationList.total');
        const ids = _.get(notificationListQuery, 'notificationList.notifications', []).map((notification) => notification.id);
        return <Popover title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span className={"pt-5"}>
                {t('Notifications')}
            </span>
            <div>
                {count > 0 ? <span>
                <Tooltip title={t("Mark all notifications as read")}>
                    <MutationButton
                        mutation={readNotificationsMutation}
                        data={notificationListQuery}
                        buttonProps={{
                            icon: "delete"
                        }}
                        onClick={() => this.setState({visible: false})}
                    />
                </Tooltip>
                </span> : null}

                <Link className={"ml-5"} to={'/profile/notificationsettings'}>
                    <Button
                        icon={"setting"}
                        onClick={() => this.setState({visible: false})}
                    >
                    </Button>
                </Link>
            </div>
        </div>}
                        visible={visible}
                        onVisibleChange={visible => this.setState({visible})}
                        content={
                            <div className={"overflow-scroll"} style={{width: 500, maxHeight: 350}}>
                                <NotificationList onClick={() => this.setState({visible: false})}/>
                            </div>
                        }
                        overlayClassName={"no-padding-popover"}
                        trigger="click"
                        placement="bottomRight">
            {
                <div onClick={() => this.setState({visible: true})}>
                    <Badge style={{
                        backgroundColor: themeConfig["@notification-bubble-background-color"],
                        color: themeConfig["@notification-bubble-text-color"]
                    }} count={_.get(notificationListQuery, 'notificationList.total')}>
                        {
                            theme === 'pv'
                                ? <MaterialCommunityIcon size={20} name={"bell"}/>
                                : <Icon type="notification"/>
                        }
                    </Badge>
                </div>
            }
        </Popover>;
    }
}

export default compose(
    graphql(NotificationListQuery, {
        name: 'notificationListQuery',
        options: () => ({
            fetchPolicy: "cache-and-network",
            variables: {
                params: {
                    filter: {
                        read: {
                            value: false
                        }
                    },
                    start: 0,
                    limit: 6
                }
            }
        }),
    }),
    graphql(MarkAllNotificationsMutation, {
        name: 'readNotificationsMutation',
        options: props => ({
            refetchQueries: [
                {
                    query: NotificationListQuery,
                    variables: {
                        params: {
                            filter: {
                                read: {
                                    value: false
                                }
                            },
                            start: 0,
                            limit: 6
                        }
                    }
                }
            ]
        })
    }),
    withTranslation(),
    socketConnect,
    withTheme(),
)(NotificationPopover);
