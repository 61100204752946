// @flow
import React, {Component} from 'react';
import FillQueryTable from "../QueryTable/fill";
import ServiceContractListQuery from "../../graphql/queries/serviceContractList.graphql";
import type {Query} from "../../types";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import _ from "lodash";
import {withRouter} from "react-router-dom";


type Props = {
  t: Function,
  serviceContractListQuery: Query,
  history: {
    push: Function,
  },
}

class ServiceContractPartnersList extends Component<Props, State> {
  componentDidMount() {}

  render() {

    const { t, serviceContractListQuery, history } = this.props;

    return <div className={"full-size"}>
      <FillQueryTable
        className={"link-table"}
        size={'middle'}
        query={serviceContractListQuery}
        itemsKeyPath={'serviceContractList.serviceContracts'}
        totalKeyPath={'serviceContractList.total'}
        rowKey={"id"}
        onRow={(item) => {
          return {
          onClick: () => history.push(`/contractpartners/${_.get(item, 'id')}`)
        };
        }}
        columns={[
          {
            dataIndex: 'client.name',
            title: t('Name'),
            key: 'client.name',
          },
          {
            dataIndex: 'serviceContractProductList.total',
            title: t('Count'),
            key: 'serviceContractProductList.total',
            width: '20%',
          }
        ]}
      />
    </div>;
  }
}

export default compose(
  withTranslation(),
  withRouter,
  graphql(ServiceContractListQuery, {
    name: 'serviceContractListQuery',
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        params: {
          sort: {
            clientName: 'ASC'
          }
        }
      }
    }),
  }),
)(ServiceContractPartnersList)
