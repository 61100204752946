// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";

import CreateRole from "./../../graphql/mutations/createRole.graphql";
import RoleTypeDefinitionAttributeList from "./../../graphql/queries/roleTypeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributesInlineForm from "./../Form/attributes-inline-form";
import RoleListQuery from "../../graphql/queries/roleList.graphql";

type Props = {
    createRoleMutation: Function,
    roleTypeDefinitionAttributeList: query,
    onSaved: Function
}

class RoleCreateComponent extends Component<Props> {

    render() {
        const {roleTypeDefinitionAttributeList, onSaved, createRoleMutation} = this.props;

        return <AttributesInlineForm
            useSubmit
            onSubmit={onSaved}
            attributes={_.get(roleTypeDefinitionAttributeList, "typeDefinitionAttributeList.typeDefinitionAttributes", [])}
            mutation={createRoleMutation}
            translateMutationOptions={({values}) => ({
                variables: {
                    role: {
                        name: values.name,
                        description: values.description
                    }
                }
            })}
        />;
    }
}

export default compose(
    graphql(RoleTypeDefinitionAttributeList, {
        name: 'roleTypeDefinitionAttributeList',
    }),
    waitWhileLoading('roleTypeDefinitionAttributeList', ['typeDefinitionAttributeList.typeDefinitionAttributes']),
    graphql(CreateRole, {
        name: 'createRoleMutation',
        options: () => ({
            refetchQueries: [{
                query: RoleListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    })
)(withRouter(RoleCreateComponent));
