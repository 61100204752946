
const Themes =  {

    "vsm": {
        '@service-ticket-status-width': 70,
        '@primary-color': '#ee8126',
        "@navigation-bg": "#f5f8fc",
        "@navigation-border-color": "#e37626",
        "@navigation-search-border-color": "#9c9c9c",
        "@notification-bubble-background-color": '#ee8126',
    },

    "busch": {
        '@primary-color': '#fd4006',
        "@navigation-border-color": "#fd4006",
        '@service-ticket-status-width': 70,
        "@navigation-bg": "#f5f8fc",
        "@navigation-search-border-color": "#9c9c9c",
        "@notification-bubble-background-color": '#fd4006',
    },

    "xenon": {
        '@service-ticket-status-width': 90,
        '@primary-color': '#f3aa08',
        "@navigation-bg": "#ffb208",
        "@navigation-border-color": "#333333",
        "@navigation-search-border-color": "#AC8206",
    },

    "pv": {
        '@service-ticket-status-width': 70,
        '@primary-color': '#cc0033',
        "@navigation-bg": "#cc0033",
        "@navigation-title-color": "#ffffff",
        "@navigation-border-color": "#900024",
        "@navigation-search-border-color": "#ffa0b1",
        "@navigation-search-text-color": "#ffffff",
        "@notification-bubble-background-color": '#ffffff',
        "@notification-bubble-text-color": '#cc0033',
    },

    "pvuk": {
        '@service-ticket-status-width': 70,
        '@primary-color': '#cc0033',
        "@navigation-bg": "#cc0033",
        "@navigation-title-color": "#ffffff",
        "@navigation-border-color": "#900024",
        "@navigation-search-border-color": "#ffa0b1",
        "@navigation-search-text-color": "#ffffff",
        "@notification-bubble-background-color": '#ffffff',
        "@notification-bubble-text-color": '#cc0033',
    }
}

export default Themes
