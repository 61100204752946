// @flow
import React, {Component} from 'react';
import MutationButton from "../MutationButton";
import {graphql} from '@apollo/client/react/hoc';
import UpdateAGBMutation from "../../graphql/mutations/updateAGB.graphql";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

type Props = {
    width: number,
    onAccept?: () => void
};

type State = {

}

class AGB extends Component<Props, State> {

  render() {
    const { updateProfileMutation, t, width, onAccept} = this.props;


    return <div>
        <div>
            <iframe style={{border: "none"}} src="/business-terms?terminal=1" width={width} height="500"/>
        </div>
        <div className={"text-right p-15"}>
            <MutationButton buttonProps={{type: 'primary', icon: 'check'}} onDone={onAccept} mutation={ updateProfileMutation } >
                {t("Accept")}
            </MutationButton>
        </div>
    </div>;
  }
}


export default compose(
    graphql(UpdateAGBMutation, {
        name: 'updateProfileMutation',
    }),
    withTranslation()
)(AGB);
