// @flow
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {useApolloClient} from "@apollo/client";
import _ from "lodash";

type Props = {
    query: any,
    downloadLinkPath: string,
    variables?: any,
    onDone?: Function,
    onError?: Function
}

const Export = ({query, variables, onDone, onError, downloadLinkPath}: Props) => {
    const {t} = useTranslation();
    const client = useApolloClient();

    const [loading, setLoading] = useState(false);

    const handleOnClick = () => {
        setLoading(true);

        client.query({
            query,
            variables,
            fetchPolicy: 'network-only'
        })
            .then(({data})=> {
                const download = _.get(data, downloadLinkPath);
                if(download) window.open(download, '_blank');

                setLoading(false);
                if(onDone) onDone(data);
            })
            .catch((e) => {
                setLoading(false);
                if(onError) onError(e);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return <Tooltip title={t('Export')}>
        <Button
            icon={"download"}
            loading={loading}
            onClick={handleOnClick}
            className="mr-10"
        />
    </Tooltip>
};

export default Export
