// @flow
import React, {Component} from 'react';
import SubHeaderWithTools from "./index";
import _ from "lodash";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import ServiceTicketFinalTestQuery from "../../graphql/queries/serviceTicketFinalTest.graphql";
import FinalizeFinalTestMutation from "../../graphql/mutations/finalizeFinalTest.graphql";
import {withTranslation} from "react-i18next";
import {message} from "antd";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import FinalTestFormQuery from "../../graphql/queries/FinalTestForm.graphql";
import {withProps} from "recompose";

type Props = {
    serviceTicketId: string,
    t: Function,
    title?: String
}

class FinalTestSubHeader extends Component<Props> {


    _handleFinalize = () => {

        const {t, finalizeFinalTestMutation, finalTestId} = this.props;

        this.setState({
            loading: true,
        });
        finalizeFinalTestMutation({
            variables: {
                finalTestId,
            }
        })
            .then(({data}) => {
                message.success(t('final test finalized'));

                /*
                https://dreebit.atlassian.net/browse/VSM-10653
                const printURL = _.get(data, 'finalizeFinalTest.print.sheet.link');
                if (printURL) {
                    setTimeout(() => {
                        window.open(printURL, "_blank");
                    }, 1000)
                }

                 */
            })
            .catch((err) => {
                console.error(err);
                message.error(t('Error'));
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    };


    render() {
        const {t, serviceTicketFinalTestQuery, title, finalTestId} = this.props;
        const printLink = _.get(serviceTicketFinalTestQuery, 'serviceTicket.finalTest.print.sheet.link');
        const finalized = _.get(serviceTicketFinalTestQuery, 'serviceTicket.finalTest.finalized', false);

        return <SubHeaderWithTools title={title ? title : t('Final test')}
                                   finalized={finalized}
                                   onFinalize={finalTestId && !finalized ? this._handleFinalize : undefined}
                                   printLink={printLink}/>;
    }
}

export default compose(
    graphql(ServiceTicketFinalTestQuery, {
        name: 'serviceTicketFinalTestQuery',
        options: (props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    waitWhileLoading('serviceTicketFinalTestQuery', 'serviceTicket.finalTest'),
    withProps((props) => {
        const finalTestId = _.get(props, 'serviceTicketFinalTestQuery.serviceTicket.finalTest.id');
        return {
            finalTestId
        }
    }),
    graphql(FinalizeFinalTestMutation, {
        name: 'finalizeFinalTestMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: serviceTicketDashboard,
                variables: {
                    id: props.serviceTicketId
                }
            }, {
                query: ServiceTicketFinalTestQuery,
                variables: {
                    id: props.serviceTicketId
                }
            }, {
                query: FinalTestFormQuery,
                variables: {
                    id: props.finalTestId
                }
            }]
        }),
    })
)(withTranslation()(FinalTestSubHeader));
