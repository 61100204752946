// @flow
import React, {Component} from "react"
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Modal, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components";

import MatchBookmarks from "../MatchBookmarks";

type Props = {
    showModal: boolean,
    onClose: Function,
    data: any,
    t: Function,
}

class MatchBookmarksModal extends Component<Props, State> {

    render() {
        const {showModal, onClose, data, t} = this.props;

        return <div>
            <Modal
                width={700}
                footer={null}
                title={<span>{t('match bookmark')} <Tooltip placement="topLeft"
                                                            title={t('here you can match one or more bookmarks with one API-Token.')}>{
                    <Icon type="info-circle"/>}</Tooltip></span>}
                visible={showModal}
                onOk={onClose}
                onCancel={onClose}
            >
                {<MatchBookmarks onSubmit={onClose} id={data.id} dataJson={JSON.parse(data.data)}/>}
            </Modal>
        </div>
    }
}

export default compose(
    withTranslation(),
)(MatchBookmarksModal);
