// @flow
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import withResources from "../../hoc/withResources";
import _ from "lodash";
import Error from "../../components/Error";
import ServiceActionTypeDashboard from "../../components/ServiceActionTypeDashboard";

type Props = {
    t: Function,
};

class ServiceActionTypeDetailRoute extends Component<Props, State> {


    render() {
        const {t, match} = this.props;

        const index = _.get(match, 'params.index');

        if (!index) {
            return <Error type="404"/>
        }

        return <ServiceActionTypeDashboard index={index}/>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withResources(["administrateServiceActionTypePrivileges"])
)(ServiceActionTypeDetailRoute);
