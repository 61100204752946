// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Tooltip} from "antd";
import {Button} from "@dreebit/react-components";
import {withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import _ from "lodash"
import ServiceBudgetListExportQuery from "../../graphql/queries/serviceBudgetListExport.graphql";

type Props = {
    t: Function,
    params: any
};

type State = {
    loading: boolean,
}

class ServiceBudgetExport extends Component<Props> {

    state = {
        loading: false
    };

    handleOnClick = () => {

        this.setState({
            loading: true
        }, () => {
            this.props.client.query({
                query: ServiceBudgetListExportQuery,
                variables:{
                    params: this.props.params
                }
            }).then(({data}) => {

                const download = _.get(data, 'serviceBudgetList.export.link');
                window.open(download, '_blank');

            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })

    };

    render() {
        const {t} = this.props;

        return <Tooltip title={t('Export')}>
            <Button icon={"download"} loading={this.state.loading} onClick={this.handleOnClick} className="mr-10"/>
        </Tooltip>
    }
}

export default compose(
    withTranslation(),
    withRouter,
    withApollo,

    withTranslation()
)(ServiceBudgetExport);
