// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

import AttributesInlineForm from "../Form/attributes-inline-form";
import MutationButton from "../MutationButton";
import ServiceBudgetExport from "../ServiceBudgetExport";
import {getParams} from "./index";

type Props = {
  onChange: Function,
  values: any,
  t: Function,
  disableStatus?: boolean,
};


class ServiceBudgetToolbar extends Component<Props, State> {
  render() {
    const {onChange, values, t} = this.props;

    const attributes = [
      {
        index: 'componentId',
        type: 'string',
        inputType: 'component',
      },
      {
        index: 'dueDate',
        type: "object",
        inputType: "daterangepicker",
        name: t('date range'),
        format: 'LL'
      },
      {
        index: 'isPlanned',
        name: t('Planned'),
        type: 'boolean',
        inputType: 'select',
        optionValues: [{
          value: 'true',
          name: t('Planned')
        },{
          value: 'false',
          name: t('Unplanned')
        }]
      }
    ];

    return <div>
      <AttributesInlineForm
          attributes={attributes}
          layout={'inline'}
          useSubmit={false}
          onChange={(attributeValues, allValues) => {
            if (onChange)
              onChange(attributeValues, allValues)
          }}
          values={values}
      />
    </div>

  }
}

export default withTranslation()(ServiceBudgetToolbar)
