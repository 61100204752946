

export const cleanKeys = (obj) => {

    if (!obj) return null;

    return Object.keys(obj).reduce((acc, key) => {



        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== ""){
            acc[key] = obj[key];
        }
        return acc;
    },{})
}


