// @flow
import React, { Component } from 'react';
import MutationButton from "../MutationButton";
import {compose} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import UpdateUserTourProgressMutation from "../../graphql/mutations/updateUserTourProgress.graphql";
import {withTranslation} from "react-i18next";

type Props = {
  tourSkipInput: any,
  onDone?: Function,
  block?: boolean,
}

class TourGuideSkipButton extends Component<Props> {

  render() {
    const { updateUserTourProgressMutation, t, onDone, block } = this.props;

    return <MutationButton
      mutation={updateUserTourProgressMutation}
      onDone={onDone}
      block={block}
    >
      {t("Skip Tour")}
    </MutationButton>
  }
}

export default compose(
  graphql(UpdateUserTourProgressMutation, {
    name: 'updateUserTourProgressMutation',
    options: (props) => ({
      variables: {
        input: props.tourSkipInput
      }
    })
  }),
  withTranslation()
)(TourGuideSkipButton);
