import _ from 'lodash'
import {getColorFromServicePlan} from "./servicePlanHelper";
import {getColorFromServiceControl} from "./serviceControlHelper";

const serviceCalendarItemConfig = {
    servicePlan: (data) => ({
        id: `servicePlan-${data.id}`,
        dataId: data.id,
        text: data.name,
        color: getColorFromServicePlan(data),
        data: data,
        link: `/infrastructure/${_.get(data, 'component.id')}/serviceplans/${data.id}`,
    }),
    serviceControl: (data) => ({
        id: `serviceControl-${data.id}`,
        dataId: data.id,
        text: data.name,
        color: getColorFromServiceControl(data),
        data: data,
        link: `/infrastructure/${_.get(data, 'component.id')}/servicecontrols/${data.id}`,
    })
};

export function mapServiceCalendarBadgeItemProps(dataItem) {
    const item = Object.keys(serviceCalendarItemConfig).reduce((acc, key) => {
        if (acc) {
            return acc;
        }
        if (_.has(dataItem, key)) {
            return serviceCalendarItemConfig[key](_.get(dataItem, key));
        }
        return acc;
    }, null);

    const result = item || {
        id: `${_.uniqueId(dataItem.calendarItemIndex)}`,
        text: dataItem.name,
        color: '#5f5f5f'
    };

    return {
        ...result,
        calendarItemIndex: dataItem.calendarItemIndex
    }
}
