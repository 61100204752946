// @flow
import React, {Component} from 'react';
import Image from "../Utilities/Image";
import ComponentTypeIcon from "../ComponentTypeIcon";
import OneLineLabel from "../OneLineLabel";
import _ from "lodash";
import ProductInfoQuery from "./../../graphql/queries/ProductInfo.graphql";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
    productId: string,
    product: {
        name: string,
        catalogNo: string,
        manufacturer: {
            name: string,
        },
        imageUrl: string,
        componentType: {
            index: string,
        }
    },
    wrapperStyle: ?any,
};


class ProductInfoBox extends Component<Props, State> {
    static defaultProps = {
        wrapperStyle: {padding: '8px 16px'}
    };

    render() {
        const {product, t, wrapperStyle} = this.props;

        const { componentType, name, catalogNo, manufacturer} = product;

        return <div style={wrapperStyle}>
            <div className="flex-row">
                <div>
                    <Image height={75} src={_.get(product,'image.thumbnailLink')}/>
                </div>
                <div style={{overflow: 'hidden'}} className="flow-grow-1 ph-10 ml-5">
                    <div style={{fontSize: 18}}>
                        <span style={{minWidth: "1.5em", display: 'inline-block'}}><ComponentTypeIcon
                            componentTypeIndex={componentType.index}/></span>

                        <span>{name}</span>
                    </div>
                    {
                        <div>
                            <OneLineLabel><span>{t('Catalog No')}:</span> <span>{catalogNo}</span></OneLineLabel>
                            <OneLineLabel><span>{t('Manufacturer')}:</span>
                                <span>{_.get(manufacturer, 'name')}</span></OneLineLabel>
                        </div>
                    }
                </div>
            </div>
        </div>;
    }
}

export default compose(
    graphql(ProductInfoQuery, {
        name: 'productInfoQuery',
        options: (props: Props) => ({
            variables: {
                id: props.productId
            }
        }),
    }),
    waitWhileLoading('productInfoQuery', 'product'),
    withTranslation(),
    withProps(props => ({
        product: _.get(props, 'productInfoQuery.product')
    }))
)(ProductInfoBox);
