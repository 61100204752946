// @flow
import React, {Component} from 'react';
import type {Attribute} from "../../types";
import {withTranslation} from "react-i18next";
import TDAAttributesForm from "./../TDAAttributesForm";
import _ from 'lodash';
import './index.css';

type Props = {
    t: Function,
    componentId: string,
    attributes: Attribute[],
    mutation: Function,
    translateMutationOptions: Function,
};

const halfColProps = {
    md: 12
};
const fullColProps = {
    md: 24
};

export const getContaminationDeclarationGroups = (t) => [
    {
        name: `1. ${t('Description of component')}`,
        index: 'shippingInfo',
        colProps: halfColProps
    },
    {
        name: `2. ${t('Reason for return')}`,
        index: 'reason',
        colProps: halfColProps,
        style: {
            formItemLayout: {
                labelCol: {
                    md: {span: 0}
                },
                colon: false,
                label: null,
                wrapperCol: {
                    md: {span: 24}
                },
            }
        }
    },
    {
        name: `3. ${t('Equipment condition')}`,
        index: 'stateOfComponent',
        colProps: halfColProps,
        style: {
            formItemLayout: {
                labelCol: {
                    md: {span: 24}
                },
                wrapperCol: {
                    md: {span: 24}
                },
            }
        }
    },
    {
        name: `4.1 ${t('Process related contamination of equipment')}`,
        index: 'processOfContamination',
        colProps: halfColProps,
        footer: <span
            className={'text-italic'}>* {t('We will not accept delivery of any equipment that has been radioactively or microbiologically contaminated without written evidence of decontamination!')}</span>,
        style: {
            formItemLayout: {
                labelCol: {
                    md: {span: 12}
                },
                className: 'width-half display-inline-block',
                colon: false,
                label: null,
                wrapperCol: {
                    md: {span: 12}
                },
            }
        }

    },
    {
        name: `4.2 ${t('Harmful substances')}`,
        index: 'contaminants',
        colProps: fullColProps,
        style: {
            formItemLayout: {
                labelCol: {
                    md: {span: 0}
                },
                colon: false,
                label: null,
                wrapperCol: {
                    md: {span: 24}
                },
            }
        }
    },
    {
        name: `5. ${t('Legally Binding Declaration')}`,
        index: 'declaration',
        colProps: fullColProps,
        style: {
            formItemLayout: {
                labelCol: {
                    md: {span: 7}
                },
                className: 'width-half display-inline-block',
                wrapperCol: {
                    md: {span: 17}
                },
            }
        }
    }
]

class ContaminationDeclarationForm extends Component<Props, State> {

    render() {
        const {t, mutation, translateMutationOptions} = this.props;
        let {attributes} = this.props;

        const titleMap = {
            'freeFromHarmfulSubstances': t('Is the equipment free from potentially harmful substances?'),
            'oil': t('What type of pump oil was used?'),
            'componentUsed': t('Has the component been used?'),
            'toxic': ({name}) => (`${name} *`),
            'microbiologicalHazard': ({name}) => (`${name} *`),
            'explosive': ({name}) => (`${name} *`),
        };
        const titleKeys = Object.keys(titleMap);

        attributes = attributes.map(item => {
            if (titleKeys.indexOf(item.index) !== -1) {
                return {
                    ...item,
                    name: _.isFunction(titleMap[item.index]) ? titleMap[item.index](item) : titleMap[item.index],
                }
            }
            return item;
        });



        const conditionList = [
            "toxic",
            "microbiologicalHazard",
            "radioActive",
            "corrosive",
            "explosive",
            "otherHarmfulSubstances"
        ];



        attributes = attributes.map((attribute) => {

            if (attribute.index === 'reasonForReturn'){
                attribute = {
                    ...attribute,
                    name: null
                }
            }


            return attribute;
        });

        return <div className={"submit-right DecontaminationForm"}>
            <TDAAttributesForm
                mutation={mutation}
                attributes={attributes}
                groups={getContaminationDeclarationGroups(t)}
                translateMutationOptions={translateMutationOptions}
            />
        </div>
    }
}


export default withTranslation()(ContaminationDeclarationForm);
