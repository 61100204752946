// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ServiceTicketLogTimeline from "../../components/ServiceTicketLogTimeline";
import {graphql} from '@apollo/client/react/hoc';
import Enums from "../../graphql/queries/enums.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceTicketLogToolbar from "../../components/ServiceTicketLogToolbar";

type Props = {
    logLevelEnumsQuery: query,
    logActionKeyEnumsQuery: query,
    ticketId: any,
};

type State = {
    values: any,
}

class ServiceTicketLogTimelineRoute extends Component<Props, State> {


    state = {
        values: {logLevel: [], logActionKey: []},
    };

    onChangeToolbar = (change) => {
        this.setState({
            values: {
                ...this.state.values,
                ...change
            }
        });
    };

    render() {
        const {ticketId, logLevelEnumsQuery, logActionKeyEnumsQuery} = this.props;

        return <div>
            <ServiceTicketLogToolbar
                values={this.state.values}
                onChange={this.onChangeToolbar}
                logLevelEnums={_.get(logLevelEnumsQuery, '__type.enumValues')}
                logActionKeyEnums={_.get(logActionKeyEnumsQuery, '__type.enumValues')}
            />
            <ServiceTicketLogTimeline values={this.state.values} serviceTicketId={ticketId}/>
        </div>
    }
}

export default compose(
    withRouter,
    graphql(Enums, {
        name: 'logLevelEnumsQuery',
        options: () => ({
            variables: {
                name: 'LogLevel',
            }
        }),
    }),
    waitWhileLoading('logLevelEnumsQuery', '__type.enumValues'),
    graphql(Enums, {
        name: 'logActionKeyEnumsQuery',
        options: () => ({
            variables: {
                name: 'LogActionKey',
            }
        }),
    }),
    waitWhileLoading('logActionKeyEnumsQuery', '__type.enumValues'),
    withTranslation()
)(ServiceTicketLogTimelineRoute);
