// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import PortletQuery from "../../graphql/queries/portlet.graphql";
import UpdatePortletMutation from "../../graphql/mutations/updateUserPortlet.graphql";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributesInlineForm from "../Form/attributes-inline-form";

type Props = {
    portletId: string,
};

type State = {}

class DashboardPortletNameForm extends Component<Props, State> {

    render() {
        const {t, portletQuery, mutation, portletId} = this.props;


        return <div>
            <AttributesInlineForm
                values={{
                    title: _.get(portletQuery, 'userPortlet.title')
                }}
                mutation={mutation}
                translateMutationOptions={({values}) => {
                    return {
                        variables: {
                            id: portletId,
                            input: values
                        }
                    }
                }}
                attributes={[
                    {
                        index: 'title',
                        inputType: 'text',
                        type: 'string',
                        name: t('Name')
                    }
                ]}
            />
        </div>;
    }
}

export default compose(
    graphql(PortletQuery, {
        name: 'portletQuery',
        options: (props) => {
            return {
                variables: {
                    id: props.portletId
                }
            }
        }
    }),
    graphql(UpdatePortletMutation, {
        name: 'mutation'
    }),
    withTranslation(),
    waitWhileLoading('portletQuery')
)(DashboardPortletNameForm);
