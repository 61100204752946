// @flow
import React, {Component} from "react"
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import _ from "lodash";
import AttributesInlineForm from "../Form/attributes-inline-form";
import UpdateClientMutation from "./../../graphql/mutations/updateClient.graphql";
import ClientDetailsForm from "./../../graphql/queries/clientDetails-Form.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {categoryIndizesToGroups} from "../../utils/categoryHelper";
import ServiceContractBanner from "./serviceContractBanner";
import Error from "../Error";
import ClientActivationBanner from "../ClientActivationBanner";

type Props = {
    clientId: string,
    currentUserClientId: string,
    updateClientMutation: Function,
    clientDetailsFormQuery: any,
}

class ClientForm extends Component<Props> {

    render() {

        const {updateClientMutation, clientId, currentUserClientId, clientDetailsFormQuery, t} = this.props;

        const attributes = _.get(clientDetailsFormQuery, 'client.typeDefinitionAttributeList.typeDefinitionAttributes', []);

        /*
          attributes.push({
            type: 'float',
            name: 'Electricity Price',
            inputType: 'float',
            index: 'electricityPrice',
            categories: ['tariffs'],
            addOnAfter: '€',
        })
         */

        const formGroups = [
            {
                name: t("Default"),
                index: "default",
                colProps: {
                    lg: 12
                }
            },
            {
                name: t("Client information"),
                index: "serviceProviderClient",
                colProps: {
                    lg: 12
                }
            },
            {
                name: t("Address"),
                index: "address",
                colProps: {
                    lg: 12
                }
            },
            {
                name: t("Company"),
                index: "company",
                colProps: {
                    lg: 12
                }
            },
        ];

        return <div className={'white-bg'}>
            {/* deprecated: Funktionen für Service Vertrags Partner werden testweise nicht mehr angezeigt */}
            {/* ggf. später wieder einfügen oder komplett entfernen */}
            {/*<ServiceContractBanner clientId={this.props.clientId}/>*/}
            <ClientActivationBanner clientId={this.props.clientId} currentUserClientId={currentUserClientId}/>
            <AttributesInlineForm
                className={"ph-15"}
                inline={true}
                attributes={attributes}
                groups={formGroups}
                mutation={updateClientMutation}
                translateMutationOptions={({values}) => {
                    return {
                        variables: {
                            id: clientId,
                            input: values
                        }
                    }
                }}/>
        </div>;
    }

}

export default compose(
    graphql(ClientDetailsForm, {
        name: 'clientDetailsFormQuery',
        options: (props) => ({
            variables: {
                id: props.clientId
            }
        }),
    }),
    waitWhileLoading('clientDetailsFormQuery', ['client.typeDefinitionAttributeList.typeDefinitionAttributes']),
    graphql(UpdateClientMutation, {
        name: 'updateClientMutation',
    }),
    withTranslation()
)(ClientForm);
