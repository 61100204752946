// @flow
import React, {Component} from 'react';
import {message, Popconfirm} from 'antd';
import {Button, Icon} from "@dreebit/react-components";
import {Row} from "../Flex/index";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

import ContentHeader from "../Typo/ContentHeader";
import AttributesInlineForm from "../Form/attributes-inline-form";
import styled from "styled-components";
import DefaultServiceTaskQuery from "../../graphql/queries/defaultServiceTask.graphql";
import UpdateDefaultServiceTaskMutation from "../../graphql/mutations/updateDefaultServiceTask.graphql";
import DeleteDefaultServiceTaskMutation from "../../graphql/mutations/deleteDefaultServiceTask.graphql";
import ComponentTypeDefaultServiceTaskListQuery
    from "../../graphql/queries/componentTypeDefaultServiceTaskList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Panel from "../Panel";


type Props = {
    t: Function,
    defaultServiceTaskQuery: query,
    updateDefaultServiceTaskMutation: Function,
    deleteDefaultServiceTaskMutation: Function,
    history: {
        push: Function,
    },
    match: {
        params: {
            index: string,
            defaultServiceTaskId: string,
        }
    },
};

const Wrapper = styled.div`
  border: 1px solid #cfdbe2;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: white;
`;


class ComponentTypeDefaultServiceTask extends Component<Props> {

    handleDelete = () => {
        const {deleteDefaultServiceTaskMutation, history, match} = this.props;

        deleteDefaultServiceTaskMutation({variables: {defaultServiceTaskId: _.get(match, 'params.defaultServiceTaskId')}}).then((res) => {
            message.success(res.data.deleteDefaultServiceTask.message);
            history.push(`/catalog/componentTypes/detail/${_.get(match, 'params.index')}`);
        })
    };

    render() {
        const {t, defaultServiceTaskQuery, updateDefaultServiceTaskMutation, history, match} = this.props;

        const defaultServiceTaskId = _.get(match, 'params.defaultServiceTaskId');
        const attributes = _.get(defaultServiceTaskQuery, 'defaultServiceTask.typeDefinitionAttributeList.typeDefinitionAttributes', []);

        return <div>
            <ContentHeader title={_.get(defaultServiceTaskQuery, 'defaultServiceTask.name', "")}/>
            <Wrapper>
                <Row className={'flex-space-between mb-10 flex-center'}>
          <span style={{fontSize: '1.3em', fontWeight: 'bold'}}>
            {_.get(defaultServiceTaskQuery, 'defaultServiceTask.name')}
          </span>
                    <Popconfirm
                        title={t('Please confirm to delete this item')}
                        icon={<Icon type="delete" style={{color: 'red'}}/>}
                        onConfirm={this.handleDelete}
                        okText={t('Confirm')}
                        cancelText={t('Cancel')}
                    >
                        <Button href="#" icon="delete" type="danger">{t('Remove')}</Button>
                    </Popconfirm>
                </Row>
                <div>
                    <Panel>
                        <AttributesInlineForm
                            attributes={attributes}
                            t={t}
                            useSubmit={true}
                            mutation={updateDefaultServiceTaskMutation}
                            translateMutationOptions={({values}) => ({
                                variables: {
                                    defaultServiceTaskId: defaultServiceTaskId,
                                    attributes: values,
                                }
                            })}
                        />
                    </Panel>

                </div>
            </Wrapper>
        </div>
    }
}

export default compose(
    withRouter,
    graphql(DefaultServiceTaskQuery, {
        name: 'defaultServiceTaskQuery',
        options: (props: Props) => ({
            variables: {
                id: _.get(props, 'match.params.defaultServiceTaskId'),
            }
        }),
    }),
    waitWhileLoading('defaultServiceTaskQuery', ["defaultServiceTask.typeDefinitionAttributeList.typeDefinitionAttributes"]),
    graphql(UpdateDefaultServiceTaskMutation, {
        name: "updateDefaultServiceTaskMutation",
        options: (props: Props) => ({
            refetchQueries: [{
                query: ComponentTypeDefaultServiceTaskListQuery,
                variables: {
                    "params": {
                        "filter": {
                            "componentTypeIndex": {
                                "value": _.get(props, 'index')
                            }
                        }
                    }
                }
            }]
        }),
    }),
    graphql(DeleteDefaultServiceTaskMutation, {
        name: "deleteDefaultServiceTaskMutation",
        options: (props: Props) => ({
            refetchQueries: [{
                query: ComponentTypeDefaultServiceTaskListQuery,
                variables: {
                    "params": {
                        "filter": {
                            "componentTypeIndex": {
                                "value": _.get(props, 'index')
                            }
                        }
                    }
                }
            }]
        }),
    }),
    withTranslation(),
)(ComponentTypeDefaultServiceTask);

