// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import ServiceTicketCreateForm from "../../components/ServiceTicketCreateForm";
import Toolbar from "../../components/Toolbar";
import withTheme from "../../hoc/withTheme";


class ComponentServiceTicketsCreateRoute extends Component<Props, State> {

    state = {}

    _handleCreate = (res) => {
        const {history} = this.props;
        const id = _.get(res, 'data.createServiceTicket.id');
        if (id) {
            history.push(`/service/${id}`);
        }

    };

    render() {
        const {t} = this.props;
        const componentId = _.get(this.props, 'match.params.id');

        return <div className={"white-bg pb-10"}>
            <Toolbar renderLeft={() => <h3>{t('New Service Ticket')}</h3>} borderBottom/>
            <ServiceTicketCreateForm
                onCreated={this._handleCreate}
                componentId={componentId}
            />
        </div>;
    }
}

export default compose(
    withRouter,
    withTheme(),
    withTranslation()
)(ComponentServiceTicketsCreateRoute)

