// @flow
import React, {Component} from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import ServiceCalenderListQuery from "./../../graphql/queries/ServiceCalenderList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import moment from "moment";
import {withRouter} from "react-router-dom";

import Values from "../../themes/default/values";
import QueryTable from "../QueryTable";
import ComponentImage from "../ComponentImage";
import OneLineLabel from "../OneLineLabel";
import {mapServiceCalendarBadgeItemProps} from "../../utils/calendarItemHelper";
import ServiceCalendarItemIcon from "../ServiceCalendarItemIcon";
import FillQueryTable from "../QueryTable/fill";

type Props = {
    filter: any,
    search: any,
    showPath?: boolean,
};


class ServiceCalendarPortletTable extends Component<Props, State> {

    static defaultProps = {
        showPath: true
    }


    render() {
        const {serviceCalenderListQuery, t, showPath, history} = this.props;

        return <FillQueryTable
            className={"table-layout-fixed"}
            size={"middle"}
            onRow={(record, rowIndex) => ({
                onClick: (event) => {
                    const calendarItem = mapServiceCalendarBadgeItemProps(record);
                    if (record.calendarItemIndex === 'servicePlan') {
                        const component = _.get(calendarItem, 'data.component');
                        const servicePlan = _.get(record, 'servicePlan');
                        if (component && servicePlan) {
                            history.push(`/infrastructure/${component.id}/serviceplans/${servicePlan.id}`)
                        }
                    } else if (record.calendarItemIndex === 'serviceControl') {
                        const component = _.get(calendarItem, 'data.component');
                        const serviceControl = _.get(record, 'serviceControl');
                        if (component && serviceControl) {
                            history.push(`/infrastructure/${component.id}/servicecontrols/${serviceControl.id}`)
                        }
                    }
                }
            })}
            query={serviceCalenderListQuery}
            itemsKeyPath={'calendarItemList.calendarItems'}
            totalKeyPath={'calendarItemList.total'}
            columns={[, {
                dataIndex: 'calendarItemIndex',
                width: 10,
                render: (text, item) => {
                    return <ServiceCalendarItemIcon calendarItemIndex={item.calendarItemIndex}/>;
                }
            }, {
                dataIndex: 'image',
                width: 70,
                render: (text, item) => {
                    const calendarItem = mapServiceCalendarBadgeItemProps(item);
                    const component = _.get(calendarItem, 'data.component');

                    return <ComponentImage size={50} link={_.get(component, 'image.thumbnailLink')}/>
                }
            }, {
                dataIndex: 'name',
                title: t('Name'),
                width: "25%",
                render: (text, item) => <div>
                    <div>{item.name || '-'}</div>
                    <div className={"text-grey"}>{t('Due to')}: {moment(item.date).format('L')}</div>
                </div>
            }, {
                dataIndex: 'component',
                title: t('Component'),
                width: "35%",
                render: (text, item) => {

                    const calendarItem = mapServiceCalendarBadgeItemProps(item);
                    const component = _.get(calendarItem, 'data.component');
                    if (!component) {
                        return null;
                    }
                    return <div>
                        <div>{component.name}</div>
                        {showPath ?
                            <OneLineLabel><ComponentPathBreadcrumb path={component.path}/></OneLineLabel> : null}
                    </div>
                }
            }, {
                dataIndex: 'catalogNo',
                title: t('Catalog No'),
                render: (text, item) => {
                    const calendarItem = mapServiceCalendarBadgeItemProps(item);
                    const component = _.get(calendarItem, 'data.component');
                    if (!component) {
                        return null;
                    }
                    return <div>
                        <div>
                            {_.get(component, 'product.manufacturer.name')}
                        </div>
                        <div>
                            {_.get(component, 'product.catalogNo')}
                        </div>
                    </div>
                }
            }]}
        />
    }
}


export default compose(
    withRouter,
    withTranslation(),
    graphql(ServiceCalenderListQuery, {
        name: 'serviceCalenderListQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: props.filter || {
                        date: {
                            operator: 'gt',
                            value: new Date().toISOString(),
                        }
                    },
                    search: props.search || {},
                    start: 0,
                    limit: Values.pagingSize
                }
            }
        }),
    }),
    waitWhileLoading('serviceCalenderListQuery', 'calendarItemList.calendarItems')
)(ServiceCalendarPortletTable);
