import React, {Component} from "react";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ComponentTCOQuery from "../../graphql/queries/componentTCOBasis.graphql";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {Empty} from "antd";
import {Line, LineChart, XAxis, YAxis} from "recharts";
import {withSize} from "react-sizeme";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import withTheme from "../../hoc/withTheme";
import {formatCurrency} from "../CurrencyLabel";

type Props = {
    componentId: string,
    tcoSettings: any,
    resultFormat?: string
}

type State = {
    modal: boolean
}

class ComponentTCOChart extends Component<Props, State> {

    state = {
        modal: false
    }

    render() {
        const { t, size , componentTCOQuery, themeConfig } = this.props;


        const entries =  _.get(componentTCOQuery,'component.componentTcoValueList.componentTcoValues',[]).map((item) => ({
            ...item,
            date: new Date(item.date).getTime()
        }))

        if (!entries.length) return <Empty/>

        return <div>
            <LineChart
                width={size.width}
                height={300}
                data={entries}
            >
                <XAxis
                    scale={'time'}
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    dataKey={"date"}
                    tickFormatter={(val) => moment(val).format('DD.MM.YYYY')}
                />
                <YAxis
                    dataKey={"value"}
                    width={100}
                    tickFormatter={(val) => formatCurrency(val, t)}
                />
                <Line type="monotone" dataKey="value" stroke={themeConfig["@primary-color"]} />


            </LineChart>
        </div>;
    }
}


export default compose(
    graphql(ComponentTCOQuery,{
        name: 'componentTCOQuery',
        options: (props) => ({
            variables: {
                id: props.componentId,
                params: {
                    // ...props.tcoSettings
                    resultFormat:"cumulative",
                    includeSubComponents: !!props.tcoSettings.includeSubComponents,
                    considerServiceTickets: !!props.tcoSettings.considerServiceTickets,
                    filter: props.tcoSettings.date && props.tcoSettings.date.length === 2 ? {
                        date: {
                            operator: "range",
                            range: {
                                from: _.first(props.tcoSettings.date),
                                to: _.last(props.tcoSettings.date)
                            }
                        }
                    } : undefined
                }
            }
        } )
    }),
    waitWhileLoading('componentTCOQuery'),
    withTranslation(),
    withSize(),
    withTheme()
)(ComponentTCOChart);
