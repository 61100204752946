// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import ServiceCenterFormQuery from "./../../graphql/queries/ServiceCenterFormQuery.graphql";
import ServiceCenterUpdateMutation from "./../../graphql/mutations/updateServiceCenter.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributeInlineForm from "../Form/attributes-inline-form";

type Props = {
    serviceCenterId: string,
    attributes: any[],
    serviceCenterUpdateMutation: Function,
};


class ServiceCenterForm extends Component<Props, State> {

    render() {
        const {serviceCenterId, attributes, serviceCenterUpdateMutation} = this.props;

        return <AttributeInlineForm
            useSubmit
            attributes={attributes}
            mutation={serviceCenterUpdateMutation}
            translateMutationOptions={({values}) => ({
                variables: {
                    serviceCenterId,
                    input: values
                }
            })}
        />;
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceCenterFormQuery, {
        name: 'serviceCenterFormQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceCenterId
            }
        }),
    }),
    waitWhileLoading('serviceCenterFormQuery'),
    graphql(ServiceCenterUpdateMutation, {
        name: 'serviceCenterUpdateMutation',
    }),
    withProps((props) => ({
        serviceCenter: _.get(props, 'serviceCenterFormQuery.serviceCenter'),
        attributes: _.get(props, 'serviceCenterFormQuery.serviceCenter.typeDefinitionAttributeList.typeDefinitionAttributes', []),
    }))
)(ServiceCenterForm);
