export const defaultSteps = [
    {
        title: 'Address',
        component: "CreateClientAddressStep"
    },
    {
        title: 'Customer number & contact',
        component: "CreateClientClientInformationStep"
    },
]

export const serviceProviderSteps = [
    {
        title: 'Name',
        component: "CreateClientGeneralStep"
    },
    ...defaultSteps
]
