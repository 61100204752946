// @flow
import React, {Component} from 'react';
import Toolbar from "../Toolbar";
import ManufacturerToolbar from "../ManufacturerToolbar";
import ManufacturerMatchList from "../ManufacturerMatchList";

type Props = {
    onManufacturerSelect: (manufacturerId: string ) => void,
    removeKeyById?: string
};

type State = {
    filter: any,
}

class ManufacturerFilterList extends Component<Props, State> {

    state = {
        filter: this.props.filterValues
    }

    render() {
        const { tableProps, onManufacturerSelect, removeKeyById } = this.props;

        return <div className={'full-width'}>
            <Toolbar
                borderBottom
                className={"white-bg"}
                renderLeft={() => <ManufacturerToolbar
                    values={this.state.filter}
                    onChange={(attributes) => {
                        this.setState({
                            filter: {
                                ...this.state.filter,
                                ...attributes
                            }
                        })
                    }}
                />}
            />

            <ManufacturerMatchList
                filterValues={this.state.filter}
                onClick={(item) => {
                    if (onManufacturerSelect) {
                        onManufacturerSelect(item.id)
                    }
                }}
                tableProps={tableProps}
                removeKeyById={removeKeyById}
            />
        </div>;
    }
}

export default ManufacturerFilterList;
