// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import Loader from "../Loader"
import CreateWarrantyReportMutation from "./../../graphql/mutations/createWarrantyReport.graphql"
import Error from "../Error";

type Props = {
    serviceTicketId: string,
    onCreated: (res) => void,
};

type State = {

}

class WarrantyReportCreater extends Component<Props, State> {

    state = {
        loading: true
    }

    componentDidMount(){

        this.props.createWarrantyReportMutation().then((res) =>{
            this.setState({
                loading: false
            }, () => {
                if (this.props.onCreated){
                    this.props.onCreated(res)
                }
            })
        }).catch((err) => {
            this.setState({
                loading: false,
                error: err
            })
        })

    }

  render() {
    const { loading, error } = this.state;

    if (loading ) return <Loader/>;
      if (error ) return <Error error={error}/>;

    return null;
  }
}


export default compose(
    graphql(CreateWarrantyReportMutation,{
        name: 'createWarrantyReportMutation',
        options: ({serviceTicketId}) => ({
            variables: {
                serviceTicketId
            }
        })
    })
)(WarrantyReportCreater);
