// @flow
import React, {Component} from 'react';
import ComponentProductInfoQuery from '../../graphql/queries/component-productInfo.graphql';
import _ from "lodash";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import ComponentImage from "../ComponentImage";
import Panel from "../Panel";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import {AttributesForm} from "@dreebit-registry/form-attributes-vsm";
import withResources from "../../hoc/withResources";
import {Icon} from "@dreebit/react-components";
import {Link} from "react-router-dom";

type Props = {
  componentId: string,
  componentProductInfoQuery: any,
  t: Function
};

type State = {
  visible: boolean,
}

class ProductInfoWidget extends Component<Props, State> {

  state = {
    visible: false
  };

  componentDidMount() {}

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const {componentId, componentProductInfoQuery, t} = this.props;
    const component = _.get(componentProductInfoQuery, 'component', {});
    const typeDefAttributesSlice = _.slice(_.get(component, 'product.typeDefinitionAttributeList.typeDefinitionAttributes'), 6);
    const disabledAttributes = _.forEach(_.cloneDeep(typeDefAttributesSlice), (item) => {_.set(item, 'disabled', true)});
    const productFiles = _.get(component, 'product.productFileList.productFiles', []);


    const manufacturerLink = _.get(component, 'product.manufacturerLink');

    const imageAndInfo = <div className='flex-row' onClick={this.showModal}>
      <Link to={`/infrastructure/${componentId}/product`}>
        <div>
          <ComponentImage editable={false} link={_.get(component, 'product.image.thumbnailLink')}/>
        </div>
      </Link>
      <div className='flex-grow-1 flex-col ml-10'>
        <Link to={`/infrastructure/${componentId}/product`}>
          <span className='larger-text bold' style={{textDecoration: 'none', color: 'black'}}>
            {_.get(component, 'product.name')}
          </span>
        </Link>
        <span className='text-italic'>{_.get(component, 'product.componentType.name')}</span>
        <span>{t('Manufacturer')}: {_.get(component, 'product.manufacturer.name')}</span>
        <span>{t('Catalog No')}: {_.get(component, 'product.catalogNo')}</span>
        {manufacturerLink ? <div>
          <a target={"_BLANK"} title={_.get(component, 'product.name')}
             href={manufacturerLink}>{t("Find more information")} <Icon style={{verticalAlign: 'middle'}}
                                                                        type={'link'}/> </a>
        </div> : null}
      </div>
    </div>;

    const footerInfo = <div>
      {disabledAttributes.length > 0 ?
        <Panel title={t('Catalog data')}>
          <AttributesForm
            attributes={disabledAttributes}
            disableSubmit
            editable={false}
            t={t}
          />
        </Panel> : null
      }
      {productFiles.length > 0 ?
        <Panel title={t('Files')}>
          {productFiles.map((file, index) => {
            return <div key={`${index}`} className='flex-row flex-space-between mb-5 large-text'>
              <a target="_blank" rel="noopener noreferrer" href={file.link}>
                <MaterialCommunityIcon name={'paperclip'}/> {file.filename}
              </a>
              {(file.size.toString().length > 6) ?
                <span>{Math.round( (file.size/1048576) * 10 ) / 10} MB</span> :
                <span>{Math.round( (file.size/1024) * 10 ) / 10} KB</span>
              }
            </div>
          })}
        </Panel> : null
      }
    </div>;

    return <div>
      <div className='flex-row' onClick={this.showModal}>
        {imageAndInfo}
      </div>
      {/*<Modal
        title={t('Product Information')}
        visible={this.state.visible}
        onCancel={this.handleCancel}
        footer={footerInfo}
        centered
      >
        {imageAndInfo}
      </Modal>*/}
    </div>
    ;
  }
}

export default compose(
  graphql(ComponentProductInfoQuery, {
    name: 'componentProductInfoQuery',
    options: (props: Props) =>({
      variables: {
        componentId: props.componentId
      }
    }),
  }),
  waitWhileLoading('componentProductInfoQuery', 'component'),
  withTranslation(),
    withResources(),
)(ProductInfoWidget);
