// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ServiceTicketCreateForm from "../ServiceTicketCreateForm";
import _ from 'lodash';

type Props = {
  t: Function,
};

type State = {
    data: any
}

class CreateServiceTicketTest extends Component<Props, State> {
  componentDidMount() {  }

  state = {
      data: {}
  };

  render() {
    const { t } = this.props;
    const { data } = this.state;

    return <div>
        <ServiceTicketCreateForm onCreated={(data) => this.setState({data: data.data})} componentId={22276}/>
        <span id={'response'}>{_.get(data, 'createServiceTicket.id')?'success':'error'}</span>
    </div>;
  }
}

export default compose(
    withTranslation(),
)(CreateServiceTicketTest);