// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import TypeDefinitionAttributeListQuery from "./../../graphql/queries/typeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from 'lodash'
import {compose, withProps} from "recompose";
import type {Attribute} from "../../types";
import TDAAttributesForm from "../TDAAttributesForm";

type Props = {
    index: string,
    formProps: any,
    filter?: any,
    mapAttributes?: (item: Attribute) => Attribute
};


class TypeDefinitionAttributesForm extends Component<Props, State> {

    render() {
        const {attributes, formProps} = this.props;

        return <TDAAttributesForm
            {...formProps}
            attributes={attributes}
        />;
    }
}

export default compose(
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props: Props) => ({
            variables: {
                index: props.index,
                options: props.options
            }
        }),
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery', 'typeDefinitionAttributeList.typeDefinitionAttributes'),
    withProps((props) => ({
        attributes: _.chain(props)
            .get('typeDefinitionAttributeListQuery.typeDefinitionAttributeList.typeDefinitionAttributes', [])
            .map(props.mapAttributes ? props.mapAttributes : undefined)
            .filter((item) => {
                if (props.filter){
                    return props.filter(item)
                }
                return true
            })
            .value()
    }))
)(TypeDefinitionAttributesForm);
