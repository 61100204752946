import React from 'react';
import _ from "lodash";
import {compose} from "redux";
import { graphql } from '@apollo/client/react/hoc';
import ServiceReportConfigNameQuery from "../../graphql/queries/serviceReportConfigName.graphql";
import {withTranslation} from "react-i18next";

const ServiceReportConfigBreadcrumb = ({serviceReportConfigNameQuery, t}) =>
  <span>{_.get(serviceReportConfigNameQuery, 'serviceReportAttributeConfig.name', '...')}</span>;

export default compose(
  graphql(ServiceReportConfigNameQuery, {
    name: 'serviceReportConfigNameQuery',
    options: ({match}) => ({
      variables: {
        index: _.get(match, 'params.serviceReportConfigIndex'),
      }
    })
  }),
)(withTranslation()(ServiceReportConfigBreadcrumb));
