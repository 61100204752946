// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ComponentTypeErrorPatternQuery from "./../../graphql/queries/componentTypeErrorPatterns.graphql"
import DeleteComponentTypeFromErrorPatternMutation
    from "./../../graphql/mutations/removeComponentTypesFromErrorPattern.graphql"
import AddComponentTypesToErrorPatternMutation from "./../../graphql/mutations/addComponentTypesToErrorPattern.graphql";
import ErrorPatternListQuery from "./../../graphql/queries/errorPatternList.graphql"
import {Icon} from "@dreebit/react-components";
import QueryTable from "../QueryTable";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import QuerySelect from "../QuerySelect";

type Props = {

};

type State = {

}

class ComponentTypeErrorPatterns extends Component<Props, State> {

  render() {
    const { componentTypeErrorPatternQuery, componentTypeIndex,t, deleteComponentTypeFromErrorPatternMutation, addComponentTypesToErrorPatternMutation } = this.props;

    return <div>

        <QuerySelect
            className={"mb-10"}
            useLocalSearch
            onSelect={(id) => {
                return addComponentTypesToErrorPatternMutation({
                    variables: {
                        errorPatternId: id,
                        componentTypeIndices: [componentTypeIndex]
                    }
                })
            }}
            queryConfig={{
                query: ErrorPatternListQuery,
                variables: {

                },
                dataKeyPath: 'errorPatte' +
                  'rnList.errorPatterns',
                titleKeyPath: 'name',
                valueKeyPath: 'id',
            }} />

        <QueryTable
            itemsKeyPath={'errorPatternList.errorPatterns'}
            totalKeyPath={'errorPatternList.total'}
            rowKey={'id'}
            query={componentTypeErrorPatternQuery}
            columns={[{
                dataIndex: 'name',
                title: t('Name')
            },{
                dataIndex: 'index',
                width: 40,
                render: (text, errorPattern) => <Icon type={'close'} className={"pointer"} onClick={() => deleteComponentTypeFromErrorPatternMutation({
                    variables: {
                        errorPatternId: errorPattern.id,
                        componentTypeIndices: [componentTypeIndex]
                    }
                })}/>
            }]}
        />
    </div>;
  }
}

export default compose(
    graphql(ComponentTypeErrorPatternQuery,{
        name: 'componentTypeErrorPatternQuery',
        options: props => ({
            variables: {
                params: {
                    filter: {
                        componentTypeIndex: {
                            value: props.componentTypeIndex
                        }
                    }
                }
            }
        })
    }),
    waitWhileLoading('componentTypeErrorPatternQuery'),
    graphql(AddComponentTypesToErrorPatternMutation,{
        name: 'addComponentTypesToErrorPatternMutation',
        options: (props) => (
            {
                refetchQueries: [
                    {
                        query: ComponentTypeErrorPatternQuery,
                        variables: {
                            params: {
                                filter: {
                                    componentTypeIndex: {
                                        value: props.componentTypeIndex
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        )
    }),
    graphql(DeleteComponentTypeFromErrorPatternMutation,{
        name: 'deleteComponentTypeFromErrorPatternMutation',
        options: (props) => (
            {
                refetchQueries: [
                    {
                        query: ComponentTypeErrorPatternQuery,
                        variables: {
                            params: {
                                filter: {
                                    componentTypeIndex: {
                                        value: props.componentTypeIndex
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        )
    }),
    withTranslation()
)(ComponentTypeErrorPatterns);
