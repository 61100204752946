// @flow
import React, {Component} from 'react';
import {Avatar} from "react-chat-elements";
import ServiceActionLabel from "./serviceActionLabel";
import _ from "lodash";

type Props = {
  message: any
};

type State = {

}

const getInitials = (name) => {

  const initials = _.slice(name.match(/\b\w/g), 0, 2) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

class MessageBoxContent extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { message } = this.props;

    const insertTextAtIndices = (text, obj) => {
      return text.replace(/./g, function(character, index) {
        return obj[index] ? obj[index] + character : character;
      });
    };

    const extractUrlFromText = (text) => {
      return text.match(/\b(http|https)?:\/\/\S+/gi) || [];
    };

    const textWithHyperlinks = (text) => {

      extractUrlFromText(text).forEach(link => {
        const startIndex = text.indexOf(link);
        const endIndex = startIndex + link.length;
        text = insertTextAtIndices(text, {
          [startIndex]: `<a href="${link}" target="_blank" rel="noopener noreferrer">`,
          [endIndex]: "</a>"
        });
      });

      return text;
    };

    /*
    {extractUrlFromText(_.get(message, 'text')).length > 0 && !_.get(message, 'serviceAction.id') ?
        <MicrolinkCard
            url={extractUrlFromText(_.get(message, 'text'))}
            style={{borderRadius: '5px', maxWidth: 'none', marginTop: '5px'}}
        /> : null
    }
    */

    const urls = extractUrlFromText(_.get(message, 'text'));

    return <div>
      <div className='flex-row'>
        <div style={{width: 'fit-content'}}>
          <Avatar
              src={_.get(message, 'author.avatar.thumbnailLink') ? _.get(message, 'author.avatar.thumbnailLink') : `https://via.placeholder.com/90x90.png?text=${getInitials(message.author.name)}`}
              alt={'avatar'}
              size={'large'}
              type="circle flexible"
          />
        </div>
        <div className='flex-col ml-10'>
          <span className={'large-text font-weight-600'}>{_.get(message, 'author.name')}</span>
          <span dangerouslySetInnerHTML={{__html: textWithHyperlinks(_.get(message, 'text'))}}/>
          {_.get(message, 'serviceAction.id') ?
              <ServiceActionLabel serviceActionId={_.get(message, 'serviceAction.id')}/> : null}
        </div>
      </div>
    </div>;
  }
}

export default MessageBoxContent;
