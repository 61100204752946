// @flow
import React, {Component} from 'react';
import {Table} from "antd";
import {Icon} from "@dreebit/react-components";
import {Link} from "react-router-dom";
import ServiceContractQuery from "../../graphql/queries/serviceContract.graphql";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import _ from "lodash";
import type {Query} from "../../types";
import {graphql} from '@apollo/client/react/hoc';
import './index.less';
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
  t: Function,
  serviceContractQuery: Query,
  match: {
    params: any
  },
}

class ServiceContractProductList extends Component<Props, State> {
  componentDidMount() {}

  _getRowClassName = (record) => {
    return _.get(record, 'serviceContractDefaultServiceTasks.length', 0) > 1 ? '' : 'contract-row--hide-expand';
  };

  render() {

    const { t, serviceContractQuery } = this.props;

    const serviceContractProducts = _.get(serviceContractQuery, 'serviceContract.serviceContractProductList.serviceContractProducts', []);

    const serviceContractProductColumns = [
      {
        title: t('Manufacturer'),
        dataIndex: 'product',
        key: 'manufacturerName',
        render: (text, item) => <Link to={`/catalog/manufacturer/${_.get(item, 'product.manufacturer.id')}`}><span
            className='link-dark'>{_.get(text, 'manufacturer.name')}</span></Link>
      },
      {
        title: t('Name'),
        dataIndex: 'product',
        key: 'productName',
        render: (text, item) => <Link to={`/catalog/products/${_.get(item, 'product.id')}`}><span
            className='link-dark'>{_.get(text, 'name')}</span></Link>
      },
      {
        title: t('Catalog No'),
        dataIndex: 'product',
        key: 'catalogNo',
        render: (text, item) => _.get(text, 'catalogNo')
      },
      {
        title: t('Component type'),
        dataIndex: 'product',
        key: 'componentTypeName',
        render: (text, item) => <Link
            to={`/catalog/componentTypes/detail/${_.get(item, 'product.componentType.index')}`}><span
            className='link-dark'>{_.get(text, 'componentType.name')}</span></Link>
      },
      {
        title: t('Count'),
        dataIndex: 'componentCount',
        key: 'componentCount'
      }
    ];

    const serviceContractDefaultServiceTasksColumns = [
      {
        title: t('Service description'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t('Standard price in Euro'),
        dataIndex: 'defaultPrice',
        key: 'defaultPrice'
      },
      {
        title: t('discount'),
        dataIndex: 'contractDiscount',
        key: 'contractDiscount'
      },
      {
        title: t('package price'),
        dataIndex: 'contractPrice',
        key: 'contractPrice'
      },
      {
        title: t('on request'),
        dataIndex: 'onRequest',
        key: 'onRequest',
        render: item => item ? <Icon type="check" /> : <Icon type="close" />
      },
      {
        title: t('Service level'),
        dataIndex: 'serviceLevel',
        key: 'serviceLevel'
      }
    ];

    return <div className={"full-size bg-white overflow-scroll-y"}>
      <Table
        columns={serviceContractProductColumns}
        dataSource={serviceContractProducts}
        rowClassName={this._getRowClassName}
        pagination={{pageSize: 10, style: {paddingRight: '10px'}}}
        expandedRowRender={
          item => _.get(item, 'serviceContractDefaultServiceTasks').length > 0 ?
            <Table
              style={{marginTop: '5px', marginBottom: '5px'}}
              columns={serviceContractDefaultServiceTasksColumns}
              dataSource={_.get(item, 'serviceContractDefaultServiceTasks')}
              pagination={false}
            />
            :
            null
        }
      />
    </div>;
  }
}

export default compose(
  withTranslation(),
  graphql(ServiceContractQuery, {
    name: 'serviceContractQuery',
    options: props => ({
      variables: {
        id: _.get(props, 'match.params.id'),
        params: {
          sort: {
            componentCount: 'DESC'
          }
        }
      }
    })
  }),
  waitWhileLoading('serviceContractQuery', 'serviceContract')
)(ServiceContractProductList)
