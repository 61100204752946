// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";

import createProduct from "./../../graphql/mutations/createProduct.graphql";
import type {Query} from "../../types";
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import {withTranslation} from "react-i18next";
import ProductListQuery from "../../graphql/queries/productList.graphql";

type Props = {
    createProduct: Function,
    productTypeDefinitionAttributeList: Query,
    onSaved: Function,
    t: Function,
}

class ProductCreate extends Component<Props> {

    render() {

        const {createProduct, onSaved, t} = this.props;

        return <TypeDefinitionAttributesForm
            index={'product'}
            formProps={{
                useSubmit: true,
                submitTitle: t('Create'),
                mutation: createProduct,
                onSubmit: (res) => {
                    onSaved(res);
                },
                translateMutationOptions: (values) => {
                    return {
                        variables: {
                            input: values.values
                        }
                    }
                }
            }}
        />
    }
}

export default compose(
    withTranslation(),
    graphql(createProduct, {
        name: 'createProduct',
        options: (props: Props) => ({
            refetchQueries: [{
                query: ProductListQuery,
                variables: {
                    params: {
                        limit: 30,
                        start: 0,
                    }
                }
            }]
        }),
    })
)(withRouter(ProductCreate));
