// @flow
import React, {Component, ReactNode} from 'react';
import _ from "lodash";
import {AutoComplete, Input} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import type {QueryConfig} from "../../types";
import {compose} from "recompose";
import {Query} from '@apollo/client/react/components';
import {withTranslation} from "react-i18next";

const {Option} = AutoComplete;

type Props = {
    inputProps?: any,
    renderOption?: (item: any) => ReactNode,
    queryConfig: QueryConfig,
    onSelect: (value: any) => Promise<any>,
    submitIcon?: string,
    useLocalSearch?: boolean,
};

type State = {

}

class QuerySelect extends Component<Props, State> {

    state = {
        search: null,
        adding: false,
    };

    debounceSearch = _.debounce((search) => {
        this.setState({
            search
        })
    }, 500);

    onSelect = (value) => {

        this.setState({
            text: null,
            adding: true,
        }, () => {
            this.props.onSelect(value).finally(() => {
                this.setState({
                    adding: false
                })
            })
        })

    };

    renderOption = (item) => {

        const {queryConfig, renderOption} = this.props;

        return (
            <Option key={_.get(item, queryConfig.valueKeyPath)} text={_.get(item, queryConfig.titleKeyPath)}>
                {
                    renderOption ? renderOption(item) : <span>{_.get(item, queryConfig.titleKeyPath)}</span>
                }
            </Option>
        );
    };

  render() {
    const { queryConfig, t, inputProps, className, submitIcon, useLocalSearch } = this.props;
    const { search, text } = this.state;

      return <Query query={queryConfig.query} variables={queryConfig.getQueryOptions ? queryConfig.getQueryOptions({
          query: search
      }) : queryConfig.variables}>
          { ({data}) => {

              let dataSource = _.get(data, queryConfig.dataKeyPath, []);
              if (useLocalSearch && search && search.length){
                  dataSource = dataSource.filter((item)=> {
                      return _.get(item, queryConfig.titleKeyPath,'').includes(search)
                  })
              }

              return <AutoComplete
                  style={{ width: '100%' }}
                  className={className}
                  dataSource={dataSource.map(this.renderOption)}
                  onSelect={this.onSelect}
                  onSearch={(search) => {
                      this.setState({
                          text: search
                      });
                      this.debounceSearch(search)
                  }}
                  filterOption={false}
                  value={text}
                  placeholder={t("Add")}
                  optionLabelProp="text"
              >
                  { submitIcon ? <Input
                          suffix={
                              <Button
                                  loading={this.state.adding}
                                  className="search-btn"
                                  style={{ marginRight: -12 }}
                                  type="primary"
                              >
                                  <Icon type={submitIcon} />
                              </Button>
                          }
                      /> : null }

              </AutoComplete>
          }}
      </Query>
  }
}

export default compose(
    withTranslation()
)(QuerySelect);
