import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import {Button} from "@dreebit/react-components";
import ComponentTypeCreateServiceReportConfig from "../ComponentTypeCreateServiceReportConfig";

interface Props {
    componentTypeIndex: string,
    onCreate: () => void
}

const ComponentTypeServiceReportAttributeCreateButton = ({onCreate, componentTypeIndex}: Props) => {

    const {t} = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const hide = () => setShowModal(false);
    const show = () => setShowModal(true);

    const modal = <>
        <Modal destroyOnClose visible={showModal} onCancel={hide} title={t("New attribute")} footer={null}>
            <ComponentTypeCreateServiceReportConfig
                index={componentTypeIndex}
                onCreateServiceReportConfig={(index, res) => {
                    setShowModal(false);
                    if (onCreate) {
                        onCreate(res);
                    }
                }}
            />
        </Modal>
        <Button onClick={show}> {t('Add')}</Button>
    </>;

    return modal
};

export default ComponentTypeServiceReportAttributeCreateButton;
