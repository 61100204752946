// @flow
import moment from "moment";

export function getCurrentMonthRange(date: Date = new Date(), addionalDays: number = 0, openEnd?: boolean): Date[] {
    let start = moment(date);
    let end = moment(date);

    if (!start.isValid()) {
        start = moment();
        end = moment();
    }

    if (openEnd) return [start.startOf('month').subtract(addionalDays, 'days').toDate(), end.endOf('month').add(2, 'years').toDate()];

    return [start.startOf('month').subtract(addionalDays, 'days').toDate(), end.endOf('month').add(addionalDays, 'days').toDate()]
}

export function hoursToString(hours: number, t?: Function) {
    const now = moment();
    const time = moment().subtract(hours, 'hours');
    const intervals = ['years', 'months', 'days', 'hours'];

    const out = [];

    for (let i = 0; i < intervals.length; i++) {
        const diff = time.diff(now, intervals[i]);
        if (diff !== 0) {
            now.add(diff, intervals[i]);
            let name = intervals[i];
            if (t) {
                if (name === "days") {
                    name = "days2"
                }
                name = t(name);
            }

            out.push(Math.abs(diff) + ' ' + name);
        }

    }
    return out.join(', ');
}
