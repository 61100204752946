// @flow
import React, {Component} from 'react';
import QueryTable from '../QueryTable';
import _ from 'lodash';
import ServiceReportListQuery from "../../graphql/queries/serviceReportList.graphql";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import withResources from "../../hoc/withResources";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {
  serviceReportListQuery: query,
  typeDefinitionAttributeListQuery: any,
  t: Function,
  onServiceReportRowClick: (row: any) => void
};

class ServiceReportList extends Component<Props> {
  componentDidMount() {
  }

  render() {
    const {serviceReportListQuery, onServiceReportRowClick, t, typeDefinitionAttributeListQuery} = this.props;

    const columns = [
      {
        dataIndex: 'name',
        key: 'name',
        title: t('Name')
      },
      {
        dataIndex: 'update',
        key: 'update',
        title: t('Last updated'),
        render: (text) => moment(text).format('L LT')
      },
      {
        dataIndex: 'updatedBy.name',
        key: 'updatedBy.name',
        title: t('updated by')
      },
      {
        dataIndex: 'serviceReportType',
        key: 'serviceReportType',
        title: t('Type'),
        render: (text) => {
          const typeDefinitionAttributes = _.get(typeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);

          const serviceReportTDA = _.find(typeDefinitionAttributes, {index: 'serviceReportType'});
          if (serviceReportTDA) {
            const options = _.get(serviceReportTDA, 'optionValues', []);
            const value = _.find(options, {value: text});

            if (value) {
              return _.get(value, 'name');
            }
          }

          return text;
        }
      }
    ]

    return <div>
      <QueryTable
        rowKey={"id"}
        onRow={(record) => ({onClick: () => onServiceReportRowClick(record)})}
        query={serviceReportListQuery}
        itemsKeyPath={'serviceReportList.serviceReports'}
        totalKeyPath={'serviceReportList.total'}
        columns={columns}
      />
    </div>;
  }
}

export default compose(
  withResources(["serviceReports"]),
    withTranslation(),
    withRouter,
    graphql(ServiceReportListQuery, {
      name: 'serviceReportListQuery',
      options: (props: Props) => ({
        variables: {
          serviceTicketId: _.get(props, 'match.params.id')
        }
      }),
    }),
    waitWhileLoading('serviceReportListQuery', ["serviceReportList.serviceReports"]),
    graphql(TypeDefinitionAttributeListQuery, {
      name: 'typeDefinitionAttributeListQuery',
      options: () => ({
        variables: {
          index: 'serviceReport'
        }
      })
    }),
    waitWhileLoading('typeDefinitionAttributeListQuery', ["typeDefinitionAttributeList.typeDefinitionAttributes"]),
)(ServiceReportList);
