// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import InvoiceDetailQuery from "../../graphql/queries/invoiceDetail.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import withResources from "../../hoc/withResources";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import moment from "moment";
import {Table} from "antd";
import ContentHeader from "../Typo/ContentHeader";
import Panel from "../Panel";
import Empty from "../Empty";
import {bytesToSize} from "../../utils/fileSize"
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

type Props = {
  invoiceId: string,
  t: Function
};

class InvoiceDetail extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { invoiceId, invoiceDetailQuery, t } = this.props;
    const invoice  = _.get(invoiceDetailQuery, 'invoice', null);
    const invoiceFiles = _.get(invoiceDetailQuery, 'invoice.invoiceFileList.invoiceFiles', {});

    const dateRenderer = text => moment(text).format('L');
    const currencyRenderer = text => text ? `${String(text).replace('.',',')} €` : '';

    const invoiceDetailColumns = [
      {
        dataIndex: 'begin',
        title: t('Start'),
        key: 'begin',
        width: '10%',
        render: dateRenderer
      },
      {
        dataIndex: 'end',
        title: t('End'),
        key: 'end',
        width: '10%',
        render: dateRenderer
      },
      {
        dataIndex: 'amountBasicCharge',
        title: t('Basic charges in Euro'),
        key: 'amountBasicCharge',
        render: currencyRenderer
      },
      {
        dataIndex: 'amountComponent',
        title: t('Component charges in Euro'),
        key: 'amountComponent',
        render: currencyRenderer
      },
      {
        dataIndex: 'amountServiceTicket',
        title: t('Service Ticket charges in Euro'),
        key: 'amountServiceTicket',
        render: currencyRenderer
      },
      {
        dataIndex: 'amountTotal',
        title: t('Amount in Euro'),
        key: 'amountTotal',
        width: '10%',
        render: currencyRenderer
      },
    ];

    const invoiceFileColumns = [
      {
        dataIndex: 'filename',
        title: t('File name'),
        key: 'filename',
        render: (text, item) => {return <a href={_.get(item, 'link')} target="_blank" className="text-dark">{text}</a>}
      },
      {
        dataIndex: 'contentType',
        title: t('Type'),
        key: 'id',
        width: '10%'
      },
      {
        dataIndex: 'size',
        title: t('Size'),
        key: 'size',
        width: '10%',
        render: text => bytesToSize(text)
      },
      {
        width: '5%',
        render: item => { return <a href={_.get(item, 'link')} target="_blank" className="text-dark">
          <MaterialCommunityIcon name="download" size="20px"/>
        </a>}
      }
    ];

    return <div>
      <ContentHeader title={invoice.name}/>
      <div className="mh-15">
        <Panel title={t('Periods')}>
          {invoice ?
              <Table
                  dataSource={_.get(invoice, 'invoicePeriods')}
                  columns={invoiceDetailColumns}
                  pagination={false}
              />
              : <Empty/>}
        </Panel>
      </div>

      <div className="mh-15">
        <Panel title={t('Files')}>
          {invoiceFiles ?
              <Table
                  dataSource={_.get(invoice, 'invoiceFileList.invoiceFiles')}
                  columns={invoiceFileColumns}
                  pagination={false}
              />
              : <Empty/>
          }
        </Panel>
      </div>


    </div>;
  }
}

export default compose(
  withResources('accounting'),
  withRouter,
  withTranslation(),
  graphql(InvoiceDetailQuery, {
    name: 'invoiceDetailQuery',
    options: props => ({
      variables: {
        invoiceId: props.invoiceId
      }
    }),
  }),
  waitWhileLoading('invoiceDetailQuery', 'invoice')
)(InvoiceDetail);
