// @flow
import React, {Component} from 'react';
import ContentHeader from "../Typo/ContentHeader";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceControlDashboardQuery from "./../../graphql/queries/serviceControl.graphql";
import Panel from "../Panel";
import ServiceControlForm from "./../ServiceControlForm";
import {Col, Popconfirm, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ServiceControlPerformAction from "./../ServiceControlPerformAction";
import DeleteServiceControlMutation from "../../graphql/mutations/deleteServiceControl.graphql";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import ServicePlanAndControlQuery from "../../graphql/queries/servicePlanAndControlList.graphql";

type Props = {
    serviceControlId: string,
    componentId: string,
    serviceControlDashboardQuery: any,
};


class ServiceControlDashboard extends Component<Props, State> {

    handleDelete = () => {
        const {deleteServiceControlMutation, onDelete} = this.props;

        deleteServiceControlMutation().then((res) => {
            if (onDelete) {
                onDelete(res);
            }
        })
    };

    render() {
        const {serviceControlDashboardQuery, serviceControlId, t} = this.props;

        return <div>
            <ContentHeader
                title={_.get(serviceControlDashboardQuery, 'serviceControl.name')}
                tools={<Popconfirm
                    title={t('Please confirm to delete this item')}
                    icon={<Icon type="delete" style={{color: 'red'}}/>}
                    onConfirm={this.handleDelete}
                    okText={t('Confirm')}
                    cancelText={t('Cancel')}
                >
                    <Button icon="delete" type="danger">{t('Remove')}</Button>
                </Popconfirm>}
            />
            <Row gutter={16} className={'ph-20'}>
                <Col md={16}>
                    <Panel title={t('Detail')}>
                        <ServiceControlForm serviceControlId={serviceControlId}/>
                    </Panel>
                </Col>
                <Col md={8}>
                    <div style={{marginTop: 16}}>
                        <h3>{t('Was the inspection okay?')}</h3>
                    </div>
                    <ServiceControlPerformAction serviceControlId={serviceControlId}/>
                </Col>
            </Row>

        </div>;
    }
}

export default compose(
    graphql(DeleteServiceControlMutation, {
        name: 'deleteServiceControlMutation',
        options: props => ({
            variables: {
                serviceControlId: props.serviceControlId
            },
            refetchQueries: [{
                query: componentQuery,
                variables: {
                    id: props.componentId
                }
            }, {
                query: ServicePlanAndControlQuery,
                variables: {
                    params: {
                        filter: {
                            componentId: {
                                value: props.componentId,
                            }
                        }
                    }
                }
            }]
        })
    }),
    graphql(ServiceControlDashboardQuery, {
        name: 'serviceControlDashboardQuery',
        options: (props: Props) => ({
            variables: {
                serviceControlId: props.serviceControlId
            }
        })
    }),
    waitWhileLoading('serviceControlDashboardQuery'),
    withTranslation(),
)(ServiceControlDashboard);

