// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import './index.css';
import _ from "lodash";
import {Tabs} from "antd";
import {Icon} from "@dreebit/react-components";

const TabPane = Tabs.TabPane;

const defaultTabKeys = [{
    index: "search",
    icon: 'search'
}];

type Props = {
    activeKey?: string,
    t: Function,
    onClick: Function
};

type State = {

}

class ServiceSearchTab extends Component<Props, State> {
  componentDidMount() {  }

  state = {  };

  render() {
      const {t, profileQuery, activeKey, onClick} = this.props;
      const {} = this.state;


      let tabs = defaultTabKeys.map((key) => (
              <TabPane key={_.get(key, 'index')} tab={<Icon type={_.get(key, 'icon')}/>}
                       closable={false}/>
          )
      );

      return <div>
          <Tabs
              className="tabs-no-bottom-margin tabs-top-padding full-height serviceTicketSearchTab"
              hideAdd
              onTabClick={onClick}
              activeKey={activeKey}
            type="editable-card"
        >
            {tabs}
        </Tabs>
    </div>;
  }
}

export default compose(
    withTranslation()
)(ServiceSearchTab);
