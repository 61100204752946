// @flow
import React, {Component} from 'react';
import {Button} from "@dreebit/react-components";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ContentHeader from "../../components/Typo/ContentHeader";
import withResources from "../../hoc/withResources";
import NewsTable from "../../components/NewsTable";

type Props = {
    t: Function,
};


class NewsListRoute extends Component<Props, State> {


    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader
                title={t('News')}
                tools={<Link to={"/admin/news/new"}>
                    <Button>
                        {t('Add')}
                    </Button>
                </Link>}
            />
            <div style={{width: '100%', height: 'calc(100vh - 150px)', overflow: 'scroll', padding: 10}}>
                <NewsTable/>
            </div>
        </div>;
    }
}

export default withTranslation()(withResources(["newsEditor"])(NewsListRoute));
