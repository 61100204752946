// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import IconSplitPanelTCOQuery from "../../graphql/queries/iconSplitPanelTCO.graphql"
import _ from "lodash";
import SplitPanelList from "../SplitPanelList";
import withResources from "../../hoc/withResources";
import {addMonths} from "../ComponentTCOHistoryChart";

type Props = {
    t: Function,
    componentId: string,
    iconSplitPanelNotesQuery: any
}


class IconSplitPanelTCO extends Component<Props> {

    render() {

        const {t, iconSplitPanelNotesQuery, componentId} = this.props;
        const total = _.chain(iconSplitPanelNotesQuery).get('component.tco.componentTcoValueList.tcoValues').first().get('value').value();



        return <SplitPanelList
            items={[
                {
                    link: `/infrastructure/${componentId}/tco`,
                    largeTitle: total,
                    title: total ? t('TCO', {count: total}) : t('Total cost of ownership'),
                    description: total ? t('Total cost of ownership') : t('Click here to view the calculation of tco'),
                    icon: "euro-sign",
                }
            ]}
        />

    }
}


export default compose(
    withResources(''),
    withTranslation(),
    graphql(IconSplitPanelTCOQuery, {
        name: 'iconSplitPanelTCOQuery',
        options: (props: Props) => ({
            variables: {
                componentId: props.componentId,
                filter: {
                    date: {
                        operator: "range",
                        range: {
                            from: addMonths(new Date(), -1).toISOString(),
                            to: new Date().toISOString()
                        }
                    }
                }
            }
        })
    })
)(IconSplitPanelTCO);


