import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Error404 from './error404';
import Error500 from './error500'

type Props = {};

type State = {};

class ErrorPagesRoute extends Component<Props, State> {

    render() {

        return <Switch>
            <Route exact path="/error/404" component={Error404}/>
            <Route exact path="/error/500" component={Error500}/>
        </Switch>;
    }
}

export default ErrorPagesRoute;