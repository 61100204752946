// @flow
import React, {Component} from 'react';
import {Waypoint} from 'react-waypoint';
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import styled from "styled-components";
import type {Query as QueryType} from "../../types";
import Empty from "../Empty";
import Colors from "../../themes/default/colors";
import {Input, List} from "antd";
import {Icon} from "@dreebit/react-components";
import Loader from "../Loader";
import {withApollo} from "@apollo/client/react/hoc";
import {compose} from "recompose";
import statics from "../../statics";
import NavigationContainer from "../NavigationContainer";

type Props = {
    query: QueryType,
    queryProps: {
        variables: any,
        fetchPolicy?: string,
        notifyOnNetworkStatusChange?: boolean
    },
    style?: any,
    bordered?: boolean,
    searchAddonAfter?: any,
    itemsKeyPath: string,
    totalKeyPath: string,
    renderItem: (item: any, index: number) => any,
    pagingSize: ?number,
    limitKeyPath: ?string,
    startKeyPath: ?string,
    searchKeyPath: ?string,
    searchText:? string,
    onSearchTextChanged?: (searchText: string) => void,
    listProps?: any,
    showSearchBar?: boolean,
    uniqueIdentifier?: any,
    scrollContainerRef?: any,
};

type State = {
    items: any[],
    total: number,
    loading: boolean,
    loadingMore: boolean
}

const ListWrapper = styled.div`
    background: ${Colors.white};
    height: 100%;
`;

class QueryListAdvanced extends Component<Props, State> {

    static defaultProps = {
        pagingSize: statics.defaultPagingSize,
        limitKeyPath: 'params.limit',
        startKeyPath: 'params.start',
        searchKeyPath: 'params.search.query',
        queryProps: {},
        uniqueIdentifier: 'id'
    };

    state = {
        items: [],
        total: -1,
        loading: false,
        loadingMore: false,
        searchText: null,
    };

    currentRequestId = null;

    constructor(props) {
        super(props);
        this._debounceSearchTextChanges = _.debounce((text) => {
            if (this.props.onSearchTextChanged) {
                this.props.onSearchTextChanged(text);
            }
        }, 1000);
        this._debounceRefetch = _.debounce(this.refetch, 1100)
    }


    componentDidUpdate(prevProps) {
        if (this.props.searchText && (JSON.stringify(prevProps.queryProps) !== JSON.stringify(this.props.queryProps))){
            this.setState({
                searchText: this.props.searchText
            })
        }

        if (prevProps.searchText !== this.props.searchText) {
            this.setState({
                searchText: this.props.searchText
            })
        }
    }



    _getVariablesForPage = (start, limit) => {
        const variables = Object.assign({}, _.get(this.props.queryProps, 'variables', {}));
        _.set(variables, this.props.limitKeyPath, limit);
        _.set(variables, this.props.startKeyPath, start);
        return variables;
    };

    _handleWaypointEnter = () => {

        const {total, items} = this.state;
        const length = items ? items.length : -1;

        if (length < total && total > 0) {

           this.fetchMore(length)


        }
    };

    _handleWaypointLeave = () => {

    };

    _handleSearch = (e) => {

        const {queryProps} = this.props;

        const text = _.get(e, 'currentTarget.value');
        this.setState({
            searchText: text
        });

        this._debounceSearchTextChanges(text);

        const variables = {
            ...queryProps.variables,
        };
        _.set(variables, this.props.searchKeyPath, text);

        this._debounceRefetch(variables)
    };

    componentDidMount(){
        this.refetch()
    }

    shouldComponentUpdate(nextProps) {
        let oldQueryProps = _.omit(_.cloneDeep(this.props.queryProps), [`variables.${this.props.limitKeyPath}`, `variables.${this.props.startKeyPath}`]);
        let nextQueryProps = _.omit(_.cloneDeep(nextProps.queryProps), [`variables.${this.props.limitKeyPath}`, `variables.${this.props.startKeyPath}`]);

        if (JSON.stringify(nextQueryProps) !== JSON.stringify(oldQueryProps)){
            this.refetch();
        }

        return true;
    }

    clearSearch = () => {
        this.setState({
            searchText: null
        })
    };

    refetch = () => {
        this.setState({
            loading: true,
            items: [],
        }, () => {
            this.fetchMore(0);
        })

    };

    fetchMore = (start) => {
        const variables = this._getVariablesForPage(start, this.props.pagingSize);

        const {client, itemsKeyPath, totalKeyPath, query, uniqueIdentifier, queryProps} = this.props;
        const {items}= this.state;

        let loadingUpdate = {
            loading: false,
            loadingMore: true
        };

        if (start === 0){
            loadingUpdate = {
                loading: true,
                loadingMore: false
            }
        }

        const me = this;

        this.setState(loadingUpdate, () => {

            me.currentRequestId = _.uniqueId('QueryListAdvancedRequest_');
            const currentRequestId = `${me.currentRequestId}`;
            client.query({
                query,
                ...queryProps,
                variables
            }).then((q)=> {

                if (me.currentRequestId === currentRequestId){
                    this.setState({
                        items: _.uniqBy([...items, ..._.get(q, `data.${itemsKeyPath}`)], uniqueIdentifier),
                        total: _.get(q, `data.${totalKeyPath}`,-1),
                        loading: false,
                        loadingMore: false
                    })
                }

            }).catch(() => {
                this.setState({
                    loading: false,
                    loadingMore: false
                })
            })
        })

    };

    render() {
        const {searchAddonAfter, renderItem, bordered, style, listProps, showSearchBar, t, scrollContainerRef} = this.props;

        const {items, loading, loadingMore, total} = this.state;

        return <div style={style}><ListWrapper>
            <NavigationContainer scroll navigationBar={showSearchBar ? <div className={"bb"}>
                <Input className={"no-border"}
                       data-cy={'TEST_queryListAdvancedSearchInput'}
                       allowClear
                       value={this.state.searchText}
                       suffix={!searchAddonAfter ? <Icon type={"search"}/> : searchAddonAfter}
                       onChange={(e) => this._handleSearch(e, this.refetch)}
                       placeholder={t("Search")}/>
            </div> : null}>
                <div ref={scrollContainerRef}>
                    {loading && !loadingMore ?
                        <Loader size={'small'}/> : items.length ?
                            <List
                                {...listProps}
                                bordered={bordered}
                            >
                                {items.map(renderItem)}
                                {!loading && items && items.length && total && items.length < total ? <div style={{height: 1}} className={'wa'}>
                                    <Waypoint
                                        bottomOffset={-200}
                                        onEnter={() => {
                                            this._handleWaypointEnter(this.fetchMore, items)
                                        }}
                                        onLeave={this._handleWaypointLeave}
                                    /></div> : null}
                            </List> : <Empty/>}




                    { loadingMore ? <Loader size={"small"}/> : null}
                </div>
            </NavigationContainer>
        </ListWrapper></div>;
    }
}


export default compose(
    withTranslation(),
    withApollo,
)(QueryListAdvanced);
