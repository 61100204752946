// @flow
import React, {Component} from 'react';
import {compose, withProps} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import Query from "../../graphql/queries/componentTypeDefaultServiceTaskList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import _ from "lodash";
import DefaultServiceTasksTable from "../DefaultServiceTasksTable";

type Props = {
    componentTypeIndex: string
};

type State = {

}

class ComponentTypeDefaultServiceTasksTable extends Component<Props, State> {

  render() {
    const { items } = this.props;

    return <DefaultServiceTasksTable
        items={items}
    />;
  }
}

export default compose(
    graphql(Query,{
        options: (props) => ({
            variables: {
                componentTypeIndex: props.componentTypeIndex
            }
        })
    }),
    waitWhileLoading("data"),
    withProps((props)=>({
        items: _.get(props,'data.defaultServiceTaskList.defaultServiceTasks')
    }))
)(ComponentTypeDefaultServiceTasksTable);
