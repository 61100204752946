// @flow
import React, {Component} from 'react';
import {Col, Row} from "antd";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ProductImageQuery from "../../graphql/queries/productImage.graphql";
import UpdateProductMutation from "./../../graphql/mutations/updateProduct.graphql";
import _ from "lodash";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import "./index.css"
import AvatarLightBox from "../AvatarLightBox";
import {CodeSandboxOutlined} from "@ant-design/icons";

type Props = {
    productId: string,
    size?: number
};

type State = {

}


class ProductImageForm extends Component<Props, State> {

    static defaultProps = {
        size: 100,
    };

  render() {
    const { productImageQuery, updateProductMutation, productId, t } = this.props;

      const attributeFilter = _.get(productImageQuery, 'product.typeDefinitionAttributeList.typeDefinitionAttributes',[]).filter(item => item.index === 'imageId').map((item) => {
          return {
              ...item,
              onUpload: (file: any) => {
                  const fileId = _.get(file, 'response.fileId');
                  if (file && file.status === 'done' && fileId) {
                      updateProductMutation({
                          variables: {
                              id: productId,
                              attributes: {
                                  imageId: fileId
                              }
                          }
                      })
                  }
              },
              formItemLayout: {
                  colon: false,
                  labelCol: {
                      md: {
                          span: 0
                      }
                  },
                  wrapperCol: {
                      md: {
                          span: 24
                      }
                  }
              },
          }
      });

    return <div>
        <Row>
           <Col className={"text-center"} md={6}>
               <AvatarLightBox
                   shape={'square'}
                   icon={<CodeSandboxOutlined/>}
                   size={this.props.size}
                   thumbnail={_.get(productImageQuery,'product.image.thumbnailLink')}
                   src={_.get(productImageQuery,'product.image.link')}

               />
           </Col>
            <Col className={"product-image-uploader"} md={16}>

                <AttributesInlineForm
                    attributes={attributeFilter}
                    useSubmit={false}
                    t={t}
                />
            </Col>
        </Row>
    </div>;
  }
}


export default compose(
    withTranslation(),
    graphql(ProductImageQuery,{
        name: 'productImageQuery',
        options: (props) => ({
            variables: {
                id: props.productId,
            }
        })
    }),
    waitWhileLoading('productImageQuery'),
    graphql(UpdateProductMutation, {
        name: 'updateProductMutation'
    })
)(ProductImageForm);
