// @flow
import React, {Component} from 'react';
import _ from "lodash";
import ServiceActionsTable from "../../components/ServiceActionsTable";
import {Link, withRouter} from "react-router-dom";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Button} from "@dreebit/react-components";
import Toolbar from "../../components/Toolbar";


class ServiceTicketActionsRoute extends Component<Props, State> {


    render() {
        const {match, t} = this.props;
        const ticketId = _.get(match, 'params.id');

        return <div>
            <Toolbar
                renderLeft={() => <h3>{t('Service Actions')}</h3>}
                renderRight={() => <Link to={`/service/${ticketId}/actions/add`}><Button>{t('Add')}</Button></Link>}/>
            <ServiceActionsTable serviceTicketId={ticketId}/>
        </div>
    }
}

export default compose(
    withRouter,
    withTranslation()
)(ServiceTicketActionsRoute);
