// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import styled from "styled-components";

import ClientInfoQuery from "./../../graphql/queries/ClientInfoBox.graphql";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Grow, Row} from "../Flex";
import Empty from "./../Empty";

type Props = {
    clientId: string,
    clientInfoQuery: any,
};

const LargeLabel = styled.span`
  font-weight: 600;
`;

const IconRow = styled.div`
  margin-bottom: 4px;
`;

class ClientInfoBox extends Component<Props, State> {

    render() {
        const {clientInfoQuery} = this.props;

        const client = _.get(clientInfoQuery, 'client', {});
        const customerId = _.get(clientInfoQuery, 'client.customerId');

        if (!Object.keys(client).length) {
            return <Empty/>
        }

        return <div>
            <IconRow>
                <LargeLabel>
                    <FontAwesomeIcon name={'building'}/>
                    <span style={{paddingLeft: 5}}>
          {_.get(clientInfoQuery, 'client.name')}
          </span>
                </LargeLabel>
                <span>{customerId ? ` (${customerId})` : null}</span>
            </IconRow>
            <IconRow>
                <Row><FontAwesomeIcon name="map-marker-alt"/> <Grow style={{paddingLeft: 9}}>
                    <div>{_.get(clientInfoQuery, 'client.street')}</div>
                    <div>{_.get(clientInfoQuery, 'client.zip')}, {_.get(clientInfoQuery, 'client.city')}</div>
                    <div>{_.get(clientInfoQuery, 'client.country')}</div>
                </Grow>
                </Row>
            </IconRow>
            {client.contactName ? <IconRow>
                <FontAwesomeIcon name={'user'}/> <span style={{paddingLeft: 4}}> <a
                href={`mailto:${client.contactEmail}`}>{client.contactName}</a>
        </span>
            </IconRow> : null}
            {client.contactPhone ? <IconRow>
                <FontAwesomeIcon name={'phone'}/> <span style={{paddingLeft: 3}}> {client.contactPhone} </span>
            </IconRow> : null}

        </div>;
    }
}

export default compose(
    graphql(ClientInfoQuery, {
        name: 'clientInfoQuery',
        options: (props: Props) => ({
            variables: {
                id: props.clientId,
            }
        }),
    }),
    waitWhileLoading('clientInfoQuery'),
    withTranslation()
)(ClientInfoBox);
