// @flow
import React, {Component} from "react"
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import Products from "./products";
import Product from "./product";
import AddProduct from "./add-product";
import ManufacturersMasterDetail from "./manufacturersMasterDetail";
import ComponentTypesMasterDetail from "./componentTypesMasterDetail";
import AttributesRoute from "./attributes";
import withResources from "../../hoc/withResources";
import ErrorPatternsRoute from "./errorPatterns";
import Error404 from "../errorPages/error404";
import CatalogCategoriesRoute from "./categories";

class CatalogRoute extends Component {

    render() {
        return <Switch>
            <Route path="/catalog/products/add" component={AddProduct}/>
            <Route path="/catalog/products/:id/:sub" component={Product}/>
            <Route path="/catalog/products/:id" component={Product}/>
            <Route path="/catalog/products" component={Products}/>
            <Route path="/catalog/manufacturer" component={ManufacturersMasterDetail}/>
            <Route path="/catalog/componentTypes/detail/:index" component={ComponentTypesMasterDetail}/>
            <Route path="/catalog/attributes" component={AttributesRoute}/>
            <Route path="/catalog/errorPatterns" component={ErrorPatternsRoute}/>
            <Route path="/catalog/categories" component={CatalogCategoriesRoute}/>
            <Redirect exact from="/catalog/products/:id/defaultServiceTasks"
                      to="/catalog/products/:id/defaultServiceTasks/create"/>
            <Redirect exact from="/catalog" to="/catalog/products"/>
            <Redirect exact from="/catalog/componentTypes" to="/catalog/componentTypes/detail/machine"/>
            <Redirect exact from="/catalog/componentTypes/detail" to="/catalog/componentTypes/detail/machine"/>
            <Route component={Error404}/>
        </Switch>;
    }

}

export default compose(
    withResources('catalogEditor'),
    withTranslation(),
    withRouter,
)(CatalogRoute);
