//@flow

import React, {Component} from "react"
import FontAwesomeIcon from '../Icons/FontAwesomeIcon';
import LogLevelIconFactory from '../../utils/LogLevelIconFactory';
import type {LogItem} from "../../types";
import moment from 'moment'
import './index.css';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import RemoveLogEntryMutation from "./../../graphql/mutations/removeLog.graphql"
import UpdateLogEntryMutation from "./../../graphql/mutations/updateLog.graphql"
import LogDetailAttachment from '../LogDetailAttachment'
import UserLabel from '../UserLabel';
import {Icon} from "@dreebit/react-components";
import {Grow} from "../Flex";
import MutationButton from "../MutationButton";
import Editable from "../Editable";
import ReactMarkdown from "react-markdown";


type Props = {
    log: LogItem,
    onRemoved: () => void,
    componentId: string,
    refetchQueries?: any[]
};

class LogEntryDetailed extends Component<Props> {


    render() {

        const {
            log,
            removeLogEntryMutation,
            onRemoved,
            updateLogEntryMutation,
            componentId,
            refetchQueries
        } = this.props;
        const {insert, actionKey, level, title, message, user, access, id} = log;

        const type = LogLevelIconFactory.getIcon(actionKey);
        const levelColor = LogLevelIconFactory.getColor(level);
        const levelBackgroundColor = LogLevelIconFactory.getBackgroundColor(level);

        return <div className="log-card-wrapper">
            <div className="log-icon">
                <FontAwesomeIcon name={type} circled={true} className={levelColor} size={'20px'}/>
            </div>
            <div className="log-card">
                <div className={`log-head flex-row ${level} ${levelBackgroundColor}`}>
                    <Grow>
                        <span className="log-title"><b>{title}</b></span>
                    </Grow>
                    {access && access.remove ?
                        <MutationButton buttonProps={{type: 'link'}} mutation={() => removeLogEntryMutation({
                            variables: {
                                id
                            }
                        }).then(() => {
                            if (onRemoved) {
                                onRemoved();
                            }
                        })}><Icon type="rest" style={{color: 'white'}}/></MutationButton> : null}
                </div>
                <div className="log-body">
                    <div className="log-content">
                        {
                            access && access.write
                                ? <Editable inputContainerStyles={{display: 'inline-block', width: "100%"}}
                                            onChange={(value) => {
                                                return updateLogEntryMutation({
                                                    variables: {
                                                        logId: id,
                                                        input: {
                                                            message: value
                                                        }
                                                    }
                                                })
                                            }} value={message} enableClick renderDisplay={(value) => value}/>
                                : actionKey === 'productNote'
                                    ? <ReactMarkdown children={message}/>
                                    : message
                        }
                    </div>
                    <div className="log-attachment">
                        <LogDetailAttachment log={log} componentId={componentId} refetchQueries={refetchQueries}/>
                    </div>
                </div>

                <div className="log-footer">
                    <span className="log-user-date">
                        <UserLabel userInfo={user} showIcon={true}/> - {moment(insert).format('LLL')}
                    </span>
                </div>
            </div>
        </div>

    }
}

export default compose(
    graphql(UpdateLogEntryMutation, {
        name: 'updateLogEntryMutation',
        options: (props: Props) => ({
            refetchQueries: props.refetchQueries
        })
    }),
    graphql(RemoveLogEntryMutation, {
        name: 'removeLogEntryMutation',
        options: (props: Props) => ({
            refetchQueries: props.refetchQueries
        })
    })
)(LogEntryDetailed)
