// @flow
import React, {Component} from "react"
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose, onlyUpdateForKeys, withProps} from "recompose";
import ComponentDashboard from "./../../components/ComponentDashboard";
import ComponentDashboardHeader from "../../components/ComponentDashboardHeader";
import ComponentFormsRoute from "./componentForms";
import ComponentFilesRoute from "./componentFiles";
import ComponentServicePlans from "../../components/ComponentServicePlans";
import ComponentServicePlan from "./componentServicePlan";
import ComponentServiceControl from "./componentServiceControl";
import CreateServicePlan from "../../components/ServicePlanForm/create";
import ComponentAccessRoute from "./componentAccess";
import CreateServiceControl from "./componentServiceControlCreate";
import ComponentDuplicateRoute from "./componentDuplicate";
import ComponentDeleteRoute from "./componentDelete";
import ComponentMoveRoute from "./componentMove";
import ComponentServiceTicketsRoute from "./componentServiceTickets";
import ComponentServiceTicketsCreateRoute from "./componentServiceTicketsCreateRoute";
import ComponentVersionalAttributeRoute from "./componentVersionalAttributeRoute";
import componentQuery from "../../graphql/queries/component-ComponentDashboard.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentLogsRoute from "./componentLogs";
import ComponentProductChange from "./componentProductChange";
import ComponentProductDetailsDashboard from "../../components/ComponentProductDetailsDashboard"
import ComponentTCORoute from "./componentTCO";
import Error from "../../components/Error";
import ComponentTokenRoute from "./componentToken";

type Props = {
    t: Function,
    onDelete?: Function,
    match: {
        params: {
            id: string
        }
    },
}

class ComponentDashboardRoute extends Component<Props> {

    render() {


        const {componentId, getComponent} = this.props;


        if (!getComponent.component) {
            return <Error status={'404'}/>
        }

        return <div className={"full-height overflow-scroll-y"}>
            <div className={'white-bg'}>
                <ComponentDashboardHeader componentId={componentId}/>
            </div>

            <Switch>
                <Route path="/infrastructure/:id/product"
                       component={() => <ComponentProductDetailsDashboard componentId={componentId}/>}/>
                <Route path="/infrastructure/:id/details"
                       component={() => <ComponentDashboard componentId={componentId}/>}/>
                <Route path="/infrastructure/:id/logs" component={ComponentLogsRoute}/>
                <Route path="/infrastructure/:id/access" component={ComponentAccessRoute}/>
                <Route path="/infrastructure/:id/move" component={ComponentMoveRoute}/>
                <Route path="/infrastructure/:id/delete" component={ComponentDeleteRoute}/>
                <Route path="/infrastructure/:id/duplicate" component={ComponentDuplicateRoute}/>
                <Route path="/infrastructure/:id/productchange" component={ComponentProductChange}/>
                <Route path="/infrastructure/:id/forms/:index" component={ComponentFormsRoute}/>
                <Route path="/infrastructure/:id/versionalattributes/:index" component={ComponentVersionalAttributeRoute}/>
                <Route path="/infrastructure/:id/files" component={ComponentFilesRoute}/>
                <Route exact path="/infrastructure/:id/serviceplans"
                       component={() => (<ComponentServicePlans componentId={componentId}/>)}/>
                <Route path="/infrastructure/:id/serviceplans/new"
                       component={() => (<CreateServicePlan componentId={componentId}/>)}/>


                <Route path="/infrastructure/:id/serviceplans/:servicePlanId" component={ComponentServicePlan}/>
                <Route path="/infrastructure/:id/servicecontrols/new" component={CreateServiceControl}/>
                <Route path="/infrastructure/:id/servicecontrols/:serviceControlId"
                       component={ComponentServiceControl}/>
                <Redirect exact from="/infrastructure/:componentId/servicecontrols"
                          to="/infrastructure/:componentId/serviceplans"/>
                <Route path="/infrastructure/:id/servicetickets/new" component={ComponentServiceTicketsCreateRoute}/>
                <Route path="/infrastructure/:id/servicetickets" component={ComponentServiceTicketsRoute}/>
                <Route path="/infrastructure/:id/tco" component={ComponentTCORoute}/>
                <Route path="/infrastructure/:id/token" component={ComponentTokenRoute}/>
                <Redirect exact from="/infrastructure/:id/forms" to={`/infrastructure/${componentId}/forms/default`}/>
                <Redirect exact from="/infrastructure/:id" to={`/infrastructure/${componentId}/details`}/>
            </Switch>

        </div>;
    }

}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => ({
        locationURL: _.get(props, 'location.pathname') + _.get(props, 'location.search'),
        componentId: _.get(props, 'match.params.id')
    })),
    graphql(componentQuery, {
        name: 'getComponent',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('getComponent', null, {
        allowErrors: true
    }),
    onlyUpdateForKeys(
        ["componentId", "locationURL"]
    ),
)(ComponentDashboardRoute);
