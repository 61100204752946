// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";

import CreateUser from "./../../graphql/mutations/createUser.graphql";
import UserTypeDefinitionAttributeList from "./../../graphql/queries/userTypeDefinitionAttributeList.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import AttributesInlineForm from "./../Form/attributes-inline-form";
import UserListQuery from "../../graphql/queries/userList.graphql";

type Props = {
    createUserMutation: Function,
    userTypeDefinitionAttributeList: query,
    onSaved: Function
}

class UserCreateComponent extends Component<Props> {

    render() {
        const {userTypeDefinitionAttributeList, onSaved, createUserMutation} = this.props;

        return <AttributesInlineForm
            useSubmit
            onSubmit={onSaved}
            attributes={_.get(userTypeDefinitionAttributeList, "typeDefinitionAttributeList.typeDefinitionAttributes", [])}
            mutation={createUserMutation}
            translateMutationOptions={({values}) => ({
                variables: {
                    input: values
                }
            })}
        />;
    }
}

export default compose(
    graphql(UserTypeDefinitionAttributeList, {
        name: 'userTypeDefinitionAttributeList',
    }),
    waitWhileLoading('userTypeDefinitionAttributeList', ['typeDefinitionAttributeList.typeDefinitionAttributes']),
    graphql(CreateUser, {
        name: 'createUserMutation',
        options: () => ({
            refetchQueries: [{
                query: UserListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    })
)(withRouter(UserCreateComponent));
