// @flow
import React, {Component} from 'react';
import {Link, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import ContentHeader from "../../components/Typo/ContentHeader";
import {Col, Grow, Row} from "../../components/Flex";
import {Button} from "@dreebit/react-components";
import NavigationContainer from "../../components/NavigationContainer";
import ErrorPatternsList from "../../components/ErrorPatternsList";
import ErrorPatternCreateRoute from "./errorPatterns/create";
import ErrorPatternDetailRoute from "./errorPatterns/detail";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};


class ErrorPatternsRoute extends Component<Props> {



    render() {
        const {t, history} = this.props;

        return (
            <Row className={'full-size'}>
                <Col className={"br"} style={{width: "20%", minWidth: 300}}>
                    <NavigationContainer
                        navigationBar={<ContentHeader
                        hideBreadcrumb
                        title={t('Error Patterns')}
                        description={t('Common product errors')}
                        tools={<Link to={'/catalog/errorPatterns/create'}><Button
                            icon={"plus"}>{t('New')}</Button></Link>}/>}
                    >
                        <ErrorPatternsList />
                    </NavigationContainer>


                </Col>
                <Grow>
                    <Switch>
                        <Route
                            path='/catalog/errorPatterns/create'
                            component={() => (<ErrorPatternCreateRoute/>)}
                        />
                        <Route
                            path={'/catalog/errorPatterns/:id'}
                            component={ErrorPatternDetailRoute}
                        />
                        <Redirect exact from={'/catalog/errorPatterns'} to={'/catalog/errorPatterns/create'}/>
                    </Switch>
                </Grow>
            </Row>
        );
    }
}

export default compose(
    withRouter,
    withTranslation()
)(ErrorPatternsRoute);
