// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {Alert} from "antd";
import moment from "moment";
import FileDownloadBox from "../FileDownloadBox";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import WarrantyReportFinalizedQuery from "../../graphql/queries/warrantyReportFinalized.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

type Props = {

};

type State = {

}

class WarrantyReportFinalized extends Component<Props, State> {

    render() {
        const {warrantyReportFinalizedQuery, t} = this.props;

        const warrantyReport = _.get(warrantyReportFinalizedQuery, 'warrantyReport');
        const files = [];
        if (_.get(warrantyReport,'print.customer')){
            files.push({
                ..._.get(warrantyReport,'print.customer'),
                filename: t('Customer Report')
            })
        }
        if (_.get(warrantyReport,'print.serviceProvider')){
            files.push({
                ..._.get(warrantyReport,'print.serviceProvider'),
                filename: t('Service Provider Report')
            })
        }

        return <div className={"p-10"}>
            <Alert
                message={t('Warranty report was finalized')}
                description={t('Warranty report was finalized by USER at DATE', {
                    USER: _.get(warrantyReport, 'finalized.finalizedBy.name'),
                    DATE: moment(_.get(warrantyReport, 'finalized.insert'), 'LLL').format("DD MMM YYYY hh:mm"),
                })}
                type="success"
                showIcon
            />

            {files.map((file) => {
                return <div className={'mt-10'}>

                    <FileDownloadBox
                        title={_.get(file, 'filename')}
                        thumbnailLink={_.get(file, 'thumbnailLink')}
                        link={_.get(file, 'link')}
                        size={_.get(file, 'size')}
                        update={_.get(file, 'update')}
                    />

                </div>
            })}

        </div>;
    }
}

export default compose(
    withRouter,
    withTranslation(),
    graphql(WarrantyReportFinalizedQuery, {
        name: 'warrantyReportFinalizedQuery',
        options: (props) => ({
            variables: {
                warrantyReportId: props.warrantyReportId
            }
        })
    }),
    waitWhileLoading('warrantyReportFinalizedQuery', ['warrantyReport']),
)(WarrantyReportFinalized);

