// @flow
import React, {Component} from 'react';
import {Col, Row} from "antd";
import Panel from "../../components/Panel";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import WarrantyReportForm from "../../components/WarrantyReportForm";
import {withRouter} from "react-router-dom";
import WarrantyReportFiles from "../../components/WarrantyReportFiles";
import ServiceTicketWarrantyReportQuery from "../../graphql/queries/ServiceTicketWarrantyReport.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import WarrantyReportCreater from "../../components/WarrantyReportCreater";
import WarrantyReportFinalized from "../../components/WarrantyReportFinalized";

type Props = {

};

type State = {

}

class ServiceTicketWarrantyReportRoute extends Component<Props, State> {

    state = {
        creating: false
    }

    componentDidMount(){

    }

  render() {
    const { t, serviceTicketWarrantyReportQuery } = this.props;

      const {serviceTicketId, warrantyReport} = this.props;

      if (!warrantyReport){
          return <WarrantyReportCreater
              onCreated={() => {
                  serviceTicketWarrantyReportQuery.refetch();
              }}
              serviceTicketId={serviceTicketId}/>
      }

      if (warrantyReport.finalized && warrantyReport.finalized.insert){
          return <div className={"p-10"}>
              <Panel title={t('Warranty Report')}>
                  <WarrantyReportFinalized warrantyReportId={warrantyReport.id}/>
              </Panel>
          </div>
      }


    return <div className={"p-10"}>
        <Row gutter={16}>
            <Col md={16}>
                <Panel title={t('Warranty Report')}>
                    <WarrantyReportForm serviceTicketId={serviceTicketId}/>
                </Panel>
            </Col>
            <Col md={8}>
                <Panel title={t('Files')}>
                    <WarrantyReportFiles serviceTicketId={serviceTicketId}/>
                </Panel>
            </Col>
        </Row>
    </div>;
  }
}

export default compose(
    withTranslation(),
    withRouter,
    withProps((props) => ({
        serviceTicketId: _.get(props, 'match.params.id')
    })),
    graphql(ServiceTicketWarrantyReportQuery, {
        name: 'serviceTicketWarrantyReportQuery',
        options: (props) => {
            return {
                variables: {
                    serviceTicketId: props.serviceTicketId
                }
            }
        }
    }),
    waitWhileLoading('serviceTicketWarrantyReportQuery', ['serviceTicket.warrantyReport']),
    withProps((props) => ({
        warrantyReport: _.get(props, 'serviceTicketWarrantyReportQuery.serviceTicket.warrantyReport')
    }))
)(ServiceTicketWarrantyReportRoute);
