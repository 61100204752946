// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Col, Row} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from 'lodash';
import SplitPanelList from "../SplitPanelList";

import ProductComponentCountQuery from "../../graphql/queries/productComponentCount.graphql";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ComponentTypeProduct from "../../graphql/queries/componentTypeProduct.graphql";
import ProductForm from "../ProductForm";
import Panel from "../Panel";
import Sizes from "../../themes/default/sizes";
import ProductDashboardFilesWidget from "../ProductDashboardFilesWidget";
import ProductDashboardQuery from "../../graphql/queries/productDashboard.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ProductImageForm from "../ProductImageForm";
import {Link} from "react-router-dom";
import ProductHistoryPanel from "../ProductHistoryPanel";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

type Props = {
    id: any,
    t: Function,
    productDashboardQuery: query,
    componentListQuery: query,
    componentTypeProduct: query,
}

class ProductDashboard extends Component<Props, State> {
    render() {
        const {id, t, productDashboardQuery, productComponentCountQuery, componentTypeProduct} = this.props;

        return <div className={"p-10"}>
            <Row gutter={2 * Sizes.grid}>
                <Col lg={12}>
                    <Panel title={t('Image')}>
                        <ProductImageForm productId={id}/>
                    </Panel>

                    <Panel title={t('Edit')}>
                        <ProductForm id={id}/>
                    </Panel>
                    <Panel title={t('Files')}>
                        <ProductDashboardFilesWidget productId={id}/>
                    </Panel>
                </Col>
                <Col lg={12} className={"pt-50"}>
                    <SplitPanelList
                        items={[{
                            leftComponent: <FontAwesomeIcon size='2x' name={'box'}/>,
                            showLinkIndicator: false,
                            title: t('Components'),
                            description: <div style={{width: '100%'}}>{t(`by MANUFACTURERNAME`, {
                                manufacturerName: productDashboardQuery.product.manufacturer.name
                            })}</div>,
                            link: `/catalog/products/${id}/components`,
                            largeTitle: _.get(productComponentCountQuery, 'productComponentList.total')
                        }]}
                    />
                    <SplitPanelList
                        items={[{
                            leftComponent: <Icon style={{fontSize: '35px'}} type="bars" theme="outlined"/>,
                            showLinkIndicator: false,
                            title: t('Attributes'),
                            description: <div
                                style={{width: '100%'}}>{t('Click here to show and edit attributes')}</div>,
                            link: `/catalog/products/${id}/attributes`
                        }]}
                    />
                    <SplitPanelList
                        items={[{
                            leftComponent: <Icon style={{fontSize: '35px'}} type="tool" theme="outlined"/>,
                            showLinkIndicator: false,
                            title: t('Default Service Tasks'),
                            largeTitle: _.get(productDashboardQuery,'product.defaultServiceTaskList.total'),
                            description: <div style={{width: '100%'}}>{t('Default Service Tasks of Product')}</div>,
                            link: `/catalog/products/${id}/defaultServiceTasks`
                        }]}
                    />
                    <SplitPanelList
                        items={[{
                            leftComponent: <Icon style={{fontSize: '35px'}} type="AppstoreAddOutlined" theme="outlined"/>,
                            showLinkIndicator: false,
                            title: t('Spare Parts'),
                            description:t('Click here to view accessory products and spare parts'),
                            link: `/catalog/products/${id}/spareparts`,
                            largeTitle: _.get(productComponentCountQuery, 'product.sparePartList.total')
                        }]}
                    />
                    <Panel title={t('History')}
                           containerStyle={{padding: 0}}
                           topRight={<Link to={`/catalog/products/${id}/logs`}>{t('Show all entries')}</Link>}
                    >
                      <ProductHistoryPanel productId={id}/>
                    </Panel>

                </Col>
            </Row>
        </div>
    }

}

export default compose(
    graphql(ProductDashboardQuery, {
        name: 'productDashboardQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    waitWhileLoading('productDashboardQuery'),
    graphql(ProductComponentCountQuery, {
        name: 'productComponentCountQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    graphql(ComponentTypeProduct, {
        name: 'componentTypeProduct',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    withTranslation(),
)(ProductDashboard);
