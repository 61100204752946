// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {AutoComplete, Avatar, Spin} from "antd";
import {Col, Row} from "../Flex";
import productList from "./../../graphql/queries/productList.graphql"
import * as _ from "lodash";

const Option = AutoComplete.Option;

type Props = {
    query: string,
    onSelect: ?Function,
    getProductList: {
        loading: boolean,
        productList: any,
        refetch: ?Function,
    },
    handleSearch: Function,
    debounce: number
}

class ProductSearch extends Component<Props> {

    static defaultProps = {
        debounce: 100
    };

    constructor(props) {
        super(props);

        this.search = _.debounce(val => {
            this.props.handleSearch(val)
        }, props.debounce);
    }


    render() {

        const {getProductList, onSelect, t} = this.props;

        let data = [];

        if (getProductList) {
            if (getProductList.loading) {
                data = [
                    <Option disabled key={'loader'}>
                        <Spin size={'small'}/>
                    </Option>
                ]
            } else {
                if (getProductList.productList && getProductList.productList.total > 0) {
                    data = getProductList.productList.products;
                }
                data = data.map(item => {
                    return <Option key={item.id} name={item.name} value={String(item.id)}>
                        <Row style={{width: '100%'}}>
                            <Col style={{alignItems: 'center'}}>
                                <Avatar shape={'square'}
                                        style={{verticalAlign: 'middle'}}
                                        icon={"code-sandbox"}
                                        size={70}
                                        src={_.get(item, 'image.thumbnailLink')}/>
                            </Col>
                            <Col style={{paddingLeft: 10}}>
                                <span style={{fontSize: 18}}>{item.name}</span>
                                <span style={{fontSize: 12}}>{t('Catalog No')}: {item.catalogNo}</span>
                                <span style={{fontSize: 12}}>{t('Manufacturer')}: {item.manufacturer.name}</span>
                            </Col>
                        </Row>
                    </Option>
                });
            }
        }


        return <div data-cy={'component-create-product-input'}>
            <AutoComplete
                style={{width: '100%'}}
                dataSource={data}
                optionLabelProp={'name'}
                onSelect={(val) => {
                    return onSelect ? onSelect(Number.parseInt(val, 10)) : null
                }}
                filterOption={false}
                onSearch={(val) => this.search(val)}
                placeholder={t('Search for product name or catalog number')}
            />
        </div>;
    }

}

export default compose(
    graphql(productList, {
        name: 'getProductList',
        options: props => ({
            skip: props.query === null,
            variables: {
                params: {
                    start: 0,
                    limit: 10,
                    search: {
                        query: props.query,
                        fields: ['name', 'catalogNo']
                    }
                }
            }
        }),
    }),
    withTranslation(),
)(ProductSearch);
