// @flow
import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import productAttributesQuery from "../../graphql/queries/productAttributes.graphql";
import catalogAttributeList from "../../graphql/queries/CatalogAttributesList.graphql";
import addProductToCatalogAttribute from "../../graphql/mutations/addProductToCatalogAttribute.graphql";
import removeProductFromCatalogAttribute from "../../graphql/mutations/removeProductFromCatalogAttribute.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import Toolbar from "../Toolbar";
import {message, Select} from "antd";
import {Button} from "@dreebit/react-components";
import QueryTable from "../QueryTable";
import ContentWrapper from "../ContentWrapper";
import _ from 'lodash';
import {toastError} from "../../utils/toast";

type Props = {
    t: Function,
    id: any,
    getProductAttributesQuery: query,
    catalogAttributeListQuery: query,
    addProductToCatalogAttributeMutation: Function,
    removeProductFromCatalogAttributeMutation: Function
};

type State = {
    selectedRowIds: any[],
    loading: boolean,
}

class ProductAttributesDetail extends Component<Props, State> {
    state = {
        selectedRowIds: [],
        loading: false,
    };

    _handleAdd = (catalogAttributeIndex) => {
        const {addProductToCatalogAttributeMutation, id} = this.props;

        addProductToCatalogAttributeMutation({
            variables: {
                catalogAttributeIndex: catalogAttributeIndex,
                productId: id
            }
        }).then((res) => {
            message.success(res.data.addProductToCatalogAttribute.message);
        })
    };

    _handleDelete = () => {
        const {removeProductFromCatalogAttributeMutation, id} = this.props;

        this.setLoading(true)
            .then(() => {
                const promises = this.state.selectedRowIds.map((index) => removeProductFromCatalogAttributeMutation({
                    variables: {
                        catalogAttributeIndex: index,
                        productId: id
                    }
                }));
                return Promise.all(promises);
            })
            .catch(toastError)
            .finally(() => {
                this.setState({
                    selectedRowIds: [],
                });
                this.setLoading(false);
            })
    };

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {t, getProductAttributesQuery, catalogAttributeListQuery} = this.props;
        const {selectedRowIds} = this.state;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {

                this.setState({
                    selectedRowIds: selectedRows.map(item => item.index)
                })
            },
            getCheckboxProps: record => ({
                disabled: record.current,
                name: record.name,
            }),
        };

        const valueIndexList = _.get(getProductAttributesQuery,'product.catalogAttributes.catalogAttributes').map(item => item.index);

        return <ContentWrapper>
            <ContentWrapper>
                <Select
                    onSelect={(index) => this._handleAdd(index)}
                    defaultActiveFirstOption={true}
                    autoFocus
                    placeholder={t('Please select an attribute')}
                    style={{width: '100%'}}>
                    {_.get(catalogAttributeListQuery, 'catalogAttributeList.catalogAttributes', [])
                        .filter((item) => valueIndexList.indexOf(item.index) === -1)
                        .map((item) => (
                        <Select.Option key={item.index}
                                       value={item.index}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            </ContentWrapper>

            {selectedRowIds.length ? <Toolbar
                renderLeft={() => <Button onClick={this._handleDelete}>{t('Delete')}</Button>}
            /> : null}
            <QueryTable
                loading={this.state.loading}
                rowSelection={rowSelection}
                size={'small'}
                rowKey={"index"}
                query={getProductAttributesQuery}
                itemsKeyPath={'product.catalogAttributes.catalogAttributes'}
                totalKeyPath={'product.catalogAttributes.total'}
                columns={[
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: t('Name')
                    },
                    {
                        dataIndex: 'type',
                        key: 'type',
                        title: t('Typ')
                    },
                    {
                        dataIndex: 'categories',
                        key: 'categories',
                        title: t('Categories'),
                        render: (text) => text.length > 0 ? text.map((category) => category.name) : ''
                    }
                ]}
            /></ContentWrapper>;
    }
}

export default compose(
    graphql(productAttributesQuery, {
        name: 'getProductAttributesQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    waitWhileLoading('getProductAttributesQuery', ["product.catalogAttributes.catalogAttributes"]),
    graphql(catalogAttributeList, {
        name: 'catalogAttributeListQuery',
        options: props => ({
            variables: {
                params: {
                    start: 0,
                    limit: 50
                }
            }
        }),
    }),
    waitWhileLoading('catalogAttributeListQuery', ["catalogAttributeList.catalogAttributes"]),
    graphql(addProductToCatalogAttribute, {
        name: 'addProductToCatalogAttributeMutation',
        options: props => ({
            refetchQueries: [{
                query: productAttributesQuery,
                variables: {
                    "id": props.id
                }
            }]
        })
    }),
    graphql(removeProductFromCatalogAttribute, {
        name: 'removeProductFromCatalogAttributeMutation',
        options: props => ({
            refetchQueries: [{
                query: productAttributesQuery,
                variables: {
                    "id": props.id
                }
            }]
        })
    }),
    withTranslation(),
)(ProductAttributesDetail);
