// @flow
import React, {Component} from 'react';
import {withApollo} from "@apollo/client/react/hoc";

type Props = {};

type State = {}

class CacheReset extends Component<Props, State> {

    intervalId = null

    componentDidMount() {
        this.intervalId = setInterval(this.clearStore, 10*60*1000)
    }

    clearStore = () => {
        console.log("reset store", this.props.client);
        this.props.client.resetStore()
    }

    componentWillUnmount() {
        clearInterval(this.intervalId)
    }

  render() {
    return this.props.children;
  }
}

export default withApollo(CacheReset);
