// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import type {Query} from "../../types";
import NewsListQuery from "./../../graphql/queries/NewsList.graphql";
import QueryList from "../QueryList";
import {List} from "antd";
import Loader from "./../../components/Loader"
import Empty from "../Empty";
import ReactMarkdown from 'react-markdown'
import withProfile from "../../hoc/withProfile";
import {checkResource} from "../../hoc/withResources";
import statics from "../../statics";
import OneLineLabel from "../OneLineLabel";

type Props = {
    newsListQuery: Query
};

class NewsList extends Component<Props, State> {
    componentDidMount() {
    }

    render() {
        const {t, newsListQuery, resources} = this.props;

        if (newsListQuery.loading) return <Loader size={'small'}/>

        const isNewsAdmin = checkResource('newsEditor', resources);

        return <QueryList
            empty={<Empty text={t('No news available')}/>}
            query={newsListQuery}
            itemsKeyPath={'newsList.news'}
            totalKeyPath={'newsList.total'}
            renderItem={(item) => (<List.Item
                    className={"ph-10"}
                    key={item.id}>
                    <List.Item.Meta
                        title={item.title}
                        description={<OneLineLabel><ReactMarkdown>{item.text}</ReactMarkdown></OneLineLabel>}
                    />
                </List.Item>
            )}

        />;
    }
}

export default compose(
    withTranslation(),
    graphql(NewsListQuery, {
        name: 'newsListQuery',
        options: (props) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                params: {
                    start: 0,
                    limit: props.limit || statics.defaultPageSize,
                }
            }
        }),
    }),
    withProfile()
)(NewsList);
