// @flow
import React, {Component} from 'react';
import styled from "styled-components";
import {Popover} from "antd";

const ThumbnailWrapper = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  position: relative:
`;

const PopoverImage = styled.img`
  width: ${(props) => props.maxPopoverSize}px;
`;

type Props = {
    link: string,
    maxPopoverSize?: number,
    size?: number,
    popover: boolean
};


class Thumbnail extends Component<Props, State> {

    static defaultProps = {
        size: 30,
        maxPopoverSize: 200,
        popover: false
    };

    render() {
        const {link, size, maxPopoverSize} = this.props;


        const thumb = <img src={link} style={{maxWidth: size, maxHeight: size}}/>;

        if (this.props.popover) {
            return <Popover content={<PopoverImage width={maxPopoverSize} src={link}/>}>
                {thumb}
            </Popover>
        }

        return thumb;
    }
}

export default Thumbnail;
