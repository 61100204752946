// @flow
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import _ from "lodash";
import FinalizeFinalTestMutation from "../../graphql/mutations/finalizeFinalTest.graphql";
import FinalTestFormQuery from "./../../graphql/queries/FinalTestForm.graphql";
import UpdateFinalTestMutation from "./../../graphql/mutations/UpdateFinalTestMutation.graphql";
import {getAttributeGroups} from "../../utils/attributeGroups";
import TDAAttributesForm from "../TDAAttributesForm";
import serviceTicketDashboard from "../../graphql/queries/serviceTicketDashboard.graphql";
import ServiceTicketFinalTestQuery from "../../graphql/queries/serviceTicketFinalTest.graphql";
import {Button, Loader, MutationButton} from "@dreebit/react-components";
import {useMutation, useQuery} from "@apollo/client";
import {toastError, toastSuccess} from "../../utils/toast";

type Props = {
    finalTestId: string,
    serviceTicketId: any
};

const FinalTestForm = ({finalTestId, serviceTicketId}: Props) => {
    const {t} = useTranslation();
    const [changes, setChanges] = useState(null);

    const finalTestFormQuery = useQuery(FinalTestFormQuery, {
        variables: {
            id: finalTestId
        },
        fetchPolicy: 'no-cache'
    });
    const [finalizeFinalTestMutation] = useMutation(FinalizeFinalTestMutation, {
        variables: {
            finalTestId,
        },
        errorPolicy: 'all',
        refetchQueries: [{
            query: serviceTicketDashboard,
            variables: {
                id: serviceTicketId
            }
        }, {
            query: ServiceTicketFinalTestQuery,
            variables: {
                id: serviceTicketId
            }
        }, {
            query: FinalTestFormQuery,
            variables: {
                id: finalTestId
            }
        }]
    });
    const [updateFinalTestMutation] = useMutation(UpdateFinalTestMutation, {
        refetchQueries: [{
            query: serviceTicketDashboard,
            variables: {
                id: serviceTicketId
            }
        }, {
            query: ServiceTicketFinalTestQuery,
            variables: {
                id: serviceTicketId
            }
        }, {
            query: FinalTestFormQuery,
            variables: {
                id: finalTestId
            }
        }]
    });

    if (finalTestFormQuery.loading) return <Loader/>;

    let attributes = _.get(finalTestFormQuery, 'data.finalTest.typeDefinitionAttributeList.typeDefinitionAttributes', []);
    const finalTest = _.get(finalTestFormQuery, 'data.finalTest');
    const finalized = !!_.get(finalTest, 'finalized');
    const groups = getAttributeGroups(attributes, t).map((group) => ({
        ...group,
        colProps: {
            md: 12
        }
    }));

    attributes = attributes.map((attribute) => {
        return {
            ...attribute,
            formItemLayout: {
                labelCol: {
                    md: {span: 12},
                    lg: {span: 12},
                },
                wrapperCol: {
                    md: {span: 12},
                    lg: {span: 12},
                }
            }
        };
    });

    const file = _.get(finalTest, 'file') || _.get(finalTest, 'print.sheet');

    const onDone = (res: any) => {
        if (res.errors && res.errors.length) {
            return res.errors.forEach((error) => {
                toastError(_.get(error, 'message', t('Error')));
            })
        }

        toastSuccess(t('Final test successfully finalized'));
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        return finalTestFormQuery.refetch();
    };
    const onError = (error: any) => {
        if (error) {
            toastError(error)
        }
    };

    return <div className={"submit-right pv-5"}>
        <TDAAttributesForm
            mutation={updateFinalTestMutation}
            attributes={attributes}
            groups={groups}
            submitTitle={t('Save')}
            useSubmit={!finalized}
            onChange={(newChanges) => setChanges(newChanges)}
            onSubmit={() => {
                setChanges(null);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }}
            afterSubmit={changes
                ? null
                : <MutationButton
                    buttonProps={{style: {float: "right", marginRight: 8}, icon: "lock"}}
                    mutation={finalizeFinalTestMutation}
                    onError={onError}
                    onDone={onDone}
                >
                    {t("Finalize")}
                </MutationButton>
            }
            translateMutationOptions={({values}) => {
                Object.keys(values).map((el) => {
                    if (values[el] === null) delete values[el]
                });

                return {
                    variables: {
                        attributes: values,
                        finalTestId
                    }
                }
            }}
        />
        {finalized ? <div style={{textAlign: 'right', paddingRight: 8, paddingBottom: 5}}>
            <a download target={"_BLANK"} href={file.link}>
                <Button icon="printer" className="mr-10">{t('Print')}</Button>
            </a>
        </div> : null}
    </div>;
};

export default FinalTestForm;
