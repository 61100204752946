// @flow
import React, {Component} from "react"
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ClientListQuery from "./../../graphql/queries/clientList.graphql";
import {graphql} from '@apollo/client/react/hoc';
import "./index.css";
import _ from "lodash";
import FillQueryTable from "../QueryTable/fill";
import Statics from "../../themes/default/values";
import {Badge} from "antd";

type Props = {
    resources: any[],
    onClick: Function,
    clientListQuery: query,
    filter: Object,
    profile: any,
    serviceProviderListQuery: any
};

class ClientList extends Component<Props, State> {

    render() {
        const { t, clientListQuery, onClick, filter, search} = this.props;

        return <FillQueryTable
            className={"link-table"}
            columns={[
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: t('Name'),
                    width: "40%"
                },
                {
                    key: 'customerId',
                    dataIndex: 'customerId',
                    title: t('customerId')
                },
                {
                    key: 'activatedBy',
                    dataIndex: 'activatedBy.name',
                    title: t('Activated by')
                },
                {
                    key: 'active',
                    dataIndex: 'active',
                    title: t('VSM Active'),
                    render: (text, record) => {
                        return text ? <Badge status="processing" text={t("Active")} /> :   <Badge status="default" text={t("Inactive")} />
                    }
                }
            ]}
            onRow={(record) => {
                return {
                    onClick: () => {
                        onClick(record);
                    },
                };
            }}
            size={'middle'}
            rowKey={"id"}
            query={clientListQuery}
            itemsKeyPath={'clientList.clients'}
            totalKeyPath={'clientList.total'}
        />
    }
}

export default compose(
    graphql(ClientListQuery,{
        name: 'clientListQuery',
        options: props => ({
            variables: {
                params: {
                    search: {
                        query: _.get(props.search, 'query', '') == null ? '' : _.get(props.search, 'query', ''),
                        fields: ["name", "customerId"],
                    },
                    start: 0,
                    limit: Statics.pagingSize*2,
                    sort: {
                        name: 'ASC'
                    },
                    filter: props.filter
                }
            }
        })
    }),
    withTranslation()
)(ClientList);

