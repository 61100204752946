import React, {useState} from 'react';
import {Col} from "../Flex";
import StatisticsTabFilter from "../StatisticsTabFilter";
import {useTranslation} from "react-i18next";
import statisticList from "../../graphql/queries/statisticList.graphql";
import {getFilterVariables} from "../../routes/admin/StatisticsRoute";
import FillQueryTable from "../QueryTable/fill";
import _ from "lodash";
import {Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {changeDirectionType} from "../StatisticsServiceProvider";

type Props = {
};

const StatisticsServiceProvider = ({}: Props) => {
    const {t} = useTranslation();

    const [filter, setFilter]: any = useState();
    const [sorter, setSorter]: {
        date: 'ASC' | 'DESC',
        componentCount: 'ASC' | 'DESC',
        serviceTicketCount: 'ASC' | 'DESC',
        clientCount: 'ASC' | 'DESC',
        userCount: 'ASC' | 'DESC',
    } = useState({
        componentCount: 'DESC'
    });

    return <Col className={'full-height'}>
        <StatisticsTabFilter
            filter={filter}
            onChange={(changes: any) => {
                setFilter({
                    ...filter,
                    ...changes
                })
            }}
        />

        <FillQueryTable
            useReactComponents={true}
            queryData={{
                query: statisticList,
                itemsKeyPath: 'statisticList.statistics',
                totalKeyPath: 'statisticList.total',
                limitKeyPath: 'params.limit',
                startKeyPath: 'params.start',
                pagingSize: 25,
                queryVariables: {
                    params: {
                        filter: getFilterVariables(filter),
                        type: 'componentTypeIndex',
                        sort: sorter
                    }
                }
            }}
            onSorterChange={(sorter: any) => {
                if (sorter && sorter.field) setSorter({
                    [sorter.field]: changeDirectionType(sorter.order)
                });
            }}
            columns={[
                {
                    key: 'componentTypeIndex',
                    dataIndex: 'componentTypeIndex',
                    title: t('Component Types'),
                    render: (item) => _.get(item, 'name')
                },
                {
                    key: 'componentCount',
                    dataIndex: 'componentCount',
                    title: t('Components'),
                    sorter: true,
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: changeDirectionType(_.get(sorter, 'componentCount'))
                },
                {
                    key: 'serviceTicketCount',
                    dataIndex: 'serviceTicketCount',
                    title: t('Service Ticket'),
                    sorter: true,
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: changeDirectionType(_.get(sorter, 'serviceTicketCount'))
                }
            ]}
            footerHeight={95}
            renderExtraFooter={(query) => {
                const exportLink = _.get(query, 'data.statisticList.export.link');
                if(!exportLink) return;

                return <a
                    href={exportLink}
                    target={"_BLANK"}
                >
                    <Button
                        icon={<DownloadOutlined />}
                    >
                        {t('CSV Export')}
                    </Button>
                </a>
            }}
        />
    </Col>
}

export default StatisticsServiceProvider;
