// @flow
import React, {Component} from "react"

import ComponentTypeQuery from "../../graphql/queries/componentType.graphql";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ComponentTypeIcon from "../ComponentTypeIcon";
import {graphql} from '@apollo/client/react/hoc';
import Loader from "../Loader";
import type {Query} from "../../types";
import _ from 'lodash';


type Props = {
    componentTypeIndex: string,
    getComponentType: Query,
    size?: number
}

class ComponentTypeInfoBox extends Component<Props> {


    static defaultProps = {
        size: 21
    };

    render() {

        const {componentTypeIndex, componentTypeQuery, size} = this.props;

        if (componentTypeQuery.loading) {
            return <Loader size={'small'}/>
        }

        return <div>
            <ComponentTypeIcon size={size} componentTypeIndex={componentTypeIndex}/> <span
            style={{fontSize: size}}>{_.get(componentTypeQuery, 'componentType.name')}</span>
        </div>;
    }

}

export default compose(
    graphql(ComponentTypeQuery,{
        name: 'componentTypeQuery',
        options: props => ({
            variables: {
                index: props.componentTypeIndex
            }
        })
    })
)(withTranslation()(ComponentTypeInfoBox));
