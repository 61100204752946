// @flow
import styled from "styled-components";

const EllipseLabel = styled.span`
  max-width: ${(props) => (props.maxWidth || 200)}px;
  white-space: nowrap;  
  text-overflow: ellipsis "...";
  display: inline-block;
`;

export default EllipseLabel;
