// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import ServiceCalendarPortletTable from "./../ServiceCalendarPortletTable";
import {Mutation} from "@apollo/client/react/components";
import UpdatePortletMutation from "../../graphql/mutations/updateUserPortlet.graphql";
import {Translation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {getServiceCalendarFilterAttributes} from "../ServiceCalendarFilterPanel";
import {objectToFilter} from "../../utils/queryFilter";

type Props = {
    portlet: any,
};


class ServiceCalendarListPortlet extends Component<Props, State> {


    static RENDER_SETTINGS_COMPONENT = (portlet: any) => {


        const config = portlet.config ? JSON.parse(portlet.config) : {};

        return <div>
            <Mutation mutation={UpdatePortletMutation}>
                {(updatePortletMutation, {data}) => (
                    <Translation>
                        {
                            (t) => <AttributesInlineForm
                                values={_.get(config, 'filter', {})}
                                submitChangesOnly={false}
                                mutation={updatePortletMutation}
                                translateMutationOptions={({values}) => {
                                    const newConfig = {
                                        ...config,
                                        filter: values
                                    };
                                    return {
                                        variables: {
                                            id: portlet.id,
                                            input: {
                                                config: JSON.stringify(newConfig)
                                            }
                                        }
                                    }
                                }
                                }
                                attributes={
                                    [
                                        ...getServiceCalendarFilterAttributes(t),
                                        /*


                                        {
                                            index: 'futureOnly',
                                            inputType: 'checkbox',
                                            type: 'bool',
                                            name: t('Future only')
                                        }

                                         */
                                    ]
                                }

                            />
                        }
                    </Translation>
                )}
            </Mutation>

        </div>
    }

    render() {
        const {portlet} = this.props;

        const config = JSON.parse(portlet.config);
        const filterObj = _.get(config, 'filter', {});

        if (filterObj.futureOnly) {
            delete filterObj["futureOnly"];
            filterObj.date = {
                operator: "gt",
                value: new Date().toISOString()
            }
        }
        const filter = objectToFilter(filterObj);

        return <ServiceCalendarPortletTable filter={filter}/>;
    }
}

export default ServiceCalendarListPortlet;

