// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {Col, Row} from "antd";
import {graphql} from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "./../Form/attributes-inline-form";
import {compose} from "redux";
import Error from "./../Error";

import serviceActionAttributes from "./../../graphql/queries/serviceActionAttributes.graphql";
import updateServiceAction from "./../../graphql/mutations/updateServiceAction.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {getAttributeGroups} from "../../utils/attributeGroups";
import ServiceActionFilesQuery from "../../graphql/queries/serviceActionFiles.graphql";
import ServiceActionDashboardQuery from "../../graphql/queries/serviceActionDashboard.graphql";

type Props = {
    serviceActionId: string,
    updateServiceActionMutation: Function,
    serviceActionAttributesQuery: any
};

const detailAttributes = ['doneById', 'date', 'description'];

class ServiceActionDetailsInlineForm extends Component<Props, State> {


    translateMutationOptions = ({values, changes}) => {
        return {
            variables: {
                id: this.props.serviceActionId,
                attributes: changes
            }
        }
    };

    render() {
        const {serviceActionAttributesQuery, updateServiceActionMutation, t} = this.props;
        let attributes = _.get(serviceActionAttributesQuery, 'serviceAction.typeDefinitionAttributeList.typeDefinitionAttributes');

        if (!attributes) {
            return <Error title={t('No attributes found')}/>
        }

        // attributes.filter(item => detailAttributes.indexOf(item.index) !== -1)


        // Hide default category attributes
        attributes = attributes.map((attr) => {
            if (attr.categories.includes("default")) {
                return {
                    ...attr,
                    categories: [""]
                }
            } else {
                return attr
            }
        })
        return <Row>
            <Col md={24}>
                <div data-cy={'TEST_20004'}>
                    <AttributesInlineForm
                        groups={getAttributeGroups(attributes, t)}
                        readOnly={!_.get(serviceActionAttributesQuery, 'serviceAction.access.write')}
                        submitTitle={t("Save")}
                        submitChangesOnly={true}
                        mutation={updateServiceActionMutation}
                        translateMutationOptions={this.translateMutationOptions}
                        attributes={attributes}
                    />
                </div>
            </Col>
        </Row>;
    }
}

export default compose(
    graphql(serviceActionAttributes, {
        name: 'serviceActionAttributesQuery',
        options: (props) => ({
            fetchPolicy: 'network-only',
            variables: {
                id: props.serviceActionId
            }
        })
    }),
    waitWhileLoading('serviceActionAttributesQuery', ['serviceAction']),
    graphql(updateServiceAction, {
        name: 'updateServiceActionMutation',
        options: (props) => ({
            refetchQueries: [
                {
                    query:  ServiceActionDashboardQuery,
                    variables: {
                        id: props.serviceActionId
                    }
                }
            ]
        })
    }),
    withTranslation(),
)(ServiceActionDetailsInlineForm);

