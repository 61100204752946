import React, {Component} from "react";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ComponentTCOQuery from "../../graphql/queries/componentTCOBasis.graphql";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {Empty} from "antd";
import {Bar, BarChart, Tooltip, XAxis, YAxis} from "recharts";
import {withSize} from "react-sizeme";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import withTheme from "../../hoc/withTheme";
import ComponentTCOValueInfo from "../ComponentTCOValueInfo";

type Props = {
    componentId: string,
    tcoSettings: any,
    resultFormat?: string
}

type State = {
    modal: boolean
}

class ComponentTCOChart extends Component<Props, State> {

    state = {
        modal: false
    }

    render() {
        const { t, size , componentTCOQuery, themeConfig, componentId, tcoSettings } = this.props;


        console.log(themeConfig);
        const entries =  _.get(componentTCOQuery,'component.componentTcoValueList.componentTcoValues',[]).map((item) => ({
            ...item,
            date: moment(item.date).format('DD.MM.YYYY')
        }))

        if (!entries.length) return <Empty/>


        return <BarChart
            width={size.width}
            height={300}
            data={entries}
        >
            <XAxis dataKey="date" />
            <Tooltip content={({label}) => {
                const item = _.find(entries,{date: label})
                if (item){
                    return <div className={"p-10 white-bg"}>
                        <ComponentTCOValueInfo date={moment(label,"DD.MM.YYYY")} componentId={componentId} tcoSettings={tcoSettings} />
                    </div>
                }
                return null
            }} />
            <YAxis />
            <Bar name={t('Amount')} dataKey="value" fill={themeConfig["@primary-color"]} />


        </BarChart>
    }
}


export default compose(
    graphql(ComponentTCOQuery,{
        name: 'componentTCOQuery',
        options: (props) => ({
            variables: {
                id: props.componentId,
                params: {
                    // ...props.tcoSettings
                    resultFormat: 'daily',
                    includeSubComponents: !!props.tcoSettings.includeSubComponents,
                    considerServiceTickets: !!props.tcoSettings.considerServiceTickets,
                    filter: props.tcoSettings.date && props.tcoSettings.date.length === 2 ? {
                        date: {
                            operator: "range",
                            range: {
                                from: _.first(props.tcoSettings.date),
                                to: _.last(props.tcoSettings.date)
                            }
                        }
                    } : undefined
                }
            }
        } )
    }),
    waitWhileLoading('componentTCOQuery'),
    withTranslation(),
    withSize(),
    withTheme()
)(ComponentTCOChart);
