// @flow
import React, {Component} from 'react';
import _ from 'lodash';
import {withRouter} from "react-router-dom";
import ServiceControlDashboard from "../../components/ServiceControlDashboard";

type Props = {
    match: {
        params: {
            serviceControlId: string,
            id: string
        }
    },
    history: {
        goBack: Function,
    }
};

class ComponentServiceControl extends Component<Props> {

    render() {
        const serviceControlId = _.get(this.props, 'match.params.serviceControlId', null);
        const componentId = _.get(this.props, 'match.params.id', null);
        return <ServiceControlDashboard onDelete={this.props.history.goBack} serviceControlId={serviceControlId} componentId={componentId}/>;
    }
}

export default withRouter(ComponentServiceControl);
