// @flow
import React, {Component} from 'react';
import _ from "lodash";
import {Link, Route, Switch, withRouter} from "react-router-dom";
import ProductLogsRoute from "./productLogs";
import ProductAttributes from "../../../components/ProductAttributes";
import ProductComponentList from "../../../components/ProductComponents";
import ProductDashboard from "../../../components/ProductDashboard";
import ProductMatchWrapper from "../../../components/ProductMatchWrapper";
import ProductDefaultServiceTasks from "../../../components/ProductDefaultServiceTasks";
import {Col, Row} from "../../../components/Flex";
import {Popconfirm, Tooltip} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ContentHeader from "../../../components/Typo/ContentHeader";
import {compose, onlyUpdateForKeys, withProps} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ProductDashboardQuery from "../../../graphql/queries/productDashboard.graphql";
import waitWhileLoading from "../../../hoc/waitWhileLoading";
import DeleteProducts from "../../../graphql/mutations/deleteProducts.graphql";
import ProductListQuery from "../../../graphql/queries/productList.graphql";
import {withTranslation} from "react-i18next";
import NavigationContainer from "../../../components/NavigationContainer";
import ProductDefaultServiceTasksCreate from "../../../components/ProductDefaultServiceTasksCreate";
import ProductDefaultServiceTasksDashboard from "../../../components/ProductDefaultServiceTasksDashboard";
import ProductSparePartsRoute from "./productSpareParts";

type Props = {

};

type State = {
    deleting: boolean
}

class ProductRoute extends Component<Props, State> {

    state = {
        deleting: false
    };

    handleDelete = () => {
        const {deleteProducts, history} = this.props;

        this.setState({
            deleting: true
        }, () => {
            deleteProducts().then(() => {
                this.setState({
                    deleting: false
                },() => {
                    history.push(`/catalog/products`);
                })

            }).catch(() => {
                this.setState({
                    deleting: false
                })
            })
        })

    };

  render() {
    const { productDashboardQuery, t } = this.props;

    const productId = this.props.match.params.id;
    const isDashboard = !this.props.match.params.sub;

    const productComponentListCount = _.get(productDashboardQuery,'product.componentList.total',0)

    return <div className={"full-size"}>
        <NavigationContainer scroll navigationBar={
            <ContentHeader title={productDashboardQuery.product.name} tools={isDashboard ? <Row>
                <Col>
                    {
                        productDashboardQuery.product.status !== 'published'
                            ? <Link to={`/catalog/products/${productDashboardQuery.product.id}/match`}>
                                <Button type="warning" icon="branches" >
                                    {t('Match')}
                                </Button>
                            </Link>
                            : null
                    }
                </Col>
                <Col style={{paddingLeft: '15px'}}>
                    {productComponentListCount > 0? <Tooltip title={t(`There are COUNT devices in the infrastructure this product must not be deleted`,{COUNT: productComponentListCount > 1 ? productComponentListCount : ''})}>
                        <span><Button disabled type="danger" icon="delete">{t('Delete')}</Button></span>
                    </Tooltip> : <Popconfirm placement="bottomRight" title={t("Are you sure delete this task?")}
                                                               onConfirm={this.handleDelete} okText={t("Yes")} cancelText={t("No")}
                                                               icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                        <Button loading={this.state.deleting} type="danger" icon="delete">{t('Delete')}</Button>
                    </Popconfirm> }

                </Col>
            </Row>: null}/>
        }
        >
            <Switch>
                <Route path="/catalog/products/:id/logs" component={() => <ProductLogsRoute productId={productId} />}/>
                <Route path="/catalog/products/:id/spareparts" component={() => <ProductSparePartsRoute productId={productId} />}/>
                <Route path="/catalog/products/:id/attributes" component={() => <ProductAttributes productId={productId}/>}/>
                <Route path="/catalog/products/:id/components" component={() => <div className={"ph-10"}><ProductComponentList productId={productId}/></div>}/>
                <Route path="/catalog/products/:id/match" component={() => <ProductMatchWrapper productId={productId} />}/>
                <Route exact path="/catalog/products/:id/defaultServiceTasks/create" component={() => <ProductDefaultServiceTasksCreate productId={productId}/>}/>
                <Route exact path="/catalog/products/:id/defaultServiceTasks" component={() => <ProductDefaultServiceTasks productId={productId}/>}/>
                <Route exact path="/catalog/products/:id/defaultServiceTasks/:dstid" component={(props) => <ProductDefaultServiceTasksDashboard productId={productId} defaultServiceTaskId={_.get(props,'match.params.dstid')} />}/>
                <Route path="/catalog/products/:id" component={() => <ProductDashboard id={productId}/>}/>
            </Switch>

        </NavigationContainer>
    </div>

  }
}

export default compose(
    withProps((props) => ({
        productId: _.get(props,'match.params.id'),
        defaultServiceTaskId: _.get(props,'match.params.dstid')
    })),
    graphql(ProductDashboardQuery, {
        name: 'productDashboardQuery',
        options: props => ({
            variables: {
                id: props.productId
            }
        }),
    }),
    waitWhileLoading('productDashboardQuery', ["product"]),
    graphql(DeleteProducts, {
        name: 'deleteProducts',
        options: props => ({
            refetchQueries: [{
                query: ProductListQuery,
                variables: {
                    params: {
                        limit: 30,
                        start: 0,
                    }
                }
            }]
        })
    }),
    withTranslation(),
    withRouter,
    onlyUpdateForKeys(
        ["productId", "defaultServiceTaskId"]
    ),
)(ProductRoute);
