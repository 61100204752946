// @flow
import React, {Component} from "react"
import RoleDashboard from "../../components/RoleDashboard";
import Error from "../../components/Error";
import withResources from "../../hoc/withResources";

type Props = {
    match: {
        params: {
            id: string,
        }
    },
    history: {
        push: Function,
    }
}

class RoleDashboardRoute extends Component<Props> {
    render() {
        const {match, history} = this.props;

        const roleId = match.params.id;

        return (<div>
            {
                !roleId
                    ? <Error type="404"/>
                    : <RoleDashboard roleId={roleId} history={history}/>
            }
        </div>)
    }

}

export default withResources(["administrateRoles"])(RoleDashboardRoute);