import {connect} from "react-redux";
import _ from "lodash";
import {saveForm} from "../actions/settings";


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function connectForm(formId: string) {
    return connect(({settings}, props) => {

        return {
            [formId]: {
                ..._.get(settings, `forms.${formId}`, {})
            }
        }
    }, (dispatch, props) => {
        return {
            [`set${capitalizeFirstLetter(formId)}`]: (values) => dispatch(saveForm(formId, values))
        }
    })
}
