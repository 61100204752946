// @flow
import React from "react";

const VSMPanelTopBorder = () => {
    return <div className={'vsmPanelTop'}>
        <div className={'primary-bg primaryPanelTop full-height '} />
        <div className={'secondary-bg secondaryPanelTop full-height'} />
        <div className={'secondary-lighter-bg secondaryPanelTop full-height'} />
    </div>
};

export default VSMPanelTopBorder
