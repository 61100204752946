// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import ServiceDepartmentUserListQuery from "./../../graphql/queries/ServiceDepartmentUserList.graphql";
import AddUserToServiceDepartmentMutation from "./../../graphql/queries/AddUserToServiceDepartment.graphql";
import RemoveUserFromServiceDepartmentMutation from "./../../graphql/queries/RemoveUserFromServiceDepartment.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import UserLoaderConfig from "../UserLoaderConfig";
import ListAdapter from "../ListAdapter";
import {message} from "antd";

type Props = {
    serviceDepartmentId: string,
};


class ServiceDepartmentUserTable extends Component<Props> {

    handleAddUser = (value) => {
        const {serviceDepartmentId, t, addUserToServiceDepartmentMutation} = this.props;

        addUserToServiceDepartmentMutation({variables: {serviceDepartmentId: serviceDepartmentId, userId: value}}).then((res) => {
            console.log(res)
            message.success(res.data.addUserToServiceDepartment.message);
        })
    };

    handleDeleteUser = (index) => {
        const {serviceDepartmentId, users, t, removeUserFromServiceDepartmentMutation} = this.props;

        if (users.length > 1) {
            removeUserFromServiceDepartmentMutation({variables: {serviceDepartmentId: serviceDepartmentId, userId: index.id}}).then((res) => {
                console.log(res)
                message.success(res.data.removeUserFromServiceDepartment.message);
            })
        } else {
            message.error(t('Can`t delete last Admin'));
        }

    };


    render() {
        const {users, t} = this.props;

        return <ListAdapter
            items={users}
            addRoleToUserMutation={(value) => {
                this.handleAddUser(value)
            }}
            deleteRoleFromUserMutation={(index) => {
                this.handleDeleteUser(index)
            }}
            loaderConfig={{
                ...UserLoaderConfig
            }}
            queryPath={'userList.users'}
            placeholder={t('select users')}
        />;
    }
}

export default compose(
    withTranslation(),
    graphql(ServiceDepartmentUserListQuery, {
        name: 'serviceDepartmentUserListQuery',
        options: (props: Props) => ({
            variables: {
                serviceDepartmentId: props.serviceDepartmentId
            }
        }),
    }),
    graphql(AddUserToServiceDepartmentMutation, {
        name: 'addUserToServiceDepartmentMutation',
        options: (props) => ({
            refetchQueries: [
                {
                    query: ServiceDepartmentUserListQuery,
                    variables: {
                        serviceDepartmentId: props.serviceDepartmentId
                    }
                }
            ]
        })
    }),
    graphql(RemoveUserFromServiceDepartmentMutation, {
        name: 'removeUserFromServiceDepartmentMutation',
        options: (props) => ({
            refetchQueries: [
                {
                    query: ServiceDepartmentUserListQuery,
                    variables: {
                        serviceDepartmentId: props.serviceDepartmentId
                    }
                }
            ]
        })
    }),
    waitWhileLoading('serviceDepartmentUserListQuery'),
    withProps((props) => ({
        users: _.get(props, 'serviceDepartmentUserListQuery.serviceDepartment.users', [])
    }))
)(ServiceDepartmentUserTable);
