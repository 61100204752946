// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import Panel from "../Panel";
import _ from "lodash";

import UpdateNotificationSetting from "../../graphql/mutations/updateNotificationSetting.graphql";
import AttributesInlineForm from "../Form/attributes-inline-form";
import NotificationSetting from "../../graphql/queries/notificationSetting.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Col, Row} from "antd";
import NotificationSettingList from "../../graphql/queries/notificationSettingList.graphql";
import SubscribedServiceTickets from '../SubscribedServiceTickets';
import SubscribedComponents from '../SubscribedComponents';

type Props = {
    id: any,
    updateNotificationSettingMutation: Function,
    t: Function,
    notificationSettingQuery: query
}

class ProfileNotificationSettingDashboard extends Component<Props, State> {

    render() {
        const {t, updateNotificationSettingMutation, id, notificationSettingQuery} = this.props;

        const setting = _.get(notificationSettingQuery, 'notificationSetting', {});
        const notificationTypeIndex = _.get(setting, 'notificationType.index', '');
        const attributes = _.get(setting, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);

        const groupObjects = {};
        attributes.forEach((item) => {
            item.categories.forEach((cat) => {
                groupObjects[cat] = cat
            });
        });
        const groups = Object.keys(groupObjects).map((key) => ({
            index: key,
            name: t(key),
            colProps: {
                xl: 12
            }
        }));

        return <div>
                <Row>
                    <Col>
                        <Panel title={t('Edit')} style={{margin: 13}}>
                            <AttributesInlineForm
                                groups={groups.length > 1 ? groups : undefined}
                                attributes={attributes}
                                layout={'horizontal'}
                                useSubmit={true}
                                t={t}
                                mutation={updateNotificationSettingMutation}
                                translateMutationOptions={({values, changes}) => ({
                                    variables: {
                                        id: id,
                                        settings: changes
                                    }
                                })}
                            />
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            notificationTypeIndex === "component"
                                ? <SubscribedComponents id={id}/>
                                : null
                        }
                        {
                            notificationTypeIndex === "serviceTicket"
                                ? <SubscribedServiceTickets id={id}/>
                                : null
                        }
                    </Col>
                </Row>
            </div>
    }
}

export default compose(
    graphql(NotificationSetting, {
        name: 'notificationSettingQuery',
        options: (props: Props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only'
        }),
    }),
    waitWhileLoading('notificationSettingQuery', 'notificationSetting.typeDefinitionAttributeList.typeDefinitionAttributes'),
    graphql(UpdateNotificationSetting, {
        name: 'updateNotificationSettingMutation',
        options: props => ({
            refetchQueries: [{
                query: NotificationSetting,
                variables: {
                    id: props.id
                }
            },{
                query: NotificationSettingList
            }]
        })
    }),
    withTranslation(),
)(ProfileNotificationSettingDashboard);
