import _ from 'lodash';

export const BOOKMARK_TYPE_INFRASTRUCTURE_DETAIL = "infrastructureDetail";
export const BOOKMARK_TYPE_INFRASTRUCTURE_NAVIGATION = "infrastructureNavigation";

export const getInfrastrutureDetailsBookmarkInput = (dataInfo) => {
    const componentId = _.get(dataInfo, 'componentId');
    return {
        link: `/infrastructure/${componentId}`,
        componentId,
    }
};

export const getInfrastrutureNavigationBookmarkInput = (dataInfo) => {
    const componentId = _.get(dataInfo, 'componentId');
    return {
        link: `/infrastructure/dashboard?finder=${componentId}`,
        componentId,
    }
};


export const getBookmarkInput = (type, dataInfo) => {

    let data = null;

    if (type === BOOKMARK_TYPE_INFRASTRUCTURE_DETAIL) {
        data = getInfrastrutureDetailsBookmarkInput(dataInfo)
    } else if (type === BOOKMARK_TYPE_INFRASTRUCTURE_NAVIGATION) {
        data = getInfrastrutureNavigationBookmarkInput(dataInfo)
    }

    return {
        title: _.get(dataInfo, 'title'),
        type: BOOKMARK_TYPE_INFRASTRUCTURE_DETAIL,
        data: JSON.stringify(data)
    }
};
