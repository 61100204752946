// @flow
import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";

import {Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import ContentWrapper from "../../../components/ContentWrapper";
import _ from "lodash";
import ContentHeader from "../../../components/Typo/ContentHeader";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ErrorPatternQuery from "../../../graphql/queries/errorPatternDetails.graphql";
import waitWhileLoading from "../../../hoc/waitWhileLoading";
import {toastError, toastMessage} from "../../../utils/toast";
import DeleteErrorPatternMutation from "../../../graphql/mutations/deleteErrorPattern.graphql";
import ErrorPatternsListQuery from "../../../graphql/queries/errorPatternList.graphql";
import ErrorPatternDashboard from "../../../components/ErrorPatternDashboard";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};

class ErrorPatternDetailRoute extends Component<Props> {

    state = {
        deleting: false
    };

    _handleDelete = () => {
        const {deleteErrorPatternMutation, errorPatternQuery, onDeleted, history} = this.props;
        const id = _.get(errorPatternQuery, 'errorPattern.id');

        this.setLoading(true)
            .then(() => deleteErrorPatternMutation({
                variables: {
                    id: id
                }
            }))
            .then((res) => {
                toastMessage(_.get(res, 'data.deleteErrorPattern.message'));
                if (onDeleted) onDeleted(res);
            })
            .then(() => {
                history.push('/catalog/errorPatterns/create')
            })
            .catch((err) => toastError(err))
            .finally(() => {
                this.setLoading(false);
            })
    };

    setLoading = (deleting) => {
        return new Promise((resolve) => {
            this.setState({
                deleting
            }, resolve)
        })
    };

    render() {
        const {t, history, errorPatternQuery} = this.props;

        const errorPattern = errorPatternQuery.errorPattern;

        return (
            <div>
                <ContentHeader title={errorPattern.name}
                               tools={<Popconfirm placement="bottomRight" title={t("Are you sure delete this task?")}
                                                  onConfirm={this._handleDelete} okText={t("Yes")} cancelText={t("No")}
                                                  icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                                   <Button loading={this.state.deleting} type="danger" icon="delete">{t('Delete')}</Button>
                               </Popconfirm>}/>
                <ContentWrapper>
                    <Route
                        exact path='/catalog/errorPatterns/:id'
                        component={(props) => (<ErrorPatternDashboard id={errorPattern.id}/>)}
                    />
                </ContentWrapper>
            </div>
        );
    }
}


export default compose(
    withRouter,
    withTranslation(),
    graphql(ErrorPatternQuery, {
        name: 'errorPatternQuery',
        options: (props: Props) => ({
            variables: {
                id: _.get(props, 'match.params.id', '')
            }
        }),
    }),
    graphql(DeleteErrorPatternMutation, {
        name: 'deleteErrorPatternMutation',
        options: () => ({
            refetchQueries: [{
                query: ErrorPatternsListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        }),
    }),
    waitWhileLoading('errorPatternQuery', ['errorPattern']),
)(ErrorPatternDetailRoute);

