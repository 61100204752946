// @flow
import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {compose, withProps} from "recompose";

import Panel from "../../components/Panel"
import ContentHeader from "../../components/Typo/ContentHeader";
import InvoiceList from "../../components/InvoiceList";
import InvoiceListToolbar from "../../components/InvoiceList/toolbar";
import queryString from "query-string";
import Toolbar from "../../components/Toolbar";
import withResources from "../../hoc/withResources";
import InvoiceStatistic from "../../components/InvoiceStatistic";


type Props = {
  history: {
    push: Function,
  },
  match: any,
  t: Function,
};

class InvoiceListRoute extends Component<Props, State> {


  render() {
    const {t, history, filter} = this.props;


    return (

        <div className={"full-size"}>
            <ContentHeader hideBreadcrumb title={t('Billing')}/>
            <div className={"mh-10"}>
                <Panel title={t("Current billing period")}>
                    <InvoiceStatistic/>
                </Panel>

                <Panel title={t("Invoices")}>
                    <Toolbar
                        borderBottom
                        className={"white-bg"}
                        renderLeft={() => <InvoiceListToolbar
                            values={filter}
                            onChange={(attributes, {allValues}) => {
                                const query = queryString.stringify(allValues);
                                history.push(`/admin/invoices?${query}`)
                            }}
                        />}
                    />
                    <InvoiceList filterValues={filter}/>
                </Panel>
            </div>
      </div>


    )
  }
}

export default compose(
  withResources('accounting'),
  withTranslation(),
  withRouter,
  withProps((props) => {
    let query = _.get(props, 'history.location.search');
    return {
      query
    }
  }),
  withProps((props) => ({
    filter: queryString.parse(props.query)
  }))
)(InvoiceListRoute);
