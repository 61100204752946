// @flow
import React, {Component} from 'react';
import _ from "lodash";
import QueryListAdvanced from "../QueryListAdvanced";
import ConversationListQuery from "../../graphql/queries/conversationList.graphql";
import {ChatItem} from "react-chat-elements";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {withApollo} from '@apollo/client/react/hoc'
import {socketConnect} from "socket.io-react";
import statics from "../../statics";

type Props = {
    archive?: boolean,
    participant?: boolean,
    activeKey?: string,
    queryProps?: any,
    onItemClick?: (item: any) => void,
    conversationListQuery: any,
};

type State = {}

const getInitials = (name) => {
    const initials = _.slice(name.match(/\b\w/g), 0, 2) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

class ConversationList extends Component<Props, State> {

    static defaultProps = {
        archive: false,
        queryProps: {
            variables: {
                params: {
                    sort: {
                        lastMessageUpdate: "DESC"
                    },
                    filter: {
                        archive: {
                            value: false
                        },
                        participant: {
                            value: true
                        }
                    }
                }
            }
        }
    };

    state = {
        loading: null,
    };

    updateConversationListener = () => {
        const {client} = this.props;
        client.query({
            query: ConversationListQuery,
            variables: {
                params: {
                    start: 0,
                    limit: statics.defaultPagingSize,
                    sort: {
                        lastMessageUpdate: "DESC"
                    },
                    filter: {
                        participant: {
                            value: true
                        }
                    }
                }
            }
        }).then(() => {
            this.setState({
                loading: Date.now()
            });
        });
    }

    componentDidMount() {
        const {socket} = this.props;
        if (socket) {
            socket.on("updateConversation", this.updateConversationListener);
        }
    }

    componentWillUnmount() {
        const {socket} = this.props;
        if (socket) {
            socket.off("updateConversation", this.updateConversationListener);
        }
    }

    render() {
        const {archive, style, onItemClick, t, activeKey, queryProps, match} = this.props;
        const {loading} = this.state;

        const _queryProps = _.cloneDeep(queryProps) || {};

        _.set(_queryProps, 'loading', loading);

        if (archive) {
            _.set(_queryProps, 'variables.params.filter.archive.value', archive);
        }

        return <div style={style} className={"full-height overflow-hidden"}>
            <QueryListAdvanced
                style={{overflow: 'scroll', height: '100%'}}
                itemsKeyPath={'conversationList.conversations'}
                totalKeyPath={'conversationList.total'}
                query={ConversationListQuery}
                queryProps={_queryProps}
                renderItem={(item) => {
                    return <ChatItem
                        className={activeKey === item.id ? "active" : null + item.read ? null : " unread"}
                        key={item.id}
                        avatar={`https://via.placeholder.com/90x90.png?text=${getInitials(item.title)}`}
                        title={item.title}
                        subtitle={_.get(item, 'lastMessage.text', t('No messages yet'))}
                        dateString={item.serviceTicket ? item.serviceTicket.key : ' '}
                        unread={0}
                        onClick={() => {
                            if (onItemClick) onItemClick(item)
                        }}
                    />
                }}
            />
        </div>
    }
}

export default compose(
    withTranslation(),
    withApollo,
    socketConnect
)(ConversationList);
