// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import queryWrapper from "../QueryWrapper";
import FileUploadList from "../FileUploadList";
import {graphql} from '@apollo/client/react/hoc';

import ServiceActionFilesQuery from "./../../graphql/queries/serviceActionFiles.graphql";
import AddServiceActionFilesMutation from "./../../graphql/mutations/addServiceActionFiles.graphql";
import DeleteServiceActionFileMutation from "./../../graphql/mutations/deleteServiceActionFile.graphql";
import UpdateServiceActionFileMutation from "./../../graphql/mutations/updateServiceActionFile.graphql";

import type {FileCreateInput} from "../../types";

type Props = {
    addServiceActionFilesMutation: Function,
    serviceActionFilesQuery: any,
    deleteServiceActionFileMutation: Function,
    updateServiceActionFileMutation: Function,
    serviceActionId: string,
    isPrivate: boolean
};


class ServiceActionDashboardFilesWidget extends Component<Props, State> {


    render() {
        const {addServiceActionFilesMutation, serviceActionFilesQuery, updateServiceActionFileMutation, serviceActionId, deleteServiceActionFileMutation, isPrivate} = this.props;

        return <div>
            <FileUploadList
                readOnly={!_.get(serviceActionFilesQuery,"serviceAction.access.write")}
                categories={_.get(serviceActionFilesQuery, '__type.enumValues', []).map((enumValue) => ({
                    index: enumValue.name,
                    title: enumValue.description,
                }))}
                addFileMutation={addServiceActionFilesMutation}
                addFileMutationOptions={(fileInputs: FileCreateInput[]) => ({
                    variables: {
                        serviceActionId,
                        files: fileInputs
                    }
                })}
                editFileMutation={updateServiceActionFileMutation}
                editFileMutationOptions={(file, input) => ({
                    variables: {
                        serviceActionId,
                        serviceActionIdFileId: file.id,
                        input
                    }
                })}
                removeFileMutation={deleteServiceActionFileMutation}
                removeFileMutationOptions={(file) => ({
                    variables: {
                        serviceActionId,
                        fileId: file.id
                    }
                })}
                fileListQuery={serviceActionFilesQuery}
                fileListKey={'serviceAction.serviceActionFileList.serviceActionFiles'}
                checkPrivateCondition={(file) => {
                    // only dependent from the private flag of the service action
                    return isPrivate ? "private" : undefined
                }}
            />
        </div>;
    }
}

export default compose(
    graphql(ServiceActionFilesQuery, {
        name: 'serviceActionFilesQuery',
        options: (props) => ({
            variables: {
                id: props.serviceActionId
            }
        })
    }),
    graphql(AddServiceActionFilesMutation, {
        name: 'addServiceActionFilesMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceActionFilesQuery,
                    variables: {
                        id: props.serviceActionId
                    }
                }
            ]
        })
    }),
    graphql(UpdateServiceActionFileMutation, {
        name: 'updateServiceActionFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceActionFilesQuery,
                    variables: {
                        id: props.serviceActionId
                    }
                }
            ]
        })
    }),
    graphql(DeleteServiceActionFileMutation, {
        name: 'deleteServiceActionFileMutation',
        options: (props: Props) => ({
            refetchQueries: [
                {
                    query: ServiceActionFilesQuery,
                    variables: {
                        id: props.serviceActionId
                    }
                }
            ]
        })
    }),
)(withTranslation()(
    queryWrapper(withRouter(ServiceActionDashboardFilesWidget), (props: Props) => ({
        queryConfigs: [{
            query: props.serviceActionFilesQuery,
            checkProperties: ['serviceAction.serviceActionFileList.serviceActionFiles'],
        }]
    }))));
