import styled from "styled-components";

const OneLineLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export default OneLineLabel;
