// @flow
import React, {Component} from "react"
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Empty, Tree} from 'antd';
import {Icon} from "@dreebit/react-components";
import _ from "lodash";

import JsonDataManu from "../JsonDataManu";

const {TreeNode} = Tree;

type Props = {
    t: Function,
    data: any,
    id: any,
}

type State = {
    showModal: boolean,
    selectedKeys: Array,
    e: any,
}

function tree(input) {
    let arr;
    if (Array.isArray(input)) arr = [...input];
    else arr = Object.keys(input);

    return arr.reduce((acc, key, i) => {
        if (!acc) acc = [];

        if (Array.isArray(input[key])) {
            return [...acc, <TreeNode key={i + "-" + key} title={key + ": "}>{tree(input[key])}</TreeNode>];
        }
        if (Array.isArray(key)) {
            return [...acc, <TreeNode key={i + "-" + key} title={key + ": "}>{tree(key)}</TreeNode>];
        }
        if (_.isObject(input[key])) {
            return [...acc, <TreeNode key={i + "-" + key} title={key + ": "}>{tree(input[key])}</TreeNode>];
        }
        if (_.isObject(key)) {
            return [...acc, <TreeNode key={i + "-" + i} title={i + ": "}>{tree(key)}</TreeNode>];
        }

        if (!input[key]) return [...acc, <TreeNode key={i + "-" + key} title={key}/>];
        else return [...acc, <TreeNode key={i + "-" + key} title={key + ": " + input[key]}/>];
    }, []);
}

class JsonTree extends Component<Props, State> {
    state = {
        showModal: false,
        selectedKeys: [],
        e: {},
    };

    _hideModal = () => {
        this.setState({
            showModal: false,
            selectedKeys: [],
            e: {},
        })
    };

    _showModal = () => {
        this.setState({
            showModal: true
        })
    };

    render() {
        const {data, id} = this.props;

        if (!_.get(data, 'data')) return <Empty/>;

        const treeData = JSON.parse(data.data);
        if (!treeData || _.isEmpty(treeData)) return <Empty/>;

        return <div>
            <Tree
                switcherIcon={<Icon type="down"/>}
                onSelect={(selectedKeys, e) => {
                    this.setState({selectedKeys, e});
                    return this._showModal()
                }}
            >
                {
                    tree(treeData)
                }
            </Tree>
            {this.state.showModal ? <JsonDataManu data={JSON.parse(data.data)} id={id} showModal={this.state.showModal}
                                                  onCancel={this._hideModal} selectData={this.state.e}/> : null}
        </div>
    }

}

export default compose(
    withTranslation(),
)(JsonTree);
