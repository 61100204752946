// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import _ from 'lodash';
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import AddParticipantsToConversationQuery from "../../graphql/mutations/addParticipantsToConversation.graphql";
import {Loader} from "@dreebit/react-components";
import TDAAttributesForm from "../TDAAttributesForm";
import ConversationQuery from "../../graphql/queries/conversation.graphql";
import MessageListQuery from "../../graphql/queries/messageList.graphql";
import {toastError} from "../../utils/toast";

type Props = {
    onClose: () => void,
    conversationId?: string
}

const MessageDashboardSettingsAddParticipant = ({onClose, conversationId}: Props) => {
    const {t} = useTranslation();

    const [addParticipantsToConversation] = useMutation(AddParticipantsToConversationQuery, {
        refetchQueries: [{
            query: ConversationQuery,
            variables: {
                conversationId
            }
        }, {
            query: MessageListQuery,
            variables: {
                conversationId,
                params: {
                    start: 0,
                    limit: 40,
                }
            }
        }]
    });
    const {data, loading, error} = useQuery(TypeDefinitionAttributeListQuery, {
        variables: {
            index: 'conversationParticipant'
        }
    });

    if (loading) return <Loader/>;
    if (!_.get(data, 'typeDefinitionAttributeList') || error) {
        toastError(t('An error has occurred. Please try again later.'));
        onClose();
        return null
    }

    let attributes = _.get(data, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);

    attributes = attributes.map(item => {
        if (item.index === "participants") {
            return {
                ...item,
                inputType: "autocomplete",
            }
        }
        return item

    })

    return <TDAAttributesForm
        layout={'vertical'}
        clearOnSubmit={true}
        cacheChanges={true}
        mutation={addParticipantsToConversation}
        attributes={attributes}
        successMessage={t('Participants added!')}
        submitTitle={t('Add Participants')}
        onSubmit={onClose}
        translateMutationOptions={({values}) => {
            const participants = _.get(values, 'participants', []).map((participant: string) => {
                if (participant.includes('@')) {
                    return {
                        email: participant
                    }
                }

                return {
                    userId: participant
                }
            });

            return {
                variables: {
                    conversationId,
                    input: {
                        participants,
                        sendConversationHistory: values.sendConversationHistory
                    },
                }
            }
        }}
    />;
};

export default MessageDashboardSettingsAddParticipant
