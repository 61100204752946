// @flow
import React, {Component} from 'react';
import {Card, Collapse, message, Switch} from "antd";
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

import addModuleToClientMutation from "./../../graphql/mutations/addModuleToClient.graphql";
import removeModuleFromClientMutation from "./../../graphql/mutations/removeModuleFromClient.graphql";
import ClientModulesQuery from "../../graphql/queries/ClientModules.graphql";
import Strings from "../../i18n/strings";
import Empty from "../Empty";
import ReactMarkdown from "react-markdown";

const Panel = Collapse.Panel;

type Props = {
    clientId: string,
    refetchQueries?: any[],
    active: boolean,
    module: {
        key: string,
        name: string,
        resources: {
            key: string,
            name: string,
            description: string
        }
    },
    style: ?any,
    t: Function,
    addModuleToClientMutation: Function,
    removeModuleFromClientMutation: Function,
};


class ClientModuleListItem extends Component<Props> {


    state = {
        loading: false,
    };

    handleActiveChange = (checked) => {

        const {t} = this.props;

        this.setState({
            loading: true
        }, () => {

            let promise = null;
            let resKey = "";
            if (checked) {
                resKey = "addModuleToClient";
                promise = this.props.addModuleToClientMutation();
            } else {
                resKey = "removeModuleFromClient";
                promise = this.props.removeModuleFromClientMutation();
            }

            promise.then((res) => {
                message.success(_.get(res, `data.${resKey}.message`))
            }).catch(() => {
                message.error(t(Strings.errorOccurred))
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })

    };

    render() {
        const {module, active, style} = this.props;

        return <Card
            title={module.name}
            extra={<Switch loading={this.state.loading} checked={active} onChange={this.handleActiveChange}/>}
            style={style}
        >
            {module.resources && module.resources.length ?
                <Collapse>
                    {module.resources.map(item => (<Panel header={item.name} key={item.key}>
                       <ReactMarkdown source={item.description} />
                    </Panel>))}
                </Collapse> : <Empty/>}
        </Card>
    }

}

export default compose(
    graphql(removeModuleFromClientMutation, {
        name: 'removeModuleFromClientMutation',
        options: (props: Props) => ({
            variables: {
                clientId: props.clientId,
                moduleKey: props.module.key
            },
            refetchQueries: props.refetchQueries ? props.refetchQueries : [{
                query: ClientModulesQuery,
                variables: {
                    id: props.clientId
                }
            }]
        }),
    }),
    graphql(addModuleToClientMutation, {
        name: 'addModuleToClientMutation',
        options: (props: Props) => ({
            variables: {
                clientId: props.clientId,
                moduleKey: props.module.key
            },
            refetchQueries: props.refetchQueries ? props.refetchQueries : [{
                query: ClientModulesQuery,
                variables: {
                    id: props.clientId
                }
            }]
        }),
    }),
    withTranslation()
)(ClientModuleListItem);
