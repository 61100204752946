// @flow
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import serviceTicketListSearch from '../../graphql/queries/serviceTicket-Search.graphql';
import componentListSearch from '../../graphql/queries/componentList-Search.graphql';
import {useMutation, useQuery} from "@apollo/client";
import {Button} from "@dreebit/react-components";
import _ from "lodash";
import updateBookmark from "../../graphql/mutations/updateBookmark.graphql";
import BookmarkListQuery from "../../graphql/queries/bookmarkList.graphql";
import Toolbar from "../Toolbar";
import QueryTable from "../QueryTable";
import {toastError, toastSuccess} from "../../utils/toast";
import bookmarkDataQuery from "../../graphql/queries/bookmarkData.graphql";
import {bookmarkListVariables} from "../BookmarkList";
import {getColumns} from "./utils";
import Loader from '../Loader';

type Props = {
    bookmark: any
}

const BookmarkDetailView = (props: Props) => {

    const { bookmark } = props;
    const {t} = useTranslation();

    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [query, setQuery] = useState(null);
    const [itemsKeyPath, setItemsKeyPath] = useState('');
    const [totalKeyPath, setTotalKeyPath] = useState('');
    const [columns, setColumns] = useState([]);

    const bookmarkData = JSON.parse(bookmark.data);
    let bookmarkIds = [];

    if(bookmarkData && bookmarkData.serviceTicketIds) bookmarkIds = bookmarkData.serviceTicketIds;
    if(bookmarkData && bookmarkData.componentIds) bookmarkIds = bookmarkData.componentIds;

    const serviceTicketListQuery = useQuery(serviceTicketListSearch, {
        variables: {
            params: {
                filter: {
                    id: {
                        value: bookmarkIds
                    }
                }
            }
        },
        skip: !bookmarkData.serviceTicketIds || bookmarkIds.length === 0
    });

    const componentListQuery = useQuery(componentListSearch, {
        variables: {
            params: {
                filter: {
                    id: {
                        value: bookmarkIds
                    }
                }
            }
        },
        skip: !bookmarkData.componentIds || bookmarkIds.length === 0
    });

    useEffect(() => {
        if(_.get(serviceTicketListQuery, 'data.serviceTicketList.serviceTickets')) {
            setQuery(serviceTicketListQuery);
            setItemsKeyPath('serviceTicketList.serviceTickets');
            setTotalKeyPath('serviceTicketList.total');
            setColumns(getColumns('serviceTicket', t));
        }
        if(_.get(componentListQuery, 'data.componentList.components')) {
            setQuery(componentListQuery);
            setItemsKeyPath('componentList.components');
            setTotalKeyPath('componentList.total');
            setColumns(getColumns('component', t));
        }
    }, [serviceTicketListQuery, componentListQuery]);

    const [updateBookmarkMutation] = useMutation(updateBookmark, {
        refetchQueries: [
            {
                query: BookmarkListQuery,
                variables: bookmarkListVariables(_.get(props, 'bookmarkTypes', null) || _.get(props, 'type', null))
            }, {
                query: bookmarkDataQuery,
                variables: {
                    bookmarkId: bookmark.id
                }
            }
        ]
    });

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowIds(selectedRows.map((item: any) => item.id));
        },
        getCheckboxProps: (record: any )=> ({
            disabled: record.current,
            name: record.name,
        }),
    };

    return <div>
        {selectedRowIds.length ? <Toolbar
            renderLeft={() => {
                if(!_.isNil(bookmark.access.remove) && !bookmark.access.remove) return null;

                return <Button
                    onClick={() => {
                        let data = {};

                        if(bookmarkData && bookmarkData.serviceTicketIds) {
                            data = {
                                serviceTicketIds: _.difference(bookmarkIds, selectedRowIds)
                            };
                        }
                        if(bookmarkData && bookmarkData.componentIds) {
                            data = {
                                componentIds: _.difference(bookmarkIds, selectedRowIds)
                            };
                        }

                        updateBookmarkMutation({
                            variables: {
                                id: bookmark.id,
                                input: {
                                    data: JSON.stringify({
                                        link: bookmarkData.link,
                                        ...data
                                    })
                                }
                            }
                        })
                            .then(() => {
                                setSelectedRowIds([]);
                                toastSuccess(t('Successfully removed'));
                            })
                            .catch(() => {
                                setSelectedRowIds([]);
                                toastError(t('An error occurred'));
                            });
                    }}
                >
                    {t('Remove')}
                </Button>
            }}
        /> : null}
        {
            query
                ? <QueryTable
                    loading={_.get(query, 'loading', true)}
                    className={"table"}
                    size={'middle'}
                    rowSelection={bookmark.access.write ? rowSelection : null}
                    query={query}
                    itemsKeyPath={itemsKeyPath}
                    totalKeyPath={totalKeyPath}
                    rowKey={"id"}
                    columns={columns}/>
                : <Loader/>
        }
    </div>
};

export default BookmarkDetailView
