import React from "react";
import {Button, Tooltip} from "antd";
import {Icon} from "@dreebit/react-components"
import ServiceTicketShareQuery from "./../../graphql/queries/serviceTicketShare.graphql"
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import _ from "lodash";

interface Props {

}

const ServiceTicketShareButton = ({buttonProps, serviceTicketId}: Props) => {

    const query = useQuery(ServiceTicketShareQuery,{
        variables: {
            id: serviceTicketId
        }
    })
    const {t} = useTranslation();

    return <Tooltip title={t('Open Order Status Page')}> <Button {...buttonProps}
                             loading={query.loading}>{!query.loading ?
        <a target={"_BLANK"} href={_.get(query,'data.serviceTicket.orderStatusLink')}><Icon type={'ShareAltOutlined'}/></a> : null }</Button> </Tooltip>

}

export default ServiceTicketShareButton;
