import React from 'react';
import {compose, withProps} from 'recompose';
import {graphql} from '@apollo/client/react/hoc';
import ProfileQuery from "./../graphql/queries/profile.graphql";
import _ from "lodash";

const withProfile = (options) =>
    compose(
        graphql(ProfileQuery, {
            name: 'profileQuery',
            options: () => ({
                fetchPolicy: options && options.fetchPolicy ? options.fetchPolicy : null
            })
        }),
        withProps(({profileQuery}) => {

            const profile = _.get(profileQuery,'profile');

            return {
                profile: profile,
                resources: _.get(profile, 'resources', []).reduce((acc, cur) => {
                    return [...acc, cur.key]
                }, [])
            }


        })
    );

export default withProfile;
