// @flow
import React, {Component} from 'react';
import {message} from "antd";
import MutationButton from "../MutationButton";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import SubmitDebugMessageMutation from "../../graphql/mutations/submitDebugMessage.graphql";
import _ from "lodash";

type Props = {
    description?: string,
    feedbackData?: any,
    title: string
};

type State = {

}

const parseFeedbackDataFromError = (error) => {
    let tmp = _.cloneDeep(error);
    if (tmp.error) {
        tmp = _.cloneDeep(tmp.error);
    }

    const query = _.get(tmp, "graphQLErrors[0].operation.query.loc.source.body");
    const operationName = _.get(tmp, "graphQLErrors[0].operation.operationName");
    const variables = _.get(tmp, "graphQLErrors[0].operation.variables");

    return {
        error: _.get(tmp, 'message'),
        variables,
        operationName,
        query,
        raw: tmp
    };
}

class FeedbackButton extends Component<Props, State> {

  render() {
    const { submitDebugMessageMutation, t } = this.props;

    return <MutationButton
        onDone={() => {
            message.success(t("Send feedback"))
        }}
        onError={() => message.error(t("Could not send feedback"))}
        mutation={submitDebugMessageMutation}
        buttonProps={{
            type: "default",
            icon: "alert"
        }}>{t("Send Feedback")}</MutationButton>
  }
}

export default compose(
    graphql(SubmitDebugMessageMutation, {
        name: 'submitDebugMessageMutation',
        options: (props) => {
            const data = {
                ..._.cloneDeep(parseFeedbackDataFromError(props.feedbackData)),
                location: JSON.stringify(window.location)
            };

            return {
                variables: {
                    input: {
                        type: "error",
                        text: _.isString(props.description) ? props.description : _.isString(props.title) ? props.title : _.get(data, 'error', "Error"),
                        data: data ? JSON.stringify(data) : JSON.stringify(window.location),
                    }
                }
            }
        }
    }),
    withTranslation(),
)(FeedbackButton);
