// @flow
import React, {Component} from 'react';
import type {Ticket} from "../../types";
import {Link} from "react-router-dom";
import _ from "lodash";

type Props = {
  serviceTicket: Ticket
};

type State = {

}

class ServiceTicketLabel extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { serviceTicket } = this.props;

    return <Link to={`/service/${serviceTicket.id}`}>
      <div className={'flex-col border border-radius-5 p-5'}>
        <div>
          <span className={'bold'}>{serviceTicket.key} - {serviceTicket.name}</span>
        </div>
        <span>{_.get(serviceTicket, 'component.name')}</span>
        <span className={'small-text'}>{_.get(serviceTicket, 'lastServiceAction.serviceActionType.code')} - {_.get(serviceTicket, 'lastServiceAction.serviceActionType.name')}: {_.get(serviceTicket, 'lastServiceAction.doneBy.name')}</span>
      </div>
    </Link>;
  }
}

export default ServiceTicketLabel;