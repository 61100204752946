import React from 'react';
import _ from "lodash";
import {compose} from "redux";
import { graphql } from '@apollo/client/react/hoc';
import ComponentTypeNameQuery from "../../graphql/queries/componentType.graphql";
import {withTranslation} from "react-i18next";

const ComponentTypeBreadcrumb = ({componentTypeNameQuery, t}) =>
    <span>{_.get(componentTypeNameQuery, 'componentType.name', '...')}</span>;

export default compose(
    graphql(ComponentTypeNameQuery, {
        name: 'componentTypeNameQuery',
        options: ({match}) => ({
            variables: {
                index: _.get(match, 'params.componentTypeIndex', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(ComponentTypeBreadcrumb));
