// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {graphql} from '@apollo/client/react/hoc';

import ManufacturerTypeDefinitionAttributeListQuery
    from "../../graphql/queries/manufacturerTypeDefinitionAttributeList.graphql";
import CreateManufacturerMutation from "../../graphql/mutations/createManufacturer.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ManufacturerListQuery from "../../graphql/queries/manufacturerList.graphql";
import AttributesInlineForm from "../Form/attributes-inline-form";
import ContentHeader from "../Typo/ContentHeader";

type Props = {
    t: Function,
    manufacturerTypeDefinitionAttributeListQuery: query,
    createManufacturerMutation: Function,
    match: {
        params: {
            filter: any,
            id: string,
        }
    }
};


class ManufacturerCreate extends Component<Props, State> {
    render() {
        const {t, manufacturerTypeDefinitionAttributeListQuery, createManufacturerMutation, history} = this.props;

        return <div className={'full-width white-bg'}>
            <ContentHeader title={t('Create Manufacturer')}/>
            <div className={'full-width flex flex-center pt-20'}>
                <AttributesInlineForm
                    attributes={_.get(manufacturerTypeDefinitionAttributeListQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', {})}
                    layout={'vertical'}
                    useSubmit={true}
                    t={t}
                    onSubmit={(res) => {
                        history.push(`/catalog/manufacturer/${_.get(res, 'data.createManufacturer.id')}`)
                    }}
                    mutation={createManufacturerMutation}
                    translateMutationOptions={({values}) => ({
                        variables: {
                            input: values
                        }
                    })}
                />
            </div>
        </div>;
    }
}

export default compose(
    withRouter,
    graphql(ManufacturerTypeDefinitionAttributeListQuery, {
        name: 'manufacturerTypeDefinitionAttributeListQuery',
    }),
    waitWhileLoading('manufacturerTypeDefinitionAttributeListQuery', ['typeDefinitionAttributeList.typeDefinitionAttributes']),
    graphql(CreateManufacturerMutation, {
        name: "createManufacturerMutation",
        options: props => ({
            refetchQueries: [{
                query: ManufacturerListQuery,
                variables: {
                    params: {
                        filter: {
                            status: {
                                operator: "in",
                                value: _.get(_.has(props, "match.params.filter") ? JSON.parse(_.get(props, "match.params.filter")) : {}, 'status')
                            }
                        },
                        limit: 40,
                        search: {
                            query: _.get(_.has(props, "match.params.filter") ? JSON.parse(_.get(props, "match.params.filter")) : {}, 'query')
                        }
                    }
                }
            }, {
                query: ManufacturerListQuery
            }]

        })
    }),
    withTranslation(),
)(ManufacturerCreate)
