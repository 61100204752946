// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import ServiceBudgetListQuery from "../../graphql/queries/serviceBudgetList.graphql";
import _ from "lodash";
import moment from "moment";
import FillQueryTable from "../QueryTable/fill";
import {Link} from "react-router-dom";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import statics from "../../statics";

type Props = {
  filterValues: any,
  t: Function
};


class ServiceBudgetList extends Component<Props, State> {


  componentDidMount() {}

  render() {
    const { t, serviceBudgetListQuery } = this.props;

    return <div className={"full-size position-absolute"}>
      <div className={"full-width text-right mv-5"}>
        <span className={"text-bold mr-25"}>{t('Total price')}: {_.get(serviceBudgetListQuery, 'serviceBudgetList.totalPrice')}€</span>
      </div>
      <FillQueryTable
        className={"link-table"}
        size={'middle'}
        query={serviceBudgetListQuery}
        itemsKeyPath={'serviceBudgetList.serviceBudgets'}
        totalKeyPath={'serviceBudgetList.total'}
        startKeyPath={'params.start'}
        limitKeyPath={'params.limit'}
        rowKey={"component.id"}
        columns={[
          {
            dataIndex: 'servicePlan.dueDate',
            title: t('Date'),
            key: 'servicePlan.dueDate',
            width: '15%',
            render: text => moment(text).format('l LT')
          },
          {
            dataIndex: 'servicePlan.name',
            title: t('Name'),
            key: 'servicePlan.name',
            width: '25%',
            render: (text, item) => <Link to={`/infrastructure/${_.get(item, 'component.id')}/serviceplans/${_.get(item, 'servicePlan.id')}`}><span className='text-font-weight-500 link-dark'>{text}</span></Link>
          },
          {
            dataIndex: 'component.name',
            title: t('Component'),
            key: 'component.name',
            render: (text, item) => <div className={'flex-col'}>
              <Link to={`/infrastructure/${_.get(item, 'component.id')}`}><span className='text-font-weight-500 link-dark'>{text}</span></Link>
              <span className='small-text'>{t('serialNo')}: {_.get(item, 'component.serialNo', '-')}</span>
              <span className='small-text'>
                {t('Component path')}: {_.get(item, 'component.path[0].name')} {_.tail(_.get(item, 'component.path')).map(item => ` > ${_.get(item, 'name')}`)}
              </span>
            </div>
          },
          {
            dataIndex: 'price',
            title: t('Price in Euro'),
            key: 'price',
            width: '7%',
            render: text => text ? text : '-'
          }
        ]}
      />
    </div>;
  }
}

export const getParams = (filter) => {
  const params = {};

  if (filter.componentId) {
    _.set(params, 'filter.parentId', {
      value: _.get(filter, 'componentId')
    })
  }

  if (filter.dueDate) {
    const dateFrom = new Date(_.get(filter, 'dueDate')[0]);
    const dateTo = new Date(_.get(filter, 'dueDate')[1]);

    _.set(params, 'filter.dueDate', {
      operator: 'range',
      range: {
        from: dateFrom.toISOString(),
        to: dateTo.toISOString()
      }
    })
  }

  if (filter.isPlanned) {
    if(_.get(filter, 'isPlanned') === 'true') {
      _.set(params, 'filter.isPlanned', {
        value: true
      })
    }else{
      _.set(params, 'filter.isPlanned', {
        value: false
      })
    }

  }

  return params;
};

export default compose(
  withTranslation(),
  graphql(ServiceBudgetListQuery, {
    name: 'serviceBudgetListQuery',
    options: props => ({
      variables: {
        params: {
          ...getParams(props.filterValues),
          start: 0,
          limit: statics.defaultPagingSize,
        }
      }
    }),
  }),
  waitWhileLoading('serviceBudgetListQuery', 'serviceBudgetList')
)(ServiceBudgetList);
