// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {Collapse} from "antd";
import ReactMarkdown from "react-markdown";
import TDAAttributesForm from "../TDAAttributesForm";
import tda from "./tda";
import {getContaminationDeclarationGroups} from "../ContaminationDeclarationForm/form";

const { Panel } = Collapse;


type Props = {

};

type State = {

}

class RMADecontaminationDeclaration extends Component<Props, State> {


  /**
   * sends a request to the specified url from a form. this will change the window location.
   * @param {string} path the path to send the post request to
   * @param {object} params the paramiters to add to the url
   * @param {string} [method=post] the method to use on the form
   */

  post(path, params, method='post') {

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }

  render() {
    const { t, i18n } = this.props;

    console.log(i18n);

    return <div>
      <div className="contamination-border-top">
        <div className="contamination-primary-bg"/>
        <div className="contamination-secondary-bg"/>
        <div className="contamination-light-secondary-bg"/>
      </div>
      <div className={"p-10"}>
        <h2 className={"pv-0 mv-0"}>{t('Contamination Declaration')}</h2>
        <hr/>
        <Collapse>
          <Panel header={t('More info')} key="1">
            <ReactMarkdown source={t('deco info text')}/>
          </Panel>
        </Collapse>

        <TDAAttributesForm
            translateMutationOptions={({ values}) => {
              return values;
            }}
            mutation={(values) => {
              return new Promise((resolve, reject)=>{

                this.post(``,{ values: JSON.stringify(values)} );
                resolve();
              })
            }}

          attributes={_.get(window,'__BE__.decoTypeDefinitionAttributes',_.get(tda,'data.typeDefinitionAttributeList.typeDefinitionAttributes',[]))}
          groups={getContaminationDeclarationGroups(t)}
          />
      </div>
    </div>

  }
}
export default compose(
    withTranslation()
)(RMADecontaminationDeclaration);

