const getLessVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable);

export default {
    primary: getLessVariable('--primary'),
    darkHalf: getLessVariable('--darkHalf'),
    inactive: getLessVariable('--inactive'),
    highlight: getLessVariable('--highlight'),
    copyLight: getLessVariable('--copyLight'),
    copyMedium: getLessVariable('--copyMedium'),
    copyDark: getLessVariable('--copyDark'),
    red: getLessVariable('--red'),
    deepPurple: getLessVariable('--deepPurple'),
    indigo: getLessVariable('--indigo'),
    teal: getLessVariable('--teal'),
    orange: getLessVariable('--orange'),
    blueGrey: getLessVariable('--blueGrey'),
    blueGreyDark: getLessVariable('--blueGreyDark'),
    purple: getLessVariable('--purple'),
    green: getLessVariable('--green'),
    white: getLessVariable('--white'),
    grey: getLessVariable('--grey'),
    greyLight: getLessVariable('--greyLight'),
    greyLighter: getLessVariable('--greyLighter'),
    greyDark: getLessVariable('--greyDark'),
    greyDarker: getLessVariable('--greyDarker'),
    black: getLessVariable('--black'),
    success: getLessVariable('--success'),
    warning: getLessVariable('--warning'),
    warningLighter: getLessVariable('--warningLighter'),
    warningLight: getLessVariable('--warningLight'),
    warningDark: getLessVariable('--warningDark'),
    warningDarker: getLessVariable('--warningDarker'),
    dangerLightest: getLessVariable('--dangerLightest'),
    dangerLighter: getLessVariable('--dangerLighter'),
    dangerLight: getLessVariable('--dangerLight'),
    danger: getLessVariable('--danger'),
    error: getLessVariable('--error'),
    info: getLessVariable('--info'),
    open: getLessVariable('--open'),
    unplanned: getLessVariable('--unplanned'),
    inProgress: getLessVariable('--inProgress'),
    done: getLessVariable('--done'),
    translucentWhite: getLessVariable('--translucentWhite'),
    transparent: getLessVariable('--transparent'),
};
