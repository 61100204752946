// @flow
import React, {Component} from "react"

import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import ServiceProviderListQuery from "./../../graphql/queries/serviceProviderList.graphql";
import {withTranslation} from "react-i18next";
import type {Query} from "../../types";
import FillQueryTable from "../QueryTable/fill";
import './index.css';
import _ from "lodash";
import {Popconfirm, Tag} from "antd";
import {Icon} from "@dreebit/react-components";
import {changeDirectionType} from "../StatisticsServiceProvider";

type Props = {
    t: Function,
    serviceProviderListQuery: Query,
    onClick: Function,
    userClientId?: string,
    defaultServiceProviderId?: string,
}

class ServiceProviderList extends Component<Props> {


    render() {

        const {serviceProviderListQuery, t, onClick, defaultServiceProviderId} = this.props;

        return <div className={'ServiceProviderList'}>
            <FillQueryTable
                style={{borderRadius: 0}}
                className={"link-table"}
                columns={[
                    {
                        key: 'name',
                        dataIndex: 'name',
                        title: t('Name'),
                        render: (text, item) => {
                            const isDefaultServiceProvider = defaultServiceProviderId && defaultServiceProviderId === _.get(item, 'id');
                            return <>{text} {isDefaultServiceProvider ? <Tag className={"ml-5"} color={'blue'}>{t("Default Service Provider")}</Tag> : null}</>
                        }
                    },
                    {
                        key: 'selfService',
                        dataIndex: 'selfService',
                        title: t('Self service'),
                        filters: [{
                            value: "selfService",
                            text: t('self managed')
                        }],
                        onFilter: (value, record) => _.get(record, 'selfService') === true,
                        render: (text, item) => {
                            return <>{text} {_.get(item, 'selfService') ? <Tag className={"ml-5"}>{t("self managed")}</Tag> : null}</>
                        }
                    }
                ]}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onClick(record);
                        },
                    };
                }}
                size={'small'}
                rowKey={"id"}
                query={serviceProviderListQuery}
                itemsKeyPath={'serviceProviderList.serviceProviders'}
                totalKeyPath={'serviceProviderList.total'}
                sortBy={'name'}
                footerHeight={95}
            />
        </div>
    }

}

export default compose(
    graphql(ServiceProviderListQuery, {
        name: 'serviceProviderListQuery',
        options: (props) => ({
            variables: {
                params: {
                    start: 0,
                    limit: 40,
                    filter: {
                        clientId: props.userClientId ? {
                            value: props.userClientId
                        } : undefined
                    }
                }
            }
        }),
    }),
    withTranslation(),
)(ServiceProviderList);
