import React, {Component} from 'react';
import _ from "lodash";
import {compose} from "recompose";
import NavigationContainer from "../../../../components/NavigationContainer";
import ContentHeader from "../../../../components/Typo/ContentHeader";
import {withTranslation} from "react-i18next";
import CategoryList from "../../../../components/CategoryList";
import {createDndContext} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Col, Grow, Row} from "../../../../components/Flex";
import ComponentTypesList from "../../../../components/ComponentTypesList";
import Panel from "../../../../components/Panel";

type Props = {
    onCategoryListRowClick: (row: any) => void,
};

class CatalogCategoriesListRoute extends Component<Props>{
    render(){

        const RNDContext = createDndContext(HTML5Backend)

        const {t, match, onCategoryListRowClick} = this.props

        return (
            <div>
                <ContentHeader title={ t('Category List')} />
                    <Row className={'full-size'}>
                        <Grow className={"overflow-scroll"}>
                            <div style={{height: 'calc(100vh - 120px)', overflow: 'scroll'}}>
                            <CategoryList
                                onCategoryListRowClick={row => onCategoryListRowClick(row)}
                                context={RNDContext}
                            />
                            </div>
                        </Grow>
                    </Row>
        </div>)
    }
}

export default compose(
    withTranslation()
)(CatalogCategoriesListRoute);