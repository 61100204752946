// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import './index.css';

import type {Query} from "../../types";
import {Checkbox, ConfigProvider, Empty, Table, Tooltip} from "antd";
import Loader from "../Loader";
import QueryErrorIndicator from "../QueryErrorIndicator";
import {updateCache} from "../../utils/loadMoreApolloCache";
import {withTranslation} from "react-i18next";
import {Row} from "../Flex";
import statics from "../../statics";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {saveForm} from "../../actions/settings";

type Props = {
    defaultAutoRefetch?: boolean,
    autoRefetchInterval?: number,
    query: Query,
    queryVariables?: any,
    style: any,
    bordered: boolean,
    itemsKeyPath: string,
    totalKeyPath: string,
    pagingSize: ?number,
    limitKeyPath: ?string,
    startKeyPath: ?string,
    columns: ?any[],
    uniqueIdentifier?: any,
    useWindow?: boolean,
    sortBy?: string,
    groupBy?: string,
    removeKeyById?: string,
    pagingScrollHeight?: number,
    renderCustomFooter?: (variables?: any) => any
};

type State = {
    autoRefetch: boolean,
    loadingMore: boolean,
    sorter: any,
}

class QueryTable extends Component<Props, State> {

    static defaultProps = {
        autoRefetchInterval: 5*60*1000,
        pagingSize: statics.defaultPagingSize,
        sortKeyPath: 'params.sort',
        limitKeyPath: 'params.limit',
        startKeyPath: 'params.start',
        pagingScrollHeight: 20
    };

    tableRef = React.createRef();
    refetchInterval = null;

    state = {
        autoRefetch: this.props.defaultAutoRefetch !== undefined ? this.props.defaultAutoRefetch : false,
        sorter: {},
        loadingMore: false,
    };

    componentDidMount() {
        const scrollContainer = this.tableRef.current ? this.tableRef.current.querySelector(".ant-table-body") : undefined;

        if (scrollContainer) {
            scrollContainer.onscroll = ({target}) => {
                if (target.scrollHeight - target.offsetHeight - target.scrollTop < this.props.pagingScrollHeight) {
                    this._handleWaypointEnter();
                }
            }
        }

        this.refetchInterval = setInterval(() => {
            this._handleAutoRefetch();
        }, this.props.autoRefetchInterval)
    }

    componentWillUnmount() {

        clearInterval(this.refetchInterval);
    }

    _handleAutoRefetch () {
        if (this.state.autoRefetch){
            this.props.query.refetch();
        }
    }

    _handleWaypointEnter = () => {

        const oldData = _.clone(_.get(this.props.query,'data',this.props.query));

        const total = _.get(oldData, this.props.totalKeyPath);
        const length = _.get(oldData, this.props.itemsKeyPath, []).length;

        if (length < total) {


            const variables = {
                ...this.props.queryVariables,
                ...this.props.query.variables,
            };
            _.set(variables, this.props.limitKeyPath, this.props.pagingSize);
            _.set(variables, this.props.startKeyPath, length);

            this.setState({
                loadingMore: true
            }, () => {
                this.props.query.fetchMore({
                    variables,
                    updateQuery: (prev, next) => {
                        return updateCache(oldData, next, this.props.itemsKeyPath, this.props.totalKeyPath, this.props.uniqueIdentifier);
                    },
                }).then(() => this.setState({loadingMore: false}));
            });


        }
    };


    render() {
        const {
            query,
            itemsKeyPath,
            totalKeyPath,
            style,
            className,
            groupBy,
            sortBy,
            hideEmpty,
            t,
            removeKeyById,
            filter,
            renderCustomFooter,
            queryVariables
        } = this.props;

        let items = _.get(_.get(query, 'data', query), itemsKeyPath, []);
        if (filter) {
            items = _.filter(items, filter)
        }
        if (sortBy) {
            items = _.sortBy(items, [sortBy]);
        }
        if (removeKeyById) {
            items = _.filter(items, (item) => item.id !== removeKeyById)
        }

        let columns = this.props.columns.map((item) => {
            if (item.dataIndex && _.isString(item.dataIndex) && item.dataIndex.includes(".")) {
                return {
                    ...item,
                    dataIndex: item.dataIndex.split(".")
                }
            }
            return item
        });

        if (groupBy) {
            const groups = _.groupBy(items, groupBy);
            let cursor = 0;
            Object.keys(groups).forEach((key, index) => {
                const section = {
                    type: "__SECTION__",
                    title: key
                };
                items.insert(cursor, section);
                cursor += groups[key].length + index;
            })


            columns = columns.map((column, colIndex) => {
                const existingRender = column.render ? column.render.clone() : null;
                return {
                    ...column,
                    render: (text, row, index) => {
                        if (_.get(row, 'type') === '__SECTION__') {
                            return {
                                children: colIndex === 0 ? <span>{_.get(row, 'title')}</span> : undefined,
                                props: {
                                    colSpan: colIndex === 0 ? _.size(this.props.columns) : 0
                                },
                            }
                        }
                        return existingRender ? existingRender(text, row, index) : text
                    }
                };
            })

        }

        return <div ref={this.tableRef} className={className} style={style}>
            <div className={'queryTableWrapper'}>

                <ConfigProvider renderEmpty={() => hideEmpty ? null : <Empty />}>
                    <Table
                        rowKey={'id'}
                        loading={this.state.loading || (query.loading && !this.state.loadingMore)}
                        footer={() => {
                            let errorComponent = null;
                            if (query.error) {
                                errorComponent =
                                    <div data-cy={'query-error-component'}><QueryErrorIndicator query={query}/></div>
                            }

                            const total =  _.get(_.get(query, 'data', query), totalKeyPath, -1);
                            return <Row style={{height: 30, contentAlign: 'middle'}}>
                        <span className={"flex-grow-1"}>
                            {total>-1 ? `${total} ${total === 1 ? t("Item") : t("items")}` : t("No entries loaded")}
                        </span>

                                {this.state.loadingMore ? <span className={"mr-10"}>
                            <Loader size={"small"}/>
                        </span> : <Tooltip title={t("Auto refetch at {{time}}",{time: `${this.props.autoRefetchInterval / 1000 / 60} min`})}>

                                    <Checkbox checked={this.state.autoRefetch} onChange={() => {
                                        this.props.saveAutoRefetch(!this.state.autoRefetch);
                                        this.setState({
                                            autoRefetch: !this.state.autoRefetch
                                        },() => {
                                            this._handleAutoRefetch();
                                        })
                                    }}>{t("Auto refetch")}</Checkbox>
                                </Tooltip>}

                                {errorComponent}
                                {renderCustomFooter ? renderCustomFooter(_.get(query, 'variables')) : null}
                            </Row>

                        } }
                        pagination={false}
                        onChange={(pagination, filters, sorter) => {

                            if (JSON.stringify(sorter) !== JSON.stringify(this.state.sorter)) {

                                const sortObject = {};
                                if (sorter.field) {
                                    sortObject[sorter.field] = sorter.order === "descend" ? "DESC" : "ASC";
                                }

                                const variables = {
                                    ...queryVariables,
                                    ...query.variables,
                                };
                                _.set(variables, this.props.sortKeyPath, sortObject);

                                this.setState({
                                    sorter,
                                    loading: true
                                },() => {
                                    query.refetch(variables).then(() => {
                                        this.setState({
                                            loading: false
                                        });
                                    }).catch(() => {
                                        this.setState({
                                            loading: false
                                        });
                                    })
                                })


                            }

                        }}
                        {...this.props}
                        columns={columns}
                        dataSource={items}
                    />
                </ConfigProvider>

            </div>
        </div>;
    }
}


export default compose(
    withTranslation(),
    withProps((props) => {
        const hashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)

        const formId = hashCode(JSON.stringify({
            keys: Object.keys(props.query),
            ...props.query.variables
        }));
        return {
            formId: `tableSettings_${formId}`
        }
    }),
    connect(({settings}, props) => {

        return {
            defaultAutoRefetch: _.get(settings,`forms.${props.formId}.defaultAutoRefetch`,false)
        }
    }, (dispatch, props) => {
        return {
            saveAutoRefetch: (defaultAutoRefetch) => dispatch(saveForm(`${props.formId}`, {defaultAutoRefetch}))
        }
}),
)(QueryTable);
