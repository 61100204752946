// @flow
import React, {Component} from 'react';
import {Bar, BarChart, LabelList, Legend, XAxis, YAxis} from "recharts";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ServiceTicketListUpdateQuery from "../../../graphql/queries/serviceTicketListUpdate.graphql";
import waitWhileLoading from "../../../hoc/waitWhileLoading";
import _ from "lodash";
import {groupByMomentFormat} from "../helperFunctions";

type Props = {
  width: number,
  height: number,
  userParams: {
    dateRange: {
      from: string,
      to: string
    }
  }
};

type State = {

}

class A_DoneServiceTickets extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { width, height, serviceTicketListUpdateQuery } = this.props;

    let chartWidth = (width * window.innerWidth * 0.2) - 40;
    let chartHeight = (height * window.innerHeight * 0.33) - 40;

    const doneServiceTickets = _.get(serviceTicketListUpdateQuery, 'serviceTicketList.serviceTickets');

    const data = groupByMomentFormat(doneServiceTickets, 'MMM');

    return <div>
      <BarChart width={chartWidth} height={chartHeight} data={data} margin={{top: 20, right: 20, bottom: 20, left: 20}}>
        <XAxis dataKey={'date'}/>
        <YAxis/>
        <Legend/>
        <Bar dataKey={'tickets'}  name="Servicetickets" fill={'#ee8126'}>
          <LabelList dataKey={'tickets'} style={{ fill: '#FFFFFF' }}/>
        </Bar>
      </BarChart>
    </div>;
  }
}

export default compose(
  graphql(ServiceTicketListUpdateQuery, {
    name: 'serviceTicketListUpdateQuery',
    options: (props: Props) =>({
      variables: {
        params: {
          start: 0,
          limit: 100000,
          sort: {
            update: 'ASC'
          },
          filter: {
            serviceTicketStateIndex: {value: 'done'},
            serviceTicketTypeIndex: {operator: 'in', value: ['repair', 'recon', 'maintenance', 'loan']},
            update: {operator: 'range', range: _.get(props, 'userParams.dateRange')}
          }
        }
      }
    }),
  }),
  waitWhileLoading('serviceTicketListUpdateQuery', 'serviceTicketList.serviceTickets'),
)(A_DoneServiceTickets);
