// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import {Card, message, Popconfirm} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {withRouter} from "react-router-dom";

import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Col, Row} from "../Flex";
import AttributesInlineForm from "../Form/attributes-inline-form";
import ListAdapter from "../ListAdapter";
import RolesLoaderConfig from "../DataLoader/RolesLoaderConfig";
import PanelComponent from "../Panel";
import ModuleResourcesPanel from "../ModuleResourcesPanel";
import ContentHeaderWithBreadcrumb from "../ContentHeaderWithBreadcrumb";
import DeleteRole from "../../graphql/mutations/deleteRole.graphql";
import UpdateRole from "../../graphql/mutations/updateRole.graphql";
import RoleListQuery from "../../graphql/queries/roleList.graphql";
import RemoveUserFromRole from "../../graphql/mutations/removeUserFromRole.graphql";
import AddRoleToUser from "../../graphql/mutations/addRoleToUser.graphql";
import UserListQuery from "../../graphql/queries/userList.graphql";
import AddResourceToRole from "../../graphql/mutations/addResourceToRole.graphql";
import RemoveResourceFromRole from "../../graphql/mutations/removeResourceFromRole.graphql";
import ModuleList from "../../graphql/queries/ModuleList.graphql";
import roleDetails from "./../../graphql/queries/roleDetails.graphql";
import {toastError} from "../../utils/toast";

type Props = {
    roleId: string,
    t: Function,
    history: {
        push: Function
    },
    roleDetailsQuery: query,
    rolesTypeDefinitionAttributeList: query,
    moduleListQuery: query,
    deleteRoleMutation: Function,
    updateRoleMutation: Function,
    removeUserFromRoleMutation: Function,
    addRoleToUserMutation: Function,
    addResourceToRoleMutation: Function,
    removeResourceFromRoleMutation: Function,
}

class RoleDashboard extends Component<Props> {

    handleDeleteRole = () => {
        const {deleteRoleMutation, roleId, history} = this.props;

        deleteRoleMutation({variables: {id: roleId}}).then((res) => {
            message.success(res.data.deleteRole.message);
            history.push(`/admin/roles`)
        })
    };

    handleAddUserToRole = (value) => {
        const {addRoleToUserMutation, roleId} = this.props;

        addRoleToUserMutation({variables: {roleId: roleId, userId: value}}).then((res) => {
            message.success(res.data.addUserToRole.message);
        })
    };

    handleDeleteUserFromRole = (index) => {
        const {removeUserFromRoleMutation, roleId, roleDetailsQuery, t} = this.props;
        if (roleDetailsQuery.role.users.length === 1 && roleDetailsQuery.role.clientAdminRole) {
            message.error(t('Can`t delete last Admin'));
        } else {
            removeUserFromRoleMutation({variables: {roleId: roleId, userId: index.id}}).then((res) => {
                message.success(res.data.removeUserFromRole.message);
            })
        }

    };

    handleResourceToRole = (value, key) => {
        const {addResourceToRoleMutation, removeResourceFromRoleMutation, roleId} = this.props;

        if (value) {
            addResourceToRoleMutation({variables: {roleId: roleId, resourceKey: key}}).then((res) => {
                message.success(res.data.addResourceToRole.message);
            }).catch((err) => {
                toastError(err)
            })
        } else {
            removeResourceFromRoleMutation({variables: {roleId: roleId, resourceKey: key}}).then((res) => {
                message.success(res.data.removeResourceFromRole.message);
            }).catch((err) => {
                toastError(err)
            })
        }

    };

    render() {
        const {roleDetailsQuery, roleId, t, moduleListQuery, updateRoleMutation} = this.props;

        return <Col style={{width: '100%'}}>
            <ContentHeaderWithBreadcrumb
                title={roleDetailsQuery.role.name}
                tools={
                    roleDetailsQuery.role.clientAdminRole ? null :
                        <Popconfirm placement="bottomRight" title={t("Are you sure delete this user?")}
                                    onConfirm={this.handleDeleteRole} okText={t("Yes")} cancelText={t("No")}
                                    icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}>
                            <Button type="danger" icon="delete">{t('Delete')}</Button>
                        </Popconfirm>
                }
                path={[{
                    name: t("Roles"),
                    id: -1,
                    link: '/admin/roles',
                }, {
                    name: roleDetailsQuery.role.name,
                    id: roleId,
                    link: '/admin/roles',
                }]}
            />

            <Row className={''}
                 style={{backgroundColor: '#F9F9F9', height: '100%', width: '100%', marginTop: '20px'}}>
                <Col style={{width: '55%'}} className={""}>
                    <div style={{width: '90%'}}>
                        <PanelComponent
                            title={t('Edit')}
                            children={<AttributesInlineForm
                                attributes={_.get(roleDetailsQuery, 'role.typeDefinitionAttributeList.typeDefinitionAttributes')}
                                layout={'horizontal'}
                                useSubmit={true}
                                t={t}
                                values={roleDetailsQuery.role}
                                mutation={updateRoleMutation}
                                translateMutationOptions={({values}) => ({
                                    variables: {
                                        roleId: roleId,
                                        role: {
                                            name: values.name,
                                            description: values.description ? values.description : ""
                                        }
                                    }
                                })}
                            />}
                        />
                    </div>
                    <div style={{width: '90%', paddingTop: '20px'}}>
                        <PanelComponent
                            title={t('User')}
                            children={<ListAdapter
                                items={roleDetailsQuery.role.users}
                                addRoleToUserMutation={(value) => {
                                    this.handleAddUserToRole(value)
                                }}
                                deleteRoleFromUserMutation={(index) => {
                                    this.handleDeleteUserFromRole(index)
                                }}
                                loaderConfig={RolesLoaderConfig}
                                queryPath={'userList.users'}
                                placeholder={t('select users')}
                            />}
                        />
                    </div>
                </Col>
                <Col style={{width: '45%', height: '100%', paddingBottom: '20px'}}>
                    <div style={{width: '90%'}}>
                        {
                            roleDetailsQuery.role.clientAdminRole
                                ? <PanelComponent
                                    title={t('Rights')}
                                    children={<Card
                                        title={roleDetailsQuery.role.name}
                                        style={{width: '100%', background: '#F9F9F9'}}
                                    >
                                        <p>{t('This role is an admin and have all rights')}</p>
                                    </Card>}
                                />
                                : <PanelComponent
                                    title={t('Rights')}
                                    children={<ModuleResourcesPanel
                                        moduleList={_.get(moduleListQuery, 'moduleList.modules', [])}
                                        onChange={(value, key) => {
                                            this.handleResourceToRole(value, key)
                                        }}
                                        activatedList={_.get(roleDetailsQuery, 'role.resources', [])}
                                    />}
                                />
                        }
                    </div>
                </Col>
            </Row>
        </Col>;
    }
}

export default compose(
    withRouter,
    graphql(roleDetails, {
        name: 'roleDetailsQuery',
        options: props => ({
            fetchPolicy: 'network-only',
            variables: {
                id: props.roleId
            }
        }),
    }),
    waitWhileLoading('roleDetailsQuery', ['role']),
    graphql(ModuleList, {
        name: 'moduleListQuery',
    }),
    waitWhileLoading('moduleListQuery', ['moduleList.modules']),
    graphql(DeleteRole, {
        name: 'deleteRoleMutation',
        options: () => ({
            refetchQueries: [{
                query: RoleListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }, {
                query: UserListQuery,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    }),
    graphql(UpdateRole, {
        name: 'updateRoleMutation',
        options: props => ({
            refetchQueries: [{
                query: roleDetails,
                variables: {
                    id: props.roleId
                }
            }]
        })
    }),
    graphql(RemoveUserFromRole, {
        name: 'removeUserFromRoleMutation',
        options: props => ({
            refetchQueries: [{
                query: roleDetails,
                variables: {
                    id: props.roleId
                }
            }]
        })
    }),
    graphql(AddRoleToUser, {
        name: 'addRoleToUserMutation',
        options: props => ({
            refetchQueries: [{
                query: roleDetails,
                variables: {
                    id: props.roleId
                }
            }]
        })
    }),
    graphql(AddResourceToRole, {
        name: 'addResourceToRoleMutation',
        options: props => ({
            refetchQueries: [{
                query: roleDetails,
                variables: {
                    id: props.roleId
                }
            }]
        })
    }),
    graphql(RemoveResourceFromRole, {
        name: 'removeResourceFromRoleMutation',
        options: props => ({
            refetchQueries: [{
                query: roleDetails,
                variables: {
                    id: props.roleId
                }
            }]
        })
    }),
    withTranslation(),
)(RoleDashboard);
