// @flow
import React, {Component} from "react"
import {Link, withRouter} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {compose, withProps} from "recompose";

import ContentHeader from "../../components/Typo/ContentHeader";
import ProductList from "./../../components/ProductList";
import ProductToolbar from "../../components/ProductToolbar";
import queryString from "query-string";
import Toolbar from "../../components/Toolbar";
import NavigationContainer from "../../components/NavigationContainer";
import {connect} from "react-redux";
import {setProductSearchQuery} from "../../actions/settings";

type Props = {
	history: {
		push: Function,
	},
	match: any,
	t: Function,
};

class ProductsRoute extends Component<Props, State> {
	render() {
		const {t, history, setQuery, filter} = this.props;


		return (

			<NavigationContainer navigationBar={
				<div>
					<ContentHeader
						hideBreadcrumb
						title={t('Products')}
						tools={<div>
							<Link to={'/catalog/products/add'}>
								<Button icon={"plus"}>
									{t('Add')}
								</Button>
							</Link>
						</div>}/>
					<Toolbar
						borderBottom
						className={"white-bg"}
						renderLeft={() => <ProductToolbar
							values={filter}
							onChange={(attributes, {allValues}) => {
								const query = queryString.stringify(allValues);
								setQuery(query);
								history.push(`/catalog/products?${query}`)
							}}
						/>}/>
				</div>
			} >
				<ProductList
					filterValues={filter}
					tableProps={{
						onRow: (record, rowIndex) => {
							return {
								onClick: event => {
									history.push(`/catalog/products/${record.id}`)
								}
							};
						}
					}}
				/>
			</NavigationContainer>

		)
	}
}

export default compose(
	withTranslation(),
	withRouter,
	withProps((props) => {
		let query = _.get(props, 'history.location.search');
		return {
			query
		}
	}),
	connect((state, props) => {

		let query = props.query;
		if (!query || !query.length) {
			query = _.get(state, 'settings.productSearchQuery');
		}

		return {
			query,
		}
	}, dispatch => ({
		setQuery: (query) => {
			return dispatch(setProductSearchQuery(query))
		}
	})),
	withProps((props) => ({
		filter: queryString.parse(props.query)
	}))
)(ProductsRoute);
