import logo from '../../assets/logo/virtual-service-management-w240.png';
import vsmCubes from '../../assets/logo/vsm-cubes.svg';
import pvSignetLogo from '../../assets/logo/pfeiffervacuum-logo-2024-favicon.svg';
import pvNewLogo from '../../assets/logo/pfeiffervacuum-logo-2024.svg';
import buschLogo from '../../assets/logo/busch-logo-w250.png';
import buschLogoSVG from '../../assets/logo/busch-logo.svg';
import xenonLogo from '../../assets/logo/xenon-logo-w250.png';
import xenonLogoSVG from '../../assets/logo/xenon_logo.svg';
import virtualServiceManagement from '../../assets/logo/virtual-service-management.png';

const Logos =  {
    vsm: {
        '@login-logo': logo,
        '@navigation-logo': vsmCubes,
        '@big-logo': virtualServiceManagement,
    },
    busch: {
        '@login-logo': buschLogo,
        '@navigation-logo': buschLogoSVG,
    },
    xenon: {
        '@login-logo': xenonLogo,
        '@navigation-logo': xenonLogoSVG,
    },
    pv: {
        '@login-logo': pvNewLogo,
        '@navigation-logo': pvSignetLogo,
    },
    pvuk: {
        '@login-logo': pvNewLogo,
        '@navigation-logo': pvSignetLogo,
    }
};

export default Logos
