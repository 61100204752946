import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ServicePlanQuery from "../../graphql/queries/servicePlan.graphql"

const ServiceplanBreadcrumb = ({ServicePlanQuery}) => <span>{_.get(ServicePlanQuery, 'servicePlan.name', '...')}</span>;

export default compose(
    graphql(ServicePlanQuery, {
        name: 'ServicePlanQuery',
        options: ({match}) => ({
            variables: {
                servicePlanId: _.get(match, 'params.servicePlanId', _.get(match, 'params.id')),
            }
        })
    }),
)(withTranslation()(ServiceplanBreadcrumb));
