// @flow
import React, {useMemo} from 'react';
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";

interface Props {
    onChange: (changedValues: any, allValues: any) => void,
    values: any
}

const ProductToolbar = ({onChange, values}: Props) => {

    return (
        <TypeDefinitionAttributesForm
            index={'productFilter'}
            formProps={{
                values,
                layout: 'inline',
                useSubmit: false,
                enableInitOnChange: true,
                onChange: (changedValues, allValues) => {
                    if (onChange) {
                        onChange(changedValues, allValues)
                    }
                }
            }}
        />
    );
}

export default ProductToolbar;
