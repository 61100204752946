import * as actionTypes from '../actions/actionTypes';



const initialState = {
    user: null,
    token: null,
    workflowboard_uuid: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_LOGGED_IN:
            return {
                ...state,
                user: action.user,
                token: action.token
            };
        case actionTypes.USER_LOGGED_OUT:
            return {
                ...state,
                user: null,
                token: null,
                workflowboard_uuid: null
            };

        case actionTypes.USER_UUID:
            return {
                ...state,
                workflowboard_uuid: action.uuid
            };
        default:
            return state;
    }
};

export default reducer;
