// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "recompose";

import Toolbar from "../Toolbar";
import AttributesInlineForm from "../Form/attributes-inline-form";

type Props = {
    t: Function,
    logLevelEnums: any,
    logActionKeyEnums: any,
    onChange: Function,
    values: any,
};

class ServiceTicketLogToolbar extends Component<Props, State> {
    render() {
        const {t, logLevelEnums, logActionKeyEnums, onChange, values} = this.props;

        const attributes = [{
            index: 'logLevel',
            type: 'string',
            entityType: 'logLevel',
            enumType: 'LogLevel',
            inputType: 'dropdowncheckbox',
            placeholder: t('Level'),
            config: {
                mode: 'default',
            },
            optionValues: logLevelEnums?.map(item => ({
                name: t(item.name),
                value: t(item.name)
            })),
        }, {
            index: 'logActionKey',
            type: 'string',
            entityType: 'enum',
            enumType: 'ComponentLogType',
            inputType: 'dropdowncheckbox',
            placeholder: t('Action'),
            config: {
                mode: 'default',
            },
            optionValues: logActionKeyEnums?.map(item => ({
                name: t(item.name),
                value: t(item.name)
            })),
        }];

        return <Toolbar
            style={{paddingBottom: 10}}
            renderLeft={() => <AttributesInlineForm
                layout={'inline'}
                t={t}
                onValuesChange={(changes) => {
                    onChange(changes)
                }}
                values={values}
                attributes={attributes}
                useSubmit={false}
            />}
        />
    }
}

export default compose(
    withTranslation(),
)(ServiceTicketLogToolbar);
