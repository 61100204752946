// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import ComponentServicePlansList from "./list";
import {Grow, Row} from "../Flex";
import {Link} from "react-router-dom";
import {Button} from "@dreebit/react-components";

type Props = {
    componentId: string,
    t: Function,
};


class ComponentServicePlans extends Component<Props> {

    render() {
        const {componentId, t} = this.props;

        return <div className={'ph-15 mv-10'}>
            <Row className={'flex-align-items-center'}>
                <Grow>
                    <h3>{t('Service Plans and Inspections')}</h3>
                </Grow>
                <Link to={`/infrastructure/${componentId}/servicecontrols/new`}>
                    <Button className={'mr-10'} type={'primary'} icon={'plus'}>{t('New Inspection')}</Button>
                </Link>
                <Link to={`/infrastructure/${componentId}/serviceplans/new`}>
                    <Button type={'primary'} icon={'plus'}>{t('New service recommendation')}</Button>
                </Link>
            </Row>
            <ComponentServicePlansList componentId={componentId}/>
        </div>;
    }
}

export default withTranslation()(ComponentServicePlans);
