// @flow
import React from "react";
import VSMPanelTopBorder from "./topBorder";

type Props = {
    children?: any
}

const VSMPanel = ({children}: Props) => {

    return <div className={'bg-white vsmPanel'} style={{height: 'fit-content'}}>
        <VSMPanelTopBorder/>
        <div className={'pv-25 pt-40 pb-20 ph-35 mt-5'}>
            {children}
        </div>
    </div>
};

export default VSMPanel
